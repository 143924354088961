<template>
  <v-card>
    <!-- Toolbar -->
    <header-main />
    <!-- End of Toolbar -->

    <!-- Stepper -->
    <v-stepper v-model="e1" class="mt-2" style="background: #f6f6f6">
      <div class="layout column align-center pt-3" style="background: #fff">
        <p class="cyan--text text--darken-1 font-weight-bold" id="title">
          Apply for Investor Access
        </p>
      </div>

      <!-- Stepper Header -->
      <v-stepper-header style="background: #fff">
        <v-divider />

        <v-stepper-step
          @click="redirectToStep(1)"
          :complete="val > 1"
          step="1"
          color="#00aac3"
        />

        <v-divider />

        <v-stepper-step
          @click="redirectToStep(2)"
          :complete="val > 2"
          step="2"
          color="#00aac3"
        />

        <v-divider />

        <v-stepper-step
          @click="redirectToStep(3)"
          :complete="val > 3"
          step="3"
          color="#00aac3"
        />

        <v-divider />

        <v-stepper-step
          @click="redirectToStep(4)"
          :complete="val > 4"
          step="4"
          color="#00aac3"
        />

        <v-divider />
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Step 1 -->
        <v-stepper-content step="1">
          <Step1
            @passInvestType="defineInvestType"
            @done="storeApplicationStep(2)"
            v-bind:me="me"
          />
        </v-stepper-content>

        <!-- Step 2 -->
        <v-stepper-content step="2">
          <Step2
            @passFirmName="defineFirmName"
            @done="storeApplicationStep(3)"
            v-bind:me="me"
            v-if="e1 == 2 && me"
          />
        </v-stepper-content>

        <!-- Step 3 -->
        <v-stepper-content step="3">
          <Step3
            @done="storeApplicationStep(4)"
            :me="me"
            v-if="e1 == 3 && me && me.investment_type"
          />
        </v-stepper-content>

        <!-- Step 4 -->
        <v-stepper-content step="4">
          <Step4
            v-bind:me="me"
            v-if="e1 == 4 && me && me.investment_type"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import HeaderMain from "@/components/HeaderMain.vue";

import Profile from "@/store/services/profile-service";
import Status from "@/store/services/status.service";

import Step1 from "@/components/Steps/Investor/Step1/Contact.vue";
import Step2 from "@/components/Steps/Investor/Step2/Accreditation.vue";
import Step3 from "@/components/Steps/Investor/Step3/Investment.vue";
import Step4 from "@/components/Steps/Investor/Step4/Terms.vue";

import AccreditationService from "@/store/services/investor/accreditation.service";

export default {
  components: {
    HeaderMain,
    Step1,
    Step2,
    Step3,
    Step4,
  },

  data() {
    return {
      e1: 1,
      val: 1,
      me: {},
    };
  },
  methods: {
    redirectToStep(value) {
      if (value <= this.val) {
        this.e1 = value;
      }
    },
    defineInvestType(investment_type) {
      this.me.investment_type = investment_type; // can be individual or firm
    },
    defineFirmName(firm_name)
    {
      this.me.firm_name= firm_name;
    },
    getProfile() {
      Profile.get().then((res) => {
        if (res.data) {
          this.me.first_name = res.data.first_name;
          this.me.last_name = res.data.last_name;
          this.me.email = res.data.email;
        }
      });
    },
    getStatus() {
      Status.readAll()
        .then((res) => {
          if (res.data) {
            this.me.company_name = res.data.active_company_name;
            this.redirectToLastEl(res.data.active_application_step);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    },
    storeApplicationStep(variable) {
      this.e1 = variable;
      if (this.e1 > this.val) {
        this.val = this.e1;
      }
      Status.store(JSON.stringify({ application_step: this.e1 }));
    },
    redirectToLastEl(variable) {
      if (this.$route.query.el) {
        this.e1 = this.$route.query.el;
        this.val = this.e1;
      } else if (variable) {
        this.e1 = variable;
        this.val = this.e1;
      }
    },
    getType() {
      AccreditationService.getType()
        .then((res) => {
          if (res.data) {
            if (res.data.firm_or_individual) {
              this.me.investment_type = res.data.firm_or_individual;
            }
          }
        })
        .catch((e) => {
          //console.error(e);
        });
    },
  },
  mounted() {
    this.getType()
    this.getProfile();
    this.getStatus();
  },
};
</script>

<style>
#title {
  font-size: 25px;
  letter-spacing: -1px;
}
</style>
