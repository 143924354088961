import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

axios.defaults.headers.post["Content-Type"] = "application/vnd.api+json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token");
axios.defaults.headers.common["Accept"] = "application/vnd.api+json";

function saveStrategy(payload) {
  return axios.post(`${url}/business/strategy`, payload);
}
function getStrategy(company_id) {
  return axios.get(`${url}/business/strategy/${company_id}`);
}

function saveType(payload) {
  return axios.post(`${url}/business/type`, payload);
}
function getType(company_id) {
  return axios.get(`${url}/business/type/${company_id}`);
}

function saveProduct(payload) {
  return axios.post(`${url}/business/product`, payload);
}
function getProduct(company_id) {
  return axios.get(`${url}/business/product/${company_id}`);
}

export default {
  saveStrategy,
  saveType,
  saveProduct,
  getProduct,
  getStrategy,
  getType
};