<template>
    <v-row align="center" justify="center" class="mar-top-100">
        <v-col cols="10">
            <p class="cyan--text text--darken-1 mt-3 text-sm-left" id="title">Company Contact</p>

            <!-- Header -->
            <div style="background-color: #c5e7ec;" class="mb-7">
                <v-row>
                    <v-col cols="4" md="2">
                        <v-img
                        class="py-5"
                        contain
                        minWidth="75px"
                        minHeight="75px"
                        maxWidth="150px"
                        maxHeight="150px"
                        style="border-radius: 50%; border: 0 solid;"
                        :src="avatarPreviewUrl"/>
                    </v-col>
                    
                    <v-col cols="auto" class="pt-10">
                        <p class="thin text-sm-left">{{me.first_name}}</p>
                        <p class="thin text-sm-left">{{me.last_name}}</p>
                        <p class="thin text-sm-left">{{me.email}}</p>
                    </v-col>
                </v-row>
            </div>

            <!-- Body -->
            <div id="personal">

                <!-- Live -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.personal.city.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Where I live*</p>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <FormInlineMessage v-if="$v.personal.city.$error">
                            Please select where you live
                        </FormInlineMessage>
                        <v-autocomplete
                        v-model="personal.city"
                        :items="country"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Phone -->
                <v-row>
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.phoneValid.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Phone*</p>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <FormInlineMessage v-if="$v.phoneValid.$error">
                            Please select valid phone number
                        </FormInlineMessage>
                        <vue-tel-input
                        defaultCountry="country"
                        v-model="phone"
                        @validate="validatePhone"/>
                    </v-col>
                </v-row>

                <!-- Date of Birth -->
                <v-row>
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.personal.date_of_birth.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Date of Birth*</p>
                    </v-col>
                    <v-col cols="8" sm="3">
                        <FormInlineMessage v-if="$v.personal.date_of_birth.$error">
                            Please select your date of birth
                        </FormInlineMessage>
                       
                        <v-menu
                            ref="dob"
                            :close-on-content-click="false"
                            :return-value.sync="personal.date_of_birth"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                height="40"
                                outlined
                                v-model="personal.date_of_birth"
                                placeholder="Date of birth"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                @blur="$v.personal.date_of_birth.$touch()"
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="personal.date_of_birth" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="$refs.dob.isActive = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dob.save(personal.date_of_birth)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="0" sm="1"/>

                </v-row>

                <!-- Gender -->
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="2">
                        <p class="text-sm-left">Gender*</p>
                    </v-col>
                    <v-col cols="8" sm="2">
                        <v-checkbox
                        @click="female=false; other=false; no=false;"
                        v-model="male"
                        label="Male"
                        class="mt-n3"/>
                    </v-col>
                    <v-col cols="8" sm="2">
                        <v-checkbox
                        @click="male=false; other=false; no=false;"
                        v-model="female"
                        label="Female"
                        class="mt-n3"/>
                    </v-col>
                    <v-col cols="8" sm="2">
                        <v-checkbox
                        @click="male=false; female=false; no=false;"
                        v-model="other"
                        label="Other"
                        class="mt-n3"/>
                    </v-col>
                    <v-col cols="8" sm="4">
                        <v-checkbox
                        @click="male=false; female=false; other=false;"
                        v-model="no"
                        label="Prefer not to say"
                        class="mt-n3"/>
                    </v-col>
                </v-row>

                <!-- Years of experience -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.personal.experience.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Years of experience*</p>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <FormInlineMessage v-if="$v.personal.experience.$error">
                            Please select years of experience
                        </FormInlineMessage>
                        <v-select
                        v-model="personal.experience"
                        :items="yearsExperiences"
                        dense
                        outlined/>
                    </v-col>
                </v-row>

                <!-- Social Networks -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="2">
                        <p class="text-sm-left">My Social Networks</p>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <v-text-field
                        v-model="personal.social_network.twitter"
                        prepend-inner-icon="mdi-twitter pb-2"
                        outlined
                        dense
                        placeholder="https://twitter.com/@john"/>

                        <v-text-field
                        class="mt-n6"
                        v-model="personal.social_network.linkedin"
                        prepend-inner-icon="mdi-linkedin pb-2"
                        outlined
                        dense
                        placeholder="https://linkedin.com/in/john"/>
                    </v-col>
                </v-row>

                <!-- Save button -->
                <v-row align="center" justify="center" class="pt-2">
                    <v-col cols="0" sm="9"/>
                    <v-col cols="12" sm="1">
                            <v-btn
                            id="btn-photo"
                            class="white--text mb-3"
                            style="font-weight: bold; font-size: 14px"
                            color="#11aec9"
                            min-width="20"
                            rounded
                            block
                            :disabled="false"
                            @click.stop.prevent="savePersonal()">
                                save
                            </v-btn>
                    </v-col>
                    <v-col cols="0" sm="2"/>
                </v-row>
            </div>

            <div id="education">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Education</p>

                <div v-for="(education, index) in educations" :key="index">

                    <!-- School name -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="2">
                            <FormInlineMessage v-if="$v.educations.$each[index].school.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">School</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.educations.$each[index].school.$error">
                                Provide School Name
                            </FormInlineMessage>
                            <v-text-field
                            v-model="education.school"
                            outlined
                            dense/>
                        </v-col>
                    </v-row>

                    <!-- Date -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="2">
                            <FormInlineMessage v-if="$v.educations.$each[index].start_year.$error || $v.educations.$each[index].end_year.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Date Attended</p>
                        </v-col>

                        <!-- Start date -->
                        <v-col cols="12" sm="3">
                            <FormInlineMessage v-if="$v.educations.$each[index].start_year.$error">
                                When did you start?
                            </FormInlineMessage>
                            <FormInlineMessage v-else-if="$v.educations.$each[index].end_year.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <v-menu
                            ref="menuStart"
                            :close-on-content-click="false"
                            :return-value.sync="education.start_year"
                            offset-y
                            min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    height="40"
                                    outlined
                                    v-model="education.start_year"
                                    placeholder="Start"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"/>
                                </template>

                                <v-date-picker
                                v-model="education.start_year"
                                no-title
                                scrollable
                                type="month">

                                    <v-spacer/>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuStart[index].isActive = false">
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuStart[index].save(education.start_year)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="0" sm="2">
                            <p><br>———</p>
                        </v-col>
                        
                        <!-- End date -->
                        <v-col cols="12" sm="3">
                            <FormInlineMessage v-if="$v.educations.$each[index].end_year.$error">
                                When did you end?
                            </FormInlineMessage>
                            <FormInlineMessage v-else-if="$v.educations.$each[index].start_year.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <v-menu
                            ref="menuEnd"
                            :close-on-content-click="false"
                            :return-value.sync="education.end_year"
                            offset-y
                            min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    height="40"
                                    outlined
                                    v-model="education.end_year"
                                    placeholder="End"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @blur="$v.educations.$each[index].end_year.$touch()"/>
                                </template>

                                <v-date-picker
                                    v-model="education.end_year"
                                    no-title
                                    scrollable
                                    type="month">

                                    <v-spacer/>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuEnd[index].isActive = false">
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuEnd[index].save(education.end_year)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <!-- Degree -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="2">
                            <FormInlineMessage v-if="$v.educations.$each[index].degree.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Degree</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.educations.$each[index].degree.$error">
                                Please select your degree
                            </FormInlineMessage>
                            <v-select
                            v-model="education.degree"
                            :items="degrees"
                            dense
                            outlined/>
                        </v-col>
                    </v-row>

                    <!-- Field of study -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="2">
                            <FormInlineMessage v-if="$v.educations.$each[index].field.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Field of Study</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.educations.$each[index].field.$error">
                                Please indicate field of study
                            </FormInlineMessage>
                            <v-text-field
                            v-model="education.field"
                            dense
                            outlined/>
                        </v-col>
                    </v-row>

                    <!-- Description -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="2">
                            <FormInlineMessage v-if="$v.educations.$each[index].description.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Description</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.educations.$each[index].description.$error">
                                Please type the description
                            </FormInlineMessage>
                            <v-textarea
                            v-model="education.description"
                            outlined
                            dense/>
                        </v-col>
                    </v-row>
                </div>

                <!-- Add/Remove/Save button -->
                <v-row class="mt-n8">
                    <v-col cols="12" sm="2">
                        <v-btn
                        id="btn-add"
                        text
                        color="#00aac3"
                        @click.prevent.stop="addEducation()">
                        + Add education
                        </v-btn>
                    </v-col>
                    <v-col cols="0" sm="1"/>
                    <v-col cols="12" sm="2" v-if="educations.length > 1">
                        <v-btn
                        id="btn-add"
                        text
                        color="#00aac3"
                        @click.prevent.stop="deleteEducation()">
                        - Remove education
                        </v-btn>
                    </v-col> 

                    <v-col cols="0" sm="2" v-else/>    

                    <v-col cols="0" sm="4"/>

                    <v-col cols="12" sm="1">
                            <v-btn
                            id="btn-photo"
                            class="white--text mb-3"
                            style="font-weight: bold; font-size: 14px"
                            color="#11aec9"
                            min-width="20"
                            rounded
                            block
                            :disabled="false"
                            @click.stop.prevent="saveEducation()">
                            save
                            </v-btn>
                    </v-col>       
                </v-row>
            </div>

            <div id="experience">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Experience</p>

                <div v-for="(experience, index) in experiences" :key="index">

                    <!-- Company name -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="2">
                            <FormInlineMessage v-if="$v.experiences.$each[index].company.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Name company</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.experiences.$each[index].company.$error">
                                Provide Company Name
                            </FormInlineMessage>
                            <v-text-field
                            v-model="experience.company"
                            dense
                            outlined/>
                        </v-col>
                    </v-row>

                    <!-- Title -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="2">
                            <FormInlineMessage v-if="$v.experiences.$each[index].title.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Title</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.experiences.$each[index].title.$error">
                                Provide your title
                            </FormInlineMessage>
                            <v-text-field
                            v-model="experience.title"
                            dense
                            outlined/>
                        </v-col>
                    </v-row>

                    <!-- Location -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="2">
                            <FormInlineMessage v-if="$v.experiences.$each[index].location.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Location</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.experiences.$each[index].location.$error">
                                Provide location
                            </FormInlineMessage>
                            <v-autocomplete
                            v-model="experience.location"
                            :items="country"
                            outlined
                            dense/>
                        </v-col>
                    </v-row>

                    <!-- Time period -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="2">
                            <FormInlineMessage v-if="$v.experiences.$each[index].start.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Time period</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-row>
                                <v-row  align="center" justify="center">
                                    <v-col class="mt-n5" cols="3" sm="2">
                                        <FormInlineMessage v-if="$v.experiences.$each[index].start.$error">
                                            <v-col cols="0" sm="12"/>
                                        </FormInlineMessage>
                                        <p>begin</p>
                                    </v-col>

                                    <!-- Begin date -->
                                    <v-col cols="7" sm="5">
                                        <FormInlineMessage v-if="$v.experiences.$each[index].start.$error">
                                            When did you start?
                                        </FormInlineMessage>
                                        <v-menu
                                        ref="menuBegin"
                                        :close-on-content-click="false"
                                        :return-value.sync="experience.start"
                                        offset-y
                                        min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                height="40"
                                                outlined
                                                v-model="experience.start"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"/>
                                            </template>

                                            <v-date-picker
                                                v-model="experience.start"
                                                no-title
                                                scrollable
                                                type="month">

                                                <v-spacer/>

                                                <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menuBegin[index].isActive = false">
                                                    Cancel
                                                </v-btn>

                                                <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menuBegin[index].save(experience.start)">
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col cols="0" sm="5"/>
                                </v-row>

                                <v-col cols="12">
                                    <v-checkbox
                                    v-model="experience.current_work"
                                    label="I currently work here"
                                    class="mt-n3 mb-8"/>
                                </v-col>

                                <!-- End date -->
                                <v-row  align="center" justify="center" v-if="!experience.current_work">
                                    <v-col class="mt-n5" cols="3" sm="2">
                                        <FormInlineMessage v-if="$v.experiences.$each[index].end.$error">
                                           <v-col cols="0" sm="12"/>
                                        </FormInlineMessage>
                                        <p>end</p>
                                    </v-col>
                                    <v-col cols="7" sm="5">
                                        <FormInlineMessage v-if="$v.experiences.$each[index].end.$error">
                                           When did you end?
                                        </FormInlineMessage>
                                        <v-menu
                                        ref="menuFinish"
                                        :close-on-content-click="false"
                                        :return-value.sync="experience.end"
                                        offset-y
                                        min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                height="40"
                                                outlined
                                                v-model="experience.end"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"/>
                                            </template>

                                            <v-date-picker
                                                v-model="experience.end"
                                                no-title
                                                scrollable
                                                type="month">

                                                <v-spacer/>

                                                <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menuFinish[index].isActive = false">
                                                    Cancel
                                                </v-btn>

                                                <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menuFinish[index].save(experience.end)">
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="0" sm="5"/>
                                </v-row>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>

                <!-- Add/Remove/Save button -->
                <v-row class="mt-n8">
                    <v-col cols="12" sm="2">
                        <v-btn
                        id="btn-add"
                        text
                        color="#00aac3"
                        @click.prevent.stop="addExperience()">
                        + Add position
                        </v-btn>
                    </v-col>
                    <v-col cols="0" sm="1"/>
                    <v-col cols="12" sm="2" v-if="experiences.length > 1">
                        <v-btn
                        id="btn-add"
                        text
                        color="#00aac3"
                        @click.prevent.stop="deleteExperience()">
                        - Remove position
                        </v-btn>
                    </v-col> 

                    <v-col cols="0" sm="2" v-else/>    

                    <v-col cols="0" sm="4"/>

                    <v-col cols="12" sm="1">
                            <v-btn
                            id="btn-photo"
                            class="white--text mb-3"
                            style="font-weight: bold; font-size: 14px"
                            color="#11aec9"
                            min-width="20"
                            rounded
                            block
                            :disabled="false"
                            @click.stop.prevent="saveExperience()">
                            save
                            </v-btn>
                    </v-col>       
                </v-row>
            </div>

            <div id="know-how">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">My know-how</p>

                <!-- Skills -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.skills.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Skills</p>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <FormInlineMessage v-if="$v.skills.$error">
                            Please select skills
                        </FormInlineMessage>
                        <v-combobox
                        v-model="skills"
                        :items="entries"
                        item-text="name"                  
                        label=""
                        outlined
                        dense
                        multiple
                        :search-input.sync="search"
                        small-chips
                        deletable-chips/>
                    </v-col>
                </v-row>

                <!-- Abilities -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.abilities.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Ability</p>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <FormInlineMessage v-if="$v.abilities.$error">
                            Please select Personal Attributes
                        </FormInlineMessage>
                        <v-autocomplete
                        v-model="abilities"
                        :items="allAbilities"
                        outlined
                        dense
                        small-chips
                        multiple
                        deletable-chips/>
                    </v-col>
                </v-row>

                <div v-for="(language, index) in languages" :key="language.language">
                    
                    <!-- Languages -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="2" v-if="!index">
                            <FormInlineMessage v-if="$v.languages.$each[index].language.$error || $v.languages.$each[index].proficiency.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Languages</p>
                        </v-col>
                        <v-col cols="0" sm="2" v-else/>
                        <v-col cols="12" sm="4">
                            <FormInlineMessage v-if="$v.languages.$each[index].language.$error">
                                Please choose language
                            </FormInlineMessage>
                            <FormInlineMessage v-else-if="$v.languages.$each[index].proficiency.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <v-select
                            v-model="language['language']"
                            :items="allLanguages"
                            item-text="name"
                            dense
                            outlined/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <FormInlineMessage v-if="$v.languages.$each[index].proficiency.$error">
                                Please choose proficiency
                            </FormInlineMessage>
                            <FormInlineMessage v-else-if="$v.languages.$each[index].language.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <v-select
                            v-model="language['proficiency']"
                            :items="proficiencyList"
                            dense
                            outlined/>
                        </v-col>
                    </v-row>
                </div>

                <!-- Add/Remove/Save button -->
                <v-row class="mt-6">
                    <v-col cols="12" sm="6">
                        <v-btn
                        id="btn-add"
                        text
                        color="#00aac3"
                        class="bg-secondary-customized"
                        @click.prevent.stop="addLanguage()">
                        + Add language
                        </v-btn>
                    </v-col>
                    <v-col cols="0" sm="1"/>
                    <v-col cols="12" sm="2" v-if="languages.length > 1">
                        <v-btn
                        id="btn-add"
                        text
                        color="#00aac3"
                        class="bg-secondary-customized"
                        @click.prevent.stop="deleteLanguage()">
                        - Remove language
                        </v-btn>
                    </v-col> 

                    <v-col cols="0" sm="2" v-else/>    

                    <v-col cols="0" sm="4"/>     
                </v-row>
                <v-row align="center" justify="center" class="mt-2">
                    <v-col cols="0" sm="9"/>
                    <v-col cols="12" sm="1">
                        <v-btn
                            id="btn-photo"
                            class="white--text mb-3"
                            style="font-weight: bold; font-size: 14px"
                            color="#11aec9"
                            min-width="20"
                            rounded
                            block
                            :disabled="false"
                            @click.stop.prevent="saveKnowHow()">
                            save
                        </v-btn>
                    </v-col>
                    <v-col cols="0" sm="2"/>
                </v-row>
            </div>

            <div id="resume">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Mini Resume</p>

                <!-- Resume -->
                <v-row>
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.resume.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Mini Resume</p>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <FormInlineMessage v-if="$v.resume.$error">
                            Please give your resume
                        </FormInlineMessage>
                        <v-textarea 
                        v-model="resume"
                        :counter="160"
                        outlined 
                        dense 
                        placeholder="My role,my recent achievements" />
                    </v-col>
                </v-row>

                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">My position in the company</p>

                <!-- Position -->
                <v-row align="center" justify="center" class="" no-gutters>
                      <v-col cols="10" md="6" class="pl-5">
                        <v-row>
                            <v-checkbox
                            @click="otherposition=false; otherField=''"
                            v-model="ceo"
                            label="I am the CEO of the Company"
                            class="mb-5"/>
                        </v-row>

                        <v-row>
                            <v-checkbox
                            @click="otherposition=false;  otherField=''"
                            v-model="cofounder"
                            label="I am the Co-founder of the Company"
                            class="mb-5"/>
                        </v-row>

                        <v-row>
                            <v-checkbox
                            v-model="otherposition" 
                            @click = "cofounder=false; ceo=false"
                            label="Other"/>

                            <v-text-field
                            v-if="otherposition"
                            class="mt-3 ml-3"
                            v-model="otherField"
                            outlined 
                            dense />
                        </v-row>
                    </v-col>
                </v-row>

                <!-- Save button -->
                <v-row align="center" justify="center" class="mt-2">
                    <v-col cols="0" sm="9"/>
                    <v-col cols="12" sm="1">
                        <v-btn
                        id="btn-photo"
                        class="white--text mb-3"
                        style="font-weight: bold; font-size: 14px"
                        color="#11aec9"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.stop.prevent="saveResume()">
                            save
                        </v-btn>
                    </v-col>
                    <v-col cols="0" sm="2"/>
                </v-row>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import {
    required,
    minLength,
    maxLength,
    sameAs,
    url,
    requiredIf,
} from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";
import Abilities from "@/abilities.js";
import Languages from "@/languages.js";
import Var from "@/store/services/var";

import Profile from "@/store/services/profile-service";
import UserEducationService from "@/store/services/user.education.service";
import UserExperienceService from "@/store/services/user.experience.service";
import UserKnowhowService from "@/store/services/user.knowhow.service";
import CompanyService from "@/store/services/company/company.service";

const diffArray = (arr1, arr2) =>
    arr1
        .concat(arr2)
        .filter((val) => !(arr1.includes(val) && arr2.includes(val)));

export default {
    components: {
        FormInlineMessage,
    },

    props: {
        avatarPreviewUrl: String,
    },

    data() {
        return {
            yearsExperiences: [
                "0 - 5 years",
                "5 - 10 years",
                "10 - 15 years",
                "15 - 20 years",
                "Above 20 years",
            ],
            proficiencyList: [
                "Elementary proficiency",
                "Limited working proficiency",
                "Professional working proficiency",
                "Full professional proficiency",
                "Native or bilingual proficiency",
            ],
            queryTerm: "",
            me: {},
            phone: null,
            male: false,
            female: false,
            other: false,
            no: false,
            phoneValid: false,
            country: [],
            entries: [],
            degrees: [],
            personal: { social_network: {}, },
            educations: [{}],
            experiences: [{}],
            skills: [],
            abilities: [],
            languages: [{}],
            resume: null,
            ceo:false,
            cofounder:false,
            otherposition:false,
            otherField:''
        }
    },

    validations: {
        phoneValid: { sameAs: sameAs(() => true), },
        personal: {
            city: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(120),
            },
            date_of_birth: { required, },
            social_network: {
                twitter: { url, },
                linkedin: { url, },
            },
            experience: { required, },
        },
        educations: {
            required,
            $each: {
                school: { required, },
                start_year: { required, },
                end_year: { required, },
                degree: { required, },
                field: {
                    required,
                    minLength: minLength(3),
                },
                description: {
                    required,
                    minLength: minLength(3),
                }
            }
        },
        experiences: {
            required,
            $each: {
                company: { required, },
                title: { required, },
                location: { required, },
                start: { required, },
                end: {
                    required: requiredIf(function (experience) {
                        if (experience.current_work != "current") { return true; }
                        else { return false; }
                    }),
                },
            },
        },
        skills: {
            required,
            minLength: minLength(1),
        },
        abilities: {
            required,
            minLength: minLength(1),
        },
        languages: {
            required,
            $each: {
                language: { required, },
                proficiency: { required, },
            }
        },
        resume: {
            required,
            minLength: minLength(10),
        },
    },

    watch: {
        abilitiesChange: function (newVal, oldVal) {
            if (newVal.length < oldVal.length) {
                const toDelete = diffArray(newVal, oldVal);
                this.deleteAbility(toDelete);
            }
        },

        skillsChange: function (newVal, oldVal) {
            if (newVal.length < oldVal.length) {
                const toDelete = diffArray(newVal, oldVal);
                this.deleteSkill(toDelete);
            }
        },
    },

    methods: {
        addEducation() { this.educations.push({}); },
        addExperience() { this.experiences.push({}); },
        addLanguage() { this.languages.push({}); },
        deleteAbility(toDelete) { UserKnowhowService.destroyAbility(toDelete) },
        deleteSkill(toDelete) { UserKnowhowService.destroySkill(toDelete) },

        loadEntries() {
            let that = this;

            Var.getAllSkills(this.queryTerm).then((res) => {
                that.entries = res.data;
            });
        },

        validatePhone(params) {
            this.phoneValid = params.valid;
            this.personal.phone = params.number;
        },

        deleteLanguage() {
            let language = this.languages[this.languages.length - 1];
            const user_id = language["user_id"] ?? false;
            language = language["language"] ?? false;
            if (user_id && language) {
                UserKnowhowService.destroyLanguage([language])
                .then((response) => { this.languages.pop(); })
                .catch((error) => {
                    //console.log(error)
                });
            } else {
                this.languages.pop();
            }
        },

        deleteExperience() {
            const experienceId = this.experiences[this.experiences.length - 1]["id"] ?? false;
            if (experienceId) {
                UserExperienceService.destroy(experienceId)
                .then(() => { this.experiences.pop(); })
                .catch((error) => {
                    //console.log(error)
                });
            } else { this.experiences.pop(); }
        },

        deleteEducation(){
            const educationId = this.educations[this.educations.length-1]['id'] ?? false;

            if(educationId){
                UserEducationService.destroy(educationId)
                .then(() => { this.educations.pop(); return; })
                .catch(error => {
                    //console.log(error)
                });
            } else { this.educations.pop(); }
        },

        savePersonal() {
            this.$v.personal.$touch();
            this.$v.phoneValid.$touch();
            if (this.$v.personal.$error || this.$v.phoneValid.$error) { return; }

            if (this.male) { this.personal.gender = "male" }
            else if (this.female) { this.personal.gender = "female" }
            else if (this.other) { this.personal.gender = "Other" }
            else { this.personal.gender="none" }
            Profile.saveDetails(this.personal).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Personal details saved successfully",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Error saving personal details",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },

        saveEducation() {
            this.$v.educations.$touch();
            if (this.$v.educations.$error) { return; }

            UserEducationService.save({'user_education':this.educations}).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Education details saved successfully",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Error saving education details",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },

        saveExperience() {
            this.$v.experiences.$touch();
            if (this.$v.experiences.$error) { return; }

            UserExperienceService.save({ user_experience: this.experiences }).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Experience details saved successfully",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Error saving experience details",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },

        saveKnowHow() {
            this.$v.skills.$touch();
            this.$v.abilities.$touch();
            this.$v.languages.$touch();
            if (this.$v.skills.$error || this.$v.abilities.$error || this.$v.languages.$error) { return; }

            UserKnowhowService.saveSkills(this.skills);
            UserKnowhowService.saveAbilities(this.abilities);
            UserKnowhowService.saveLanguages(this.languages).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Information saved successfully",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Error saving knowhow details",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },

        saveResume() {
            this.$v.resume.$touch();
            if (this.$v.resume.$error) { return; }

            let applicant = [];
            if (this.otherposition) { applicant.push(this.otherField); }
            else { 
                if (this.ceo) { applicant.push('ceo'); }
                if(this.cofounder) { applicant.push('cofounder'); }
            }

            CompanyService.saveApplicant({"resume":this.resume, "applicant":applicant.join()}).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Main Contact information saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Error saving resume",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },
    },

    computed: {
        search: {
            get: function () { return this.queryTerm; },

            set: function (searchInput) {

                if (this.queryTerm !== searchInput) {
                    this.queryTerm = searchInput;
                    this.loadEntries();
                }
            },
        },

        allLanguages() { return Object.values(Languages); },
        allAbilities() { return Abilities; },
        abilitiesChange() { return this.abilities; },
        skillsChange() { return this.skills; },
    },

    created() {
        Promise.all([
            Var.getAllCountry().then((res) => { this.country = res.data; }),
            Var.getAllDegrees().then((res) => { this.degrees = res.data; }),

            Profile.get().then((res) => { this.me = res.data; }),

            Profile.myDetails()
            .then((res) => {
                this.personal = res.data;
                this.phone = this.personal.phone;
                this.personal.social_network = JSON.parse(this.personal.social_network);
                if (this.personal.gender == "male") { this.male = true; }
                else if (this.personal.gender == "female") { this.female = true; }
                else if (this.personal.gender == "Other") { this.other = true; }
                else { this.no = true; }
            }),

            UserEducationService.getAll().then(res=>{ this.educations = res.data; }),

            UserExperienceService.getAll().then((res) => { this.experiences = res.data; }),

            UserKnowhowService.getSkills().then((res) => { this.skills = res.data; }),

            UserKnowhowService.getAbilities().then((res) => { this.abilities = res.data; }),

            UserKnowhowService.getLanguages().then((res) => { this.languages = res.data; }),

            CompanyService.getAll()
            .then((res) => {
                this.resume = res.data.resume;
                let applicant = res.data.applicant.split(',');
                if(applicant.includes("ceo") ) {
                    this.ceo = true;
                } 
                if(applicant.includes("cofounder")){
                    this.cofounder = true;
                }

                if(!applicant.includes("cofounder")&&!applicant.includes("ceo")) {
                    this.otherposition = true;
                    this.otherField = applicant[0];
                }
            })
        ]);
    }
}
</script>
