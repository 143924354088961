<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8">
      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-col cols="1" />

        <v-col cols="10">
          <!-- Industry -->
          <v-row no-gutters>
            <v-col cols="3">
              <p class="text-sm-left bold">Industry sectors</p>
            </v-col>

            <v-col>
              <FormInlineMessage v-if="$v.type.industry.$error" class="errmsg">
                Select industry sectors
              </FormInlineMessage>
              <v-combobox
                v-model="type.industry"
                :items="allIndustries"
                outlined
                dense
                multiple
                small-chips
                deletable-chips
                @blur="$v.type.industry.$touch()"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <!-- Stage -->
          <v-row no-gutters>
            <v-col cols="3">
              <p class="text-sm-left bold">Preferred stages of company</p>
            </v-col>

            <v-col>
              <FormInlineMessage v-if="$v.type.stage.$error" class="errmsg">
                Select investment stages
              </FormInlineMessage>
              <v-combobox
                v-model="type.stage"
                :items="allStage"
                multiple
                small-chips
                deletable-chips
                outlined
                dense
                @blur="$v.type.stage.$touch()"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row no-gutters>
            <v-col cols="3">
              <p class="text-sm-left bold">Locations invested in</p>
            </v-col>

            <v-col>
              <FormInlineMessage v-if="$v.type.location.$error" class="errmsg">
                Select investment locations
              </FormInlineMessage>
              <v-combobox
                v-model="type.location"
                :items="allLocation"
                multiple
                small-chips
                deletable-chips
                outlined
                dense
                @blur="$v.type.location.$touch()"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row class="mt-n3 mb-5">
            <v-col cols="4">
              <p class="text-sm-left">
                U.S corporate structure required for investment consideration?
              </p>
            </v-col>

            <v-col class="justify-space-between">
              <v-btn
                @click="type.usa_structure = true"
                v-bind:color="type.usa_structure ? 'primary' : ''"
                class="mr-15"
              >
                YES
              </v-btn>

              <v-btn
                @click="type.usa_structure = false"
                v-bind:color="!type.usa_structure ? 'primary' : ''"
                class="mr-15"
              >
                NO
              </v-btn>
            </v-col>
            <v-col cols="1" />
          </v-row>
        </v-col>

        <v-col cols="1" />
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-8">
      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="1" />

        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            Markets invested in
          </p>

          <v-col />

          <!-- Model -->
          <v-row no-gutters>
            <v-col cols="3">
              <p class="text-sm-left pt-2">Business Models invested in</p>
            </v-col>

            <v-col>
              <FormInlineMessage v-if="$v.type.model.$error" class="errmsg">
                Fill the field
              </FormInlineMessage>
              <v-combobox
                v-model="type.model"
                :items="allModel"
                outlined
                dense
                multiple
                small-chips
                deletable-chips
                @blur="$v.type.model.$touch()"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <!-- Location -->
          <v-row no-gutters>
            <v-col cols="3">
              <p class="text-sm-left pt-2">Market Location</p>
            </v-col>

            <v-col>
              <FormInlineMessage
                v-if="$v.type.market_location.$error"
                class="errmsg"
              >
                Fill the field
              </FormInlineMessage>
              <v-combobox
                v-model="type.market_location"
                :items="allLocation"
                outlined
                dense
                multiple
                small-chips
                deletable-chips
                @blur="$v.type.market_location.$touch()"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <!-- Size -->
          <v-row no-gutters>
            <v-col cols="3">
              <p class="text-sm-left pt-2">
                Min. Addressable Market Size<span class="font-weight-regular">
                  in Million USD</span
                >
              </p>
            </v-col>

            <v-col>
              <FormInlineMessage v-if="$v.type_size.$error" class="errmsg">
                Fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_size_length_error.error">
                  {{ is_size_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                outlined
                dense
                v-model="type_size"
                @input="type_size  = formatToString(type_size), $v.type_size.$touch()"
                @keypress="isNumber($event)"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <!-- Growth -->
          <v-row no-gutters>
            <v-col cols="2">
              <p class="text-sm-left">
                Min. Estimated market growth for the next 3 years<span
                  class="font-weight-regular"
                >
                  in %</span
                >
              </p>
            </v-col>

            <v-col cols="1"></v-col>

            <v-col >
              <FormInlineMessage v-if="$v.type_growth.$error" class="errmsg">
                Fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_type_growth_length_error.error">
                {{ is_type_growth_length_error.message }}
              </FormInlineMessage>
              <FormInlineMessage v-if="is_type_growth_decimal_length_error.error">
                {{ is_type_growth_decimal_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                outlined
                dense
                prefix="%"
                v-model="type_growth"
                @blur="$v.type_growth.$touch()"
                @keypress="isNumber($event)"
                @input="type_growth = formatToString(type_growth)"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>
        </v-col>

        <v-col cols="1" />
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveStrategy"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import AllVar from "@/store/services/var";
import InvestmentService from "@/store/services/investor/investment.service";
import FormInlineMessage from "@/components/FormInlineMessage";
import { required, minValue } from "vuelidate/lib/validators";
import { myMixins } from "@/mixins/mixins.js";

export default {
  mixins: [myMixins],
  components: {
    FormInlineMessage,
  },

  data() {
    return {
      currentBlock: true,
      allCountries: [],
      allIndustries: [],
      allStage: [],
      allModel: [],
      allLocation: [],
      allMaturity: [],
      type: {
        industry: [],
        stage: [],
        model: [],
        location: [],
        market_location: [],
        // size: "",
        // growth: "",
        usa_structure: false,
      },
      type_size: null,
      type_growth: null,
    };
  },

  validations: {
    type: {
      required,
      industry: { required },
      stage: { required },
      model: { required },
      location: { required },
      market_location: { required },
    },
    type_size: { required },
    type_growth: { required },
  },

  methods: {
    getStrategy() {
      InvestmentService.getStrategy()
        .then((res) => {
          this.type.industry=res.data.sectors
          this.type.stage=res.data.preferred_stages
          this.type.location=res.data.locations_invested
          this.type.market_location=res.data.market_locations
          this.type.usa_structure=res.data.usa_corp_structure
          this.type.model=res.data.business_models
          this.type_growth = res.data.min_market_growth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.type_size   = res.data.min_market_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        })
        .catch((e) => {
          //console.error(e);
        });
    },

    saveStrategy() {
      this.$v.type_size.$touch();
      this.$v.type.$touch();
      if (
        this.$v.type.$error ||
        this.$v.type_size.$error ||
        this.is_size_length_error.error ||
        this.is_type_growth_length_error.error ||
        this.is_type_growth_decimal_length_error.error
      ) {
        return;
      }
      this.type_size   = this.formatToInt(this.type_size);
      this.type_growth = this.formatToInt(this.type_growth);
      
      this.type.size   = this.type_size;
      this.type.growth = this.type_growth;

      InvestmentService.saveStrategy(JSON.stringify(this.type))
        .then((res) => {
          this.type_size   = this.type_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.type_growth = this.type_growth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.currentBlock = false;
          this.$emit("block2");
        })
        .catch((e) => {
          //console.error(e);
        });
    },

    getVariables() {
      let that = this;

      AllVar.getAllCountry().then((res) => {
        that.allCountries = res.data;
      });

      AllVar.getAllIndustries().then((res) => {
        that.allIndustries = res.data;
      });

      AllVar.getAllStageOfYourCompanies().then((res) => {
        that.allStage = res.data;
      });

      AllVar.getAllLocations().then((res) => {
        that.allLocation = res.data;
      });

      AllVar.getAllBusinessModel().then((res) => {
        that.allModel = res.data;
      });
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  created() {
    this.getVariables();
  },
  mounted() {
    this.getStrategy();
  },
};
</script>

<style>
.errmsg {
  color: red;
}
</style>
