<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="11">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            Investment Stage
          </p>

          <v-row no-gutters>
            <v-col cols="12">
              <v-checkbox
                @change="changeCheckboxValue('seed_stage')"
                v-model="model.seed_stage"
                :label="stages[0] && stages[0].label"
                class="mybold"
              ></v-checkbox>
            </v-col>

            <p class="text-sm-left font-weight-thin ml-8 mt-2">
              {{ stages[0] && stages[0].description }}
            </p>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <v-checkbox
                @change="changeCheckboxValue('series_a')"
                v-model="model.series_a"
                :label="stages[1] && stages[1].label"
                class="mt-2 mybold"
              ></v-checkbox>
            </v-col>
            <p class="text-sm-left font-weight-thin ml-8 mt-2">
              {{ stages[1] && stages[1].description }}
            </p>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <v-checkbox
                @change="changeCheckboxValue('series_b')"
                v-model="model.series_b"
                :label="stages[2] && stages[2].label"
                class="mt-2 mybold"
              ></v-checkbox>
            </v-col>
            <p class="text-sm-left font-weight-thin ml-8 mt-2">
              {{ stages[2] && stages[2].description }}
            </p>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <v-checkbox
                @change="changeCheckboxValue('later_stage')"
                v-model="model.later_stage"
                :label="stages[3] && stages[3].label"
                class="mt-2 mybold"
              ></v-checkbox>
            </v-col>
            <p class="text-sm-left font-weight-thin ml-8 mt-2">
              {{ stages[3] && stages[3].description }}
            </p>
          </v-row>
        </v-col>
      </v-row>
      <p></p>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveStage"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import InvestmentService from "@/store/services/investor/investment.service";
import { required } from "vuelidate/lib/validators";
import Var from "@/store/services/var";

export default {
  components: {
    FormInlineMessage,
  },

  data() {
    return {
      stages: [],
      model: {
        seed_stage: false,
        series_a: false,
        series_b: false,
        later_stage: false,
      },
      investment: {
        stage: [],
      },
      currentBlock: true,
    };
  },

  validations: {
    investment: {
      required,
      stage: { required },
    },
  },

  methods: {
    changeCheckboxValue(value) {
      if (this.investment.stage.includes(value)) {
        this.investment.stage.splice(this.investment.stage.indexOf(value), 1);
      } else {
        this.investment.stage.push(value);
      }
    },
    saveStage() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      InvestmentService.saveStage(JSON.stringify(this.investment))
        .then((res) => {
          this.currentBlock = false;
          this.$emit("block4");
        })
        .catch((e) => {
          //console.error(e);
        });
    },
    getStage() {
      InvestmentService.getStage()
        .then((res) => {
          res.data.stage.forEach((entry) => {
            if (entry == "seed_stage") {
              this.model.seed_stage = true;
            } else if (entry == "series_a") {
              this.model.series_a = true;
            } else if (entry == "series_b") {
              this.model.series_b = true;
            } else if (entry == "later_stage") {
              this.model.later_stage = true;
            }
          });
          this.investment.stage = res.data.stage;
        })
        .catch((e) => {
          //console.error(e);
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getStage();
    Var.getStages().then((res) => { this.stages = Object.values(res.data); });
  },
};
</script>

<style scss>
.mybold label {
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
  font-size: 20px;
}
</style>
