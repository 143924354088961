import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
};

function getAll(){
  return axios.get(`${url}/user/experience`, options)
}


function save(payload) {  
  return axios
    .post(`${url}/user/experience`, payload);    
}

function destroy(id) {
  return axios
    .delete(`${url}/user/experience/`+id);    
}

export default {
  save,
  getAll,
  destroy
};
