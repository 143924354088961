import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import SignUp from "../views/SignUp.vue";
import Login from "../views/Login.vue";
import Application from "@/views/Application.vue";
import InvestorApplication from "@/views/InvestorApplication.vue";
import Sponsor from "@/views/Sponsor.vue";
import ContactUs from "@/views/ContactUs.vue";
import PaymentSuccess from "@/components/PaymentSuccess";
import PageNotFound from "@/components/PageNotFound";
import PaymentError from "@/components/PaymentError";
import DashboardCompany from "@/views/Dashboard.vue";
import DashboardInvestor from "@/views/DashboardInvestor.vue";
import AdminLogin from "@/views/admin/Login.vue";
import DashboardAdminCompany from "@/views/admin/company/Dashboard.vue";
import FindInvestors from "@/views/admin/company/FindInvestors.vue";
import DashboardAdminInvestor from "@/views/admin/investor/Dashboard.vue";
import ExchangeRate from "@/views/admin/ExchangeRate.vue";
import Coupon from "@/views/admin/Coupon.vue";
import CreateCoupon from "@/views/admin/CreateCoupon.vue";
import DoneStep from "@/views/DoneStep.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter(to, from, next) {
      window.location.href = "/home";
    },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  {
    //route used exclusively for Company users
    path: "/application",
    name: "Application",
    component: Application,

    beforeEnter(to, from, next) {
      // there has to be a more elegant way of checking authorization, maybe using VueAuthenticate or global state variable instead of localstorage
      if (
        localStorage.getItem("user_role").substring(0, 1) == "c" &&
        localStorage.getItem("vue-authenticate.vueauth_access_token") != null
      ) {
        next();
      } else {
        next({
          //this.$router.push({ name: "Login", query: { authredirect: true } });
          name: "Login",
        });
      }
    },
  },

  {
    //route used exclusively for Investor users
    path: "/investor/application",
    name: "InvestorApplication",
    component: InvestorApplication,

    beforeEnter(to, from, next) {
      // there has to be a more elegant way of checking authorization, maybe using VueAuthenticate or global state variable instead of localstorage
      if (
        localStorage.getItem("user_role").substring(0, 1) == "i" &&
        localStorage.getItem("vue-authenticate.vueauth_access_token") != null
      ) {
        next();
      } else {
        next({
          name: "Login",
        });
      }
    },
  },

  {
    path: "/dashboard/company",
    name: "CompanyDashboard",
    component: DashboardCompany,

    beforeEnter(to, from, next) {
      // there has to be a more elegant way of checking authorization, maybe using VueAuthenticate or global state variable instead of localstorage
      if (
        (localStorage.getItem("user_role").substring(0, 1) == "c") &&
          localStorage.getItem("vue-authenticate.vueauth_access_token") != null
      ) {
        next();
      } else {
        next({
          name: "Login",
        });
      }
    },
  },

  {
    path: "/dashboard/investor",
    name: "DashboardInvestor",
    component: DashboardInvestor,

    beforeEnter(to, from, next) {
      // there has to be a more elegant way of checking authorization, maybe using VueAuthenticate or global state variable instead of localstorage
      if ((
        localStorage.getItem("user_role").substring(0, 1) == "i") &&
        localStorage.getItem("vue-authenticate.vueauth_access_token") != null
      ) {
        next();
      } else {
        next({
          name: "Login",
        });
      }
    },
  },

  {
    path: "/sponsor",
    name: "Sponsor",
    component: Sponsor,
    meta: {
      title: "IDEASFUNDX | Sponsor the Tool",
      metaTags: [
        {
          name: "description",
          content:
            "Raise awareness for the sponsor’s products to startups and investors.",
        },
        {
          name: "keywords",
          content:
            "Secure fundraising, Find the right investors, invest in relevant seed, series A, series B startups, Venture Capital, Match VC, screened qualified and verified dealflow",
        },
        {
          property: "og:image",
          content: "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          property: "og:image:width",
          content: "1200",
        },
        {
          property: "og:image:height",
          content: "630",
        },
        {
          property: "og:locale",
          content: "en_US",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          property: "og:description",
          content: "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the deal flow screening process for high quality deals.",
        },
        {
          property: "og:url",
          content: "https://www.ideasfundx.com/",
        },
        {
          property: "og:site_name",
          content: "IdeasFundX",
        },
        {
          name: "twitter:card",
          content:
            "summary_large_image",
        },
        {
          name: "twitter:title",
          content:
            "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          name: "twitter:description",
          content:
            "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the dealflow screening process for high quality deals.",
        },
        {
          name: "twitter:image",
          content:
            "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          name: "twitter:image:width",
          content:
            "1200",
        },
        {
          name: "twitter:image:height",
          content:
            "630",
        },
      ],
    },
  },

  {
    path: "/contact",
    name: "ContactUs",
    component: ContactUs,
    meta: {
      title: "IDEASFUNDX | Sponsor the Tool",
      metaTags: [
        {
          name: "description",
          content:
            "Raise awareness for the sponsor’s products to startups and investors.",
        },
        {
          name: "keywords",
          content:
            "Secure fundraising, Find the right investors, invest in relevant seed, series A, series B startups, Venture Capital, Match VC, screened qualified and verified dealflow",
        },
        {
          property: "og:image",
          content: "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          property: "og:image:width",
          content: "1200",
        },
        {
          property: "og:image:height",
          content: "630",
        },
        {
          property: "og:locale",
          content: "en_US",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          property: "og:description",
          content: "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the deal flow screening process for high quality deals.",
        },
        {
          property: "og:url",
          content: "https://www.ideasfundx.com/",
        },
        {
          property: "og:site_name",
          content: "IdeasFundX",
        },
        {
          name: "twitter:card",
          content:
            "summary_large_image",
        },
        {
          name: "twitter:title",
          content:
            "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          name: "twitter:description",
          content:
            "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the dealflow screening process for high quality deals.",
        },
        {
          name: "twitter:image",
          content:
            "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          name: "twitter:image:width",
          content:
            "1200",
        },
        {
          name: "twitter:image:height",
          content:
            "630",
        },
      ],
    },
  },

  {
    //soon to be obsolete route, will be replaced by url queries in /dashboard callback url
    path: "/paymentsuccess",
    name: "Paypal Success",
    component: PaymentSuccess,

    beforeEnter(to, from, next) {
      // there has to be a more elegant way of checking authorization, maybe using VueAuthenticate or global state variable instead of localstorage
      if (
        localStorage.getItem("vue-authenticate.vueauth_access_token") != null
      ) {
        next();
      } else {
        next({
          name: "Login",
        });
      }
    },
  },

  {
    //soon to be obsolete route, will be replaced by url queries in /dashboard callback url
    path: "/paymenterror",
    name: "Paypal Error",
    component: PaymentError,

    beforeEnter(to, from, next) {
      // there has to be a more elegant way of checking authorization, maybe using VueAuthenticate or global state variable instead of localstorage
      if (
        localStorage.getItem("vue-authenticate.vueauth_access_token") != null
      ) {
        next();
      } else {
        next({
          name: "Login",
        });
      }
    },
  },

  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/products",
    name: "Products",
    component: () => import(/* webpackChunkName: "products" */ "../views/Products.vue"),
    meta: {
      title: "IDEASFUNDX | Our other products and services for entrepreneurs",
      metaTags: [
        {
          name: "description",
          content:
            "Advices for your Pitch Deck, Fundraising and Funding.",
        },
        {
          name: "keywords",
          content:
            "secure fundraising, improve pitch deck, business plan, go-to-market strategy, funding, find cofounders for your startup"
        },
        {
          property: "og:image",
          content: "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          property: "og:image:width",
          content: "1200",
        },
        {
          property: "og:image:height",
          content: "630",
        },
        {
          property: "og:locale",
          content: "en_US",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          property: "og:description",
          content: "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the deal flow screening process for high quality deals.",
        },
        {
          property: "og:url",
          content: "https://www.ideasfundx.com/",
        },
        {
          property: "og:site_name",
          content: "IdeasFundX",
        },
        {
          name: "twitter:card",
          content:
            "summary_large_image",
        },
        {
          name: "twitter:title",
          content:
            "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          name: "twitter:description",
          content:
            "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the dealflow screening process for high quality deals.",
        },
        {
          name: "twitter:image",
          content:
            "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          name: "twitter:image:width",
          content:
            "1200",
        },
        {
          name: "twitter:image:height",
          content:
            "630",
        },
      ],
    },
  },

  {
    path: "/products/pitch-deck",
    name: "PitchDeck",
    component: () => import(/* webpackChunkName: "pitch-deck" */ "../views/products/PitchDeck.vue"),
    meta: {
      title: "IDEASFUNDX | Get Feedback for your Pitch Deck",
      metaTags: [
        {
          name: "description",
          content:
            "Advices to Make your Pitch Deck legible, simple and obvious.",
        },
        {
          name: "keywords",
          content:
            "secure fundraising, improve pitch deck, review business plan, define go-to-market strategy"
        },
        {
          property: "og:image",
          content: "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          property: "og:image:width",
          content: "1200",
        },
        {
          property: "og:image:height",
          content: "630",
        },
        {
          property: "og:locale",
          content: "en_US",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          property: "og:description",
          content: "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the deal flow screening process for high quality deals.",
        },
        {
          property: "og:url",
          content: "https://www.ideasfundx.com/",
        },
        {
          property: "og:site_name",
          content: "IdeasFundX",
        },
        {
          name: "twitter:card",
          content:
            "summary_large_image",
        },
        {
          name: "twitter:title",
          content:
            "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          name: "twitter:description",
          content:
            "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the dealflow screening process for high quality deals.",
        },
        {
          name: "twitter:image",
          content:
            "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          name: "twitter:image:width",
          content:
            "1200",
        },
        {
          name: "twitter:image:height",
          content:
            "630",
        },
      ],
    },
  },

  {
    path: "/products/consultancy",
    name: "Consultancy",
    component: () =>
    import(/* webpackChunkName: "pitch-deck" */ "../views/products/Consultancy.vue"),
    meta: {
      title: "IDEASFUNDX | Consult our experts for business success",
      metaTags: [
        {
          name: "description",
          content:
            "Consult our experts about your business and strategy.",
        },
        {
          name: "keywords",
          content:
            "secure fundraising, define your strategy, improve your business plan, organize your governance"
        },
        {
          property: "og:image",
          content: "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          property: "og:image:width",
          content: "1200",
        },
        {
          property: "og:image:height",
          content: "630",
        },
        {
          property: "og:locale",
          content: "en_US",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          property: "og:description",
          content: "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the deal flow screening process for high quality deals.",
        },
        {
          property: "og:url",
          content: "https://www.ideasfundx.com/",
        },
        {
          property: "og:site_name",
          content: "IdeasFundX",
        },
        {
          name: "twitter:card",
          content:
            "summary_large_image",
        },
        {
          name: "twitter:title",
          content:
            "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          name: "twitter:description",
          content:
            "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the dealflow screening process for high quality deals.",
        },
        {
          name: "twitter:image",
          content:
            "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          name: "twitter:image:width",
          content:
            "1200",
        },
        {
          name: "twitter:image:height",
          content:
            "630",
        },
      ],
    },
  },

  {
    path: "/admin",
    name: "AdminLogin",
    component: AdminLogin,
  },

  {
    path: "/admin/company",
    name: "DashboardAdminCompany",
    component: DashboardAdminCompany,
  },

  {
    path: "/admin/company/find-investors",
    name: "FindInvestors",
    component: FindInvestors,
  },

  {
    path: "/admin/investor",
    name: "DashboardAdminInvestor",
    component: DashboardAdminInvestor,
  },

  {
    path: "/admin/exchange-rate",
    name: "ExchangeRate",
    component: ExchangeRate,
  },

  {
    path: "/admin/coupon-list",
    name: "Coupon",
    component: Coupon,
  },

  {
    path: "/admin/create-coupon",
    name: "CreateCoupon",
    component: CreateCoupon
  },

  {
    path: "/admin/update-coupon/:id",
    name: "UpdateCoupon",
    component: CreateCoupon
  },

  {
    path: "/donesteps/:type",
    name: "DoneStep",
    component: DoneStep,
  },
  
  { path: "/:catchAll(.*)", name: "404", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
