<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            How much do you want to raise ?
          </p>
          <p class="text-sm-left">
            Total amount you are raising for this round (in US dollars)*<br />
            <span
              class="text-sm-left blue-grey--text text--lighten-2 font-weight-thin"
              >For example, enter "1000000" for $1,000,000</span
            >
          </p>

          <v-row justify="start">
            <v-col cols="6">
              <FormInlineMessage v-if="$v.fund_raising.$error" class="errmsg">
                  Please fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_fund_raising_amount_length_error.error" class="errmsg">
                  {{ is_fund_raising_amount_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                v-model="fund_raising"
                outlined

                dense
                @input="fund_raising = formatToString(fund_raising)"
                @keypress="isNumber($event)"
              />
            </v-col>
          </v-row>

          <p class="text-sm-left">
            Total amount still needed for this round (in US dollars)*<br />
            <span
              class="text-sm-left blue-grey--text text--lighten-2 font-weight-thin"
              >For example, enter "500000" for $500,000</span
            >
          </p>

          <v-row justify="start">
            <v-col cols="6">
              <FormInlineMessage v-if="$v.fund_needed.$error" class="errmsg">
                  Please fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_fund_needed_length_error.error" class="errmsg">
                  {{ is_fund_needed_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                v-model="fund_needed"
                outlined
                dense
                @input="fund_needed  = formatToString(fund_needed)"
                @keypress="isNumber($event)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            When are you aiming to close this round of financing* ?
          </p>
          <p
            class="text-sm-left blue-grey--text text--lighten-2 font-weight-thin"
          >
            Your target fundraise close date should be at least 30 days in the
            future
          </p>

          <v-row justify="start">
            <v-col sm="auto">
              <v-menu
            ref="dob"
            :close-on-content-click="false"
            :return-value.sync="fund.date"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                height="40"
                outlined
                v-model="fund.date"
                placeholder="mm/dd/yyyy"
                readonly
                v-bind="attrs"
                v-on="on"
                @blur="$v.fund.date.$touch()"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fund.date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.dob.isActive = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dob.save(fund.date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            How do you plan to use this money, for what needs* ?
          </p>
          <p class="text-sm-left">
            Description of your need(s) and how you will allocate the new
            funds<br />
            <span
              class="text-sm-left blue-grey--text text--lighten-2 font-weight-thin"
            >
              Describe the purpose of your fund raising and what it will allow
              you to achieve that you couldn't without it<br />
              (from 100 to 1500 characters)
            </span>
          </p>

          <v-row justify="start">
            <v-col cols="11">
              <v-textarea
                v-model="fund.describe"
                outlined
                dense
                rows="10"
                :counter="1500"
                placeholder=""
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveFund"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import NeedService from "@/store/services/need.service";
import FormInlineMessage from "@/components/FormInlineMessage";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import Status from "@/store/services/status.service";
import { myMixins } from "@/mixins/mixins.js";

export default {
  mixins: [myMixins],
  components: {
    FormInlineMessage,
  },

  props: {
    company_id: Number,
  },

  data() {
    return {
      currentBlock: true,
      fund: {
        date: "",
        describe: "",
      },
      fund_raising: null,
      fund_needed: null,
    };
  },

  validations: {
    fund: {
      required,
      date: { required },
      describe: {
        required,
        minLength: minLength(100),
        maxLength: maxLength(1500),
      },
    },
    fund_raising: { required },
    fund_needed: { required }
  },

  methods: {
    saveFund() {
      this.$v.$touch();
      if (
        this.$v.fund.$error ||
        this.$v.fund_needed.$error ||
        this.$v.fund_raising.$error ||
        this.is_fund_raising_amount_length_error.error ||
        this.is_fund_needed_length_error.error
      ) {
        return this.$toast.open({
          message: "Please fill all required fields or correct numbers or amounts having more than 15 digits before saving.",
          type: "error",
          position: "top-right",
        });
      }
      this.fund_raising = this.formatToInt(this.fund_raising);
      this.fund_needed  = this.formatToInt(this.fund_needed);

      this.fund.raising = this.fund_raising;
      this.fund.needed  = this.fund_needed;

      NeedService.saveFund({ company_id: this.company_id, fund: this.fund });

      this.fund_raising = this.fund_raising.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.fund_needed  = this.fund_needed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.currentBlock = false;
      this.$emit("block2");
    },
    getFund(company_id) {
      NeedService.getFund(company_id)
        .then((res) => {
          if (res.data) {
            this.fund = res.data;
            this.fund_raising = this.fund.raising.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.fund_needed  = this.fund.needed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        })
        .catch((e) => {});
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  mounted() {
    Status.readAll()
      .then((res) => {
        if (res.data) {
          this.getFund(res.data.active_company_id);
        }
    })
  },
};
</script>
