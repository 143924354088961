<template>
    <div class="mar-top-100">
        <LoaderPop v-if="loaderAlert" type="loading"/>
        <v-row class="accountStatus">
          <!-- Header -->
            <div class="mb-7 header">
               <h1 class="text-light mt-5">INVESTOR PROFILE CONFIRMED</h1>
               <hr>
            </div>
            <v-col cols="11" sm="8">
                <p>
                    You’re ready to receive recommended qualified deals
                </p>
                <p class="mt-7">
                    <strong>Subscribe to the IO Newsletter</strong>
                </p>
            </v-col>
            <v-col cols="11" sm="8" class="text-left">
                <p class="puce-content">
                    <img src="../../../assets/img/puce.png" alt="Puce"> Access to highly qualified deals matching your investment strategy and investor profile
                </p>
                <p class="puce-content">
                    <img src="../../../assets/img/puce.png" alt="Puce"> Gain efficiency and put in less effort to understand the activity and the potential of the companies 
                    thanks to a higher quality of information provided by companies
                </p>
                <p class="puce-content">
                    <img src="../../../assets/img/puce.png" alt="Puce"> Diversify the investment portfolio for a better risk mitigation
                </p>
                <p class="puce-content">
                    <img src="../../../assets/img/puce.png" alt="Puce"> Limit bias in the investment decision-making process and improve portfolio profitability 
                </p>
                <p class="puce-content">
                    <img src="../../../assets/img/puce.png" alt="Puce"> Reduce the risk of missing deals and stand out from other VCs
                </p><br>
            </v-col>
            <v-col cols="11" sm="8">
                <p class="text-left">
                    We preserve confidentiality 
                </p><br>
                <p class="text-left">
                    If you are interested in a deal, you sign an NDA to receive all information provided by a company.
                    It’s up to you to contact the company to start the discussion and the negotiation.
                </p>
                <p class="text-left">
                    IdeasFundX takes a success fee of 4% of the investment in the deals which IdeasFundX has recommended to investors
                </p>
                <img src="../../../assets/img/Special.png" alt="Special" class="mt-10">
            </v-col>
            <v-col cols="11" sm="4" class="mt-8">
                For the launch, IdeasFundX offers you a 6 months free subscription. To continue your subscription, you will then pay a Yearly Membership fee of {{ inEEC(countryBase) ?  Math.floor(productPrice * change * 100) / 100 + ' €' : `$ ${productPrice}`}} excl. tax 
            </v-col>
            <div class="mt-5">
                <v-btn
                    sm="5"
                    id="btn-photo"
                    class="white--text"
                    color="#58407c"
                    height="90"
                    width="200"
                    rounded
                    block
                    @click="subscribeNewsletter()"
                    :disabled="false">
                    SUBSCRIBE
                </v-btn>
            </div><br>
            <v-col cols="11" sm="4" class="mb-n7">
                <p class="blue-grey--text text--lighten-2 font-weight-thin">Be aware of new investment opportunities that have passed the qualification</p>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import Profile from "@/store/services/profile-service";
    import Investor from "@/store/services/admin/investor";
    import AccreditationService from "@/store/services/investor/accreditation.service";
    import LoaderPop from "@/components/LoaderPop.vue";
    import Var from '@/store/services/var.js';

    export default { 
        components : { 
            LoaderPop
        },
        data() {
            return {
                user_id: null,
                loaderAlert: false,
                user: {
                    id : null
                },
                countryBase: null,
                change: 0,
                productPrice: 0,
                EEC: [
                    "Austria",
                    "Belgium",
                    "Bulgaria",
                    "Croatia",
                    "Cyprus",
                    "Czech Republic",
                    "Denmark",
                    "Estonia",
                    "Finland",
                    "France",
                    "France, Metropolitan",
                    "Germany",
                    "Greece",
                    "Hungary",
                    "Ireland",
                    "Italy",
                    "Latvia",
                    "Lithuania",
                    "Luxembourg",
                    "Malta",
                    "Netherlands",
                    "Poland",
                    "Portugal",
                    "Romania",
                    "Slovakia",
                    "Slovenia",
                    "Spain",
                    "Sweden",
                ],
            }
        },
        mounted() {
            Profile.get().then((res) => { this.user.id = res.data.id });
            AccreditationService.getProfile().then((res) => { this.countryBase = res.data.country; });
            Var.getRate().then((res) => { this.change = res.data.final_rate; })
            Var.getProduct(4).then((res) => { this.productPrice = res.data.product_price; }); // 4 => 'Investor Newsletter' id in the table var_products
        },
        methods: {
            inEEC (country) {
                return this.EEC.includes(country);
            },
            subscribeNewsletter() {
                this.loaderAlert = true;
                Investor.subscribeNewsletter(this.user, false).then(res=>{
                    if(res.status == 200){
                        this.loaderAlert = false;
                        this.$fire({
                            title: "Subscription to the IO Newsletter",
                            text: res.data.message,
                            type: res.data.type,
                            timer: 4000
                        }).then(r => {
                            // console.log(r.value);
                        });
                        this.$emit("forceUpdate");
                    }
                    this.loaderAlert = false;
                }).catch((e)=>{
                    this.loaderAlert = false;
                });
            }
        }
    }
</script>