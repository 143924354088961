import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

axios.defaults.headers.post["Content-Type"] = "application/vnd.api+json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token");
axios.defaults.headers.common["Accept"] = "application/vnd.api+json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET, POST, OPTIONS, DELETE, PUT, PATCH";
axios.defaults.headers.common["Access-Control-Allow-Headers"] =
  "Cache-Control, Content-Language, Content-Type, Expires, Last-Modified, Pragma";
axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";

function store(payload) {
  return axios.post(url + "/company/address", payload);
}

function read(company_id) {
  return axios.get(url + `/company/address/${company_id}`);
}

export default {
  store,
  read
};
