import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

axios.defaults.headers.post["Content-Type"] = "application/vnd.api+json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token");
axios.defaults.headers.common["Accept"] = "application/vnd.api+json";

function savePolicies(payload) {
  return axios.post(`${url}/team/policies`, payload);
}

function getPolicies(company_id) {
  return axios.get(`${url}/team/policies/${company_id}`);
}

function saveTeam(payload) {
    return axios.post(`${url}/team/team`, payload);
}

function getTeam(company_id) {
  return axios.get(`${url}/team/team/${company_id}`);
}

function saveFounders(payload) {
  return axios.post(`${url}/team/founders`, payload);
}

function getFounders(company_id) {
  return axios.get(url + `/team/founders/${company_id}`);
}

function isFounder(company_id) {
  return axios.get(url + "/team/isfounder/" + company_id);
}

function destroy(id) {
  return axios.delete(`${url}/team/founder/${id}`);
}

export default {
  savePolicies,
  getPolicies,
  saveTeam,
  getTeam,
  saveFounders,
  getFounders,
  isFounder,
  destroy
};
