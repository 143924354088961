<template>
  <div class="mar-top-100">
    <v-col/>
    <v-row align="center" justify="center">
      <v-col cols="0" md="1"/>
      <v-col cols="11" md="11">
        <p class="cyan--text text--darken-1 text-sm-left" id="title">The Founding Team</p>
        <div v-for="(founder, index) in listFounder" :key="founder.name">
          <p class="text-sm-left">Cofounder {{index + 1}} - {{founder.name}}</p>
          <Founder
          ref="founder"
          v-bind:founderProp="founder"
          class="mb-16"/>
        </div>

        <div v-for="index in nb" :key="index">
          <p class="text-sm-left">Cofounder {{index + listFounder.length}}</p>
          <NewFounder
          ref="newfounder"
          v-bind:founderProp="founder"
          class="mb-16"/>
        </div>

        <v-row class="mb-10 mt-n30">
          <v-col cols="12" sm="2">
            <v-btn
            id="btn-add"
            text
            color="#00aac3"
            @click.prevent.stop="addFounder()">
              + Add Founder
            </v-btn>
          </v-col>
          <v-col cols="0" sm="2"/>
          <v-col cols="12" sm="2" v-if="nb > 0">
            <v-btn
            id="btn-add"
            text
            color="#00aac3"
            @click.prevent.stop="deleteFounder()">
              - Remove Founder
            </v-btn>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="pt-2 mb-4">
          <v-btn
            v-if="listFounder.length > 0"
            id="btn-photo"
            class="white--text mb-3"
            style="font-weight: bold; font-size: 14px"
            color="#11aec9"
            min-width="100px"
            width="15%"
            rounded
            :disabled="false"
            @click.stop.prevent="saveFounder()">
            save
          </v-btn>
          <v-col cols="0" sm="4"/>
        </v-row>

    <p class="cyan--text text--darken-1 text-sm-left" id="title">The Team</p>

    <v-row>
        <v-col cols="12">
          <p class="text-sm-left">Does your startup have a female founder ?<br>
            <span class="blue-grey--text text--lighten-3 font-weight-thin">Feel free to answer this question</span>
          </p>
        </v-col>

        <v-row align="center" justify="center">
          <v-col cols="3" sm="2" class="mt-n5">
            <v-btn
            @click="policies.female = true; not_female = false;"
            v-bind:color="policies.female ? 'primary' : ''">
              YES
            </v-btn>
          </v-col>

          <v-col cols="3" sm="2" class="mt-n5">
            <v-btn
            @click="policies.female = false; not_female = true;"
            v-bind:color="not_female ? 'primary' : ''">
              NO
            </v-btn>
          </v-col>
          
          <v-col cols="0" sm="8"/>
        </v-row>
    </v-row>

    <v-row align="center" justify="center">
        <v-col cols="12">
          <p class="text-sm-left">Does your startup have a founder of color ?<br>
            <span class="blue-grey--text text--lighten-3 font-weight-thin">Feel free to answer this question</span>
          </p>
        </v-col>

        <v-row align="center" justify="center">
          <v-col cols="3" sm="2" class="mt-n5">
            <v-btn
            @click="policies.color = true; not_color = false;"
            v-bind:color="policies.color ? 'primary' : ''">
              YES
            </v-btn>
          </v-col>

          <v-col cols="3" sm="2" class="mt-n5">
            <v-btn
            @click="policies.color = false; not_color = true;"
            v-bind:color="not_color ? 'primary' : ''">
              NO
            </v-btn>
          </v-col>
          
          <v-col cols="0" sm="8"/>
        </v-row>
    </v-row>

    <v-row align="center" justify="center" class="mb-7">
        <v-col cols="12">
          <p class="text-sm-left">Does your startup have a LGBTQ founder ?<br>
            <span class="blue-grey--text text--lighten-3 font-weight-thin">Feel free to answer this question</span>
          </p>
        </v-col>

        <v-row align="center" justify="center">
          <v-col cols="3" sm="2" class="mt-n5">
            <v-btn
            @click="policies.lgbtq = true; not_lgbtq = false;"
            v-bind:color="policies.lgbtq ? 'primary' : ''">
              YES
            </v-btn>
          </v-col>

          <v-col cols="3" sm="2" class="mt-n5">

            <v-btn
            @click="policies.lgbtq = false; not_lgbtq = true;"
            v-bind:color="not_lgbtq ? 'primary' : ''">
              NO
            </v-btn>
          </v-col>
          
          <v-col cols="0" sm="8"/>
        </v-row>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12">
          <p class="thin text-sm-left">How many staff work at your company ?</p>
      </v-col>

      <v-col cols="8">
         <FormInlineMessage v-if="$v.team.number.$error">
            How many are you ?
          </FormInlineMessage>
          <v-text-field
          v-model="team.number"
          outlined
          dense
          placeholder="100"
          @keypress="isNumber($event)"/>
      </v-col>

      <v-col cols="0" sm="4"/>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="8">
          <p class="text-sm-left">Briefly describe why your team is the right team to make your
            startup successful (e.g., technical/industry expertise, existing
            relationships with potential customers/partners).</p>
      </v-col>
      <v-col cols="0" sm="4"/>
      <v-col cols="8">
        <FormInlineMessage v-if="$v.team.describe.$error" class="errmsg">
          Could you describe why your team is the right team
        </FormInlineMessage>
        <v-textarea v-model="team.describe" rows="4" outlined dense />
      </v-col>

      <v-col cols="0" sm="4"/>
    </v-row>

    <v-row align="center" justify="center" class="pt-2 mb-10">
      <v-btn
        id="btn-photo"
        class="white--text mb-6"
        style="font-weight: bold; font-size: 14px"
        color="#11aec9"
        min-width="100px"
        width="15%"
        rounded
        :disabled="false"
        @click.stop.prevent="save()">
        save
      </v-btn>
      <v-col cols="0" sm="4"/>
    </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Founder from "@/components/Dashboard/Founder.vue";
import NewFounder from "@/components/Dashboard/NewFounder.vue";
import FormInlineMessage from "@/components/FormInlineMessage";
import TeamsService from "@/store/services/teams.service";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Founder,
    NewFounder,
    FormInlineMessage,
  },

  props: {
    company_id: Number,
  },

  data() {
    return {
      listFounder: [],
      nb: 0,
      not_female: false,
      not_color: false,
      not_lgbtq: false,
      policies: {
        female: null,
        color: null,
        lgbtq: null,
      },
      team: {
        number: null,
        describe: null,
      }
    }
  },

  validations: {
    team: {
      number: { required, },
      describe: { required, },
    }
  },

  methods: {
    addFounder() {
      this.nb += 1;
    },

    deleteFounder() {
      this.nb -= 1;
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    saveFounder() {
      this.$v.$touch();
      if (this.$v.$error) { return; };

      let nb = 0;
      this.listFounder.forEach((founder, index) => {
        nb += 1;
        this.$refs.founder[index].save(this.company_id);
      });

      for (let x = 0; x < this.nb; x++) {
        this.$refs.newfounder[x].save(this.company_id);
      }
    },

    save() {
      TeamsService.savePolicies(this.policies);
      TeamsService.saveTeam(this.team).then((response) => {
        if(response.status == 200) {
          this.$toast.open({
            message: "Team Information saved successfully !",
            type: 'success',
            position: 'top-right'
          });
        } else {
          this.$toast.open({
            message: "An error occured",
            type: 'error',
            position: 'top-right'
          });
        }
      });
    }
  },

  created() {
    Promise.all([
      this.policies.id = this.company_id,
      this.team.company_id = this.company_id,

      TeamsService.getPolicies(this.company_id).then((res) => {
        if (res.data.female != null) { this.policies.female = res.data.female; this.not_female = !res.data.female; }
        if (res.data.lgbtq != null) { this.policies.lgbtq = res.data.lgbtq; this.not_lgbtq = !res.data.lgbtq; }
        if (res.data.color != null) { this.policies.color = res.data.color; this.not_color = !res.data.color; }
      }),

      TeamsService.getTeam(this.company_id).then((res) => {
        this.team.number = res.data.number;
        this.team.describe = res.data.describe;
      }),

      TeamsService.getFounders(this.company_id).then((res) => {
        res.data.forEach((founder, index) => {
          this.listFounder.push({});
          this.listFounder[index].name = founder[0];
          this.listFounder[index].country = founder[1];
          this.listFounder[index].birth = founder[2];
          this.listFounder[index].equity = founder[3];
          this.listFounder[index].expertise = founder[4];
          this.listFounder[index].years = founder[5];
          this.listFounder[index].formers = [];
          this.listFounder[index].schools = [];
          founder[6].forEach((element) => {
            this.listFounder[index].formers.push({ company: element.company, position: element.position, });
          });
          founder[7].forEach((element) => {
            this.listFounder[index].schools.push({ school: element.school, degree: element.degree, });
          });
          this.listFounder[index].linkedin = founder[8];
          this.listFounder[index].describe = founder[9];
          this.listFounder[index].photoLink = founder[10];
          });
        }),
    ])
  }
}
</script>
