<template>
    <div class="mar-top-100">
        <v-row>
            <v-col cols="1" md="1"/>
            <v-col cols="10" md="10">
                <!-- First part of step 4 -->
                <p class="cyan--text text--darken-1 text-sm-left mt-5" id="title">The type of business</p>

                <!-- Industry -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.type.industry.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Industry sector</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.type.industry.$error">
                            Select your industry's sector
                        </FormInlineMessage>
                        <v-combobox
                        v-model="type.industry"
                        :items="allIndustries"
                        outlined
                        dense
                        small-chips
                        multiple
                        deletable-chips/>
                    </v-col>
                </v-row>

                <!-- Stage -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.type.stage.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Stage of the company</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.type.stage.$error">
                            Select the stage of your compagny
                        </FormInlineMessage>
                        <v-select
                        v-model="type.stage"
                        :items="allStage"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <p class="cyan--text text--darken-1 text-sm-left mt-8" id="title">About the market</p>

                <!-- Model -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.type.model.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Business Model</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.type.model.$error">
                            Fill the field
                        </FormInlineMessage>
                        <v-select
                        v-model="type.model"
                        :items="allModel"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Location -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.type.location.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Market Location</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.type.location.$error">
                            Fill the field
                        </FormInlineMessage>
                        <v-combobox
                            v-model="type.location"
                            :items="allLocation"
                            outlined
                            dense
                            multiple
                            small-chips
                            deletable-chips
                            @blur="$v.type.location.$touch()"
                        />
                    </v-col>
                </v-row>

                <!-- Maturity -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.type.maturity.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Stage of Market Maturity</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.type.maturity.$error">
                            Fill the field
                        </FormInlineMessage>
                        <v-select
                        v-model="type.maturity"
                        :items="allMaturity"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Size -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.type_size.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">
                            Addressable Market Size<span class="font-weight-regular">
                            in Million USD</span
                            >
                        </p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.type_size.$error">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_size_length_error.error">
                            {{ is_size_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        prefix="$"
                        v-model="type_size"
                        outlined
                        dense
                        @input="type_size = formatToString(type_size)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <!-- Growth -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.type_growth.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Estimated market growth for the next 3 years</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.type_growth.$error">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_type_growth_length_error.error">
                            {{ is_type_growth_length_error.message }}
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_type_growth_decimal_length_error.error">
                            {{ is_type_growth_decimal_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        prefix="%"
                        v-model="type_growth"
                        outlined
                        dense
                        @input="type_growth = formatToString(type_growth)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <!-- Solve -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.type.solve.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">The need that your product or service is solving</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.type.solve.$error">
                            Fill the field
                        </FormInlineMessage>
                        <v-textarea
                        rows="2"
                        v-model="type.solve"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Competitors -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.type.competitors.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Who are the competitors ?</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.type.competitors.$error">
                            Fill the field
                        </FormInlineMessage>
                        <v-textarea
                        rows="2"
                        v-model="type.competitors"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Barriers -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.type.barriers.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">What are the entry barriers ?</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.type.barriers.$error">
                            Fill the field
                        </FormInlineMessage>
                        <v-textarea
                        rows="2"
                        v-model="type.barriers"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Save Market button  -->
                <v-row align="center" justify="center" class="pt-2">
                    <v-col cols="0" sm="9"/>
                    <v-col cols="12" sm="1">
                        <v-btn
                        id="btn-photo"
                        class="white--text mb-3"
                        style="font-weight: bold; font-size: 14px"
                        color="#11aec9"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.stop.prevent="saveMarket(false)">
                        save
                        </v-btn>
                    </v-col>
                    <v-col cols="0" sm="2"/>
                </v-row>

                <!-- Second part of step 4 -->
                <p class="cyan--text text--darken-1 text-sm-left mt-8" id="title">About the product</p>

                <!-- Service Offer -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.product.service_offer.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Primary product/service you offer</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.product.service_offer.$error">
                            Select your primary product/service
                        </FormInlineMessage>
                        <v-select
                        v-model="product.service_offer"
                        :items="allPrimary"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Service Offer Other -->
                <v-row class="mb-n8" v-if="product.service_offer === 'other'">
                    <v-col cols="0" sm="2"/>
                    <v-col cols="12" sm="6">
                        <v-text-field
                        v-model="product.service_offer_other"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Service Does -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="12">
                        <FormInlineMessage v-if="$v.product.service_does.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">What your product/service does ?</p>
                    </v-col>
                    <v-col cols="12" sm="10" class="mt-n5">
                        <FormInlineMessage v-if="$v.product.service_does.$error">
                            Select your primary product/service
                        </FormInlineMessage>
                        <v-textarea
                        rows="2"
                        v-model="product.service_does"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Technology Focus -->
                <v-row>
                    <v-col cols="12" sm="3">
                        <p class="text-sm-left">Technology focus</p>
                    </v-col>

                    <v-col cols="6" sm="3">
                        <v-btn
                        @click="product.technology_focus = true; no_technology_focus = false;"
                        v-bind:color="product.technology_focus ? 'primary' : ''">
                            YES
                        </v-btn>
                    </v-col>
                    <v-col cols="6" sm="3">
                        <v-btn
                        @click="product.technology_focus = false; no_technology_focus = true; product.technology=''"
                        v-bind:color="no_technology_focus ? 'primary' : ''">
                            NO
                        </v-btn>
                    </v-col>
                </v-row>

                <!-- Technology -->
                <v-row class="mb-n8" v-if="product.technology_focus">
                    <v-col cols="12" sm="12">
                        <p class="text-sm-left">Which Technology ?</p>
                    </v-col>
                    <v-col cols="12" sm="10" class="mt-n5">
                        <v-textarea
                        rows="2"
                        v-model="product.technology"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- R&D -->
                <v-row>
                    <v-col cols="12" sm="3">
                        <p class="text-sm-left">With R&D</p>
                    </v-col>

                    <v-col cols="6" sm="3">
                        <v-btn
                        @click="product.rd = true; no_rd = false;"
                        v-bind:color="product.rd ? 'primary' : ''">
                            YES
                        </v-btn>
                    </v-col>
                    <v-col cols="6" sm="3">
                        <v-btn
                        @click="product.rd = false; no_rd = true;"
                        v-bind:color="no_rd ? 'primary' : ''">
                            NO
                        </v-btn>
                    </v-col>
                </v-row>

                <!-- Exclusive Technology -->
                <v-row>
                    <v-col cols="12">
                        <p class="text-sm-left">Do you have any exclusive/proprietary rights or patents on the technology ?</p>
                    </v-col>

                    <v-col cols="0" sm="3"/>

                    <v-col cols="6" sm="3" class="mt-n5">
                        <v-btn
                        @click="product.exclusive_technology = true; no_exclusive_technology = false;"
                        v-bind:color="product.exclusive_technology ? 'primary' : ''">
                            YES
                        </v-btn>
                    </v-col>
                    <v-col cols="6" sm="3" class="mt-n5">
                        <v-btn
                        @click="product.exclusive_technology = false; no_exclusive_technology = true; product.exclusive_technology_resume=''"
                        v-bind:color="no_exclusive_technology ? 'primary' : ''">
                            NO
                        </v-btn>
                    </v-col>
                </v-row>

                <!-- Esclusive Technology Resume -->
                <v-row v-if="product.exclusive_technology">
                    <v-col cols="12" sm="10">
                        <v-textarea
                        rows="2"
                        v-model="product.exclusive_technology_resume"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Life Cycle -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.product.life_cycle.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Product Life Cycle Stage</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.product.life_cycle.$error">
                            Select your product life cycle stage
                        </FormInlineMessage>
                        <v-select
                        v-model="product.life_cycle"
                        :items="allLifeCycle"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Unique -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="10" md="10" lg="2">
                        <FormInlineMessage v-if="$v.product.unique.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">What make it unique ?</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="8">
                        <FormInlineMessage v-if="$v.product.unique.$error">
                            Select your difference
                        </FormInlineMessage>
                        <v-select
                        v-model="product.unique"
                        :items="allDiff"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Advantage -->
                <v-row class="mb-n8">
                    <v-col cols="12">
                        <FormInlineMessage v-if="$v.product.advantage.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">What are the competitive advantages ?</p>
                    </v-col>
                    <v-col cols="12" sm="10">
                        <FormInlineMessage v-if="$v.product.advantage.$error">
                            Fill the field
                        </FormInlineMessage>
                        <v-textarea
                        rows="2"
                        v-model="product.advantage"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Save Product button -->
                <v-row align="center" justify="center" class="pt-2">
                    <v-col cols="0" sm="9"/>
                    <v-col cols="12" sm="1">
                        <v-btn
                        id="btn-photo"
                        class="white--text mb-3"
                        style="font-weight: bold; font-size: 14px"
                        color="#11aec9"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.stop.prevent="saveProduct()">
                        save
                        </v-btn>
                    </v-col>
                    <v-col cols="0" sm="2"/>
                </v-row>

                <!-- Third part of step 4 -->
                <p class="cyan--text text--darken-1 text-sm-left mt-8" id="title">Go-To-Market Strategy</p>

                <!-- Existing clients -->
                <v-row class="mb-n8">
                    <v-col cols="12">
                        <FormInlineMessage v-if="$v.strategy.existing_client.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Do you have already clients or users for your product ? Who are they ? How many ?</p>
                    </v-col>
                    <v-col cols="12" sm="10" class="mt-n8">
                        <FormInlineMessage v-if="$v.strategy.existing_client.$error">
                            Have you clients ?
                        </FormInlineMessage>
                        <v-textarea
                        rows="2"
                        v-model="strategy.existing_client"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Earn Money -->
                <v-row class="mb-n8">
                    <v-col cols="12">
                        <FormInlineMessage v-if="$v.strategy.earn_money.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">
                            Briefly describe the process of how you earn money<br/>
                            <span class="blue-grey--text text--lighten-2">
                                (from where the money originates to how it gets into your pocket).
                            </span>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="10" class="mt-n8">
                        <FormInlineMessage v-if="$v.strategy.earn_money.$error">
                            Explain how you earn money
                        </FormInlineMessage>
                        <v-textarea
                        rows="2"
                        v-model="strategy.earn_money"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Customer Target -->
                <v-row class="mb-n8">
                    <v-col cols="12">
                        <FormInlineMessage v-if="$v.strategy.customer_target.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Which customer segment(s) do you target and What is your strategy to reach those customers ?</p>
                    </v-col>
                    <v-col cols="12" sm="10" class="mt-n8">
                        <FormInlineMessage v-if="$v.strategy.customer_target.$error">
                            Who are your target ?
                        </FormInlineMessage>
                        <v-textarea
                        rows="2"
                        v-model="strategy.customer_target"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Growth Perspectives -->
                <v-row class="mb-n8">
                    <v-col cols="12">
                        <FormInlineMessage v-if="$v.strategy.growth_perspectives.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">What growth prospective do you plan in the next 2 years ?</p>
                    </v-col>
                    <v-col cols="12" sm="10" class="mt-n8">
                        <FormInlineMessage v-if="$v.strategy.growth_perspectives.$error">
                            What is your prospect ?
                        </FormInlineMessage>
                        <v-textarea
                        rows="2"
                        v-model="strategy.growth_perspectives"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Years -->
                <v-row>
                    <v-col cols="12">
                        <p class="text-sm-left">What turnover in the next 3 years do you forecast ?</p>
                    </v-col>

                    <v-row class="mt-n8">
                        <v-col cols="12" sm="2">
                            <v-row>
                                <v-col cols="0" sm="12" class="mb-7"/>
                                <v-col cols="12">
                                    <FormInlineMessage v-if="$v.strategy_year1.$error || $v.strategy_year2.$error || $v.strategy_year3.$error">
                                        <v-col cols="0" sm="12"/>
                                    </FormInlineMessage>
                                    <p class="text-sm-left blue-grey--text text--lighten-2">Unit: USD K</p>
                                </v-col>
                            </v-row>
                        </v-col>
                        
                        <!-- First Year -->
                        <v-col cols="12" sm="2">
                            <v-row align="center" justify="center">
                                <v-col cols="12">
                                    <FormInlineMessage v-if="$v.strategy_year1.$error">
                                        Need a value
                                    </FormInlineMessage>
                                    <FormInlineMessage v-if="is_strategy_year1_length_error.error">
                                        {{ is_strategy_year1_length_error.message }}
                                    </FormInlineMessage>
                                    <FormInlineMessage v-else-if="$v.strategy_year2.$error || $v.strategy_year3.$error">
                                        <v-col cols="0" sm="12"/>
                                    </FormInlineMessage>
                                    <p style="background-color:grey; color: white;" class="py-2">Year 1</p>
                                </v-col>

                                <v-col cols="12" class="mt-n8">
                                    <v-text-field
                                    v-model="strategy_year1"
                                    outlined
                                    dense
                                    @input="strategy_year1 = formatToString(strategy_year1)"
                                    @keypress="isNumber($event)"/>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- Second Year -->
                        <v-col cols="12" sm="2">
                            <v-row align="center" justify="center">
                                <v-col cols="12">
                                    <FormInlineMessage v-if="$v.strategy_year2.$error">
                                        Need a value
                                    </FormInlineMessage>
                                    <FormInlineMessage v-if="is_strategy_year2_length_error.error">
                                        {{ is_strategy_year2_length_error.message }}
                                    </FormInlineMessage>
                                    <FormInlineMessage v-else-if="$v.strategy_year1.$error || $v.strategy_year3.$error">
                                        <v-col cols="0" sm="12"/>
                                    </FormInlineMessage>
                                    <p style="background-color:grey; color: white;" class="py-2">Year 2</p>
                                </v-col>

                                <v-col cols="12" class="mt-n8">
                                    <v-text-field
                                    v-model="strategy_year2"
                                    outlined
                                    dense
                                    @input="strategy_year2 = formatToString(strategy_year2)"
                                    @keypress="isNumber($event)"/>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- Third Year -->
                        <v-col cols="12" sm="2">
                            <v-row align="center" justify="center">
                                <v-col cols="12">
                                    <FormInlineMessage v-if="$v.strategy_year3.$error">
                                        Need a value
                                    </FormInlineMessage>
                                    <FormInlineMessage v-if="is_strategy_year3_length_error.error">
                                        {{ is_strategy_year3_length_error.message }}
                                    </FormInlineMessage>
                                    <FormInlineMessage v-else-if="$v.strategy_year1.$error || $v.strategy_year2.$error">
                                        <v-col cols="0" sm="12"/>
                                    </FormInlineMessage>
                                    <p style="background-color:grey; color: white;" class="py-2">Year 3</p>
                                </v-col>

                                <v-col cols="12" class="mt-n8">
                                    <v-text-field
                                    v-model="strategy_year3"
                                    outlined
                                    dense
                                    @input="strategy_year3 = formatToString(strategy_year3)"
                                    @keypress="isNumber($event)"/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-row>

                <!-- Save button -->
                <v-row align="center" justify="center" class="pt-2">
                    <v-col cols="0" sm="9"/>
                    <v-col cols="12" sm="1">
                        <v-btn
                        id="btn-photo"
                        class="white--text mb-3"
                        style="font-weight: bold; font-size: 14px"
                        color="#11aec9"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.stop.prevent="saveMarketStrategy()">
                        save
                        </v-btn>
                    </v-col>
                    <v-col cols="0" sm="2"/>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Var from "@/store/services/var";
import BusinessService from "@/store/services/business.service";
import FormInlineMessage from "@/components/FormInlineMessage";

import { required, minValue } from "vuelidate/lib/validators";
import { myMixins } from "@/mixins/mixins";

export default {
    mixins: [myMixins],
    components: {
        FormInlineMessage,
    },

    props: {
        company_id: Number,
    },

    data() {
        return {
            allIndustries: [],
            allStage: [],
            allLocation: [],
            allModel: [],
            allMaturity: [],
            allLifeCycle: [],
            allDiff: [],
            allPrimary: [],
            no_technology_focus: false,
            no_exclusive_technology: false,
            no_rd: false,
            type: { 
                industry: [], 
                location: []
            },
            type_size: null,
            type_growth: null,
            strategy_year1: null,
            strategy_year2: null,
            strategy_year3: null,
            product: {},
            strategy: {},

            // input_value: null,
        }
    },

    validations: {
        type: {
            industry: { required, },
            stage: { required, },
            model: { required, },
            location: { required },
            maturity: { required },
            // size: {
            //     required,
            //     minValue: minValue(0),
            // },
            growth: {
                required,
                minValue: minValue(0),
            },
            solve: { required },
            competitors: { required },
            barriers: { required },
        },
        type_size: { 
            // required,
            // minValue: minValue(0), 
        },
        type_growth: { 
            required,
            // minValue: minValue(0), 
        },
        product: {
            service_offer: { required },
            service_does: { required },
            technology_focus: { notSameAs() { return this.technology_focus_no != this.product.technology_focus; }, },
            rd: { notSameAs() { return this.rd_no != this.product.rd; }, },
            exclusive_technology: { notSameAs() { return this.exclusive_technology_no != this.product.exclusive_technology; }, },
            life_cycle: { required },
            unique: { required },
            advantage: { required },
        },
        strategy: {
            existing_client: { required, },
            earn_money: { required, },
            customer_target: { required, },
            growth_perspectives: { required, },
        },

        strategy_year1: { required },
        strategy_year2: { required },
        strategy_year3: { required },
    },

    methods: {
        saveMarket(is_outcall) {
            this.$v.$touch();
            if (
                this.$v.$error ||
                this.is_size_length_error.error ||
                this.is_type_growth_length_error ||
                this.is_type_growth_decimal_length_error
            ) {
                return this.$toast.open({
                    message: "Please fill all required fields or correct numbers or amounts having more than 15 digits before saving.",
                    type: 'error',
                    position: 'top-right'
                }); 
            }
            this.type_size = this.formatToInt(this.type_size);
            this.type_growth = this.formatToInt(this.type_growth);

            this.type.growth = this.type_growth;
            
            BusinessService.saveType(this.type).then((response) => {
                if(response.status == 200) {
                    if (!is_outcall) {
                        this.$toast.open({
                            message: "Type of product and Market saved successfully !",
                            type: 'success',
                            position: 'top-right'
                        });
                    }
                } else {
                    this.$toast.open({
                        message: "Market saved failed!",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
            this.type_size = this.type_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_growth = this.type_growth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        saveProduct(is_outcall) {
           this.$v.$touch();
            if (this.$v.$error) { return; };

            BusinessService.saveProduct(this.product).then((response) => {
                if(response.status == 200) {
                    if (!is_outcall) {
                        this.$toast.open({
                            message: "Product saved successfully !",
                            type: 'success',
                            position: 'top-right'
                        });
                    }
                } else {
                    this.$toast.open({
                        message: "Product saved failed !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            }); 
        },

        saveMarketStrategy() {
            this.$v.$touch();
            if (
                this.$v.$error ||
                this.is_size_length_error.error ||
                this.is_strategy_year1_length_error.error ||
                this.is_strategy_year2_length_error.error ||
                this.is_strategy_year3_length_error.error
            ) { 
                return this.$toast.open({
                    message: "Please fill all required fields or correct numbers or amounts having more than 15 digits before saving.",
                    type: 'error',
                    position: 'top-right'
                }); 
            };

            this.strategy_year1 = this.formatToInt(this.strategy_year1);
            this.strategy_year2 = this.formatToInt(this.strategy_year2);
            this.strategy_year3 = this.formatToInt(this.strategy_year3);

            this.strategy.year1 = this.strategy_year1; 
            this.strategy.year2 = this.strategy_year2;
            this.strategy.year3 = this.strategy_year3; 

            BusinessService.saveStrategy(this.strategy).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Strategy saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Strategy saved failed !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });

            this.strategy_year1 = this.strategy_year1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.strategy_year2 = this.strategy_year2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.strategy_year3 = this.strategy_year3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.saveMarket(true);
            this.saveProduct(true);
        },
    },

    created() {
        Promise.all([
            this.type.company_id = this.company_id,
            this.product.company_id = this.company_id,
            this.strategy.company_id = this.company_id,

            Var.getAllIndustries().then((res) => { this.allIndustries = res.data; }),
            Var.getAllStageOfYourCompanies().then((res) => { this.allStage = res.data; }),
            Var.getAllLocations().then((res) => { this.allLocation = res.data; }),
            Var.getAllBusinessModel().then((res) => { this.allModel = res.data; }),
            Var.getAllStageOfMarketMaturity().then((res) => { this.allMaturity = res.data; }),
            Var.getAllMakesProductUniques().then((res) => { this.allDiff = res.data; }),
            Var.getAllProductLifeCycles().then((res) => { this.allLifeCycle = res.data; }),
            Var.getAllTypeOfOffers().then((res) => { this.allPrimary = res.data; }),

            BusinessService.getType(this.company_id).then((res) => {
                this.type = res.data;
                this.type_size = res.data.size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.type_growth = res.data.growth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }),

            BusinessService.getProduct(this.company_id).then((res) => {
                this.product = res.data;
                this.no_technology_focus = !res.data.technology_focus;
                this.no_exclusive_technology = !res.data.exclusive_technology;
                this.no_rd = !res.data.rd;
            }),

            BusinessService.getStrategy(this.company_id).then((res)=>{
                this.strategy = res.data;
                this.strategy_year1 = this.strategy.year1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.strategy_year2 = this.strategy.year2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.strategy_year3 = this.strategy.year3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
            }),
        ])
    }
}
</script>
