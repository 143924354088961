<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-10">
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <p class="pt-2 text-sm-left cyan--text text--darken-1" id="title">
            Your Billing Address
          </p>
        </v-col>
        <v-col cols="1" />
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="1" md="0" lg="0" />
        <v-col cols="10" md="10" lg="10" style="background-color: #00acc1">
          <v-row>
            <v-col cols="1" />
            <v-col cols="10">
              <v-row class="mt-2">
                <!-- Gender -->
                <v-col cols="5" md="2" class="mt-1">
                  <v-autocomplete
                    v-model="billingAddress.gender"
                    :items="allGenre"
                    background-color="white"
                    outlined
                    dense
                  />
                </v-col>

                <!-- First Name -->
                <v-col cols="7" md="5">
                  <FormInlineMessage
                    v-if="$v.billingAddress.first_name.$error"
                    class="errmsg"
                  >
                    Please fill the field
                  </FormInlineMessage>
                  <v-text-field
                    class="mt-1"
                    v-model="billingAddress.first_name"
                    background-color="white"
                    outlined
                    dense
                    placeholder="First Name"
                  />
                </v-col>

                <!-- Last Name -->
                <v-col cols="12" md="5">
                  <FormInlineMessage
                    v-if="$v.billingAddress.last_name.$error"
                    class="errmsg"
                  >
                    Please fill the field
                  </FormInlineMessage>
                  <v-text-field
                    class="mt-1"
                    v-model="billingAddress.last_name"
                    background-color="white"
                    outlined
                    dense
                    placeholder="Last Name"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n9" v-if="!is_investor || !is_individual">
                <!-- Company Name -->
                <v-col cols="12">
                  <v-text-field
                    v-model="billingAddress.company_name"
                    background-color="white"
                    outlined
                    dense
                    :placeholder="!is_investor ? 'Company' : 'Firm Name'"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n9">
                <!-- Street -->
                <v-col cols="12">
                  <FormInlineMessage
                    v-if="$v.billingAddress.street.$error"
                    class="errmsg"
                  >
                    Please fill the field
                  </FormInlineMessage>
                  <v-text-field
                    v-model="billingAddress.street"
                    background-color="white"
                    outlined
                    dense
                    placeholder="Street"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n9">
                <!-- City -->
                <v-col cols="12" md="6">
                  <FormInlineMessage
                    v-if="$v.billingAddress.city.$error"
                    class="errmsg"
                  >
                    Please fill the field
                  </FormInlineMessage>
                  <v-text-field
                    v-model="billingAddress.city"
                    background-color="white"
                    outlined
                    dense
                    placeholder="City"
                  />
                </v-col>

                <!-- ZIP Code -->
                <v-col cols="12" md="6">
                  <FormInlineMessage
                    v-if="$v.billingAddress.zip_code.$error"
                    class="errmsg"
                  >
                    Please fill the field
                  </FormInlineMessage>
                  <v-text-field
                    v-model="billingAddress.zip_code"
                    background-color="white"
                    outlined
                    dense
                    placeholder="Zip Code"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n9">
                <!-- Country -->
                <v-col cols="12" md="7" class="mb-2">
                  <FormInlineMessage
                    v-if="$v.billingAddress.country.$error"
                    class="errmsg"
                  >
                    Please fill the field
                  </FormInlineMessage>
                  <v-select
                    v-model="billingAddress.country"
                    :items="countries"
                    background-color="white"
                    outlined
                    dense
                    placeholder="Country"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n9">
                <!-- VAT -->
                <v-col cols="12">
                  <v-text-field
                    v-model="billingAddress.vat"
                    background-color="white"
                    outlined
                    dense
                    placeholder="VAT #########"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="1" />
      </v-row>
      <v-col />
      <v-col />
      <v-col />
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-n15" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col />
        <v-col cols="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="validAddress()"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import CompanyService from "@/store/services/company/company.service";
import AddressService from "@/store/services/address.service";
import AccreditationService from "@/store/services/investor/accreditation.service";
import Var from "@/store/services/var";
import FormInlineMessage from "@/components/FormInlineMessage";

import { required } from "vuelidate/lib/validators";

export default {
  components: {
    FormInlineMessage,
  },

  props: {
    company_id: Number,
    is_investor: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      currentBlock: true,
      allGenre: ["Mr.", "Ms.", "Mrs."],
      countries: [],
      billingAddress: {
        gender: "Ms.",
        first_name: "",
        last_name: "",
        company_name: "",
        street: "",
        city: "",
        zip_code: "",
        country: "",
        vat: "",
      },
      is_individual: false,
    };
  },

  validations: {
    billingAddress: {
      required,
      first_name: { required },
      last_name: { required },
      street: { required },
      city: { required },
      zip_code: { required },
      country: { required },
    },
  },

  methods: {
    validAddress() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      if (!this.is_investor) {
        
        AddressService.store(
          JSON.stringify({ company_id: this.company_id, billing_address: this.billingAddress })
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch();
      } else {
        this.billingAddress.firm_name = this.billingAddress.company_name
        AccreditationService.storeBillingAddress(
          JSON.stringify({ billing_address: this.billingAddress })
        )
      }
      this.currentBlock = false;
      this.$emit("block3", this.billingAddress);
    },
  },

  mounted() {
    if (!this.is_investor) {
      AddressService.read(this.company_id)
        .then((res) => {
          if (res.data) {
            this.billingAddress.gender = res.data.gender;
            this.billingAddress.first_name = res.data.first_name;
            this.billingAddress.last_name = res.data.last_name;
            this.billingAddress.company_name = res.data.company_name;
            this.billingAddress.street = res.data.street;
            this.billingAddress.city = res.data.city;
            this.billingAddress.zip_code = res.data.zip_code;
            this.billingAddress.country = res.data.country;
            this.billingAddress.vat = res.data.vat;
          }
        })
        .catch((e) => {
          CompanyService.getDescribe().then((res) => {
            if (res.data) {
              this.billingAddress.first_name = res.data.first_name;
              this.billingAddress.last_name = res.data.last_name;
              this.billingAddress.company_name = res.data.company_name;
              this.billingAddress.street = res.data.street;
              this.billingAddress.city = res.data.city;
              this.billingAddress.zip_code = res.data.zip;
              this.billingAddress.country = res.data.country;
              this.billingAddress.gender = res.data.gender;
              this.billingAddress.vat = res.data.vat;
            }
          });
        });
    } else {
      AccreditationService.getProfile().then((res) => {
        if (res.data) {
          this.is_individual = res.data.firm_or_individual == 'individual' ? true : false;
          if (res.data.billing_address) {
            let address = JSON.parse(res.data.billing_address);
            this.billingAddress.first_name = address.first_name;
            this.billingAddress.last_name = address.last_name;
            this.billingAddress.company_name = address.firm_name ? address.firm_name : address.company_name;;
            this.billingAddress.street = address.street;
            this.billingAddress.city = address.city;
            this.billingAddress.zip_code = address.zip_code;
            this.billingAddress.country = address.country;
            this.billingAddress.gender = address.gender;
            this.billingAddress.vat = address.vat;
          } else {
            let address = JSON.parse(res.data.address);
            this.billingAddress.first_name = res.data.legal_first_name;
            this.billingAddress.last_name = res.data.legal_last_name;
            this.billingAddress.company_name = res.data.firm_name;
            this.billingAddress.street = address.street;
            this.billingAddress.city = address.city;
            this.billingAddress.zip_code = address.zip;
            this.billingAddress.country = res.data.country;
            this.billingAddress.gender = res.data.gender;
            this.billingAddress.vat = res.data.vat;
          }
        }
      });
    }

    Var.getAllCountry().then((res) => {
      this.countries = res.data;
    });
  },
};
</script>
