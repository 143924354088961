import Vue from "vue";
import router from "@/router";
import { VueAuthenticate } from "vue-authenticate";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

const baseUrl = process.env.VUE_APP_API_BASE_URL;
const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: baseUrl,
  tokenName: "access_token",
  loginUrl: "/login",
  registerUrl: "/register",
});

const requestOptions = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

/*
  key name of access token from server
*/
vueAuth.options.tokenPath = "token";

export default {
  state: {
    isAuthenticated:
      localStorage.getItem("vue-authenticate.vueauth_access_token") != null,
    userId: "",
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },

    // userId(state, payload) {
    //   state.userId = payload
    // }
  },

  actions: {
    login(context, payload) {
      return vueAuth.login(payload.user, requestOptions).then((response) => {
        //console.log(requestOptions);
        context.commit("isAuthenticated", {
          isAuthenticated: vueAuth.isAuthenticated(),
        });
        localStorage.setItem("user_role", response.data.user_role);
        localStorage.setItem("user_id", response.data.user_id);
      });
    },

    register(context, payload) {
      if (payload.url) {
        vueAuth.options.registerUrl =
          vueAuth.options.registerUrl + "/" + payload.url;
      }
      return vueAuth.register(payload.user, requestOptions).then((response) => {
        context.commit("isAuthenticated", {
          isAuthenticated: vueAuth.isAuthenticated(),
        });
        localStorage.setItem("user_role", response.data.user_role);
        localStorage.setItem("user_id", response.data.user_id);
        return;
        // context.commit("userId", {
        // });
        // router.push({name: "Home"});
      });
    },

    logout(context, payload) {
      return vueAuth.logout().then((response) => {
        context.commit("isAuthenticated", {
          isAuthenticated: vueAuth.isAuthenticated(),
        });
        router.push({ name: "Login" });
      });
    },
  },
};
