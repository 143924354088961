<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8">
      <v-img
        lazy-src="@/assets/img/tree&bbl3.png"
        max-height="100%"
        width="100%"
        src="@/assets/img/tree&bbl3.png"
        class="align-center"
      >
        <v-row align="center" justify="center" class="pt-4">
          <!-- <div class="layout column pt-5"> -->
          <v-col cols="12" md="4">
            <p
              class="white--text headline font-weight-medium text-sm-center"
              id="title"
            >
              Please tell us more about your accreditation
            </p>
            <!-- </div> -->
          </v-col>
          <!-- <v-col cols="3">            
		    </v-col>   
		    <v-col cols="1"> -->
          <!-- </v-col>  -->
        </v-row>

        <!-- <v-card-title class="white--text justify-center headline bold font-weight-medium">Please tell us more about your company</v-card-title>
 -->
        <v-card-actions class="justify-center mt-10">
          <v-btn
            id="btn-verify"
            center
            class="white--text ml-n3 mt-10"
            color="#514d7a"
            width="220"
            height="65"
            rounded
            @click.stop.prevent="$emit('agreed')"
            :disabled="false"
          >
            NEXT
          </v-btn>
        </v-card-actions>
      </v-img>
      <v-row align="center" justify="center">
        <v-col cols="12" class="mb-n5"> </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style></style>
