<template>
    <section class="vh-100 bg">
      <!-- Verification Code Resend if email not verified -->
      <v-dialog v-model="dialogVerification" max-width="500px">
        <v-card
          min-height="450px"
          style="overflow-x: hidden; overflow-y: hidden"
        >
          <v-card-title>
            <span id="title">Verify It's You</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="sub-title-code">
                We sent a verification code to your email.
              </v-row>
              <v-row>
                Enter The code from the email in the field below.</v-row
              >

              <v-row class="mt-10">
                <v-col cols="12" class="pl-0">
                  <v-row class="mb-1 pl-3"> 6 digit code* </v-row>
                  <v-text-field
                    v-model="code"
                    placeholder="Enter 6 digit Code"
                    required
                    outlined
                  ></v-text-field>
                  <v-row class="pl-3 red--text">
                    <v-icon color="red">mdi-alert-octagon</v-icon>
                    Verification Code Required
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn
              id="btn-verify"
              center
              class="white--text"
              color="#514d7a"
              width="255"
              height="65"
              rounded
              @click.stop.prevent="sendCode"
              :disabled="false"
            >
              Verify Code
            </v-btn>
          </v-card-actions>
          <v-row>
            <v-col cols="10" class="pr-15 text-decoration-underline">
              <a>Need help?</a>
            </v-col>
          </v-row>
          <v-container class="ml-5">
            <v-row>
              <v-col cols="12">
                Resend the code.
                <a @click="regenerateCode">Try again?</a>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Forgot Password -->
      <v-dialog v-model="dialogForgot" max-width="500px">
        <v-card
          max-height="40%"
          style="overflow-x: hidden; overflow-y: hidden"
        >
          <v-card-title class="justify-center mt-2">
            <span style="text-align: center" id="title"
              >Restore your password</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>

              <v-row class="mt-1">
                <v-col cols="12">
                  <v-text-field
                    v-model="emailForgot"
                    placeholder="Your e-mail"
                    required
                    outlined
                  ></v-text-field>
                  <FormInlineMessage style="font-size: large; color: green;" v-if="this.hide_restore_button==1">
                    Password reset email sent successfully.<br> Check your inbox.
                  </FormInlineMessage>
                  <FormInlineMessage style="font-size: large" v-if="this.hide_restore_button==2">
                    Provided email not found.
                  </FormInlineMessage>
                  <v-btn
                    v-if="this.hide_restore_button==3"
                    id="btn-verify"
                    center
                    class="white--text"
                    color="#514d7a"
                    width="255"
                    height="65"
                    rounded
                    @click.stop.prevent="generateToken"
                    :disabled="false"
                  >
                    Restore
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-row>
            <v-col cols="12" class="justify-center text-decoration">
              <a>Need help?</a>
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12">
                <a @click="regenerateToken">Resend the Token?</a>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Reset Password -->
      <v-dialog v-model="dialogReset" max-width="500px">
        <v-card
          min-height="450px"
          style="overflow-x: hidden; overflow-y: hidden"
        >
          <v-card-title>
            <span class id="title">Enter new password</span>
          </v-card-title>
          <v-card-text>
            <v-form class="my-5">
              <FormInlineMessage v-if="$v.reset.password.$error">
                New password must be at least 8 characters long
              </FormInlineMessage>
              <v-text-field
                v-model="reset.password"
                outlined
                id="new-password"
                name="new-password"
                label="Password"
                type="password"
                @blur="$v.reset.password.$touch()"
              ></v-text-field>
              <FormInlineMessage v-if="$v.reset.password_confirmation.$error">
                Password doesn't match
              </FormInlineMessage>
              <v-text-field
                v-model="reset.password_confirmation"
                outlined
                id="confirm-new-assword"
                name="confirm-new-password"
                label="Confirm Password"
                type="password"
                @blur="$v.reset.password_confirmation.$touch()"
              ></v-text-field>
              <v-card-actions class="justify-center">
                <v-btn
                  id="btn-verify"
                  center
                  class="white--text"
                  color="#514d7a"
                  width="255"
                  height="65"
                  rounded
                  @click.stop.prevent="nextReset"
                  :disabled="false"
                >
                  Reset password
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Server Error Dialog -->
      <v-dialog
        v-model="dialogServer"
        persistent
        width="380"
        min-height="393"
      >
        <v-card height="100%" width="380">
          <v-card-title class="justify-center"> Notification: </v-card-title>
          <v-card-text>{{ messageServer }}</v-card-text>
          <v-card-actions class="pb-15 justify-center">
            <v-col cols="12" class="justify-center">
              <v-btn
                center
                class="white--text btn-next bold justify-center"
                @click="resetServerMessage"
                color="#00aac3"
                width="140"
                height="45"
                rounded
              >
                Close
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div class="card shadow-2-strong" style="border-radius: 1rem;">
              <div class="card-body p-5 text-center">

                <img
                  alt="Ideasfundx logo"
                  src="../assets/logo.png"
                  width="200"
                  height="32"
                />

                <h2 class="flex secondary--text pt-3" id="sub-title">Sign in</h2>

                <v-form class="my-5">
                  <FormInlineMessage v-if="$v.normal_login.email.$error">
                    Please provide a valid email.
                  </FormInlineMessage>
                  <v-text-field
                    v-model="normal_login.email"
                    outline
                    name="email"
                    label="Your e-mail"
                    type="email"
                    outlined
                    @blur="$v.normal_login.email.$touch()"
                  />
                  <FormInlineMessage v-if="$v.normal_login.password.$error">
                    Please provide a valid password.
                  </FormInlineMessage>
                  <v-text-field
                    v-model="normal_login.password"
                    outlined
                    outline
                    class="mb-n1"
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    @blur="$v.normal_login.password.$touch()"
                  ></v-text-field>
                </v-form>

                <div v-if="this.error_is_valid" class="alert alert-danger" role="alert">
                  {{ this.error_message }}
                </div>

                <div class="mb-2">
                  <a
                    style="font-size: large;"
                    href="#"
                    @click.prevent="forgotPassword()"
                    >Forgot your password?</a
                  >
                </div>

                <button
                  class="btn btn-lg btn-primary btn-block white--text"
                  style="background-color: #31aed4; border: none;"
                  type="submit"
                  @click.stop.prevent="nextLogin"
                  :disabled="false"
                  v-if="this.login_instead_of_verify"
                >Login</button>

                <hr class="my-4">

                <h2 class="flex secondary--text" id="sub-title">Don't have an IdeasFundX account?</h2>

                <p class="flex justify-center" style="text-align: center">Sign up now</p>

                <div class="row mt-3">
                  <button
                    class="btn btn-lg btn-block btn-primary"
                    style="background-color: #31aed4; border: none;"
                    type="submit"
                    @click.stop.prevent="investorSignup"
                    :disabled="false"
                  >INVESTOR ACCESS</button>
                  <button
                    class="btn btn-lg btn-block btn-primary"
                    style="background-color: #514d7a; border: none;"
                    type="submit"
                    @click.stop.prevent="companySignup"
                    :disabled="false"
                  >COMPANY ACCESS</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import {
  required,
  minLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";
import Profile from "@/store/services/profile-service";
import Status from "@/store/services/status.service";
import Admin from "@/store/services/admin/admin";

export default {
  name: "SignUp",
  components: {
    FormInlineMessage,
  },

  data() {
    return {
      normal_login: {
        email: "",
        password: "",
      },
      reset: {
        password: "",
        password_confirmation: "",
      },
      error_is_valid: false,
      error_message: "",
      dialogServer: false,
      messageServer: "",
      code: "",
      token: "",
      emailForgot: "",
      dialogVerification: false,
      dialogForgot: false,
      dialogReset: false,
      login_instead_of_verify: true,
      hide_restore_button: 3,
      log: {
        id: null,
        profile: null,
      }
    };
  },

  validations: {
    normal_login: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
    reset: {
      password: {
        required,
        minLength: minLength(8),
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },

  methods: {
    nextLogin() {
      this.$v.normal_login.$touch();
      if (this.$v.normal_login.$error) {
        return;
      }
      this.submitLogin();
    },

    saveLogs(userId, profile) {
      this.log.id = userId;
      this.log.profile = profile;
      Admin.storeLogs(this.log).then((res)=> {
        if (res.status) {
          console.log(res);
        }
      })
    },

    nextReset() {
      this.$v.reset.$touch();
      if (this.$v.reset.$error) {
        return;
      }
      this.resetPassword();
    },

    async submitLogin() {

      let that = this;

      await this.$store
        .dispatch("login", {
          user: {
            email: that.normal_login.email,
            password: that.normal_login.password,
          }, //,
          //url: "login",
        })
        .then((response) => {
          Status.readAll()
            .then((res) => {
              if (res.data) {
                if (localStorage.getItem("user_role").substring(0, 1) == "c" ) { //company redirect
                  if (res.data.active_application_step >= 1 && res.data.active_application_step <= 7)
                  {
                    this.$router.push({ name: "Application", query: { el: res.data.active_application_step }});
                    this.saveLogs(localStorage.getItem('user_id'), 'Company')
                  }
                }
                else if (localStorage.getItem("user_role").substring(0, 1) == "i") { //investor redirect
                  if(res.data.active_application_step >= 1 && res.data.active_application_step <= 4)
                  {
                    this.$router.push({ name: "InvestorApplication", query: { el: res.data.active_application_step }});
                    this.saveLogs(localStorage.getItem('user_id'), 'Investor')
                  }
                  else if(res.data.active_application_step == 5)
                  {
                    this.$router.push({ name: "DashboardInvestor" });
                    this.saveLogs(localStorage.getItem('user_id'), 'Investor')
                    //it will not become '5' since investors cant pay yet, but when they can, this will redirect to investor dashboard which is not ready :)
                  }
                }
              }
              else if(localStorage.getItem("user_role").substring(0, 1) == "c") 
              {
                //this.$router.push({ name: "CompanyDashboard" });
                this.$router.push({ name: "Application" });
                this.saveLogs(localStorage.getItem('user_id'), 'Company');
              }
              else if(localStorage.getItem("user_role").substring(0, 1) == "i") 
              {
                this.$router.push({ name: "InvestorApplication" });
                this.saveLogs(localStorage.getItem('user_id'), 'Company');
              }
            })
            .catch((e) => {
              if(localStorage.getItem("user_role").substring(0, 1) =="i") 
              {
                this.$router.push({ name: "InvestorApplication" });
                this.saveLogs(localStorage.getItem('user_id'), 'Investor')
              }
              else if(localStorage.getItem("user_role").substring(0, 1) =="c") 
              {
                //this.$router.push({ name: "CompanyDashboard" });
                this.$router.push({ name: "Application" });
                this.saveLogs(localStorage.getItem('user_id'), 'Investor')
              }
            });
        })
        .catch((error) => {
          this.error_is_valid = true;
          try {
            if (error.response.data.errors[0].status == 422) {
              this.error_message = "Email address not found.";
            }
          } catch (e) {
            if (error.response.status == 420) {
              this.login_instead_of_verify = false;
            }
            this.error_message = error.response.data.message;
          }
        });
    },

    companySignup() {
      this.$router.push({ name: "SignUp" });
    },
    investorSignup() {
      this.$router.push({ name: "SignUp", query: { inv: 1 } });
    },

    forgotPassword() {
      this.dialogForgot = true;
    },

    sendToken() {
      if (this.$route.query.token) {
        Profile.tokenFind(this.$route.query.token)
          .then((res) => {
            //pop up dialogue box for the actual resetting
            this.messageServer =
              "Recovery email sent, please check your inbox.";
            this.dialogReset = true;
          })
          .catch((e) => {
            this.messageServer = "Invalid reset token.";
            this.dialogServer = true;
          });
      } else {
        return;
      }
    },

    resetPassword() {
      if (this.$route.query.token && this.$route.query.email) {
        Profile.passwordReset(
          JSON.stringify({
            token: this.$route.query.token,
            email: this.$route.query.email,
            password: this.reset.password,
          })
        )
          .then((res) => {
            this.messageServer = "Password reset was successful.";
            this.dialogServer = true;
          })
          .catch((e) => {
            this.messageServer = "Password reset failed.";
            this.dialogServer = true;
          });
      } else {
        return;
      }
    },

    generateToken() {
      Profile.passwordForgot(JSON.stringify({ email: this.emailForgot }))
        .then((res) => {
          this.hide_restore_button = 1;
        })
        .catch((e) => {this.hide_restore_button = 2;});
    },

    regenerateToken() {
      this.dialogForgot = true;
      this.dialogServer = true;
      this.generateToken();
      this.messageServer = "Your code has been sent";
    },
    startVerification() {
      this.dialogVerification = true;
      this.generateCode();
    },

    regenerateCode() {
      this.dialogServer = true;
      this.messageServer = "Your code has been sent";
      this.generateCode();
    },

    async generateCode() {
      await this.$store
        .dispatch("profile/generateCode", {})
        .then((response) => {
          ("Your 6 digit code successfully generated. Check your email");
        })
        .catch((error) => {});
    },

    async sendCode() {
      var that = this;
      await this.$store
        .dispatch("profile/sendCode", { code: this.code })
        .then((response) => {
          this.$router.push({ name: "Application" });
        })
        .catch((error) => {
          this.dialogServer = true;
          this.messageServer = error.response.data.message;
        });
    },

    resetServerMessage() {
      this.dialogVerification = false;
      this.dialogForgot = false;
      this.dialogReset = false;
      this.messageServer = "";
      this.dialogServer = false;
    },
  },
  mounted() {
    this.sendToken();
  },
};
</script>

<style scoped lang="css">
.btn-next {
  font-weight: bold;
  font-size: 20px;
  border-radius: 70px;
  background: #269abc !important;
  border: none !important;
}
.btn-primary:hover {
  filter: brightness(0.9) !important;
}
.btn-next:hover {
  background: #31aed4 !important;
}

#btn-next {
  font-size: 20px;
}

#title {
  font-weight: bolder;
  font-size: 18px;
}
#sub-title {
  font-size: 14px;
}

.sub-title-code {
  font-size: 15px;
}

#btn-verify {
  font-weight: bolder;
  font-size: 18px;
  border-radius: 70px;
}

#card-text {
  padding: 25px;
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("~@/assets/img/signup.jpg") no-repeat center center;
  background-size: cover;
  background-color: #f6f6f6;
  transform: scale(1);
}

.signIn_btns {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.signUp_btns {
  margin-left: -18px;
}

.btn-company {
  margin-left: 10px;
}
@media (min-width: 1013px) and (max-width: 1112px) { .signUp_btns {margin-left: -34px;}}
@media (min-width: 965px) and (max-width: 1024px) { .signUp_btns {margin-left: -24px;}}
@media (min-width: 965px) and (max-width: 1024px) { .signUp_btns {margin-left: -24px;}}
@media (min-width: 960px) and (max-width: 964px) { .signIn_btns { display: flex; justify-content: center; margin-left: -8%;}}
@media (min-width: 687px) and (max-width: 959px) { .signIn_btns { display: flex; justify-content: center;}}
@media (min-width: 20px) and (max-width: 686px) { 
.signIn_btns { 
  display: flex; 
  flex-direction: column; 
  justify-content: center;
} 
.btn-company {
  margin-top: 10px;
}
.btn-primary.btn-login {
  height: 50px !important;
  background-color: red !important;
}
  .div-login {
    margin-top: 10px
  
  }
}
 /* @media (min-width: 1264px) and (max-width: 1340px) { .signIn_btns { display: flex; justify-content: center; margin-left: -20%;}} */
/*@media (min-width: 1274px) and (max-width: 1281px) { .signIn_btns { display: flex; justify-content: center; margin-left: -21%;}}
@media (min-width: 1341px) and (max-width: 1371px) { .signIn_btns { display: flex; justify-content: center; margin-left: -18%;}}
@media (min-width: 1372px) and (max-width: 1496px) { .signIn_btns { display: flex; justify-content: center; margin-left: -16%;}}
@media (min-width: 1499px) and (max-width: 1640px) { .signIn_btns { display: flex; justify-content: center; margin-left: -11%;}} */
</style>
