<template>
  <div>
    <v-sheet v-if="false" height="100%" class="mx-auto mt-10">
      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="12" md="6" class="pl-2">
          <p
            class="light-green--text text--darken-3 font-weight-bold text-sm-left"
            id="title"
          >
            Mini Resume
          </p>
        </v-col>
        <v-col cols="3"> </v-col>
        <v-col cols="1"> </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" md="3">
          <p class="text-sm-left">Mini Resume*</p>
        </v-col>
        <v-col cols="6">
          <FormInlineMessage v-if="$v.resume.$error">
            Please give your resume
          </FormInlineMessage>
          <v-textarea
            v-model="resume"
            :counter="160"
            outlined
            dense
            placeholder="My role,my recent achievements"
            @blur="$v.resume.$touch()"
          ></v-textarea>
        </v-col>
        <v-col cols="1"> </v-col>
      </v-row>
    </v-sheet>

    <!-- CEO -->
    <v-sheet height="300" class="mx-auto mt-10">
      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="12" md="6" class="pl-2">
          <p
            class="light-green--text text--darken-3 font-weight-bold text-sm-left"
            id="title"
          >
            Type of Investor
          </p>
        </v-col>
        <v-col cols="3"></v-col>
        <v-col cols="1"></v-col>
      </v-row>

      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="10" md="6" class="pl-2">
          <p class="text-sm-left">How will you invest in companies?</p>
        </v-col>
        <v-col cols="3"></v-col>
        <v-col cols="1"></v-col>
      </v-row>

      <v-row align="center" justify="center" class="" no-gutters>
        <v-col cols="10" md="6" class="pl-5">
          <v-radio-group v-model="model" class="mb-6">
            <v-row>
              <v-radio :value="0" />
              <p class="thin">As an individual</p>
            </v-row>
            <v-row>
              <v-radio :value="1" />
              <p class="thin">As a firm or a fund</p>
            </v-row>
          </v-radio-group>
        </v-col>
        <v-col cols="3"></v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row align="center" justify="center" v-if="currentBlock">
        <v-col cols="10"></v-col>
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveType"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import FormInlineMessage from "@/components/FormInlineMessage";
import AccreditationService from "@/store/services/investor/accreditation.service";

export default {
  components: {
    FormInlineMessage,
  },
  data() {
    return {
      model: null,
      currentBlock: true,
      resume: null,
      investment: {
        type: "",
      },
    };
  },

  validations: {
    // resume: {
    //   required,
    //   minLength: minLength(10),
    // },
    // investment: {
    //   type: {required}
    // },
    model: { required },
  },

  methods: {
    saveType() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      if (this.model == 0) {
        this.investment.type = "individual";
      } else if (this.model == 1) {
        this.investment.type = "firm";
      }

      AccreditationService.saveType(
        JSON.stringify({ type_of_investment: this.investment.type })
      )
        .then((res) => {
          this.currentBlock = false;
          this.$emit("passInvestType", this.investment.type);
          this.$emit("block6");
        })
        .catch((e) => {
          //console.error(e);
        });
    },

    getType() {
      AccreditationService.getType()
        .then((res) => {
          if (res.data) {
            if (res.data.firm_or_individual) {
              this.investment.type = res.data.firm_or_individual;

              if (this.investment.type == "individual") {
                this.model = 0;
              } else if (this.investment.type == "firm") {
                this.model = 1;
              }
              this.$emit("passInvestType", this.investment.type);
            }
            return;
          }
        })
        .catch((e) => {
          //console.error(e);
        });
    },
  },

  created() {},
  mounted() {
    this.getType();
  },
};
</script>
