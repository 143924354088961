<template>
<div class="mar-top-80">
    <v-col class="mb-n8 companyName">
        <v-select
        v-model="company_id"
        :items="companies"
        item-text="company_name"
        item-value="company_id"
        dense
        outlined/>
    </v-col>
    <v-sheet class="main-content" style="background-color: #f6f6f6" height="100%">
        <!-- <v-col cols="12" v-if="companies.length > 1" class="mb-n10">
            <h3>Select active company</h3>
            <v-select
            v-model="company_id"
            @change="changeComponent(actual)"
            :items="companies"
            item-text="company_name"
            item-value="company_id"
            dense
            outlined/>
        </v-col>
                    <h2 class="mb-5 text-sm-left menuTitle">{{company.company_name}}</h2> -->

        <v-row>
            <v-col cols="1"/>

            <v-col cols="11">
                <div id="Company-dashboard-company" class="mb-6">
                    <div @click.prevent.stop="companyMenu = !companyMenu" class="ml-5 mr-3">
                        <h2 class="mb-5 text-sm-left menuTitle company"><span>COMPANY</span><img class="ml-10 link float-right" :class="companyMenu == true ? 'img' : ''" src="../../assets/img/rowUp.png" alt=""></h2>
                    </div>

                    <div v-if="companyMenu" class="ml-8 subMenu">
                        <p v-bind:style="actual == 'mainContact' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('mainContact')">Main Contact</p>
                        <p v-bind:style="actual == 'companyProfile' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('companyProfile')">Company Profile</p>
                        <p v-bind:style="actual == 'team' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('team')">Team</p>
                        <p v-bind:style="actual == 'product' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('product')">Product</p>
                        <p v-bind:style="actual == 'financeHistory' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('financeHistory')">Finance History</p>
                        <p v-bind:style="actual == 'fundingNeeds' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('fundingNeeds')">Funding Needs</p>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-divider style="border-color: #11aec9!important;"></v-divider>

        <v-row>
            <v-col cols="1"/>

            <v-col cols="11">
                <div id="Billing-dashboard-company" class="mt-10">
                    <div @click.prevent.stop="adminPanel = !adminPanel" class="ml-5">
                        <h2 class="mb-5 text-sm-left menuTitle"><span>ADMIN PANEL</span><img class="ml-10 link float-right" :class="adminPanel == true ? 'img' : ''" src="../../assets/img/rowUp.png" alt=""></h2>
                    </div>

                    <div v-if="adminPanel" class="ml-8 subMenu">
                        <p v-bind:style="actual == 'InitialApplication' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="openAlertPopup('initial', 'company'), actual = 'InitialApplication'">
                            Initial Application
                        </p>

                        <p v-bind:style="actual == 'UpdatedApplication' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="openAlertPopup('updated', 'company'), actual = 'UpdatedApplication'">
                            Updated Application
                        </p>

                        <p v-bind:style="actual == 'AccountStatus' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('AccountStatus')">
                            Account Status
                        </p>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-divider style="border-color: #11aec9!important;"></v-divider>

        <v-row>
            <v-col cols="1"/>

            <v-col cols="11">
                <div id="Billing-dashboard-company" class="mt-10">
                    <div @click.prevent.stop="billingMenu = !billingMenu" class="ml-5">
                        <h2 class="mb-5 text-sm-left menuTitle billing"><span>BILLING</span> <img class="ml-10 float-right link" :class="billingMenu == true ? 'img' : ''" src="../../assets/img/rowUp.png" alt=""></h2>
                    </div>
                    <div v-if="billingMenu" class="ml-8 subMenu">
                        <p v-bind:style="actual == 'legalRepresentative' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('legalRepresentative')">
                            Legal Representative
                        </p>

                        <p v-bind:style="actual == 'billingAddress' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('billingAddress')">
                            Billing Address
                        </p>

                        <p v-bind:style="actual == 'invoices' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('invoices')">
                            Invoices
                        </p>
                    </div>
                </div>
            </v-col>
        </v-row><br><br>
    </v-sheet>
</div>
</template>

<script>
export default {
    props: {
        comp_id: Number,
        array: Array,
        current: String,
    },

    components: { },

    data() {
        return {
            companies: [],
            companyMenu: true,
            billingMenu: true,
            adminPanel: true,
            actual: null,
            company_id: null,
            company: {},
            enablePopup: false,
            type: null,
        }
    },

    methods: {
        changeComponent(value) {
            this.companies.forEach(c => {
                if (c.company_id == this.company_id) {
                    this.company = c;
                }
            });

            this.actual = value;
            this.$emit('changeComponent', this.company_id, value);
        },

        init(val, id) {
            this.companies = val;
            this.company_id = id;
            this.companies.forEach(company => {
                if (company.company_id == id) { this.company = company; }
            });
        },

         /**
         * @param $val // initial updated
         * @param $application // Investor Company
         */
        openAlertPopup(val, application) {
            this.type = val;
            this.$emit('openAlertPopupDownload', val, application);
        }
    },

    created() {
        this.actual = this.current;
        if (this.comp_id) {
            this.companies = this.array;
            this.company_id = this.comp_id;
            this.companies.forEach(company => {
                if (company.company_id == this.comp_id) { this.company = company; }
            });
        }
    }
}
</script>

<style>
    .companyName {
        position: absolute;
        width: 235px !important;
        margin-left: -2px !important;
    }
    .link:hover {
        cursor: pointer;
    }
    .main-content {
        margin-top: 80px !important;
    }
    .menuTitle {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 700;
    }
    
    @media only screen and (max-width: 959px) {
        .companyName {
            justify-content: center !important;
            margin-top: -22px;
        }
        .billing span {
            margin-right: 50px !important;
        }
        .subMenu {
            text-align: left !important;
        }
    }
</style>