<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6">
        <p class="thin">Name</p>
      </v-col>

      <v-col cols="0" sm="6" />

      <v-col cols="8" sm="6" class="mt-n5">
        <FormInlineMessage v-if="$v.founder.name.$error" class="errmsg">
          Fill in the field
        </FormInlineMessage>

        <v-text-field
          class="field"
          v-model="founder.name"
          outlined
          dense
          placeholder="First and Last Name"
          @blur="$v.founder.name.$touch()"
        />
      </v-col>

      <v-col cols="0" sm="6" />
    </v-row>

    <v-row align="center" justify="center" class="mb-8"  v-if="founderIndex == 1">
      <v-card-actions class="justify-center">
        <v-hover v-slot:default="{  }">
          <v-img
            v-if="!photoBlock && founderIndex == 1"
            contain
            style="border-radius: 50%; border: 0 solid"
            max-height="150"
            max-width="150"
            @click.stop="dialogUp('Photo')"
            :src="imagePreviewUrl"
          >
          </v-img>
        </v-hover>

        <v-btn
          v-if="founderIndex != 1"
          sm="10"
          id="btn-photo"
          class="white--text"
          color="#514d7a"
          height="90"
          min-width="20"
          rounded
          :disabled="false"
          @click.stop="dialogUp('Photo')"
        >
          ADD YOUR PHOTO*
        </v-btn>
      </v-card-actions>
    </v-row>

    <v-row align="center" justify="center" class="mb-8"  v-if="founderIndex != 1">
      <v-card-actions class="justify-center">
        <v-hover v-slot:default="{ hover }">
           <v-img
            v-if="!photoBlock && founderIndex != 1"
            contain
            style="border-radius: 50%; border: 0 solid"
            max-height="150"
            max-width="150"
            @click.stop="dialogUp('Photo', 2)"
            :src="profileLink"
          >
            <v-expand-transition>
              <div
                v-if="hover"
                style="background-color: rgb(81, 77, 122); height: 100%"
                class="d-flex transition-fast-in-fast-out v-img--reveal display-1 white--text align-center justify-center"
              >
                CHANGE YOUR PHOTO
              </div>
            </v-expand-transition>
          </v-img>
        </v-hover>

        <v-btn
          v-if="!profileLink && founderIndex != 1"
          sm="10"
          id="btn-photo"
          class="white--text"
          color="#514d7a"
          height="90"
          min-width="20"
          rounded
          :disabled="false"
          @click.stop="dialogUp('Photo', 2)"
        >
          ADD YOUR PHOTO*
        </v-btn>
      </v-card-actions>
    </v-row>

    <!-- dialog for Avatar image -->
    <v-dialog v-model="dialogPhoto" max-width="400">
      <PhotoForm @uploadPhoto="uploadPhoto" @closePhoto="dialogDown('Photo')" />
    </v-dialog>

    <v-row class="mt-n5">
      <v-col cols="12" sm="6">
        <v-row align="center" justify="center">
          <v-col cols="12" class="mb-n5">
            <p class="thin">Country of residence</p>
          </v-col>

          <v-col cols="8" sm="12">
            <v-autocomplete
              class="field"
              v-model="founder.country"
              :items="country"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="6">
        <v-row align="center" justify="center">
          <v-col cols="12" class="mb-n5">
            <p class="thin">Date of Birth</p>
          </v-col>
          <v-col cols="8" sm="12">
            <v-menu
              ref="dob"
              :close-on-content-click="false"
              :return-value.sync="founder.birth"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  
                  outlined
                  v-model="founder.birth"
                  placeholder="Date of birth"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @blur="$v.founder.birth.$touch()"
                ></v-text-field>
              </template>
              <v-date-picker v-model="founder.birth" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.dob.isActive = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dob.save(founder.birth)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col cols="12" sm="6">
        <p class="thin">Equity - % Share</p>
      </v-col>

      <v-col cols="0" sm="6" />

      <v-row align="center" justify="center" class="ml-0">
        <v-col cols="8" sm="6" class="mt-n5">
          <FormInlineMessage v-if="less_than_100_percent_error.error">
            {{ less_than_100_percent_error.message }}
          </FormInlineMessage>
          <v-text-field
            class="field"
            prefix="%"
            v-model="founder.equity"
            outlined
            dense
            placeholder="% Shares"
            @keypress="isNumber($event)"
          />
        </v-col>

        <v-col cols="0" sm="6" />
      </v-row>
    </v-row>

    <v-row class="mt-n5">
      <v-col cols="12" sm="6">
        <v-row align="center" justify="center">
          <v-col cols="12" class="mb-n5">
            <p class="thin">Field of expertise</p>
          </v-col>

          <v-col cols="8" sm="12">
            <v-combobox
              v-model="founder.expertise"
              :items="entries"
              item-text="name"
              label=""
              outlined
              dense
              multiple
              small-chips
              deletable-chips
              @blur="$v.founder.expertise.$touch()"/>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="6">
        <v-row align="center" justify="center">
          <v-col cols="12" class="mb-n5">
            <p class="thin">Number of years in this expertise</p>
          </v-col>

          <v-col cols="8" sm="12">
            <v-autocomplete
              class="field"
              v-model="founder.years"
              :items="years"
              outlined
              dense
              placeholder="1-5 years"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-n4">
      <v-col cols="6">
        <p class="thin">Former position</p>
      </v-col>

      <v-col cols="6">
        <p class="thin">Former company</p>
      </v-col>
    </v-row>

    <div v-for="(former, index) in founder.formers" :key="index">
      <v-row align="center" justify="center" class="mb-3" v-if="index > 0">
        <v-divider />
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="8" sm="6">
          <v-text-field
            class="field"
            v-model="former.position"
            outlined
            dense
            placeholder="CTO"
          />
        </v-col>

        <v-col cols="8" sm="6">
          <v-text-field
            class="field"
            v-model="former.company"
            placeholder="Company"
            outlined
            dense
          />
        </v-col>
      </v-row>
    </div>

    <v-row class="mt-n8">
      <v-col cols="12" sm="2">
        <v-btn
          id="btn-add"
          text
          color="#00aac3"
          @click.prevent.stop="addFormer()"
        >
          + Add a position
        </v-btn>
      </v-col>

      <v-col cols="12" sm="2" v-if="f > 1 || founder.formers.length > 1">
        <v-btn
          id="btn-add"
          text
          color="#00aac3"
          @click.prevent.stop="deleteFormer()"
        >
          - Remove a position
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <p class="thin">Academic Degree</p>
      </v-col>

      <v-col cols="6">
        <p class="thin">School</p>
      </v-col>
    </v-row>

    <div v-for="(school, index) in founder.schools" :key="index">
      <v-row align="center" justify="center">
        <v-col cols="8" sm="6">
          <v-autocomplete
            class="field"
            v-model="school.degree"
            :items="degrees"
            placeholder="MBA"
            outlined
            dense
            @blur="$v.founder.schools.$each[index].degree.$touch()"
          />
        </v-col>

        <v-col cols="8" sm="6">
          <v-text-field
            class="field"
            v-model="school.school"
            outlined
            dense
            placeholder="Harvard"
          />
        </v-col>
      </v-row>
    </div>

    <v-row class="mt-n8">
      <v-col cols="12" sm="2">
        <v-btn
          id="btn-add"
          text
          color="#00aac3"
          @click.prevent.stop="addSchool()"
        >
          + Add a school
        </v-btn>
      </v-col>

      <v-col cols="12" sm="2" v-if="s > 1 || founder.schools.length > 1">
        <v-btn
          id="btn-add"
          text
          color="#00aac3"
          @click.prevent.stop="deleteSchool()"
        >
          - Remove a school
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <p class="text-sm-left thin">Linkedin Profile</p>
      </v-col>

      <v-row align="center" justify="center" class="ml-0">
        <v-col cols="8" class="mt-n5">
          <v-text-field
            class="test"
            v-model="founder.linkedin"
            prepend-inner-icon="mdi-linkedin pb-2"
            outlined
            dense
            placeholder="https://www.linkedin.com/pub/name"
            @blur="$v.founder.linkedin.$touch()"
          />
        </v-col>

        <v-col cols="0" sm="4" />
      </v-row>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12">
        <p class="thin text-sm-left">
          Briefly describe what are the 3 top achievements of the founder
        </p>
      </v-col>

      <v-col cols="8">
        <FormInlineMessage v-if="$v.founder.describe.$error">
          Fill in the field
        </FormInlineMessage>
        <v-textarea
          v-model="founder.describe"
          rows="3"
          outlined
          dense
          @blur="$v.founder.describe.$touch()"
        />
      </v-col>

      <v-col cols="0" sm="4" />
    </v-row>

    <v-row align="center" justify="center" class="mb-16">
      <v-divider />
    </v-row>
  </div>
</template>

<script>
/* Table var skills - 02.10.2022 */
import PreviewImage from "@/components/partials/PreviewImage.vue";
import { required, minLength, url } from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";
import TeamsService from "@/store/services/teams.service";
import PhotoForm from "@/forms/PhotoForm";

import { myMixins } from "@/mixins/mixins";

export default {
  mixins: [myMixins],
  components: {
    FormInlineMessage,
    PreviewImage,
    PhotoForm,
  },

  props: {
    founderProp: Object,
    founderIndex: Number,
    degrees: Array,
    country: Array,
    years: Array,
    entries: Array,
    imagePreviewUrl: String,
    photoBlock: Boolean,
  },

  data() {
    return {
      profileLink: null,
      dialogPhoto: false,
      founderType: null,
      f: 1,
      s: 1,
      queryTerm: " ",
      entries: [],
      founderData: new FormData(),
      founder: {
        name: null,
        country: null,
        birth: null,
        equity: null,
        expertise: [],
        years: null,
        formers: [
          {
            position: null,
            company: null,
          },
        ],
        schools: [
          {
            degree: null,
            school: null,
          },
        ],
        linkedin: "",
        describe: null,
      },
      company_id: localStorage.getItem('company_id'),
    };
  },

  validations: {
    founder: {
      required,
      name: { required },
      country: { required },
      birth: { required },
      equity: { required },
      expertise: { 
        required,
        minLength: minLength(1),
      },
      years: { required },
      formers: {
        required,
        $each: {
          position: { required },
          company: { required },
        },
      },
      schools: {
        required,
        $each: {
          degree: { required },
          school: { required },
        },
      },
      linkedin: { url },
      describe: { required },
    },
  },

  methods: {
    addFormer() {
      this.founder.formers.push({});
      this.f += 1;
    },

    addSchool() {
      this.founder.schools.push({});
      this.s += 1;
    },

    deleteFormer() {
      this.founder.formers.pop();
      this.f -= 1;
    },

    deleteSchool() {
      this.founder.schools.pop();
      this.s -= 1;
    },

    dialogUp(element, type = null) {
      this.founderType = type;
      this.$data["dialog" + element] = true;
    },

    dialogDown(element) {
      this.$data["dialog" + element] = false;
    },

    uploadPhoto(chosenImage) {
      this.founderData.append("photo", chosenImage);
      if (this.founderType == 2) {
        this.profileLink = URL.createObjectURL(chosenImage);
      }
      this.imagePreviewUrl = URL.createObjectURL(chosenImage);
      this.photoBlock = false;
      this.dialogDown("Photo");
    },

    getProp() {
      this.founder.name = this.founderProp.name;
      this.founder.country = this.founderProp.country;
      this.founder.birth = this.founderProp.birth;
      this.founder.equity = this.founderProp.equity;
      // this.founder.expertise = this.founderProp.expertise ? this.founderProp.expertise.split(',') : null;
      this.founder.expertise = this.founderProp.expertise.split(',');
      this.founder.years = this.founderProp.years;
      this.founder.formers = this.founderProp.formers;
      this.founder.schools = this.founderProp.schools;
      this.founder.linkedin = this.founderProp.linkedin;
      this.founder.describe = this.founderProp.describe;

      // this.photoLink = this.founderProp.photoLink

      if (this.founderProp.photoLink) {
        this.profileLink = this.founderProp.photoLink;
        this.photoBlock = false;
      } else {
        this.imagePreviewUrl = this.founderProp.photoLink;
      }

      if (this.founder.linkedin == null) {
        this.founder.linkedin = "";
      }
    },

    saveFounder(company_id) {
      this.$v.$touch();
      if (this.$v.$error || this.less_than_100_percent_error.error) {
        return false;
      }
      this.founderData.append("company_id", company_id);
      this.founderData.append("name", this.founder.name);
      this.founderData.append("country", this.founder.country);
      this.founderData.append("birth", this.founder.birth);
      this.founderData.append("equity", this.founder.equity);
      this.founderData.append("expertise", this.founder.expertise);
      this.founderData.append("years", this.founder.years);
      this.founderData.append("formers", JSON.stringify(this.founder.formers));
      this.founderData.append("schools", JSON.stringify(this.founder.schools));
      this.founderData.append("linkedin", this.founder.linkedin);
      this.founderData.append("describe", this.founder.describe);

      TeamsService.saveFounders(this.founderData).then((response) => {
        if(response.status == 200) {
          this.$toast.open({
            message: "Founder saved successfully !",
            type: 'success',
            position: 'top-right'
          });
        } else {
          this.$toast.open({
            message: "Founder not saved !",
            type: 'error',
            position: 'top-right'
          });
        }
      });
      return true;
    },
  },

  created() {
    this.getProp();
  },
};
</script>

<style>
.field input,
.field input::placeholder {
  text-align: center;
  font-weight: bold;
}
</style>