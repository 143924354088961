<template>
	<div>
	<v-sheet        
	height="100%"

	class="mx-auto mt-10"
	>  
	    <v-row align="center" justify="center" class="pt-8">
		  	<v-col cols="12" md="6" class="pl-2">
	            <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Education</p> 
		    </v-col> 
		    <v-col cols="3">
	            
		    </v-col> 
		    <v-col cols="1">
		    </v-col>

    	</v-row>
		<div v-for="(education, index) in educations" :key="index">

		  <v-row align="center" justify="center" no-gutters class="pt-2">
		  	
		  	<v-col cols="12" md="3">
		  		<p class="text-sm-left">School Name*</p>
		  	</v-col>	
		  	<v-col cols="10" md="6">
		  		<FormInlineMessage v-if="$v.educations.$each[index].school.$error">
                Provide School Name
              	</FormInlineMessage>
		  		<v-text-field
		  			v-model="education.school" 
		  			outlined 
		  			dense 
		  			placeholder="" 
		  			@blur="$v.educations.$each[index].school.$touch()" 
		  		></v-text-field>
		  	</v-col>
		  	<v-col cols="1">
		  	</v-col>

		  </v-row>

	  <v-row align="center" justify="center" no-gutters>
	  	
	  	<v-col cols="12" md="3">
	  		<p class="text-sm-left">Date Attended*</p>
	  	</v-col>	
	  	<v-col cols="10" md="6">
	  	<v-row>	
	  		<v-col cols="6">


	  		<FormInlineMessage v-if="$v.educations.$each[index].start_year.$error">
                When did you start?
            </FormInlineMessage>
			 <v-menu
              ref="menuStart"
              :close-on-content-click="false"
              :return-value.sync="education.start_year"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                	height="40"
                	outlined
                  v-model="education.start_year"
                 placeholder="Start"
                  
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @blur="$v.educations.$each[index].start_year.$touch()" 
                  
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="education.start_year"
                no-title
                scrollable
                type="month"
                
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuStart[index].isActive = false"
                > 
                  Cancel
                
                
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuStart[index].save(education.start_year)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>	  			

	  		</v-col>
	  		<v-col cols="6">	
	  		<FormInlineMessage v-if="$v.educations.$each[index].end_year.$error">
                When did you end?
            </FormInlineMessage>  		

	  			<v-menu
              ref="menuEnd"
              :close-on-content-click="false"
              :return-value.sync="education.end_year"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                	height="40"
                	outlined
                  v-model="education.end_year"
                 placeholder="End"
                  
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @blur="$v.educations.$each[index].end_year.$touch()" 
                  
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="education.end_year"
                no-title
                scrollable
                type="month"
                
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuEnd[index].isActive = false"
                > 
                  Cancel               
                
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuEnd[index].save(education.end_year)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>	 
	  		</v-col>
		  	</v-row>	  		

	  	</v-col>
	  	<v-col cols="1">
	  	</v-col>
	  </v-row>

	  <v-row align="center" justify="center" no-gutters>
	  	
	  	<v-col cols="12" md="3">
	  		<p class="text-sm-left">Degree*</p>
	  	</v-col>	
	  	<v-col cols="10" md="6">	
	  	<FormInlineMessage v-if="$v.educations.$each[index].degree.$error">
                Please select your degree
            </FormInlineMessage>   		
	  		<v-select
	  			v-model="education.degree"
	          :items="degreeList"
	          dense
	          outlined
	          @blur="$v.educations.$each[index].degree.$touch()" 
	        ></v-select>
	  	</v-col>
	  	<v-col cols="1">
	  	</v-col>
	  </v-row>


	  <v-row align="center" justify="center" no-gutters>	  	
	  	<v-col cols="12" md="3">
	  		<p class="text-sm-left">Field Of Study*</p>
	  	</v-col>	
	  	<v-col cols="10" md="6">
	  		<FormInlineMessage v-if="$v.educations.$each[index].field.$error">
                Please indicate field of study
            </FormInlineMessage>  
	  		<v-text-field 
	  			outlined 
	  			dense 
	  			placeholder="" 
	  			v-model="education.field"
	  			@blur="$v.educations.$each[index].field.$touch()"
	  		></v-text-field>
	  	</v-col>
	  	<v-col cols="1">
	  	</v-col>
	  </v-row>


	  <v-row align="center" justify="center" no-gutters>
	  	
	  	<v-col cols="12" md="3">
	  		<p class="text-sm-left">Description*</p>
	  	</v-col>	
	  	<v-col cols="10" md="6">
	  		<FormInlineMessage v-if="$v.educations.$each[index].description.$error">
                Please type the description
            </FormInlineMessage> 
	  		<v-textarea 
	  			v-model="education.description"
	  			outlined 
	  			dense 
	  			placeholder=""
	  			@blur="$v.educations.$each[index].description.$touch()" 
	  		></v-textarea>
	  		
	  	</v-col>
	  	<v-col cols="1">
	  	</v-col>
	  </v-row>
	  <v-row align="center" justify="center" class="mb-5">
	  <v-col cols="11">
	  <v-divider color="#f6f6f6"></v-divider>
	</v-col>
	 </v-row>

</div>

		<!-- Add -->
	  <v-row align="center" justify="center">	  	
	  	<v-col cols="3">
	  	</v-col>
	  	
	  	<v-col cols="9" md="4">
	  	<v-row class="justify-left mb-5">	
	  		<v-btn
	  		  id="btn-add"
		      text
		      color="#00aac3"
			  class="bg-secondary-customized"
		      @click.prevent.stop="addEducation(), currentBlock = true"
		    >
		      + Add education
		    </v-btn>
		</v-row>
		
		</v-col>
		<v-col cols="9" md="3">


			<v-row class="justify-left mb-5">	
	  		<v-btn
	  		  id="btn-add"
		      text
		      color="#00aac3"
			  class="bg-secondary-customized"
		      @click.prevent.stop="deleteEducation(), currentBlock = true"
		    >
		      - Remove education
		    </v-btn>
		</v-row>


		</v-col>	  		
	  </v-row>
 </v-sheet>

  	<v-sheet        
	    height="100%"
	    class="mx-auto"
	    color="#f6f6f6"
	    v-if="currentBlock"
	  >  
	  <v-row align="center" justify="center">	  	
	  	<v-col cols="10"></v-col>	
	  	<v-col cols="12" md="12">  		
	  		
		    <v-icon
				large
				color="#00aac3"
				right
				center            
				@click.prevent.stop="saveEducation"
			>
				mdi-arrow-down-circle
   			</v-icon>
	  	</v-col>
	  </v-row>
	</v-sheet>

</div>
</template>
<script>

	import { required, minLength } from "vuelidate/lib/validators";
	import FormInlineMessage from "@/components/FormInlineMessage";
	import UserEducationService from "@/store/services/user.education.service";

	export default {
		components: {
			FormInlineMessage
		},

		data() {
			return{
				selectedStart:null,
				currentBlock:true,				
				selected:null,
				skills:null,
				degreeList: [
					"Associate's Degree",
					"Bachelor's Degree",
					"Doctor of Medicine (M.D.)",
					"Doctor of Philosophy (Ph.D.)",
					"Engineer's Degree",
					"High School",
					"Juris Doctor (J.D.)",
					"Master of Business Administration (M.B.A.)",
					"Master's Degree",
					"Other"
				],


				educations: [
					{start_year:null,end_year:'',school:'',degree:'',field:'',description:''}					
				]
			}

		},


		validations: {
			
		    educations: {
		    	required,
		    	$each: {
		    		school:{
		    			required,		    			
		    		},
		    		start_year: {
		    			required,

		    		},
		    		end_year: {
		    			required
		    		},
		    		degree: {
		    			required
		    		},
		    		field: {
		    			required,
		    			minLength:minLength(3)
		    		},
		    		description: {
		    			required,
		    			minLength:minLength(3)
		    		}
		    	}
			    
		    }
		    
		},

		methods:{
			saveEducation() {
				this.$v.$touch();
			      if (this.$v.$error) {
			      	return this.$toast.open({
                        message: "Education is required, please add your education!",
                        type: 'error',
                        position: 'top-right'
                    }); 
			    }

			    UserEducationService.save({'user_education':this.educations});
			    this.currentBlock=false;
				this.$emit("block3");
			},

			addEducation() {
				this.educations.push({});
			},

			deleteEducation(){
	          const educationId = this.educations[this.educations.length-1]['id'] ?? false;
	          if(educationId) {
	            UserEducationService.destroy(educationId)
	            .then((response)=>{
	              this.educations.pop();
	              return;
	          })
	            .catch(error=>console.log(error));        
	          } else {
	            this.educations.pop();
	          }
	        }    
		},

		created() {	
			UserEducationService.getAll().then(res=>{
					if(res.data) {						
						this.educations = res.data;
					}					 
			});
		}
	}

</script>