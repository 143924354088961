<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-10">
      <v-row align="center" justify="center" class="pt-2">
        <!-- Tittle -->
        <v-col cols="12" md="6" class="pl-2">
          <p class="light-green--text text--darken-3 text-sm-left" id="title">My Proficiencies</p>
        </v-col>

        <v-col cols="4"/>
      </v-row>

      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" md="3">
          <p class="text-sm-left">Skills*</p>
        </v-col>

        <v-col cols="10" md="6">
          <FormInlineMessage v-if="$v.knowHow.skills.$error">
            Please select skills
          </FormInlineMessage>
          
          <v-combobox
          v-model="knowHow.skills"
          :items="entries"
          item-text="name"		          
          label=""
          outlined
          dense
          multiple
          :search-input.sync="search"
          small-chips
          deletable-chips
          @blur="$v.knowHow.skills.$touch()"/>
        </v-col>

        <v-col cols="1"/>
      </v-row>

      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" md="3">
          <p class="text-sm-left">Personal Attributes*</p>
        </v-col>	

        <v-col cols="10" md="6">
          <FormInlineMessage v-if="$v.knowHow.abilities.$error">
            Please select Personal Attributes
          </FormInlineMessage>
              
          <v-autocomplete
          v-model="knowHow.abilities"
          :items="allAbilities"
          outlined
          dense
          small-chips
          multiple
          deletable-chips
          @blur="$v.knowHow.abilities.$touch()"/>
        </v-col>

        <v-col cols="1"/>
      </v-row>

      <v-row align="start" justify="center" no-gutters class="mt-2" style="position: relative;">
        <v-col cols="12" md="3" class="" >
          <p class="text-sm-left" style="position: absolute">Languages*</p>
        </v-col>	
        <v-col cols="10" md="6">
          <FormInlineMessage v-if="$v.knowHow.abilities.$error">
            Please choose language
          </FormInlineMessage>
          <div v-for="(language, index) in knowHow.languages" :key="language.language">
            <v-row dense>
              <v-col cols="12" md="6" >
                <FormInlineMessage v-if="$v.knowHow.languages.$each[index].language.$error">
                  Please choose language
                </FormInlineMessage>

                <v-select
                v-model="language['language']"
                :items="allLanguages"
                item-text="name"
                dense
                outlined
                @blur="$v.knowHow.languages.$each[index].language.$touch()"/>
              </v-col>

              <v-col cols="12" md="6">
                <FormInlineMessage v-if="$v.knowHow.languages.$each[index].proficiency.$error">
                  Please choose proficiency
                </FormInlineMessage>

                <v-select
                v-model="language['proficiency']"
                :items="proficiencyList"
                dense
                outlined
                @blur="$v.knowHow.languages.$each[index].proficiency.$touch()"/>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" no-gutters class="mt-n5">
              <v-col cols="10" md="12"><v-divider color="#f6f6f6" class="mb-8"/></v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="1"/>
      </v-row>

      <!-- Add -->
      <v-row align="center" justify="center" class="mt-n2">
        <v-col cols="3"/>

        <v-col cols="9" md="4">
          <v-row class="justify-left mb-5">
            <v-btn
            id="btn-add"
            text
            color="#00aac3"
            class="bg-secondary-customized"
            @click.prevent.stop="addLanguage(), currentBlock = true">
              + Add Language
            </v-btn>
          </v-row>
        </v-col>

        <v-col cols="9" md="3">
          <v-row class="justify-left mb-5">	
            <v-btn
            id="btn-add"
            text
            color="#00aac3"
            class="bg-secondary-customized"
            @click.prevent.stop="deleteLanguage(), currentBlock = true">
              - Remove language
            </v-btn>
          </v-row>
        </v-col> 
      </v-row>
    </v-sheet>

    <v-sheet
    height="100%"
    class="mx-auto"
    color="#f6f6f6"
    v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10"/>

        <v-col cols="12" md="12">
          <v-icon
          large
          color="#00aac3"
          right
          center
          @click.prevent.stop="saveKnowhow">
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script >
  import AllVar from "@/store/services/var";
  import Abilities from "@/abilities.js";
  import Languages from "@/languages.js";
  import UserKnowhowService from "@/store/services/user.knowhow.service";

  import { required, minLength } from "vuelidate/lib/validators";
  import FormInlineMessage from "@/components/FormInlineMessage";

  const diffArray = (arr1, arr2) =>
    arr1
      .concat(arr2)
      .filter((val) => !(arr1.includes(val) && arr2.includes(val)));

  export default {
    components: {
        FormInlineMessage,
    },

    data() {
      return {
        currentBlock: true,
        queryTerm: "",
        entries: [],
        proficiencyList: [
          "Elementary proficiency",
          "Limited working proficiency",
          "Professional working proficiency",
          "Full professional proficiency",
          "Native or bilingual proficiency",
        ],

        items: ["blbla", "hhsa"],
        knowHow: {
          skills: [],
          abilities: [],
          experience: "",
          languages: [{}, {}],
        },
      };
    },

    watch: {
      abilitiesChange: function (newVal, oldVal) {
        if (newVal.length < oldVal.length) {
          const toDelete = diffArray(newVal, oldVal);
          this.deleteAbility(toDelete);
        }
      },

      skillsChange: function (newVal, oldVal) {
        if (newVal.length < oldVal.length) {
          const toDelete = diffArray(newVal, oldVal);
          this.deleteSkill(toDelete);
        }
      },
    },

    validations: {
      knowHow: {
        required,

        skills: {
          required,
          minLength: minLength(1),
        },

        abilities: {
          required,
          minLength: minLength(1),
        },

        languages: {
          required,
          $each: {
            language: { required, },
            proficiency: { required, },
          },
        },
      },
    },

    methods: {
      saveKnowhow() {
        this.$v.$touch();
        if (this.$v.$error) { 
          if (this.knowHow.skills.length != 0 && this.knowHow.abilities.length != 0 && this.knowHow.languages.length == 0) {
            return this.$toast.open({
              message: "Language is required, please add your Languages!",
              type: 'error',
              position: 'top-right'
            });
          }
          return; 
        }

        UserKnowhowService.saveSkills(this.knowHow.skills);
        UserKnowhowService.saveAbilities(this.knowHow.abilities);
        UserKnowhowService.saveLanguages(this.knowHow.languages);

        this.currentBlock = false;
        this.$emit("block5");
      },

      loadEntries() {
        let that = this;

        AllVar.getAllSkills(this.queryTerm).then((res) => {
          that.entries = res.data;
        });
      },

      addLanguage() { this.knowHow.languages.push({}); },

      deleteAbility(toDelete) {
        UserKnowhowService.destroyAbility(toDelete)
          .then((res) => {})
          .catch((err) => {});
      },

      deleteSkill(toDelete) {
        UserKnowhowService.destroySkill(toDelete)
          .then((res) => {})
          .catch((err) => {});
      },

      deleteLanguage() {
        let language = this.knowHow.languages[this.knowHow.languages.length - 1];
        const user_id = language["user_id"] ?? false;
        language = language["language"] ?? false;
        if (user_id && language) {
          UserKnowhowService.destroyLanguage([language])
          .then((response) => {
            this.knowHow.languages.pop();
          })
          .catch((error) => {
            //console.log(error)
          });
        } else {
          this.knowHow.languages.pop();
        }
      },
    },

    computed: {
      search: {
        get: function () { return this.queryTerm; },

        set: function (searchInput) {
          /* in comment - table skills 02-10-2022 */
          // if (!searchInput) { return; }

          if (this.queryTerm !== searchInput) {
            this.queryTerm = searchInput;
            this.loadEntries();
          }
        },
      },

      allLanguages() { return Object.values(Languages); },
      allAbilities() { return Abilities; },
      abilitiesChange() { return this.knowHow.abilities; },
      skillsChange() { return this.knowHow.skills; },
    },

    created() {
      this.loadEntries();

      UserKnowhowService.getSkills().then((res) => {
        if (res.data) {
          this.knowHow.skills = res.data;
          return;
        }
      });

      UserKnowhowService.getAbilities().then((res) => {
        if (res.data) {
          this.knowHow.abilities = res.data;
          return;
        }
      });

      UserKnowhowService.getLanguages().then((res) => {
        if (res.data) {
          this.knowHow.languages = res.data;
          return;
        }
      });
    },
  };
</script>

<style>
  .v-autocomplete__content .v-input--selection-controls__input {
  display: none !important;
  padding: 0;
  }

  /*#list-361 .v-input--selection-controls__input{
    display: none !important;
    padding:0;
  }*/

  .v-autocomplete__content .v-list-item__action {
  padding: 0 !important;
  margin: 0 !important;
  }
</style>
