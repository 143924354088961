<template>
    <div class="body">
      <Header class="d-none d-md-flex"/>
      <HeaderPortable class="d-flex d-md-none"/>
      <v-row>
        <v-col cols="0" md="2" class="d-none d-md-flex">
          <Menu />
        </v-col>
        
        <v-row align="center" justify="center" class="mt-10">
            <v-col cols="10">
                <v-col cols="12" md="8">
                    <p class="cyan--text text--darken-1 mt-3 text-sm-center" id="title">{{ !$route.params.id ? 'Create' : 'Update'}} Coupon</p>
                </v-col>
                <!-- Body -->
                <div id="personal">
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.title.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Coupon Title*</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.coupons.title.$error">
                                Please type the title
                            </FormInlineMessage>
                            <v-text-field
                                v-model="coupons.title"
                                outlined
                                dense/>
                        </v-col>
                    </v-row>

                    <v-row class="mb-n8">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.code.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Code*</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.coupons.code.$error">
                                The code must be at least 8 characters
                            </FormInlineMessage>
                            <v-text-field
                                v-model="coupons.code"
                                outlined
                                dense/>
                        </v-col>
                    </v-row>

                    <v-row class="mb-n8">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.description.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Description</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.coupons.description.$error">
                                Please type the description
                            </FormInlineMessage>
                            <v-textarea
                            v-model="coupons.description"
                            outlined
                            dense/>
                        </v-col>
                    </v-row>

                    <v-row class="mb-n8">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.product.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Product*</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.coupons.product.$error">
                                Please select the product
                            </FormInlineMessage>
                            <v-select
                            v-model="coupons.product"
                            :items="products"
                            dense
                            outlined/>
                        </v-col>
                    </v-row>

                    <v-row class="mb-n8">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.user_profil.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">User Profile*</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.coupons.user_profil.$error">
                                Please select the user profile
                            </FormInlineMessage>
                            <v-select
                            v-model="coupons.user_profil"
                            :items="profiles"
                            dense
                            outlined/>
                        </v-col>
                    </v-row>

                    <v-row class="mb-n8">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.active_from.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Active from*</p>
                        </v-col>

                        <!-- Begin date -->
                        <v-col cols="7" sm="3">
                            <FormInlineMessage v-if="$v.coupons.active_from.$error">
                                When the coupon start?
                            </FormInlineMessage>
                            <v-menu
                            ref="menuBegin"
                            :close-on-content-click="false"
                            :return-value.sync="coupons.active_from"
                            offset-y
                            
                            min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    height="40"
                                    outlined
                                    v-model="coupons.active_from"
                                    placeholder="Active From Date"
                                    readonly
                                    v-bind="attrs"
                                    dense
                                    v-on="on"/>
                                </template>

                                <v-date-picker
                                    v-model="coupons.active_from"
                                    no-title
                                    scrollable
                                    type="date">

                                    <v-spacer/>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuBegin.isActive = false">
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuBegin.save(coupons.active_from)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.active_to.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Active to*</p>
                        </v-col>
                        <!-- Begin date -->
                        <v-col cols="7" sm="3">
                            <FormInlineMessage v-if="$v.coupons.active_to.$error">
                                When does the coupon end?
                            </FormInlineMessage>
                            <v-menu
                            ref="menuEnd"
                            :close-on-content-click="false"
                            :return-value.sync="coupons.active_to"
                            offset-y
                            
                            min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    height="40"
                                    outlined
                                    v-model="coupons.active_to"
                                    placeholder="Active To Date"
                                    readonly
                                    v-bind="attrs"
                                    dense
                                    v-on="on"/>
                                </template>

                                <v-date-picker
                                    v-model="coupons.active_to"
                                    no-title
                                    scrollable
                                    type="date">

                                    <v-spacer/>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuEnd.isActive = false">
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuEnd.save(coupons.active_to)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    

                    <v-row class="mb-n8">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.available_stock.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Available Stock*</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.coupons.available_stock.$error">
                                Please type the available stock
                            </FormInlineMessage>
                            <v-text-field
                                v-model="coupons.available_stock"
                                outlined
                                dense
                                @keypress="isNumber($event)"/>
                        </v-col>
                    </v-row>

                    <v-row class="mb-n8">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.used_stock.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Used Stock</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.coupons.used_stock.$error">
                                Please type the used stock
                            </FormInlineMessage>
                            <v-text-field
                                v-model="coupons.used_stock"
                                disabled="disabled"
                                outlined
                                filled
                                dense/>
                        </v-col>
                    </v-row>

                    <v-row class="mb-n8">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.remaining_stock.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Remaining Stock</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.coupons.remaining_stock.$error">
                                Please type the remaining stock
                            </FormInlineMessage>
                            <v-text-field
                                v-model="coupons.remaining_stock"
                                disabled="disabled"
                                outlined
                                filled
                                dense/>
                        </v-col>
                    </v-row>

                    <v-row class="">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <p class="text-sm-left">Coupon Status*</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <div class="anableDesable">
                                <span :class="is_active ? 'active' : ''" @click="is_active = true">
                                    Active
                                </span>
                                <span :class="!is_active ? 'active' : ''" @click="is_active = false">
                                    No Active
                                </span>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <p class="text-sm-left">Coupon Type*</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <div class="anableDesable">
                                <span :class="is_percent ? 'active' : ''" @click="is_percent= true">
                                    Percent
                                </span>
                                <span :class="!is_percent ? 'active' : ''" @click="is_percent= false">
                                    Value
                                </span>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="mb-n8" v-if="is_percent">
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.percent_discount.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Percent Discount % *</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.coupons.percent_discount.$error">
                                Please type the percent discount
                            </FormInlineMessage>
                            <v-text-field
                                v-model="coupons.percent_discount"
                                outlined
                                dense
                                prefix="%"
                                @keypress="isNumber($event)"/>
                        </v-col>
                    </v-row>

                    <v-row class="mb-n8" v-else>
                        <v-col cols="12" sm="12" class="mb-n8">
                            <FormInlineMessage v-if="$v.coupons.amount_discount.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Amount Discount *</p>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <FormInlineMessage v-if="$v.coupons.amount_discount.$error">
                                Please type the amount discount
                            </FormInlineMessage>
                            <v-text-field
                                v-model="coupons.amount_discount"
                                outlined
                                dense
                                @keypress="isNumber($event)"/>
                        </v-col>
                    </v-row>

                    <!-- Save button -->
                    <v-row align="center" justify="center" class="mt-2">
                        <v-col cols="0" sm="5"/>
                        <v-col cols="12" sm="1" v-if="loader">
                            <img src="../../assets/img/Spinner-1s-200px.gif" alt="" height="50">
                        </v-col>
                        <v-col cols="12" sm="1" v-else>
                            <v-btn
                            id="btn-photo"
                            class="white--text mb-3"
                            style="font-weight: bold; font-size: 14px"
                            color="#11aec9"
                            min-width="20"
                            rounded
                            block
                            :disabled="false"
                            @click.stop.prevent="saveCoupons()">
                                {{save_btn_text}}
                            </v-btn>
                        </v-col>
                        <v-col cols="0" sm="2"/>
                    </v-row>
                </div>
            </v-col>
        </v-row>
      </v-row>
    </div>
</template>
  
<script>
    import { required, not, minLength } from "vuelidate/lib/validators";
    import Header from '@/components/admin/AdminHeader.vue';
    import HeaderPortable from '@/components/admin/AdminHeadePortable.vue';
    import Menu from '@/components/admin/AdminLeftMenu.vue';
    import Var from '@/store/services/var.js'
    import Admin from "@/store/services/admin/company";
    import FormInlineMessage from "@/components/FormInlineMessage";
  
export default {
    components: {
        Header,
        HeaderPortable,
        Menu,
        FormInlineMessage
    },
    data() {
        return {
            loader: false,
            is_checked: false,
            is_percent: true,
            is_active: true,
            products: [],
            profiles: [],
            coupons : {
                title: null, 
                code: null, 
                description: null, 
                product: null, 
                user_profil: null, 
                active_from: null, 
                active_to: null, 
                available_stock: null, 
                used_stock: 0,
                remaining_stock: 0,
                coupon_status: null, 
                coupon_type: null, 
                percent_discount: null, 
                amount_discount: null
            },
            save_btn_text: 'Save'
        }
    },
      

    validations: {
        coupons : {
            required,
            title: { required }, 
            code: { 
                required,
                minLength: minLength(8) 
            }, 
            description: { required }, 
            product: { required }, 
            user_profil: { required }, 
            active_from: { required }, 
            active_to: { required }, 
            available_stock: { required }, 
            used_stock: { required },
            remaining_stock: { required },
            coupon_status: { required }, 
            coupon_type: { required }, 
            percent_discount: { not }, 
            amount_discount: { not }
        },
    },

    methods:{
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            let charCode = evt.which ? evt.which : evt.keyCode;
            if ((charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        saveCoupons() {
            this.loader = true;
            if (this.is_active == true) {
                this.coupons.coupon_status = 'Active';
            } else {
                this.coupons.coupon_status = 'No Active';
            }
            if (this.is_percent == true) {
                this.coupons.coupon_type = 'Percent';
            } else {
                this.coupons.coupon_type = 'Value';
            }

            this.$v.$touch();
            if (this.$v.$error) {
                this.loader = false;
                return;
            }
            
            if (!this.$route.params.id) {
                Admin.createCoupons(this.coupons).then((res) =>{
                    if (res.status == 200) {
                        this.$router.push('/admin/coupon-list');
                        this.$toast.open({
                            message: res.data,
                            type: 'success',
                            position: 'top-right'
                        }); 
                    } else if (res.status == 202) {
                        this.$toast.open({
                            message: res.data,
                            type: 'info',
                            position: 'top-right'
                        });
                        this.loader = false;
                    } else {
                        this.$toast.open({
                            message: 'Creation error!',
                            type: 'warning',
                            position: 'top-right'
                        });
                    } 
                    this.loader = false;
                })
            } else {
                Admin.updateCoupon(this.coupons, this.$route.params.id).then((res) =>{
                    if (res.status == 200) {
                        this.$toast.open({
                            message: res.data,
                            type: 'success',
                            position: 'top-right'
                        });
                        this.loader = false;
                        this.getCoupon();
                    } else {
                        this.$toast.open({
                            message: 'You can\'t update',
                            type: 'warning',
                            position: 'top-right'
                        });
                        this.loader = false;
                    } 
                    this.loader = false;
                }) 
            }
        },

        getCoupon() {
            Admin.getCoupon(parseInt(this.$route.params.id)).then((res) =>{
                let coupon = res.data;
                this.coupons.title = coupon.title;
                this.coupons.code = coupon.code;
                this.coupons.description = coupon.description;
                this.coupons.product = coupon.product;
                this.coupons.user_profil = coupon.user_profil;
                this.coupons.active_from = coupon.active_from;
                this.coupons.active_to = coupon.active_to;
                this.coupons.available_stock = coupon.available_stock;
                this.coupons.used_stock = coupon.used_stock;
                this.coupons.remaining_stock = coupon.remaining_stock;
                this.coupons.coupon_status = coupon.coupon_status == 'Active' ? this.is_active = true : this.is_active = false;
                this.coupons.coupon_type = coupon.coupon_type == 'Percent' ? this.is_percent = true : this.is_percent = false;
                this.coupons.percent_discount = coupon.percent_discount;
                this.coupons.amount_discount = coupon.amount_discount;
            })
        }
    },

    mounted() {
        Var.getProducts().then((res) => {
            this.products = res.data;
        })

        Var.getUserProfiles().then((res) => {
            this.profiles = res.data;
        })

        if(this.$route.params.id) {
            this.save_btn_text = 'Update';
            this.getCoupon();
        }
    }

}
  
</script>
<style src="../../assets/styles/admin/style.css"> </style>
<style scoped src="../../assets/styles/checkBoxInput.css"></style>
  