<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-10">
      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="1" />

        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            Product
          </p>

          <v-col />

          <!-- Service Offer -->
          <div>
            <v-row>
              <v-col cols="3">
                <p class="text-sm-left pt-2">
                  Primary product/service you offer
                </p>
              </v-col>

              <v-col col="auto">
                <FormInlineMessage
                  v-if="$v.product.service_offer.$error"
                  class="errmsg"
                >
                  Select your primary product/service
                </FormInlineMessage>
                <v-select
                  v-model="product.service_offer"
                  :items="allPrimary"
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-text-field
              v-if="product.service_offer === 'Other'"
              v-model="product.service_offer_other"
              outlined
              dense
              placeholder=""
              @blur="$v.product.service_offer_other.$touch()"
            />
          </div>

          <!-- Service Does -->
          <div>
            <p class="text-sm-left">
              What your product/service does ?<br />
              <span class="blue-grey--text text--lighten-4 thin"
                >One step-by-step walk through of how the product works from a
                user's perspective.<br />
                (Only focus on the "bare bones" core aspect of the product that
                are required to solve the problem you are solving.)</span
              >
            </p>

            <FormInlineMessage
              v-if="$v.product.service_does.$error"
              class="errmsg"
            >
              Select your primary product/service
            </FormInlineMessage>
            <v-textarea
              v-model="product.service_does"
              outlined
              rows="2"
              dense
              @blur="$v.product.service_does.$touch()"
            />
          </div>

          <!-- Technology Focus -->
          <div>
            <v-row>
              <v-col cols="3">
                <p class="text-sm-left">Technology focus</p>
              </v-col>

              <v-col class="justify-space-between">
                <v-btn
                  @click="
                    product.technology_focus = true;
                    technology_focus_no = false;
                  "
                  v-bind:color="product.technology_focus ? 'primary' : ''"
                  class="mr-15"
                >
                  YES
                </v-btn>

                <v-btn
                  @click="
                    product.technology_focus = false;
                    technology_focus_no = true;
                  "
                  v-bind:color="technology_focus_no ? 'primary' : ''"
                  class="mr-15"
                >
                  NO
                </v-btn>
              </v-col>
            </v-row>

            <!-- Technology -->
            <div v-if="product.technology_focus">
              <p class="text-sm-left pt-5">
                Which Technology ?<br />
                <span class="blue-grey--text text--lighten-4 thin"
                  >The specific technologies that you use and anything else
                  worth mentioning about your system.</span
                >
              </p>
              <v-textarea
                v-model="product.technology"
                outlined
                rows="2"
                dense
                @blur="$v.product.technology.$touch()"
              />
            </div>
          </div>

          <!-- RD -->
          <div>
            <v-row>
              <v-col cols="3">
                <p class="text-sm-left pt-1">With R&D</p>
              </v-col>

              <v-col class="justify-space-between">
                <v-btn
                  @click="
                    product.rd = true;
                    rd_no = false;
                  "
                  v-bind:color="product.rd ? 'primary' : ''"
                  class="mr-15"
                >
                  YES
                </v-btn>

                <v-btn
                  @click="
                    product.rd = false;
                    rd_no = true;
                  "
                  v-bind:color="rd_no ? 'primary' : ''"
                  class="mr-15"
                >
                  NO
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <!-- Exclusive Technology -->
          <div>
            <v-row>
              <v-col cols="3">
                <p class="text-sm-left">
                  Do you have any exclusive/proprietary rights or patents on the
                  technology ?
                </p>
              </v-col>

              <v-col class="justify-space-between">
                <v-btn
                  @click="
                    product.exclusive_technology = true;
                    exclusive_technology_no = false;
                  "
                  v-bind:color="product.exclusive_technology ? 'primary' : ''"
                  class="mr-15"
                >
                  YES
                </v-btn>

                <v-btn
                  @click="
                    product.exclusive_technology = false;
                    exclusive_technology_no = true;
                  "
                  v-bind:color="exclusive_technology_no ? 'primary' : ''"
                  class="mr-15"
                >
                  NO
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" />
              <v-col cols="7">
                <v-textarea
                  v-if="product.exclusive_technology"
                  v-model="product.exclusive_technology_resume"
                  outlined
                  rows="2"
                  dense
                  @blur="$v.product.exclusive_technology_resume.$touch()"
                />
              </v-col>
              <v-col cols="1" />
            </v-row>
          </div>

          <!-- Life Cycle -->
          <div>
            <v-row>
              <v-col cols="3">
                <p class="text-sm-left">Product Life Cycle Stage</p>
              </v-col>

              <v-col>
                <FormInlineMessage
                  v-if="$v.product.life_cycle.$error"
                  class="errmsg"
                >
                  Select your product life cycle stage
                </FormInlineMessage>
                <v-select
                  v-model="product.life_cycle"
                  :items="allStage"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </div>

          <!-- Unique -->
          <div>
            <p class="text-sm-left">What make it unique ?</p>
            <ul>
              <li class="text-sm-left blue-grey--text text--lighten-2 thin">
                Product differentiation: You've got something completely
                different (and hard to replicate).
              </li>
              <li class="text-sm-left blue-grey--text text--lighten-2 thin">
                Process differentiation: You are selling a new, more efficient
                way of doing things.
              </li>
              <li class="text-sm-left blue-grey--text text--lighten-2 thin">
                Price point differentiation: You have found a way to sell a
                product or service for less or more (i.e. premium pricing).
              </li>
              <li class="text-sm-left blue-grey--text text--lighten-2 thin">
                Super niche differentiation: You've found a market that's a
                particularly good fit for you.
              </li>
            </ul>

            <v-col />

            <FormInlineMessage v-if="$v.product.unique.$error" class="errmsg">
              Select your difference
            </FormInlineMessage>
            <v-select
              v-model="product.unique"
              :items="allDiff"
              outlined
              dense
            />
          </div>

          <!-- Advantage -->
          <div>
            <p class="text-sm-left thin">
              Please tell us more about your competitive advantage ?
            </p>
            <FormInlineMessage
              v-if="$v.product.advantage.$error"
              class="errmsg"
            >
              Fill the field
            </FormInlineMessage>
            <v-textarea
              v-model="product.advantage"
              outlined
              rows="2"
              dense
              @blur="$v.product.advantage.$touch()"
            />
          </div>
        </v-col>

        <v-col cols="1" />
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveProduct"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import AllVar from "@/store/services/var";
import BusinessService from "@/store/services/business.service";
import FormInlineMessage from "@/components/FormInlineMessage";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    FormInlineMessage,
  },

  // props: {
  //   company_id: Number,
  // },

  data() {
    return {
      currentBlock: true,
      allDescr: [],
      allPrimary: [],
      allStage: [],
      allDiff: [],
      technology_focus_no: false,
      exclusive_technology_no: false,
      rd_no: false,
      product: {
        company_id: "",
        service_offer: "",
        service_offer_other: "",
        service_does: "",
        technology_focus: false,
        technology: "",
        rd: false,
        exclusive_technology: false,
        exclusive_technology_resume: "",
        life_cycle: "",
        unique: "",
        advantage: "",
      },
      company_id: localStorage.getItem('company_id'),
    };
  },

  validations: {
    product: {
      required,
      service_offer: { required },
      service_does: { required },
      technology_focus: {
        notSameAs() {
          return this.technology_focus_no != this.product.technology_focus;
        },
      },

      rd: {
        notSameAs() {
          return this.rd_no != this.product.rd;
        },
      },

      exclusive_technology: {
        notSameAs() {
          return (
            this.exclusive_technology_no != this.product.exclusive_technology
          );
        },
      },

      life_cycle: { required },
      unique: { required },
      advantage: { required },
    },
  },

  methods: {
    saveProduct() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      this.product.company_id = this.company_id;
      BusinessService.saveProduct(this.product);
      this.currentBlock = false;
      this.$emit("block3");
    },

    getProduct() {
      BusinessService.getProduct(this.company_id)
        .then((res) => {
          if(res.data){
          this.product = res.data;
          this.technology_focus_no = !res.data.technology_focus;
          this.exclusive_technology_no = !res.data.exclusive_technology;
          this.rd_no = !res.data.rd;
          }
        })
        .catch((e) => {});
    },

    getVariables() {
      AllVar.getAllProdVariables().then((res) => {
        this.allDiff = res.data.allMakeProductUnique;
        this.allStage = res.data.productLifeCycleToSend;
        this.allPrimary = res.data.allTypeOfOffer;
      })
    },
  },

  created() {
    this.getVariables();
  },
  mounted() {
    this.getProduct();
  },
};
</script>
