import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

axios.defaults.headers.post["Content-Type"] = "application/vnd.api+json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token");
axios.defaults.headers.common["Accept"] = "application/vnd.api+json";

function submitContactForm(payload) {
  return axios.post(`${url}/contact/form`, payload);
}

function submitPitchDeckContactForm(payload) {
  return axios.post(`${url}/mail/generate`, payload);
}

function submitPitchDeckForm(payload) {
  return axios.post(`${url}/mail/pitch`, payload);
}

function consustancyOrder(payload) {
  return axios.post(`${url}/mail/consustancy`, payload);
}

export default {
  submitContactForm,
  submitPitchDeckContactForm,
  submitPitchDeckForm,
  consustancyOrder
};
  