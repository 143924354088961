<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-10">
      <v-row align="center" justify="center" class="pt-2">
        <!-- Tittle -->
        <v-col cols="12" md="6" class="pl-2">
          <p class="light-green--text text--darken-3 text-sm-left" id="title">
            My know-how
          </p>
        </v-col>

        <v-col cols="4" />
      </v-row>

      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" md="3">
          <p class="text-sm-left">Main Fields of Expertise*</p>
        </v-col>

        <v-col cols="10" md="6">
          <FormInlineMessage v-if="$v.knowHow.skills.$error">
            Please enter valid fields
          </FormInlineMessage>

          <v-combobox
            v-model="knowHow.skills"
            :items="entries"
            item-text="name"
            label=""
            outlined
            dense
            multiple
            :search-input.sync="search"
            small-chips
            deletable-chips
            @blur="$v.knowHow.skills.$touch()"
          />
        </v-col>

        <v-col cols="1" />
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />

        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveKnowhow"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
// import Skills from "@/store/services/skills-service";
import AllVar from "@/store/services/var";
import UserKnowhowService from "@/store/services/user.knowhow.service";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  minLength,
  maxLength,
  numeric,
  minValue,
  email,
  sameAs,
  url,
} from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";

const diffArray = (arr1, arr2) =>
  arr1
    .concat(arr2)
    .filter((val) => !(arr1.includes(val) && arr2.includes(val)));

export default {
  components: {
    FormInlineMessage,
  },

  data() {
    return {
      currentBlock: true,
      queryTerm: "",
      entries: [],
      items: ["blbla", "hhsa"],
      knowHow: {
        skills: [],
      },
    };
  },

  watch: {
    skillsChange: function (newVal, oldVal) {
      //console.log(newVal,oldVal)
      if (newVal.length < oldVal.length) {
        const toDelete = diffArray(newVal, oldVal);
        this.deleteSkill(toDelete);
      }
    },
  },

  validations: {
    knowHow: {
      required,

      skills: {
        required,
        minLength: minLength(1),
      },
    },
  },

  methods: {
    saveKnowhow() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }

      UserKnowhowService.saveSkills(this.knowHow.skills);

      this.currentBlock = false;
      this.$emit("block5");
    },

    loadEntries() {
      var that = this;

      AllVar.getAllSkills(this.queryTerm).then((res) => {
        //console.log(this.entries,res.data.data);
        that.entries = res.data;
      });
    },

    deleteSkill(toDelete) {
      //console.log(toDelete); return
      UserKnowhowService.destroySkill(toDelete)
        .then((res) => {})
        .catch((err) => {});
    },
  },

  computed: {
    search: {
      get: function () {
        return this.queryTerm;
      },

      set: function (searchInput) {
        /* in comment - table skills 02-10-2022 */
        // if (!searchInput) { return; }

        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadEntries();
        }
      },
    },
    skillsChange() {
      return this.knowHow.skills;
    },
  },

  created() {
    this.loadEntries();

    UserKnowhowService.getSkills().then((res) => {
      if (res.data) {
        //console.log(res.data)
        this.knowHow.skills = res.data;
        //console.log(this.knowHow.skills);
        // this.$refs.gmapAutocomplete.$refs.input.value = this.experiences.location;
        // this.$refs.phoneComplete.$refs.input.value = this.personal.phone;
        // console.log(this.$refs.gmapAutocomplete.$refs);
        // this.personal.social_network = JSON.parse(this.personal.social_network)
        return;
      }
    });
  },
};
</script>

<style>
.v-autocomplete__content .v-input--selection-controls__input {
  display: none !important;
  padding: 0;
}

/*#list-361 .v-input--selection-controls__input{
    display: none !important;
    padding:0;
  }*/

.v-autocomplete__content .v-list-item__action {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
