<template>
  <v-row align="center" justify="center" class="step-1">
    <v-col cols="10">
      <v-responsive :aspect-ratio="16 / 9">
        <v-card-text>
          <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec">
            <v-row justify="center" class="mx-10">
              <v-col cols="2">
                <v-img
                  style="border-radius: 20%"
                  min-height="95%"
                  :src="logoPreviewUrl"
                />
              </v-col>
              <v-col cols="9">
                <p class="text-sm-left">
                  {{ me.company_name }}<br /><br />
                  <span class="bold">Main Contact<br /></span>
                  {{ me.first_name }}<br />
                  {{ me.last_name }}<br />
                  {{ me.email }}
                </p>
              </v-col>
            </v-row>
          </v-sheet>
          <v-col />

          <Term @block2="addressBlock = true" />

          <Address @block3="goToPriceBlock" v-if="addressBlock" :company_id="company_id" />

          <Price v-if="priceBlock" v-bind:address="this.addressPayment" :company_id="company_id"/>
        </v-card-text>
      </v-responsive>
    </v-col>
  </v-row>
</template>

<script>
import Term from "@/components/Steps/Step7/Blocks/TermBlock";
import Address from "@/components/Steps/Step7/Blocks/AddressBlock";
import Price from "@/components/Steps/Step7/Blocks/PriceBlock";
import CompanyService from "@/store/services/company/company.service.js";

export default {
  components: {
    Term,
    Address,
    Price,
  },

  props: {
    me: Object,
    company_id: Number,
  },

  data() {
    return {
      addressBlock: false,
      priceBlock: false,
      addressPayment: {},
      logoPreviewUrl: null,
    };
  },

  methods: {
    retrieveLogo() {
      CompanyService.getLogo(this.company_id)
        .then((res) => {
          this.logoPreviewUrl = res.data;
        })
        .catch((error) => {});
    },
    goToPriceBlock(value) {
      this.priceBlock = true;
      this.addressPayment= value;
    },
  },
  mounted() {
    this.retrieveLogo();
  },
};
</script>

<style scoped>
p {
  font-weight: normal !important;
}

.bold {
  font-weight: bold;
  font-size: 14px;
}
</style>
