export default ["Active and dynamic", 
"Analysis and critical spirit",
"At ease with ideas",
"At ease with people",
"Attached to present",
"Attached to principles",
"Curiosity",
"Empathy",
"Enthousiastic", 
"Imaginative",
"Intuitive",
"Like changes",
"Like to decide and conclude",
"Like to define deadlines",
"Like to make compliments",
"Like to plan",
"Methodic",
"Organised",
"Oriented objectives",
"Overall Approach",
"Pragmatic",
"Reserved",
"Search Information",
"Sense of détail",
"Spontaneity",
"Structured",
"Very social",
"Flexibility"]

