<template>
<v-sheet style="background-color: #f6f6f6; position:relative; min-height: 93vh; width: 220px; overflow: hidden; height: 100%;">
    <v-row style="margin-top: 50px;">
        <v-col cols="11" style="padding-left: 30px; border-top: solid 1px #11aec9!important;">
            <div id="company-menu" class="mt-50 ml-4">
                <div>
                    <h2 class="text-sm-left">USERS</h2>
                </div>

                <div>
                    <p class="thin text-sm-left" style="font-size: 14px" :class="{ activeClass : activePage == 'FindInvestors' || activePage == 'DashboardAdminCompany' }">Companies</p>
                    <div>
                        <p style="font-size: 14px" :class="{ activeClass : activePage == 'DashboardAdminCompany' }" class="thin mt-n3 ml-n16 link-menu" @click="redirectTo('DashboardAdminCompany')">
                            <v-icon style="font-size: 20px !important">mdi-chevron-right</v-icon> 
                            List
                        </p>
                        <p style="font-size: 14px" :class="{ activeClass : activePage == 'FindInvestors' }" class="thin mt-n3 link-menu" @click="redirectTo('FindInvestors')">
                            <v-icon style="font-size: 20px !important">mdi-chevron-right</v-icon> 
                            Find Investors
                        </p>
                    </div>
                    <p style="font-size: 14px" :class="{ activeClass : activePage == 'DashboardAdminInvestor' }" @click="redirectTo('DashboardAdminInvestor')" class="thin text-sm-left link-menu" >Investors</p>
                    <p style="font-size: 14px; cursor: pointer" @click="dropdownSumarry = !dropdownSumarry" class="thin text-sm-left link-men" mb-n3>Download Summary</p>
                    <div class="sumarry-dropdown ml-n5" :class="{ active : dropdownSumarry }">
                        <p style="font-size: 14px" class="thin mt-n3 link-menu-download">
                            <export-excel
                                :data   = "summaryCompanies"
                                :fields = "company_fields"
                                worksheet = "Companies"
                                title ="COMPANIES SUMMARY"
                                name    = "companies summary.xls">
                                <v-icon style="font-size: 20px !important">mdi-chevron-right</v-icon> 
                                Companies
                            </export-excel>
                        </p>
                        <p style="font-size: 15px" class="thin ml-n2 link-menu-download">
                            <export-excel
                                :data   = "summaryInvestors"
                                :fields = "investor_fields"
                                worksheet = "Investors"
                                title ="INVESTORS SUMMARY"
                                name    = "investors summary.xls">
                                <v-icon style="font-size: 22px !important">mdi-chevron-right</v-icon> 
                                Investors
                            </export-excel>
                        </p>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>

    <v-row style="margin-top: 300px"> 
        <v-col cols="11" style="padding-left: 30px; border-top: solid 1px #11aec9!important;">
            <div id="company-menu" class="mt-50 ml-4">
                <div class="link d-flex justify-content-between" @click.prevent.stop="settingsMenu = !settingsMenu">
                    <h2 class="">SETTINGS</h2> 
                    <img class="" :class="settingsMenu == true ? 'img' : ''" src="../../assets/img/rowUp.png" height="20" alt="Row-down">
                </div>

                <div v-if="settingsMenu" class="mt-2">
                    <p v-bind:style="this.$route.name == 'ExchangeRate' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('ExchangeRate')">
                        Exchange Rate
                    </p>

                    <p v-bind:style="this.$route.name == 'Coupon' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="changeComponent('Coupon')">
                        Coupon
                    </p>

                    <p v-bind:style="actual == 'billingAddress' ? 'font-weight: bold' :'' " class="thin text-sm-left link" @click.prevent.stop="submitLogout()">
                        Logout
                    </p>
                </div>
            </div>
        </v-col>
    </v-row>
</v-sheet>
</template>

<script>
import Company from "@/store/services/admin/company";
import Investor from "@/store/services/admin/investor";
export default {
    props: {
        comp_id: Number,
        array: Array,
        current: String,
    },

    data() {
        return {
            companies: [],
            summaryCompanies: [],
            summaryInvestors: [],
            companyMenu: true,
            settingsMenu: true,
            actual: null,
            company_id: null,
            company: {},
            rotation: false,
            activePage: '',
            dropdownSumarry: false,

            company_fields: {
                'Company-Id': 'id',
                'Company-Name': 'company_name',
                'Company Email': 'company_email',
                'Industry': 'industry',
                'Model' : 'model',
                'Location' : 'location',
                'Size' : 'size',
                'Growth' : 'growth',
                'Service Offer' : 'service_offer',
                'Technology focus' : 'technology_focus',
                'Technology' : 'technology',
                'Rd' : 'rd',
                'Exclusive Technology' : 'exclusive_technology',
                'Monthly Revenue' : 'monthly_revenue',
                'Country' : 'country',
                'Needed' : 'needed',
                'Stage' : 'stage',
                'Vehicle' : 'vehicle',
                'Female' : 'female',
                'Color' : 'color',
                'lgbtq' : 'lgbtq',
                'User Education/Schools' : 'user_education',
                'Team Founder School' : 'school',
                'Team Founder Country': 'team_founder_country',
                'Need Skills': 'skills',
            },
            investor_fields: {
                'id': 'id',
                'Investor-Name': 'investor_name',
                'Investor Email': 'investor_email',
                'Sectors': 'sectors',
                'Business models': 'business_models',
                'Market locations': 'market_locations',
                'Min_market size': 'min_market_size',
                'Min market growth': 'min_market_growth',
                'Product types': 'product_types',
                'Technology focus': 'technology_focus',
                'Technology type': 'technology_type',
                'Rd focus': 'rd_focus',
                'Patent focus': 'patent_focus',
                'Min monthly revenue': 'min_monthly_revenue',
                'Country': 'country',
                'Locations invested': 'locations_invested',
                'Min check size': 'min_check_size',
                'Max check size': 'max_check_size',
                'Stage': 'stage',
                'Investment_vehicles': 'investment_vehicles',
                'Usa corp structure': 'usa_corp_structure',
                'Preferred stages': 'preferred_stages',
                'Female' : 'female',
                'Color' : 'color',
                'lgbtq' : 'lgbtq',
                'User Education/Schools': 'school',
                'User Skills': 'skills',
            },
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],
        }
    },

    methods: {
        redirectTo(route) {
            if(route != this.activePage) {
                this.$router.push({ name: route });
            }
        },
        submitLogout() {
            localStorage.profile = '';
            this.$router.push('/admin');  
        },
        changeComponent(value) {
            this.$router.push({ name: value });
        },

        init(val, id) {
            this.companies = val;
            this.company_id = id;
            this.companies.forEach(company => {
                if (company.company_id == id) { this.company = company; }
            });
        },

        async getCompaniesSummary() {
            try {
                const response = await Company.getCompaniesSummary();
                
                this.summaryCompanies = response.data.map(company => {
                    const schools = company.team_founder.schools ? JSON.parse(company.team_founder.schools) : [];
                    let need_skills = [];
                    let educations = [];
                    company.need_skills.forEach(skill => {
                        need_skills.push(skill.skill);
                    });

                    company.user_education.forEach(education => {
                        educations.push(education.school);
                    });
                    return {
                        id: company.id,
                        company_name: company.company_name,
                        company_email: company?.user.email,
                        industry: Array.isArray(company?.business?.industry) ? company?.business?.industry.join(', ') : company?.business?.industry,
                        model: company?.business?.model,
                        location: company?.business?.location.join(', '),
                        size: company?.business?.size,
                        growth: company?.business?.growth,
                        service_offer: company?.business_products?.service_offer,
                        technology_focus: company?.business_products?.technology_focus ? "Yes" : "No",
                        technology: company?.business_products?.technology,
                        rd: company?.business_products?.rd ? "Yes" : "No",
                        exclusive_technology: company?.business_products?.exclusive_technology ? "Yes" : "No",
                        monthly_revenue: company?.finance?.monthly_revenue,
                        country: company.country,
                        needed: company?.needs?.needed,
                        stage: company?.investment?.stage,
                        vehicle: company?.need_investor?.vehicle,
                        female: company?.team_policies?.female ? "Yes" : "No",
                        color: company?.team_policies?.color ? "Yes" : "No",
                        lgbtq: company?.team_policies?.lgbtq ? "Yes" : "No",
                        user_education: educations.join(', '),
                        school: schools?.[0]?.school,
                        team_founder_country: company?.team_founder?.country,
                        skills : need_skills.join(', '),
                    };
                });
                return this.summaryCompanies;
            } catch (error) {
                console.error(error);
                return [];
            }
        },

        async getInvestorsSummary() {
            try {
                const response = await Investor.getInvestorsSummary();
                
                this.summaryInvestors = response.data.map(investor => {
                    let educations = [];
                    let skills = [];
                    investor.user_education.forEach(education => {
                        if (education.user_id == investor.id) {
                            educations.push(education.school);
                        }
                    });

                    investor.user_skill.forEach(skill => {
                        if (skill.user_id == investor.id) {
                            skills.push(skill.skill);
                        }
                    });

                    return {
                        id: investor.id,
                        investor_name: `${investor?.first_name} ${investor?.last_name}`,
                        investor_email: investor?.email,
                        sectors: Array.isArray(investor?.investor_strategy?.sectors) ? investor?.investor_strategy?.sectors.join(', ') : investor?.investor_strategy?.sectors,
                        business_models: Array.isArray(investor?.investor_strategy?.business_models) ? investor?.investor_strategy?.business_models.join(', ') : investor?.investor_strategy?.business_models,
                        market_locations: Array.isArray(investor?.investor_strategy?.market_locations) ? investor?.investor_strategy?.market_locations.join(', ') : investor?.investor_strategy?.market_locations,
                        min_market_size: investor?.investor_strategy?.min_market_size,
                        min_market_growth: investor?.investor_strategy?.min_market_growth,
                        product_types: Array.isArray(investor?.investor_strategy?.product_types) ? investor?.investor_strategy?.product_types.join(', ') : investor?.investor_strategy?.product_types,
                        technology_focus: investor?.investor_strategy?.technology_focus,
                        technology_type: investor?.investor_strategy?.technology_type,
                        rd_focus: investor?.investor_strategy?.rd_focus,
                        patent_focus: investor?.investor_strategy?.patent_focus,
                        min_monthly_revenue: investor?.investor_strategy?.min_monthly_revenue,
                        country: investor?.investor_accreditation?.country,
                        locations_invested: Array.isArray(investor?.investor_strategy?.locations_invested) ? investor?.investor_strategy?.locations_invested.join(', ') : investor?.investor_strategy?.locations_invested,
                        min_check_size: investor?.investor_accreditation?.min_check_size,
                        max_check_size: investor?.investor_accreditation?.max_check_size,
                        stage: Array.isArray(investor?.investor_strategy?.stage) ? investor?.investor_strategy?.stage.join(', ') : investor?.investor_strategy?.stage,
                        investment_vehicles: Array.isArray(investor?.investor_accreditation?.investment_vehicles) ? investor?.investor_accreditation?.investment_vehicles.join(', ') : investor?.investor_accreditation?.investment_vehicles,
                        female: investor?.investor_accreditation?.female ? "Yes" : "No",
                        color: investor?.investor_accreditation?.color ? "Yes" : "No",
                        lgbtq: investor?.investor_accreditation?.lgbtq ? "Yes" : "No",
                        usa_corp_structure: investor?.investor_strategy?.usa_corp_structure ? "Yes" : "No",
                        preferred_stages: Array.isArray(investor?.investor_strategy?.preferred_stages) ? investor?.investor_strategy?.preferred_stages.join(', ') : investor?.investor_strategy?.preferred_stages,
                        school: educations.join(', '),
                        skills: skills.join(', ')
                    };
                });
                return this.summaryInvestors;
            } catch (error) {
                console.error(error);
                return [];
            }
        }
    },

    created() {
        this.getCompaniesSummary();
        this.getInvestorsSummary();

        this.activePage = this.$route.name;
        if (this.comp_id) {
            this.companies = this.array;
            this.company_id = this.comp_id;
            this.companies.forEach(company => {
                if (company.company_id == this.comp_id) { this.company = company; }
            });
        }
    }
}
</script>

<style>
    .link-menu:hover {
        cursor: pointer;
        color: #00aac2;
    }
    #company-menu h2 {
        color: #000;
        font-weight: 600;
        font-size: 17px;
        font-family: Arial, Helvetica, sans-serif !important;
    }
    #company-menu .link:hover {
        cursor: pointer;
        color: #00aac2;
    }
    .img {
        filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        display: inline-block;
    }
    .activeClass {
        color: #00aac2 !important;
    }
    .link-menu-download {
        color: #525252;
    }
    .link-menu-download:hover {
        color: #00aac2;
        cursor: pointer;
    }
    .sumarry-dropdown {
        height: 0px;
        overflow: hidden;
        transition: ease all .5s;
    }
    .sumarry-dropdown.active {
        padding-top: 10px;
        height: 55px;
    }
</style>