<template>
  <v-app>
    <v-main transition="slide-x-transition">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "App",
};
</script>

<style src="./assets/styles/bootstrap.min.css"> </style>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.font-weight {
  font-weight: 600;
}

.color-site {
  color: #00aac3;
}

.d-flex {
  display: flex;
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-space-between{
  justify-content: space-between;
}

.width-40 {
  width: 40% !important;
}
.mar-top-80 {
  margin-top: 80px !important;
}
.mar-top-100 {
  margin-top: 100px !important;
}
</style>
