<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8">
      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-col cols="1" />

        <v-col cols="11">
          <!-- Industry -->
          <v-row no-gutters>
            <v-col cols="12">
              <p class="text-sm-left bold">What type of investor are you?</p>
            </v-col>

            <v-col cols="5" class="pl-7">
              <FormInlineMessage v-if="$v.type.investor.$error" class="errmsg">
                Select your sector
              </FormInlineMessage>
              <v-combobox
                v-model="type.investor"
                :items="combo_items"
                label="Select all types that apply"
                multiple
                deletable-chips
                small-chips
                outlined
                dense
                @blur="$v.type.investor.$touch()"
              ></v-combobox>
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row no-gutters>
            <v-col cols="6">
              <p
                v-if="investment_type == 'firm'"
                class="text-sm-left bold"
              >
                How is your firm/fund accredited?
              </p>
              <p
                v-if="investment_type == 'individual'"
                class="text-sm-left bold"
              >
                How are you accredited?
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-n8">
            <v-col cols="12">
              <v-row no-gutters>
                  <v-col>
                    <FormInlineMessage
                      v-if="$v.model.$error"
                      class="errmsg"
                    >
                      Select an option below
                    </FormInlineMessage>
                  </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="11" class="ml-6 accredit_first_option">
                  <v-checkbox
                    v-if="investment_type == 'individual'"
                    v-model="model.$200K"
                    label="I have had +$200k in income (or +$300k jointly with my
                      spouse) for the past 2 years and expect the same this year"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="11" class="ml-6">
                  <v-checkbox
                    v-if="investment_type == 'firm'"
                    v-model="model.$5_25M"
                    label="My firm/fund has $5-25M in assets"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="11" class="ml-6">
                  <v-checkbox
                    v-if="investment_type == 'individual'"
                    v-model="model.$1_2_1M"
                    label="I have $1-2.1M in net assets, exluding my primary residence"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="" no-gutters>
                <v-col cols="11" class="ml-6">
                  <v-checkbox
                    v-if="investment_type == 'firm'"
                    v-model="model.$25M"
                    label="My firm/fund has over $25M in assets"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="" no-gutters>
                <v-col cols="11" class="ml-6">
                  <v-checkbox
                    v-if="investment_type == 'individual'"
                    v-model="model.$2_1_5M"
                    label="I have $2.1-5M in net assets, exluding my primary residence"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="11" class="ml-6">
                  <v-checkbox
                    v-if="investment_type == 'firm'"
                    v-model="model.individually_accredited"
                    label="All owners of my firm/fund are individually accredited"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="11" class="ml-6">
                  <v-checkbox
                    v-if="investment_type == 'individual'"
                    v-model="model.$5M"
                    label="I have over $5M in net assets, exluding my primary residence"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="11" class="ml-6">
                  <v-checkbox
                    v-if="investment_type == 'firm'"
                    v-model="model.individually_qualified"
                    label="All owners of my firm/fund are individually qualified purchasers"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="pt-1">
        <v-col cols="1" />

        <v-col cols="10">
          <v-col />
          <v-row no-gutters>
            <v-col cols="12">
              <p
                v-if="investment_type == 'individual'"
                class="text-sm-left pt-2"
              >
                How many investments have you made?
              </p>
              <p
                v-if="investment_type == 'firm'"
                class="text-sm-left pt-2"
              >
                How many investments have you made in your current fund?
              </p>
            </v-col>

            <v-col cols="5" class="pl-7">
              <FormInlineMessage
                v-if="$v.type_investments_made.$error"
                class="errmsg"
              >
                Fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_type_investments_made_length_error.error">
                {{ is_type_investments_made_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                placeholder="99"
                outlined
                dense
                v-model="type_investments_made"
                @input="type_investments_made = formatToString(type_investments_made), $v.type_investments_made.$touch()"
                @keypress="isNumber($event)"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <p class="text-sm-left pt-2">
                How many deals do you look at in a year?
              </p>
            </v-col>

            <v-col cols="5" class="pl-7">
              <FormInlineMessage
                v-if="$v.type_deals_looked.$error"
                class="errmsg"
              >
                Fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_type_deals_looked_length_error.error">
                {{ is_type_deals_looked_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                placeholder="240"
                outlined
                dense
                v-model="type_deals_looked"
                @input="type_deals_looked = formatToString(type_deals_looked), $v.type_deals_looked.$touch()"
                @keypress="isNumber($event)"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <p class="text-sm-left pt-2">
                How many deals do you do in a year?
              </p>
            </v-col>

            <v-col cols="5" class="pl-7">
              <FormInlineMessage
                v-if="$v.type_deals_made.$error"
                class="errmsg"
              >
                Fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_type_deals_made_length_error.error">
                {{ is_type_deals_made_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                placeholder="12"
                outlined
                dense
                v-model="type_deals_made"
                @input="type_deals_made = formatToString(type_deals_made),  $v.type_deals_made.$touch()"
                @keypress="isNumber($event)"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <p class="text-sm-left pt-2">
                What is your yearly investing budget for Seed and Series A+
                startups in USD?<span class="font-weight-regular">
                  <br />For example, enter '400000' for $ 400,000</span
                >
              </p>
            </v-col>

            <v-col cols="5" class="pl-7">
              <FormInlineMessage
                v-if="$v.type_yearly_seed_budget.$error"
                class="errmsg"
              >
                Fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_type_yearly_seed_budget_length_error.error">
                {{ is_type_yearly_seed_budget_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                placeholder="4000000"
                outlined
                dense
                v-model="type_yearly_seed_budget"
                @input="type_yearly_seed_budget = formatToString(type_yearly_seed_budget), $v.type_yearly_seed_budget.$touch()"
                @keypress="isNumber($event)"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <p class="text-sm-left pt-2">
                What is your min check size for startups in USD?<span
                  class="font-weight-regular"
                >
                  <br />For example, enter '150000' for $ 150,000</span
                >
              </p>
            </v-col>

            <v-col cols="5" class="pl-7">
              <FormInlineMessage
                v-if="$v.type_min_check_size.$error"
                class="errmsg"
              >
                Fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_type_min_check_size_length_error.error">
                {{ is_type_min_check_size_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                placeholder="100000"
                outlined
                dense
                v-model="type_min_check_size"
                @input="type_min_check_size = formatToString(type_min_check_size), $v.type_min_check_size.$touch()"
                @keypress="isNumber($event)"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <p class="text-sm-left pt-2">
                What is your max check size for startups in USD?<span
                  class="font-weight-regular"
                >
                  For example, enter '1000000' for $ 1,000,000</span
                >
              </p>
            </v-col>

            <v-col cols="5" class="pl-7">
              <FormInlineMessage
                v-if="$v.type_max_check_size.$error"
                class="errmsg"
              >
                Fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_type_max_check_size_length_error.error">
                {{ is_type_max_check_size_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                placeholder="800000"
                outlined
                dense
                v-model="type_max_check_size"
                @input="type_max_check_size  = formatToString(type_max_check_size), $v.type_max_check_size.$touch()"
                @keypress="isNumber($event)"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row no-gutters>
            <v-col cols="6">
              <p class="text-sm-left bold">
                Which of the following ways have you invested previously?
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-n8">
            <v-col cols="12">
              <!-- following ways of investment -->
              <v-row no-gutters>
                <v-col cols="11" class="ml-6">
                  <v-checkbox
                    @change="changeCheckboxValue('directly')"
                    v-model="previous_ways.directly"
                    label="Directly into a SME or a startup"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="11" class="ml-6">
                  <v-checkbox
                    @change="changeCheckboxValue('LP')"
                    v-model="previous_ways.lp"
                    label="As an LP in a VC fund"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="11" class="ml-6">
                  <v-checkbox
                    @change="changeCheckboxValue('public')"
                    v-model="previous_ways.public"
                    label="In the public markets"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="pb-8" no-gutters>
                <v-col cols="11" class="ml-6">
                  <v-checkbox
                    @change="changeCheckboxValue('single_deal')"
                    v-model="previous_ways.spv"
                    label="Into a single-deal SPV"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="6">
              <p class="text-sm-left bold">
                Which remarkable investments did you made ?
              </p>
            </v-col>
          </v-row>
          <v-textarea
            v-model="remarkable_investments"
            placeholder="List some successful startups you have invested in ..."
            outlined
            rows="3"
            dense
          />

        </v-col>
        <v-col cols="1" />
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveType"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import AccreditationService from "@/store/services/investor/accreditation.service";
import FormInlineMessage from "@/components/FormInlineMessage";
import { required } from "vuelidate/lib/validators";

import { myMixins } from "@/mixins/mixins.js";

export default {
  mixins: [myMixins],
  components: {
    FormInlineMessage,
  },

  props: {
    me: Object,
  },

  data() {
    return {
      currentBlock: true,
      investment_type : null,
      // model: null,
      model: {
        $200K: false,
        $5_25M: false,
        $1_2_1M: false,
        $25M: false,
        $2_1_5M: false,
        individually_accredited: false,
        $5M: false,
        individually_qualified:false
      },
      combo_items: [
        "Angel",
        "VC Fund",
        "Accelerator",
        "Incubator",
        "Syndicate Lead",
        "Corporate VC",
        "Other",
      ],
      previous_ways: {
        directly: false,
        lp: false,
        public: false,
        spv: false,
      },
      type: {
        investor: [],
        accreditation: "",
        investments_made: null, //int
        deals_looked: null, //int
        deals_made: null, //int
        yearly_seed_budget: null, //int
        min_check_size: null, //int
        max_check_size: null, //int
        previous_investing: [],
      },
      remarkable_investments: null,
      type_investments_made: null, //int
      type_deals_looked: null, //int
      type_deals_made: null, //int

      type_yearly_seed_budget: null, //int
      type_min_check_size: null, //int
      type_max_check_size: null, //int
    };
  },

  validations: {
    model: {required},
    type: {
      investor: { required },
      //accreditation: { required },
      // investments_made: {
      //   required,
      //   minValue: minValue(0),
      // },
      // deals_looked: {
      //   required,
      //   minValue: minValue(0),
      // },
      // deals_made: {
      //   required,
      //   minValue: minValue(0),
      // },
      previous_investing: { required },
    },
    type_investments_made: { required },
    type_deals_looked: { required },
    type_deals_made: { required },

    type_yearly_seed_budget: { required },
    type_min_check_size: { required },
    type_max_check_size: { required },
  },

  methods: {
    changeCheckboxValue(value) {
      if (this.type.previous_investing.includes(value)) {
        this.type.previous_investing.splice(this.type.previous_investing.indexOf(value), 1);
      } else {
        this.type.previous_investing.push(value);
      }
    },

    saveType() {
      this.$v.type.$touch();
      this.$v.type_yearly_seed_budget.$touch();
      this.$v.type_min_check_size.$touch();
      this.$v.type_max_check_size.$touch();
      if (
        this.$v.type.$error ||
        this.$v.type_yearly_seed_budget.$error ||
        this.$v.type_min_check_size.$error ||
        this.$v.type_max_check_size.$error ||
        this.is_type_investments_made_length_error ||
        this.is_type_deals_looked_length_error ||
        this.is_type_deals_made_length_error ||
        this.is_type_yearly_seed_budget_length_error ||
        this.is_type_min_check_size_length_error ||
        this.is_type_max_check_size_length_error
      ) {
        return;
      }
      this.type_investments_made = this.formatToInt(this.type_investments_made);
      this.type_deals_looked = this.formatToInt(this.type_deals_looked);
      this.type_deals_made = this.formatToInt(this.type_deals_made);
      this.type_yearly_seed_budget = this.formatToInt(this.type_yearly_seed_budget);
      this.type_min_check_size = this.formatToInt(this.type_min_check_size);
      this.type_max_check_size = this.formatToInt(this.type_max_check_size);
      
      this.type.investments_made = this.type_investments_made;
      this.type.deals_looked = this.type_deals_looked;
      this.type.deals_made = this.type_deals_made;
      this.type.yearly_seed_budget = this.type_yearly_seed_budget;
      this.type.min_check_size = this.type_min_check_size;
      this.type.max_check_size = this.type_max_check_size;

      let model = [];
      for(const item in this.model) {
        if(this.model[item]!==false){
          if(item == '$200K') model.push('+$200K')
          if(item == '$5_25M') model.push('$5-25M')
          if(item == '$1_2_1M') model.push('$1-2.1M')
          if(item == '$25M') model.push('$25M')
          if(item == '$2_1_5M') model.push('$2.1-5M')
          if(item == 'individually_accredited') model.push('individually_accredited')
          if(item == '$5M') model.push('+$5M')
          if(item == 'individually_qualified') model.push('individually_qualified')
        }
      }
      model = model.join();
      this.type.accreditation = model;
      this.type.remarkable_investments = this.remarkable_investments;

      AccreditationService.saveCategory(this.type)
        .then((res) => {
          this.type_investments_made = this.type_investments_made.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.type_deals_looked = this.type_deals_looked.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.type_deals_made = this.type_deals_made.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.type_yearly_seed_budget = this.type_yearly_seed_budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.type_min_check_size = this.type_min_check_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.type_max_check_size = this.type_max_check_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.currentBlock = false;
          this.$emit("block3");
        })
        .catch((e) => {
          //console.error(e);
        });
    },

    getType() {
      AccreditationService.getCategory()
        .then((res) => {
          if (res.data) {
            this.type.investor = res.data.investor_type;
            this.type_investments_made = res.data.previous_investments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_deals_looked = res.data.yearly_deals_looked.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_deals_made = res.data.yearly_deals_made.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_yearly_seed_budget = res.data.yearly_budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_min_check_size = res.data.min_check_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_max_check_size = res.data.max_check_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.remarkable_investments = res.data.remarkable_investments;

            if(res.data.investment_methods)
            {
              this.type.previous_investing = res.data.investment_methods;
            }

            res.data.investment_methods.forEach((entry) => {
              if (entry == "directly") {
                this.previous_ways.directly = true;
              } else if (entry == "LP") {
                this.previous_ways.lp = true;
              } else if (entry == "public") {
                this.previous_ways.public = true;
              } else if (entry == "single_deal") {
                this.previous_ways.spv = true;
              }
            });

            let accreditation = res.data.accreditation.split(',');
            if (accreditation.includes("+$200K") ) {
              this.model.$200K = true;
            } 
            if (accreditation.includes("$5-25M") ) {
              this.model.$5_25M = true;
            } 
            if (accreditation.includes("$1-2.1M") ) {
              this.model.$1_2_1M = true;
            } 
            if (accreditation.includes("$25M") ) {
              this.model.$25M = true;
            } 
            if (accreditation.includes("$2.1-5M") ) {
              this.model.$2_1_5M = true;
            } 
            if (accreditation.includes("individually_accredited") ) {
              this.model.individually_accredited = true;
            } 
            if (accreditation.includes("+$5M") ) {
              this.model.$5M = true;
            } 
            if (accreditation.includes("individually_qualified") ) {
              this.model.individually_qualified = true;
            } 
          }
        })
        .catch((e) => {
          //console.error(e);
        });
    },

    getTypeOfAccreditation() {
      AccreditationService.getType()
        .then((res) => {
          if (res.data) {
            if (res.data.firm_or_individual) {
              this.investment_type = res.data.firm_or_individual;
            }
            return;
          }
        })
        .catch((e) => {
          //console.error(e);
        });
    },
  },

  created() {
    //
  },
  mounted() {
    this.getType();
    this.getTypeOfAccreditation();
  },
};
</script>

<style>
.errmsg {
  color: red;
}
@media (max-width: 1297px) { .accredit_first_option {margin-bottom: 12px !important; }}
</style>
