<template>
    <div class="mar-top-100">
        <v-col/>
        <v-row class="invoice">
            <v-col cols="1" md="0"/>
            <v-col cols="11" md="12">
                <p class="cyan--text text--darken-1 mt-3 text-sm-left" id="title">History of invoices</p>

                <v-app id="inspire">
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="invoices"
                        :single-select="singleSelect"
                        item-key="id"
                        show-select
                        id="user"
                    >

                        <template v-slot:item.actions="{ item }">
                            <a class="text-decoration-none" outlined :href="pdf_path + item.download_invoice" target="_blank" download>
                                <v-icon
                                large
                                color="#00aac3"
                                right
                                center
                                >
                                    mdi-arrow-down-circle
                                </v-icon>
                            </a>
                        </template>
                    </v-data-table>
                </v-app>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Paypal from '@/store/services/paypal.service'

export default {
    props: {
        company_id: Number
    },
    data() {
        
        return {
            pdf_path: process.env.VUE_APP_API_BASE_URL_PDF,
            invoices: [],
            singleSelect: false,
            selected: [],
            headers: [
                { text: 'Invoice #', value: 'id_invoice' },
                { text: 'Date', value: 'date', sortable: true },
                { text: 'Investor #', value: 'id_investor', sortable: false },
                { text: 'Description', value: 'description', sortable: false },
                { text: 'Payment Status', value: 'payment_status', sortable: false },
                { text: 'Total', value: 'total', sortable: false },
                { text: 'Tax', value: 'tax', sortable: false },
                { text: 'Download Invoice', value: 'actions', align: 'center', sortable: false },
            ],
        }
    },
    methods: {
        formatDate (date) {
            let dat = new Date(date);
            let year = dat.getFullYear();
            let month = dat.getMonth() + 1;
            let day = dat.getDate();
            if(day < 10 && month < 10){
                return `0${day}/0${month}/${year}`;
            }
            else if (month < 10) {
                return `${day}/0${month}/${year}`;
            }
            else{
                return `${day}/${month}/${year}`;
            }
        },
    },

    created() {
        Paypal.getAllInvestorInvoices(localStorage.user_id).then((res) => {
            if (res.data) {
                for (const invoice of res.data) {
                    this.invoices.push({
                        'id_invoice': invoice.id_invoice,
                        'date': this.formatDate(invoice.created_at),
                        'id_investor': invoice.id,
                        'description': invoice.description,
                        'payment_status': invoice.payment_status,
                        'total': `${invoice.currency == "USD" ? "$" : "€"} ${invoice.amount}`,
                        'tax': `${invoice.currency == "USD" ? "$" : "€"} ${invoice.vat_amount}`,
                        'download_invoice': invoice.invoice_path,
                    })
                }
            }
        })
    }
}
</script>

<style scoped>
    @media (min-width: 960px) and (max-width: 1388px) { 
        .invoice{
            margin-left: 20px;
        } 
    }
</style>