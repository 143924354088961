import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

axios.defaults.headers.post["Content-Type"] = "application/vnd.api+json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token");
axios.defaults.headers.common["Accept"] = "application/vnd.api+json";

function saveInvestment(payload) {
  return axios.post(`${url}/finance/investments`, payload);
}
function getInvestment(company_id) {
  return axios.get(`${url}/finance/investments/${company_id}`);
}


function saveShareholder(payload) {
  return axios.post(`${url}/finance/shareholders`, payload);
}
function getShareholder(company_id) {
  return axios.get(`${url}/finance/shareholders/${company_id}`);
}

function saveCurrentAccountsBalanceShoulder(payload) {
  console.log(payload);
  return axios.post(`${url}/finance/current-accounts-balance-shoulders`, payload);
}

function saveResult(payload) {
  return axios.post(`${url}/finance/results`, payload);
}
function getResult(company_id) {
  return axios.get(`${url}/finance/results/${company_id}`);
}


export default {
  saveInvestment,
  saveShareholder,
  saveCurrentAccountsBalanceShoulder,
  saveResult,
  getInvestment,
  getShareholder,
  getResult
};