<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="11">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            What is the situation of shareholder and cofounder' current accounts?
          </p>
        </v-col>
        <v-col cols="1" />
        <v-col cols="11">
          <p class="text-sm-left">Shareholders and cofounders</p>
          <v-row>
            <v-col cols="4" />
            <v-col cols="3">
              <FormInlineMessage v-if="!this.shareholder_account.shareholders" class="errmsg">
                Add a shareholder
              </FormInlineMessage>
            </v-col>
          </v-row>
        </v-col>

        <v-col>
          <div v-for="(shareholder_account, index) in shareholder_account.shareholders" :key="index + 1">
            <v-row>
              <v-col cols="1" />
              <v-col cols="4">
                <p class="font-weight-thin text-sm-left" v-if="index == 0">
                  Name
                </p>
                <FormInlineMessage
                  v-if="$v.shareholder_account.shareholders.$each[index].name.$error"
                  class="errmsg"
                >
                  Fill the field
                </FormInlineMessage>
                <v-text-field
                  v-model="shareholder_account.name"
                  outlined
                  dense
                  placeholder="Name"
                />
              </v-col>
              <v-col cols="4">
                <p class="font-weight-thin text-sm-left" v-if="index == 0">
                  Current account balance amount in USD
                </p>
                <FormInlineMessage v-if="is_current_account_balance_error(index)">
                  {{ current_account_balance_error_message(index) }}
                </FormInlineMessage>
                <v-text-field
                  v-model="shareholder_account.current_account_balance"
                  outlined
                  dense
                  placeholder="---"
                  @keypress="isMinusOrNumberKey($event)"
                  @input="shareholder_account.current_account_balance = formatToString(shareholder_account.current_account_balance)"
                />
              </v-col>
              <v-col cols="2">
                <p class="font-weight-thin text-sm-left" v-if="index == 0">
                  Type of shareholder
                </p>
                <FormInlineMessage
                  v-if="$v.shareholder_account.shareholders.$each[index].type.$error"
                  class="errmsg"
                >
                  Fill the field
                </FormInlineMessage>
                <v-select
                  height="32"
                  v-model="shareholder_account.type"
                  :items="typeShareholder"
                  placeholder="Corporate"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </div>

          <v-row class="mb-3">
            <v-col cols="4" />
            <v-btn
              id="btn-add"
              text
              color="#00aac3"
              @click.prevent.stop="addShareholdersCurrentAccounts(), currentBlock = true"
            >
              + Add Shareholder
            </v-btn>
            <v-col cols="2" />
            <v-btn
              v-if="shareholder_account.shareholders.length > 1"
              id="btn-add"
              text
              color="#00aac3"
              @click.prevent.stop="removeShareholdersCurrentAccounts()"
            >
              - Remove Shareholder
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-col />
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveCurrentAccountsBalanceShoulder"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import FinanceService from "@/store/services/finance.service";
import Var from "@/store/services/var";

import { myMixins } from "@/mixins/mixins.js";

export default {
  mixins: [myMixins],
  components: {
    FormInlineMessage,
  },

  props: {
    company_id: Number,
  },

  data() {
    return {
      currentBlock: true,
      typeShareholder: [],
      shareholder_account: {
        shareholders: [{}],
      },
    };
  },

  validations: {
    shareholder_account: {
      shareholders: {
        $each: {
          name: {  },
          current_account_balance: {  },
          type: {  },
        },
      },
      account_current_account_balance_has_error: false,
    },
  },

  methods: {
    saveCurrentAccountsBalanceShoulder() {
      if (this.is_currentBalance_current_account_balance_length_error.error) {
        return;
      }

      if (this.shareholder_account.shareholders.some((account) => {
        let value = this.formatToInt(account.current_account_balance);
        if (value.toString().length > 15) {
          this.account_current_account_balance_has_error = true;
          return true;
        } 
        this.account_current_account_balance_has_error = false;
      }))

      if (this.account_current_account_balance_has_error) return;

      // format the current account balance to int
      for(let elm of this.shareholder_account.shareholders) {
        elm.current_account_balance = this.formatToInt(elm.current_account_balance);
      }

      FinanceService.saveCurrentAccountsBalanceShoulder({
        company_id: this.company_id,
        currentBalance: this.shareholder_account,
      }).then((res) => {
        for(let elm of this.shareholder_account.shareholders) {
          elm.current_account_balance = elm.current_account_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        this.$emit("block4");
        this.currentBlock = false;
      }).catch((err) => {
        for(let elm of this.shareholder_account.shareholders) {
          elm.current_account_balance = elm.current_account_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      });
    },

    getVar() {
      Var.getAllShareholders().then((res) => {
        this.typeShareholder = res.data;
      });
    },

    addShareholdersCurrentAccounts() {
      this.shareholder_account.shareholders.push({});
    },

    removeShareholdersCurrentAccounts() {
      this.shareholder_account.shareholders.pop();
    },

    getShareholder() {
      FinanceService.getShareholder(this.company_id)
        .then((res) => {
          if (res.data) {
            if (res.data.dilluted) {
              this.shareholder_account.shareholders.pop();
              res.data.dilluted.forEach((element) => {
                  this.shareholder_account.shareholders.push({
                      name: element.name,
                      current_account_balance: element.current_account_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      type: element.type,
                  });
              });
            }
          }
        })
        .catch((e) => {});
    },
  },
  mounted() {
    this.getVar();
    this.getShareholder();
  }
};
</script>
