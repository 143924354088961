<template>
    <div class="mar-top-100">
        <LoaderPop v-if="loaderAlert" type="loading"/>
        <v-row class="accountStatus">
          <!-- Header -->
            <div class="mb-7 header">
               <h1 class="text-light mt-5">QUALIFICATION CONFIRMED</h1>
               <hr>
            </div>
            <v-col cols="11" sm="8" class="mt-5">
                <h2 class="mb-5">CONGRATULATIONS!</h2>
                <p>
                    Your company has passed the qualification process. 
                </p>
                <p>
                    Your company is ready to be submitted to accredited investors who are working with us.
                </p>
                <p class="mt-7">
                    <b>Subscribe to the yearly membership to be listed in our IO Newsletter</b>
                </p>
            </v-col>
            <v-col cols="11" sm="8" class="text-left">
                <p class="puce-content">
                    <img src="../../assets/img/puce.png" alt="Puce"> Be among the qualified investment deals recommended by IdeasFundX to VCs
                </p>
                <p class="puce-content">
                    <img src="../../assets/img/puce.png" alt="Puce"> Access to an active pool of accredited VCs who are working with us
                </p>
                <p class="puce-content">
                    <img src="../../assets/img/puce.png" alt="Puce"> Maximize your chances being discovered by VCs and having your first meetings to enter the negotiation
                </p>
                <p class="puce-content">
                    <img src="../../assets/img/puce.png" alt="Puce"> Preserve the confidentiality of your data. 
                    Only investors who are interested in reviewing the presentation of the company 
                    get access to confidential information provided by your company after signing a NDA.
                </p>
            </v-col>
            <v-col cols="11" sm="8">
                <p>
                    IdeasFundX takes a success fee of 4% of the investment in your company made by investors who have been recommended by IdeasFundX for your deal.
                </p>
                <img src="../../assets/img/Special.png" alt="Special" class="mt-10">
            </v-col>
            <v-col cols="11" sm="4" class="mt-10">
                For the launch, IdeasFundX offers you a 6 months free subscription. To continue your subscription, you will then pay a Yearly Membership fee of {{ inEEC(countryBase) ?  Math.floor(productPrice * change * 100) / 100 + ' €' : `$ ${productPrice}`}} excl. tax.
            </v-col>
            <div class="mt-5">
                <v-btn
                    sm="5"
                    id="btn-photo"
                    class="white--text"
                    color="#58407c"
                    height="90"
                    width="200"
                    rounded
                    block
                    @click="subscribeNewsletter()"
                    :disabled="false">
                    SUBSCRIBE
                </v-btn>
            </div>
            <v-col cols="11" sm="4">
                <p class="blue-grey--text text--lighten-2 font-weight-thin">Be among the recommanded deals of our Newsletter for VCs</p>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import Profile from "@/store/services/profile-service";
    import Company from "@/store/services/admin/company";
    import CompanyService from "@/store/services/company/company.service";
    import LoaderPop from "@/components/LoaderPop.vue";
    import Var from '@/store/services/var.js';

    export default { 
        components : { 
            LoaderPop
        },
        data() {
            return {
                user_id: null,
                loaderAlert: false,
                user: {
                    id : null
                },
                countryBase: null,
                change: 0,
                productPrice: 0,
                EEC: [
                    "Austria",
                    "Belgium",
                    "Bulgaria",
                    "Croatia",
                    "Cyprus",
                    "Czech Republic",
                    "Denmark",
                    "Estonia",
                    "Finland",
                    "France",
                    "France, Metropolitan",
                    "Germany",
                    "Greece",
                    "Hungary",
                    "Ireland",
                    "Italy",
                    "Latvia",
                    "Lithuania",
                    "Luxembourg",
                    "Malta",
                    "Netherlands",
                    "Poland",
                    "Portugal",
                    "Romania",
                    "Slovakia",
                    "Slovenia",
                    "Spain",
                    "Sweden",
                ],
            }
        },
        mounted() {
            Profile.get().then((res) => { this.user.id = res.data.id });
            CompanyService.getDescribe().then((res) => { this.countryBase = res.data.country;  });
            Var.getRate().then((res) => { this.change = res.data.final_rate; })
            Var.getProduct(3).then((res) => { this.productPrice = res.data.product_price; }); // 3 => 'Company Newsletter' id in the table var_products
        },
        methods: {
            inEEC (country) {
                return this.EEC.includes(country);
            },

            subscribeNewsletter() {
                this.loaderAlert = true;
                Company.subscribeNewsletter(this.user, false).then(res=>{
                    if(res.status == 200){
                        this.loaderAlert = false;
                        this.$fire({
                            title: "Subscription to the IO Newsletter",
                            text: res.data.message,
                            type: res.data.type,
                            timer: 4000
                        }).then(r => {
                            // console.log(r.value);
                        });
                        this.$emit("forceUpdate");
                    }
                    this.loaderAlert = false;
                })
            }
        }
    }
</script>