<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-10">
      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="1" />

        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            Products / Services invested in
          </p>

          <v-col />

          <!-- Service Offer -->
          <v-row no-gutters>
            <v-col cols="3">
              <p class="text-sm-left pt-2">Types of product/services</p>
            </v-col>

            <v-col col="auto">
              <FormInlineMessage
                v-if="$v.product.service_type.$error"
                class="errmsg"
              >
                Select a product or service
              </FormInlineMessage>
              <v-combobox
                v-model="product.service_type"
                :items="allPrimary"
                outlined
                dense
                multiple
                small-chips
                deletable-chips
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row no-gutters>
            <v-col cols="3">
              <p class="text-sm-left pt-2">Technology Focus</p>
            </v-col>

            <v-col col="auto">
              <FormInlineMessage
                v-if="$v.product.technology_focus.$error"
                class="errmsg"
              >
                Select an option from the dropdown
              </FormInlineMessage>
              <v-combobox
                v-model="product.technology_focus"
                :items="allTechList"
                outlined
                dense
                small-chips
                deletable-chips
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <!-- Service Does -->
          <v-row
            no-gutters
            v-if="
              this.product.technology_focus == 'Both Tech and non-Tech' ||
              this.product.technology_focus == 'Only Tech'
            "
          >
            <p class="text-sm-left">
              Which Technology ?<br />
              <span class="blue-grey--text text--lighten-4 thin"
                >Do you have a focus on specific technologies and if yes, which
                ones?</span
              >
            </p>
            <v-col cols="11">
              <v-textarea
                v-model="product.which_technology"
                outlined
                rows="2"
                dense
              />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3">
              <p class="text-sm-left pt-2">With R&D</p>
            </v-col>

            <v-col col="auto">
              <FormInlineMessage
                v-if="$v.product.rd_focus.$error"
                class="errmsg"
              >
                Select an option from the dropdown
              </FormInlineMessage>
              <v-combobox
                v-model="product.rd_focus"
                :items="optionsList"
                outlined
                dense
                small-chips
                deletable-chips
                @blur="$v.product.rd_focus.$touch()"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row no-gutters>
            <v-col cols="3">
              <p class="text-sm-left pt-2">With IP Rights or Patents</p>
            </v-col>

            <v-col col="auto">
              <FormInlineMessage
                v-if="$v.product.patent_focus.$error"
                class="errmsg"
              >
                Select an option from the dropdown
              </FormInlineMessage>
              <v-combobox
                v-model="product.patent_focus"
                :items="optionsList"
                outlined
                dense
                small-chips
                deletable-chips
                @blur="$v.product.patent_focus.$touch()"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>

          <v-row no-gutters>
            <v-col cols="3">
              <p class="text-sm-left pt-2">
                Min. Monthly Recurring Revenue<span class="font-weight-regular">
                  in USD</span
                >
              </p>
            </v-col>

            <v-col>
              <FormInlineMessage
                v-if="$v.product_monthly_revenue.$error"
                class="errmsg"
              >
                Fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_product_monthly_revenue_length_error.error">
                  {{ is_product_monthly_revenue_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                outlined
                dense
                placeholder="200000"
                prefix="$"
                v-model="product_monthly_revenue"
                @input="product_monthly_revenue = formatToString(product_monthly_revenue), $v.product_monthly_revenue.$touch()"
                @keypress="isNumber($event)"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>
        </v-col>

        <v-col cols="1" />
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveProduct"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import AllVar from "@/store/services/var";
import InvestmentService from "@/store/services/investor/investment.service";
import FormInlineMessage from "@/components/FormInlineMessage";
import { required } from "vuelidate/lib/validators";
import { myMixins } from "@/mixins/mixins.js";

export default {
  mixins: [myMixins],
  components: {
    FormInlineMessage,
  },

  data() {
    return {
      currentBlock: true,
      allTechList: ["Both Tech and non-Tech", "Only Tech", "Only non-Tech"],
      optionsList: ["No Preference", "Yes", "No"],
      allDescr: [],
      allPrimary: [],
      allStage: [],
      allDiff: [],
      product: {
        service_type: [],
        technology_focus: "",
        which_technology: "",
        rd_focus: "",
        patent_focus: "",
      },
      product_monthly_revenue: null,
    };
  },

  validations: {
    product: {
      required,
      service_type: { required },
      technology_focus: { required },
      rd_focus: { required },
      patent_focus: { required },
    },
    product_monthly_revenue: { required },
  },

  methods: {
    saveProduct() {
      
      this.$v.product_monthly_revenue.$touch();
      this.$v.product.$touch();
      if (
        this.$v.product.$error ||
        this.$v.product_monthly_revenue.$error ||
        this.is_product_monthly_revenue_length_error.error
      ) {
        return;
      }
      this.product_monthly_revenue = this.formatToInt(this.product_monthly_revenue);

      this.product.monthly_revenue = this.product_monthly_revenue;

      InvestmentService.saveProduct(JSON.stringify(this.product))
        .then((res) => {
          this.product_monthly_revenue = this.product_monthly_revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.currentBlock = false;
          this.$emit("block3");
        })
        .catch((e) => {
          //console.error(e);
        });
    },

    getProduct() {
      InvestmentService.getProduct()
        .then((res) => {
          this.product.service_type = res.data.product_types;
          this.product.technology_focus = res.data.technology_focus;
          this.product.which_technology = res.data.technology_type;
          this.product.rd_focus = res.data.rd_focus;
          this.product.patent_focus = res.data.patent_focus;
          this.product_monthly_revenue = res.data.min_monthly_revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        })
        .catch((e) => {
          //console.error(e);
        });
    },

    getVariables() {
      AllVar.getAllMakesProductUniques().then((res) => {
        this.allDiff = res.data;
      });

      AllVar.getAllProductLifeCycles().then((res) => {
        this.allStage = res.data;
      });

      AllVar.getAllTypeOfOffers().then((res) => {
        this.allPrimary = res.data;
      });
    },
  },

  created() {
    this.getVariables();
  },
  mounted() {
    this.getProduct();
  },
};
</script>
