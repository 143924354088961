import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      // Authorization: "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token")
    },
};

function getAllInvestors() {
    return axios.get(`${url}/admin/company/investors`);
}

function getInvestorBySearch(data) {
    return axios.get(`${url}/admin/company/search/${JSON.stringify(data)}`)
}

function updateUserInvestorStatus(user_id) {
    return axios.get(`${url}/admin/company/status/${user_id}`)
}

function subscribeNewsletter(payload, renew) {
    return axios.post(`${url}/admin/company/newsletter/investor/${renew}`, payload)
}

function getInvestorsSummary() {
    return axios.get(`${url}/admin/company/summary-investors`)
  }

export default {  
    getAllInvestors,
    getInvestorBySearch,
    updateUserInvestorStatus,
    subscribeNewsletter,
    getInvestorsSummary
};
