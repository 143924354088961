<template>
<div>
  <SocialPopup v-bind:is_active="this.enablePopup" @closePopup="closeDialog"/>
  <!-- <div style="height: 250px"></div> -->
  <!-- <footer style="position: fixed; z-index: 100; width: 100vw; bottom: 0;"> -->
  <footer style="position: relative; z-index: 100; width: 100vw; bottom: 0;">
    <div class="container-fluid">
      <div class="footer-top pr-0">
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <div class="footer-one">
              <div class="footer-one-logo">
                <a href="/"><img src="../assets/img/ideasfundx-fundraising.png" alt="ideasfundx-fundraising" /></a>
                <p>powered by the company IDEASVOICE</p>
              </div>

              <div class="footer-one-social">
                <ul>
                  <li>
                    <a aria-label="ideasvoice" href="https://blog.ideasvoice.com/" target="_blank"
                      ><v-icon large color="white darken-2" class="social-icon">
                        mdi-link-variant
                      </v-icon></a
                    >
                  </li>
                  <li>
                    <a aria-label="twitter" href="http://www.twitter.com/ideasvoice" target="_blank"
                      ><v-icon large color="white darken-2" class="social-icon">
                        mdi-twitter
                      </v-icon></a
                    >
                  </li>
                  <li>
                    <a aria-label="facebook" href="https://www.facebook.com/IdeasvoiceNews/" target="_blank"
                      ><v-icon large color="white darken-2" class="social-icon">
                        mdi-facebook
                      </v-icon></a
                    >
                  </li>
                  <li>
                    <a aria-label="linkedin" href="https://www.linkedin.com/company/ideasvoice" target="_blank"
                      ><v-icon large color="white darken-2" class="social-icon">
                        mdi-linkedin
                      </v-icon></a
                    >
                  </li>
                  <li>
                    <a aria-label="youtube" href="https://www.youtube.com/ideasvoice" target="_blank"
                      ><v-icon large color="white darken-2" class="social-icon">
                        mdi-youtube
                      </v-icon></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="footer-link">
              <ul>
                  <li><a :href="CMScontact" target="_blank">Contact Us</a></li>
                  <li><a :href="CMSabout" target="_blank">About Ideasfundx</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="footer-link">
              <ul>
                <li><a :href="CMSambassador">Help as an ambassador</a></li>
                <li><a :href="CMSsponsor">Partner with us</a></li>
                <li><a :href="CMSProduct">Our products and services</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="footer-link">
              <ul>
                <li><a href="/popup" @click.prevent.stop="clickSocialPopup">Share IdeasFundX with your friends</a></li>
                <li><a :href="CMSterms">Terms of Use</a></li>
                <li><a :href="CMScookies">Privacy and Cookies Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom" style="text-align: center">
        <p class="mt-n4 mb-n2">COPYRIGHT © {{ new Date().getFullYear() }} IDEASFUNDX. All right reserved</p>
      </div>
    </div>
  </footer>
</div>
</template>

<script>
import SocialPopup from '@/components/SocialPopup.vue';

export default {
  components: {
    SocialPopup
  },
  data() {
    return {
      enablePopup: false,
      CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
      CMScookies: `${process.env.VUE_APP_CMS_URL}cookies`,
      CMSabout: `${process.env.VUE_APP_CMS_URL}about`,
      CMSambassador: `${process.env.VUE_APP_CMS_URL}ambassador`,
      CMSsponsor: `${process.env.VUE_APP_CMS_URL}sponsor`,
      CMSterms: `${process.env.VUE_APP_CMS_URL}terms`,
      CMSProduct: `${process.env.VUE_APP_CMS_URL}products`,
    };
  },
  methods: {
    clickSocialPopup()
    {
      this.enablePopup=true;
    },
    closeDialog(value)
    {
      this.enablePopup=false;
    }
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
footer {
  background-color: #27aac3;
  margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 35px;
}
.footer-top {
  padding-top: 28px;
  padding-left: 50px;
  padding-right: 250px;
}

.footer-one-logo img {
  display: inline-block;
  width: 170px;
}

.footer-one-logo p {
  margin-bottom: 0;
  font-size: 12px;
  color: #fff;
  margin-top: 5px;
}

.footer-one-social ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 10px;
}

.footer-one-social ul li {
  display: inline-block;
  color: #fff;
  font-size: 22px;
}

.footer-one-social ul li a {
  color: #fff;
  display: inline-block;
  margin: 0px 10px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}

.footer-one-social ul li .social-icon {
  font-size: 28px !important;
  margin-right: 5px;
}

.footer-one-social ul li a:hover {
  opacity: 0.7;
}

.footer-link ul {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
}

.footer-link ul li a {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  margin-top: 8px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}

.footer-link ul li a:hover {
  opacity: 0.7;
}

.footer-bottom {
  padding-left: 25px;
  margin-top: 50px;
}

.footer-bottom p {
  margin-bottom: 0;
  font-size: 12px;
  color: #fff;
}

@media (min-width: 100px) and (max-width: 767px) {
  .footer-link ul {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 839px) {
  .footer-one-social ul li .social-icon{
    font-size: 30px !important;
    width: 24px;
  }
}

@media only screen and (min-width: 20px) and (max-width: 575px) {
  .footer-top {
    padding-left: 0px;
    padding-right: 0px;
  }

  .footer-one {
    margin-left: auto;
    margin-right: auto;
  }

  .footer-bottom {
      padding-left: 0;
  }

  .footer-one-social ul li .social-icon{
    font-size: 27px !important;
    width: 24px;
  }
}
  /* SM Small Device :550px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-bottom p {
    margin: 0;
    text-align: center;
  }
  .footer-bottom-link {
    text-align: center;
  }
  .footer-logo img {
    width: 250px;
    margin-bottom: 15px;
  }
  .footer-top {
    padding-left: 0;
    padding-right: 0;
  }

  .footer-one-social ul li a {
    margin: 0px 7px;
  }
}
</style>