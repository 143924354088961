<template>
  <v-dialog v-model="active_dialog" max-width="500px">
    <v-card min-height="200px" style="overflow-x: hidden; overflow-y: hidden">
      <div class="mb-10">
        <v-card-title class="justify-center mt-10">
          <span v-if="type == 'updated'" id="title" class="mb-n3">Download Updated Application</span>
          <span v-if="type == 'initial'" id="title" class="mb-n3">Download Initial Application</span>
        </v-card-title>
        <img src="../assets/img/icons8-close-24.png" alt="close" class="close-icon position-absolute top-0" style="top: 20px; right: 20px; cusor: pointer;" @click="closePopup()">
      </div>
      <v-sheet height="100%" class="mx-4 my-0" rounded>
        <v-card-text>
          <v-container>
            <span v-if="type == 'updated'">Do you want to download the updated application?</span>
            <span v-if="type == 'initial'">Do you want to download the initial application?</span>
          </v-container>
        </v-card-text>
      </v-sheet>

      <div class="d-flex btns">
        <v-card-actions class="justify-center mt-4" style="color: #ccc; cusor: pointer;">
          <p @click="closePopup()">CANCEL</p>  
        </v-card-actions>
        <v-card-actions class="justify-center">
          <a class="d-flex white--text my-3 action-btn text-decoration-none" :class="downloadLink ? '' : 'disabled'" color="#514d7a" height="55" :href="downloadLink" target="_blank" @click.stop="closeAlertPopup()" download>
            <svg v-if="!loader" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-chevron-compact-right" viewBox="0 0 16 16" color="white">
              <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
            </svg>
            <img v-if="loader" src="../assets/img/Spinner-1s-200px.gif" alt="" height="30">
            <span>DOWNLOAD</span>
          </a>
        </v-card-actions>
      </div><br>
    </v-card>
  </v-dialog>
</template>

<script>
import Company from "@/store/services/admin/company";
import InvestmentService from "@/store/services/investor/investment.service";

export default {
  props: { 
    is_company: Boolean,
    company_id: Number,
    type: String,
    application: String  
  },

  data() {
    return {
      //CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
      pdf_path: process.env.VUE_APP_API_BASE_URL_PDF,
      active_dialog: true,
      loader: false,
      downloadLink: "",
    };
  },

  methods: {
    closeDialog() {
      window.open('/home', "_top");
      //this.active_dialog=false;
    },

    closePopup() {
      this.$emit('closePopup');
    },
    pdfCallback(res) {
      if(res.data){
        this.downloadLink = `${this.pdf_path}${res.data}`;
      }
       this.loader = false;
    },
    downloadPDF(type) {
      this.loader = true;
      if (this.application == 'company') {
        if (type == 'updated') {
          Company.generateCompanyApplicationPdf().then(this.pdfCallback).catch(this.pdfCallback)
        } 
        else {
          Company.downloadInitialApplication(this.company_id).then(this.pdfCallback).catch(this.pdfCallback)
        }
      } else {
        if (type == 'updated') {
          InvestmentService.generateInvestmentUptatedApplicationPdf(localStorage.user_id, true).then(this.pdfCallback).catch(this.pdfCallback)
        } 
        else {
          InvestmentService.downloadInvestorInitialApplication().then(this.pdfCallback).catch(this.pdfCallback)
        }
      }
      
    },
    closeAlertPopup() {
      this.closePopup();
    }
  },
  mounted(){
    this.downloadPDF(this.type);
  }
};
</script>

<style scoped lang="css">
#title {
  font-weight: bolder;
  font-size: 18px;
}
#sub-text p{
  font-weight: normal;
  font-size: 14px;
}
.btns{
  align-items: center !important;
  justify-content: space-around;
}
.action-btn {
  background: #11aec9;
  border-radius: 20px;
  height: 40px;
  padding: 10px 15px;
  /* width: 100px; */
  align-items: center;
  justify-content: space-around;
  outline: none;
}
.action-btn span {
  font-weight: 800;
  font-size: 15px;
}
.sub-text{
  text-align: center !important;
}
.disabled{
  pointer-events: none;
}
</style>
