import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    Authorization:
      "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token"),
  },
};

function getStrategy() {
  return axios.get(`${url}/investor/investment/strategy`, options);
}

function saveStrategy(payload) {
  return axios.post(`${url}/investor/investment/strategy`, payload, options);
}

function getProduct() {
  return axios.get(`${url}/investor/investment/product`, options);
}

function saveProduct(payload) {
  return axios.post(`${url}/investor/investment/product`, payload, options);
}

function getStage() {
  return axios.get(`${url}/investor/investment/stage`, options);
}

function saveStage(payload) {
  return axios.post(`${url}/investor/investment/stage`, payload, options);
}

function updateStatus() {
  return axios.get(`${url}/investor/status`, options);
}

function downloadInvestorInitialApplication() {
  return axios.get(`${url}/investor/initialPdf`, options);
}

function generateInvestmentUptatedApplicationPdf(investor_id, updated) {
  return axios.get(`${url}/me/generate/${investor_id}/${updated}`)
}

export default {
  getStrategy,
  getProduct,
  getStage,
  saveStrategy,
  saveProduct,
  saveStage,
  updateStatus,
  downloadInvestorInitialApplication,
  generateInvestmentUptatedApplicationPdf
};
