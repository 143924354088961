<template>
    <div class="mar-top-100">
        <v-row class="accountStatus">
          <!-- Header -->
            <div class="mb-7 header">
               <h1 class="text-light mt-5">APPLICATION REVIEW IN PROGRESS</h1>
               <hr>
            </div>
            <v-col cols="11" sm="8" class="pt-10">
                <p>
                    You have finish completing the application form. 
                    You can update your investor information and download the new PDF application version. 
                </p>
                <p>
                    Our team is now looking at the information you have provided.
                </p>
                <p>
                    We will notify you if we need more information. 
                </p>
                <p>
                    The process of validation will take 15 days from the reception of your application.
                </p>
                <p>
                    Once your application will be confirmed,  you will be ready to subscribe to our IO newsletter with qualified investment opportunities. 
                </p>
            </v-col>
            <div class="mar-top-100">
                <p>For any further information or question</p>
                <v-btn
                    sm="2"
                    id="btn-photo"
                    class="white--text"
                    color="#58407c"
                    height="90"
                    width="20"
                    rounded
                    block
                    @click="$router.push('/contact')"
                    :disabled="false">
                    CONTACT US
                </v-btn>
            </div>
        </v-row>
    </div>
</template>

<script>
    export default { 
        components : {},
        methods: {}
    }
</script>