<template>
  <v-row align="center" justify="center" class="step-1">
    <v-col cols="10">
      <v-responsive :aspect-ratio="16 / 9">
        <v-card-text>
          <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec">
            <v-row justify="center" class="mx-10">
              <v-col cols="2">
                <v-img
                  style="border-radius: 20%"
                  min-height="95%"
                  :src="avatarPreviewUrl"
                />
              </v-col>
              <v-col cols="10">
                <p class="text-sm-left bold">Main Contact</p>
                <p class="text-sm-left thin">{{ me.first_name }}</p>
                <p class="text-sm-left thin">{{ me.last_name }}</p>
                <p class="text-sm-left thin">{{ me.email }}</p>
              </v-col>
            </v-row>
          </v-sheet>

          <v-col />

          <v-col>
            <p
              v-if="me.investment_type=='individual'"
              class="cyan--text text--darken-1 font-weight-bold text-sm-center"
              id="title"
            >
              Accreditation as an Individual Investor
            </p>

            <p
              v-if="me.investment_type=='firm'"
              class="cyan--text text--darken-1 font-weight-bold text-sm-center"
              id="title"
            >
              Accreditation as a Firm or Fund Investing
            </p>
          </v-col>

          <DescribeBlock @passFirmName="emitToApplication" @block2="typeBlock = true" v-bind:me="me" />

          <TypeBlock
            @block3="vehicleBlock = true"
            v-if="typeBlock"
            v-bind:me="me"
          />

           <VehicleBlock
            @block4="nextBlock = true"
            v-if="vehicleBlock"
          /> 

          <NextBlock @agreed="$emit('done')" v-if="nextBlock" />
        </v-card-text>
      </v-responsive>
    </v-col>
  </v-row>
</template>

<script>
import DescribeBlock from "@/components/Steps/Investor/Step2/Blocks/DescribeBlock";
import TypeBlock from "@/components/Steps/Investor/Step2/Blocks/TypeBlock";
import VehicleBlock from "@/components/Steps/Investor/Step2/Blocks/VehicleBlock";
import NextBlock from "@/components/Steps/Investor/Step2/Blocks/NextBlock";
import Profile from "@/store/services/profile-service";

export default {
  components: {
    DescribeBlock,
    TypeBlock,
    VehicleBlock,
    NextBlock
  },

  props: {
    me: Object,
  },

  data() {
    return {
      avatarPreviewUrl: null,
      typeBlock: false,
      vehicleBlock: false,
      nextBlock: false,
    };
  },

  methods: {
    emitToApplication(firm_name) {
      this.$emit("passFirmName", firm_name);
    },

    retrieveTopAvatar() {
      Profile.getAvatar()
        .then((res) => {
          this.avatarPreviewUrl = res.data;
        })
        .catch((error) => {
        });
    },
  },

  mounted() {
    this.retrieveTopAvatar();
  },
};
</script>
