<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            What is the investment vehicle for this round*?
          </p>
          <p
            class="text-sm-left blue-grey--text text--lighten-2 font-weight-thin"
          >
            Choose at least one of the following choices
          </p>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="" no-gutters>
        <v-col cols="10" md="6" class="pl-5">

          <v-checkbox
            v-model="vehicle.equity"
            label="Equity (priced rounded)"
          ></v-checkbox>

          <v-checkbox
            v-model="vehicle.convertible_note"
            label="Convertible note"
              class="mb-5"
          ></v-checkbox>

          <v-checkbox
            v-model="vehicle.safe"
            label="SAFE"
              class="mb-5"
          ></v-checkbox>

          <v-checkbox
            v-model="vehicle.any_option"
            label="Open to any option"
              class="mb-5"
          ></v-checkbox>
         
        </v-col>
        <v-col cols="3"></v-col>
        <v-col cols="1"></v-col>
      </v-row> 

      <v-col />
      <v-col />
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            What is the percentage of equity offered for the expected
            fundraising amount* ?
          </p>

          <v-row justify="start">
            <v-col cols="3">
              <FormInlineMessage v-if="$v.investor.percent.$error" class="errmsg">
                Please fill the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_investor_percent_less_than_100_percent_error.error">
                  {{ is_investor_percent_less_than_100_percent_error.message }}
              </FormInlineMessage>
              <v-text-field
                v-model="investor.percent"
                placeholder="10"
                prefix="%"
                outlined
                dense
                @input="setInvestorPercent(investor.percent)"
                @keypress="isNumber($event)"
              />
            </v-col>
          </v-row>

          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            Are you ready to have investors on your board of directors* ?
          </p>

          <v-col />
          <v-row justify="start">
            <v-btn
              @click="
                investor.board_director = true;
                not_board_director = false;
              "
              v-bind:color="investor.board_director ? 'primary' : ''"
              class="mr-15"
            >
              YES
            </v-btn>

            <v-col cols="1" />

            <v-btn
              @click="
                investor.board_director = false;
                not_board_director = true;
              "
              v-bind:color="not_board_director ? 'primary' : ''"
              class="mr-15"
            >
              NO
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-col />
      <v-col />
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            What added value should the investor bring ?
          </p>
          <p class="text-sm-left">
            Main field of Expertise*<br />
            <span
              class="text-sm-left blue-grey--text text--lighten-2 font-weight-thin"
              >Enter max 7 keywords separated by ;</span
            >
          </p>

          <v-row justify="start">
            <v-col cols="6">
              <FormInlineMessage v-if="$v.investor.expertise.$error" class="errmsg">
                Expertise is required and must be less than 7 keywords
              </FormInlineMessage>
              <v-combobox
                v-model="investor.expertise"
                :items="entries"
                item-text="name"
                label=""
                outlined
                dense
                multiple
                :search-input.sync="search"
                small-chips
                deletable-chips
                @blur="$v.investor.expertise.$touch()"
              />
            </v-col>
          </v-row>


          <p class="text-sm-left">
            Describe what kind of support you are expecting from the investor*
          </p>

          <v-row justify="start">
            <v-col cols="11">
              <FormInlineMessage v-if="$v.investor.expecting.$error" class="errmsg">
                You must describe what kind of support you are expecting from the investor
              </FormInlineMessage>
              <v-textarea
                v-model="investor.expecting"
                outlined
                dense
                rows="5"
                placeholder=""
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveInvestor"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import AllVar from "@/store/services/var";
import NeedService from "@/store/services/need.service";
import { required, maxLength } from "vuelidate/lib/validators";
import { myMixins } from "@/mixins/mixins";

export default {
  mixins: [myMixins],
  components: {
    FormInlineMessage,
  },

  props: {
    company_id: Number,
  },

  data() {
    return {
      currentBlock: true,
      not_board_director: false,
      queryTerm: "",
      entries: [],

      vehicle: {
        equity:false,
        convertible_note:false,
        safe:false,
        any_option:false,
      },
      
      investor: {
        percent: "",
        board_director: false,
        expertise: [],
        expecting: "",
      },
    };
  },

  validations: {
    investor: {
      required,
      // vehicle: { required },
      percent: { required },
      board_director: {
        notSameAs() {
          return this.not_board_director != this.investor.board_director;
        },
      },
      expertise: {
        required,
        maxLength: maxLength(7),
      },
      expecting: { required },
    },
    vehicle: { required },
  },

  methods: {
    saveInvestor() {
      this.$v.$touch();
      if (this.$v.$error || this.is_investor_percent_less_than_100_percent_error.error) {
        return;
      }

      let vehicle = [];
      for(const item in this.vehicle) {
        if(this.vehicle[item]!==false){
          if(item=='otherField') {
            vehicle = [];
            vehicle.push(this.vehicle[item]);
          } else {
            vehicle.push(item);
          }
        }
      }

      this.investor.vehicle = vehicle.join();

      NeedService.saveInvestor({
        company_id: this.company_id,
        investor: this.investor,
        // vehicle: vehicle.join()
      });

      this.currentBlock = false;
      this.$emit("block3");
    },
    getInvestor() {
      NeedService.getInvestor(this.company_id)
        .then((res) => {
          if (res.data) {
            this.investor.percent = res.data.investor.percent;
            this.investor.board_director = res.data.investor.board_director;
            this.investor.expecting = res.data.investor.expecting;
            this.not_board_director = !res.data.investor.board_director;
            res.data.expertise.forEach(element => {
              this.investor.expertise.push(element.skill)
            });

            let vehicle = res.data.investor.vehicle;

            if(vehicle.includes("equity") ) {
              this.vehicle.equity = true;						
            } 
            if(vehicle.includes("convertible_note")){
              this.vehicle.convertible_note = true;				
            }
            
            if(vehicle.includes("safe")){
              this.vehicle.safe = true;				
            }	
            
            if(vehicle.includes("any_option")){
              this.vehicle.any_option = true;
              return;				
            }	
          }
        })
        .catch((e) => {});
    },
    loadEntries() {
      AllVar.getAllSkills(this.queryTerm).then((res) => {
        this.entries = res.data;
      });
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  computed: {
    search: {
      get: function () {
        return this.queryTerm;
      },

      set: function (searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadEntries();
        }
      },
    },
  },
  mounted() {
    this.getInvestor();
  },
};
</script>
