<template>
    <div>
        <Header @changeComponent="changeComponent" v-bind:avatarPreviewUrl="this.avatarPreviewUrl" class="d-none d-md-flex"/>
        <HeaderPortable @changeComponent="changeComponent" @openAlertPopupDownload="openAlertPopupDownload" v-bind:id="company_id" class="d-flex d-md-none"/>
        <v-row>
            <v-col cols="0" md="2" class="d-none d-md-flex" style="z-index: 10">
                <Menu @changeComponent="changeComponent" @openAlertPopupDownload="openAlertPopupDownload" v-bind:current="this.current" ref="MenuDashboard"/>
            </v-col>

            <v-col cols="12" :md="current == 'AccountStatus' ? 10 : 10" lg="10">
                <AlertPopup v-if="this.enablePopup" @closePopup="closeDialog()" :company_id="company_id" :type="type" :application="application" />
                <div
                :is="component"
                v-bind:avatarPreviewUrl="this.avatarPreviewUrl"
                v-bind:company_id="this.company_id"/>
            </v-col>
        </v-row>
        <Footer/>
    </div>
</template>


<script>
import AlertPopup from "@/components/AlertPopup.vue";
import Profile from "@/store/services/profile-service";

import Header from '@/components/Dashboard/Investor/HeaderDashboard.vue';
import HeaderPortable from '@/components/Dashboard/Investor/HeaderDashboardPortable.vue';
import Footer from '@/components/FooterCms.vue';
import Menu from '@/components/Dashboard/Investor/DashboardMenu.vue';

import MainContact from '@/components/Dashboard/Investor/MainContact.vue'
import LegalRepresentative from '@/components/Dashboard/Investor/LegalRepresentative.vue'
import Accreditation from '@/components/Dashboard/Investor/Accreditation.vue'
import InvestmentStrategy from '@/components/Dashboard/Investor/InvestmentStrategy.vue'

import Product from '@/components/Dashboard/TypeOfBusiness.vue'
import FinanceHistory from '@/components/Dashboard/FinanceHistory.vue'
import FundingNeeds from '@/components/Dashboard/FundingNeeds.vue'
import BillingAddress from '@/components/Dashboard/Investor/BillingAddress.vue'
import Invoices from '@/components/Dashboard/Investor/Invoices.vue'
import ChangePwd from '@/components/Dashboard/Modifypwd.vue'
import AccountStatus from '@/components/Dashboard/Investor/AccountStatus.vue'

export default {
    components: {
        Header,
        HeaderPortable,
        Footer,
        Menu,
        AlertPopup,

        MainContact,
        LegalRepresentative,
        Accreditation,
        InvestmentStrategy,

        Product,
        FinanceHistory,
        FundingNeeds,
        BillingAddress,
        Invoices,
        ChangePwd,
    },
    data() {
        return {
            company_id: null,
            component: AccountStatus,
            avatarPreviewUrl: null,
            current: 'AccountStatus',
            enablePopup: false,
            type: null,
            application: null,
        }
    },

    methods: {
        changeComponent(comp) {
            this.current = comp;
            this.component = null;
            // if (id != null) { this.company_id = id; }
            if (comp == 'mainContact') { this.component = MainContact; }
            else if (comp == 'legalRepresentative') { this.component = LegalRepresentative; }
            else if (comp == 'accreditation') { this.component = Accreditation; }
            else if (comp == 'investment_strategy') { this.component = InvestmentStrategy; }

            else if (comp == 'product') { this.component = Product; }
            else if (comp == 'financeHistory') { this.component = FinanceHistory; }
            else if (comp == 'fundingNeeds') { this.component = FundingNeeds; }
            else if (comp == 'billingAddress') { this.component = BillingAddress; }
            else if (comp == 'invoices') { this.component = Invoices; }
            else if (comp == 'changepwd') { this.component = ChangePwd; }
            else if (comp == 'AccountStatus') { this.component = AccountStatus; }
        },
        updateGoogleAnalytics()
        {
            this.$gtag.event('Company_Dashboard', { method: 'Google' });
        },
        openAlertPopupDownload(val, application){
            this.type = val;
            this.application = application;
            this.enablePopup = true;
        },
        closeDialog() {
            this.enablePopup = false;
            this.current = 'mainContact';
        },
    },

    created() {
        Promise.all([
            Profile.getAvatar()
            .then((res) => { this.avatarPreviewUrl = res.data; })
            .catch((error) => { 
                //console.log(error); 
            }),
        ]);
    },
}
</script>