import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      Authorization: "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token")
    },
};


function getAll() {
  return axios.get(`${url}/user/application`, options)
}

function saveApplicant(payload)  {
  return axios.post(`${url}/user/application`, payload, options);

}

function destroy(id) {
  return axios.delete(`${url}/user/education/${id}`, options);
}

function saveDescribe(payload) {
  return axios.post(`${url}/company/describe`, payload, options);
}

function getDescribe(){
  return axios.get(`${url}/company/describe`, options);
}

function getName(company_id){
  return axios.get(`${url}/company/name/${company_id}`, options);
}

function saveLink(payload) {
  return axios.post(`${url}/company/link`, payload, options);
}

function getLink(company_id) {
  return axios.get(`${url}/company/link/${company_id}`,options);
}

function saveResume(payload) {
  return axios.post(`${url}/company/resume`, payload, options);
}

function getResume(company_id) {
  return axios.get(`${url}/company/resume/${company_id}`, options);
}

function saveLogo(payload) {
  return axios.post(`${url}/company/logo`,payload, options);
}

function getLogo(company_id) {
  return axios.get(`${url}/company/logo/${company_id}`, options); //it will have a payload, which will contain company_id carried from a global variable
}

function getAllCompanies() {
  return axios.get(url + "/company", options);
}

function getCompanyWithRolesC2OrC3() {
  return axios.get(url + "/company/roles/c2or3", options);
}

export default {  
  destroy,
  getAll,
  saveApplicant,
  saveDescribe,
  getDescribe,
  saveLink,
  getLink,
  saveResume,
  getResume,
  saveLogo,
  getLogo,
  getAllCompanies,
  getName,
  getCompanyWithRolesC2OrC3
};
