<template>
  <div class="login global-body">
    <div class="container-login">
      <img class="inoLogo" src="../../assets/logo.png" alt="logo"/>
      <p class="description">Sign in for Admin Access</p>

      <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="errorLogin">
        <strong>Error Message!</strong> {{ errorMessage }}.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="errorLogin = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="login-input">
        <input type="text" v-model="form_login.username" placeholder="Your e-mail" @keypress="errorLogin = false" />
      </div><br>
      <div class="login-input">
        <input type="password" v-model="form_login.password" placeholder="Password" @keypress="errorLogin = false" />
      </div>
      <div class="formRemberme"> 
        <label>
          <input type="checkbox" name="remember"> Remember me
        </label>
      </div>
      <button class="btnSubmit" @click="login()" v-if="!loader">LOGIN</button>
      <img src="../../assets/img/Spinner-1s-200px.gif" v-else alt="Loader" height="70" class="loader">
    </div>
  </div>
</template>

<script>
import Company from "@/store/services/admin/company";
import Admin from "@/store/services/admin/admin";
export default {
  name: "Login",
  components: {
  },

  data() {
    return {
      errorLogin: false,
      loader: false,
      form_login: { },
      log: {
        id: null,
        profile: null,
      }
    }
  },
  mounted() {
  },
  methods: {
    saveLogs(userId, profile) {
      this.log.id = userId;
      this.log.profile = profile;
      Admin.storeLogs(this.log).then((res)=> {
        if (res.status) {
          console.log(res);
        }
      })
    },

    login() {
      this.loader = true;
      if (!this.form_login.username) {
        this.errorLogin = true;
        this.errorMessage = 'Please provide a valid email'
        this.loader = false;
      }
      else if (!this.form_login.password) {
        this.errorLogin = true;
        this.errorMessage = 'Please provide a valid password'
        this.loader = false;
      } else {
        Company.login(JSON.stringify(this.form_login)).then(res=>{
          if (res.status == 200) {
            localStorage.profile = JSON.stringify(res.data);
            this.$router.push('/admin/company');
            this.saveLogs(res.data.user_id, 'Admin')
          } else {
            this.errorLogin = true;
            this.errorMessage = res.data.message;
            this.loader = false;
          }
        })
      }
    }
  }
};
</script>
<style src="../../assets/styles/admin/login.css"> </style>
