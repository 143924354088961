import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

function getAllBusinessModel() {
  return axios.get(`${url}/var/businessmodels`);
}

function getAllCountry() {
  return axios.get(url + "/var/country");
}

function getCountryCode(country_name) {
  return axios.get(`${url}/var/countrycode/${country_name}`);
}

function getAllDegrees() {
  return axios.get(`${url}/var/degrees`);
}

function getAllIndustries() {
  return axios.get(`${url}/var/industries`);
}

function getAllLocations() {
  return axios.get(url + "/var/locations");
}

function getAllMakesProductUniques() {
  return axios.get(url+ "/var/makesproductunique");
}

function getAllProductLifeCycles() {
  return axios.get(url+ "/var/productlifecycles");
}

function getAllStageOfMarketMaturity() {
  return axios.get(`${url}/var/stageofmarketmaturities`);
}

function getAllStageOfYourCompanies() {
  return axios.get(`${url}/var/stageofyourcompanies`);
}

function getAllTypeOfOffers() {
  return axios.get(url+ "/var/typeofoffers");
}

function getAllShareholders() {
  return axios.get(url+ "/var/typeshareholders");
}

function getAllYearsOfExperience() {
  return axios.get(url + "/var/yearsofexperiences");
}

/* Ajout Skills 02-10-2022 */
function getAllSkills(key) {
  return axios.get(`${url}/var/skills?q=${key}`);
}

function getRate() {
  return axios.get(url + "/var/rate");
}

function updateRate(payload) {
  console.log("RAT", payload)
  return axios.post(`${url}/var/rate`, payload);
}

function getProducts() {
  return axios.get(url + "/var/products");
}

function getProduct(id) {
  return axios.get(`${url}/var/product/${id}`);
}

function getUserProfiles() {
  return axios.get(url + "/var/profiles");
}

function getTimeZones() {
  return axios.get(url + "/var/time-zones");
}

function getVehicles() {
  return axios.get(url + "/var/vehicules");
}

function getStages() {
  return axios.get(url + "/var/stages");
}

function getAllVariables() {
  return axios.get(`${url}/var/variables`)
}

function getAllProdVariables() {
  return axios.get(`${url}/var/product-variables`)
}

function getAllIndustryVariables() {
  return axios.get(`${url}/var/industries-variables`)
}

export default {
  getAllBusinessModel,
  getAllCountry,
  getCountryCode,
  getAllDegrees,
  getAllIndustries,
  getAllLocations,
  getAllMakesProductUniques,
  getAllProductLifeCycles,
  getAllStageOfMarketMaturity,
  getAllStageOfYourCompanies,
  getAllTypeOfOffers,
  getAllShareholders,
  getAllYearsOfExperience,
  getAllSkills,
  getRate,
  updateRate,
  getProducts,
  getUserProfiles,
  getProduct,
  getTimeZones,
  getVehicles,
  getStages,
  getAllVariables,
  getAllProdVariables,
  getAllIndustryVariables
};
