import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

axios.defaults.headers.post["Content-Type"] = "application/vnd.api+json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token");
axios.defaults.headers.common["Accept"] = "application/vnd.api+json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET, POST, OPTIONS, DELETE, PUT, PATCH";
axios.defaults.headers.common["Access-Control-Allow-Headers"] =
  "Cache-Control, Content-Language, Content-Type, Expires, Last-Modified, Pragma";
axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";

function payment() {
  return axios.get(url + "/paypal/payment/");
}

function getInvoice() {
  return axios.get(url + "/paypal/invoice/", { responseType: 'blob' });
}

function charge(payload) {
  return axios.post(url + "/paypal/charge/", payload);
}

function chargeAnonymous(payload) {
  return axios.post(url + "/paypal/charge-anonymous/", payload);
}

function chargeSubscription(payload) {
  return axios.post(url + "/paypal/subscription/", payload);
}

function subscriptionSuccess(payload) {
  return axios.post(url + "/paypal/subscriptionsuccess/", payload);
}

function paymentSuccess(payload) {
  return axios.post(url + "/paypal/paymentsuccess/", payload);
}

function promoCodeSuccess(payload) {
  return axios.post(url + "/paypal/promocodesuccess/", payload);
}

function productPaymentSuccess(payload) {
  return axios.post(url + "/paypal/product-paymentsuccess/", payload);
}

function productPromoCodeSuccess(payload) {
  return axios.post(url + "/paypal/product-promocodesuccess/", payload);
}

function getAllCompanyInvoices(company_id) {
  return axios.get(`${url}/paypal/invoices/${company_id}`);
}

function getAllInvestorInvoices(user_id) {
  return axios.get(`${url}/paypal/invoices/investor/${user_id}`);
}

function checkCode(payload) {
  return axios.post(`${url}/paypal/coupon/verify-code`, payload);
}

function checkCodeAnonymousUser(payload) {
  return axios.post(`${url}/paypal/coupon/verify-code-anonymous-user`, payload);
}

export default {
  payment,
  getInvoice,
  charge,
  chargeAnonymous,
  paymentSuccess,
  promoCodeSuccess,
  chargeSubscription,
  subscriptionSuccess,
  getAllCompanyInvoices,
  getAllInvestorInvoices,
  checkCode,
  checkCodeAnonymousUser,
  productPaymentSuccess,
  productPromoCodeSuccess
};
