import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

axios.defaults.headers.post["Content-Type"] = "application/vnd.api+json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token");
axios.defaults.headers.common["Accept"] = "application/vnd.api+json";

function saveFund(payload) {
  return axios.post(`${url}/need/fund`, payload);
}
function getFund(company_id) {
  return axios.get(`${url}/need/fund/${company_id}`);
}

function saveInvestor(payload) {
  return axios.post(`${url}/need/investor`, payload);
}
function getInvestor(company_id) {
  return axios.get(`${url}/need/investor/${company_id}`);
}

export default {
  saveFund,
  getFund,
  saveInvestor,
  getInvestor
};