<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="11">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            Investment vehicles used
          </p>
          <p class="text-sm-left thin my-n2">
            Choose at least one of the following choices
          </p>

          <v-row no-gutters>
            <v-col cols="11" class="pl-6">
              <v-checkbox
                @change="changeCheckboxValue('equity')"
                v-model="model.equity"
                label="Equity (priced rounded)"
                class="newbold"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="11" class="pl-6">
              <v-checkbox
                @change="changeCheckboxValue('convertible_note')"
                v-model="model.convertible_note"
                label="Convertible note"
                class="mt-2 newbold"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="11" class="pl-6">
              <v-checkbox
                @change="changeCheckboxValue('safe')"
                v-model="model.safe"
                label="SAFE"
                class="mt-2 newbold"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row no-gutters class="pb-6">
            <v-col cols="11" class="pl-6">
              <v-checkbox
                @change="changeCheckboxValue('any_option')"
                v-model="model.any_option"
                label="Open to any option"
                class="mt-2 newbold"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <p></p>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-8">
      <v-row no-gutters class="pt-8">
        <v-col cols="1" />
        <v-col cols="11">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            Do you invest only in specific founding team profile ?
          </p>
          <p class="text-sm-left thin mt-n2 mb-5">
            You may require a specific founding team profile for investment
            consideration. If not, then select "NO"
          </p>
        </v-col>
        <v-col cols="1" />
      </v-row>

      <v-row class="mt-n3">
          <v-col cols="1" />
        <v-col cols="2">
          <p class="text-sm-left">Female founder</p>
        </v-col>

        <v-col cols="3">
          <v-btn
            @click="specific_profile.female_founder = true"
            v-bind:color="specific_profile.female_founder ? 'primary' : ''"
            class="mr-6"
          >
            YES
          </v-btn>

          <v-btn
            @click="specific_profile.female_founder = false"
            v-bind:color="!specific_profile.female_founder ? 'primary' : ''"
            
          >
            NO
          </v-btn>
        </v-col>
        <v-col cols="1" />
      </v-row>

      <v-row class="mt-n3">
          <v-col cols="1" />
        <v-col cols="2">
          <p class="text-sm-left">Founder of color</p>
        </v-col>

        <v-col cols="3">
          <v-btn
            @click="specific_profile.founder_of_color = true"
            v-bind:color="specific_profile.founder_of_color ? 'primary' : ''"
            class="mr-6"
          >
            YES
          </v-btn>

          <v-btn
            @click="specific_profile.founder_of_color = false"
            v-bind:color="!specific_profile.founder_of_color ? 'primary' : ''"
          >
            NO
          </v-btn>
        </v-col>
        <v-col cols="1" />
      </v-row>

      <v-row class="mt-n3 mb-4">
          <v-col cols="1" />
        <v-col cols="2">
          <p class="text-sm-left">LGBTQ founder</p>
        </v-col>

        <v-col cols="3">
          <v-btn
            @click="specific_profile.lgbtq_founder = true"
            v-bind:color="specific_profile.lgbtq_founder ? 'primary' : ''"
            class="mr-6"
          >
            YES
          </v-btn>

          <v-btn
            @click="specific_profile.lgbtq_founder = false"
            v-bind:color="!specific_profile.lgbtq_founder ? 'primary' : ''"
          >
            NO
          </v-btn>
        </v-col>
      </v-row>
      <v-col cols="1" />
    </v-sheet>
    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveVehicle"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import AccreditationService from "@/store/services/investor/accreditation.service";

import { required } from "vuelidate/lib/validators";

export default {
  components: {
    FormInlineMessage,
  },

  data() {
    return {
      model: {
        equity: false,
        convertible_note: false,
        safe: false,
        any_option: false,
      },
      vehicle_array: [],
      specific_profile: {
        female_founder: false,
        founder_of_color: false,
        lgbtq_founder: false,
      },
      currentBlock: true,
    };
  },

  validations: {
    specific_profile: { required },
    vehicle_array: { required }
 },

  methods: {
    changeCheckboxValue(value) {
      if (this.vehicle_array.includes(value)) {
        this.vehicle_array.splice(this.vehicle_array.indexOf(value), 1);
      } else {
        this.vehicle_array.push(value);
      }
    },
    saveVehicle() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      AccreditationService.saveVehicle(JSON.stringify({vehicle_array: this.vehicle_array, specific_profile: this.specific_profile}))
        .then((res) => {
          this.currentBlock = false;
          this.$emit("block4");
        })
        .catch((e) => {
          //console.error(e);
        });
    },
    getVehicle() {
      AccreditationService.getVehicle()
        .then((res) => {
          res.data.investment_vehicles.forEach((entry) => {
            if (entry == "equity") {
              this.model.equity = true;
            } else if (entry == "convertible_note") {
              this.model.convertible_note = true;
            } else if (entry == "safe") {
              this.model.safe = true;
            } else if (entry == "any_option") {
              this.model.any_option = true;
            }
          });
          this.vehicle_array = res.data.investment_vehicles;
          let vehicle = res.data;
          this.specific_profile.female_founder   = vehicle.female;
          this.specific_profile.founder_of_color = vehicle.color;
          this.specific_profile.lgbtq_founder    = vehicle.lgbtq;
        })
        .catch((e) => {
          //console.error(e);
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getVehicle();
  },
};
</script>

<style scss>
.mybold label {
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
  font-size: 20px;
}

.newbold label {
  /* color: rgb(0, 0, 0) !important; */
  /* font-weight: bold; */
  margin-top: 9px;
}
</style>
