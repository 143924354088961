<template>
    <v-row align="center" justify="center" class="mar-top-100">
        <v-col cols="10">
            <p class="cyan--text text--darken-1 mt-3 text-sm-left" id="title">Investor Main Contact</p>

            <!-- Header -->
            <div style="background-color: #c5e7ec;" class="mb-7 profileContent">
                <v-row class="topMenu">
                    <v-col cols="4" md="2" class="profilImg">
                        <v-img
                        class="py-5"
                        contain
                        minWidth="75px"
                        minHeight="75px"
                        maxWidth="150px"
                        maxHeight="150px"
                        style="border-radius: 50%; border: 0 solid;"
                        :src="avatarPreviewUrl"/>
                    </v-col>
                    
                    <v-col cols="auto" class="pt-10">
                        <p class="thin text-sm-left">{{me.first_name}}</p>
                        <p class="thin text-sm-left">{{me.last_name}}</p>
                        <p class="thin text-sm-left">{{me.email}}</p>
                        <p class="thin text-sm-left profilUserMobile">Born on {{ formatDate(personal.date_of_birth) }}</p>
                        <p class="thin text-sm-left profilUserMobile">Gender : {{ personal.gender == 'male' ? 'M' : personal.gender == 'female' ? 'F' : capitalize(personal.gender) }}</p>
                        <p class="thin text-sm-left profilUserWeb">
                            <span col="12" class="mr-10">Born on {{ formatDate(personal.date_of_birth) }}</span>
                            <span class="ml-10">Gender : {{ personal.gender == 'male' ? 'M' : personal.gender == 'female' ? 'F' : capitalize(personal.gender) }}</span>
                        </p>
                    </v-col>
                </v-row>
            </div>

            <v-row>
                <v-col>
                    <span class="float-right" style="color: #ccc;">Required fields <span style="color:#000;">*</span> to save your data</span>
                </v-col>
            </v-row>
            
            <!-- Body -->
            <div id="personal">

                <!-- Live -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.personal.city.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Where I live*</p>
                    </v-col>
                    <v-col cols="12" sm="9">
                        <FormInlineMessage v-if="$v.personal.city.$error">
                            Please select where you live
                        </FormInlineMessage>
                        <v-autocomplete
                        v-model="personal.city"
                        :items="country"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <!-- Phone -->
                <v-row>
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.phoneValid.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Phone*</p>
                    </v-col>
                    <v-col cols="12" sm="9">
                        <FormInlineMessage v-if="$v.phoneValid.$error">
                            Please select valid phone number
                        </FormInlineMessage>
                        <vue-tel-input
                        defaultCountry="country"
                        v-model="phone"
                        @validate="validatePhone"
                        dense/>
                    </v-col>
                </v-row>

                <!-- Years of experience -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.personal.experience.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Years of experience*</p>
                    </v-col>
                    <v-col cols="12" sm="9">
                        <FormInlineMessage v-if="$v.personal.experience.$error">
                            Please select years of experience
                        </FormInlineMessage>
                        <v-select
                        v-model="personal.experience"
                        :items="yearsExperiences"
                        dense
                        outlined/>
                    </v-col>
                </v-row>

                <!-- Social Networks -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="3">
                        <p class="text-sm-left">My Social Networks</p>
                    </v-col>
                    <v-col cols="12" sm="9">
                        <v-text-field
                        v-model="personal.social_network.twitter"
                        prepend-inner-icon="mdi-twitter pb-2"
                        outlined
                        dense
                        placeholder="https://twitter.com/@john"/>

                        <v-text-field
                        class="mt-n6"
                        v-model="personal.social_network.linkedin"
                        prepend-inner-icon="mdi-linkedin pb-2"
                        outlined
                        dense
                        placeholder="https://linkedin.com/in/john"/>
                    </v-col>
                </v-row>
                <v-divider style="border-color: #11aec9 !important;" />

                <!-- Save button -->
                <v-row align="center" justify="center" class="pt-2 btn-save">
                    <v-col cols="0" xs="5" sm="8" md="9" lg="11"/>
                    <v-col cols="12" xs="1" sm="1" md="1" lg="1">
                        <v-btn
                        id="btn-photo"
                        class="white--text mb-3"
                        style="font-weight: bold; font-size: 14px"
                        color="#11aec9"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.stop.prevent="savePersonal()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg> save
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <div id="education">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Education</p>
                <v-row>
                    <v-col>
                        <span class="float-right" style="color: #ccc;">Required fields <span style="color:#000;">*</span> to save your data</span>
                    </v-col>
                </v-row>
                

                <div v-for="(education, index) in educations" :key="index">

                    <!-- School name -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="3">
                            <FormInlineMessage v-if="$v.educations.$each[index].school.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">School</p>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <FormInlineMessage v-if="$v.educations.$each[index].school.$error">
                                Provide School Name
                            </FormInlineMessage>
                            <v-text-field
                            v-model="education.school"
                            outlined
                            dense/>
                        </v-col>
                    </v-row>

                    <!-- Date -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="3">
                            <FormInlineMessage v-if="$v.educations.$each[index].start_year.$error || $v.educations.$each[index].end_year.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Date Attended</p>
                        </v-col>

                        <!-- Start date -->
                        <v-col cols="12" sm="4">
                            <FormInlineMessage v-if="$v.educations.$each[index].start_year.$error">
                                When did you start?
                            </FormInlineMessage>
                            <FormInlineMessage v-else-if="$v.educations.$each[index].end_year.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <v-menu
                            ref="menuStart"
                            :close-on-content-click="false"
                            :return-value.sync="education.start_year"
                            offset-y
                            min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    height="40"
                                    outlined
                                    v-model="education.start_year"
                                    placeholder="Start"
                                    readonly
                                    dense
                                    v-bind="attrs"
                                    v-on="on"/>
                                </template>

                                <v-date-picker
                                v-model="education.start_year"
                                no-title
                                scrollable
                                type="month">

                                    <v-spacer/>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuStart[index].isActive = false">
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuStart[index].save(education.start_year)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="0" sm="1" class="mt-3">
                            <p>———</p>
                        </v-col>
                        
                        <!-- End date -->
                        <v-col cols="12" sm="4">
                            <FormInlineMessage v-if="$v.educations.$each[index].end_year.$error">
                                When did you end?
                            </FormInlineMessage>
                            <FormInlineMessage v-else-if="$v.educations.$each[index].start_year.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <v-menu
                            ref="menuEnd"
                            :close-on-content-click="false"
                            :return-value.sync="education.end_year"
                            offset-y
                            min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    height="40"
                                    outlined
                                    v-model="education.end_year"
                                    placeholder="End"
                                    readonly
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    @blur="$v.educations.$each[index].end_year.$touch()"/>
                                </template>

                                <v-date-picker
                                    v-model="education.end_year"
                                    no-title
                                    scrollable
                                    type="month">

                                    <v-spacer/>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuEnd[index].isActive = false">
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuEnd[index].save(education.end_year)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <!-- Degree -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="3">
                            <FormInlineMessage v-if="$v.educations.$each[index].degree.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Degree</p>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <FormInlineMessage v-if="$v.educations.$each[index].degree.$error">
                                Please select your degree
                            </FormInlineMessage>
                            <v-select
                            v-model="education.degree"
                            :items="degrees"
                            dense
                            outlined/>
                        </v-col>
                    </v-row>

                    <!-- Field of study -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="3">
                            <FormInlineMessage v-if="$v.educations.$each[index].field.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Field of Study</p>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <FormInlineMessage v-if="$v.educations.$each[index].field.$error">
                                Please indicate field of study
                            </FormInlineMessage>
                            <v-text-field
                            v-model="education.field"
                            dense
                            outlined/>
                        </v-col>
                    </v-row>

                    <!-- Description -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="3">
                            <FormInlineMessage v-if="$v.educations.$each[index].description.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Description</p>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <FormInlineMessage v-if="$v.educations.$each[index].description.$error">
                                Please type the description
                            </FormInlineMessage>
                            <v-textarea
                            v-model="education.description"
                            outlined
                            rows="4"
                            dense/>
                        </v-col>
                    </v-row>
                    <v-divider style="border-color: #11aec9 !important;" />
                </div>

                <!-- Add/Remove/Save button -->
                <v-row class="mt-n4 btn-save">
                    <v-col cols="12" sm="2">
                        <v-btn
                        id="btn-add"
                        text
                        color="#00aac3"
                        @click.prevent.stop="addEducation()">
                        + Add education
                        </v-btn>
                    </v-col>
                    <v-col cols="0" sm="2" md="2" lg="2"/>
                    <v-col cols="12" sm="2" md="2" lg="2" v-if="educations.length > 1">
                        <v-btn
                        id="btn-add"
                        text
                        color="#00aac3"
                        @click.prevent.stop="deleteEducation()">
                        - Remove education
                        </v-btn>
                    </v-col> 

                    <v-col cols="0" sm="2" md="2" lg="2" v-else/>    

                    <v-col cols="0" sm="4" md="4" lg="5"/>

                    <v-col cols="12" sm="2" md="1" lg="1">
                            <v-btn
                            id="btn-photo"
                            class="white--text mb-3"
                            style="font-weight: bold; font-size: 14px"
                            color="#11aec9"
                            min-width="20"
                            rounded
                            block
                            :disabled="false"
                            @click.stop.prevent="saveEducation()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg> save
                            </v-btn>
                    </v-col>       
                </v-row>
            </div>

            <div id="experience" class="mt-10">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Experience</p>

                <div v-for="(experience, index) in experiences" :key="index">

                    <!-- Company name -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="3">
                            <FormInlineMessage v-if="$v.experiences.$each[index].company.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Name company</p>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <FormInlineMessage v-if="$v.experiences.$each[index].company.$error">
                                Provide Company Name
                            </FormInlineMessage>
                            <v-text-field
                            v-model="experience.company"
                            dense
                            outlined/>
                        </v-col>
                    </v-row>

                    <!-- Title -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="3">
                            <FormInlineMessage v-if="$v.experiences.$each[index].title.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Title</p>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <FormInlineMessage v-if="$v.experiences.$each[index].title.$error">
                                Provide your title
                            </FormInlineMessage>
                            <v-text-field
                            v-model="experience.title"
                            dense
                            outlined/>
                        </v-col>
                    </v-row>

                    <!-- Location -->
                    <v-row class="mb-n8">
                        <v-col cols="12" sm="3">
                            <FormInlineMessage v-if="$v.experiences.$each[index].location.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Location</p>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <FormInlineMessage v-if="$v.experiences.$each[index].location.$error">
                                Provide location
                            </FormInlineMessage>
                            <v-autocomplete
                            v-model="experience.location"
                            :items="country"
                            outlined
                            dense/>
                        </v-col>
                    </v-row>

                    <!-- Time period -->
                   

                    <v-row class="mb-n8">
                         <v-col cols="12" sm="2">
                            <FormInlineMessage v-if="$v.experiences.$each[index].start.$error">
                                <v-col cols="0" sm="12"/>
                            </FormInlineMessage>
                            <p class="text-sm-left">Time period</p>
                        </v-col>
                        <v-col cols="12" sm="10" lg="8" class="ml-n2 timePeriod">
                            <v-row align="center" justify="center" no-gutters>
                                <v-col class="mt-n5" cols="3" sm="2">
                                    <FormInlineMessage v-if="$v.experiences.$each[index].start.$error">
                                        <v-col cols="0" sm="12"/>
                                    </FormInlineMessage>
                                    <p>begin</p>
                                </v-col>
                                <!-- Begin date -->
                                <v-col cols="8" sm="8">
                                    <FormInlineMessage
                                        v-if="$v.experiences.$each[index].start.$error"
                                        >
                                        When did you start?
                                    </FormInlineMessage>
                                    <v-menu
                                        ref="menuStartExperience"
                                        :close-on-content-click="false"
                                        :return-value.sync="experience.start"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            height="40"
                                            outlined
                                            v-model="experience.start"
                                            placeholder="Start"
                                            readonly
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            @blur="$v.experiences.$each[index].start.$touch()"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="experience.start"
                                            no-title
                                            scrollable
                                            type="month"
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menuStartExperience[index].isActive = false"
                                            >
                                            Cancel
                                            </v-btn>
                                            <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menuStartExperience[index].save(experience.start)"
                                            >
                                            OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                    <!-- end of date picker -->
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" class="mt-n5 ml-n2" no-gutters>
                                <v-col cols="5" sm="6" class="ma-2" justify="center">
                                    <v-checkbox
                                    v-model="experience.current_work"
                                    label="I currently work here"
                                    color="#11aec9"
                                    value="current"
                                    hide-details
                                    dense
                                    class="pb-8 mb-0 pt-0 mt-0 newbold"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                            <v-row align="center" justify="center" no-gutters>
                                <v-col class="mt-n16 ml-n2" cols="3" sm="2" v-if="experience.current_work != 'current'">
                                    <FormInlineMessage v-if="$v.experiences.$each[index].end.$error">
                                        <v-col cols="0" sm="12"/>
                                    </FormInlineMessage>
                                    <p>end</p>
                                </v-col>
                                <v-col cols="8" sm="8" class="mt-2" v-if="experience.current_work != 'current'">
                                    <FormInlineMessage
                                        v-if="$v.experiences.$each[index].end.$error"
                                    >
                                        When did you end?
                                    </FormInlineMessage>
                                    <v-menu
                                        ref="menuEndExperience"
                                        :close-on-content-click="false"
                                        :return-value.sync="experience.end"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            height="40"
                                            outlined
                                            class="mt-n2"
                                            v-model="experience.end"
                                            placeholder="End"
                                            readonly
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            @blur="$v.experiences.$each[index].end.$touch()"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="experience.end"
                                            no-title
                                            scrollable
                                            type="month"
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menuEndExperience[index].isActive = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menuEndExperience[index].save(experience.end)"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="1"> </v-col>
                    </v-row>
                    <v-divider style="border-color: #11aec9 !important;" />
                </div>

                <!-- Add/Remove/Save button -->
                <v-row class="mt-n4 btn-save">
                    <v-col cols="12" sm="2">
                        <v-btn
                        id="btn-add"
                        text
                        color="#00aac3"
                        @click.prevent.stop="addExperience()">
                        + Add position
                        </v-btn>
                    </v-col>
                    <v-col cols="0" sm="2"/>
                    <v-col cols="12" sm="2" v-if="experiences.length > 1">
                        <v-btn
                        id="btn-add"
                        text
                        color="#00aac3"
                        @click.prevent.stop="deleteExperience()">
                        - Remove position
                        </v-btn>
                    </v-col> 

                    <v-col cols="0" sm="2" md="2" lg="2" v-else/>    

                    <v-col cols="0" sm="4" md="4" lg="5"/>

                    <v-col cols="12" sm="2" md="1" lg="1">
                            <v-btn
                            id="btn-photo"
                            class="white--text mb-3"
                            style="font-weight: bold; font-size: 14px"
                            color="#11aec9"
                            min-width="20"
                            rounded
                            block
                            :disabled="false"
                            @click.stop.prevent="saveExperience()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg> save
                            </v-btn>
                    </v-col>       
                </v-row>
            </div>

            <div id="know-how" class="mt-10">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">My know-how</p>

                <!-- Skills -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.skills.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left mt-2">Main Fields of Expertise*</p>
                    </v-col>
                    <v-col cols="12" sm="9">
                        <FormInlineMessage v-if="$v.skills.$error">
                            Please select skills
                        </FormInlineMessage>
                        <v-combobox
                        v-model="skills"
                        :items="entries"
                        item-text="name"
                        label=""
                        outlined
                        dense
                        multiple
                        :search-input.sync="search"
                        small-chips
                        deletable-chips/>
                    </v-col>
                </v-row>
            </div>

            <div id="know-how" class="mt-10">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Type of investor</p>
                <p class="text--darken-3 font-weight-bold text-sm-left">How will you invest in companies?</p>

                <v-row align="center" justify="center" class="" no-gutters>
                    <v-col cols="10" md="10">
                        <v-radio-group v-model="model" class="mb-15">
                            <v-row>
                                <v-radio :value="0" color="#11aec9"/>
                                <p class="thin mt-2">As an individual</p>
                            </v-row>
                            <v-row>
                                <v-radio :value="1" color="#11aec9"/>
                                <p class="thin mt-3">As a firm or a fund</p>
                            </v-row>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="1"></v-col>
                </v-row>

                <v-divider style="border-color: #11aec9 !important;" />
                
                <!-- Save button -->
                <v-row align="center" justify="center" class="pt-2 btn-save">
                    <v-col cols="0" xs="5" sm="8" md="9" lg="11"/>
                    <v-col cols="12" xs="1" sm="1" md="1" lg="1">
                        <v-btn
                        id="btn-photo"
                        class="white--text mb-3"
                        style="font-weight: bold; font-size: 14px"
                        color="#11aec9"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.stop.prevent="saveKnowHow()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg> save
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <br><br><br><br>
        </v-col>
    </v-row>
</template>

<script>
import {
    required,
    minLength,
    maxLength,
    email,
    sameAs,
    url,
    requiredIf,
} from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";
import Var from "@/store/services/var";

import Profile from "@/store/services/profile-service";
import UserEducationService from "@/store/services/user.education.service";
import UserExperienceService from "@/store/services/user.experience.service";
import UserKnowhowService from "@/store/services/user.knowhow.service";
import CompanyService from "@/store/services/company/company.service";
import AccreditationService from "@/store/services/investor/accreditation.service";

const diffArray = (arr1, arr2) =>
    arr1
        .concat(arr2)
        .filter((val) => !(arr1.includes(val) && arr2.includes(val)));

export default {
    components: {
        FormInlineMessage,
    },

    props: {
        avatarPreviewUrl: String,
    },

    data() {
        return {
            yearsExperiences: [
                "0 - 5 years",
                "5 - 10 years",
                "10 - 15 years",
                "15 - 20 years",
                "Above 20 years",
            ],
            proficiencyList: [
                "Elementary proficiency",
                "Limited working proficiency",
                "Professional working proficiency",
                "Full professional proficiency",
                "Native or bilingual proficiency",
            ],
            model: 0,
            investment: {
                type: "",
            },
            knowHow: {
                skills: [],
            },
            queryTerm: "",
            me: {},
            phone: null,
            male: false,
            female: false,
            other: false,
            no: false,
            phoneValid: false,
            country: [],
            entries: [],
            degrees: [],
            personal: { social_network: {}, },
            educations: [{}],
            experiences: [{}],
            skills: [],
            ceo:false,
            cofounder:false,
            otherposition:false,
            otherField:''
        }
    },

    validations: {
        phoneValid: { sameAs: sameAs(() => true), },
        personal: {
            city: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(120),
            },
            date_of_birth: { required, },
            social_network: {
                twitter: { url, },
                linkedin: { url, },
            },
            experience: { required, },
        },
        educations: {
            required,
            $each: {
                school: { required, },
                start_year: { required, },
                end_year: { required, },
                degree: { required, },
                field: {
                    required,
                    minLength: minLength(3),
                },
                description: {
                    required,
                    minLength: minLength(3),
                }
            }
        },
        experiences: {
            required,
            $each: {
                company: { required, },
                title: { required, },
                location: { required, },
                start: { required, },
                end: {
                    required: requiredIf(function (experience) {
                        if (experience.current_work != "current") { return true; }
                        else { return false; }
                    }),
                },
            },
        },
        skills: {
            required,
            minLength: minLength(1),
        },
        model: { required },
    },

    watch: {
        abilitiesChange: function (newVal, oldVal) {
            if (newVal.length < oldVal.length) {
                const toDelete = diffArray(newVal, oldVal);
                this.deleteAbility(toDelete);
            }
        },

        skillsChange: function (newVal, oldVal) {
            if (newVal.length < oldVal.length) {
                const toDelete = diffArray(newVal, oldVal);
                this.deleteSkill(toDelete);
            }
        },
    },

    methods: {
        capitalize(word) {
            if (typeof word !== 'string') return ''
            return word.charAt(0).toUpperCase() + word.slice(1)
        },
        formatDate (date)
        {
            let dat = new Date(date);
            let year = dat.getFullYear();
            let month = dat.getMonth() + 1;
            let day = dat.getDate();
            if(day < 10 && month < 10){
                return `0${day}/0${month}/${year}`;
            }
            else if (month < 10) {
                return `${day}/0${month}/${year}`;
            }
            else{
                return `${day}/${month}/${year}`;
            }
        },

        addEducation() { this.educations.push({}); },
        addExperience() { this.experiences.push({}); },
        deleteAbility(toDelete) { UserKnowhowService.destroyAbility(toDelete) },
        deleteSkill(toDelete) { UserKnowhowService.destroySkill(toDelete) },

        loadEntries() {
            var that = this;

             Var.getAllSkills(this.queryTerm).then((res) => {
                that.entries = res.data;
            });
        },


        validatePhone(params) {
            this.phoneValid = params.valid;
            this.personal.phone = params.number;
        },

        deleteExperience() {
            const experienceId = this.experiences[this.experiences.length - 1]["id"] ?? false;
            if (experienceId) {
                UserExperienceService.destroy(experienceId)
                .then(() => { this.experiences.pop(); })
                .catch((error) => {
                    //console.log(error)
                });
            } else { this.experiences.pop(); }
        },

        deleteEducation(){
            const educationId = this.educations[this.educations.length-1]['id'] ?? false;

            if(educationId){
                UserEducationService.destroy(educationId)
                .then(() => { this.educations.pop(); return; })
                .catch(error => {
                    //console.log(error)
                });
            } else { this.educations.pop(); }
        },

        savePersonal() {
            this.$v.personal.$touch();
            this.$v.phoneValid.$touch();
            if (this.$v.personal.$error || this.$v.phoneValid.$error) { return; }

            if (this.male) { this.personal.gender = "male" }
            else if (this.female) { this.personal.gender = "female" }
            else if (this.other) { this.personal.gender = "Other" }
            else { this.personal.gender= "none" }
            Profile.saveDetails(this.personal).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Personal details saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Something went wrong !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },

        saveEducation() {
            this.$v.educations.$touch();
            if (this.$v.educations.$error) { return; }

            UserEducationService.save({'user_education':this.educations}).then((res) => {
                if(res.status == 200) {
                    this.$toast.open({
                        message: "Education details saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Something went wrong !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },

        saveExperience() {
            this.$v.experiences.$touch();
            if (this.$v.experiences.$error) { return; }

            UserExperienceService.save({ user_experience: this.experiences }).then((res) => {
                if(res.status == 200) {
                    this.$toast.open({
                        message: "Experience details saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Something went wrong !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },
        

        saveKnowHow() {
            this.$v.skills.$touch();
            if (this.$v.skills.$error) { return; }
            if (this.model == 0) {
                this.investment.type = "individual";
            } else if (this.model == 1) {
                this.investment.type = "firm";
            }

            UserKnowhowService.saveSkills(this.skills);
            AccreditationService.saveType(JSON.stringify({ type_of_investment: this.investment.type })).then((res) => {
                if(res.status == 200) {
                    this.$toast.open({
                        message: "Main Contact information saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Something went wrong !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },
    },

    computed: {
        search: {
            get: function () { return this.queryTerm; },

            set: function (searchInput) {
                /* in comment - table skills 02-10-2022 */
                // if (!searchInput) { return; }

                if (this.queryTerm !== searchInput) {
                    this.queryTerm = searchInput;
                    this.loadEntries();
                }
            },
        },

        skillsChange() { return this.skills; },
    },

    created() {
        Promise.all([
            Var.getAllCountry().then((res) => { this.country = res.data; }),
            Var.getAllDegrees().then((res) => { this.degrees = res.data; }),

            Profile.get().then((res) => { this.me = res.data; }),

            Profile.myDetails()
            .then((res) => {
                this.personal = res.data;
                this.phone = this.personal.phone;
                this.personal.social_network = JSON.parse(this.personal.social_network);
                
                if (this.personal.gender == "male") { this.male = true; }
                else if (this.personal.gender == "female") { this.female = true; }
                else if (this.personal.gender == "Other" || this.personal.gender == "other") { this.other = true; }
                else { this.no = true; }
            }),

            UserEducationService.getAll().then(res=>{ this.educations = res.data; }),

            UserExperienceService.getAll().then((res) => { this.experiences = res.data; }),

            UserKnowhowService.getSkills().then((res) => { this.skills = res.data; }),

            AccreditationService.getType()
            .then((res) => {
                if (res.data) {
                    if (res.data.firm_or_individual) {
                        this.investment.type = res.data.firm_or_individual;

                        if (this.investment.type == "individual") {
                            this.model = 0;
                        } else if (this.investment.type == "firm") {
                            this.model = 1;
                        }
                    }
                }
            }),

            // CompanyService.getAll()
            // .then((res) => {
            //     var applicant = res.data.applicant.split(',');
            //     if(applicant.includes("ceo") ) {
            //         this.ceo = true;
            //     } 
            //     if(applicant.includes("cofounder")){
            //         this.cofounder = true;
            //     }

            //     if(!applicant.includes("cofounder")&&!applicant.includes("ceo")) {
            //         this.otherposition = true;
            //         this.otherField = applicant[0];
            //     }
            // })
        ]);
    }
}
</script>
<style scoped>
.profilUserMobile {
    display: none;
}
.profileContent {
    padding-left: 20px;
}
@media (min-width: 1264px) and (max-width: 2890px) { 
    .btn-save {
        margin-right: 1% !important;
    }
    .timePeriod {
        margin-left: 1% !important;
    }
}
@media (min-width: 10px) and (max-width: 576px) { 
    .topMenu {
        justify-content: center !important;
        align-items: center !important;
    }
    .profilUserMobile {
        display: block;
    }
    .profilUserWeb {
        display: none;
    }
    .profilImg {
        margin-bottom: -30px;
    }
    .profileContent {
        padding-left: 0px;
    }
}
@media (min-width: 100px) and (max-width: 400px) { 
    .profileContent .topMenu {
        display: flex;
        flex-direction: column;
    }
}
</style>
