<template>
    <div>
        <Header @changeComponent="changeComponent" v-bind:avatarPreviewUrl="this.avatarPreviewUrl" class="d-none d-md-flex"/>
        <HeaderPortable @changeComponent="changeComponent" @openAlertPopupDownload="openAlertPopupDownload" v-bind:companies="companies" v-bind:id="company_id" class="d-flex d-md-none"/>
        <v-row>
            <v-col cols="0" md="2" class="d-none d-md-flex">
                <Menu @changeComponent="changeComponent" @openAlertPopupDownload="openAlertPopupDownload" v-bind:current="this.current" v-bind:companies="companies" v-bind:comp_id="null" ref="MenuDashboard"/>
            </v-col>

            <v-col cols="12" :md="current == 'invoices' ? 10 : current == 'AccountStatus' ? 10 : 8" :lg="current == 'invoices' ? 9 : current == 'AccountStatus' ? 9 : 8">
                <AlertPopup v-if="this.enablePopup" @closePopup="closeDialog()" :company_id="company_id" :type="type" :application="application" />
                <div
                :is="component"
                v-bind:avatarPreviewUrl="this.avatarPreviewUrl"
                v-bind:company_id="this.company_id"/>
            </v-col>
        </v-row>
        <Footer/>
    </div>
</template>

<script>
import AlertPopup from "@/components/AlertPopup.vue";
import Profile from "@/store/services/profile-service";
import CompanyService from "@/store/services/company/company.service";

import Header from '@/components/HeaderDashboard.vue';
import HeaderPortable from '@/components/HeaderDashboardPortable.vue';
import Footer from '@/components/FooterCms.vue';
import Menu from '@/components/Dashboard/DashboardMenu.vue';

import MainContact from '@/components/Dashboard/MainContact.vue'
import CompanyProfile from '@/components/Dashboard/CompanyProfile.vue'
import FounderTeam from '@/components/Dashboard/FoundingTeam.vue'
import Product from '@/components/Dashboard/TypeOfBusiness.vue'
import FinanceHistory from '@/components/Dashboard/FinanceHistory.vue'
import FundingNeeds from '@/components/Dashboard/FundingNeeds.vue'
import LegalRepresentative from '@/components/Dashboard/LegalRepresentative.vue'
import BillingAddress from '@/components/Dashboard/BillingAddress.vue'
import Invoices from '@/components/Dashboard/Invoices.vue'
import ChangePwd from '@/components/Dashboard/Modifypwd.vue'
import AccountStatus from '@/components/Dashboard/AccountStatus.vue'

export default {
    components: {
        Header,
        HeaderPortable,
        Footer,
        Menu,
        AlertPopup,

        CompanyProfile,
        MainContact,
        FounderTeam,
        Product,
        FinanceHistory,
        FundingNeeds,
        LegalRepresentative,
        BillingAddress,
        Invoices,
        ChangePwd,
    },
    data() {
        return {
            company_id: null,
            companies: [],
            component: AccountStatus,
            avatarPreviewUrl: null,
            current: 'AccountStatus',
            enablePopup: false,
            type: null,
            application: null,
        }
    },

    methods: {
        changeComponent(id, comp) {
            this.current = comp;
            this.component = null;
            if (id != null) { this.company_id = id; }
            if (comp == 'mainContact') { this.component = MainContact; }
            else if (comp == 'companyProfile') { this.component = CompanyProfile; }
            else if (comp == 'team') { this.component = FounderTeam; }
            else if (comp == 'product') { this.component = Product; }
            else if (comp == 'financeHistory') { this.component = FinanceHistory; }
            else if (comp == 'fundingNeeds') { this.component = FundingNeeds; }
            else if (comp == 'legalRepresentative') { this.component = LegalRepresentative; }
            else if (comp == 'billingAddress') { this.component = BillingAddress; }
            else if (comp == 'invoices') { this.component = Invoices; }
            else if (comp == 'changepwd') { this.component = ChangePwd; }
            else if (comp == 'AccountStatus') { this.component = AccountStatus; }
        },
        updateGoogleAnalytics()
        {
            this.$gtag.event('Company_Dashboard', { method: 'Google' });
        },
        openAlertPopupDownload(val, application){
            this.type = val;
            this.application = application
            this.enablePopup = true;
        },
        closeDialog() {
            this.enablePopup = false;
            this.current = 'mainContact';
        },
    },

    created() {
        Promise.all([
            Profile.getAvatar()
            .then((res) => { this.avatarPreviewUrl = res.data; })
            .catch((error) => { 
                //console.log(error); 
            }),

            CompanyService.getAllCompanies().then((res) => {
                this.companies = res.data;
                this.company_id = this.companies[0].company_id;
                this.$nextTick(() => { this.$refs.MenuDashboard.init(this.companies, this.company_id); });
            }),
        ]);
    },
    // mounted() {
    //     this.updateGoogleAnalytics();
    // },
}
</script>
