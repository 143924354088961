<template>
    <div class="mb-5">
        <v-row align="center" justify="center" class="mb-8">
            <v-card-actions class="justify-center">
                <v-hover v-slot:default="{ hover }">
                    <v-img
                    v-if="!photoBlock"
                    contain
                    style="border-radius: 50%; border: 0 solid;"
                    max-height="150"
                    max-width="150"
                    @click.stop="dialogUp('Photo')"
                    :src="imagePreviewUrl">
              
                        <v-expand-transition>
                            <div
                            v-if="hover"
                            style="background-color: rgb(81, 77, 122); height: 100%"
                            class="d-flex transition-fast-in-fast-out v-img--reveal display-1 white--text align-center justify-center">
                                CHANGE YOUR PHOTO
                            </div>
                        </v-expand-transition>
                    </v-img>
                </v-hover>
                <v-btn
                v-if="photoBlock"
                sm="10"
                id="btn-photo"
                class="white--text"
                color="#514d7a"
                height="90"
                min-width="20"
                rounded
                :disabled="false"
                @click.stop="dialogUp('Photo')">
                    ADD YOUR PHOTO*
                </v-btn>
            </v-card-actions>
        </v-row>

        <!-- dialog for Avatar image -->
        <v-dialog v-model="dialogPhoto" max-width="400">
            <PhotoForm @uploadPhoto="uploadPhoto" @closePhoto="dialogDown('Photo')"/>
        </v-dialog>

        <v-row class="mt-n5">
            <v-col cols="12" sm="4">
                <v-row  align="center" justify="center">
                    <v-col cols="12" class="mb-n5">
                        <p class="thin text-sm-left">Country of residence</p>
                    </v-col>
                    <v-col cols="8" sm="12">
                        <v-autocomplete
                        v-model="founder.country"
                        :items="country"
                        outlined
                        dense/>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" sm="4">
                <v-row  align="center" justify="center">
                    <v-col cols="12" class="mb-n5">
                        <p class="thin text-sm-left">Date of Birth</p>
                    </v-col>
                    <v-col cols="8" sm="12">
                        <v-menu
                            ref="dob"
                            :close-on-content-click="false"
                            :return-value.sync="founder.birth"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    height="40"
                                    outlined
                                    v-model="founder.birth"
                                    placeholder="Date of birth"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    dense
                                    @blur="$v.founder.birth.$touch()"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="founder.birth" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="$refs.dob.isActive = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dob.save(founder.birth)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mt-n5">
            <v-col cols="12">
                <v-row  align="center" justify="center">
                    <v-col cols="12">
                        <p class="text-sm-left thin">Equity - % Share</p>
                    </v-col>
                    <v-row align="center" justify="center">
                        <v-col cols="8" sm="4" class="mt-n5">
                            <FormInlineMessage v-if="is_founder_equity_error.error">
                                {{ is_founder_equity_error.message }}
                            </FormInlineMessage>
                            <v-text-field
                            class="ml-5"
                            v-model="founder.equity"
                            outlined
                            dense
                            placeholder="% Shares"
                            @input="setFounderEquity(founder.equity)"
                            @keypress="isNumber($event)"/>
                        </v-col>
                        <v-col cols="0" sm="8"/>
                    </v-row>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mt-n5">
            <v-col cols="12" sm="4">
                <v-row  align="center" justify="center">
                    <v-col cols="12" class="mb-n5">
                        <p class="thin text-sm-left">Field of expertise</p>
                    </v-col>
                    <v-col cols="8" sm="12">
                        <v-combobox
                        v-model="founder.expertise"
                        :items="entries"
                        item-text="name"
                        :search-input.sync="search"
                        outlined
                        dense
                        placeholder="Corporate"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" sm="4">
                <v-row  align="center" justify="center">
                    <v-col cols="12" class="mb-n5">
                        <p class="thin text-sm-left">Number of years in this expertise</p>
                    </v-col>
                    <v-col cols="8" sm="12">
                        <v-autocomplete
                        v-model="founder.years"
                        :items="years"
                        outlined
                        dense
                        placeholder="1-5 years"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mt-n4 mobile" align="center" justify="center" >
             <v-col cols="12" sm="1">
                <p class="thin text-sm-left">Former position / Former company</p>
            </v-col>
        </v-row>

        <v-row class="mt-n4 web">
            <v-col cols="6" sm="4">
                <p class="thin text-sm-left">Former position</p>
            </v-col>

            <v-col cols="6" sm="4">
                <p class="thin text-sm-left">Former company</p>
            </v-col>
        </v-row>

        <div v-for="(former, index) in founder.formers" :key="index">
            <v-col cols="12" sm="8" v-if="index > 0">
                <v-row  align="center" justify="center">
                    <v-col cols="8" sm="12" class="mt-n5">
                        <v-divider class="divider" style="border-color: #11aec9"></v-divider>
                    </v-col>
                </v-row>
            </v-col>

            <v-row class="mt-n5">
                <v-col cols="12" sm="4">
                    <v-row  align="center" justify="center">
                        <v-col cols="8" sm="12">
                            <v-text-field
                            v-model="former.position"
                            outlined
                            dense
                            placeholder="CTO"/>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" sm="4">
                    <v-row  align="center" justify="center">
                        <v-col cols="8" sm="12">
                            <v-text-field
                            v-model="former.company"
                            outlined
                            dense
                            placeholder="Company"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <v-row class="mt-n4">
            <v-col cols="12" sm="2">
                <v-btn
                id="btn-add"
                text
                color="#00aac3"
                @click.prevent.stop="addFormer()">
                    + Add a position
                </v-btn>
            </v-col>
            <v-col cols="0" sm="2"/>
            <v-col cols="12" sm="2" v-if="f > 1 || founder.formers.length > 1">
                <v-btn
                id="btn-add"
                text
                color="#00aac3"
                @click.prevent.stop="deleteFormer()">
                    - Remove a position
                </v-btn>
            </v-col>            
        </v-row>

        <v-row class="mt-n4 mobile" align="center" justify="center" >
             <v-col cols="12" sm="1">
                <p class="thin text-sm-left">Academic Degree / School</p>
            </v-col>
        </v-row>

        <v-row class="mt-n4 web">
            <v-col cols="6" sm="4">
                <p class="thin text-sm-left">Academic Degree</p>
            </v-col>

            <v-col cols="6" sm="4">
                <p class="thin text-sm-left">School</p>
            </v-col>
        </v-row>

        <div v-for="(school, index) in founder.schools" :key="school.name">
           <v-col cols="12" xs="5" sm="8" v-if="index > 0">
                <v-row  align="center" justify="center">
                    <v-col cols="8" xs="5" sm="12" class="mt-n5">
                        <v-divider class="divider" style="border-color: #11aec9"></v-divider>
                    </v-col>
                </v-row>
            </v-col>            

            <v-row class="mt-n5">
                <v-col cols="12" sm="4">
                    <v-row  align="center" justify="center">
                        <v-col cols="8" sm="12">
                            <v-autocomplete
                            v-model="school.degree"
                            :items="degrees"
                            placeholder="MBA"
                            outlined
                            dense/>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" sm="4">
                    <v-row  align="center" justify="center">
                        <v-col cols="8" sm="12">
                            <v-text-field
                            v-model="school.school"
                            outlined
                            dense
                            placeholder="Harvard"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <v-row class="mt-n4">
            <v-col cols="12" sm="2">
                <v-btn
                id="btn-add"
                text
                color="#00aac3"
                @click.prevent.stop="addSchool()">
                    + Add a school
                </v-btn>
            </v-col>
            <v-col cols="0" sm="2"/>
            <v-col cols="12" sm="2" v-if="s > 1 || founder.schools.length > 1">
                <v-btn
                id="btn-add"
                text
                color="#00aac3"
                @click.prevent.stop="deleteSchool()">
                    - Remove a school
                </v-btn>
            </v-col>            
        </v-row>

        <v-row>
            <v-col cols="12">
                <p class="text-sm-left thin">Linkedin Profile</p>
            </v-col>
            <v-row  align="center" justify="center">
                <v-col cols="8" sm="8" class="mt-n5">
                    <v-text-field
                    v-model="founder.linkedin"
                    outlined
                    dense
                    placeholder="https://www.linkedin.com/pub/name"/>
                </v-col>
                <v-col cols="0" sm="4"/>
            </v-row>
        </v-row>

        <v-row align="center" justify="center">
            <v-col cols="12">
                <p class="thin text-sm-left">Briefly describe what are the 3 top achievements of the founder</p>
            </v-col>

            <v-col cols="8">
                <v-textarea
                v-model="founder.describe"
                rows="3"
                outlined
                dense
                @blur="$v.founder.describe.$touch()"/>
            </v-col>

            <v-col cols="0" sm="4"/>
        </v-row>
    </div>
</template>

<script>
import { required, url} from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";
import TeamsService from "@/store/services/teams.service";
import Var from "@/store/services/var";
import PreviewImage from "@/components/partials/PreviewImage.vue";
import PhotoForm from "@/forms/PhotoForm";
import { myMixins } from "@/mixins/mixins";

export default {
    mixins: [myMixins],
    components: {
        PreviewImage,
        PhotoForm,
        FormInlineMessage,
    },

    props: {
        founderProp: Object,
        company_id: Number,
    },

    data() {
        return {
            imagePreviewUrl: null,
            photoBlock: true,
            dialogPhoto: false,
            f: 1,
            s: 1,
            founderData: new FormData(),
            queryTerm: " ",
            entries: [],
            years: [],
            country: [],
            degrees: [],
            founder: {
                formers: [{}],
                schools: [{}],
                linkedin: "",
            },
        }
    },

    validations: {
        founder: {
            name: { required },
            country: { required },
            birth: { required },
            equity: { required },
            expertise: { required },
            years: { required },
            formers: {
                $each: {
                    position: { required },
                    company: { required },
                },
            },
            schools: {
                $each: {
                    degree: { required },
                    school: { required },
                },
            },
            linkedin: { url },
            describe: { required },
        },
    },

    methods: {
        addFormer() {
            this.founder.formers.push({});
            this.f += 1;
        },

        addSchool() {
            this.founder.schools.push({});
            this.s += 1;
        },

        deleteFormer() {
            this.founder.formers.pop();
            this.f -= 1;
        },

        deleteSchool() {
            this.founder.schools.pop();
            this.s -= 1;
        },

        dialogUp(element) {
            this.$data["dialog" + element] = true;
        },

        dialogDown(element) {
            this.$data["dialog" + element] = false;
        },

        uploadPhoto(chosenImage) {
            this.founderData.append("photo", chosenImage);
            this.imagePreviewUrl = URL.createObjectURL(chosenImage);
            this.photoBlock = false;
            this.dialogDown("Photo");
        },

        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            let charCode = evt.which ? evt.which : evt.keyCode;
            if ((charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        loadEntries() {
            Var.getAllSkills(this.queryTerm).then((res) => {
                this.entries = res.data;
            });
        },

        save(company_id) {
            this.$v.$touch();
            if (this.$v.$error || this.is_founder_equity_error.error) { 
                return this.$toast.open({
                    message: "Please fill all required fields or correct numbers or amounts having more than 15 digits before saving.",
                    type: "error",
                    position: "top-right",
                }); 
            };

            this.founderData.append("company_id", company_id);
            this.founderData.append("name", this.founder.name);
            this.founderData.append("country", this.founder.country);
            this.founderData.append("birth", this.founder.birth);
            this.founderData.append("equity", this.founder.equity);
            this.founderData.append("expertise", this.founder.expertise);
            this.founderData.append("years", this.founder.years);
            this.founderData.append("formers", JSON.stringify(this.founder.formers));
            this.founderData.append("schools", JSON.stringify(this.founder.schools));
            this.founderData.append("linkedin", this.founder.linkedin);
            this.founderData.append("describe", this.founder.describe);
            this.founderData.append("photo", this.imagePreviewUrl);
            TeamsService.saveFounders(this.founderData).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Founder saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Founder not saved !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
            return true;
        }
    },

    computed: {
        search: {
            get: function () {
                return this.queryTerm;
            },

            set: function (searchInput) {
                if (!searchInput) {
                    if (!this.queryTerm) {
                        if (this.founder.expertise) {
                            this.queryTerm = this.founder.expertise;
                            this.loadEntries();
                        } else {
                            this.queryTerm = "business development";
                            this.loadEntries();
                        }
                    }
                } else if (this.queryTerm !== searchInput) {
                    this.queryTerm = searchInput;
                    this.loadEntries();
                }
            },
        },
    },

    created() {
        Promise.all([
            Var.getAllSkills(this.queryTerm).then((res) => { this.entries = res.data; }),
            Var.getAllDegrees().then((res) => { this.degrees = res.data; }),
            Var.getAllYearsOfExperience().then((res) => { this.years = res.data; }),
            Var.getAllCountry().then((res) => { this.country = res.data; }),
        ])

        this.founder.name = this.founderProp.name;
        this.founder.country = this.founderProp.country;
        this.founder.birth = this.founderProp.birth;
        this.founder.equity = this.founderProp.equity;
        this.founder.expertise = this.founderProp.expertise;
        this.founder.years = this.founderProp.years;
        this.founder.formers = this.founderProp.formers;
        this.founder.schools = this.founderProp.schools;
        this.founder.linkedin = this.founderProp.linkedin;
        this.founder.describe = this.founderProp.describe;

        if(this.founderProp.photoLink)
        {
            this.imagePreviewUrl = this.founderProp.photoLink;
            this.photoBlock = false;
        }
        
        if (this.founder.linkedin == null) {
            this.founder.linkedin = "";
        }
    }
}

</script>
<style scoped>
.mobile {
    display: none;
}
@media only screen and (max-width: 600px) {
    .mobile {
        display: block;
    }
    .web {
        display: none;
    }
}
</style>