<template>
    <div>
 
        <v-sheet
        height="100%"
        class="mx-auto mt-8">
            <v-row align="center" justify="center" no-gutters class="pt-8">
                <v-col cols="1"/>
                <v-col cols="11">
                        <p class="text-sm-left bold">Describe of your company<br>
                        <span class=" blue-grey--text font-weight-thin text-sm-left">describe the main object and the main business of your company
                        <br>(from 35 to 1500 characters)</span></p>
                </v-col>
                <v-col cols="1"/>
                <v-col cols="9">
                    <FormInlineMessage v-if="$v.resumes.resume.$error">
                        Your description must be between 35 and 1500 characters
                    </FormInlineMessage>
                    <v-textarea
                    v-model="resumes.resume"
                    outlined
                    dense
                    rows="10"
                    :counter="1500"/>
                </v-col>
                <v-col cols="1"/>
            </v-row>
        </v-sheet>

        <v-sheet
        height="100%"
        class="mx-auto mt-8">
            <v-row align="center" justify="center" no-gutters class="pt-8">
                <v-col cols="1"/>
                <v-col cols="3">
                    <p class="text-sm-left bold">Link to pitch deck<br>
                    <span class=" blue-grey--text font-weight-thin text-sm-left">Please include a deck that allows access without login</span>
                    </p>
                    <v-col cols="1"/>
                </v-col>
                <v-col cols="7">
                    <FormInlineMessage v-if="$v.resumes.pitch_deck.$error">
                        please write a valid url
                    </FormInlineMessage>
                    <v-text-field
                    v-model="resumes.pitch_deck"
                    outlined
                    dense
                    placeholder="Enter url of your pitch deck"/>
                </v-col>
                <v-col cols="1"/>
            </v-row>
        </v-sheet>

        <v-sheet
        height="100%"
        class="mx-auto"
        color="#f6f6f6"
        v-if="currentBlock">
            <v-row align="center" justify="center">
                <v-col/>
                <v-col cols="12">
                    <v-icon
                    large
                    color="#00aac3"
                    right
                    center      
                    @click.prevent.stop="saveResume ">
                        mdi-arrow-down-circle
                    </v-icon>
                </v-col>
            </v-row>
        </v-sheet>

    </div>
</template>

<script>
    
    import { validationMixin } from "vuelidate";
	import { required } from "vuelidate/lib/validators";
	import {
	  minLength,
	  maxLength,
	  numeric,
	  minValue,
	  email,
	  sameAs,
	  url
	} from "vuelidate/lib/validators";

	import FormInlineMessage from "@/components/FormInlineMessage";
    import CompanyService from "@/store/services/company/company.service"

    export default {
        components: {
            FormInlineMessage
        },

        props: {
            company_id: Number
        },

        data() {
            return{
                currentBlock:true,
                resumes: {
                    resume: null,
                    pitch_deck: null,
                }
            }
        },

        validations: {
            resumes: {
                resume: {
                    required,
                    minLength: minLength(35),
                    maxLength: maxLength(1500)
                },

                pitch_deck: { url }
            }
        },

        methods: {
            saveResume() {
                this.$v.$touch();
                if (this.$v.$error) {
                    return;
                }

                CompanyService.saveResume({'company_id':this.company_id, 'resumes':this.resumes});

                this.currentBlock=false;
                this.$emit("block4");
            },
        },

        created() {
            CompanyService.getResume(this.company_id).then(res=>{
                if (res.data) {
                    this.resumes = res.data;
                }
            });
        }
    }
</script>