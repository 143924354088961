<template>
  <v-card>
    <!-- Toolbar -->
    <header-main />
    <!-- End of Toolbar -->

    <!-- Stepper -->
    <v-stepper v-model="e1" class="mt-2" style="background: #f6f6f6">
      <div class="layout column align-center pt-3" style="background: #fff">
        <p class="cyan--text text--darken-1 font-weight-bold" id="title">
          Apply for Company Access
        </p>
      </div>

      <!-- Stepper Header -->
      <v-stepper-header style="background: #fff">
        <v-divider />

        <v-stepper-step
          @click="redirectToStep(1)"
          :complete="val > 1"
          step="1"
          color="#00aac3"
        />

        <v-divider />

        <v-stepper-step
          @click="redirectToStep(2)"
          :complete="val > 2"
          step="2"
          color="#00aac3"
        />

        <v-divider />

        <v-stepper-step
          @click="redirectToStep(3)"
          :complete="val > 3"
          step="3"
          color="#00aac3"
        />

        <v-divider />

        <v-stepper-step
          @click="redirectToStep(4)"
          :complete="val > 4"
          step="4"
          color="#00aac3"
        />

        <v-divider />

        <v-stepper-step
          @click="redirectToStep(5)"
          :complete="val > 5"
          step="5"
          color="#00aac3"
        />

        <v-divider />

        <v-stepper-step
          @click="redirectToStep(6)"
          :complete="val > 6"
          step="6"
          color="#00aac3"
        />

        <v-divider />

        <v-stepper-step
          @click="redirectToStep(7)"
          :complete="val > 7"
          step="7"
          color="#00aac3"
        />

        <v-divider />
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Step 1 -->
        <v-stepper-content step="1">
          <Step1 @done="storeApplicationStep(2)" v-bind:me="this.me" />
        </v-stepper-content>

        <!-- Step 2 -->
        <v-stepper-content step="2">
          <Step2
            @passCID="defineLocalCID"
            @done="storeApplicationStep(3)"
            v-bind:me="this.me"
            v-if="this.e1 == 2"
          />
        </v-stepper-content>

        <!-- Step 3 -->
        <v-stepper-content step="3">
          <Step3
            @done="storeApplicationStep(4)"
            v-bind:me="this.me"
            v-bind:company_id="this.company_id"
            v-if="this.e1 == 3"
          />
        </v-stepper-content>

        <!-- Step 4 -->
        <v-stepper-content step="4">
          <Step4
            @done="storeApplicationStep(5)"
            v-bind:me="this.me"
            v-bind:company_id="this.company_id"
            v-if="this.e1 == 4"
          />
        </v-stepper-content>

        <!-- Step 5 -->
        <v-stepper-content step="5">
          <Step5
            @done="storeApplicationStep(6)"
            v-bind:me="me"
            v-bind:company_id="company_id"
            v-if="e1 == 5 && company_id && me"
          />
        </v-stepper-content>

        <!-- Step 6 -->

        <v-stepper-content step="6">
          <Step6
            @done="storeApplicationStep(7)"
            v-bind:me="me"
            v-bind:company_id="company_id"
            v-if="e1 == 6 && me"
          />
        </v-stepper-content>

        <v-stepper-content step="7">
          <Step7
            :me="me"
            :company_id="company_id"
            v-if="e1 == 7 && company_id && me"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import HeaderMain from "@/components/HeaderMain.vue";
import Profile from "@/store/services/profile-service";
import Status from "@/store/services/status.service";

import Step1 from "@/components/Steps/Step1/CompanyContact.vue";
import Step2 from "@/components/Steps/Step2/Company.vue";
import Step3 from "@/components/Steps/Step3/Team.vue";
import Step4 from "@/components/Steps/Step4/Business.vue";
import Step5 from "@/components/Steps/Step5/Finance.vue";
import Step6 from "@/components/Steps/Step6/Need.vue";
import Step7 from "@/components/Steps/Step7/Payment.vue";

export default {
  components: {
    HeaderMain,
    // FooterLarge,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
  },

  data() {
    return {
      e1: 1,
      val: 1,
      me: {},
      company_id: null,
    };
  },
  methods: {
    redirectToStep(value) {
      if (value <= this.val) {
        this.e1 = value;
      }
    },
    defineLocalCID(company_id, company_name) {
      this.company_id = company_id;
      this.me.company_name = company_name;
      Status.store(
        JSON.stringify({
          company_id: this.company_id,
          company_name: this.me.company_name,
        })
      );
    },
    getProfile() {
      Profile.get().then((res) => {
        if (res.data) {
          this.me.first_name = res.data.first_name;
          this.me.last_name = res.data.last_name;
          this.me.email = res.data.email;
        }
      });
    },
    getStatus() {
      Status.readAll()
        .then((res) => {
          if (res.data) {
            this.company_id = res.data.active_company_id;
            this.me.company_name = res.data.active_company_name;
            localStorage.setItem('company_id', res.data.active_company_id)
            this.redirectToLastEl(res.data.active_application_step);
          }
        })
        .catch((e) => {
          this.storeApplicationStep(1);
        });
    },
    storeApplicationStep(variable) {
      this.e1 = variable;
      if (this.e1 > this.val) {
        this.val = this.e1;
      }
      if(this.company_id)
      {
        Status.store(JSON.stringify({company_id : this.company_id, application_step: this.e1 }));
      }
      else{
        Status.store(JSON.stringify({application_step: this.e1 }));
      }
    },

    redirectToLastEl(variable) {
      if (this.$route.query.el) {
        this.e1 = this.$route.query.el;
        this.val = this.e1;
      } else if (variable) {
        this.e1 = variable;
        this.val = this.e1;
      }
    },
  },
  created() {
    this.getProfile();
    this.getStatus();
  },
  mounted() {
    this.redirectToLastEl();
  },
};
</script>

<style>
  #title {
    font-size: 25px;
    letter-spacing: -1px;
  }
</style>
