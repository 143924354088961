<template>
    <div class="mar-top-100">
        <v-row class="accountStatus">
          <!-- Header -->
            <v-col cols="12" md="12" class="mb-7 header">
               <h1 class="text-light mt-5">IO NEWSLETTER SUBSCRIPTION</h1>
               <hr>
            </v-col>
            <v-col cols="11" sm="8" class="mt-5" v-if="subscriber">
                <p v-if="getRemainingDays(subscriber.date_end) != 0">
                    Your subscription to recommend your company in the IO Newsletter will finish on <strong>{{ subscriber ? subscriber.date_end : 'YYYY/MM/DD' }}</strong>.
                </p>
                <p v-if="getRemainingDays(subscriber.date_end) < 8 && getRemainingDays(subscriber.date_end) > 0">
                    Your subscription ends in <strong>{{ getRemainingDays(subscriber.date_end) }}</strong> day<span v-if="getRemainingDays(subscriber.date_end) > 1">s</span>.
                </p>
                <p v-if="getRemainingDays(subscriber.date_end) == 0">
                    <span v-if="new Date(subscriber.date_end).toDateString() == new Date().toDateString()">Your subscription to recommend your company in the IO Newsletter end today</span>
                    <span v-else>Your subscription to recommend your company in the IO Newsletter ended on <strong>{{ subscriber.date_end }}</strong></span>
                </p>
            </v-col>
            <v-col cols="11" sm="8" class="text-left">
                <p class="puce-content">
                    <img src="../../assets/img/puce.png" alt="Puce"> Your company is now among the qualified investment deals recommended by IdeasFundX to VCs
                </p>
                <p class="puce-content">
                    <img src="../../assets/img/puce.png" alt="Puce"> You have access to an active pool of accredited VCs who are working with us
                </p>
                <p class="puce-content">
                    <img src="../../assets/img/puce.png" alt="Puce"> You maximize your chances being discovered by VCs and having your first meetings to enter the negotiation
                </p>
                <p class="puce-content">
                    <img src="../../assets/img/puce.png" alt="Puce"> Preserve the confidentiality of your data. 
                    Only investors who are interested in reviewing the presentation of the company 
                    get access to confidential information provided by your company after signing an NDA.
                </p>
                <p class="puce-content">
                    <img src="../../assets/img/puce.png" alt="Puce"> You are informed as soon as an investor wants to learn more about your company
                </p>
            </v-col>
            <v-col cols="11" sm="8">
                <p>
                    IdeasFundX takes a success fee of 4% of the investment in your company made by investors brought by IdeasFundX
                </p>
            </v-col>

            <h2 v-if="subscriber" class="comingSoon">{{ getRemainingDays(subscriber.date_end) >= 7 ? "COMING SOON" : "RENEW YOUR MEMBERSHIP" }}</h2>
            <v-col cols="11" sm="8" class="mt-10" v-if="subscriber">
                <p v-if="getRemainingDays(subscriber.date_end) >= 7">
                    Because we want the best for you, we are working hard to build the new release of the investor dashboard where you will be able 
                    to update your data and administrative information, receive deal recommendations and follow up the progress of your deal flow.
                </p>
                <p v-else>

                    <span>Keep being among the recommanded deals of our Newsletter for VCs</span> <br>
                    <span>Pay a Yearly Membership of {{ inEEC(countryBase) ?  Math.floor(productPrice * change * 100) / 100 + ' €' : `$ ${productPrice}`}} excl. tax</span>
                </p>
            </v-col>
            <v-col cols="10" sm="4" md="3" lg="2" v-if="subscriber && getRemainingDays(subscriber.date_end) >= 7">
                <v-btn
                    cols="1" sm="1"
                    id="btn-photo"
                    class="white--text sm-5"
                    color="#58407c"
                    height="90"
                    rounded
                    block
                    @click="$router.push('/contact')"
                    :disabled="false">
                    CONTACT US
                </v-btn>
            </v-col>
            <v-col cols="10" sm="4" md="3" lg="3" v-if="!addresBloc  && subscriber && getRemainingDays(subscriber.date_end) <= 7">
                <v-btn
                    sm="12"
                    id="btn-photo"
                    class="white--text"
                    color="#58407c"
                    height="70"
                    width="15"
                    rounded
                    block
                    @click="addresBloc = true"
                    :disabled="false">
                    RENEW
                </v-btn>
            </v-col>

            <AddressBlock v-if="addresBloc" @block3="priceBlock = true" :company_id="companyId" class="w-100"/>
            <PriceBlock 
                v-if="priceBlock"
                :product_price="productPrice"
                :address="billingAddress"
                class="w-100"
                :company_id="companyId"
                :is_company="true"
                :defaultCurrency="inEEC(countryBase) ?  'EUR' : 'USD'"
            />
        </v-row>

        <v-dialog v-model="dialogPaymentSuccess" max-width="500px">
            <v-card
                min-height="200px"
                style="overflow-x: hidden; overflow-y: hidden"
            >
                <v-card-title class="justify-center">
                    <span id="title">PAYMENT CONFIRMED</span>
                </v-card-title>
                <v-sheet height="100%" class="mx-3 my-0" color="#c5e7ec" rounded>
                    <v-card-text>
                        <v-container>
                            <v-row class="text-left sub-title-code mt-n5">
                                <p>
                                    Check your mailbox, you have received
                                    an email with your invoice, and 
                                    IdeasFundX team will start the review of 
                                    your application.</p>
                            </v-row>
                            <v-row  class="text-left sub-title-code mt-n2 mb-n9">
                            <p><br>For any question, please contact us. <a v-bind:href="CMScontact">contact us</a></p>
                            </v-row>

                        </v-container>
                    </v-card-text>
                </v-sheet>
                <div class="d-flex justify-content-between align-items-center p-3">
                    <div class="btn-ruturn-home">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" size="20" fill="currentColor" class="bi bi-chevron-left mr-2 mt-3" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        <span @click="reDirect()" style="font-size: 22px">Home</span>
                    </div>
                    <v-btn
                        id="btn-verify"
                        center
                        class="white--text my-3"
                        color="#11aec9"
                        height="50"
                        min-width="120"
                        rounded
                        @click.stop.prevent="dialogPaymentSuccess = false"
                        :disabled="false"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg> OK
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPaymentFail" max-width="500px">
            <v-card
                min-height="200px"
                style="overflow-x: hidden; overflow-y: hidden"
            >
                <v-card-title class="justify-center">
                    <span id="title">INTERRUPTION <br> OF YOUR PAYMENT</span>
                </v-card-title>
                <v-sheet height="100%" class="mx-3 my-0" color="#c5e7ec" rounded>
                    <v-card-text>
                        <v-container>
                            <v-row class="text-left sub-title-code mt-n5">
                                <p>
                                    Paypal informed us that the transaction of 
                                    your payment was interrupted.</p>
                            </v-row>
                            <v-row  class="text-left sub-title-code mt-n2 mb-n9">
                            <p><br>For any question, please contact us. <a v-bind:href="CMScontact">contact us</a></p>
                            </v-row>

                        </v-container>
                    </v-card-text>
                </v-sheet>
                <div class="d-flex justify-content-between align-items-center p-3">
                    <div class="btn-ruturn-home">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" size="20" fill="currentColor" class="bi bi-chevron-left mr-2 mt-3" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        <span @click="dialogPaymentFail = false" style="font-size: 22px">BACK</span>
                    </div>
                    <v-btn
                        id="btn-verify"
                        center
                        class="white--text my-3"
                        color="#11aec9"
                        height="50"
                        min-width="120"
                        rounded
                        :disabled="false"
                        @click="addresBloc = true, dialogPaymentFail = false"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg> TRY AGAIN
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Company from "@/store/services/admin/company";
    import Var from '@/store/services/var.js';
    import Paypal from "@/store/services/paypal.service";
    import AddressBlock from '../../components/Steps/Step7/Blocks/AddressBlock.vue'
    import PriceBlock from '../../components/Dashboard/payments/PriceBlock.vue';


    export default { 
        components : { 
            AddressBlock,
            PriceBlock
        },
        data() {
            return {
                subscriber: null,
                countryBase: null,
                change: 0,
                productPrice: 0,
                addresBloc: false,
                priceBlock: false,
                companyId: null,
                dialogPaymentSuccess: false,
                dialogPaymentFail: false,
                CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
                billingAddress: null,
                user: {
                    id: null
                },
                EEC: [
                    "Austria",
                    "Belgium",
                    "Bulgaria",
                    "Croatia",
                    "Cyprus",
                    "Czech Republic",
                    "Denmark",
                    "Estonia",
                    "Finland",
                    "France",
                    "France, Metropolitan",
                    "Germany",
                    "Greece",
                    "Hungary",
                    "Ireland",
                    "Italy",
                    "Latvia",
                    "Lithuania",
                    "Luxembourg",
                    "Malta",
                    "Netherlands",
                    "Poland",
                    "Portugal",
                    "Romania",
                    "Slovakia",
                    "Slovenia",
                    "Spain",
                    "Sweden",
                ],
            }
        },
        mounted() {
            this.user.id = + localStorage.user_id;
            Company.getsubscribeNewsletter().then((res)=>{ this.subscriber = res.data });
            Company.getCompany(localStorage.user_id).then((res)=>{ 
                this.companyId = res.data.id;
                this.billingAddress = JSON.parse(res.data.billing_address);
                if (!res.data.billing_address) {
                   this.countryBase = res.data.country;
                } else {
                    this.countryBase = this.billingAddress.country;
                }
            });
            Var.getRate().then((res) => { this.change = res.data.final_rate; })
            Var.getProduct(3).then((res) => { this.productPrice = res.data.product_price; }); // 3 => 'Campany Newsletter' id in the table var_products
            if (
                (this.$route.query.token || this.$route.query.ba_token) &&
                    this.$route.query.tpay && !this.$route.query.status
                ) {
                    this.dialogPaymentSuccess = true;
                    this.completePayment();
                    this.subscribeNewsletter();
                    this.$router.push({ name: "CompanyDashboard" });
            } 
            if (
                (this.$route.query.token || this.$route.query.ba_token) &&
                    this.$route.query && this.$route.query.status == "fail"
                ) {
                this.dialogPaymentFail = true;
            } 
        },
        methods: {
            inEEC (country) {
                return this.EEC.includes(country);
            },
            getRemainingDays (date) {
                let lastDate = new Date(date);   
                let toDay = new Date();  

                if (lastDate > toDay) {
                    let difference = lastDate.getTime() - toDay.getTime();
                    return Math.ceil(difference / (1000 * 3600 * 24));
                } else {
                    return 0
                }
            },

            completePayment() {
                Paypal.paymentSuccess(
                    JSON.stringify({
                        company_id: this.$route.query.cid, //this.company_id,
                        paymentId: this.$route.query.paymentId,
                        token: this.$route.query.token,
                        PayerID: this.$route.query.PayerID,
                        v: this.$route.query.v,
                        role: this.$route.query.role,
                        tpay: this.$route.query.tpay,
                        fee: this.$route.query.fee,
                        discount_amount: localStorage.discount_amount ? localStorage.discount_amount : 0,
                        promo_code: localStorage.promo_code ? localStorage.promo_code : null,
                        initial_amount: localStorage.initial_amount ? localStorage.initial_amount : 0,
                        vatBase: localStorage.vatBase ? localStorage.vatBase : 0,
                        coupon_type : localStorage.coupon_type ? localStorage.coupon_type : null,
                        coupon_discount : localStorage.coupon_discount ? localStorage.coupon_discount : null,
                        description:
                        "IO Newsletter Subscription for companies",
                    })
                    )
                    .then((res) => {
                        if (res.status == 200) {
                            localStorage.removeItem("discount_amount");
                            localStorage.removeItem("initial_amount");
                            localStorage.removeItem("vatBase");
                            localStorage.removeItem("coupon_type");
                            localStorage.removeItem("coupon_discount");
                        }
                    })
                    .catch(); //throw Unknown error happened
            },

            subscribeNewsletter() {
                this.loaderAlert = true;
                Company.subscribeNewsletter(this.user, true).then(res=>{
                    if(res.status == 200){
                        location.reload();
                        this.$forceUpdate();
                    }
                    this.loaderAlert = false;
                })
            },
        },
    }
</script>