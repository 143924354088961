import axios from "axios";
// import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/vnd.api+json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token");
axios.defaults.headers.common["Accept"] = "application/vnd.api+json";

function get() {
  return axios.get(`${url}/me`);
}

function profileStatus () {
  return axios.get(`${url}/me/profile/status`)
}

function generateCode(path) {
  return axios.get(url + "/" + path);
}

function send(payload) {
  return axios.post(url + "/" + payload.path, payload.payload);
}

function callEmail(path) {
  let prefix = "email";
  return axios.get(url + "/" + prefix + "/" + path);
}

function myDetails() {
  let path = "user/personal";
  return axios.get(url + "/" + path);
}

function saveAvatar(payload) {
  let path = "user/avatar";
  return axios.post(url + "/" + path, payload);
}

function getAvatar() {
  return axios.get(url + "/user/avatar");
}

function saveDetails(payload) {
  let path = "user/personal";
  return axios.post(url + "/" + path, payload);
}

function passwordForgot(payload) {
  return axios.post(url + "/password-forgot", payload);
}

function tokenFind(token) {
  return axios.get(url + `/password-reset/${token}`);
}

function passwordReset(payload) {
  return axios.post(url + "/password-reset", payload);
}

function passwordChange(payload) {
  return axios.post(url + "/dashboard-password-reset", payload)
}

export default {
  get,
  profileStatus,
  generateCode,
  send,
  callEmail,
  myDetails,
  saveDetails,
  saveAvatar,
  getAvatar,
  passwordForgot,
  passwordReset,
  tokenFind,
  passwordChange
};
