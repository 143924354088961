<template>
  <div>
    <header-main />
    <v-stepper v-model="e1" class="mt-2" style="background: #f6f6f6">
      <div class="layout column align-center pt-3" style="background: #fff">
        <p class="cyan--text text--darken-1 font-weight-bold" id="title">
          Apply for Company Access
        </p>
      </div>

      <!-- Stepper Header -->
      <v-stepper-header style="background: #fff">
        <v-divider />
        <v-stepper-step :complete="true" step="1" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="true" step="2" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="true" step="3" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="true" step="4" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="true" step="5" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="true" step="6" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="true" step="7" color="#00aac3" />
        <v-divider />
      </v-stepper-header>
    </v-stepper>

    <v-row align="center" justify="center">
      <v-col cols="10">
        <v-card-text>
          <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec">
            <v-row justify="center" class="mx-10">
              <v-col cols="2">
                <v-img
                  style="border-radius: 20%"
                  min-height="95%"
                  :src="logoPreviewUrl"
                />
              </v-col>
              <v-col cols="10">
                <p class="text-sm-left thin">{{ this.me.company_name }}</p>
                <p class="text-sm-left bold">Main Contact</p>
                <p class="text-sm-left thin">{{ this.me.first_name }}</p>
                <p class="text-sm-left thin">{{ this.me.last_name }}</p>
                <p class="text-sm-left thin">{{ this.me.email }}</p>
              </v-col>
            </v-row>
          </v-sheet>
          <v-col />
          <v-col>
            <p class="cyan--text text--darken-1" id="title"></p>
          </v-col>
        </v-card-text>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" class="mx-1">
      <v-col cols="3"> </v-col>
      <v-col cols="6">
        <p class="cyan--text text--darken-1 font-weight-bold" id="title">
          PAYMENT IN PROCESS
        </p>
        <p class="text-sm-left thin mt-10">
          Once your payment is confirmed, you will receive an email confirmation
          with your invoice and the IdeasFundX team will start the review of
          your application.
        </p>
        <p class="text-sm-left thin">
          For any questions, please <a href="/contact">contact us</a>.
        </p>
      </v-col>
      <v-col cols="3"> </v-col>
    </v-row>
    <TempPopup v-if="this.enablePopup" v-bind:is_company="true" />
    <v-row v-if="emailBlock" align="center" justify="center" class="mx-1">
      <v-col cols="3"> </v-col>
      <v-col cols="6">
        <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec" rounded>
          <v-card-text>
            <p class="text-sm-left thin mx-auto mt-0">
              Check your mailbox, an email has been sent to your address
              {{ this.me.email }}. If you do not receive an email within the
              next 15 minutes, click the button below to resend an invoice at
              your current address.
            </p>
            <v-row>
              <v-col cols="4"> </v-col>
              <v-col cols="4">
                <v-btn
                  sm="2"
                  class="white--text mt-5"
                  color="#514d7a"
                  max-height="100"
                  width="40"
                  rounded
                  block
                  @click.stop="resendEmail()"
                >
                  Resend Email
                </v-btn>
                <v-col cols="4"> </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-sheet>
      </v-col>
      <v-col cols="3"> </v-col>
    </v-row>
    <v-col />
    <v-col>
      <p class="cyan--text text--darken-1" id="title"></p>
    </v-col>
  </div>
</template>

<script>
import HeaderMain from "@/components/HeaderMain.vue";
import Paypal from "@/store/services/paypal.service";
import CompanyService from "@/store/services/company/company.service.js";
import Profile from "@/store/services/profile-service";
import Status from "@/store/services/status.service";
import TempPopup from "@/components/Popup.vue";

export default {
  components: {
    HeaderMain,
    TempPopup,
  },
  data() {
    return {
      enablePopup: false,
      emailBlock: false,
      me: {},
      logoPreviewUrl: null,
      company_id: null,
      e1: null
    };
  },
  methods: {
    resendEmail() {
      //
    },

    completePayment() {
      //if (this.company_id != null) {
      if (this.$route.query.tpay == "monthly") {
        Paypal.subscriptionSuccess(
          JSON.stringify({
            company_id: this.$route.query.cid, //this.company_id,
            ba_token: this.$route.query.ba_token,
            token: this.$route.query.token,
            v: this.$route.query.v,
            role: this.$route.query.role,
            tpay: this.$route.query.tpay,
            fee: this.$route.query.fee,
          })
        )
          .then((res) => {
            if (res.status == 200) {
              this.emailBlock = true;
              this.enablePopup = true;
            }
          })
          .catch(); //throw Unknown error happened
      } else {
        Paypal.paymentSuccess(
          JSON.stringify({
            company_id: this.$route.query.cid, //this.company_id,
            paymentId: this.$route.query.paymentId,
            token: this.$route.query.token,
            PayerID: this.$route.query.PayerID,
            v: this.$route.query.v,
            role: this.$route.query.role,
            tpay: this.$route.query.tpay,
            fee: this.$route.query.fee,
            promo_code: localStorage.promo_code ? localStorage.promo_code : null,
            initial_amount: localStorage.initial_amount ? localStorage.initial_amount : 0,
            vatBase: localStorage.vatBase ? localStorage.vatBase : 0,
            coupon_type : localStorage.coupon_type ? localStorage.coupon_type : null,
            coupon_discount : localStorage.coupon_discount ? localStorage.coupon_discount : null,
            description:
              "Application to submit your company profile to investors",
          })
        )
          .then((res) => {
            if (res.status == 200) {
              this.emailBlock = true;

              if (this.$route.query.role == "company") {
                Status.store(
                  JSON.stringify({
                    company_id: this.$route.query.cid,
                    application_step: 8,
                  })
                ).then((res) => {
                  this.$router.push({ name: "CompanyDashboard" });
                  localStorage.removeItem("promo_code");
                });
              } else if (this.$route.query.role == "investor") {
                Status.store(JSON.stringify({ application_step: 5 })).then(
                  (res) => {
                    this.$router.push({ name: "DashboardInvestor" });
                    localStorage.removeItem("promo_code");
                    localStorage.removeItem("coupon_type");
                    localStorage.removeItem("coupon_discount");
                  }
                );
              }
            }
          })
          .catch(); //throw Unknown error happened
      }
    },

    retrieveLogo() {
      if (this.company_id != null) {
        CompanyService.getLogo(this.company_id)
          .then((res) => {
            //console.log(res.data);
            this.logoPreviewUrl = res.data;
            //this.formData.append("avatar", photoFromApi);
          })
          .catch((error) => {});
      }
    },
  },
  mounted() {
    Status.readAll()
      .then((res) => {
        if (res.data) {
          this.company_id = res.data.active_company_id;
          this.me.company_name = res.data.active_company_name;
        }

        this.completePayment();
        this.retrieveLogo();
      })
      .catch((e) => {
        //console.log(e);
      });

    Profile.get().then((res) => {
      this.me = res.data;
    });
  },
};
</script>
