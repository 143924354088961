import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import Vuelidate from "vuelidate";
// import google from "@/google_location.js";
// import GoogleAutocomplete from 'google-autocomplete-vue';
import * as VueGoogleMaps from "vue2-google-maps";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueGtag from "vue-gtag";
import Meta from 'vue-meta';
import VueSimpleAlert from "vue-simple-alert";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import excel from 'vue-excel-export'
import { BootstrapVue, IconsPlugin, VBModal } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.directive('b-modal', VBModal)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueGtag, {
  config: { id: "G-K60R5NYM0R" }
}, router);

Vue.use(VueTelInput);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA8fD7I-olQu-p_tFd2mwQSlTgOrwwIJ60",
    libraries: "places",
  },
  installComponents: true,
});

Vue.use(Vuelidate);

Vue.use(Meta);

Vue.use(VueSimpleAlert);

Vue.use(VueToast);

Vue.use(excel)

// globalData and mixin is not needed anymore
// let globalData = new Vue({
//   data: { $global_company_id: null }
// });
// Vue.mixin({
//   computed: {
//     $global_company_id: {
//       get: function () { return globalData.$data.$global_company_id },
//       set: function (newValue) { globalData.$data.$global_company_id = newValue; }
//     }
//   }
// })

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
