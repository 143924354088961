<template>
  <div>
    <header-main />
    <v-stepper v-model="e1" class="mt-2" style="background: #f6f6f6">
      <div class="layout column align-center pt-3" style="background: #fff">
        <p class="cyan--text text--darken-1 font-weight-bold" id="title">
          Apply for Company Access
        </p>
      </div>

      <!-- Stepper Header -->
      <v-stepper-header style="background: #fff">
        <v-divider />
        <v-stepper-step :complete="true" step="1" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="true" step="2" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="true" step="3" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="true" step="4" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="true" step="5" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="true" step="6" color="#00aac3" />
        <v-divider />
        <v-stepper-step :complete="false" step="7" color="#00aac3" />
        <v-divider />
      </v-stepper-header>
    </v-stepper>

    <v-row align="center" justify="center">
      <v-col cols="10">
        <v-card-text>
          <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec">
            <v-row justify="center" class="mx-10">
              <v-col cols="2">
                <v-img
                  style="border-radius: 20%"
                  min-height="95%"
                  :src="logoPreviewUrl"
                />
              </v-col>
              <v-col cols="10">
                <p class="text-sm-left thin">{{ this.me.company_name }}</p>
                <p class="text-sm-left bold">Main Contact</p>
                <p class="text-sm-left thin">{{ this.me.first_name }}</p>
                <p class="text-sm-left thin">{{ this.me.last_name }}</p>
                <p class="text-sm-left thin">{{ this.me.email }}</p>
              </v-col>
            </v-row>
          </v-sheet>
          <v-col />
          <v-col>
            <p class="cyan--text text--darken-1" id="title"></p>
          </v-col>
        </v-card-text>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" class="mx-1">
      <v-col cols="3"> </v-col>
      <v-col cols="6">
        <p class="cyan--text text--darken-1 font-weight-bold" id="title">
          INTERRUPTION OF YOUR PAYMENT
        </p>
        <p class="text-sm-left thin mt-10">
          An error was encountered during the processing of your payment.
        </p>
        <p class="text-sm-left thin">
          For any questions, please <a href="/contact">contact us</a>.
        </p>
      </v-col>

      <v-col cols="3"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="5"> </v-col>
      <v-col cols="2">
        <v-btn
          sm="2"
          class="white--text"
          color="#514d7a"
          max-height="100"
          width="40"
          rounded
          block
          @click.stop.prevent="retryPayment()"
        >
          Try Again
        </v-btn>
        <v-col cols="5"> </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeaderMain from "@/components/HeaderMain.vue";
import CompanyService from "@/store/services/company/company.service.js";
import Profile from "@/store/services/profile-service";
import Status from "@/store/services/status.service";

export default {
  components: {
    HeaderMain,
  },
  data() {
    return {
      me: {},
      company_id: null,
      logoPreviewUrl: null,
    };
  },
  methods: {
    retryPayment() {
      if (this.$route.query.tp == "company") {
        this.$router.push({ name: "Application", query: { el: 7 } });
      } else if (this.$route.query.tp == "investor") {
        this.$router.push({ name: "InvestorApplication", query: { el: 4 } });
      } else if (this.$route.query.tp == "sponsor") {
        //this.$router.push({ name: "InvestorApplication", query: { el: 4 } });
      }
    },

    retrieveLogo() {
      if (this.company_id != null) {
        CompanyService.getLogo(this.company_id)
          .then((res) => {
            //console.log(res.data);
            this.logoPreviewUrl = res.data;
            //this.formData.append("avatar", photoFromApi);
          })
          .catch((error) => {});
      }
    },
  },

  mounted() {
    Status.readAll()
      .then((res) => {
        if (res.data) {
          this.company_id = res.data.active_company_id;
          this.me.company_name = res.data.active_company_name;
        }
        this.retrieveLogo();
      })
      .catch((e) => {
        //console.log(e);
      });

    Profile.get().then((res) => {
      this.me = res.data;
    });

    this.getCompanyName();
  },
};
</script>
