<template>
  <div class="mt-10">
    <br />
    <v-container id="sub" class="mycenter">
      <h2 id="title">WELCOME TO IDEASFUNDX</h2>
      <TempPopup v-if="this.enablePopup" :user_status="user_status" />
      <v-btn
        id="btn-verify"
        center
        class="white--text my-3"
        color="#514d7a"
        width="275"
        height="55"
        rounded
        :disabled="false"
        @click="redictionHome()"
      >
        Go to HomePage
      </v-btn>
      <p class="mt-3">
        Or <a v-bind:href="CMScontact">contact us</a> for any further
        information.
      </p>
    </v-container>
  </div>
</template>

<script>
import TempPopup from "@/components/ComingSoonPopup.vue";
export default {
  components: {
    TempPopup,
  },
  //props: { is_company: Boolean },

  data() {
    return {
      CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
      enablePopup: true,
      user_status: ''
      //
    };
  },

  methods: {
    redictionHome () {
      this.$router.push({ name: "Home" });
    },
    redirectToCMS() {
      window.open(process.env.VUE_APP_CMS_URL, "_top");
    },
  },

  mounted(){
    if(!localStorage.getItem("user_role")) {
      this.redictionHome();
      this.enablePopup = false;
    }
    if (localStorage.getItem("user_role") == 'i-1') this.user_status = 'i-1';
    if (localStorage.getItem("user_role") == 'i-2') this.user_status = 'i-2';
    if (localStorage.getItem("user_role") == 'c-1') this.user_status = 'c-1';
    if (localStorage.getItem("user_role") == 'c-2') this.user_status = 'c-2';
  }
};
</script>

<style scoped lang="css">
.mycenter {
  display: block;
  margin: 11% auto;
  width: 50%;
}

#title {
  /* font-weight: bolder; */
  font-size: 2em;
}
#sub-text p {
  font-weight: normal;
  font-size: 1em;
}
</style>
