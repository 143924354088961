<template>
    <div>
        <div
            :is="component" @forceUpdate="getProfile()"
        />
    </div>
</template>

<script>
    import InvestorStatusI1 from '@/components/Dashboard/Investor/InvestorStatusI1';
    import InvestorStatusI2 from '@/components/Dashboard/Investor/InvestorStatusI2';
    import InvestorStatusI3 from '@/components/Dashboard/Investor/InvestorStatusI3';
    import Profile from "@/store/services/profile-service";
    export default { 
        components : {
            InvestorStatusI1,
            InvestorStatusI2,
            InvestorStatusI3
        },
        data() {
            return {
                user: null,
                component: null
            }
        },
        methods: {
            getProfile() {
                Profile.get().then((res) => { 
                    if (res.status == 200) {
                        if (res.data.role_id == 'i-1') {
                            this.component = 'InvestorStatusI1';
                        }else if(res.data.role_id == 'i-2') {
                            this.component = 'InvestorStatusI2';
                        }else if (res.data.role_id == 'i-3') {
                            this.component = 'InvestorStatusI3';
                        }
                    }
                });
            },
        },
        mounted() {
            this.getProfile();
        }
    }
</script>

<style scoped>
    @import '../../../assets/styles/accountStatus.css';
</style>