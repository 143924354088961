import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    Authorization:
      "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token"),
  },
};

function getCategory() {
  return axios.get(`${url}/investor/accreditation/category`, options);
}

function saveCategory(payload) {
  return axios.post(`${url}/investor/accreditation/category`, payload, options);
}

function saveCategoryDashboard(payload, category) {
  return axios.post(`${url}/investor/accreditation/category/${category}`, payload, options);
}

function getProfile() {
  return axios.get(`${url}/investor/accreditation/billing`, options);
}

function saveProfile(payload) {
  return axios.post(`${url}/investor/accreditation/profile`, payload, options);
}

function storeBillingAddress(payload) {
  return axios.post(`${url}/investor/accreditation/profile/update`, payload, options);
}

function getLogo() {
  return axios.get(`${url}/investor/accreditation/logo`, options);
}

function saveLogo(payload) {
  return axios.post(`${url}/investor/accreditation/logo`, payload, options);
}

function getType() {
  return axios.get(`${url}/investor/accreditation/type`, options);
}

function saveType(payload) {
  return axios.post(`${url}/investor/accreditation/type`, payload, options);
}

function getVehicle() {
  return axios.get(`${url}/investor/accreditation/vehicle`, options);
}

function saveVehicle(payload) {
  return axios.post(`${url}/investor/accreditation/vehicle`, payload, options);
}

export default {
  getCategory,
  getProfile,
  getLogo,
  getType,
  getVehicle,
  saveCategory,
  saveCategoryDashboard,
  saveProfile,
  storeBillingAddress,
  saveLogo,
  saveType,
  saveVehicle
};
