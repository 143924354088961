<template>
    <div>
        <v-row align="center" justify="center" class="mar-top-100">
            <v-col cols="0" md="1"/>
            <v-col cols="11" md="11">
                <p class="cyan--text text--darken-1 text-sm-left mt-3 " id="title">The company - <span>{{ representative.company_name }}</span></p>

                <v-row align="center" justify="center">
                    <v-col cols="3">
                        <v-card-actions class="justify-center">
                            <v-hover v-slot:default="{ hover }">
                                <v-img
                                v-if="!photoBlock"
                                max-height="300"
                                max-width="300"
                                @click.stop="dialogUp('Photo')"
                                :src="imagePreviewUrl">
                                    <v-expand-transition>
                                        <div
                                        v-if="hover"
                                        style="background-color: rgb(81, 77, 122); height: 100%"
                                        class="d-flex transition-fast-in-fast-out v-img--reveal display-1 white--text align-center justify-center">
                                            CHANGE YOUR LOGO
                                        </div>
                                    </v-expand-transition>
                                </v-img>
                            </v-hover>

                            <v-btn
                            v-if="photoBlock"
                            sm="2"
                            id="btn-photo"
                            class="white--text"
                            color="#514d7a"
                            height="90"
                            min-width="20"
                            rounded
                            block
                            :disabled="false"
                            @click.stop="dialogUp('Photo')">
                            ADD LOGO*
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>

                <v-dialog v-model="dialogPhoto" max-width="400">
                    <PhotoForm @uploadPhoto="uploadLogo" @closePhoto="dialogDown('Photo')"/>
                </v-dialog>

                <v-row align="center" justify="center" no-gutters class="pt-8">
                    
                    <!-- Website -->
                    <v-row>
                        <v-col cols="2">
                            <FormInlineMessage v-if="$v.link.website.$error">
                                <v-col cols="12"/>
                            </FormInlineMessage>
                            <img
                            src="../../assets/img/Icon-Website.png"
                            width="30"
                            height="30"/>
                        </v-col>
                        <v-col cols="8">
                            <FormInlineMessage v-if="$v.link.website.$error">
                                please write a valid url
                            </FormInlineMessage>
                            <v-text-field
                            v-model="link.website"
                            outlined
                            dense
                            placeholder="Enter url of your company website"/>
                        </v-col>
                    </v-row>

                    <v-col cols="12" />

                    <!-- Blog -->
                    <v-row>
                        <v-col cols="2">
                            <FormInlineMessage v-if="$v.link.blog.$error">
                                <v-col cols="12"/>
                            </FormInlineMessage>
                            <img
                            src="../../assets/img/Icon-Blog4.png"
                            width="30"
                            height="30"/>
                        </v-col>
                        <v-col cols="8">
                            <FormInlineMessage v-if="$v.link.blog.$error">
                                please write a valid url
                            </FormInlineMessage>
                            <v-text-field
                            v-model="link.blog"
                            outlined
                            dense
                            placeholder="Enter url of your company blog"/>
                        </v-col>
                    </v-row>

                    <v-col cols="12" />

                    <!-- Facebook -->
                    <v-row>
                        <v-col cols="2">
                            <FormInlineMessage v-if="$v.link.facebook.$error">
                                <v-col cols="12"/>
                            </FormInlineMessage>
                            <img
                            src="../../assets/img/Icon-FB1.png"
                            width="30"
                            height="30"
                            />
                        </v-col>
                        <v-col cols="8">
                            <FormInlineMessage v-if="$v.link.facebook.$error">
                                please write a valid url
                            </FormInlineMessage>
                            <v-text-field
                            v-model="link.facebook"
                            outlined
                            dense
                            placeholder="Enter url of your company facebook page"/>
                        </v-col>
                    </v-row>

                    <v-col cols="12" />

                    <!-- Linkedin -->
                    <v-row>
                        <v-col cols="2">
                            <FormInlineMessage v-if="$v.link.linkedin.$error">
                                <v-col cols="12"/>
                            </FormInlineMessage>
                            <img
                            src="../../assets/img/Icon-In.png"
                            width="30"
                            height="30"
                            />
                        </v-col>
                        <v-col cols="8">
                            <FormInlineMessage v-if="$v.link.linkedin.$error">
                                please write a valid url
                            </FormInlineMessage>
                            <v-text-field
                            v-model="link.linkedin"
                            outlined
                            dense
                            placeholder="Enter url of your company linkedin"/>
                        </v-col>
                    </v-row>

                    <v-col cols="12" />

                    <!-- Twitter -->
                    <v-row>
                        <v-col cols="2">
                            <FormInlineMessage v-if="$v.link.twitter.$error">
                                <v-col cols="12"/>
                            </FormInlineMessage>
                            <img
                            src="../../assets/img/Icon-Twitter.png"
                            width="30"
                            height="30"
                            />
                        </v-col>
                        <v-col cols="8">
                            <FormInlineMessage v-if="$v.link.twitter.$error">
                                please write a valid url
                            </FormInlineMessage>
                            <v-text-field
                            v-model="link.twitter"
                            outlined
                            dense
                            placeholder="Enter url of your company twitter account"/>
                        </v-col>
                    </v-row>
                </v-row>

                <v-col cols="12"/>

                <v-row align="start" justify="start" no-gutters class="pt-8">
                    <v-col cols="12" md="2">
                        <FormInlineMessage v-if="$v.representative.date_incorporation.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <p class="text-sm-left bold mt-2" >Date of incorporation</p>
                    </v-col>
                    <v-col cols="12" md="8">
                        <FormInlineMessage v-if="$v.representative.date_incorporation.$error" class="errmsg">
                            Indicate date of incorporation
                        </FormInlineMessage>
                        <v-menu
                            ref="dob"
                            :close-on-content-click="false"
                            :return-value.sync="representative.date_incorporation"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                height="40"
                                outlined
                                v-model="representative.date_incorporation"
                                placeholder="Date of incorporation"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                @blur="$v.representative.date_incorporation.$touch()"
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="representative.date_incorporation" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="$refs.dob.isActive = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dob.save(representative.date_incorporation)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>

                <!-- Describe -->
                <v-row>
                    <v-col cols="12">
                        <p class="text-sm-left">Description of the company</p>
                    </v-col>
                    <v-col cols="12" md="10">
                        <FormInlineMessage v-if="$v.company.resume.$error">
                            Your description must be between 35 and 1500 characters
                        </FormInlineMessage>
                        <v-textarea
                        v-model="company.resume"
                        outlined
                        dense
                        rows="10"
                        :counter="1500"/>
                    </v-col>
                </v-row>

                <!-- Pitch deck -->
                <v-row align="start" justify="start" no-gutters class="pt-8">
                    <v-col cols="12" md="2">
                        <FormInlineMessage v-if="$v.company.pitch_deck.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left bold mt-2">Link to pitch deck</p>
                    </v-col>
                    <v-col cols="12" md="8">
                        <FormInlineMessage v-if="$v.company.pitch_deck.$error">
                            please write a valid url
                        </FormInlineMessage>
                        <v-text-field
                        v-model="company.pitch_deck"
                        outlined
                        dense
                        placeholder="Enter url of your pitch deck"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- Save button -->
        <v-row align="center" justify="center" class="pt-2">
            <v-col cols="11" sm="1">
                <v-btn
                id="btn-photo"
                class="white--text mb-3"
                style="font-weight: bold; font-size: 14px"
                color="#11aec9"
                min-width="20"
                rounded
                block
                :disabled="false"
                @click.stop.prevent="saveCompanyProfile()">
                    save
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import PhotoForm from "@/forms/PhotoForm";
import CompanyService from "@/store/services/company/company.service";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  minLength,
  maxLength,
  numeric,
  minValue,
  email,
  sameAs,
  url,
} from "vuelidate/lib/validators";

export default {
    props: {
        company_id: Number,
    },

    components: {
        FormInlineMessage,
        PhotoForm
    },

    data () {
        return {
            imagePreviewUrl: null,
            dialogPhoto: false,
            photoBlock: true,
            formData: new FormData(),
            link: {},
            company: {
                resume: null,
                pitch_deck: null,
            },
            representative: {
                date_incorporation: "",
            },
        }
    },

    validations: {
        link: {
            website: { url },
            blog: { url },
            facebook: { url },
            linkedin: { url },
            twitter: { url },
        },
        company: {
            resume: {
                required,
                minLength: minLength(35),
                maxLength: maxLength(1500)
            },
            pitch_deck: { url },
        },
        representative: {
            required,
            date_incorporation: { required },
        },
    },

    methods: {
        dialogUp(element) {
            this.$data["dialog" + element] = true;
        },

        dialogDown(element) {
            this.$data["dialog" + element] = false;
        },

        uploadLogo(chosenImage) {
            this.imagePreviewUrl = URL.createObjectURL(chosenImage);
            this.formData.append("logo", chosenImage);
            this.formData.append("company_id", this.company_id);

            CompanyService.saveLogo(this.formData);
            this.photoBlock = false;
            this.dialogDown("Photo");
        },

        saveCompanyProfile() {
            this.$v.$touch();
            if (this.$v.$error) { return; }

            CompanyService.saveLink(JSON.stringify({ 'company_id': this.company_id, 'link': this.link }));
            CompanyService.saveResume({ 'company_id':this.company_id, 'resumes':this.company });
            CompanyService.saveDescribe(this.representative).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Company profile saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Your profile has not been saved !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        }
    },

    created() {
        Promise.all([
            CompanyService.getLogo(this.company_id)
            .then((res) => {
                this.imagePreviewUrl = res.data;
                this.photoBlock = false;
            })
            .catch(() => { this.photoBlock = true; }),

            CompanyService.getLink(this.company_id).then((res) => { this.link = res.data; }),

            CompanyService.getResume(this.company_id).then((res) => { this.company = res.data; }),

            CompanyService.getDescribe().then((res) => {
                this.representative.company_name = res.data.company_name;
                this.representative.date_incorporation = res.data.date_incorporation;

                this.representative.gender = res.data.gender;
                this.representative.first_name = res.data.first_name;
                this.representative.last_name = res.data.last_name;
                this.representative.country = res.data.country;
                this.representative.street = res.data.street;
                this.representative.zip = res.data.zip;
                this.representative.city = res.data.city;
                this.representative.date_incorporation = res.data.date_incorporation;
                this.representative.phone = res.data.phone;
                this.phone = this.representative.phone;
            }),
        ])
    }
}
</script>