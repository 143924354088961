<template>
    <div>
        <v-col/>
        <v-row>
            <v-col cols="0" md="1"/>
            <v-col cols="12" md="11">
                <p class="cyan--text text--darken-1 text-sm-left" id="title">Mofidy your Password</p>

                <v-row class="mb-n8">
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.oldpwd.$error || sameAsPwd">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left pt-2">Old Password</p>
                    </v-col>
                    <v-col cols="10" sm="8">
                        <FormInlineMessage v-if="sameAsPwd">
                            It's not matching your actual password
                        </FormInlineMessage>
                        <FormInlineMessage v-else-if="$v.oldpwd.$error">
                            Please fill your actual password
                        </FormInlineMessage>
                        <v-text-field
                        v-bind:type="seeOld ? '' : 'password'"
                        v-model="oldpwd"
                        outlined
                        dense/>
                    </v-col>
                    <v-col cols="2">
                        <FormInlineMessage v-if="$v.oldpwd.$error || sameAsPwd">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <v-checkbox
                        class="mt-n1"
                        v-model="seeOld"/>
                    </v-col>
                </v-row>

                <v-row class="mb-n8">
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.newpwd.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left pt-2">New Password</p>
                    </v-col>
                    <v-col cols="10" sm="8">
                        <FormInlineMessage v-if="$v.newpwd.$error">
                            The new password need to have at least 8 characters and different from the actual one
                        </FormInlineMessage>
                        <v-text-field
                        v-bind:type="seeNew ? '' : 'password'"
                        v-model="newpwd"
                        outlined
                        dense/>
                    </v-col>
                    <v-col cols="2">
                        <FormInlineMessage v-if="$v.newpwd.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <v-checkbox
                        class="mt-n1"
                        v-model="seeNew"/>
                    </v-col>
                </v-row>

                <v-row class="mb-n8">
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.confirme.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <p class="text-sm-left pt-2">Confirme your Password</p>
                    </v-col>
                    <v-col cols="10" sm="8">
                        <FormInlineMessage v-if="$v.confirme.$error">
                            It's not matching with the new password
                        </FormInlineMessage>
                        <v-text-field
                        v-bind:type="seeConfirme ? '' : 'password'"
                        v-model="confirme"
                        outlined
                        dense/>
                    </v-col>
                    <v-col cols="2">
                        <FormInlineMessage v-if="$v.confirme.$error">
                            <v-col cols="0" sm="12"/>
                        </FormInlineMessage>
                        <v-checkbox
                        class="mt-n1"
                        v-model="seeConfirme"/>
                    </v-col>
                </v-row>

                <v-row align="center" justify="center" class="pt-2">
                    <v-col cols="0" sm="9"/>
                    <v-col cols="12" sm="1">
                            <v-btn
                            id="btn-photo"
                            class="white--text mb-3"
                            style="font-weight: bold; font-size: 14px"
                            color="#11aec9"
                            min-width="20"
                            rounded
                            block
                            :disabled="false"
                            @click.stop.prevent="save()">
                                save
                            </v-btn>
                    </v-col>
                    <v-col cols="0" sm="2"/>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog
        v-model="dialogServer"
        persistent
        width="380"
        min-height="393">
            <v-card height="100%" width="380">
                <v-card-title class="justify-center"> Notification: </v-card-title>
                <v-card-text>{{ messageServer }}</v-card-text>
                <v-card-actions class="pb-15 justify-center">
                    <v-col cols="12" class="justify-center">
                        <v-btn
                        center
                        class="white--text btn-next bold justify-center"
                        @click="dialogServer = false"
                        color="#00aac3"
                        width="140"
                        height="45"
                        rounded>
                            Close
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  minLength,
  maxLength,
  numeric,
  minValue,
  email,
  sameAs,
  not,
} from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";
import Profile from "@/store/services/profile-service";

export default {
    components: {
        FormInlineMessage,
    },

    data() {
        return {
            oldpwd: null,
            seeOld: false,
            newpwd: null,
            seeNew: false,
            confirme: null,
            seeConfirme: false,
            sameAsPwd: false,
            dialogServer: false,
            messageServer: "",
        }
    },

    validations: {
        oldpwd: { required, },
        newpwd: {
            required,
            minLength: minLength(8),
            notSameAsOldpwd: not(sameAs('oldpwd'))
        },
        confirme: {
            required,
            sameAsNewpwd: sameAs("newpwd"),
        },
    },

    methods: {
        save() {
            this.sameAsPwd = false;
            this.$v.$touch();
            if (this.$v.$error) { return; }

            Profile.passwordChange({oldpwd: this.oldpwd, newpwd: this.newpwd})
            .then(() => {
                this.messageServer = "Password modification was successful.";
                this.dialogServer = true;
            })
            .catch((res) => {
                if (res.status == 421) { this.sameAsPwd = true; return; }
                this.messageServer = "Password modification failed.";
                this.dialogServer = true;
            });

            // Profile.getpwd({oldpwd: this.oldpwd}).then((res) => {
            //     Profile.passwordReset(JSON.stringify({
            //         token: res.data.token,
            //         email: res.data.email,
            //         password: this.newpwd,
            //     }))
            //     .then(() => {
            //         this.messageServer = "Password modification was successful.";
            //         this.dialogServer = true;
            //         this.oldpwd = null;
            //         this.newpwd = null;
            //         this.confirme = null;
            //     })
            //     .catch(() => {
            //         this.messageServer = "Password modification failed.";
            //         this.dialogServer = true;
            //     });
            // }).catch(() => { this.sameAsPwd = true; return; });
        },
    }
}
</script>