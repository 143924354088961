<template>
  <div class="form-inline-message">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormInlineMessage',
};
</script>

<style>
.form-inline-message {
  font-size: smaller;
  color: red !important;
}
</style>
