<template>
    <div>
        <v-sheet
        height="100%"
        class="mx-auto mt-10">
            <v-row align="center" justify="center" class="pt-2">
                <v-col cols="1"/>
                <v-col cols="10">
                    <p class="cyan--text text--darken-1 font-weight-bold text-sm-left" id="title">Almost there... to get access to qualified deals from IdeasFundX</p>
                    <p class="text-sm-left thin"><br>I agree to the Terms of Service including:</p>
                    <p class="text-sm-left thin">I indemnify IdeasFundX and understand that most startups lose investors' money.</p>
                    <p class="text-sm-left thin">I understand that IdeasFundX aims to recommend only qualified deals to Investors matching their investment strategies.<br/>
                        IdeasFundX check the quality of each pitch and the attractiveness of the investment opportunity but doesn't ensure the accuracy of all information the company have provided and doesn’t perform detailed due diligence. 
                    </p>
                    <p class="text-sm-left thin">If I invest, I will use an attorney and comply with securities laws.<br/>
                        I will be permanently banned from IdeasFundX if I falsely represent my accreditation status
                    </p>
                    <p class="text-sm-left thin">I understand that to be referred among the investors in the IdeasFundX database, IdeasFundX will review information I have provided and could refuse my application to join the investor community of IdeasFundX.</p>
                    <p class="text-sm-left thin">If IdeasFundX contacts me to check the information provided, I agree to adapt my information provided if necessary.</p>
                    <p class="text-sm-left thin">I want to receive notification when IdeasFundX finds deals matching my investor profile. <br/>
I understand that I can choose deals among the list provided by IdeasFundX to learn more about them.<br/>
                    I understand that I can choose deals among the list provided by IdeasFundX to learn more about them.</p>
                    <p class="text-sm-left thin">I agree to sign a NDA when I want to learn more about the investment opportunity.</p>
                    <p class="text-sm-left cyan--text text--darken-1 "><br>Pricing Policy</p>
                    <p class="text-sm-left thin">IdeasFundX charges:</p>
                    <p class="text-sm-left thin">
                        => to receive notifications of qualified deals: a yearly membership of $490 excl. tax.<br>
                        => a success fee of 4% of the investment if you invest in the company.
                    </p>
                    <p class="cyan--text text--darken-1 text-sm-left" style="font-size: 17px;">IdeasFundX offers you a free 6 months trial from the confirmation date of your registration.</p>
                </v-col>
                <v-col cols="1"/>
            </v-row>
        </v-sheet>
        <TempPopup v-if="this.enablePopup" v-bind:is_company="false"/>
        <v-sheet
        height="100%"
        class="mx-auto"
        color="#f6f6f6"
        v-if="currentBlock">
            <v-row align="center" justify="center" class="pt-2">
                <v-col cols="2">
                    <v-card-actions class="justify-center">
                    <v-btn
                        sm="2"
                        id="btn-photo"
                        class="white--text test--btn"
                        style="font-weight: bold; font-size: 24px;"
                        color="#514d7a"
                        height="90"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.prevent.stop="validTerm()">
                            I AGREE
                        </v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>
        </v-sheet>
    </div>
</template>

<script>
import TempPopup from '@/components/Popup.vue';
import Status from "@/store/services/status.service";
import InvestmentService from "@/store/services/investor/investment.service"
export default {
    components: {
    TempPopup
  },
    data() {
        return{
            enablePopup: false,
            currentBlock: true
        }
    },

    methods: {
        validTerm() {
            InvestmentService.updateStatus().then((res)=>{
                //console.log(res);
                Status.store(JSON.stringify({ application_step: 5 }));
                this.$router.push({ name: "DashboardInvestor" });
                // this.enablePopup=true;
            }).catch((e)=>{
                //console.error(e);
                this.enablePopup=true;
            })
            // call final investor function, bookmarks the start of the 6 months trial, and triggers the emails
            // this.currentBlock = false
            // this.$emit("block2");
            //this.enablePopup=true;
        }
    }
}
</script>

<style scoped>
    .test--btn {
        font-weight: bold;
        font-size: 30px;
    }
</style>