<template>
    <div>
        <v-col/>
        <v-row align="center" justify="center" class="mar-top-100">
            <v-col cols="0" sm="1"/>
            <v-col cols="11" sm="11">
                <p class="cyan--text text--darken-1 text-sm-left" id="title">Billing Address</p>
                
                <v-row>
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.billing.company_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Company</p>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <FormInlineMessage v-if="$v.billing.company_name.$error" class="errmsg">
                            Indicate the name of the company
                        </FormInlineMessage>
                        <v-text-field
                        v-model="billing.company_name"
                        outlined
                        dense
                        @blur="$v.billing.company_name.$touch()"/>
                    </v-col>
                </v-row>

                <v-row class="mt-n5">
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.billing.first_name.$error || $v.billing.last_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Attention</p>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.billing.first_name.$error || $v.billing.last_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <v-autocomplete
                            v-model="billing.gender"
                            :items="genders"
                            outlined
                            dense
                        />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.billing.first_name.$error" class="errmsg">
                            Indicate first name
                        </FormInlineMessage>
                        <FormInlineMessage v-else-if="$v.billing.last_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <v-text-field
                        v-model="billing.first_name"
                        outlined
                        dense
                        placeholder="first name"
                        @blur="$v.billing.first_name.$touch()"/>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.billing.last_name.$error" class="errmsg">
                            Indicate last name
                        </FormInlineMessage>
                        <FormInlineMessage v-else-if="$v.billing.first_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <v-text-field
                        v-model="billing.last_name"
                        outlined
                        dense
                        placeholder="last name"
                        @blur="$v.billing.last_name.$touch()"/>
                    </v-col>
                </v-row>
                 <v-row class="mt-n5">   
                    <v-col cols="12">
                        <p class="text-sm-left">Billing Address</p>
                    </v-col>
                 </v-row>

                    <v-row>
                        <v-col cols="0" sm="1"/>
                        <v-col cols="12" sm="10">
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <FormInlineMessage v-if="$v.billing.street.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <p class="text-sm-left">Street</p>
                                </v-col>
                                <v-col cols="12" sm="10">
                                    <FormInlineMessage v-if="$v.billing.street.$error" class="errmsg">
                                        Indicate street
                                    </FormInlineMessage>
                                    <v-text-field
                                    v-model="billing.street"
                                    outlined
                                    dense
                                    @blur="$v.billing.street.$touch()"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="0" sm="1"/>
                    </v-row>

                    <v-col cols="0" sm="12"/>

                    <v-row class="mt-n10">
                        <v-col cols="0" sm="1"/>
                        <v-col cols="12" sm="10">
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <FormInlineMessage v-if="$v.billing.zip_code.$error || $v.billing.city.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <p class="text-sm-left">ZIP code</p>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <FormInlineMessage v-if="$v.billing.zip_code.$error" class="errmsg">
                                        Indicate zip code
                                    </FormInlineMessage>
                                    <FormInlineMessage v-else-if="$v.billing.city.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <v-text-field
                                    v-model="billing.zip_code"
                                    outlined
                                    dense
                                    @blur="$v.billing.zip_code.$touch()"/>
                                </v-col>
                                <v-col cols="0" sm="2"/>
                                <v-col cols="12" sm="2">
                                    <FormInlineMessage v-if="$v.billing.zip_code.$error || $v.billing.city.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <p class="text-sm-left">City</p>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <FormInlineMessage v-if="$v.billing.city.$error" class="errmsg">
                                        Indicate city
                                    </FormInlineMessage>
                                    <FormInlineMessage v-else-if="$v.billing.zip_code.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <v-text-field
                                    v-model="billing.city"
                                    outlined
                                    dense
                                    @blur="$v.billing.city.$touch()"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="0" sm="1"/>
                    </v-row>

                    <v-col cols="0" sm="12"/>
                    
                    <v-row class="mt-n10">
                        <v-col cols="0" sm="1"/>
                        <v-col cols="12" sm="10">
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <FormInlineMessage v-if="$v.billing.country.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <p class="text-sm-left">Country</p>
                                </v-col>
                                <v-col cols="12" sm="10">
                                    <FormInlineMessage v-if="$v.billing.country.$error" class="errmsg">
                                        Indicate country
                                    </FormInlineMessage>
                                    <v-select
                                    :items="countries"
                                    v-model="billing.country"
                                    outlined
                                    dense
                                    @blur="$v.billing.country.$touch()"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="0" sm="1"/>
                    </v-row>

                <v-row class="mt-n5">
                    <v-col cols="12" sm="3">
                        <p class="text-sm-left">VAT or Registration Number</p>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <v-text-field
                        dense
                        outlined
                        v-model="billing.vat"
                        placeholder="#########"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row align="center" justify="center" class="pt-2">
            <v-col cols="11" sm="1">
                    <v-btn
                    id="btn-photo"
                    class="white--text mb-3"
                    style="font-weight: bold; font-size: 14px"
                    color="#11aec9"
                    min-width="20"
                    rounded
                    block
                    :disabled="false"
                    @click.stop.prevent="saveLegalbilling()">
                        save
                    </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import Var from "@/store/services/var";
import AddressService from "@/store/services/address.service";
import { required } from "vuelidate/lib/validators";

export default {
    components: {
        FormInlineMessage,
    },

    props: {
        company_id: Number,
    },

    data() {
        return {
            genders: ["Mr.", "Ms.", "Mrs."],
            countries: [],
            billing: {
                gender: null,
                first_name: null,
                last_name: null,
                company_name: null,
                street: null,
                city: null,
                zip_code: null,
                country: null,
                vat: null,
            }
        }
    },

    validations: {
        billing: {
            required,
            first_name: { required },
            last_name: { required },
            company_name: { required },
            street: { required },
            city: { required },
            zip_code: { required },
            country: { required },
        },
    },

    methods: {
        saveLegalbilling() {
            this.$v.$touch();
            if (this.$v.$error) {
                return;
            }
            AddressService.store({ company_id: this.company_id, billing_address: this.billing }).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Billing address saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Error while saving billing address !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        }
    },

    mounted() {
        Promise.all ([
            Var.getAllCountry().then((res) => { this.countries = res.data; }),
            AddressService.read(this.company_id).then((res) => {
                this.billing.gender = res.data.gender;
                this.billing.first_name = res.data.first_name;
                this.billing.last_name = res.data.last_name;
                this.billing.company_name = res.data.company_name;
                this.billing.street = res.data.street;
                this.billing.city = res.data.city;
                this.billing.zip_code = res.data.zip_code;
                this.billing.country = res.data.country;
                this.billing.vat = res.data.vat;
            }),
        ])
    },
}
</script>