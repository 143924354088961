<template>
  <v-dialog v-model="this.is_active" @click:outside="closePopup" max-width="350px">
    <v-card min-height="200px" style="overflow-x: hidden; overflow-y: hidden">
      <v-card-title class="justify-center">
        <span id="title" class="mb-n3">Share IdeasFundx with your friends</span>
      </v-card-title>
      <v-sheet height="100%" class="mx-4 my-0" rounded>
        <v-card-text>
          <v-container>
            <div id="sub-text" class="text-left mt-2">
              <v-row align="center" justify="center">
                <v-icon color="#27aac3" @click.prevent.stop="redirectTo('https://www.facebook.com/sharer.php?u=https%3A%2F%2Fideasfundx.com%2F')" size="50px" >mdi-facebook</v-icon>
              </v-row>
              <v-row  align="center" justify="center">
                <v-icon color="#27aac3" class="mt-3" size="50px" @click.prevent.stop="redirectTo('https://twitter.com/intent/tweet?url=https%3A%2F%2Fideasfundx.com%2F&text=%23IdeasFundX+Match+high+quality+%23startup+with+relevant+%23VC+for+%2B$1mm+%23fundraising,+optimize+the+%23dealflow+%23screening+process.')">mdi-twitter</v-icon>
              </v-row>
              <v-row align="center" justify="center">
                <v-icon color="#27aac3" class="my-3" size="50px" @click.prevent.stop="redirectTo('https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fideasfundx.com%2F')">mdi-linkedin</v-icon>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props:{
    is_active: Boolean
  },
  data() {
    return {
      CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
    };
  },

  methods: {
    redirectTo(route) {
      window.open(route); //, "_top"
    },
    closePopup() {
      this.$emit('closePopup', false);
    },
  },
};
</script>

<style scoped lang="css">
#title {
  font-weight: bolder;
  font-size: 18px;
}
#sub-text p{
  font-weight: normal;
  font-size: 14px;
}

.blueicon{
  color: #27aac3;
}
</style>
