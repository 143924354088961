<template>
    <div class="mar-top-100">
        <v-col/>
        <v-row align="center" justify="center">
            <v-col cols="0" sm="1"/>
            <v-col cols="11" sm="11">
                <p class="cyan--text text--darken-1 text-sm-left" id="title" v-if="investment_type == 'firm'">Investment Firm</p>
                <p class="cyan--text text--darken-1 text-sm-left" id="title" v-else>Legal Representative</p>

                <v-row class="mb-n10" v-if="investment_type == 'firm'">
                    <v-col cols="12">
                        <p class="text-sm-left">Legal name of your firm</p>
                    </v-col>
                    <v-col cols="12" sm="8" class="mt-n8">
                        <FormInlineMessage v-if="$v.describe.firm_name.$error">
                            Provide a valid name
                        </FormInlineMessage>
                        <v-text-field
                        v-model="describe.firm_name"
                        outlined
                        dense/>
                    </v-col>
                </v-row>

                <v-row class="">
                    <v-col cols="12" class="mb-n1">
                        <FormInlineMessage v-if="$v.describe.first_name.$error || $v.describe.last_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <p class="text-sm-left" v-if="investment_type == 'firm'">Legal representative</p>
                        <p class="text-sm-left" v-else>Your Legal name </p>
                    </v-col>
                    <v-col cols="12" sm="2" class="mt-n6">
                        <FormInlineMessage v-if="$v.describe.first_name.$error || $v.describe.last_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <v-autocomplete
                            v-model="describe.gender"
                            :items="genders"
                            outlined
                            dense
                        />
                    </v-col>
                    <v-col cols="12" sm="3" class="mt-n6">
                        <FormInlineMessage v-if="$v.describe.first_name.$error" class="errmsg">
                            Indicate first name
                        </FormInlineMessage>
                        <FormInlineMessage v-else-if="$v.describe.last_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <v-text-field
                        v-model="describe.first_name"
                        outlined
                        dense
                        placeholder="first name"
                        @blur="$v.describe.first_name.$touch()"/>
                    </v-col>
                    <v-col cols="12" sm="3" class="mt-n6">
                        <FormInlineMessage v-if="$v.describe.last_name.$error" class="errmsg">
                            Indicate last name
                        </FormInlineMessage>
                        <FormInlineMessage v-else-if="$v.describe.first_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <v-text-field
                        v-model="describe.last_name"
                        outlined
                        dense
                        placeholder="last name"
                        @blur="$v.describe.last_name.$touch()"/>
                    </v-col>
                </v-row>
                
                <v-row class="mb-n8 mt-n8">
                    <v-col cols="12">
                        <p class="text-sm-left" v-if="investment_type == 'firm'">country</p>
                        <p class="text-sm-left" v-else>Country where you are living</p>
                    </v-col>
                    <v-col cols="12" sm="8" class="mt-n8">
                        <FormInlineMessage v-if="$v.describe.country.$error">
                            Indicate your country
                        </FormInlineMessage>
                        <v-autocomplete
                            v-model="describe.country"
                            :items="countries"
                            outlined
                            dense
                        />
                    </v-col>
                </v-row>

                <v-row class="mt-n5">
                    <v-col cols="12"> 
                        <p class="text-sm-left" v-if="investment_type == 'firm'">HQ Address</p>
                        <p class="text-sm-left" v-else>Your official Address</p>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="0" sm="1"/>
                    <v-col cols="12" sm="10">
                        <v-row class="mt-n14">
                            <v-col cols="12" sm="2" class="mt-2 mb-n8">
                                <FormInlineMessage v-if="$v.describe.street.$error" class="errmsg">
                                    <v-col cols='0' sm='12'/>
                                </FormInlineMessage>
                                <p class="text-sm-left">Street</p>
                            </v-col>
                            <v-col cols="12" sm="10">
                                <FormInlineMessage v-if="$v.describe.street.$error" class="errmsg">
                                    Indicate street
                                </FormInlineMessage>
                                <v-text-field
                                v-model="describe.street"
                                outlined
                                dense
                                @blur="$v.describe.street.$touch()"/>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="0" sm="1"/>

                    <v-col cols="0" sm="1"/>
                    <v-col cols="12" sm="10" class="mt-n6">
                        <v-row class="address">
                            <v-col cols="12" sm="2" class="mb-n8 mt-2">
                                <FormInlineMessage v-if="$v.describe.zip.$error || $v.describe.city.$error" class="errmsg">
                                    <v-col cols='0' sm='12'/>
                                </FormInlineMessage>
                                <p class="text-sm-left">ZIP code</p>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <FormInlineMessage v-if="$v.describe.zip.$error" class="errmsg">
                                    Indicate zip code
                                </FormInlineMessage>
                                <FormInlineMessage v-else-if="$v.describe.city.$error" class="errmsg">
                                    <v-col cols='0' sm='12'/>
                                </FormInlineMessage>
                                <v-text-field
                                v-model="describe.zip"
                                outlined
                                dense
                                @blur="$v.describe.zip.$touch()"/>
                            </v-col>
                            <v-col cols="0" sm="1"/>
                            <v-col cols="12" sm="2" class="mt-2 mb-2">
                                <FormInlineMessage v-if="$v.describe.zip.$error || $v.describe.city.$error" class="errmsg">
                                    <v-col cols='0' sm='12'/>
                                </FormInlineMessage>
                                <p class="text-sm-left address_city">City</p>
                            </v-col>
                            <v-col cols="12" sm="4" class="address_city">
                                <FormInlineMessage v-if="$v.describe.city.$error" class="errmsg">
                                    Indicate city
                                </FormInlineMessage>
                                <FormInlineMessage v-else-if="$v.describe.zip.$error" class="errmsg">
                                    <v-col cols='0' sm='12'/>
                                </FormInlineMessage>
                                <v-text-field
                                v-model="describe.city"
                                outlined
                                dense
                                @blur="$v.describe.city.$touch()"/>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="0" sm="1"/>

                    <v-col cols="0" sm="1"/>
                    <v-col cols="12" sm="10" class="mt-n6">
                        <v-row class="address">
                            <v-col cols="12" sm="2" class="mt-2 mb-n9">
                                <FormInlineMessage v-if="$v.phoneValid.$error" class="errmsg">
                                    <v-col cols='0' sm='12'/>
                                </FormInlineMessage>
                                <p class="text-sm-left">Phone</p>
                            </v-col>
                            <v-col cols="12" sm="10">
                                <FormInlineMessage v-if="$v.phoneValid.$error" class="errmsg">
                                    Indicate a valid phone number
                                </FormInlineMessage>
                                <vue-tel-input
                                v-model="phone"
                                defaultCountry="country"
                                ref="phoneComplete"
                                @validate="validatePhone"
                                @blur="$v.phoneValid.$touch()"/>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="0" sm="1"/>

                    <v-col cols="0" sm="1"/>
                    <v-col cols="12" sm="10" v-if="investment_type == 'firm'">
                        <v-row class="address">
                            <v-col cols="12" sm="2" class="mb-n8">
                                <FormInlineMessage v-if="$v.describe.website.$error" class="errmsg">
                                    <v-col cols='0' sm='12'/>
                                </FormInlineMessage>
                                <p class="text-sm-left">Firm website</p>
                            </v-col>
                            <v-col cols="12" sm="10">
                                <FormInlineMessage v-if="$v.describe.website" class="errmsg">
                                    <!-- Provide a valid url -->
                                </FormInlineMessage>
                                <v-text-field
                                v-model="describe.website"
                                outlined
                                dense
                                placeholder="Enter a url of your company website"
                                @blur="$v.describe.website.$touch()"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="0" sm="1"/>

                    <v-row v-if="investment_type == 'firm'" align="center">
                        <v-col cols="12" />
                        <v-col cols="5" />
                        <v-col cols="2" style="position: relative; display: flex; flex-direction: column;" class="align-center justify-center">
                            <v-card-actions class="justify-center">
                                <v-hover v-slot:default="{ hover }">
                                <v-img
                                    v-if="!photoBlock"
                                    max-height="300"
                                    max-width="300"
                                    @click.stop="dialogUp('Photo')"
                                    :src="imagePreviewUrl"
                                >
                                    <v-expand-transition>
                                    <div
                                        v-if="hover"
                                        style="background-color: rgb(81, 77, 122); height: 100%"
                                        class="d-flex transition-fast-in-fast-out v-img--reveal display-1 white--text align-center justify-center"
                                    >
                                        CHANGE YOUR LOGO
                                    </div>
                                    </v-expand-transition>
                                </v-img>
                                </v-hover>
                                <v-btn
                                v-if="photoBlock"
                                sm="4"
                                id="btn-photo"
                                class="white--text"
                                color="#514d7a"
                                height="90"
                                width="200"
                                rounded
                                block
                                :disabled="false"
                                @click.stop="dialogUp('Photo')"
                                >
                                ADD LOGO*
                                </v-btn>
                            </v-card-actions>
                            <p class="blue-grey--text font-weight-thin" align="center" justify="center" style=" width: 200px; display: flex; align-items: center; justify-content: center;">
                                <span v-if="!imagePreviewUrl">Add your firm logo<br /></span> Min 300x300 & Max 3 Mb
                            </p>
                        </v-col>
                    </v-row>
                    
                    <!-- dialog for Photo upload-->
                    <v-dialog v-model="dialogPhoto" max-width="400">
                        <PhotoForm
                            @uploadPhoto="uploadLogo"
                            @closePhoto="dialogDown('Photo')"
                        ></PhotoForm>
                    </v-dialog>
                </v-row>

                <v-row align="center" justify="center" class="pt-2 btn-save">
                    <v-col cols="0" xs="5" sm="8" md="9" lg="10"/>
                    <v-col cols="12" xs="1" sm="1" md="1" lg="1">
                        <v-btn
                        id="btn-photo"
                        class="white--text mb-3"
                        style="font-weight: bold; font-size: 14px"
                        color="#11aec9"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.stop.prevent="saveDescribe()">
                            save
                        </v-btn>
                    </v-col>
                </v-row>
                <br><br>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import AccreditationService from "@/store/services/investor/accreditation.service";
import Var from "@/store/services/var";
import PhotoForm from "@/forms/PhotoForm";

import { validationMixin } from "vuelidate";
// import { , requiredIf,  } from "vuelidate/lib/validators";
// import { required } from "vuelidate/lib/validators";

import {
  minLength,
  maxLength,
  numeric,
  required,
  minValue,
  requiredIf,
  email,
  sameAs,
  url,
} from "vuelidate/lib/validators";

export default {
    components: {
        FormInlineMessage,
        PhotoForm
    },

    props: {
        company_id: Number,
    },

    data() {
        return {
            genders: ["Mr.", "Ms.", "Mrs."],
            countries: [],
            imagePreviewUrl: null,
            dialogPhoto: false,
            photoBlock: true,
            formData: new FormData(),
            describe: {
                firm_name: "",
                gender: "Mr.",
                first_name: "",
                last_name: "",
                country: "",
                street: "",
                zip: "",
                city: "",
                phone: null,
                website: "",
            },
            phoneValid: false,
            phone: null,
            photoBlock: true,
            investment_type: '',
        }
    },

    validations: {
        phoneValid: {
            sameAs: sameAs(() => true),
        },
        describe: {
            required,
            firm_name: {
                // required: requiredIf(function () {
                //     return this.investment_type == "firm";
                // }),
            },
            first_name: { required },
            last_name: { required },
            country: { required },
            street: { required },
            zip: { required },
            city: { required },
            website: {
            url
            },
        },
    },

    methods: {
        dialogUp(element) {
            //console.log('dialogUp :'+element);
            this.$data["dialog" + element] = true;
        },

        dialogDown(element) {
            //console.log("dialogDown :" + element);
            this.$data["dialog" + element] = false;
        },
        validatePhone(params) {
            this.phoneValid = params.valid;
            this.describe.phone = params.number;
        },
        getVariables() {
            Var.getAllCountry().then((res) => {
                this.countries = res.data;
            });
        },

        uploadLogo(chosenImage) {
            // console.log(chosenImage);
            this.imagePreviewUrl = URL.createObjectURL(chosenImage);
            this.formData.append("logo", chosenImage);

            AccreditationService.saveLogo(this.formData)
                .then((res) => {
                this.photoBlock = false;
                this.dialogDown("Photo");
                })
                .catch((e) => {
                //console.error(e);
            });
        },

        retrieveLogo() {
            AccreditationService.getLogo()
                .then((res) => {
                    // console.log("PHOTO", res)
                    this.imagePreviewUrl = res.data;
                    this.photoBlock = false;
                })
                .catch((error) => {
                this.photoBlock = true;
            });
        },

        getTypeOfAccreditation() {
            AccreditationService.getType().then((res) => {
                if (res.data) {
                    if (res.data.firm_or_individual) {
                        this.investment_type = res.data.firm_or_individual;
                    }
                    return;
                }
                this.investment_type = "firm";
            });
        },

        saveDescribe() {
            this.$v.$touch();
            if (this.$v.$error) { return console.log(this.$error); };

            AccreditationService.saveProfile(this.describe).then((res) => {
                if(res.status == 200) {
                    this.$toast.open({
                        message: "Legal representative information saved !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Something went wrong !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },

        getDescribe() {
            AccreditationService.getProfile().then((res) => {
                if (res.data) {
                this.describe.firm_name = res.data.firm_name;
                this.describe.first_name = res.data.legal_first_name;
                this.describe.last_name = res.data.legal_last_name;
                this.describe.country = res.data.country;
                this.describe.gender = res.data.gender;

                let temp = JSON.parse(res.data.address);
                this.describe.street = temp.street;
                this.describe.zip = temp.zip;
                this.describe.city = temp.city;

                this.describe.phone = res.data.phone;
                this.describe.website = res.data.website;

                this.phone = this.describe.phone;
                }
            });
        },
    },

    mounted() {
        this.getTypeOfAccreditation();
        this.getVariables();
        this.getDescribe();
        this.retrieveLogo();
    },
}
</script>
<style scoped>
@media (min-width: 1264px) and (max-width: 2890px) { 
    .btn-save {
        margin-right: 2% !important;
    }
}
@media (min-width: 100px) and (max-width: 599px) { 
    .address {
        /* border: solid red 1px; */
        margin-top: -8% !important;
    }
    .address_city {
        margin-top: -65px !important;
    }
}
.newbold label {
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
  margin-top: 9px;
}
</style>