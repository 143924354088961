<template>
    <v-row align="center" justify="center" class="mar-top-100">
        <v-col cols="10">
            <p class="cyan--text text--darken-1 mt-3 text-sm-left" id="title" v-if="investment_type == 'firm'">Investment Strategy of {{ describeFirmName }}</p>
            <p class="cyan--text text--darken-1 mt-3 text-sm-left" id="title" v-else>Your Investment Strategy</p>

            <v-row>
                <v-col>
                    <span class="float-right" style="color: #ccc;">Required fields <span style="color:#000;">*</span> to save your data</span>
                </v-col>
            </v-row>
            
            <!-- Body -->
            <div id="indistry">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Type of businesses you’re investing in</p>
                <!-- Industry sectors -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="12" md="12" lg="3" class="mt-2 mb-n8">
                        <p class="text-sm-left">Industry sectors*</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage v-if="$v.type.industry.$error" class="errmsg">
                            Select industry sectors
                        </FormInlineMessage>
                        <v-combobox
                            v-model="type.industry"
                            :items="allIndustries"
                            outlined
                            dense
                            multiple
                            small-chips
                            deletable-chips
                            @blur="$v.type.industry.$touch()"
                        />
                    </v-col>
                </v-row>

                <!-- preferred stage of company -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="12" md="12" lg="3" class="mt-2 mb-n8">
                        <p class="text-sm-left">Preferred stages of company*</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage v-if="$v.type.stage.$error" class="errmsg">
                            Select investment stages
                        </FormInlineMessage>
                        <v-combobox
                            v-model="type.stage"
                            :items="allStage"
                            multiple
                            small-chips
                            deletable-chips
                            outlined
                            dense
                            @blur="$v.type.stage.$touch()"
                        />
                    </v-col>
                </v-row>

                <!-- Locations invested in -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="12" md="12" lg="3" class="mt-2 mb-n8">
                        <p class="text-sm-left">Locations invested in*</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage v-if="$v.type.location.$error" class="errmsg">
                            Select investment locations
                        </FormInlineMessage>
                        <v-combobox
                            v-model="type.location"
                            :items="allLocation"
                            multiple
                            small-chips
                            deletable-chips
                            outlined
                            dense
                            @blur="$v.type.location.$touch()"
                        />
                    </v-col>
                </v-row>

                <!-- Locations invested in -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="12" md="12" lg="4">
                        <p class="text-sm-left">U.S. corporate structure required for investment consideration?</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="8" class="d-flex justify-content-around">
                        <v-btn
                            @click="type.usa_structure = true"
                            v-bind:color="type.usa_structure ? 'primary' : ''"
                        >
                            YES
                        </v-btn>

                        <v-btn
                            @click="type.usa_structure = false"
                            v-bind:color="!type.usa_structure ? 'primary' : ''"
                            class=""
                        >
                            NO
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <div id="model" class="mt-13">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Markets invested in</p>
                <!-- Model -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="12" md="12" lg="3" class="mt-2 mb-n8">
                        <p class="text-sm-left">Business Models invested in*</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage v-if="$v.type.model.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <v-combobox
                            v-model="type.model"
                            :items="allModel"
                            outlined
                            dense
                            multiple
                            small-chips
                            deletable-chips
                            @blur="$v.type.model.$touch()"
                        />
                    </v-col>
                </v-row>

                <!-- Market Location -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="12" md="12" lg="3" class="mt-2 mb-n8">
                        <p class="text-sm-left">Market Location*</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage
                            v-if="$v.type.market_location.$error"
                            class="errmsg"
                        >
                            Fill the field
                        </FormInlineMessage>
                        <v-combobox
                            v-model="type.market_location"
                            :items="allLocation"
                            outlined
                            dense
                            multiple
                            small-chips
                            deletable-chips
                            @blur="$v.type.market_location.$touch()"
                        />
                    </v-col>
                </v-row>

                <v-row class="mb-n8">
                    <v-col cols="12" sm="12" md="12" lg="3" class="mt-2 mb-n8">
                        <p class="text-sm-left mt-n2">
                            Min. Addressable Market Size <span class="font-weight-regular">in Million USD</span>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage v-if="$v.type_size.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_size_length_error.error">
                            {{ is_size_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                            outlined
                            dense
                            v-model="type_size"
                            @input="type_size  = formatToString(type_size), $v.type_size.$touch()"
                            @keypress="isNumber($event)"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="3" class="mt-2 mb-n8">
                        <p class="text-sm-left mt-n2">
                            Min. Estimated market growth for the next 3 years*<span
                            class="font-weight-regular"
                        >
                            in %</span
                            >
                        </p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage v-if="$v.type_growth.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_type_growth_length_error.error">
                            {{ is_type_growth_length_error.message }}
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_type_growth_decimal_length_error.error">
                            {{ is_type_growth_decimal_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                            outlined
                            dense
                            prefix="%"
                            v-model="type_growth"
                            @blur="$v.type_growth.$touch()"
                            @keypress="isNumber($event)"
                            @input="type_growth = formatToString(type_growth)"
                        />
                    </v-col>
                </v-row>  
            </div>

            <!-- Save strategy button -->
            <v-row align="center" justify="center" class="pt-2 btn-save">
                <v-col cols="0" xs="5" sm="9" md="9" lg="11"/>
                <v-col cols="12" xs="1" sm="1" md="1" lg="1">
                    <v-btn
                    id="btn-photo"
                    class="white--text mb-3"
                    style="font-weight: bold; font-size: 14px"
                    color="#11aec9"
                    min-width="20"
                    rounded
                    block
                    :disabled="false"
                    @click.stop.prevent="saveStrategy(false)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg> save
                    </v-btn>
                </v-col>
            </v-row>

            <div id="product" class="mt-10">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Products / Services invested in</p>
                <!-- Type of products/services -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="12" md="12" lg="3" class="mt-2 mb-n8">
                        <p class="text-sm-left">Type of products/services*</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage
                            v-if="$v.product.service_type.$error"
                            class="errmsg"
                        >
                            Select a product or service
                        </FormInlineMessage>
                        <v-combobox
                            v-model="product.service_type"
                            :items="allPrimary"
                            outlined
                            dense
                            multiple
                            small-chips
                            deletable-chips
                        />
                    </v-col>
                </v-row>

                <!-- Technology focus -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="12" md="12" lg="3" class="mt-2 mb-n8">
                        <p class="text-sm-left">Technology focus*</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage
                            v-if="$v.product.technology_focus.$error"
                            class="errmsg"
                        >
                            Select an option from the dropdown
                        </FormInlineMessage>
                        <v-combobox
                            v-model="product.technology_focus"
                            :items="allTechList"
                            outlined
                            dense
                            small-chips
                            deletable-chips
                        />
                    </v-col>
                </v-row>

                <v-row class="mb-n8 mt-n10"
                    v-if="
                        this.product.technology_focus == 'Both Tech and non-Tech' ||
                        this.product.technology_focus == 'Only Tech'
                    "
                >
                    <v-col cols="12">
                        <p class="text-sm-left">
                            Which Technology ?<br />
                            <span class="blue-grey--text text--lighten-4 thin"
                                >Do you have a focus on specific technologies and if yes, which
                                ones?</span
                            >
                        </p>
                    </v-col>
                    <v-col cols="12" sm="12" class="mt-n8">
                        <v-textarea
                            v-model="product.which_technology"
                            outlined
                            rows="2"
                            dense
                        />
                    </v-col>
                </v-row>

                <!-- With R&D -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="12" md="12" lg="3" class="mt-2 mb-n8">
                        <p class="text-sm-left">With R&D*</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage
                            v-if="$v.product.rd_focus.$error"
                            class="errmsg"
                        >
                            Select an option from the dropdown
                        </FormInlineMessage>
                        <v-combobox
                            v-model="product.rd_focus"
                            :items="optionsList"
                            outlined
                            dense
                            small-chips
                            deletable-chips
                            @blur="$v.product.rd_focus.$touch()"
                        />
                    </v-col>
                </v-row>

                <!-- With IP rights or Patents* -->
                <v-row class="mb-n8">
                    <v-col cols="12" sm="12" md="12" lg="3" class="mt-2 mb-n8">
                        <p class="text-sm-left">With IP rights or Patents*</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage
                            v-if="$v.product.patent_focus.$error"
                            class="errmsg"
                        >
                            Select an option from the dropdown
                        </FormInlineMessage>
                        <v-combobox
                            v-model="product.patent_focus"
                            :items="optionsList"
                            outlined
                            dense
                            small-chips
                            deletable-chips
                            @blur="$v.product.patent_focus.$touch()"
                        />
                    </v-col>
                </v-row>

                <!-- With R&D -->
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="3" class="mb-n8">
                        <p class="text-sm-left pt-2">
                            Min. Monthly Recurring Revenue<span class="font-weight-regular">
                            in USD</span
                            >
                        </p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9">
                        <FormInlineMessage
                            v-if="$v.product_monthly_revenue.$error"
                            class="errmsg"
                        >
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_product_monthly_revenue_length_error.error">
                            {{ is_product_monthly_revenue_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                            outlined
                            dense
                            placeholder="200000"
                            prefix="$"
                            v-model="product_monthly_revenue"
                            @input="product_monthly_revenue = formatToString(product_monthly_revenue), $v.product_monthly_revenue.$touch()"
                            @keypress="isNumber($event)"
                        />
                    </v-col>
                </v-row>     
            </div>

             <!-- Save Product button -->
            <v-row align="center" justify="center" class="pt-2 btn-save">
                <v-col cols="0" xs="5" sm="9" md="9" lg="11"/>
                <v-col cols="12" xs="1" sm="1" md="1" lg="1">
                    <v-btn
                    id="btn-photo"
                    class="white--text mb-3"
                    style="font-weight: bold; font-size: 14px"
                    color="#11aec9"
                    min-width="20"
                    rounded
                    block
                    :disabled="false"
                    @click.stop.prevent="saveProduct(false)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg> save
                    </v-btn>
                </v-col>
            </v-row>


            <div id="know-how" class="mt-10">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Investment Stage</p>

                <div class="mt-n5">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-checkbox
                                @change="changeCheckboxValue('seed_stage')"
                                v-model="model.seed_stage"
                                :label="stages[0] && stages[0].label"
                                class="mybold"
                            ></v-checkbox>
                        </v-col>

                        <p class="text-sm-left font-weight-thin ml-8 mt-2">
                            {{ stages[0] && stages[0].description }}
                        </p>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-checkbox
                                @change="changeCheckboxValue('series_a')"
                                v-model="model.series_a"
                                :label="stages[1] && stages[1].label"
                                class="mt-2 mybold"
                            ></v-checkbox>
                        </v-col>
                        <p class="text-sm-left font-weight-thin ml-8 mt-2">
                            {{ stages[1] && stages[1].description }}
                        </p>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-checkbox
                                @change="changeCheckboxValue('series_b')"
                                v-model="model.series_b"
                                :label="stages[2] && stages[2].label"
                                class="mt-2 mybold"
                            ></v-checkbox>
                        </v-col>
                        <p class="text-sm-left font-weight-thin ml-8 mt-2">
                            {{ stages[2] && stages[2].description }}
                        </p>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-checkbox
                                @change="changeCheckboxValue('later_stage')"
                                v-model="model.later_stage"
                                :label="stages[3] && stages[3].label"
                                class="mt-2 mybold"
                            ></v-checkbox>
                        </v-col>
                        <p class="text-sm-left font-weight-thin ml-8 mt-2">
                            {{ stages[3] && stages[3].description }}
                        </p>
                    </v-row>
                </div>
            </div>

            <div id="know-how" class="mt-6">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Investment vehicles used</p>
                <p class="text-sm-left blue-grey--text text--lighten-2 font-weight-thin mt-n5">Choose at least one of the following choices</p>

                <v-row class="mb-5">
                    <v-col cols="12">
                        <v-checkbox
                        v-model="modelVehicule.equity"
                        @change="changeCheckboxValueVehicule('equity')"
                        label="Equity (priced rounded)"
                        class="mt-n3 newbold"/>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                        v-model="modelVehicule.convertible_note"
                        @change="changeCheckboxValueVehicule('convertible_note')"
                        label="Convertible note"
                        class="mt-n3 newbold"/>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                        v-model="modelVehicule.safe"
                        @change="changeCheckboxValueVehicule('safe')"
                        label="SAFE"
                        class="mt-n3 newbold"/>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                        v-model="modelVehicule.any_option"
                        @change="changeCheckboxValueVehicule('any_option')"
                        label="Open to any option"
                        class="mt-n3 newbold"/>
                    </v-col>
                </v-row>  
            </div>

            <div id="know-how" class="mt-10">
                <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">
                    Do you invest only in specific founding team profile ?
                </p>
                <p class="text-sm-left blue-grey--text text--lighten-2 font-weight-thin mt-n5 mb-5">
                    You may require a specific founding team profile for investment
                    consideration. If not, then select "NO"
                </p>

                <v-row class="mt-n3 mb-4">
                    <v-col cols="" sm="3" md="3" lg="3" class="mt-2">
                        <p class="text-sm-left">Female founder</p>
                    </v-col>

                    <v-col cols="" sm="5" md="5" lg="3" class="team_profil_input">
                        <v-btn
                            @click="specific_profile.female_founder = true"
                            v-bind:color="specific_profile.female_founder ? 'primary' : ''"
                            class="mr-6"
                        >
                            YES
                        </v-btn>

                        <v-btn
                            @click="specific_profile.female_founder = false"
                            v-bind:color="!specific_profile.female_founder ? 'primary' : ''"
                        >
                            NO
                        </v-btn>
                    </v-col>
                    <v-col cols="1" />
                </v-row>

                <v-row class="mt-n3 mb-4">
                    <v-col cols="" sm="3" md="3" lg="3" class="mt-2">
                        <p class="text-sm-left">Founder of color</p>
                    </v-col>

                    <v-col cols="" sm="5" md="5" lg="3" class="team_profil_input">
                        <v-btn
                            @click="specific_profile.founder_of_color = true"
                            v-bind:color="specific_profile.founder_of_color ? 'primary' : ''"
                            class="mr-6"
                        >
                            YES
                        </v-btn>

                        <v-btn
                            @click="specific_profile.founder_of_color = false"
                            v-bind:color="!specific_profile.founder_of_color ? 'primary' : ''"
                        >
                            NO
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="mt-n3 mb-4 team_profil">
                    <v-col cols="" sm="3" md="3" lg="3" class="mt-2">
                        <p class="text-sm-left">LGBTQ founder</p>
                    </v-col>

                    <v-col cols="" sm="5" md="5" lg="3" class="team_profil_input">
                        <v-btn
                            @click="specific_profile.lgbtq_founder = true"
                            v-bind:color="specific_profile.lgbtq_founder ? 'primary' : ''"
                            class="mr-6"
                        >
                            YES
                        </v-btn>

                        <v-btn
                            @click="specific_profile.lgbtq_founder = false"
                            v-bind:color="!specific_profile.lgbtq_founder ? 'primary' : ''"
                        >
                            NO
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <!-- Save button -->
            <v-row align="center" justify="center" class="pt-2 btn-save">
                <v-col cols="0" xs="5" sm="9" md="9" lg="11"/> 
                <v-col cols="12" xs="1" sm="1" md="1" lg="1">
                    <v-btn
                    id="btn-photo"
                    class="white--text mb-3"
                    style="font-weight: bold; font-size: 14px"
                    color="#11aec9"
                    min-width="20"
                    rounded
                    block
                    :disabled="false"
                    @click.stop.prevent="saveVehicle()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg> save
                    </v-btn>
                </v-col>
            </v-row>
            <br><br><br><br>
        </v-col>
    </v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";
import AllVar from "@/store/services/var";
import InvestmentService from "@/store/services/investor/investment.service";
import AccreditationService from "@/store/services/investor/accreditation.service";
import { myMixins } from "@/mixins/mixins.js";

export default {
    mixins: [myMixins],
    components: {
        FormInlineMessage,
    },

    data() {
        return {
            stages: [],
            allIndustries: [],
            allStages: [],
            allLocation: [],
            allModel: [],
            allStage: [],
            type: {
                industry: [],
                stage: [],
                model: [],
                location: [],
                market_location: [],
                // size: "",
                // growth: "",
                usa_structure: false,
            },
            type_size: "",
            type_growth: "",
            product_monthly_revenue: "",
            allTechList: ["Both Tech and non-Tech", "Only Tech", "Only non-Tech"],
            allPrimary: [],
            optionsList: ["No Preference", "Yes", "No"],
            product: {
                service_type: [],
                technology_focus: "",
                which_technology: "",
                rd_focus: "",
                patent_focus: "",
                monthly_revenue: "",
            },
            equity: false,
            convertible: false,
            safe: false,
            any: false,

            vehicle_array: [],
            specific_profile: {
                female_founder: false,
                founder_of_color: false,
                lgbtq_founder: false,
            },
            model: {
                seed_stage: false,
                series_a: false,
                series_b: false,
                later_stage: false,
            },
            investment: {
                stage: [],
            },
            modelVehicule: {
                equity: false,
                convertible_note: false,
                safe: false,
                any_option: false,
            },
            describeFirmName: "",
            investment_type: ""
        }
    },

    validations: {
        type: {
            required,
            industry: { required },
            stage: { required },
            model: { required },
            location: { required },
            market_location: { required },
            // growth: {
            //     required,
            //     minValue: minValue(0),
            // },
        },
        type_size: { required },
        type_growth: { 
            required
        },
        product: {
            required,
            service_type: { required },
            technology_focus: { required },
            rd_focus: { required },
            patent_focus: { required },
            monthly_revenue: { required },
           
        },
        product_monthly_revenue: { required },
        specific_profile: { required },
        investment: {
            required,
            stage: { required },
        },
    },

    methods: {
        changeCheckboxValue(value) {
            if (this.investment.stage.includes(value)) {
                this.investment.stage.splice(this.investment.stage.indexOf(value), 1);
            } else {
                this.investment.stage.push(value);
            }
        },
        changeCheckboxValueVehicule(value) {
            if (this.vehicle_array.includes(value)) {
                this.vehicle_array.splice(this.vehicle_array.indexOf(value), 1);
            } else {
                this.vehicle_array.push(value);
            }
        },
        getStrategy() {
            InvestmentService.getStrategy()
                .then((res) => {
                this.type.industry = res.data.sectors
                this.type.stage    = res.data.preferred_stages
                this.type.location = res.data.locations_invested
                this.type.market_location = res.data.market_locations
                this.type.usa_structure   = res.data.usa_corp_structure
                this.type.model  = res.data.business_models
                this.type_growth = res.data.min_market_growth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.type_size = res.data.min_market_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            });
        },

        saveStrategy(is_outcall) {
            this.$v.type_size.$touch();
            this.$v.type.$touch();
            if (
                this.$v.type.$error ||
                this.$v.type_size.$error ||
                this.is_size_length_error.error ||
                this.is_type_growth_length_error.error ||
                this.is_type_growth_decimal_length_error.error
            ) {
                return this.$toast.open({
                    message: "<b>Markets invested in</b>: Please fill all required fields or correct numbers or amounts having more than 15 digits before saving.",
                    type: "error",
                    position: "top-right",
                });
            }
            this.type_size = this.formatToInt(this.type_size);
            this.type_growth = this.formatToInt(this.type_growth);

            this.type.size = this.type_size;
            this.type.growth = this.type_growth;

            InvestmentService.saveStrategy(JSON.stringify(this.type)).then((res) => {
                if(res.status == 200) {
                    if (!is_outcall) {
                        this.$toast.open({
                            message: "Investment Strategy information saved successfully !",
                            type: 'success',
                            position: 'top-right'
                        });
                    }
                } else {
                    this.$toast.open({
                        message: "Something went wrong !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });

            this.type_size = this.type_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_growth = this.type_growth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        getVariables() {
            let that = this;

            AllVar.getAllCountry().then((res) => {
                that.allCountries = res.data;
            });

            AllVar.getAllIndustries().then((res) => {
                that.allIndustries = res.data;
            });

            AllVar.getAllStageOfYourCompanies().then((res) => {
                that.allStage = res.data;
            });

            AllVar.getAllLocations().then((res) => {
                that.allLocation = res.data;
            });

            AllVar.getAllBusinessModel().then((res) => {
                that.allModel = res.data;
            });
            
            AllVar.getAllTypeOfOffers().then((res) => { 
                that.allPrimary = res.data; 
            });
        },

        saveProduct() {
            this.$v.product_monthly_revenue.$touch();
            this.$v.product.technology_focus.$touch();
            this.$v.product.rd_focus.$touch();
            this.$v.product.patent_focus.$touch();
            if (
                this.$v.product.service_type.$error ||
                this.$v.product.technology_focus.$error ||
                this.$v.product.rd_focus.$error ||
                this.$v.product.patent_focus.$error ||
                this.$v.product_monthly_revenue.$error ||
                this.is_product_monthly_revenue_length_error.error
            ) {
                return this.$toast.open({
                    message: "<b>Products</b>: Please fill all required fields or correct numbers or amounts having more than 15 digits before saving.",
                    type: "error",
                    position: "top-right",
                });
            }
            this.product_monthly_revenue = this.formatToInt(this.product_monthly_revenue);

            this.product.monthly_revenue = this.product_monthly_revenue;

            InvestmentService.saveProduct(JSON.stringify(this.product)).then((res) => {
                if(res.status == 200) {
                    this.$toast.open({
                        message: "Investment Strategy information saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Something went wrong !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });

            this.product_monthly_revenue = this.product_monthly_revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        getProduct() {
            InvestmentService.getProduct()
                .then((res) => {
                    this.product.service_type = res.data.product_types;
                    this.product.technology_focus = res.data.technology_focus;
                    this.product.which_technology = res.data.technology_type;
                    this.product.rd_focus = res.data.rd_focus;
                    this.product.patent_focus = res.data.patent_focus;

                    this.product_monthly_revenue = res.data.min_monthly_revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            });
        },

        getStage() {
            InvestmentService.getStage()
                .then((res) => {
                    res.data.stage.forEach((entry) => {
                    if (entry == "seed_stage") {
                        this.model.seed_stage = true;
                    } else if (entry == "series_a") {
                        this.model.series_a = true;
                    } else if (entry == "series_b") {
                        this.model.series_b = true;
                    } else if (entry == "later_stage") {
                        this.model.later_stage = true;
                    }
                });
                this.investment.stage = res.data.stage;
            });
        },

        saveStage(is_outcall) {
            InvestmentService.saveStage(JSON.stringify(this.investment)).then((res) => {
                if(res.status == 200) {
                    if (!is_outcall) {
                        this.$toast.open({
                            message: "Investment Strategy information saved successfully !",
                            type: 'success',
                            position: 'top-right'
                        });
                    }
                } else {
                    this.$toast.open({
                        message: "Something went wrong !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },

        saveVehicle() {
            AccreditationService.saveVehicle(JSON.stringify({vehicle_array: this.vehicle_array, specific_profile: this.specific_profile})).then((res) => {
                if(res.status == 200) {
                    this.saveInvestments();
                } else {
                    this.$toast.open({
                        message: "Something went wrong !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        },

        getVehicle() {
            AccreditationService.getVehicle()
                .then((res) => {
                res.data.investment_vehicles.forEach((entry) => {
                    if (entry == "equity") {
                        this.modelVehicule.equity = true;
                    } else if (entry == "convertible_note") {
                        this.modelVehicule.convertible_note = true;
                    } else if (entry == "safe") {
                        this.modelVehicule.safe = true;
                    } else if (entry == "any_option") {
                        this.modelVehicule.any_option = true;
                    }
                });
                this.vehicle_array = res.data.investment_vehicles;
                let vehicle = res.data;
                this.specific_profile.female_founder   = vehicle.female;
                this.specific_profile.founder_of_color = vehicle.color;
                this.specific_profile.lgbtq_founder    = vehicle.lgbtq;
            });
        },

        saveInvestments() {
            this.saveStage(true);
            this.saveProduct(true);
            this.saveStrategy(true);
        },

        getDescribe() {
            AccreditationService.getProfile().then((res) => {
                if (res.data) {
                    this.describeFirmName = res.data.firm_name;
                }
            });
        },

        getTypeOfAccreditation() {
            AccreditationService.getType().then((res) => {
                if (res.data) {
                    if (res.data.firm_or_individual) {
                        this.investment_type = res.data.firm_or_individual;
                    }
                    return;
                }
                this.investment_type = "firm";
            });
        }, 
    },

    created() {
        this.getTypeOfAccreditation();
        this.getDescribe();
        this.getVariables();
        this.getStrategy();
        this.getProduct();
        this.getStage();
        this.getVehicle();
        AllVar.getStages().then((res) => { this.stages = Object.values(res.data); })
    }
}
</script>
<style scoped>
@media (min-width: 1264px) and (max-width: 1275px) { 
    .btn-save {
        margin-right: 6% !important;
    }
}
@media (min-width: 600px) and (max-width: 735px) { 
    .btn-save {
        margin-right: 2% !important;
    }
}
@media (min-width: 100px) and (max-width: 575px) { 
  
    .team_profil_input {
        display: flex;
        justify-content: space-around;
        margin-top: -29px !important;
    }
}
@media (min-width: 192px) and (max-width: 1024px) { 
  
    .team_profil_input {
        display: flex !important;
        justify-content: space-around !important;
    }
}
</style>
