<template>
  <v-dialog v-model="active_dialog" max-width="200px" style="background-color: transparent;">
    <v-card class="justify-content-center align-items-center" min-height="200px" style="">
      <img src="../assets/img/Spinner-1s-200px.gif" alt="loader" height="140">
      <p class="mt" v-if="type == 'update'">Updating...</p>
      <p class="mt" v-else>Loading...</p>
    </v-card>
  </v-dialog>
</template>

<script>
import Company from "@/store/services/admin/company";
import InvestmentService from "@/store/services/investor/investment.service";

export default {
  props: { 
    type: String, 
  },

  data() {
    return {
      active_dialog: true
    };
  },
};
</script>
