import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      Authorization: "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token")
    },
};

function saveSkills(payload) {
  //console.log(payload);
  return axios.post(`${url}/user/skills`, payload)
}

function saveAbilities(payload) {  
  return axios.post(`${url}/user/abilities`, payload)
}

function saveLanguages(payload) {  
  return axios.post(`${url}/user/languages`, payload)
}

function getSkills() {
  //console.log();
  return axios.get(`${url}/user/skills`);
}

function getAbilities() {
  //console.log();
  return axios.get(`${url}/user/abilities`);
}

function getLanguages() {
  return axios.get(`${url}/user/languages`);
}

function destroyLanguage(name) {
  
  return axios.delete(`${url}/user/languages`, {data:name,options });
}

function destroyAbility(name) {
 
  return axios.delete(`${url}/user/abilities`,{data:name,options });
}

function destroySkill(name) { 

  return axios.delete(`${url}/user/skills`,{data:name,options });
}

export default {  
  saveSkills,
  getSkills,
  destroySkill,
  saveAbilities,
  getAbilities, 
  destroyAbility,  
  saveLanguages,
  getLanguages,
  destroyLanguage
};
