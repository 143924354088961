<template>
  <div>
    <div v-if="!loading">
      <v-sheet height="100%" class="mx-auto mt-8">
        <v-row align="center" justify="center" no-gutters class="pt-8">
          <v-col cols="1" />

          <v-col cols="10">
            <!-- Industry -->
            <v-row no-gutters>
              <v-col cols="3">
                <p class="text-sm-left bold">Industry</p>
              </v-col>

              <v-col>
                <FormInlineMessage v-if="$v.type.industry.$error" class="errmsg">
                  Select your industry's sector
                </FormInlineMessage>
                <v-combobox
                  v-model="type.industry"
                  :items="allIndustries"
                  outlined
                  dense
                  multiple
                  small-chips
                  deletable-chips
                  @blur="$v.type.industry.$touch()"
                />
              </v-col>
              <v-col cols="1" />
            </v-row>

            <!-- Stage -->
            <v-row no-gutters>
              <v-col cols="3">
                <p class="text-sm-left bold">Stage of your company</p>
              </v-col>

              <v-col>
                <FormInlineMessage v-if="$v.type.stage.$error" class="errmsg">
                  Select the stage of your compagny
                </FormInlineMessage>
                <v-select v-model="type.stage" :items="allStage" outlined dense />
              </v-col>
              <v-col cols="1" />
            </v-row>
          </v-col>

          <v-col cols="1" />
        </v-row>
      </v-sheet>

      <v-sheet height="100%" class="mx-auto mt-8">
        <v-row align="center" justify="center" class="pt-2">
          <v-col cols="1" />

          <v-col cols="10">
            <p class="cyan--text text--darken-1 text-sm-left" id="title">
              Market
            </p>

            <v-col />

            <!-- Model -->
            <v-row no-gutters>
              <v-col cols="3">
                <p class="text-sm-left pt-2">Business Model</p>
              </v-col>

              <v-col>
                <FormInlineMessage v-if="$v.type.model.$error" class="errmsg">
                  Fill the field
                </FormInlineMessage>
                <v-select v-model="type.model" :items="allModel" outlined dense />
              </v-col>
              <v-col cols="1" />
            </v-row>

            <!-- Location -->
            <v-row no-gutters>
              <v-col cols="3">
                <p class="text-sm-left pt-2">Market Location</p>
              </v-col>

              <v-col>
                <FormInlineMessage v-if="$v.type.location.$error" class="errmsg">
                  Fill the field
                </FormInlineMessage>
                <v-combobox
                  v-model="type.location"
                  :items="allLocation"
                  outlined
                  dense
                  multiple
                  small-chips
                  deletable-chips
                  @blur="$v.type.location.$touch()"
                />
              </v-col>
              <v-col cols="1" />
            </v-row>

            <!-- Maturity -->
            <v-row no-gutters>
              <v-col cols="3">
                <p class="text-sm-left pt-2">Stage of Market Maturity</p>
              </v-col>

              <v-col>
                <FormInlineMessage v-if="$v.type.maturity.$error" class="errmsg">
                  Fill the field
                </FormInlineMessage>
                <v-select
                  v-model="type.maturity"
                  :items="allMaturity"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="1" />
            </v-row>

            <!-- Size -->
            <v-row no-gutters>
              <v-col cols="3">
                <p class="text-sm-left pt-2">
                  Addressable Market Size<span class="font-weight-regular">
                    in Million USD</span
                  >
                </p>
              </v-col>

              <v-col>
                <FormInlineMessage v-if="$v.type_size.$error" class="errmsg">
                  Fill the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_size_length_error.error" class="errmsg">
                  {{ is_size_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  outlined
                  dense
                  v-model="type_size"
                  @input="type_size = formatToString(type_size), $v.type_size.$touch()"
                  @keypress="isNumber($event)"
                />
              </v-col>
              <v-col cols="1" />
            </v-row>

            <!-- Growth -->
            <v-row no-gutters>
              <v-col cols="3">
                <p class="text-sm-left">
                  Estimated market growth for the next 3 years<span
                    class="font-weight-regular"
                  >
                    in %</span
                  >
                </p>
              </v-col>

              <v-col>
                <FormInlineMessage v-if="$v.type_growth.$error" class="errmsg">
                  Fill the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_type_growth_length_error.error">
                    {{ is_type_growth_length_error.message }}
                </FormInlineMessage>
                <FormInlineMessage v-if="is_type_growth_decimal_length_error.error">
                    {{ is_type_growth_decimal_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  outlined
                  dense
                  prefix="%"
                  v-model="type_growth"
                  @input="type_growth = formatToString(type_growth), $v.type_growth.$touch()"
                  @keypress="isNumber($event)"
                />
              </v-col>
              <v-col cols="1" />
            </v-row>

            <!-- Solve -->
            <v-row no-gutters>
              <v-col cols="3">
                <p class="text-sm-left pt-5">
                  The need that your product or service is solving
                </p>
              </v-col>

              <v-col>
                <FormInlineMessage v-if="$v.type.solve.$error" class="errmsg">
                  Fill the field
                </FormInlineMessage>
                <v-textarea
                  outlined
                  rows="2"
                  dense
                  v-model="type.solve"
                  @blur="$v.type.solve.$touch()"
                />
              </v-col>
              <v-col cols="1" />
            </v-row>

            <!-- Competitors -->
            <v-row no-gutters>
              <v-col cols="3">
                <p class="text-sm-left pt-5">Who are your competitors ?</p>
              </v-col>

              <v-col>
                <FormInlineMessage
                  v-if="$v.type.competitors.$error"
                  class="errmsg"
                >
                  Fill the field
                </FormInlineMessage>
                <v-textarea
                  outlined
                  rows="2"
                  dense
                  v-model="type.competitors"
                  @blur="$v.type.competitors.$touch()"
                />
              </v-col>
              <v-col cols="1" />
            </v-row>

            <!-- Barriers -->
            <v-row no-gutters>
              <v-col cols="3">
                <p class="text-sm-left pt-5">What are the entry barriers ?</p>
              </v-col>

              <v-col>
                <FormInlineMessage v-if="$v.type.barriers.$error" class="errmsg">
                  Fill the field
                </FormInlineMessage>
                <v-textarea
                  outlined
                  rows="2"
                  dense
                  v-model="type.barriers"
                  @blur="$v.type.barriers.$touch()"
                />
              </v-col>
              <v-col cols="1" />
            </v-row>
          </v-col>

          <v-col cols="1" />
        </v-row>
      </v-sheet>

      <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
        <v-row align="center" justify="center">
          <v-col cols="10" />
          <v-col cols="12" md="12">
            <v-icon
              large
              color="#00aac3"
              right
              center
              @click.prevent.stop="saveType"
            >
              mdi-arrow-down-circle
            </v-icon>
          </v-col>
        </v-row>
      </v-sheet>
    </div>

    <div v-else>
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import AllVar from "@/store/services/var";
import BusinessService from "@/store/services/business.service";
import FormInlineMessage from "@/components/FormInlineMessage";
import { required } from "vuelidate/lib/validators";
import { myMixins } from "@/mixins/mixins.js";

export default {
  mixins: [myMixins],
  components: {
    FormInlineMessage,
  },

  props: {
    company_id: Number,
  },

  data() {
    return {
      currentBlock: true,
      allIndustries: [],
      allStage: [],
      allModel: [],
      allLocation: [],
      allMaturity: [],
      type: {
        company_id: "",
        industry: [],
        stage: "",
        model: "",
        location: [],
        maturity: "",
        // growth: "",
        solve: "",
        competitors: "",
        barriers: "",
      },
      type_size: "",
      type_growth: "",
      loading: false
    };
  },

  validations: {
    type: {
      required,
      industry: { required },
      stage: { required },
      model: { required },
      location: { required },
      maturity: { required },
      // growth: {
      //   required,
      //   minValue: minValue(0),
      // },
      solve: { required },
      competitors: { required },
      barriers: { required },
    },
    type_size: { required },
    type_growth: { required }
  },

  methods: {
    saveType() {
      this.$v.$touch();
      if (this.$v.$error || this.is_size_length_error || this.is_type_growth_length_error || this.is_type_growth_decimal_length_error) {
        return;
      }
      this.type_size = this.formatToInt(this.type_size);
      this.type_growth = this.formatToInt(this.type_growth);

      this.type.size = this.type_size;
      this.type.growth = this.type_growth;

      this.type.company_id = this.company_id;
      BusinessService.saveType(this.type);
      this.type_size = this.type_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.type_growth = this.type_growth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.currentBlock = false;
      this.$emit("block2");
    },

    getType(company_id) {
      BusinessService.getType(company_id)
        .then((res) => {
          if (res.data) {
            this.type = res.data;
            this.type_size = this.type.size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_growth = this.type.growth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type.industry = this.type.industry.split(",");
          }
        })
        .catch((e) => {});
    },

    getVariables() {
      AllVar.getAllIndustryVariables().then((res) => {
        this.allIndustries = res.data.allIndustries;
        this.allStage = res.data.allStageOfYourCompany;
        this.allLocation = res.data.allLocations;
        this.allModel = res.data.allBusinessModel;
        this.allMaturity = res.data.allStageOfMarketMaturities;
      })
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  mounted() {
    this.getVariables();
    this.getType(this.company_id);
  },
};
</script>

<style>
.errmsg {
  color: red;
}
</style>
