<template>
  <div class="body">
    <Header class="d-none d-md-flex"/>
    <HeaderPortable class="d-flex d-md-none"/>
    <v-row>
      <v-col cols="0" sm="0" md="2" lg="2" class="d-none d-md-flex">
        <Menu />
      </v-col>

      <v-col cols="10" sm="11" md="9" lg="9" class="maskContainer">
        <div class="filter">
          <p class="text-align-center title">LIST OF COMPANIES</p>
          <v-row class="d-flex justify-content-between">
            <v-col cols="12" sm="4" md="4" lg="4">
              <div>
                <p class="text-sm-left">Status</p>
                <v-combobox
                  :items="userStatus"
                  outlined
                  dense
                  v-model="searchData.role_id"
                  multiple
                  small-chips
                  deletable-chips
                  placeholder="All"
                />
              </div>
              <div>
                <p class="text-sm-left">Search</p>
                <v-text-field
                  class="mt-n2"
                  v-model="searchData.user_name"
                  outlined
                  dense
                />
              </div>
            </v-col>

            <v-col cols="12" sm="4" md="4" lg="4">
              <p class="text-sm-left">Application Created</p>
              <div>
                <v-menu
                  ref="menuBegin"
                  :close-on-content-click="false"
                  :return-value.sync="searchData.date_from"
                  offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      height="40"
                      outlined
                      v-model="searchData.date_from"
                      placeholder="From"
                      readonly
                      v-bind="attrs"
                      dense
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="searchData.date_from"
                    no-title
                    scrollable
                    type="date">

                    <v-spacer/>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuBegin.isActive = false">
                      Cancel
                    </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuBegin.save(searchData.date_from)">
                        OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>

              <div>
                <v-menu
                  ref="menuEnd"
                  :close-on-content-click="false"
                  :return-value.sync="searchData.date_to"
                  offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      height="40"
                      outlined
                      v-model="searchData.date_to"
                      placeholder="To"
                      readonly
                      v-bind="attrs"
                      dense
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="searchData.date_to"
                    no-title
                    scrollable
                    type="date">

                    <v-spacer/>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuEnd.isActive = false">
                      Cancel
                    </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuEnd.save(searchData.date_to)">
                        OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </v-col>

            <v-col cols="12" sm="4" md="4" lg="4">
              <div>
                <p class="text-sm-left">User ID</p>
                <v-text-field
                  class="mt-n2"
                  v-model="searchData.user_id"
                  placeholder="User ID"
                  outlined
                  dense
                  @keypress="isNumber($event)"
                />
              </div>
              <div>
                <p class="text-sm-left">Location</p>
                <v-text-field
                  placeholder="Enter a location"
                  class="mt-n2"
                  v-model="searchData.location"
                  outlined
                  dense
                />
              </div>
              <div>
                <p class="text-sm-left">Continent</p>
                <v-combobox
                  class="mt-n2"
                  :items="allLocation"
                  outlined
                  dense
                  v-model="searchData.continent"
                  deletable-chips
                  placeholder="Select a continent"
                />
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-content-center mt-n4 mb-2">
            <button class="btn btn-primary mr-2" @click="getCampaniesBySearch()">
              <v-icon aria-hidden="false" color="#fff" size="15">
                mdi-filter
              </v-icon>
              Filter
            </button>
            <export-excel
                class   = "btn btn-primary"
                :data   = "companies"
                :fields = "company_fields"
                worksheet = "Companies"
                title ="LIST OF COMPANIES"
                name    = "list_companies.xls">
              Export
            </export-excel>
          </v-row>
        </div>

        <LoaderPop v-if="loaderAlert" type="update" />

        <v-app id="inspire">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="companies"
            :single-select="singleSelect"
            item-key="id"
            show-select
          >

            <template v-slot:item.actions="{ item }">
              <span class="list" @mouseover="showActionsList(item)">>>></span>
              <div class="actionsList" :class="actionsList == item.id ? 'active' : ''">
                <div class="actions" @mouseleave="hideActionsList()">
                  <h5 class="actionsTitle">Action</h5>
                  <div class="download_link">
                    <a v-if="item.initial_application_pdf" outlined :href="pdf_path + item.initial_application_pdf" target="_blank" download>Download Initial Application</a>
                    <a v-else class="disabled">Download Initial Application</a>
                    <a v-if="item.last_application_pdf" outlined :href="pdf_path + item.last_application_pdf" target="_blank" download>Download Updated Application</a>
                    <a v-else class="disabled">Download Updated Application</a>
                    <a v-if="item.invoice_path" outlined :href="pdf_path + item.invoice_path" target="_blank" download>Download Application Invoice</a>
                    <a v-else class="disabled">Download Application Invoice</a>
                    <a @click="submitLogin(item.user_email)">Login to User Dashboard</a>
                    <a href="/status" @click.prevent.stop="confirmApplication(item.user_id)">Confirm Application</a>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-app>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Company from "@/store/services/admin/company";
import Header from '@/components/admin/AdminHeader.vue';
import HeaderPortable from '@/components/admin/AdminHeadePortable.vue';
import Menu from '@/components/admin/AdminLeftMenu.vue';

import LoaderPop from "@/components/LoaderPop.vue";
import { myMixins } from "@/mixins/mixins.js";
import AllVar from "@/store/services/var";

export default {
  mixins: [myMixins],
  components: {
    Header,
    HeaderPortable,
    Menu,
    LoaderPop
  },
  data() {
    return {
      pdf_path: process.env.VUE_APP_API_BASE_URL_PDF,
      profile: localStorage.profile,
      actionsList: false,
      companies: [],
      loaderAlert: false,
      userStatus: ['c-0', 'c-1', 'c-2', 'c-3'],
      allLocation: [],

      singleSelect: false,
      selected: [],
      headers: [
        { text: 'ID', align: 'start', value: 'id', sortable: false },
        { text: 'User-Name', value: 'user_name', sortable: false }, // value: 'last_name'
        { text: 'Company-Name', value: 'company_name', sortable: false },
        { text: 'City', value: 'city', sortable: false },
        { text: 'Country', value: 'country' },
        { text: 'Industry', value: 'industry', sortable: false },
        { text: 'Needed Funds', value: 'needs', align: 'right', },
        { text: 'Application Date', value: 'date_incorporation' },
        { text: 'Application Status', value: 'user_role' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      searchData: {
        role_id: null,
        user_name: null,
        date_from: null,
        date_to: null,
        user_id: null,
        location: null,
        continent: null,
      },

      company_fields: {
        'ID': 'id',
        'User-Name': 'user_name',
        'Company-Name': 'company_name',
        'City': 'city',
        'Country' : 'country',
        'Industry' : 'industry',
        'Needed Funds' : 'needs',
        'Application Date' : 'date_incorporation',
        'Application Status' : 'user_role',
      },
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
    }
  },

  mounted() {
    this.getCompanies();
    AllVar.getAllLocations().then((res) => {
      this.allLocation = res.data;
    });
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
      } else {
          return true;
      }
    },
    hideActionsList() {
      this.actionsList = false
    },
    showActionsList(item) {
      this.actionsList == item.id ? (this.actionsList = null) : (this.actionsList = item.id);
    },
    formattedDate(date) {
      let dat = new Date(date)
      return dat.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    },
    formatCompaniesArray(arr) {
      this.companies = [];
      for (const element of arr) {
        this.companies.push(
          {
            'id': element.id,
            'user_id': element.user_id,
            'user_name': `${element.first_name} ${element.last_name}`,
            'company_name': element.company_name,
            'city': element.city,
            'country': element.country,
            'industry': element.business ? element.business.industry : '',
            'needs': element.needs ? this.formatToString(element.needs.needed) : '',
            'date_incorporation': this.formattedDate(element.created_at),
            'user_role': element.user.role_id,
            'user_email': element.user.email,
            'initial_application_pdf' : element.company_application ? element.company_application.initial_application_pdf : null,
            'last_application_pdf' : element.company_application ? element.company_application.last_application_pdf : null,
            'invoice_path': element.payment ? element.payment.invoice_path : null
          }
        )
      }
    },

    getCompanies () {
      Company.getAllCompanies().then(res=>{
        if (res.status == 200) {
          this.formatCompaniesArray(res.data)
        }
      })
    },

    getCampaniesBySearch() {
      Company.getCompaniesBySearch(this.searchData).then((res) => {
        if (res.status == 200) {
          this.formatCompaniesArray(res.data)
        } else {
          console.log("Error", res);
        }
      })
    },

    confirmApplication(user_id) {
      this.loaderAlert = true;
      Company.updateUserStatus(user_id).then(res=>{
        if (res.status == 200) {
          this.loaderAlert = false;
          this.$fire({
            title: "Update User Status",
            text: res.data,
            type: "success",
            timer: 3000
          }).then(r => {
            console.log(r.value);
          });
          this.getCompanies();
        } else {
          this.loaderAlert = false;
          this.$fire({
            title: "Update User Status",
            text: res.data.message,
            type: res.data.type,
            timer: 3000
          }).then(r => {
            console.log(r.value);
          });
        }
      })
    }
  }

}
</script>
<style src="../../../assets/styles/admin/style.css"> </style>
