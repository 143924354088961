<template>
<div>

 <v-sheet        
	    height="100%"
	    class="mx-auto mt-10"
    >  
	  <v-row align="center" justify="center" class="pt-2">
	  <!-- <div class="layout column pt-5"> -->
	  	<v-col cols="12" md="6" class="pl-2">
            <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Mini Resume</p>             
	        <!-- </div> -->
	    </v-col> 
	    <v-col cols="3">            
	    </v-col>   
	    <v-col cols="1">
	    </v-col> 
    </v-row>
	  <v-row align="center" justify="center" no-gutters>
	  	
	  	<v-col cols="12" md="3">
	  		<p class="text-sm-left">Mini Resume*</p>
	  		<!-- <p class="text-sm-left"><span >0/160</span></p> -->
	  	</v-col>	
	  	<v-col cols="6">
	  		<FormInlineMessage v-if="$v.resume.$error">
                Please give your resume
              	</FormInlineMessage>
	  		<v-textarea 
	  			v-model="resume"
	  			:counter="160"
	  			outlined 
	  			dense 
	  			placeholder="My role,my recent achievements" 
	  			@blur="$v.resume.$touch()"
	  		></v-textarea>
	  	</v-col>
	  	<v-col cols="1">
	  	</v-col>
	  </v-row>	 
	
  		</v-sheet>


  		<!-- CEO -->
  		 <v-sheet        
	    height="370"

	    class="mx-auto mt-10"
	  >  
	  <v-row align="center" justify="center" class="pt-2">
	  	<v-col cols="12" md="6" class="pl-2">
            <p class="light-green--text text--darken-3 font-weight-bold text-sm-left" id="title">Your Involvement</p>             
	        <!-- </div> -->
	    </v-col>
	    <v-col cols="3"></v-col>
	    <v-col cols="1"></v-col>
	  </v-row>

	  <v-row align="center" justify="center" class="pt-2">
	  	<v-col cols="10" md="6" class="pl-2">
            <p class="text-sm-left">Are you the CEO or a Co-founder of the Company*</p>           
	        <!-- </div> -->
	    </v-col>
	    <v-col cols="3"></v-col>
	    <v-col cols="1"></v-col>
	  </v-row>  

	  <v-row align="center" justify="center" class="" no-gutters>
	  	<v-col cols="10" md="6" class="pl-5">

	 <v-checkbox
	 	@click = "applicant.other=false; applicant.otherField=false"
	 	v-model="applicant.ceo"
	 	label="I am the CEO of the Company"
	 ></v-checkbox>

    <v-checkbox
    	@click="applicant.other=false; applicant.otherField=false"
    	v-model="applicant.cofounder"
    	label="I am the Co-founder of the Company"
        class="mb-5"
    ></v-checkbox>

    <v-checkbox
    	v-model="applicant.other"
    	@click = "applicant.cofounder=false; applicant.ceo=false"
    	label="Other" 
    	class="mt-5"     
    ></v-checkbox>
             
	    </v-col>
	    <v-col cols="3"></v-col>
	    <v-col cols="1"></v-col>
	  </v-row> 	




	  <v-row align="center" justify="center" v-if="applicant.other">
	  	<v-col cols="10" md="6" class="pl-5">

	 <!-- <FormInlineMessage v-if="$v.experiences.$each[index].title.$error">
                Provide your title
            </FormInlineMessage> -->

	  		<v-text-field 
	  			v-model="applicant.otherField"
	  			outlined 
	  			dense 
	  			placeholder="" 
	  			
	  		></v-text-field>
             
	    </v-col>
	    <v-col cols="3"></v-col>
	    <v-col cols="1"></v-col>
	  </v-row> 	



  		</v-sheet>

  		<v-sheet        
	    height="100%"
	    class="mx-auto mt-8"
	    v-if="currentBlock"
	  >  
	  <v-row align="center" justify="center">	  	
	  	<v-col cols="10"></v-col>	
	  	<v-col cols="12" md="12">	  		
		<v-icon
      large
      color="#00aac3"
      right
      center
      
	  @click.prevent.stop="saveApplicant"
     
    >
      mdi-arrow-down-circle
    </v-icon>
		    <!-- </v-btn> -->
	  	</v-col>
	  </v-row>
  		</v-sheet>


</div>	


</template>
<script>
	import { validationMixin } from "vuelidate";
	import { required } from "vuelidate/lib/validators";
	import {
	  minLength,
	  maxLength,
	  numeric,
	  minValue,
	  email,
	  sameAs,
	  url
	} from "vuelidate/lib/validators";

	import FormInlineMessage from "@/components/FormInlineMessage";
	import CompanyService from "@/store/services/company/company.service";

	export default {
		components: {
			FormInlineMessage
		},
		data() {
			return {
				currentBlock:true,
				resume: null,
				applicant: {
					ceo:false,
					cofounder:false,
					other:false,
					otherField:''
				}
				

			}
		},


		validations: {
			resume: {
				required,
				minLength: minLength(10)
			}
		},

		methods: {
			saveApplicant() {

				this.$v.$touch();
			    if (this.$v.$error) {        
			        return;
		    	}

		    	var applicant = [];
		    	for(const item in this.applicant) {
		    		if(this.applicant[item]!==false){
		    			if(item=='otherField') {
		    				applicant = [];
		    				applicant.push(this.applicant[item]);
		    			} else {
		    				applicant.push(item);
		    			}
		    			

		    		}
		    	}

		    	applicant = applicant.join();
		    	
		    	CompanyService.saveApplicant({
		    		"resume":this.resume,
		    		"applicant":applicant
		    	})

		    	//console.log(applicant);


				this.currentBlock=false;
				this.$emit("block6");
			}
		},

		created() {
			CompanyService.getAll()
			.then(res=>{
				if(res.data) {						
					this.resume = res.data.resume;
					var applicant = res.data.applicant.split(',')
					//console.log(applicant.includes("bbb"))
					if(applicant.includes("ceo") ) {
						this.applicant.ceo = true;						
					} 
					if(applicant.includes("cofounder")){
						this.applicant.cofounder = true;
						return;				
					}
					
					if(!applicant.includes("cofounder")&&!applicant.includes("ceo")) {
						this.applicant.other = true;
						this.applicant.otherField = applicant[0];
					}				
					
					return;
				}					 
			});	
			
	}

	}

</script>