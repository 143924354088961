<template>
  <v-row align="center" justify="center" class="step-1">
    <v-col cols="12" sm="10">
      <v-responsive :aspect-ratio="16 / 9">
        <v-card-text>
          <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec">
            <v-row justify="center" class="mx-10">
              <v-col cols="2">
                <v-img
                  style="border-radius: 20%"
                  min-height="95%"
                  :src="logoPreviewUrl"
                />
              </v-col>
              <v-col cols="10">
                <p class="text-sm-left thin">{{this.me.company_name}}</p>
                <p class="text-sm-left bold">Main Contact</p>
                <p class="text-sm-left thin">{{ this.me.first_name }}</p>
                <p class="text-sm-left thin">{{ this.me.last_name }}</p>
                <p class="text-sm-left thin">{{ this.me.email }}</p>
              </v-col>
            </v-row>
          </v-sheet>
          <v-col />

          <v-col>
            <p class="cyan--text text--darken-1" id="title">
              Describe your Team
            </p>
          </v-col>

          <Founder
            @block2="policiesBlock = true"
            v-bind:company_id="company_id"
          />

          <Policies
            v-if="policiesBlock"
            @block3="nextBlock = true"
            v-bind:company_id="company_id"
          />

          <Next @agreed="$emit('done')" v-if="nextBlock" />
        </v-card-text>
      </v-responsive>
    </v-col>
  </v-row>
</template>

<script>
import Founder from "@/components/Steps/Step3/Blocks/FounderBlock";
import Policies from "@/components/Steps/Step3/Blocks/PoliciesBlock";
import Next from "@/components/Steps/Step3/Blocks/NextBlock";
import CompanyService from "@/store/services/company/company.service.js";
import Status from "@/store/services/status.service";

export default {
  props: {
    me: Object,
    // company_id: Number,
  },

  components: {
    Founder,
    Policies,
    Next,
    //CompanyService
  },
  
  data() {
    return {
      policiesBlock: false,
      nextBlock: false,
      logoPreviewUrl: null,
      company_id: null,
      variables: null,
      loader: false
    };
  },

  methods: {
    retrieveLogo(company_id) {
      CompanyService.getLogo(company_id)
        .then((res) => {
          this.logoPreviewUrl = res.data;
        })
        .catch((error) => {});
    },
  },

  mounted() {
    Status.readAll()
      .then((res) => {
        if (res.data) {
          this.company_id = res.data.active_company_id;
          this.retrieveLogo(res.data.active_company_id);
        }
      })
  },
};
</script>
