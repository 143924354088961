<template>
    <div class="mar-top-100">
        <v-row>
            <v-col cols="1" md="1"/>
            <v-col cols="10" md="10">
                <p class="cyan--text text--darken-1 text-sm-left mt-5" id="title">Funding Needs</p>

                <p class="cyan--text text--darken-1 text-sm-left mt-5" id="title">How much do you want to raise</p>

                <v-row class="mb-n8">
                    <v-col cols="12">
                        <p class="text-sm-left">
                            Total amount you are raising for this round (in US dollars)<br/>
                            <span class="blue-grey--text text--lighten-2 font-weight-thin">
                                For example, enter "1000000" for $1,000,000
                            </span>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="8" class="mt-n8">
                        <FormInlineMessage v-if="$v.fund_raising.$error" class="errmsg">
                            Please fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_fund_raising_amount_length_error.error">
                            {{ is_fund_raising_amount_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="fund_raising"
                        outlined
                        dense
                        @input="fund_raising = formatToString(fund_raising)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row class="mb-n8">
                    <v-col cols="12">
                        <p class="text-sm-left">
                            Total amount still needed for this round (in US dollars)<br/>
                            <span class="blue-grey--text text--lighten-2 font-weight-thin">
                                For example, enter "500000" for $500,000
                            </span>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="8" class="mt-n8">
                        <FormInlineMessage v-if="$v.fund_needed.$error" class="errmsg">
                            Please fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_fund_needed_length_error.error">
                            {{ is_fund_needed_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="fund_needed"
                        outlined
                        dense
                        @input="fund_needed  = formatToString(fund_needed)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <p class="cyan--text text--darken-1 text-sm-left mt-5" id="title">When are you aiming to close this round of financing ?</p>
                
                <p class="text-sm-left blue-grey--text text--lighten-2 thin">Your target fundraise close date should be at least 30 days in the future</p>
                
                <v-row justify="start">
                    <v-col sm="auto">
                        <FormInlineMessage v-if="$v.fund.date.$error" class="errmsg">
                            Please fill the field
                        </FormInlineMessage>
                        <v-menu
                            ref="dob"
                            :close-on-content-click="false"
                            :return-value.sync="fund.date"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                height="40"
                                outlined
                                v-model="fund.date"
                                placeholder="Closing Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                @blur="$v.fund.date.$touch()"
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="fund.date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="$refs.dob.isActive = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dob.save(fund.date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>

                <p class="cyan--text text--darken-1 text-sm-left mt-5" id="title">How do you plan to use this money, for what needs ?</p>

                <p class="text-sm-left">
                    Description of your need(s) and how you will allocate the new funds<br/>
                    <span class="blue-grey--text text--lighten-2 font-weight-thin">
                        Describe the purpose of your fund raising and what it will allow you to achieve that you couldn't without it<br/>
                        (from 100 to 1500 characters)
                    </span>
                </p>

                <v-row justify="start">
                    <v-col cols="12" sm="10">
                        <FormInlineMessage v-if="$v.fund.describe.$error" class="errmsg">
                            Please fill the field
                        </FormInlineMessage>
                        <v-textarea
                        v-model="fund.describe"
                        outlined
                        dense
                        rows="10"
                        :counter="1500"/>
                    </v-col>
                </v-row>

                <p class="cyan--text text--darken-1 text-sm-left mt-5" id="title">What is the investment vehicle for this round ?</p>

                <p class="text-sm-left blue-grey--text text--lighten-2 font-weight-thin">Choose at least one of the following choices</p>

                <v-row class="mb-5">
                    <v-col cols="12">
                        <v-checkbox
                        v-model="equity"
                        label="Equity (priced rounded)"
                        class="mt-n3"/>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                        v-model="convertible_note"
                        label="Convertible note"
                        class="mt-n3"/>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                        v-model="safe"
                        label="SAFE"
                        class="mt-n3"/>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                        v-model="any"
                        label="Open to any option"
                        class="mt-n3"/>
                    </v-col>
                </v-row>

                <p class="cyan--text text--darken-1 text-sm-left mt-5" id="title">What is the percentage of equity offered for the expected fundraising amount ?</p>

                <v-row justify="start">
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.investor.percent.$error" class="errmsg">
                            Please fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_investor_percent_less_than_100_percent_error.error">
                            {{ is_investor_percent_less_than_100_percent_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="investor.percent"
                        outlined
                        dense
                        placeholder="10"
                        prefix="%"
                        @input="setInvestorPercent(investor.percent)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <p class="cyan--text text--darken-1 text-sm-left mt-5" id="title">Are you ready to have investors on your board of directors ?</p>

                <v-row class="mt-5">

                    <v-col cols="6" sm="2" class="mt-n5">
                        <v-btn
                        @click="investor.board_director = true; not_board_director = false;"
                        v-bind:color="investor.board_director ? 'primary' : ''">
                            YES
                        </v-btn>
                    </v-col>

                    <v-col cols="6" sm="2" class="mt-n5">
                        <v-btn
                        @click="investor.board_director = false; not_board_director = true;"
                        v-bind:color="not_board_director ? 'primary' : ''">
                            NO
                        </v-btn>
                    </v-col>
                </v-row>

                <p class="cyan--text text--darken-1 text-sm-left mt-5" id="title">What added value should the investor bring ?</p>

                <v-row class="mb-n8">
                    <v-col cols="12">
                        <p class="text-sm-left">
                            Main field of Expertise<br>
                            <span class="blue-grey--text text--lighten-2 font-weight-thin">
                                Enter max 7 keywords separated by ;
                            </span>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="9" class="mt-n8">
                        <FormInlineMessage v-if="$v.investor.expertise.$error" class="errmsg">
                            Expertise is required and must be less than 7 keywords
                        </FormInlineMessage>
                        <v-combobox
                        v-model="investor.expertise"
                        :items="entries"
                        item-text="name"
                        outlined
                        dense
                        multiple
                        :search-input.sync="search"
                        small-chips
                        deletable-chips/>
                    </v-col>
                </v-row>

                <v-row class="mb-n8">
                    <v-col cols="12">
                        <p class="text-sm-left">Describe what kind of support you are expecting from the investor</p>
                    </v-col>
                    <v-col cols="12" sm="10" class="mt-n8">
                        <FormInlineMessage v-if="$v.investor.expecting.$error" class="errmsg">
                            You must describe what kind of support you are expecting from the investor
                        </FormInlineMessage>
                        <v-textarea
                        v-model="investor.expecting"
                        outlined
                        dense
                        rows="5"/>
                    </v-col>
                </v-row>

                <v-row align="center" justify="center" class="pt-2">
                    <v-col cols="0" sm="9"/>
                    <v-col cols="12" sm="1">
                        <v-btn
                        id="btn-photo"
                        class="white--text mb-3"
                        style="font-weight: bold; font-size: 14px"
                        color="#11aec9"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.stop.prevent="save()">
                            save
                        </v-btn>
                    </v-col>
                    <v-col cols="0" sm="2"/>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AllVar from "@/store/services/var";
import NeedService from "@/store/services/need.service";
import FormInlineMessage from "@/components/FormInlineMessage";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { myMixins } from "@/mixins/mixins";

export default {
    mixins: [myMixins],
    components: {
        FormInlineMessage,
    },

    props: {
        company_id: Number,
    },

    data() {
        return {
            fund: {},
            investor: {
                expertise: [],
            },
            equity: false,
            convertible_note: false,
            safe: false,
            any: false,
            not_board_director: false,
            entries: [],
            queryTerm: "",
            fund_raising: null,
            fund_needed: null,
        }
    },

    validations: {
        fund_raising: { required },
        fund_needed: { required },
        fund: {
            raising: { required, },
            needed: { required, },
            date: { required, },
            describe: {
                required,
                minLength: minLength(100),
                maxLength: maxLength(1500),
            },
        },
        investor: {
            percent: { required },
            board_director: {
                notSameAs() { return this.not_board_director != this.investor.board_director; },
            },
            expertise: {
                required,
                maxLength: maxLength(7),
            },
            expecting: { required },
        },
    },

    methods: {
        loadEntries() { 
            AllVar.getAllSkills(this.queryTerm).then((res) => { 
                this.entries = res.data; 
            }); 
        },

        save() {
            this.$v.$touch();
            if (this.$v.$error || this.is_fund_raising_amount_length_error.error || this.is_fund_needed_length_error.error || this.is_investor_percent_less_than_100_percent_error.error) {
                return this.$toast.open({
                    message: "Please fill all required fields or correct numbers or amounts having more than 15 digits before saving.",
                    type: "error",
                    position: "top-right",
                }); 
            };
            this.fund_raising = this.formatToInt(this.fund_raising);
            this.fund_needed  = this.formatToInt(this.fund_needed);

            this.fund.raising = this.fund_raising;
            this.fund.needed  = this.fund_needed;

            let vehicle = [];
            if (this.equity) { vehicle.push('equity'); }
            if (this.convertible_note) { vehicle.push('convertible_note'); }
            if (this.safe) { vehicle.push('safe'); }
            if (this.any) { vehicle.push('any_option'); }

            this.investor.vehicle = vehicle.join();

            NeedService.saveFund({ 'company_id': this.company_id, 'fund': this.fund });
            NeedService.saveInvestor({ 'company_id': this.company_id, 'investor': this.investor }).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Funding Needs saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Something went wrong !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });

            this.fund_raising = this.fund_raising.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.fund_needed  = this.fund_needed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }, 
    },

   computed: {
        search: {
            get: function () { return this.queryTerm; },

            set: function (searchInput) {
                if (!searchInput) {} else if (this.queryTerm !== searchInput) {
                    this.queryTerm = searchInput;
                    this.loadEntries();
                }
            },
        },
    },

    created() {
        Promise.all([
            AllVar.getAllSkills(this.queryTerm).then((res) => { this.entries = res.data; }),

            NeedService.getFund(this.company_id).then((res) => { 
                this.fund = res.data;
                this.fund_raising = this.fund.raising.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.fund_needed  = this.fund.needed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }),

            NeedService.getInvestor(this.company_id)
            .then((res) => {
                this.investor.percent = res.data.investor.percent;
                this.investor.board_director = res.data.investor.board_director;
                this.not_board_director = !this.investor.board_director;
                this.investor.expecting = res.data.investor.expecting;
                this.not_board_director = !res.data.investor.board_director;
                res.data.expertise.forEach(element => {
                    this.investor.expertise.push(element.skill)
                })
                let vehicle = res.data.investor.vehicle.split(',');
                if (vehicle.includes('equity')) { this.equity = true; }
                if (vehicle.includes('convertible_note')) { this.convertible_note = true; }
                if (vehicle.includes('safe')) { this.safe = true; }
                if (vehicle.includes('any_option')) { this.any = true; }
            }),
        ])
    }
}
</script>
