<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="11">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            What is your company investment stage ?
          </p>
        </v-col>
        <v-col cols="1" />
        <v-radio-group v-model="investment.stage" style="z-index: 1">
          <v-row v-for="(stage, index) in stages" :key="index">
            <v-radio :value="stage.name" />
            <p>
              {{ stage.label }}<br />
              <span class="text-sm-left font-weight-thin">
                {{ stage.description }}
              </span>
            </p>
          </v-row>
        </v-radio-group>
      </v-row>

      <!-- mise en page de la honte -->

      <v-col />
      <v-col />
      <v-col />
      <v-col />
      <v-col />
      <v-col />
      <v-col />
      <v-col />
      <v-col />
      <v-col />
      <p></p>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <p class="cyan--text text--darken-1 text-sm-left" id="title">
          How much external financing did you previously get ?
        </p>
        <v-col cols="12" />
        <v-col cols="1" />
        <div>
          <p class="text-sm-left">
            Total amount of previous external financing in USD (U.S dollars)
          </p>
          <FormInlineMessage v-if="$v.investment_finance.$error" class="errmsg">
            Fill in the field
          </FormInlineMessage>
          <FormInlineMessage v-if="is_investment_finance_length_error.error" class="errmsg">
            {{ is_investment_finance_length_error.message }}
          </FormInlineMessage>
          <v-text-field
            v-model="investment_finance"
            outlined
            dense
            placeholder="..."
            @input="investment_finance  = formatToString(investment_finance)"
            @keypress="isNumber($event)"
          />
        </div>

        <v-col cols="12" />
        <v-col cols="1" />

        <div>
          <p>
            Repartition of your previous external financing in USD<br />
            <span class="blue-grey--text text--lighten-3"
              >Enter 0 if the amount of financing types is nill</span
            >
          </p>
        </div>

        <v-col cols="12" />
        <v-col cols="1" />

        <v-row>
          <v-col sm="2">
            <p class="text-sm-left font-weight-light">Equity</p>
          </v-col>
          <v-col sm="6">
            <FormInlineMessage
              v-if="$v.investment_equity.$error"
              class="errmsg"
            >
              Fill in the field
            </FormInlineMessage>
            <FormInlineMessage v-if="is_investment_equity_length_error.error" class="errmsg">
              {{ is_investment_equity_length_error.message }}
            </FormInlineMessage>
            <v-text-field
              v-model="investment_equity"
              placeholder="..."
              outlined
              dense
              @input="investment_equity  = formatToString(investment_equity)"
              @keypress="isNumber($event)"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />
        <v-col cols="1" />

        <v-row>
          <v-col sm="2">
            <p class="text-sm-left font-weight-light">Convertible note</p>
          </v-col>
          <v-col sm="6">
            <FormInlineMessage
              v-if="$v.investment_convertible.$error"
              class="errmsg"
            >
              Fill in the field
            </FormInlineMessage>
            <FormInlineMessage v-if="is_investment_convertible_length_error.error" class="errmsg">
              {{ is_investment_convertible_length_error.message }}
            </FormInlineMessage>
            <v-text-field
              v-model="investment_convertible"
              placeholder="..."
              outlined
              dense
              @input="investment_convertible  = formatToString(investment_convertible)"
              @keypress="isNumber($event)"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />
        <v-col cols="1" />

        <v-row>
          <v-col sm="2">
            <p class="text-sm-left font-weight-light">SAFE</p>
          </v-col>
          <v-col sm="6">
            <FormInlineMessage v-if="$v.investment_safe.$error" class="errmsg">
              Fill in the field
            </FormInlineMessage>
            <FormInlineMessage v-if="is_investment_safe_length_error.error" class="errmsg">
              {{ is_investment_safe_length_error.message }}
            </FormInlineMessage>
            <v-text-field
              v-model="investment_safe"
              placeholder="..."
              outlined
              dense
              @input="investment_safe  = formatToString(investment_safe)"
              @keypress="isNumber($event)"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />
        <v-col cols="1" />

        <v-row>
          <v-col sm="2">
            <p class="text-sm-left font-weight-light">Bonds</p>
          </v-col>
          <v-col sm="6">
            <FormInlineMessage v-if="$v.investment_bonds.$error" class="errmsg">
              Fill in the field
            </FormInlineMessage>
            <FormInlineMessage v-if="is_investment_bonds_length_error.error" class="errmsg">
              {{ is_investment_bonds_length_error.message }}
            </FormInlineMessage>
            <v-text-field
              v-model="investment_bonds"
              placeholder="..."
              outlined
              dense
              @input="investment_bonds  = formatToString(investment_bonds)"
              @keypress="isNumber($event)"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />
        <v-col cols="1" />

        <v-row>
          <v-col sm="2">
            <p class="text-sm-left font-weight-light">Bank Loans</p>
          </v-col>
          <v-col sm="6">
            <FormInlineMessage v-if="$v.investment_bank.$error" class="errmsg">
              Fill in the field
            </FormInlineMessage>
            <FormInlineMessage v-if="is_investment_bank_length_error.error" class="errmsg">
              {{ is_investment_bank_length_error.message }}
            </FormInlineMessage>
            <v-text-field
              v-model="investment_bank"
              placeholder="..."
              outlined
              dense
              @input="investment_bank  = formatToString(investment_bank)"
              @keypress="isNumber($event)"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />
        <v-col cols="1" />

        <v-row>
          <v-col sm="2">
            <p class="text-sm-left font-weight-light">Grants</p>
          </v-col>
          <v-col sm="6">
            <FormInlineMessage
              v-if="$v.investment_grants.$error"
              class="errmsg"
            >
              Fill in the field
            </FormInlineMessage>
            <FormInlineMessage v-if="is_investment_grants_length_error.error" class="errmsg">
              {{ is_investment_grants_length_error.message }}
            </FormInlineMessage>
            <v-text-field
              v-model="investment_grants"
              placeholder="..."
              outlined
              dense
              @input="investment_grants  = formatToString(investment_grants)"
              @keypress="isNumber($event)"
            />
          </v-col>
        </v-row>
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveInvestment"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import FinanceService from "@/store/services/finance.service";
import Var from "@/store/services/var";
import { required } from "vuelidate/lib/validators";
import { myMixins } from "@/mixins/mixins.js";
import Status from "@/store/services/status.service";

export default {
  mixins: [myMixins],
  components: {
    FormInlineMessage,
  },

  props: {
    company_id: Number,
  },

  data() {
    return {
      investment: {},
      currentBlock: true,
      investment_finance: null,
      investment_equity: null,
      investment_convertible: null,
      investment_safe: null,
      investment_bonds: null,
      investment_bank: null,
      investment_grants: null,
      stages: [],
    };
  },

  validations: {
    investment: {
      required,
      stage: { required },
    },
    investment_finance: { required },
    investment_equity: { required },
    investment_convertible: { required },
    investment_safe: { required },
    investment_bonds: { required },
    investment_bank: { required },
    investment_grants: { required },
  },

  methods: {
    formatedNumber() {
      this.investment_finance = this.investment_finance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.investment_equity  = this.investment_equity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.investment_convertible = this.investment_convertible.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.investment_safe    = this.investment_safe.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.investment_bonds   = this.investment_bonds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.investment_bank    = this.investment_bank.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.investment_grants  = this.investment_grants.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    saveInvestment() {
      this.$v.$touch();
      if (
        this.$v.$error ||
        this.is_investment_finance_length_error ||
        this.is_investment_equity_length_error ||
        this.is_investment_convertible_length_error ||
        this.is_investment_safe_length_error ||
        this.is_investment_bonds_length_error ||
        this.is_investment_bank_length_error ||
        this.is_investment_grants_length_error
      ) {
        return;
      }
      this.investment_finance = this.formatToInt(this.investment_finance);
      this.investment_equity  = this.formatToInt(this.investment_equity);
      this.investment_convertible = this.formatToInt(this.investment_convertible);
      this.investment_safe    = this.formatToInt(this.investment_safe);
      this.investment_bonds   = this.formatToInt(this.investment_bonds);
      this.investment_bank    = this.formatToInt(this.investment_bank);
      this.investment_grants  = this.formatToInt(this.investment_grants);

      this.investment.finance = this.investment_finance;
      this.investment.equity  = this.investment_equity;
      this.investment.convertible = this.investment_convertible;
      this.investment.safe    = this.investment_safe;
      this.investment.bonds   = this.investment_bonds;
      this.investment.bank    = this.investment_bank;
      this.investment.grants  = this.investment_grants;

      FinanceService.saveInvestment({
        investment: this.investment,
        company_id: this.company_id,
      });

      this.formatedNumber();
      this.currentBlock = false;
      this.$emit("block2");
    },
    
    getInvestment(company_id) {
      FinanceService.getInvestment(company_id)
        .then((res) => {
          if(res.data)
          {
            this.investment=res.data;
            this.investment_finance = this.investment.finance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_equity  = this.investment.equity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_convertible = this.investment.convertible.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_safe    = this.investment.safe.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_bonds   = this.investment.bonds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_bank    = this.investment.bank.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_grants  = this.investment.grants.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        })
        .catch((e) => {});
    },
  },
  mounted() {
    Status.readAll()
      .then((res) => {
        if (res.data) {
          this.getInvestment(res.data.active_company_id);
        }
    });
    Var.getStages().then((res) => { this.stages = Object.values(res.data); })
  }
};
</script>
