<template>
  <v-container fluid fill-height>
    <span class="bg"></span>
    <v-layout align-center justify-center class="mt-5">
      <v-flex xs12 sm8 md5 lg4>
        <v-card class="elevation-3 pa-3" height="100%" width="100%">
          <v-card-text id="card-text">
            <div class="layout column align-center">
              <img
                alt="Ideasfundx logo"
                src="../assets/logo.png"
                width="200"
                height="32"
              />
              <h1
                v-if="!this.investorView"
                class="flex my-4 secondary--text"
                id="title"
              >
                Sign Up for company access
              </h1>
              <h1
                v-if="this.investorView"
                class="flex my-4 secondary--text"
                id="title"
              >
                Sign Up for investor access
              </h1>
              <h2 class="flex secondary--text" id="sub-title">
                Create your account
              </h2>
            </div>
            <v-form class="my-5">
              <FormInlineMessage v-if="$v.firstName.$error">
                First name must be at least 2 characters long
              </FormInlineMessage>
              <v-text-field
                v-model="firstName"
                outlined
                name="firstName"
                label="First Name"
                type="text"
                @blur="$v.firstName.$touch()"
              />
              <FormInlineMessage v-if="$v.lastName.$error">
                Last name must be at least 2 characters long
              </FormInlineMessage>
              <v-text-field
                v-model="lastName"
                outline
                name="lastName"
                label="Last Name"
                type="text"
                outlined
                @blur="$v.lastName.$touch()"
              />
              <FormInlineMessage v-if="$v.email.$error">
                Please provide valid email
              </FormInlineMessage>
              <v-text-field
                v-model="email"
                outline
                name="email"
                label="Email"
                type="email"
                outlined
                @blur="$v.email.$touch()"
              />
              <FormInlineMessage v-if="$v.password.$error">
                Password must be at least 8 characters long
              </FormInlineMessage>
              <v-text-field
                v-model="password"
                outlined
                id="password"
                name="password"
                label="Password"
                type="password"
                @blur="$v.password.$touch()"
              ></v-text-field>
              <FormInlineMessage v-if="$v.passwordConfirm.$error">
                Password doesn't match
              </FormInlineMessage>
              <v-text-field
                v-model="passwordConfirm"
                outlined
                id="confirm-password"
                name="confirm-password"
                label="Confirm Password"
                type="password"
                @blur="$v.passwordConfirm.$touch()"
              ></v-text-field>
            </v-form>
            <v-card-actions class="justify-center">
              <v-btn
                center
                class="white--text btn-nex"
                color="#00aac3"
                width="100%"
                height="65"
                @click.stop.prevent="next"
                :disabled="false"
                id="btn-next"
              >
                NEXT
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>

        <!-- Terms Dialog -->
        <v-dialog v-model="dialogNext" width="100%">
          <v-card
            min-height="450px"
            color="#f6f6f6"
            style="overflow-x: hidden; overflow-y: hidden"
            class="pt-5"
          >
            <div class="layout column align-center mb-7">
              <img
                alt="Vue logo"
                src="../assets/logo.png"
                width="200"
                height="32"
              />
              <h1 class="flex my-4 secondary--text" id="title">
                Sign Up for company access
              </h1>
              <h2 class="flex secondary--text" id="sub-title">
                Read and Accept Terms Of Service
              </h2>
            </div>
            <Terms @agreed="agreed"></Terms>
          </v-card>
        </v-dialog>

        <!-- Verification Code -->
        <v-dialog v-model="dialogVerification" max-width="500px">
          <v-card
            min-height="450px"
            style="overflow-x: hidden; overflow-y: hidden"
          >
            <div class="mt-4">
              <span id="title">Verify Your Account</span>
            </div>
            <v-card-text>
              <v-container>
                <div>We sent a verification code to your email.</div>
                <div>Enter The code from the email in the field below.</div>

                <v-row class="mt-6">
                  <v-col cols="12" class="pl-0">
                    <v-row class="mb-1 pl-3"> 6 digit code* </v-row>
                    <!-- <v-otp-input v-model="code"></v-otp-input> -->
                    <v-text-field
                      v-model="code"
                      placeholder="Enter 6 digit Code"
                      required
                      outlined
                    ></v-text-field>
                    <v-row class="pl-3 red--text">
                      <v-icon color="red">mdi-alert-octagon</v-icon>
                      Verification Code Required
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn
                id="btn-veriy"
                center
                class="white--text"
                color="#514d7a"
                width="255"
                height="65"
                rounded
                @click.stop.prevent="sendCode"
                :disabled="false"
              >
                Verify Code
              </v-btn>
            </v-card-actions>
            <!-- <v-container> -->
            <div class="mt-3">
              <a>Need help?</a>
            </div>

            <div class="mt-2">
              Send the code again.
              <a @click="regenerateCode">Try again?</a>
            </div>
          </v-card>
        </v-dialog>

        <!-- Server Error Dialog -->
        <v-dialog
          v-model="dialogServer"
          persistent
          width="380"
          min-height="393"
        >
          <v-card height="100%" width="380">
            <v-card-title class="justify-center"> Notification: </v-card-title>
            <v-card-text>{{ messageServer }}</v-card-text>
            <div class="pb-10 text-center">
              <v-spacer></v-spacer>
              <v-btn
                center
                class="white--text btn-next bold"
                @click="resetServerMessage"
                color="#00aac3"
                width="140"
                height="45"
                rounded
              >
                Close
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";
import Terms from "@/components/Terms";

export default {
  name: "SignUp",
  components: {
    FormInlineMessage,
    Terms,
  },

  data() {
    return {
      dispatchUrl: "company",
      investorView: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      dialogTerms: false,
      dialogCode: false,
      dialogNext: false,
      dialogVerification: false,
      doAgree: false,
      dialogServer: false,
      messageServer: "",
      code: "",
      registerSuccess: false,
      dialogSuccess: false,
    };
  },

  validations: {
    firstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(50),
    },
    lastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(50),
    },

    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    passwordConfirm: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },

  methods: {
    agreed() {
      this.submit();
    },

    next() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }
      this.dialogNext = true;
    },

    async submit() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.loading = false;
        return;
      }

      let that = this;

      await this.$store
        .dispatch("register", {
          user: {
            firstName: that.firstName,
            lastName: that.lastName,
            email: that.email,
            password: that.password,
            password_confirmation: that.passwordConfirm,
          },
          url: this.dispatchUrl,
        })
        .then((response) => {
          that.dialogVerification = true;
          that.generateCode();
        })
        .catch((error) => {
          that.dialogServer = true;
          that.messageServer =
            error.response.data.message ||
            "Registration not successful. Try again";
        });
    },

    regenerateCode() {
      this.dialogServer = true;
      this.messageServer = "Your code has been sent";
      this.generateCode();
    },

    async generateCode() {
      await this.$store
        .dispatch("profile/generateCode", {})
        .then((response) => {
          ("Your 6 digit code successfully generated. Check your email");
        })
        .catch((error) => {});
    },

    async sendCode() {
      let that = this;
      await this.$store
        .dispatch("profile/sendCode", { code: this.code })
        .then((response) => {
          that.$store
            .dispatch("profile/requestEmail", { path: "welcome" })
            .then()
            .catch();

          if(localStorage.getItem("user_role").substring(0, 1) == "c") 
          {
            this.$router.push({ name: "Application" });
          }
          else if(localStorage.getItem("user_role").substring(0, 1) == "i") 
          {
            this.$router.push({ name: "InvestorApplication" });
          }
        })
        .catch((error) => {
          this.dialogServer = true;
          this.messageServer = error.response.data.message;
        });
    },

    resetServerMessage() {
      this.messageServer = "";
      this.dialogServer = false;
    },

    switchToInvestorView() {
      if (this.$route.query.inv) {
        this.dispatchUrl = "investor";
        this.investorView = true;
      }
    },
  },
  mounted() {
    this.switchToInvestorView();
  },
};
</script>

<style scoped lang="css">
.btn-next {
  font-weight: bold;
  font-size: 20px;
  border-radius: 70px;
}

#btn-next {
  font-size: 20px;
}

#title {
  font-weight: bolder;
  font-size: 18px;
}
#sub-title {
  font-size: 14px;
}

.sub-title-code {
  font-size: 15px;
}

#btn-verify {
  font-weight: bolder;
  font-size: 18px;
  border-radius: 70px;
}

#card-text {
  padding: 25px;
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("~@/assets/img/signup.jpg") no-repeat center center;
  background-size: cover;
  background-color: #f6f6f6;
  transform: scale(1);
}
</style>
