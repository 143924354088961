<template>
    <div>
        <v-sheet
        height="100%"
        class="mx-auto mt-10">
            <v-row align="center" justify="center" class="pt-2">
                <v-col cols="1"/>
                <v-col cols="10">
                    <p class="cyan--text text--darken-1 font-weight-bold text-sm-left" id="title">Almost there... to get access to qualified investors from IdeasFundX</p>
                    <p class="text-sm-left thin"><br>I agree to the Terms of Service including:</p>
                    <p class="text-sm-left thin">I indemnify IdeasFundX and understand that fund raising is a long process.</p>
                    <p class="text-sm-left thin">Investors that will review your company may not be interested to contact you or may want to stop the negotiation.</p>
                    <p class="text-sm-left thin">
                        I understand that IdeasFundX aims to recommend only qualified deals to Investors matching their investment strategies.<br>
                        Therefore, the objective of the review of your application is to check the quality of your pitch and the attractiveness of the investment opportunity but IdeasFundX team doesn't verify the accurateness of the information you have provided and doesn’t perform detailed due diligence.
                    </p>
                    <p class="text-sm-left thin">If the investor invests in your company, IdeasFundX does not perform the legal part nor is responsible for the compliance with securities laws.</p>
                    <p class="text-sm-left thin">
                        I will be permanently banned from IdeasFundX if I falsely represent the company.<br>
                        I want to receive notification when IdeasFundX finds investors that want to learn more about my company and my funding needs.
                    </p>
                    <p class="text-sm-left thin">I understand that once IdeasVoice has reviewed my application, IdeasFundX will inform me if my company is ready to be recommended to investors who are using IdeasFundX.</p>
                    <p class="text-sm-left thin">I understand that the validation process will start only after the bank has confirmed the payment of the fixed fee.</p>
                    <p class="text-sm-left thin">I understand that the recommandation process will start after IdeasFundX has validated my application.</p>
                    <p class="text-sm-left thin">If IdeasFundX contact you to check the information you have provided or to improve your presentation, you agree to adapt your information provided if necessary.</p>
                    <p class="text-sm-left thin">I understand that IdeasFundx goal is to ensure that my company application is qualified to be submitted to investors, but this is not the IdeasFundX role to change your provided information.</p>
                    <p class="text-sm-left thin">At your request, IdeasFundX can assist you for improving your presentation with additional fees.</p>
                    <p class="text-sm-left thin">IdeasFunX stops referring your company to investors when:</p>
                    <p class="text-sm-left thin">a/ the amount of the funding is reached</p>
                    <p class="text-sm-left thin">
                        b/ the company decides to stop the search for investors. In this case, you just have to update the status of your company profile.<br>
                        The previous discussions will be still considered in progress as long as the investors don’t update the status of the negotiation with your company.<br>
                        There is no refunding of the fixed fee.<br>
                        In case it comes to an investment agreement from the previous discussions, the success fee of 4% is still due.
                    </p>
                    <p class="text-sm-left cyan--text text--darken-1 "><br>Pricing Policy</p>
                    <p class="text-sm-left thin">IdeasFundX charges:</p>
                    <p class="text-sm-left thin">
                        => to have your application reviewed and validated: 0.1% of the amount of investment you want to raise for this round<br>
                        => to submit your company to investors: a yearly membership of $490 excl.tax.<br>
                        => a success fee of 4% of the invesment in your company made by investors brought by IdeasFundX
                    </p>
                </v-col>
                <v-col cols="1"/>
            </v-row>
        </v-sheet>

        <v-sheet
        height="100%"
        class="mx-auto"
        color="#f6f6f6"
        v-if="currentBlock">
            <v-row align="center" justify="center" class="pt-2">
                <v-col cols="2">
                    <v-card-actions class="justify-center">
                    <v-btn
                        sm="2"
                        id="btn-photo"
                        class="white--text test--btn"
                        style="font-weight: bold; font-size: 24px;"
                        color="#514d7a"
                        height="90"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.prevent.stop="validTerm()">
                            I AGREE
                        </v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>
        </v-sheet>
    </div>
</template>

<script>
export default {
    data() {
        return{
            currentBlock: true
        }
    },

    methods: {
        validTerm() {
            this.currentBlock = false
            this.$emit("block2");
        }
    }
}
</script>

<style scoped>
    .test--btn {
        font-weight: bold;
        font-size: 30px;
    }
</style>