<template>
  <div>
    <v-toolbar style="background: #f6f6f6; font-family: Arial, Helvetica, sans-serif !important;" height="78">
      <img
        class="ml-n4 link"
        alt="Vue logo"
        src="../../assets/img/Left_menu.png"
        width="78"
        height="78"
        @click.prevent.stop="LeftSidebarPanel()"
      />

      <v-spacer></v-spacer>
      <div class="d-flex align-center justify-space-between width-40">
        <img
          alt="Vue logo"
          src="../../assets/img/Favicon.png"
          width="60"
          height="60"
        />
        <h2 class="color-site font-weight " style="">ADMIN</h2>
      </div>
      

      <v-spacer></v-spacer>

      <img
        class="mr-n4 link"
        alt="Vue logo"
        src="../../assets/img/Right_menu.png"
        width="78"
        height="78"
        @click.prevent.stop="RightSidebarPanel()"
      />
    </v-toolbar>

    <div class="sidebar">
      <div
        class="sidebar-backdrop"
        @click="LeftSidebarPanel"
        v-if="isPanelLeftOpen"
      />

      <transition name="slideLeft">
        <div v-if="isPanelLeftOpen" class="sidebar-panel-left">
          <Menu
            v-bind:array="this.companies"
            v-bind:comp_id="this.id"
            v-bind:current="this.current"
            @changeComponent="changeComponent"
          />
        </div>
      </transition>
    </div>

    <div class="sidebar">
      <div
        class="sidebar-backdrop"
        @click="RightSidebarPanel"
        v-if="isPanelRightOpen"
      />

      <transition name="slideRight">
        <div v-if="isPanelRightOpen" class="sidebar-panel-right">
          <p class="mr-4 router-link" style="text-align: left !important" @click="redirectTo('/admin/investor')" 
            :class="activePage == 'DashboardInvestor' ? 'active' : ''"
          >INVESTORS</p>
          <p class="mr-4 router-link" style="text-align: left !important" @click="redirectTo('/admin/company')"
            :class="activePage == 'DashboardCompany' ? 'active' : ''"
          >COMPANIES</p>
          <p class="setting" style="text-align: left !important" @click.prevent.stop="submitLogout()">Logout</p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/admin/AdminLeftMenu.vue";

export default {
  components: {
    Menu,
  },

  props: {
    companies: Array,
    id: Number,
  },

  data() {
    return {
      CMSlink:`window.location=\'${process.env.VUE_APP_CMS_URL}\';`,
      isPanelLeftOpen: false,
      isPanelRightOpen: false,
      current: null,
    };
  },

  created() {
    this.activePage = this.$route.name;
  },

  methods: {
    LeftSidebarPanel() {
      this.isPanelRightOpen = false;
      this.isPanelLeftOpen = !this.isPanelLeftOpen;
    },

    RightSidebarPanel() {
      this.isPanelLeftOpen = false;
      this.isPanelRightOpen = !this.isPanelRightOpen;
    },

    changeComponent(id, comp) {
      this.current = comp;
      this.isPanelLeftOpen = false;
      this.isPanelRightOpen = false;
      this.$emit("changeComponent", id, comp);
    },

    async submitLogout() {
      await this.$store
        .dispatch("logout", {})
        .then((response) => {
          //console.log("logout success");
        })
        .catch((error) => {});
    },
    redirectTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style>
.slideLeft-enter-active,
.slideLeft-leave-active,
.slideRight-enter-active,
.slideRight-leave-active {
  transition: transform 0.2s ease;
}

.slideLeft-enter,
.slideLeft-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.slideRight-enter,
.slideRight-leave-to {
  transform: translateX(+100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel-left {
  overflow-y: auto;
  background-color: #f6f6f6;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding: 3rem 20px 2rem 20px;
  width: 300;
}

.sidebar-panel-right {
  overflow-y: auto;
  background-color: #f6f6f6;
  position: fixed;
  left: 50vw;
  top: 78px;
  height: 15vh;
  z-index: 999;
  padding: 2rem 20px 2rem 20px;
  width: 50vw;
}
.link:hover {
    cursor: pointer;
}
.router-link:hover {
  color: #00a9c5;
}

</style>
