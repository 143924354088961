import { render, staticRenderFns } from "./CreateCoupon.vue?vue&type=template&id=64f8c603&scoped=true&"
import script from "./CreateCoupon.vue?vue&type=script&lang=js&"
export * from "./CreateCoupon.vue?vue&type=script&lang=js&"
import style0 from "../../assets/styles/admin/style.css?vue&type=style&index=0&lang=css&"
import style1 from "../../assets/styles/checkBoxInput.css?vue&type=style&index=1&id=64f8c603&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f8c603",
  null
  
)

export default component.exports