<template>
  <v-toolbar style="background: #f6f6f6" height="78">
    <div class="layout column align-center">
      <img alt="Vue logo" src="../assets/logo.png" width="200" height="30" />
    </div>
  </v-toolbar>
</template>
<script>
export default {};
</script>
