import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      Authorization: "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token")
    },
};


function getAll() {
  return axios.get(`${url}/user/education`, options)
}

function save(payload)  {
  return axios.post(`${url}/user/education`, payload, options);

}

function destroy(id) {
  return axios.delete(`${url}/user/education/${id}`, options);
}



export default {  
  destroy,  
  save,
  getAll
};
