<template>
  <v-row align="center" justify="center" class="step-1">
    <v-col cols="10">
      <v-responsive :aspect-ratio="16 / 9">
        <v-card-text>
          <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec">
            <v-row justify="center" class="mx-10">
              <v-col cols="2">
                <v-img
                  style="border-radius: 20%"
                  min-height="95%"
                  :src="logoPreviewUrl"
                />
              </v-col>
              <v-col cols="10">
                <p class="text-sm-left" style="font-weight: normal" v-if="user">
                  {{ company_name }}<br /><br />
                  <span style="font-weight: bold">Main Contact<br /></span>
                  {{ user.first_name }}<br />
                  {{ user.last_name }}<br />
                  {{ user.email }}
                </p>
              </v-col>
            </v-row>
          </v-sheet>
          <v-col />
          <p
            class="cyan--text text--darken-1 font-weight-bold text-sm-left"
            id="title"
          >
            What type is your business ?
          </p>
          <p class="text-sm-left thin">
            Tell us more about the type of your business<br />
            Select one or several values for each of the following criteria
          </p>

          <TypeBlock @block2="productBlock = true" v-if="company_id" :company_id="company_id"/>

          <ProductBlock @block3="strategyBlock = true" v-if="productBlock" v-bind:company_id="company_id"/>

          <StrategyBlock @block4="nextBlock = true" v-if="strategyBlock" v-bind:company_id="company_id"/>

          <NextBlock @agree="$emit('done')" v-if="nextBlock" />
        </v-card-text>
      </v-responsive>
    </v-col>
  </v-row>
</template>

<script>
import TypeBlock from "@/components/Steps/Step4/Blocks/TypeBlock";
import ProductBlock from "@/components/Steps/Step4/Blocks/ProductBlock";
import StrategyBlock from "@/components/Steps/Step4/Blocks/StrategyBlock";
import NextBlock from "@/components/Steps/Step4/Blocks/NextBlock";
import CompanyService from "@/store/services/company/company.service.js";
import Profile from "@/store/services/profile-service";

export default {
  components: {
    TypeBlock,
    ProductBlock,
    StrategyBlock,
    NextBlock,
  },

  props: {
    // me: Object,
    // company_id: Number
  },

  data() {
    return {
      productBlock: false,
      strategyBlock: false,
      nextBlock: false,
      logoPreviewUrl: null,
      company_id: null,
      me: {},
      user: {},
      company_name: null
    };
  },

  methods: {
    retrieveLogo(company_id) {
      CompanyService.getLogo(company_id)
        .then((res) => {
          this.logoPreviewUrl = res.data;
        })
        .catch((error) => {});
    },

    getProfileStatus() {
      Profile.profileStatus().then((res) =>{
        let status = res.data.status;
        this.company_id = status.active_company_id;
        this.user = res.data.profile;
        this.company_name = status.active_company_name;
        this.retrieveLogo(status.active_company_id);
      })
    },
  },
  created() {
    this.getProfileStatus()
  },
};
</script>
