<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-row>
        <v-col cols="1" />
        <v-col cols="4">
          <p class="text-sm-left">
            This part may help us to target VCs with specific policies
          </p>
          <p
            class="text-sm-left blue-grey--text text--lighten-3 font-weight-thin"
          >
            Feel free to answer these questions
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1" />

        <v-col cols="3">
          <p class="text-sm-left">Does your startup have a female founder ?</p>
        </v-col>

        <v-col cols="1" />

        <v-btn
          @click="
            policies.female = true;
            not_female = false;
          "
          v-bind:color="policies.female ? 'primary' : ''"
          class="mr-15"
        >
          YES
        </v-btn>

        <v-btn
          @click="
            policies.female = false;
            not_female = true;
          "
          v-bind:color="not_female ? 'primary' : ''"
          class="mr-15"
        >
          NO
        </v-btn>
      </v-row>

      <v-row>
        <v-col cols="1" />

        <v-col cols="3">
          <p class="text-sm-left">
            Does your startup have a founder of color ?
          </p>
        </v-col>

        <v-col cols="1" />

        <v-btn
          @click="
            policies.color = true;
            not_color = false;
          "
          v-bind:color="policies.color ? 'primary' : ''"
          class="mr-15"
        >
          YES
        </v-btn>

        <v-btn
          @click="
            policies.color = false;
            not_color = true;
          "
          v-bind:color="not_color ? 'primary' : ''"
          class="mr-15"
        >
          NO
        </v-btn>
      </v-row>

      <v-row>
        <v-col cols="1" />

        <v-col cols="3">
          <p class="text-sm-left">Does your startup have a LGBTQ founder ?</p>
        </v-col>

        <v-col cols="1" />

        <v-btn
          @click="
            policies.lgbtq = true;
            not_lgbtq = false;
          "
          v-bind:color="policies.lgbtq ? 'primary' : ''"
          class="mr-15"
        >
          YES
        </v-btn>

        <v-btn
          @click="
            policies.lgbtq = false;
            not_lgbtq = true;
          "
          v-bind:color="not_lgbtq ? 'primary' : ''"
          class="mr-15"
        >
          NO
        </v-btn>
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-row>
        <v-col cols="1" />
        <v-col cols="4">
          <p class="text-sm-left">The team</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1" />

        <v-col cols="3">
          <p class="text-sm-left">How many staff work at your company ?</p>
        </v-col>

        <v-col sm="2">
          <FormInlineMessage v-if="$v.team.number.$error" class="errmsg">
            How many are you ?
          </FormInlineMessage>
          <v-text-field
            v-model="team.number"
            outlined
            dense
            placeholder="100"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1" />

        <v-col cols="10">
          <p class="text-sm-left">
            Briefly describe why your team is the right team to make your
            startup successful (e.g., technical/industry expertise, existing
            relationships with potential customers/partners).
          </p>
        </v-col>

        <v-col cols="1" />
        <v-col cols="1" />
        <v-col cols="10">
          <FormInlineMessage v-if="$v.team.describe.$error" class="errmsg">
            Could you describe why your team is the right team
          </FormInlineMessage>
          <v-textarea v-model="team.describe" rows="4" outlined dense />
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon large color="#00aac3" right center @click.prevent.stop="save">
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";
import PoliciesService from "@/store/services/teams.service";

export default {
  components: {
    FormInlineMessage,
  },
  props: {
    company_id: Number,
  },
  data() {
    return {
      currentBlock: true,
      not_female: false,
      not_color: false,
      not_lgbtq: false,
      policies: {
        id: "",
        female: false,
        color: false,
        lgbtq: false,
      },
      team: {
        company_id: "",
        number: "",
        describe: "",
      },
    };
  },

  validations: {
    team: {
      number: { required },
      describe: { required },
    },
  },

  methods: {
    save() {
      this.policies.id = this.company_id;
      this.team.company_id = this.company_id;
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }

      if (
        this.policies.female != this.not_female ||
        this.policies.color != this.not_color ||
        this.policies.lgbtq != this.not_lgbtq
      ) {
        PoliciesService.savePolicies(this.policies);
      }

      PoliciesService.saveTeam(this.team);

      this.currentBlock = false;
      this.$emit("block3");
    },

    getPolicies() {
      PoliciesService.getPolicies(this.company_id)
        .then((res) => {
          this.policies.female = res.data.female;
          this.not_female = !res.data.female;
          this.policies.lgbtq = res.data.lgbtq;
          this.not_lgbtq = !res.data.lgbtq;
          this.policies.color = res.data.color;
          this.not_color = !res.data.color;
        })
        .catch((e) => {});
    },
    getTeam() {
      PoliciesService.getTeam(this.company_id)
        .then((res) => {
          this.team.number = res.data.number;
          this.team.describe = res.data.describe;
        })
        .catch((e) => {});
    },
  },

  created() {},
  mounted() {
    this.getPolicies();
    this.getTeam();
  },
};
</script>
