<template>
  <div class="body">
    <Header class="d-none d-md-flex"/>
    <HeaderPortable class="d-flex d-md-none"/>
    <v-row>
      <v-col cols="0" sm="0" md="2" lg="2" class="d-none d-md-flex">
        <Menu />
      </v-col>

      <v-col cols="10" sm="11" md="9" lg="9" class="maskContainer">
        <div class="filter">
          <p class="text-align-center title">LIST OF INVESTORS</p>
          <v-row class="d-flex justify-content-between">
            <v-col cols="12" sm="4" md="4" lg="4">
              <div>
                <p class="text-sm-left">Status</p>
                <v-combobox
                  :items="userStatus"
                  outlined
                  dense
                  v-model="searchData.role_id"
                  multiple
                  small-chips
                  deletable-chips
                  placeholder="All"
                />
              </div>
              <div>
                <p class="text-sm-left">Search</p>
                <v-text-field
                  class="mt-n2"
                  v-model="searchData.search"
                  outlined
                  dense
                />
              </div>
              <div>
                <p class="text-sm-left">User ID</p>
                <v-text-field
                  class="mt-n2"
                  v-model="searchData.user_id"
                  placeholder="User ID"
                  outlined
                  dense
                  @keypress="isNumber($event)"
                />
              </div>
              <div>
                <p class="text-sm-left">Investors Location</p>
                <v-text-field
                  placeholder="Enter a location"
                  class="mt-n2"
                  v-model="searchData.location"
                  outlined
                  dense
                />
              </div>
              <div>
                <p class="text-sm-left">Continent</p>
                <v-combobox
                  class="mt-n2"
                  :items="allLocation"
                  outlined
                  dense
                  v-model="searchData.continent"
                  deletable-chips
                  placeholder="Select a continent"
                />
              </div>
            </v-col>

            <v-col cols="12" sm="4" md="4" lg="4">
              <p class="text-sm-left">Application Created</p>
              <div>
                <v-menu
                  ref="menuBegin"
                  :close-on-content-click="false"
                  :return-value.sync="searchData.date_from"
                  offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      height="40"
                      outlined
                      v-model="searchData.date_from"
                      placeholder="From"
                      readonly
                      v-bind="attrs"
                      dense
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="searchData.date_from"
                    no-title
                    scrollable
                    type="date">

                    <v-spacer/>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuBegin.isActive = false">
                      Cancel
                    </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuBegin.save(searchData.date_from)">
                        OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>

              <div>
                <v-menu
                  ref="menuEnd"
                  :close-on-content-click="false"
                  :return-value.sync="searchData.date_to"
                  offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      height="40"
                      outlined
                      v-model="searchData.date_to"
                      placeholder="To"
                      readonly
                      v-bind="attrs"
                      dense
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="searchData.date_to"
                    no-title
                    scrollable
                    type="date">

                    <v-spacer/>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuEnd.isActive = false">
                      Cancel
                    </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuEnd.save(searchData.date_to)">
                        OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>

              <div>
                <p class="text-sm-left">Investing in</p>
                <v-combobox
                  v-model="searchData.investing_in"
                  :items="allLocation"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  dense
                />
              </div>
              <div>
                <p class="text-sm-left">Industry</p>
                <v-combobox
                  v-model="searchData.industry"
                  :items="allIndustries"
                  outlined
                  dense
                  multiple
                  small-chips
                  deletable-chips
                />
              </div>
            </v-col>

            <v-col cols="12" sm="4" md="4" lg="4">
              <div>
                <p class="text-sm-left">Min Check</p>
                <v-text-field
                  v-model="searchData.min_check_size"
                  placeholder="Min check"
                  outlined
                  dense
                  @keypress="isNumber($event)"
                />
              </div>
              <div>
                <p class="text-sm-left">Max Check</p>
                <v-text-field
                  placeholder="Max check"
                  class="mt-n2"
                  v-model="searchData.max_check_size"
                  outlined
                  dense
                />
              </div>
              <div>
                <p class="text-sm-left">Specific founders</p>
                <v-combobox
                  v-model="specifications"
                  :items="specific_founders"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  dense
                  @change="updateSpecific()"
                />
              </div>
              <div>
                <p class="text-sm-left">Investment Stage</p>
                <v-combobox
                  v-model="searchData.investment_stage"
                  :items="allStages"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  dense
                />
              </div>

              <div>
                <p class="text-sm-left">Investment Amount</p>
                <v-text-field
                  placeholder="Investment Amount"
                  class="mt-n2"
                  v-model="searchData.average"
                  outlined
                  dense
                />
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-content-center mt-n4 mb-2">
            <button class="btn btn-primary mr-2" @click="getInvestorsBySearch()">
              <v-icon aria-hidden="false" color="#fff" size="15">
                mdi-filter
              </v-icon>
              Filter
            </button>
            <export-excel
                class   = "btn btn-primary"
                :data   = "investors"
                :fields = "investor_fields"
                worksheet = "investors"
                title ="LIST OF INVESTORS"
                name    = "list_investors.xls">
              Export
            </export-excel>
          </v-row>
        </div>

        <LoaderPop v-if="loaderAlert" type="update" />
        <v-app id="inspire">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="investors"
            :single-select="singleSelect"
            item-key="id"
            show-select
            id="user"
          >

            <template v-slot:item.actions="{ item }">
              <span class="list" @mouseover="showActionsList(item)">>>></span>
              <div class="actionsList" :class="actionsList == item.id ? 'active' : ''">
                <div class="actions" @mouseleave="hideActionsList()">
                  <h5 class="actionsTitle">Action</h5>
                  <div class="download_link">
                    <a v-if="item.initial_application_pdf != null" outlined :href="pdf_path + item.initial_application_pdf" target="_blank" download>Download Initial Application</a>
                    <a v-else class="disabled">Download Initial Application</a>
                    <a v-if="item.last_application_pdf != null && item.last_application_pdf" outlined :href="pdf_path + item.last_application_pdf" target="_blank" download>Download Updated Application</a>
                    <a v-else class="disabled">Download Updated Application</a>
                    <a @click="submitLogin(item.email)">Login to User Dashboard</a>
                    <a href="/status" @click.prevent.stop="confirmApplication(item.id)">Confirm Application</a>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-app>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Investors from "@/store/services/admin/investor";
import Header from '@/components/admin/AdminHeader.vue';
import HeaderPortable from '@/components/admin/AdminHeadePortable.vue';
import Menu from '@/components/admin/AdminLeftMenu.vue';
import AllVar from "@/store/services/var";

import LoaderPop from "@/components/LoaderPop.vue";
import { myMixins } from "@/mixins/mixins.js";

export default {
  mixins: [myMixins],
  components: {
    Header,
    HeaderPortable,
    Menu,
    LoaderPop
  },
  data(){
    return{
      pdf_path: process.env.VUE_APP_API_BASE_URL_PDF,
      profile: localStorage.profile,
      actionsList: false,
      investors: [],
      loaderAlert: false,
      userStatus: ['i-0', 'i-1', 'i-2', 'i-3'],
      specific_founders: ['Female', 'Color', 'LGBTQ'],
      specifications: '',
      allStages: ['seed_stage', 'series_a', 'series_b', 'later_stage'],
      allIndustries: [],
      allLocation: [],

      singleSelect: false,
      selected: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'User-Name', value: 'user_name', sortable: false },
        { text: 'VC Firm Name', value: 'firm_name', sortable: false },
        { text: 'City', value: 'city', sortable: false },
        { text: 'Country', value: 'country' },
        { text: 'Application Date', value: 'terms_accepted_at' },
        { text: 'Status', value: 'role_id' },
        { text: 'Yearly Budget', value: 'yearly_budget', align: 'right' },
        { text: 'Investment Range', value: 'check_size', align: 'right' }, 
        { text: 'Sent', value: 'sent', align: 'center', sortable: false },
        { text: 'Review', value: 'review', align: 'center', sortable: false },
        { text: 'Menu', value: 'actions', sortable: false },
      ],

      specific_profile: {
        female_founder: false,
        founder_of_color: false,
        lgbtq_founder: false,
      },

      searchData: {
        role_id: null,
        search: null,
        date_from: null,
        date_to: null,
        user_id: null,
        location: null,
        continent: null,
        investing_in: null,
        industry: [],
        min_check_size: null,
        max_check_size: null,
        specific_founders: null,
        investment_stage: null,
        average: null
      },

      investor_fields: {
        'ID': 'id',
        'User-Name': 'user_name',
        'VC Firm Name': 'firm_name',
        'City': 'city',
        'Country' : 'country',
        'Application Date' : 'terms_accepted_at',
        'Status' : 'role_id',
        'Yearly Budget' : 'yearly_budget',
        'Investment Range' : 'check_size',
        'Sent' : 'sent',
        'Review' : 'review',
      },
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
    }
  },
  created(){
    if(!this.profile){
      this.$router.push('/admin');
    }
  },
  mounted(){
    this.getInvestors();

    AllVar.getAllIndustries().then((res) => {
      this.allIndustries = res.data;
    });

    AllVar.getAllLocations().then((res) => {
      this.allLocation = res.data;
    });
  },
  methods:{
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
      } else {
          return true;
      }
    },
    updateSpecific(){
      this.searchData.specific_founders = {
        "female_founder": this.specifications.includes("Female") ? 1 : 0,
        "founder_of_color": this.specifications.includes("Color") ? 1 : 0,
        "lgbtq_founder": this.specifications.includes("LGBTQ") ? 1 : 0,
      }
    },
    hideActionsList(){
      this.actionsList = false
    },
    showActionsList(item){
      this.actionsList == item.id ? (this.actionsList = null) : (this.actionsList = item.id);
    },
    getCity(address) {
      let city = JSON.parse(address);
      return city.city;
    },
    formatDate(date){
      let dat = new Date(date);
      let year = dat.getFullYear();
      let month = dat.getMonth() + 1;
      let day = dat.getDate();
      if(day < 10 && month < 10){
        return `0${day}/0${month}/${year}`;
      }
      else if (month < 10) {
        return `${day}/0${month}/${year}`;
      }
      else{
        return `${day}/${month}/${year}`;
      }
    },

    formatInvestorArray (arr) {
      this.investors = [];
      for (const element of arr) {
        this.investors.push(
          {
            id: element.id,
            'user_name': `${element.first_name} ${element.last_name}`,
            'firm_name': element.investor_accreditation && element.investor_accreditation.firm_name ? element.investor_accreditation.firm_name : '----',
            'city': element.investor_accreditation ? this.getCity(element.investor_accreditation.address) : '',
            'country': element.investor_accreditation ? element.investor_accreditation.country : '',
            'terms_accepted_at': element.investor_status ? this.formatDate(element.investor_status.terms_accepted_at) : '',
            'role_id': element.role_id,
            'yearly_budget': element.investor_accreditation ? this.formatToString(element.investor_accreditation.yearly_budget) : '',
            'check_size': element.investor_accreditation ? `${this.formatToString(element.investor_accreditation.min_check_size)} - ${this.formatToString(element.investor_accreditation.max_check_size)}` : '',
            'sent': '0',
            'review': '0',
            'initial_application_pdf': element.investor_status ? element.investor_status.initial_application_pdf : null,
            'last_application_pdf': element.investor_status ? element.investor_status.last_application_pdf : null,
            'email': element.email
          }
        )
      }
    },

    getInvestors() {
      Investors.getAllInvestors().then(res=>{
        if(res.status == 200){
          this.formatInvestorArray(res.data);
        }
      })
    },

    getInvestorsBySearch() {
      let industries = [];
      for (const element of this.searchData.industry) {
        industries.push(element.replaceAll('/',' '));
      }
      this.searchData.industry = industries;

      Investors.getInvestorBySearch(this.searchData).then((res) => {
        if (res.status == 200) {
          this.formatInvestorArray(res.data);
        } else {
          console.log("Error", res);
        }
      })
    },

    confirmApplication(user_id) {
      this.loaderAlert = true;
      Investors.updateUserInvestorStatus(user_id).then(res=>{
        if(res.status == 200){
          this.loaderAlert = false;
          this.$fire({
            title: "Update User Status",
            text: res.data,
            type: "success",
            timer: 3000
          })
          this.getInvestors();
        } else {
          this.loaderAlert = false;
          this.$fire({
            title: "Update User Status",
            text: res.data.message,
            type: res.data.type,
            timer: 3000
          })
        }
      })
    }
  }

}
</script>
<style src="../../../assets/styles/admin/style.css"> </style>