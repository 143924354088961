<template>
  <v-dialog v-model="active_dialog" max-width="500px">
    <v-card min-height="200px" style="overflow-x: hidden; overflow-y: hidden">
      <v-card-title class="justify-center">
        <span v-if="this.is_company" id="title" class="mb-n3">Company Dashboard</span>
        <span v-if="!this.is_company" id="title" class="mb-n3">Investor Dashboard</span>
      </v-card-title>
      <v-sheet height="100%" class="mx-4 my-0" rounded>
        <v-img
        class=" my-3"
          src="../assets/img/coming_soon.png"
          height="30%"
          width="100%"
        >
        </v-img>
        <v-card-text>
          <v-container>
            <v-row id="sub-text" class="text-left mt-n5">
              <p>
                Because we want the best for you, we are working hard to build
                the new <span v-if="this.is_company">company</span
                ><span v-if="!this.is_company">investor</span> dashboard where
                you will be able to update your data and
                <span v-if="this.is_company"
                  >administrate your billing information</span
                ><span v-if="!this.is_company">administrative information</span
                >.
              </p>
              <p>
                The new release of the
                <span v-if="this.is_company">company</span
                ><span v-if="!this.is_company">investor</span> dashboard will be
                online very soon.
              </p>
              <p v-if="this.is_company">
                Meanwhile you will receive notifications from us when your
                application will be confirmed and when investors express
                interests in your company.
              </p>
              <p v-if="!this.is_company">
                Once your application will be confirmed, you will receive our
                newsletter with investment opportunities. For each investment
                opportunity you want to learn more about, just click on the link
                and we will send you a NDA before sharing with you all the
                information about the deal.
              </p>
            </v-row>
            <v-row id="sub-text" class="text-left mt-n2 mb-n9">
              <p>
                <br />
                <a href="/contact">Contact Us</a> for any further
                information or questions.
              </p>
            </v-row>
          </v-container>
        </v-card-text>
      </v-sheet>

      <v-card-actions class="justify-center">
        <v-btn
          id="btn-verify"
          center
          class="white--text my-3"
          color="#514d7a"
          width="275"
          height="55"
          rounded
          @click.stop.prevent="closeDialog"
          :disabled="false"
        >
          Go to HomePage
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: { is_company: Boolean },

  data() {
    return {
      //CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
      active_dialog: true,
    };
  },

  methods: {
    closeDialog() {
      window.open('/home', "_top");
      //this.active_dialog=false;
    },
  },
};
</script>

<style scoped lang="css">
#title {
  font-weight: bolder;
  font-size: 18px;
}
#sub-text p{
  font-weight: normal;
  font-size: 14px;
}
</style>
