<template>
    <div class="mar-top-100">
        <v-col/>
        <v-row align="center" justify="center">
            <v-col cols="0" sm="1"/>
            <v-col cols="11" sm="11">
                <p class="cyan--text text--darken-1 text-sm-left" id="title">Legal Representative</p>
                
                <v-row>
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.representative.company_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Legal name of the company</p>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <FormInlineMessage v-if="$v.representative.company_name.$error" class="errmsg">
                            Indicate the legal name of the company
                        </FormInlineMessage>
                        <v-text-field
                        v-model="representative.company_name"
                        outlined
                        dense
                        @blur="$v.representative.company_name.$touch()"/>
                    </v-col>
                </v-row>

                <v-row class="mt-n5">
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.representative.first_name.$error || $v.representative.last_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Legal representative</p>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <FormInlineMessage v-if="$v.representative.first_name.$error || $v.representative.last_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <v-autocomplete
                            v-model="representative.gender"
                            :items="genders"
                            outlined
                            dense
                        />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.representative.first_name.$error" class="errmsg">
                            Indicate first name
                        </FormInlineMessage>
                        <FormInlineMessage v-else-if="$v.representative.last_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <v-text-field
                        v-model="representative.first_name"
                        outlined
                        dense
                        placeholder="first name"
                        @blur="$v.representative.first_name.$touch()"/>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.representative.last_name.$error" class="errmsg">
                            Indicate last name
                        </FormInlineMessage>
                        <FormInlineMessage v-else-if="$v.representative.first_name.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <v-text-field
                        v-model="representative.last_name"
                        outlined
                        dense
                        placeholder="last name"
                        @blur="$v.representative.last_name.$touch()"/>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12">
                        <p class="text-sm-left">HQ Address</p>
                    </v-col>
                </v-row>

                    <v-row>
                        <v-col cols="0" sm="1"/>
                        <v-col cols="12" sm="10">
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <FormInlineMessage v-if="$v.representative.street.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <p class="text-sm-left">Street</p>
                                </v-col>
                                <v-col cols="12" sm="10">
                                    <FormInlineMessage v-if="$v.representative.street.$error" class="errmsg">
                                        Indicate street
                                    </FormInlineMessage>
                                    <v-text-field
                                    v-model="representative.street"
                                    outlined
                                    dense
                                    @blur="$v.representative.street.$touch()"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="0" sm="1"/>

                        <v-col cols="0" sm="1"/>
                        <v-col cols="12" sm="10" class="mt-n10">
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <FormInlineMessage v-if="$v.representative.zip.$error || $v.representative.city.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <p class="text-sm-left">ZIP code</p>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <FormInlineMessage v-if="$v.representative.zip.$error" class="errmsg">
                                        Indicate zip code
                                    </FormInlineMessage>
                                    <FormInlineMessage v-else-if="$v.representative.city.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <v-text-field
                                    v-model="representative.zip"
                                    outlined
                                    dense
                                    @blur="$v.representative.zip.$touch()"/>
                                </v-col>
                                <v-col cols="0" sm="2"/>
                                <v-col cols="12" sm="2">
                                    <FormInlineMessage v-if="$v.representative.zip.$error || $v.representative.city.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <p class="text-sm-left">City</p>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <FormInlineMessage v-if="$v.representative.city.$error" class="errmsg">
                                        Indicate city
                                    </FormInlineMessage>
                                    <FormInlineMessage v-else-if="$v.representative.zip.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <v-text-field
                                    v-model="representative.city"
                                    outlined
                                    dense
                                    @blur="$v.representative.city.$touch()"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="0" sm="1"/>

                        <v-col cols="0" sm="1"/>
                        <v-col cols="12" sm="10">
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <FormInlineMessage v-if="$v.representative.country.$error" class="errmsg">
                                        <v-col cols='0' sm='12'/>
                                    </FormInlineMessage>
                                    <p class="text-sm-left">Country</p>
                                </v-col>
                                <v-col cols="12" sm="10">
                                    <FormInlineMessage v-if="$v.representative.country.$error" class="errmsg">
                                        Indicate country
                                    </FormInlineMessage>
                                    <v-select
                                    :items="countries"
                                    v-model="representative.country"
                                    outlined
                                    dense
                                    @blur="$v.representative.country.$touch()"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="0" sm="1"/>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="3">
                        <FormInlineMessage v-if="$v.phoneValid.$error" class="errmsg">
                            <v-col cols='0' sm='12'/>
                        </FormInlineMessage>
                        <p class="text-sm-left">Phone</p>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <FormInlineMessage v-if="$v.phoneValid.$error" class="errmsg">
                            Indicate a valid phone number
                        </FormInlineMessage>
                        <vue-tel-input
                        v-model="phone"
                        defaultCountry="country"
                        ref="phoneComplete"
                        @validate="validatePhone"
                        @blur="$v.phoneValid.$touch()"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row align="center" justify="center" class="pt-2">
            <v-col cols="11" sm="1">
                <v-btn
                id="btn-photo"
                class="white--text mb-3"
                style="font-weight: bold; font-size: 14px"
                color="#11aec9"
                min-width="20"
                rounded
                block
                :disabled="false"
                @click.stop.prevent="saveLegalRepresentative()">
                    save
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import CompanyService from "@/store/services/company/company.service";
import Var from "@/store/services/var";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
    components: {
        FormInlineMessage,
    },

    props: {
        company_id: Number,
    },

    data() {
        return {
            genders: ["Mr.", "Ms.", "Mrs."],
            countries: [],
            phoneValid: false,
            phone: null,
            representative: {
                company_name: null,
                gender: null,
                first_name: null,
                last_name: null,
                country: null,
                street: null,
                zip: null,
                city: null,
                phone: null,
                date_incorporation: "",
            }
        }
    },

    validations: {
        phoneValid: {
            sameAs: sameAs(() => true),
        },
        representative: {
            required,
            company_name: { required },
            first_name: { required },
            last_name: { required },
            country: { required },
            street: { required },
            zip: { required },
            city: { required },
            date_incorporation: { required },
        },
    },

    methods: {
        validatePhone(params) {
            this.phoneValid = params.valid;
            this.representative.phone = params.number;
        },

        saveLegalRepresentative() {
            this.$v.$touch();
            if (this.$v.$error) { return; };

            CompanyService.saveDescribe(this.representative).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Legal representative saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Error saving legal representative !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });
        }
    },

    mounted() {
        Promise.all([
            Var.getAllCountry().then((res) => { this.countries = res.data; }),
            CompanyService.getDescribe().then((res) => {
            this.representative.company_name = res.data.company_name;
            this.representative.gender = res.data.gender;
            this.representative.first_name = res.data.first_name;
            this.representative.last_name = res.data.last_name;
            this.representative.country = res.data.country;
            this.representative.street = res.data.street;
            this.representative.zip = res.data.zip;
            this.representative.city = res.data.city;
            this.representative.date_incorporation = res.data.date_incorporation;
            this.representative.phone = res.data.phone;
            this.phone = this.representative.phone;
            }),
        ])
    },
}
</script>