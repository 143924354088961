<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8">
      <v-row v-if="investment_type == 'firm'" no-gutters>
        <v-col cols="1" />
        <v-col cols="10" class="mt-10">
          <p class="text-sm-left">What is the legal name of your firm ?</p>
        </v-col>
        <v-row>
          <v-col cols="1" />
          <v-col cols="10" md="10" sm="10">
            <FormInlineMessage v-if="$v.describe.firm_name.$error" class="errmsg">
              Provide a valid name
            </FormInlineMessage>
            <v-text-field
              v-model="describe.firm_name"
              outlined
              dense
              placeholder=""
            />
          </v-col>
        </v-row>
      </v-row>

      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-col cols="1" />
        <v-col cols="11" class="mt-10">
          <p
            v-if="investment_type == 'firm'"
            class="text-sm-left bold"
          >
            What is the legal representative name ?
          </p>
          <p
            v-if="investment_type == 'individual'"
            class="text-sm-left bold"
          >
            What is your legal name ?
          </p>
        </v-col>

        <v-col cols="1" class="hidden-web"/>
        <v-col cols="10" md="2" sm="10">
          <v-autocomplete
            v-model="describe.gender"
            :items="allGenre"
            outlined
            dense
          />
        </v-col>

        <v-col cols="1" class="hidden-web"/>
        <v-col cols="1"/>
        <v-col cols="10" md="3" sm="10" align="center" justify="center">
          <FormInlineMessage
            v-if="$v.describe.first_name.$error"
            class="errmsg"
          >
            Provide a valid name
          </FormInlineMessage>
          <v-text-field
            v-model="describe.first_name"
            outlined
            dense
            placeholder="first name"
          />
        </v-col>

        <v-col cols="1"/>
        <v-col cols="10" md="3" sm="10">
          <FormInlineMessage v-if="$v.describe.last_name.$error" class="errmsg">
            Provide a valid name
          </FormInlineMessage>
          <v-text-field
            v-model="describe.last_name"
            outlined
            dense
            placeholder="last name"
          />
        </v-col>
      </v-row>

      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-col cols="10" md="3">
          <v-col sm="10" class="ml-n3">
            <p class="text-sm-left bold">Where are you based ?</p>
          </v-col>
        </v-col>
        <v-col cols="10" md="7">
          <FormInlineMessage v-if="$v.describe.country.$error" class="errmsg">
            Indicate where you are based
          </FormInlineMessage>
          <v-autocomplete
            v-model="describe.country"
            :items="country"
            outlined
            dense
          />
        </v-col>
        <!-- <v-col cols="1"/> -->
      </v-row>

      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-col cols="10" md="10">
          <v-col sm="10" class="ml-n3">
            <p
              v-if="investment_type == 'firm'"
              class="text-sm-left bold"
            >
              What is your Firm HQ Address ?
            </p>
            <p
              v-if="investment_type == 'individual'"
              class="text-sm-left bold"
            >
              What is your Home Address ?
            </p>
          </v-col>
        </v-col>

        <v-col cols="12" />

        <v-row>
          <v-col cols="2" />
          <v-col sm="2">
            <p class="text-sm-left bold">Street</p>
          </v-col>
          <v-col sm="7" class="ml-n1">
            <FormInlineMessage v-if="$v.describe.street.$error" class="errmsg">
              Provide a valid address
            </FormInlineMessage>
            <v-text-field
              v-model="describe.street"
              outlined
              dense
              placeholder=""
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-col cols="12" />

        <v-row>
          <v-col cols="2" />
          <v-col sm="2">
            <p class="text-sm-left bold">ZIP code</p>
          </v-col>
          <v-col sm="2" class="ml-n1">
            <FormInlineMessage v-if="$v.describe.zip.$error" class="errmsg">
              Provide a valid address
            </FormInlineMessage>
            <v-text-field v-model="describe.zip" outlined dense placeholder="">
            </v-text-field>
          </v-col>

          <v-col cols="1" />

          <v-col sm="2">
            <p class="text-sm-left bold">City*</p>
          </v-col>
          <v-col cols="2">
            <FormInlineMessage v-if="$v.describe.city.$error" class="errmsg">
              Provide a valid address
            </FormInlineMessage>
            <v-text-field v-model="describe.city" outlined dense placeholder="">
            </v-text-field>
          </v-col>
        </v-row>

        <v-col cols="12" />

        <v-row class="pb-2">
          <v-col cols="2" />
          <v-col sm="2">
            <p class="text-sm-left bold">Phone</p>
          </v-col>
          <v-col sm="7" class="ml-n1">
            <FormInlineMessage v-if="$v.phoneValid.$error" class="errmsg">
              Indicate a valid phone number
            </FormInlineMessage>
            <vue-tel-input
              v-model="phone"
              ref="phoneComplete"
              defaultCountry="country"
              @validate="validatePhone"
              class="mb-5"
              @blur="$v.phoneValid.$touch()"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />

        <v-row v-if="investment_type == 'firm'">
          <v-col cols="2" />
          <v-col sm="2">
            <p class="text-sm-left bold">Firm Website</p>
          </v-col>
          <v-col sm="7" class="ml-n1">
            <FormInlineMessage v-if="$v.describe.website.$error" class="errmsg">
              Provide a valid url
            </FormInlineMessage>
            <v-text-field
              v-model="describe.website"
              outlined
              dense
              placeholder="Enter a url of your company website"
              @blur="$v.describe.website.$touch()"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />
      </v-row>

      <v-row v-if="investment_type == 'firm'" align="center">
        <v-col cols="12" />
        <v-col cols="5" />
        <v-col cols="2">
          <v-card-actions class="justify-center">
            <v-hover v-slot:default="{ hover }">
              <v-img
                v-if="!photoBlock"
                max-height="300"
                max-width="300"
                @click.stop="dialogUp('Photo')"
                :src="imagePreviewUrl"
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    style="background-color: rgb(81, 77, 122); height: 100%"
                    class="d-flex transition-fast-in-fast-out v-img--reveal display-1 white--text align-center justify-center"
                  >
                    CHANGE YOUR LOGO
                  </div>
                </v-expand-transition>
              </v-img>
            </v-hover>
            <v-btn
              v-if="photoBlock"
              sm="2"
              id="btn-photo"
              class="white--text"
              color="#514d7a"
              height="90"
              min-width="20"
              rounded
              block
              :disabled="false"
              @click.stop="dialogUp('Photo')"
            >
              ADD LOGO*
            </v-btn>
          </v-card-actions>
          <p class="blue-grey--text font-weight-thin">
            Add your firm logo<br />Min 300x300 & Max 3 Mb
          </p>
        </v-col>
      </v-row>
    </v-sheet>

    <!-- dialog for Photo upload-->
    <v-dialog v-model="dialogPhoto" max-width="400">
      <PhotoForm
        @uploadPhoto="uploadLogo"
        @closePhoto="dialogDown('Photo')"
      ></PhotoForm>
    </v-dialog>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col></v-col>
        <v-col cols="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveDescribe"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import AccreditationService from "@/store/services/investor/accreditation.service";
import Var from "@/store/services/var";
import PhotoForm from "@/forms/PhotoForm";

import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import {
  minLength,
  maxLength,
  numeric,
  minValue,
  email,
  sameAs,
  url,
} from "vuelidate/lib/validators";

export default {
  components: {
    FormInlineMessage,
    PhotoForm,
  },

  props: {
    me: Object,
  },

  data() {
    return {
      imagePrevieUrl: null,
      dialogPhoto: false,
      photoBlock: true,
      formData: new FormData(),
      investment_type: null,

      currentBlock: true,
      allGenre: ["Mr.", "Ms.", "Mrs."],
      country: [],
      phoneValid: false,
      phone: null,
      describe: {
        firm_name: "",
        gender: "Mr.",
        first_name: "",
        last_name: "",
        country: "",
        street: "",
        zip: "",
        city: "",
        phone: null,
        website: "",
      },
    };
  },

  validations: {
    phoneValid: {
      sameAs: sameAs(() => true),
    },
    describe: {
      required,
      firm_name: {
        required: requiredIf(function () {
          return this.me.investment_type == "firm";
        }),
      },
      first_name: { required },
      last_name: { required },
      country: { required },
      street: { required },
      zip: { required },
      city: { required },
      website: {
        url//,
        // required: requiredIf(function () {
        //   return this.me.investment_type == "firm";
        // }),
      },
    },
  },

  methods: {
    dialogUp(element) {
      //console.log('dialogUp :'+element);
      this.$data["dialog" + element] = true;
    },

    dialogDown(element) {
      //console.log("dialogDown :" + element);
      this.$data["dialog" + element] = false;
    },

    uploadLogo(chosenImage) {
      this.imagePreviewUrl = URL.createObjectURL(chosenImage);
      this.formData.append("logo", chosenImage);

      AccreditationService.saveLogo(this.formData)
        .then((res) => {
          this.photoBlock = false;
          this.dialogDown("Photo");
        })
        .catch((e) => {
          //console.error(e);
        });
    },

    retrieveLogo() {
      AccreditationService.getLogo()
        .then((res) => {
          this.imagePreviewUrl = res.data;
          this.photoBlock = false;
        })
        .catch((error) => {
          this.photoBlock = true;
        });
    },

    validatePhone(params) {
      this.phoneValid = params.valid;
      this.describe.phone = params.number;
    },

    saveDescribe() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }

      AccreditationService.saveProfile(this.describe)
        .then((res) => {
          this.currentBlock = false;
          this.$emit("passFirmName", this.describe.firm_name);
          this.$emit("block2");
        })
        .catch((e) => {});
    },

    getDescribe() {
      AccreditationService.getProfile().then((res) => {
        if (res.data) {
          //console.log(res.data);
          this.describe.firm_name = res.data.firm_name;
          this.describe.first_name = res.data.legal_first_name;
          this.describe.last_name = res.data.legal_last_name;
          this.describe.country = res.data.country;
          this.describe.gender = res.data.gender;

          let temp = JSON.parse(res.data.address);
          this.describe.street = temp.street;
          this.describe.zip = temp.zip;
          this.describe.city = temp.city;

          this.describe.phone = res.data.phone;
          this.describe.website = res.data.website;

          this.phone = this.describe.phone;
        }
      });
    },

    getVariables() {
      Var.getAllCountry().then((res) => {
        this.country = res.data;
      });
    },

    getTypeOfAccreditation() {
      AccreditationService.getType()
        .then((res) => {
          if (res.data) {
            if (res.data.firm_or_individual) {
              this.investment_type = res.data.firm_or_individual;
            }
            return;
          }
        })
        .catch((e) => {
          //console.error(e);
        });
    },
  },

  created() {
    this.getVariables();
    this.getDescribe();
    this.retrieveLogo();
    this.getTypeOfAccreditation();
  },
};
</script>
<style scoped>
.hidden-web {
  display: none !important;
}
@media (max-width: 960px) {
  .hidden-web {
    display: block !important;
  }
}
</style>
