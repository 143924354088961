<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-10">
      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="1" />

        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            Go-To-Market Strategy
          </p>
          <v-col />

          <!-- Existing clients -->
          <div>
            <p class="text-sm-left thin">
              Do you have already clients or users for your product ? Who are
              they ? How many ?
            </p>
            <FormInlineMessage
              v-if="$v.strategy.existing_client.$error"
              class="errmsg"
            >
              Have you clients ?
            </FormInlineMessage>
            <v-textarea
              v-model="strategy.existing_client"
              outlined
              rows="2"
              dense
              placeholder=""
              @blur="$v.strategy.existing_client.$touch()"
            />
          </div>

          <!-- Earn Money -->
          <div>
            <p class="text-sm-left thin">
              Briefly describe the process of how you earn money<br />
              <span class="blue-grey--text text--lighten-2"
                >(from where the money originates to how it gets into your
                pocket).</span
              >
            </p>
            <FormInlineMessage
              v-if="$v.strategy.earn_money.$error"
              class="errmsg"
            >
              Explain how you earn money
            </FormInlineMessage>
            <v-textarea
              v-model="strategy.earn_money"
              outlined
              rows="2"
              dense
              @blur="$v.strategy.earn_money.$touch()"
            />
          </div>

          <!-- Customer Target -->
          <div>
            <p class="text-sm-left thin">
              Which customer segment(s) do you target and What is your strategy
              to reach those customers ?
            </p>
            <FormInlineMessage
              v-if="$v.strategy.customer_target.$error"
              class="errmsg"
            >
              Who are your target ?
            </FormInlineMessage>
            <v-textarea
              v-model="strategy.customer_target"
              outlined
              rows="2"
              dense
              @blur="$v.strategy.customer_target.$touch()"
            />
          </div>

          <!-- Growth Perspectives -->
          <div>
            <p class="text-sm-left thin">
              What growth prospective do you plan in the next 2 years ?
            </p>
            <FormInlineMessage
              v-if="$v.strategy.growth_perspectives.$error"
              class="errmsg"
            >
              What is your prospect ?
            </FormInlineMessage>
            <v-textarea
              v-model="strategy.growth_perspectives"
              outlined
              rows="2"
              dense
              @blur="$v.strategy.growth_perspectives.$touch()"
            />
          </div>

          <!-- Years -->
          <div>
            <p class="text-sm-left thin">
              What turnover in the next 3 years do you forecast ?
            </p>
            <v-row>
              <v-col cols="2">
                <p
                  class="text-sm-left blue-grey--text text--lighten-2 thin mt-5"
                >
                  Unit : USD K
                </p>
              </v-col>

              <!-- First Year -->
              <v-col cols="2">
                <p style="border: 1px solid; height: auto">Year 1</p>

                <FormInlineMessage
                  v-if="$v.strategy_year1.$error"
                  class="errmsg"
                >
                  Value required
                </FormInlineMessage>
                <FormInlineMessage v-if="is_strategy_year1_length_error.error" class="errmsg">
                  {{ is_strategy_year1_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="strategy_year1"
                  outlined
                  dense
                  @input="strategy_year1 = formatToString(strategy_year1), $v.strategy_year1.$touch()"
                  @keypress="isNumber($event)"
                />
              </v-col>

              <!-- Second Year -->
              <v-col cols="2">
                <p style="border: 1px solid; height: auto">Year 2</p>

                <FormInlineMessage
                  v-if="$v.strategy_year2.$error"
                  class="errmsg"
                >
                  Value required
                </FormInlineMessage>
                <FormInlineMessage v-if="is_strategy_year2_length_error.error" class="errmsg">
                  {{ is_strategy_year2_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="strategy_year2"
                  outlined
                  dense
                  @input="strategy_year2 = formatToString(strategy_year2), $v.strategy_year2.$touch()"
                  @keypress="isNumber($event)"
                />
              </v-col>

              <!-- Third Year -->
              <v-col cols="2">
                <p style="border: 1px solid; height: auto">Year 3</p>

                <FormInlineMessage
                  v-if="$v.strategy_year3.$error"
                  class="errmsg"
                >
                  Value required
                </FormInlineMessage>
                <FormInlineMessage v-if="is_strategy_year3_length_error.error">
                  {{ is_strategy_year3_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="strategy_year3"
                  outlined
                  dense
                  @input="strategy_year3 = formatToString(strategy_year3), $v.strategy_year3.$touch()"
                  @keypress="isNumber($event)"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="1" />
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveStrategy"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import BusinessService from "@/store/services/business.service";
import FormInlineMessage from "@/components/FormInlineMessage";
import { required } from "vuelidate/lib/validators";
import { myMixins } from "@/mixins/mixins.js";

export default {
  name: "Strategy",
  mixins: [myMixins],
  components: {
    FormInlineMessage,
  },

  // props: {
  //   company_id: Number
  // },

  data() {
    return {
      currentBlock: true,
      strategy: {
        company_id: "",
        existing_client: "",
        earn_money: "",
        customer_target: "",
        growth_perspectives: "",
      },
      strategy_year1: null, // int
      strategy_year2: null, // int
      strategy_year3: null, // int
      company_id: localStorage.getItem('company_id'),
    };
  },

  validations: {
    strategy: {
      existing_client: { required },
      earn_money: { required },
      customer_target: { required },
      growth_perspectives: { required },
    },
    strategy_year1: { required },
    strategy_year2: { required },
    strategy_year3: { required },
  },

  methods: {
    saveStrategy() {
      this.$v.$touch();
      if (
        this.$v.$error ||
        this.is_strategy_year1_length_error ||
        this.is_strategy_year2_length_error ||
        this.is_strategy_year3_length_error
      ) {
        return;
      }
      this.strategy_year1 = this.formatToInt(this.strategy_year1);
      this.strategy_year2 = this.formatToInt(this.strategy_year2);
      this.strategy_year3 = this.formatToInt(this.strategy_year3);

      this.strategy.year1 = this.strategy_year1;
      this.strategy.year2 = this.strategy_year2;
      this.strategy.year3 = this.strategy_year3;

      this.strategy.company_id = this.company_id;
      BusinessService.saveStrategy(this.strategy);
      this.strategy_year1 = this.strategy_year1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.strategy_year2 = this.strategy_year2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.strategy_year3 = this.strategy_year3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.currentBlock = false;
      this.$emit("block4");
    },

    getStrategy()
    {
      BusinessService.getStrategy(this.company_id).then((res) => {
        if(res.data){
          this.strategy = res.data
          this.strategy_year1 = this.strategy.year1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.strategy_year2 = this.strategy.year2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.strategy_year3 = this.strategy.year3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }).catch((e)=>{
        
      })
    },

  },

  mounted() {
    this.getStrategy()
  }
};
</script>
