import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      // Authorization: "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token")
    },
};

function login(payload) {
  return axios.post(`${url}/admin/company/login`, payload);
}

function getAllCompanies() {
  return axios.get(`${url}/admin/company/companies`)
}

function getCompany(user_id) {
  return axios.get(`${url}/admin/company/company-user/${user_id}`)
}

function getCompaniesBySearch(data) {
  return axios.get(`${url}/admin/company/search/companies/${JSON.stringify(data)}`)
}

function generateCompanyApplicationPdf() {
  return axios.get(`${url}/pdf/application`)
}

function downloadInitialApplication(company_id) {
  return axios.get(`${url}/pdf/download/${company_id}`)
}

function updateUserStatus(user_id) {
  return axios.get(`${url}/admin/company/application/status/${user_id}`)
}

function subscribeNewsletter(payload, renew) {
  return axios.post(`${url}/admin/company/newsletter/${renew}`, payload)
}

function getsubscribeNewsletter() {
  return axios.get(`${url}/admin/company/subscriber`)
}

function createCoupons(payload) {
  return axios.post(`${url}/admin/company/coupons`, payload)
}

function getCoupons() {
  return axios.get(`${url}/admin/company/coupons`)
}

function getCoupon(coupon_id) {
  return axios.get(`${url}/admin/company/coupon/${coupon_id}`)
}

function updateCoupon(payload, coupon_id) {
  return axios.post(`${url}/admin/company/coupon/update/${coupon_id}`, payload);
}

function deleteCoupon(coupon_id) {
  return axios.delete(`${url}/admin/company/coupon/delete/${coupon_id}`)
}

function getAllInfoCompanyById(company_id) {
  return axios.get(`${url}/admin/company/company/${company_id}`);
}

function getSkillsById(user_id) {
  return axios.get(`${url}/admin/company/skills/${user_id}`);
}

function getEducationsById(user_id) {
  return axios.get(`${url}/admin/company/educations/${user_id}`);
}

function admingetLogoCompany(company_id) {
  return axios.get(`${url}/admin/company/logo-profile/${company_id}`);
}

function admingetAvatarCompany(user_id) {
  return axios.get(`${url}/admin/company/avatar-profile/${user_id}`);
}

function filterInvestor(payload) {
  return axios.post(`${url}/admin/company/company-matching`, payload);
}

function getCompaniesSummary() {
  return axios.get(`${url}/admin/company/summary-companies`)
}

export default {  
  login,
  getAllCompanies,
  getCompany,
  getCompaniesBySearch,
  generateCompanyApplicationPdf,
  downloadInitialApplication,
  updateUserStatus,
  subscribeNewsletter,
  getsubscribeNewsletter,
  createCoupons,
  getCoupons,
  getCoupon,
  updateCoupon,
  deleteCoupon,
  getAllInfoCompanyById,
  getSkillsById,
  getEducationsById,
  admingetLogoCompany,
  admingetAvatarCompany,
  filterInvestor,
  getCompaniesSummary
};
