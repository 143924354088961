<template>
    <div>
      <HeaderSponsor class="d-none d-md-flex"/>
      <HeaderSponsorPortable class="d-flex d-md-none"/>
       <v-row align="center" justify="center" class="step-1">
            <v-col cols="12" md="10">
                <v-responsive :aspect-ratio="16 / 9">
                    <v-card-text>
                        <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
                            <v-row align="center" justify="center" class="pt-2">
                                <v-col cols="10">
                                    <h1 style="font-size: 2em;" class="cyan--text text--darken-1 font-weight-bold text-sm-center mb-5" id="title">Future sponsors</h1>
                
                                    <p class="text-sm-left thin mb-5">Your sponsorship will keep the tool going and help make it even better for both the entrepreneurs and investors.</p>
                
                                    <h2 style="font-size: 1.17em;" class="text-sm-center text-md-left font-weight-bold mb-2">Sponsor Benefits</h2>
                                    <p class="text-left thin">You will ...
                                        <ul>
                                            <li class="text-sm-left thin">be listed as a Sponsor in the IdeasFundX landing page.</li>
                                            <li class="text-sm-left thin">feels good about supporting a platform that helps talented entrepreneurs to raise funding for their growth and provide qualified deal flow to investors.</li>
                                            <li class="text-sm-left thin">drive awareness for your products or services whilst expanding you good will.</li>
                                            <li class="text-sm-left thin">convert talk into action in supporting entrepreneurship and economic development.</li>
                                            <li class="text-sm-left thin">be part of the success stories of promising innovative business.</li>
                                            <li class="text-sm-left thin">receive our IO newletter with exciting investment opportunities.</li>
                                        </ul>
                                    </p>
                
                                    <h2 style="font-size: 1.17em;" class="text-sm-center text-md-left font-weight-bold mb-2">Goal</h2>
                                    <p class="text-sm-left thin mb-5">When we reach +200 sponsors, we'll be able to create added services for both the +350 investors and + 970 talented founders on the platform.<br></p>

                                    <h2 style="font-size: 1.17em;" class="text-sm-center text-md-left font-weight-bold mb-2">How do I become a sponsor</h2>
                                    <p class="text-sm-left thin">
                                        Give the amount you want.<br>
                                        We sent you an email with you receipt and a form to complete.<br>
                                        And send us back the form with your logo for sharing.
                                    </p>
                                </v-col>
                            </v-row>
                        </v-sheet>

                        <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center" color="#00b088">
                            <p class="text-sm-center py-10" style="color:white; font-size: 24px; margin-left: 4%">Become a Sponsor</p>
                        </v-sheet>

                        <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
                            <v-row align="center" justify="center" class="pt-2">
                                <v-col md="10" sm="10">

                                                                  
                                    <v-row class="mb-n10 mt-n5">
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <FormInlineMessage v-if="$v.sponsor.first_name.$error">
                                                  <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">First name*</p>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="12">
                                            <FormInlineMessage v-if="$v.sponsor.first_name.$error">
                                                Please provide a valid first name
                                            </FormInlineMessage>
                                            <v-text-field
                                            v-model="sponsor.first_name"
                                            outlined
                                            dense
                                            @blur="$v.sponsor.first_name.$touch()"
                                            />
                                        </v-col>
                                    </v-row>
                                    
                                    
                                    <v-row class="mb-n10">
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <FormInlineMessage v-if="$v.sponsor.last_name.$error">
                                                <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">Last name*</p>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="12">
                                            <FormInlineMessage v-if="$v.sponsor.last_name.$error">
                                                Please provide a valid last name
                                            </FormInlineMessage>
                                            <v-text-field
                                            v-model="sponsor.last_name"
                                            outlined
                                            dense
                                            @blur="$v.sponsor.last_name.$touch()"/>
                                        </v-col>
                                    </v-row>

                                    
                                    <v-row class="mb-n10">
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <FormInlineMessage v-if="$v.sponsor.email.$error">
                                                <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">E-mail Address*</p>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="12">
                                            <FormInlineMessage v-if="$v.sponsor.email.$error">
                                                Please provide a valid email address
                                            </FormInlineMessage>
                                            <v-text-field
                                            v-model="sponsor.email"
                                            outlined
                                            dense
                                            @blur="$v.sponsor.email.$touch()"
                                            />
                                        </v-col>
                                    </v-row>

                                    
                                    <v-row class="mb-n10">
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <FormInlineMessage v-if="$v.sponsor.password.$error">
                                                <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">Password*</p>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="12">
                                            <FormInlineMessage v-if="$v.sponsor.password.$error">
                                                Please provide a valid password, 8 chars minumum
                                            </FormInlineMessage>
                                            <v-text-field
                                            v-model="sponsor.password"
                                            outlined
                                            dense
                                            name="password"
                                            type="password"
                                            @blur="$v.sponsor.password.$touch()"
                                            />
                                        </v-col>
                                    </v-row>

                                    
                                    <v-row class="mb-n10">
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <FormInlineMessage v-if="$v.sponsor.confirm_password.$error">
                                                <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">Confirm Password*</p>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="12">
                                            <FormInlineMessage v-if="$v.sponsor.confirm_password.$error">
                                                Please provide a matching password
                                            </FormInlineMessage>
                                            <v-text-field
                                            v-model="sponsor.confirm_password"
                                            outlined
                                            dense
                                            name="confirm-password"
                                            type="password"
                                            @blur="$v.sponsor.confirm_password.$touch()"/>
                                        </v-col>
                                    </v-row>

                                    
                                    <v-row class="mb-n10">
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <FormInlineMessage v-if="$v.sponsor.title.$error">
                                                <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">Title*</p>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="12">
                                            <FormInlineMessage v-if="$v.sponsor.title.$error">
                                                Please provide a valid title
                                            </FormInlineMessage>
                                            <v-text-field
                                            v-model="sponsor.title"
                                            outlined
                                            dense
                                            @blur="$v.sponsor.title.$touch()"/>
                                        </v-col>
                                    </v-row>

                                    
                                    <v-row>
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <FormInlineMessage v-if="$v.sponsor.company.$error">
                                                <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">Company*</p>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="12">
                                            <FormInlineMessage v-if="$v.sponsor.company.$error">
                                                Please provide a valid company name
                                            </FormInlineMessage>
                                            <v-text-field
                                            v-model="sponsor.company"
                                            outlined
                                            dense
                                            @blur="$v.sponsor.company.$touch()"/>
                                        </v-col>
                                    </v-row>

                                    <p class="test-sm-left mb-1" style="text-align: left;">Your Address*</p>
                                    
                                    
                                    <v-row class="mb-n10">
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <FormInlineMessage v-if="$v.sponsor.country.$error">
                                                <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">Country</p>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="12">
                                            <FormInlineMessage v-if="$v.sponsor.country.$error">
                                                Please provide a valid country
                                            </FormInlineMessage>
                                            <v-autocomplete
                                            v-model="sponsor.country"
                                            :items="countrylist"
                                            outlined
                                            dense
                                            @change="getCurrency(); computePrice();"
                                            @blur="$v.sponsor.country.$touch()"/>
                                        </v-col>
                                    </v-row>

                                    
                                    <v-row class="mb-n10">
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <FormInlineMessage v-if="$v.sponsor.street.$error">
                                                <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">Street</p>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="12">
                                            <FormInlineMessage v-if="$v.sponsor.street.$error">
                                                Please provide a valid street address
                                            </FormInlineMessage>
                                            <v-text-field   
                                            v-model="sponsor.street"
                                            outlined
                                            dense
                                            @blur="$v.sponsor.street.$touch()"/>
                                        </v-col>
                                    </v-row>

                                    
                                    <v-row class="mb-n10">
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <FormInlineMessage v-if="$v.sponsor.zip.$error">
                                                <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">ZIP code</p>
                                        </v-col>
                                        <v-col cols="12" md="2" sm="12">
                                            <FormInlineMessage v-if="$v.sponsor.zip.$error">
                                                Please provide a valid ZIP
                                            </FormInlineMessage>
                                            <v-text-field   
                                            v-model="sponsor.zip"
                                            outlined
                                            dense
                                            @blur="$v.sponsor.zip.$touch()"/>
                                        </v-col>
                                    
                                        <v-col cols="1"/>

                                    
                                        <v-col cols="12" md="2" sm="12" class="">
                                            <FormInlineMessage v-if="$v.sponsor.city.$error"> 
                                                <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">City</p>
                                        </v-col>
                                        <v-col cols="12" md="2" sm="12" class="">
                                            <FormInlineMessage v-if="$v.sponsor.city.$error">
                                                Please provide a valid city
                                            </FormInlineMessage>
                                            <v-text-field   
                                            v-model="sponsor.city"
                                            outlined
                                            dense
                                            @blur="$v.sponsor.city.$touch()"/>
                                        </v-col>
                                    </v-row>

                                    
                                    <v-row class="mb-n8">
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <FormInlineMessage v-if="$v.phoneValid.$error">
                                                <br>
                                            </FormInlineMessage>
                                            <p class="text-sm-left">Phone*</p>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="12">
                                            <FormInlineMessage v-if="$v.phoneValid.$error">
                                                Please provide a valid phone number
                                            </FormInlineMessage>
                                            <vue-tel-input
                                            v-model="phone"
                                            ref="phoneComplete"
                                            @validate="validatePhone"
                                            class="mb-5"
                                            @blur="$v.phoneValid.$touch()"/>
                                        </v-col>
                                    </v-row>

                                    
                                    <v-row>
                                        <v-col cols="12" md="2" sm="12" class="mb-n9">
                                            <p class="text-sm-left">VAT Number</p>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="12">
                                        <v-text-field
                                            v-model="sponsor.vat"
                                            outlined
                                            dense/>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-sheet>

                        <v-sheet height="100%" class="mx-auto mt-n5" align="center" justify="center">
                            <v-row align="center" justify="center" class="pt-2">
                                <v-col cols="10">
                                    <p class="cyan--text text--darken-1 font-weight-bold text-sm-center mb-10" id="title">Sponsorship packages</p>
                                    
                                    <v-row class="mb-10 justify-content-center align-items-center">
                                      <v-col cols="3" md="1" style="background-color: #7889c6; height: 85px;">
                                        <p class="mt-5" style="color: white; font-size: 18px;">$37</p>
                                      </v-col>
                                      <v-col cols="9" md="5" style="background-color: #514d7a; height: 85px;">
                                        <p style="color: white; text-align: center; font-weight: normal; font-size: 14px;">For 1 month, receive the IO newsletters with selected opportunities</p>
                                      </v-col>
                                    </v-row>
                                    
                                    <v-row class="mb-10 justify-content-center align-items-center">
                                      <v-col class="package-price" cols="3" md="1" style="background-color: #7889c6; height: 85px;">
                                        <p class="mt-5" style="color: white; font-size: 18px;">$250</p>
                                      </v-col>
                                      <v-col class="package-content" cols="9" md="5" style="background-color: #514d7a; max-height: 85px;">
                                        <p style="color: white; text-align: center; font-weight: normal; font-size: 14px;">For 3 months, be listed among our sponsors on the website and receive the IO newsletters with selected opportunities</p>
                                      </v-col>
                                    </v-row>

                                    <v-row class="mb-10 justify-content-center align-items-center">
                                      <v-col class="package-price" cols="3" md="1" style="background-color: #7889c6; height: 85px;">
                                        <p class="mt-5" style="color: white; font-size: 18px; background-color: #7889c6">$500</p>
                                      </v-col>
                                      <v-col class="package-content" cols="9" md="5" style="background-color: #514d7a; max-height: 85px;">
                                        <p style="color: white; text-align: center; font-weight: normal; font-size: 14px;">For 1 year, be listed among our sponsors on the website and receive the IO newsletters with selected opportunities</p>
                                      </v-col>
                                    </v-row>

                                    <v-row class="justify-content-center align-items-center">
                                      <v-col class="package-price bloc-4" cols="3" md="1" style="background-color: #7889c6; height: 85px;">
                                        <p class="mt-5" style="color: white; font-size: 18px; background-color: #7889c6">$3500</p>
                                      </v-col>
                                      <v-col class="package-content bloc-4" cols="9" md="5" style="background-color: #514d7a; max-height: 85px;">
                                        <p style="color: white; text-align: center; font-weight: normal; font-size: 14px;">For 2 years, be listed among our sponsors on the website, the newsletters and receive the IO newsletters with selected opportunities</p>
                                      </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-sheet>

                        <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
                            <v-row align="center" justify="center" class="pt-2">
                                <v-col cols="10" md="6">
                                    <p class="cyan--text text--darken-1 font-weight-bold text-sm-center mb-10" id="title">Sponsor our Tool Now!</p>

                                    <v-radio-group v-model="sponsor.payment" class="mb-16 text-sm-center">
                                        <v-row>
                                            <v-radio :value="'one-time'" />
                                            <p class="thin">One-Time Payment</p>
                                        </v-row>
                                        <v-row>
                                            <v-radio :value="'monthly'" />
                                            <p class="thin">Monthly Payment</p>
                                        </v-row>
                                    </v-radio-group>

                                    <v-col/>

                                    <v-btn
                                        style="border: 3px solid #00aac2; border-radius: 0px; width: 100%; font-size: 16px; font-weight: bold;"
                                        class="py-7 mb-7"
                                        @click="value = computeValue(37); computePrice();"
                                        v-bind:style="value == computeValue(37) ? 'color: white; background: #00aac2' : 'color: black; background-color: white'">
                                        {{currency == "USD" ? '$37' : '€' + computeValue(37)}}{{sponsor.payment=="monthly" ? "/month" : ""}}
                                    </v-btn>

                                    <v-btn
                                        style="border: 3px solid #00aac2; border-radius: 0px; width: 100%; font-size: 16px; font-weight: bold;"
                                        class="py-7 mb-7"
                                        @click="value = computeValue(250); computePrice();"
                                        v-bind:style="value == computeValue(250) ? 'color: white; background: #00aac2' : 'color: black; background-color: white'">
                                        {{currency == "USD" ? '$250' : '€' + computeValue(250)}}{{sponsor.payment=="monthly" ? "/month" : ""}}
                                    </v-btn>

                                    <v-btn
                                        style="border: 3px solid #00aac2; border-radius: 0px; width: 100%; font-size: 16px; font-weight: bold;"
                                        class="py-7 mb-7"
                                        @click="value = computeValue(500); computePrice();"
                                        v-bind:style="value == computeValue(500) ? 'color: white; background: #00aac2' : 'color: black; background-color: white'">
                                        {{currency == "USD" ? '$500' : '€' + computeValue(500)}}{{sponsor.payment=="monthly" ? "/month" : ""}}
                                    </v-btn>

                                    <v-btn
                                        style="border: 3px solid #00aac2; border-radius: 0px; width: 100%; font-size: 16px; font-weight: bold;"
                                        class="py-7 mb-7"
                                        @click="value = computeValue(3500); computePrice();"
                                        v-bind:style="value == computeValue(3500) ? 'color: white; background: #00aac2' : 'color: black; background-color: white'">
                                        {{currency == "USD" ? '$3500' : '€' + computeValue(3500)}}{{sponsor.payment=="monthly" ? "/month" : ""}}
                                    </v-btn>

                                    <div v-if="value">
                                        <p class="text-sm-left thin">Payment amount<span v-if="sponsor.payment=='monthly'"> per month</span></p>

                                        <v-text-field
                                        v-model="value"
                                        @blur="$v.value.$touch()"
                                        v-bind:prefix="currency == 'USD' ? '$' : '€'"
                                        dense
                                        @change="computePrice()"/>
                                    </div>
                               </v-col>
                            </v-row>
                        </v-sheet>
                        <v-sheet v-if="value" height="100%" class="mx-auto mt-8" align="center" justify="center">
                            <v-row align="center" justify="center" class="pt-2">
                                <v-col cols="10">
                                    <div v-if="this.inEEC()">
                                        <p class="blue-grey--text text--lighten-2 font-weight-thin">VAT base {{'€' + valueEUR}}</p>
                                        <p class="blue-grey--text text--lighten-2 font-weight-thin">VAT 20% {{'€' + taxEUR}}</p>
                                    </div>
                                    <FormInlineMessage v-if="minimumValue() && temp">
                                        The value is too low
                                    </FormInlineMessage>
                                    <p class="thin" style="font-size: 28px;">Total<span v-if="sponsor.payment=='monthly'"> per month</span></p>
                                    <p style="font-size: 28px;">{{currency == "USD" ? '$' + totalUSD : '€' + totalEUR}}</p>
                                    <div style="text-align: center; display:inline;">
                                      <label class="inputCheckBox">
                                        <input
                                          type="checkbox"
                                          name="consent" id="consent" @click = "fee = !fee; computePrice()"
                                        />
                                        <span class="checkmark"></span>
                                        <span style="margin-top: 100px !important;">Cover fees so IdeasFundX gets my full sponsorship</span>
                                      </label>
                                        <!-- <input type="checkbox" name="consent" id="consent" @click = "fee = !fee; computePrice()"> -->
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row align="center" justify="center" class="pt-2">
                              <v-col cols="8" md="3" sm="5">
                                <v-btn
                                  id="btn-photo"
                                  class="white--text mb-3"
                                  style="font-weight: bold; font-size: 24px"
                                  color="#514d7a"
                                  height="90"
                                  min-width="20"
                                  rounded
                                  block
                                  :disabled="false"
                                  @click.stop.prevent="payment(); temp=true;">
                                  PAY NOW
                                </v-btn>
                                <p class="blue-grey--text text--lighten-2 font-weight-thin">Note that you can pay directly with credit card or with your paypal account with Paypal</p>
                              </v-col>
                            </v-row>
                        </v-sheet>
                    </v-card-text>

                    <v-dialog v-model="dialogPaymentSuccess" max-width="500px">
                        <v-card
                            min-height="200px"
                            style="overflow-x: hidden; overflow-y: hidden"
                        >
                            <v-card-title class="justify-center">
                            <span id="title">Payment Status</span>
                            </v-card-title>
                            <v-sheet height="100%" class="mx-3 my-0" color="#c5e7ec" rounded>
                            <v-card-text>
                                <v-container>
                                    <v-row class="text-left sub-title-code mt-n5">
                                        <p>
                                    Your sponsor profile has been successfully created.
                                    Once your payment is confirmed, you will receive an email confirmation
                                    with your invoice and the IdeasFundX team will start the review of
                                    your information.</p>
                                    </v-row>
                                    <v-row  class="text-left sub-title-code mt-n2 mb-n9">
                                    <p><br>
                                    If you do not receive an email within the
                                    next 15 minutes, or if you have any questions, 
                                    please <a v-bind:href="CMScontact">contact us</a></p>
                                    </v-row>

                                </v-container>
                            </v-card-text>
                            </v-sheet>
                            <v-card-actions class="justify-center">
                            <v-btn
                                id="btn-verify"
                                center
                                class="white--text my-3"
                                color="#514d7a"
                                width="275"
                                height="55"
                                rounded
                                @click.stop.prevent="redirectToCMS"
                                :disabled="false"
                            >
                                Return to Home Page
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogPaymentFail" max-width="500px">
                        <v-card
                            min-height="200px"
                            style="overflow-x: hidden; overflow-y: hidden"
                        >
                            <v-card-title class="justify-center">
                            <span id="title">Payment Interrupted</span>
                            </v-card-title>
                            <v-sheet height="100%" class="mx-3 my-0" color="#c5e7ec" rounded>
                            <v-card-text>
                                <v-container>
                                    <v-row class="text-left sub-title-code mt-n5">
                                        <p>
                                            Your sponsor profile has been successfully created, but
                                    an error was encountered during the processing of your payment.</p>
                                    </v-row>
                                    <v-row  class="text-left sub-title-code mt-n2 mb-n9">
                                    <p><br>
                                    For any questions, please <a v-bind:href="CMScontact">contact us</a> or click below to try again.</p>
                                    </v-row>

                                </v-container>
                            </v-card-text>
                            </v-sheet>
                            <v-card-actions class="justify-center">
                            <v-btn
                                id="btn-verify"
                                center
                                class="white--text my-3"
                                color="#514d7a"
                                width="275"
                                height="55"
                                rounded
                                @click.stop.prevent="skipRegisterToPay" 
                                :disabled="false"
                            >
                                Retry Payment
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogRegistrationFail" max-width="500px">
                        <v-card
                            min-height="200px"
                            style="overflow-x: hidden; overflow-y: hidden"
                        >
                            <v-card-title class="justify-center">
                            <span id="title">Registration Interrupted</span>
                            </v-card-title>
                            <v-sheet height="100%" class="mx-3 my-0" color="#c5e7ec" rounded>
                            <v-card-text>
                                <v-container>
                                    
                                        <v-row class="text-left sub-title-code mt-n5">
                                            <p>
                                        An error was encountered during the processing of your registration, check the provided email that it is valid, and try again in a few minutes.</p>
                                        </v-row>
                                        <v-row  class="text-left sub-title-code mt-n2 mb-n9">
                                        <p><br>
                                        For any questions, please <a v-bind:href="CMScontact">contact us</a> or click below to try again.</p>
                                        </v-row>
                                    
                                </v-container>
                            </v-card-text>
                            </v-sheet>

                            <v-card-actions class="justify-center">
                            <v-btn
                                id="btn-verify"
                                center
                                class="white--text my-3"
                                color="#514d7a"
                                width="275"
                                height="55"
                                rounded
                                @click.stop.prevent="closeDialog" 
                                :disabled="false"
                            >
                                Retry Registration
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-responsive>
            </v-col>
       </v-row>

       <Footer/>
    </div>
</template>

<script>
import Var from '@/store/services/var.js'
import Paypal from '@/store/services/paypal.service'
import Profile from '@/store/services/profile-service'
import Footer from '@/components/FooterCms.vue';
import HeaderSponsor from '@/components/HeaderSponsor.vue';
import HeaderSponsorPortable from '@/components/HeaderSponsorPortable.vue';
import {
  minLength,
  required,
  email,
  sameAs
} from 'vuelidate/lib/validators'
import FormInlineMessage from '@/components/FormInlineMessage'

export default {
  components: {
    FormInlineMessage,
    Footer,
    HeaderSponsor,
    HeaderSponsorPortable
  },
  data() {
    return {
      CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
      dialogPaymentSuccess: false,
      dialogPaymentFail: false,
      dialogRegistrationFail: false,
      skipRegistration: false,
      temp: false,
      countrylist: [],
      phoneValid: false,
      phone: null,
      fee: false,
      feeAmountEUR: 0,
      feeAmountUSD: 0,
      currency: 'USD',
      change: null,
      EEC: [
        'Austria',
        'Belgium',
        'Bulgaria',
        'Croatia',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Estonia',
        'Finland',
        'France',
        "France, Metropolitan",
        'Germany',
        'Greece',
        'Hungary',
        'Ireland',
        'Italy',
        'Latvia',
        'Lithuania',
        'Luxembourg',
        'Malta',
        'Netherlands',
        'Poland',
        'Portugal',
        'Romania',
        'Slovakia',
        'Slovenia',
        'Spain',
        'Sweden',
      ],
      value: null,
      valueUSD: null,
      valueEUR: null,
      taxUSD: null,
      taxEUR: null,
      totalUSD: null,
      totalEUR: null,
      vatEnabled: 0,
      sponsor: {
        email: null,
        first_name: null,
        last_name: null,
        password: null,
        confirm_password: null,
        title: null,
        company: null,
        country: null,
        street: null,
        zip: null,
        city: null,
        vat: null,
        phone: null,
        payment: 'one-time',
      },
    }
  },

  validations: {
    phoneValid: { sameAs: sameAs(() => true) },
    value: {
      required,
    },
    sponsor: {
      required,
      email: { required, email },
      first_name: { required, minLength: minLength(2) },
      last_name: { required, minLength: minLength(2) },
      password: {
        required,
        minLength: minLength(8),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs('password'),
      },
      title: { required, minLength: minLength(2) },
      company: { required, minLength: minLength(2) },
      country: { required },
      street: { required, minLength: minLength(2) },
      zip: { required, minLength: minLength(2) },
      city: { required, minLength: minLength(1) },
      payment: { required },
    },
  },

  methods: {
    updateGoogleAnalytics()
    {
      this.$gtag.event('Sponsor', { method: 'Google' });
    },
    skipRegisterToPay() {
      this.skipRegistration = true
      //get investor from user profile services, and fill the fields
      Profile.get()
        .then((res) => {
          if (res.data) {
            //console.log(res.data.sponsor_address)
            this.sponsor.email = res.data.email
            this.sponsor.first_name = res.data.first_name
            this.sponsor.last_name = res.data.last_name
            this.sponsor.title = res.data.sponsor_title
            this.sponsor.phone = res.data.sponsor_phone
            this.phone = res.data.sponsor_phone
            this.sponsor.company = res.data.sponsor_company
            this.sponsor.country = JSON.parse(res.data.sponsor_address).country
            this.sponsor.city = JSON.parse(res.data.sponsor_address).city
            this.sponsor.street = JSON.parse(res.data.sponsor_address).street
            this.sponsor.zip = JSON.parse(res.data.sponsor_address).zip
            this.sponsor.vat = JSON.parse(res.data.sponsor_address).vat

            this.sponsor.password = '12345678123123123' //placeholder to bypass v.error
            this.sponsor.confirm_password = '12345678123123123' //placeholder to bypass v.error
          }
        })
        .catch((e) => {
          //console.error(e)
        })
      this.dialogPaymentFail = false
    },
    redirectToCMS() {
      window.open(process.env.VUE_APP_CMS_URL, '_top')
    },
    closeDialog() {
      this.dialogRegistrationFail = false
    },
    inEEC: function () {
      if (this.EEC.includes(this.sponsor.country)) {
        return true
      }
      return false
    },

    minimumValue() {
      var min = this.computeValue(37)
      if (this.value < min) return true
      return false
    },

    validatePhone(params) {
      this.phoneValid = params.valid
      this.sponsor.phone = params.number
    },

    computeValue(val) {
      if (this.getCurrency() == 'USD') {
        //!this.inEEC()
        return val
      }
      // return Math.floor(val * this.change * 1.05 * 100) / 100
      return Math.floor(val * this.change * 100) / 100;
    },

    validateValue(params) {
      //console.log(params)
    },

    computePrice() {
      this.valueUSD = this.value
      this.valueEUR = this.value

      this.taxUSD = 0
      this.taxEUR = 0

      if (
        (this.inEEC() && !this.sponsor.vat) ||
        this.sponsor.country == 'France' || this.sponsor.country == "France, Metropolitan"
      ) {
        this.valueUSD = Math.floor(this.valueUSD * 100) / 100
        this.valueEUR = Math.floor(this.valueEUR * 100) / 100
        this.taxUSD = Math.floor(this.valueUSD * 0.2 * 100) / 100
        this.taxEUR = Math.floor(this.valueEUR * 0.2 * 100) / 100
        this.vatEnabled = 1
      } else {
        this.valueUSD = Math.floor(this.valueUSD * 100) / 100
        this.valueEUR = Math.floor(this.valueEUR * 100) / 100
        this.vatEnabled = 0
      }

      this.totalUSD = Math.floor((this.valueUSD + this.taxUSD) * 100) / 100
      this.totalEUR = Math.floor((this.valueEUR + this.taxEUR) * 100) / 100

      if (this.fee) {
        this.feeAmountEUR = Math.floor(this.valueEUR * 0.045 * 100) / 100
        this.feeAmountUSD = Math.floor(this.valueUSD * 0.045 * 100) / 100

        this.totalUSD =
          Math.floor((this.feeAmountUSD + this.totalUSD) * 100) / 100
        this.totalEUR =
          Math.floor((this.feeAmountEUR + this.totalEUR) * 100) / 100
      } else {
        this.feeAmountEUR = 0
        this.feeAmountUSD = 0
      }
    },

    getCurrency() {
      if (
        this.sponsor.country == 'United States' ||
        this.sponsor.country == 'Canada' ||
        !this.sponsor.country
      ) {
        this.currency = 'USD'
        return 'USD'
      } else {
        this.currency = 'EUR'
        return 'EUR'
      }
    },

    // async registerSponsor() {
    //   await this.$store
    //     .dispatch('register', {
    //       user: this.sponsor,
    //       url: 'sponsor',
    //     })
    //     .then((response) => {
    //       console.log('registration success')
    //     })
    //     .catch((error) => {
    //       this.dialogRegistrationFail = true
    //     })
    // },

    payment() {
      this.$v.$touch()
      if (this.$v.$error || this.minimumValue()) {
        return false
      }

      //   SponsorService.registration(this.sponsor)
      //     .then((res) => {})
      //     .catch((e) => {})

      //this.registerSponsor();

      var tempFee = this.feeAmountUSD
      var total = this.totalUSD
      var currency = this.getCurrency()
      if (currency == 'EUR') {
        total = this.totalEUR
        tempFee = this.feeAmountEUR
      }
      let payItems = {
        currency: currency,
        total: total,
        v: this.vatEnabled,
        company_id: null,
        role: 'sponsor',
        tpay: this.sponsor.payment,
        fee: tempFee,
      }

      if (!this.skipRegistration) {
        //this.registerSponsor()
        this.$store
          .dispatch('register', {
            user: this.sponsor,
            url: 'sponsor',
          })
          .then((res) => {
            if (payItems.currency && payItems.total != 0) {
              if (this.sponsor.payment == 'one-time') {
                Paypal.charge(payItems)
                  .then((res) => {
                    if (res.status == 202) {
                      window.open(res.data, '_top')
                    } else {
                      //console.log(res.data)
                      //TO-DO show payment fail dialog
                      this.dialogPaymentFail = true
                    }
                  })
                  .catch((e) => {
                    this.dialogPaymentFail = true
                  })
              } else if (this.sponsor.payment == 'monthly') {
                Paypal.chargeSubscription(payItems)
                  .then((res) => {
                    // console.log('here');
                    // console.log(res);
                    if (res.status == 202) {
                      window.open(res.data, '_top')
                    } else {
                      console.log(res.data)
                      this.dialogPaymentFail = true
                    }
                  })
                  .catch((e) => {
                    this.dialogPaymentFail = true
                  })
              }
            }
          })
          .catch((e) => {
            //console.error(e)
            this.dialogRegistrationFail = true
          })
      } else {
        if (payItems.currency && payItems.total != 0) {
          if (this.sponsor.payment == 'one-time') {
            Paypal.charge(payItems)
              .then((res) => {
                if (res.status == 202) {
                  window.open(res.data, '_top')
                } else {
                  //console.log(res.data)
                  this.dialogPaymentFail = true
                }
              })
              .catch((e) => {
                this.dialogPaymentFail = true
              })
          } else if (this.sponsor.payment == 'monthly') {
            Paypal.chargeSubscription(payItems)
              .then((res) => {
                // console.log('here');
                // console.log(res);
                if (res.status == 202) {
                  window.open(res.data, '_top')
                } else {
                  //console.log(res.data)
                  this.dialogPaymentFail = true
                }
              })
              .catch((e) => {
                this.dialogPaymentFail = true
              })
          }
        }
      }
    },

    completePayment() {
      if (
        (this.$route.query.token || this.$route.query.ba_token) &&
        this.$route.query.tpay &&
        !this.$route.query.status
      ) {
        if (this.$route.query.tpay == 'monthly') {
          Paypal.subscriptionSuccess(
            JSON.stringify({
              company_id: this.$route.query.cid, //this.company_id,
              ba_token: this.$route.query.ba_token,
              token: this.$route.query.token,
              v: this.$route.query.v,
              role: this.$route.query.role,
              tpay: this.$route.query.tpay,
              fee: this.$route.query.fee,
              description: 'Monthly Sponsorship for 12 months'
            })
          )
            .then((res) => {
              if (res.status == 200) {
                //console.log(res)
                this.dialogPaymentSuccess = true
              }
            })
            .catch((e) => {
              //console.error(e)
              this.dialogPaymentFail = true
            }) //throw Unknown error happened
        } else {
          Paypal.paymentSuccess(
            JSON.stringify({
              company_id: this.$route.query.cid, //this.company_id,
              paymentId: this.$route.query.paymentId,
              token: this.$route.query.token,
              PayerID: this.$route.query.PayerID,
              v: this.$route.query.v,
              role: this.$route.query.role,
              tpay: this.$route.query.tpay,
              fee: this.$route.query.fee,
              description: 'One Time Sponsorship'
            })
          )
            .then((res) => {
              //console.log(res);
              if (res.status == 200) {
                //this.emailBlock = true
                this.dialogPaymentSuccess = true
              }
            })
            .catch((e) => {
              //console.error(e)
              this.dialogPaymentFail = true
            }) //throw Unknown error happened
        }
      }

      if (this.$route.query.status == 'fail') {
        this.dialogPaymentFail = true
      }
      //wtf jean baptiste
      // Paypal.getInvoice().then((res)=>{
      //   if(res.data)
      //   {
      //     //console.log(res.data)
      //   }
      // }).catch((e)=>{
      //   //console.error(e);
      // })
    },

    onFieldChange() {
      this.$v.$touch()
      if (this.$v.$error) {
        return false
      }
    },
  },
  mounted() {
    this.updateGoogleAnalytics();
    Var.getAllCountry().then((res) => {
      this.countrylist = res.data
    });
    Var.getRate().then((res) => {
      this.change = res.data.final_rate;
    })
    this.completePayment()
  },
}
</script>

<style scoped src="../assets/styles/checkBoxInput.css"></style>
<style>
  @media only screen and (min-width: 20px) and (max-width: 511px) {
    .package-price {
      padding-top: 20px !important;
      height: 104px !important;
    }
    .package-content {
      max-height: 104px !important;
    } 
  }

  @media only screen and (min-width: 20px) and (max-width: 442px) {
    .package-price.bloc-4 {
      padding-top: 35px !important;
      height: 124px !important;
    }
    .package-content.bloc-4 {
      max-height: 124px !important;
    } 
  }

  @media only screen and (min-width: 20px) and (max-width: 360px) {
    .package-price {
      padding-top: 30px !important;
      height: 126px !important;
    }
    .package-content {
      max-height: 126px !important;
    } 
  }

  @media only screen and (min-width: 20px) and (max-width: 339px) {
    .package-price.bloc-4 {
      padding-top: 40px !important;
      height: 150px !important;
    }
    .package-content.bloc-4 {
      max-height: 150px !important;
    } 
  }

  @media only screen and (min-width: 20px) and (max-width: 291px) {
    .package-price {
      padding-top: 50px !important;
      height: 170px !important;
    }
    .package-content {
      max-height: 170px !important;
    }
    .package-price p {
      position: absolute;
      left: 5px !important;
      z-index: 100;
    } 
    .package-price.bloc-4 p {
      position: absolute;
      left: 1px !important;
      z-index: 100;
    }
    .package-price.bloc-4 {
      padding-top: 50px !important;
      height: 170px !important;
    }
    .package-content.bloc-4 {
      max-height: 170px !important;
    } 
  }
</style>