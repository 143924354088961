<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-10">
      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="12" md="6" class="pl-2">
          <p
            class="light-green--text text--darken-3 font-weight-bold text-sm-left"
            id="title"
          >
            Experience
          </p>
          <p
            class="light-green--text text--darken-3 font-weight-bold text-sm-left"
          >
            from earliest to latests
          </p>
        </v-col>
        <v-col cols="3"> </v-col>
        <v-col cols="1"> </v-col>
      </v-row>
      <div v-for="(experience, index) in experiences" :key="index">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="3">
            <p class="text-sm-left">Name Company*</p>
          </v-col>
          <v-col cols="10" md="6">
            <FormInlineMessage
              v-if="$v.experiences.$each[index].company.$error"
            >
              Provide Company Name
            </FormInlineMessage>

            <v-text-field
              v-model="experience.company"
              outlined
              dense
              placeholder=""
              @blur="$v.experiences.$each[index].company.$touch()"
            >
            </v-text-field>
          </v-col>
          <v-col cols="1"> </v-col>
        </v-row>
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="3">
            <p class="text-sm-left">Title*</p>
          </v-col>
          <v-col cols="10" md="6">
            <FormInlineMessage v-if="$v.experiences.$each[index].title.$error">
              Provide your title
            </FormInlineMessage>

            <v-text-field
              v-model="experience.title"
              outlined
              dense
              placeholder=""
              @blur="$v.experiences.$each[index].title.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="1"> </v-col>
        </v-row>

        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="3">
            <p class="text-sm-left">Location*</p>
          </v-col>
          <v-col cols="10" md="6">
            <FormInlineMessage
              v-if="$v.experiences.$each[index].location.$error">
              Provide location
            </FormInlineMessage>

            <v-select
            v-model="experience.location"
            :items="countries"
            background-color="white"
            outlined
            dense
            @blur="$v.experiences.$each[index].location.$touch()"/>
          </v-col>
          <v-col cols="1"> </v-col>
        </v-row>

        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="3">
            <p class="text-sm-left">Time Period*</p>
          </v-col>
          <v-col cols="10" md="6">
            <v-row align="center" justify="center" no-gutters>
              <v-col cols="3">
                <p class="text-sm-left">begin</p>
              </v-col>

              <v-col cols="9">
                <FormInlineMessage
                  v-if="$v.experiences.$each[index].start.$error"
                >
                  When did you start?
                </FormInlineMessage>
                <v-menu
                  ref="menuStart"
                  :close-on-content-click="false"
                  :return-value.sync="experience.start"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      height="40"
                      outlined
                      v-model="experience.start"
                      placeholder="Start"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @blur="$v.experiences.$each[index].start.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="experience.start"
                    no-title
                    scrollable
                    type="month"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuStart[index].isActive = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuStart[index].save(experience.start)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <!-- end of date picker -->
              </v-col>
            </v-row>

            <v-row align="center" justify="center" no-gutters>
              <v-col cols="3" class="pb-4">
                <p class="text-sm-left">end</p>
              </v-col>
              <v-col cols="9" class="mt-n10">
                <v-checkbox
                  v-model="experience.current_work"
                  label="I currently work here"
                  color="#00aac"
                  value="current"
                  hide-details
                  dense
                  class="pb-8 mb-0 pt-0 mt-0"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" no-gutters class="mt-n5">
              <v-col cols="3"> </v-col>
              <v-col cols="9" v-if="experience.current_work != 'current'">
                <FormInlineMessage
                  v-if="$v.experiences.$each[index].end.$error"
                >
                  When did you end?
                </FormInlineMessage>

                <v-menu
                  ref="dateMenuEnd"
                  :close-on-content-click="false"
                  :return-value.sync="experience.end"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      height="40"
                      outlined
                      v-model="experience.end"
                      placeholder="End"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @blur="$v.experiences.$each[index].end.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="experience.end"
                    no-title
                    scrollable
                    type="month"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dateMenuEnd[index].isActive = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dateMenuEnd[index].save(experience.end)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1"> </v-col>
        </v-row>
        <v-row align="center" justify="center" class="mb-5">
          <v-col cols="11">
            <v-divider color="#f6f6f6"></v-divider>
          </v-col>
        </v-row>
      </div>
      <!-- Add -->
      <v-row align="center" justify="center">
        <v-col cols="3">
        </v-col>

        <v-col cols="9" md="4">
          <v-row class="justify-left mb-5">
            <v-btn
              id="btn-add"
              text
              color="#00aac3"
              class="bg-secondary-customized"
              @click.prevent.stop="addExperience(), currentBlock = true"
            >
              + Add experience
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="9" md="3">
          <v-row class="justify-left mb-5">
            <v-btn
              id="btn-add"
              text
              color="#00aac3"
              class="bg-secondary-customized"
              @click.prevent.stop="deleteExperience(), currentBlock = true"
            >
              - Remove experience
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10"></v-col>
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveExperience"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";
import UserExperienceService from "@/store/services/user.experience.service";
import Var from "@/store/services/var";

export default {
  components: {
    FormInlineMessage,
  },
  data() {
    return {
      place: "america",
      currentBlock: true,
      //current_work:false,
      currentIndex: null,
      countries: [],
      experiences: [
        {
          company: "",
          title: null,
          location: "",
          start: "",
          end: "",
          current_work: false,
        },
      ],
    };
  },

  validations: {
    experiences: {
      required,
      $each: {
        company: {
          required,
        },
        title: {
          required,
        },
        start: {
          required,
        },
        current_work: {},
        end: {
          required: requiredIf(function (experience) {
            if (experience.current_work != "current") {
              return true; //short messages are not considered valid
            } else {
              return false;
            }
          }),
        },
        location: {
          //required,
        },
      },
    },
  },

  methods: {
    loadLocation(ref, experience) {
      if (this.$refs[ref]) {
        this.$refs[ref][0].$refs.input.value = experience.location;
      }
    },
    saveExperience() {
      this.$v.$touch();
      if (this.$v.$error) {
        return this.$toast.open({
            message: "Experience is required, please add your experience!",
            type: 'error',
            position: 'top-right'
        }); 
      }

      UserExperienceService.save({ user_experience: this.experiences });

      this.currentBlock = false;
      this.$emit("block4");
    },

    addExperience() {
      this.experiences.push({
        company: "",
        title: null,
        location: "",
        start: "",
        end: "",
        current_work: false,
      });
    },

    setPlace(place) {
      this.experiences[this.currentIndex].location = place.formatted_address;
    },

    deleteExperience() {
      const experienceId =
        this.experiences[this.experiences.length - 1]["id"] ?? false;
      if (experienceId) {
        UserExperienceService.destroy(experienceId)
          .then((response) => {
            this.experiences.pop();
            return;
          })
          .catch((error) => {
            //console.log(error)
          });
      } else {
        this.experiences.pop();
      }
    },
  },

  mounted() {
    UserExperienceService.getAll().then((res) => {
      if (res.data) {
        let that = this;
        this.experiences = res.data;

        setTimeout(function () {
          that.experiences.forEach(function (experience, index) {
            that.$refs["gmapAutocomplete" + index][0].$refs.input.value =
              experience.location;
          });
        }, 700);
      }
    });

    Var.getAllCountry().then((res) => {
      this.countries = res.data;
    });
  },
};
</script>
