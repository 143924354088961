<template>
  <v-row align="center" justify="center" class="step-1">
    <v-col cols="10">
      <v-responsive :aspect-ratio="16 / 9">
        <v-card-text>
          <!-- space-between -->
          <v-row justify="center" class="mx-10">
            <v-col cols="12" md="7">
              <!-- <div class="layout column"> -->
              <p
                class="cyan--text text--darken-1 font-weight-bold text-sm-left"
                id="title"
              >
                Company Contact
              </p>
              <!-- </div>     -->
            </v-col>
            <v-col cols="12" md="5">
              <p class="smallest text-sm-right">
                required fields* to save your data
              </p>
            </v-col>
          </v-row>

          <PersonalDetails @block2="educationBlock = true"></PersonalDetails>

          <!-- Education -->
          <EducationBlock
            @block3="experienceBlock = true"
            v-if="educationBlock"
          ></EducationBlock>

          <!-- Experience -->
          <ExperienceBlock
            @block4="knowhowBlock = true"
            v-if="experienceBlock"
          ></ExperienceBlock>

          <!-- Know How -->
          <KnowhowBlock
            @block5="resumeBlock = true"
            v-if="knowhowBlock"
          ></KnowhowBlock>

          <!-- Resume -->
          <ResumeBlock
            @block6="nextBlock = true"
            v-if="resumeBlock"
          ></ResumeBlock>

          <!-- Next -->
          <NextBlock @agreed="$emit('done')" v-if="nextBlock"></NextBlock>
        </v-card-text>
      </v-responsive>
    </v-col>
  </v-row>
</template>
<script>
// import FooterLarge from '@/components/partials/FooterLarge.vue'
// import Skills from "@/store/services/skills-service";
import AllVar from "@/store/services/var";
import PersonalDetails from "@/components/Steps/Step1/Blocks/PersonalDetails";
import EducationBlock from "@/components/Steps/Step1/Blocks/EducationBlock";
import ExperienceBlock from "@/components/Steps/Step1/Blocks/ExperienceBlock";
import KnowhowBlock from "@/components/Steps/Step1/Blocks/KnowhowBlock";
import ResumeBlock from "@/components/Steps/Step1/Blocks/ResumeBlock";
import NextBlock from "@/components/Steps/Step1/Blocks/NextBlock";

export default {
  components: {
    PersonalDetails,
    EducationBlock,
    ExperienceBlock,
    KnowhowBlock,
    ResumeBlock,
    NextBlock,
  },
  data() {
    return {
      nextBlock: false,
      experienceBlock: false,
      knowhowBlock: false,
      educationBlock: false,
      resumeBlock: false,
      abilities: [],
      skill: null,
      dateMenu: false,
      dateValue: null,
      menu1: "",
      selected: "",
      date: null,
      ex4: "",
      radios: "",
      skills: ["driving", "cooking"],
      items: ["blbla", "hhsa"],
      // valuse: ['driving'],
      knowHow: {
        items: ["driving", "cooking"],
        abilities: ["super", "great"],
        experience: "",
        languages: ["german", "thai"],
      },
      queryTerm: "",
      entries: [],
    };
  },

  methods: {
    loadEntries() {
      var that = this;

      AllVar.getAllSkills(this.queryTerm).then((res) => {
        //console.log(this.entries,res.data.data);
        that.entries = res.data;
      });
    },
  },

  computed: {
    search: {
      get: function () {
        return this.queryTerm;
      },

      set: function (searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadEntries();
        }
      },
    },
  },

  created() {
    this.loadEntries();
  },
};
</script>
<style>
.step-1 {
  font-family: "Arial";
}

p.smallest {
  font-size: 12px;
}

p.thin {
  font-weight: normal;
}

#btn-photo {
  font-weight: bolder;
  font-size: 14px;
  border-radius: 70px;
}

p {
  font-weight: bold;
  font-size: 14px;
}

#title {
  letter-spacing: -1px;
}

.mdi-twitter,
.mdi-linkedin {
  color: #00aac3 !important;
}

#btn-add {
  text-transform: none;
  font-weight: bold;
}

#btn-save {
  font-weight: bolder;
  font-style: italic;
}

.step-1 .v-text-field .v-input__control .v-input__slot {
  min-height: 30px !important;
  display: flex !important;
  align-items: center !important;
}

.v-input--selection-controls {
  height: 20px;
}

.radio-label label {
  margin-left: 25px;
}

#btn-verify {
  font-weight: bolder;
  font-size: 18px;
  border-radius: 70px;
  margin-bottom: 50px;
}
</style>
