<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec">
      <v-row align="center" justify="center">
        <v-col cols="10" md="3">
          <p class="text-sm-left thin">{{ me.first_name }}</p>
        </v-col>
        <v-col cols="10" md="8">
          <p class="text-sm-left thin">{{ me.last_name }}</p>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="11" md="11" sm="10">
          <p class="text-sm-left thin">{{ me.email }}</p>
        </v-col>
        <!-- <v-col cols="8"> -->
          <!-- <p class="text-sm-left thin">Last Name</p> -->
        <!-- </v-col> -->
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-8">
      <!-- v-if="photoBlock" -->
      <v-row align="center">
        <v-col cols="12">
          <v-card-actions class="justify-center ml-10 py-1">
            <v-hover v-slot:default="{ hover }">
              <v-img
                v-if="!photoBlock"
                max-height="300"
                max-width="300"
                @click.stop="dialogUp('Photo')"
                :src="imagePreviewUrl"
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    style="background-color: rgb(81, 77, 122); height: 100%"
                    class="
                      d-flex
                      transition-fast-in-fast-out
                      v-img--reveal
                      display-1
                      white--text
                      align-center
                      justify-center
                    "
                  >
                    CHANGE YOUR PHOTO
                  </div>
                </v-expand-transition>
              </v-img>
            </v-hover>
            <v-btn
              v-if="photoBlock"
              sm="10"
              id="btn-photo"
              class="white--text"
              color="#514d7a"
              height="90"
              min-width="20"
              rounded
              :disabled="false"
              @click.stop="dialogUp('Photo')"
            >
              ADD YOUR PHOTO*
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-sheet>

    <!-- dialog for Avatar image -->
    <v-dialog v-model="dialogPhoto" max-width="400">
      <PhotoForm
        @uploadPhoto="uploadAvatar"
        @closePhoto="dialogDown('Photo')"
      ></PhotoForm>
    </v-dialog>

    <!-- Live -->
    <v-sheet height="100%" class="mx-auto mt-8">
      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-col cols="12" md="3" dense>
          <p class="text-sm-left">Where I live*</p>
        </v-col>
        <v-col cols="10" md="6" no-gutters>
          <FormInlineMessage v-if="$v.personal.city.$error">
            Please select where you live
          </FormInlineMessage>

          <v-autocomplete
            placeholder=""
            v-model="personal.city"
            :items="country"
            outlined
            dense
            @change="getCountryCode(personal.city)"
            @blur="$v.personal.city.$touch()"
          />
        </v-col>
        <v-col cols="1"> </v-col>
      </v-row>
    </v-sheet>

    <!-- Phone -->
    <v-sheet height="100%" class="mx-auto mt-8">
      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-col cols="12" md="3">
          <p class="text-sm-left">Phone*</p>
        </v-col>
        <v-col cols="10" md="6">
          <FormInlineMessage v-if="$v.phoneValid.$error">
            Please select valid phone number
          </FormInlineMessage>
          <vue-tel-input
            v-model="phone"
            ref="phoneComplete"
            :defaultCountry="cityDefaultCountry"
            @validate="validatePhone"
            class="mb-5"
            @blur="$v.phoneValid.$touch()"
          ></vue-tel-input>
        </v-col>

        <v-col cols="1">
        </v-col>
      </v-row>
    </v-sheet>

    <!-- Date of Birth -->

    <v-sheet height="285px" class="mx-auto mt-8">
      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-col cols="12" md="3">
          <p class="text-sm-left">Date of Birth*</p>
        </v-col>

        <v-col cols="10" md="6">
          <FormInlineMessage v-if="$v.personal.date_of_birth.$error">
            Please select your date of birth
          </FormInlineMessage>
          <v-menu
            ref="dob"
            :close-on-content-click="false"
            :return-value.sync="personal.date_of_birth"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                height="40"
                outlined
                v-model="personal.date_of_birth"
                placeholder="Date of birth"
                readonly
                v-bind="attrs"
                v-on="on"
                @blur="$v.personal.date_of_birth.$touch()"
              ></v-text-field>
            </template>
            <v-date-picker v-model="personal.date_of_birth" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.dob.isActive = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dob.save(personal.date_of_birth)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="1"> </v-col>
      </v-row>

      <v-row align="center" justify="center" no-gutters>
        <v-col cols="3">
          <p class="text-sm-left">Gender*</p>
        </v-col>
        <v-col cols="6" class="mt-n6">
          <v-radio-group v-model="personal.gender" column class="pt-0" dense>
            <v-radio label="Male" color="primary" value="male"></v-radio>
            <v-radio label="Female" color="primary" value="female"></v-radio>
            <v-radio label="Other" color="primary" value="other"></v-radio>
            <v-radio label="Prefer not to say" color="primary" value="no"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="1"> </v-col>
      </v-row>
    </v-sheet>

    <!-- Years of experience -->
    <v-sheet height="100%" class="mx-auto mt-8">
      <v-row align="center" justify="center" class="pt-8">
        <v-col cols="3">
          <p class="text-sm-left">Years of experience*</p>
        </v-col>
        <v-col cols="6">
          <FormInlineMessage v-if="$v.personal.experience.$error">
            Please select years of experience
          </FormInlineMessage>
          <v-select
            v-model="personal.experience"
            :items="experiences"
            dense
            outlined
            @blur="$v.personal.experience.$touch()"
          ></v-select>
        </v-col>
        <v-col cols="1"> </v-col>
      </v-row>
    </v-sheet>

    <!-- My Social Network -->
    <v-sheet height="100%" class="mx-auto mt-8">
      <v-row align="center" justify="center" class="pt-8">
        <v-col cols="12" md="3">
          <p class="text-sm-left">My Social Networks</p>
        </v-col>
        <v-col cols="10" md="6">
          <FormInlineMessage v-if="$v.personal.social_network.twitter.$error">
            please provide full twitter url to your profile
          </FormInlineMessage>
          <v-text-field
            v-model="personal.social_network.twitter"
            prepend-inner-icon="mdi-twitter pb-2"
            outlined
            dense
            placeholder="https://twitter.com/@john"
            @blur="$v.personal.social_network.twitter.$touch()"
          ></v-text-field>
          <FormInlineMessage v-if="$v.personal.social_network.linkedin.$error">
            please provide full linkedin url to your profile
          </FormInlineMessage>
          <v-text-field
            v-model="personal.social_network.linkedin"
            prepend-inner-icon="mdi-linkedin pb-2"
            outlined
            dense
            placeholder="https://linkedin.com/in/john"
            @blur="$v.personal.social_network.linkedin.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="1"> </v-col>
      </v-row>
    </v-sheet>

    <!-- Save -->
    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10"></v-col>
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="savePersonal"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>
<script>
import Var from "@/store/services/var";
import {
  required,
  minLength,
  maxLength,
  sameAs,
  url,
} from "vuelidate/lib/validators";

import FormInlineMessage from "@/components/FormInlineMessage";
import PhotoForm from "@/forms/PhotoForm";
import Profile from "@/store/services/profile-service";

export default {
  components: {
    FormInlineMessage,
    PhotoForm
  },
  validations: {
    phoneValid: {
      sameAs: sameAs(() => true),
    },
    personal: {
      city: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(120),
      },
      date_of_birth: {
        required,
      },
      social_network: {
        twitter: {
          url,
        },
        linkedin: {
          url,
        },
      },
      experience: {
        required,
      },
    },
  },

  data() {
    return {
      imagePreviewUrl: null,
      photoBlock: true,
      dialogPhoto: false,
      formData: new FormData(),
      currentBlock: true,
      phoneValid: false,
      phone: null,
      country: [],
      experiences: [
        "0 - 5 years",
        "5 - 10 years",
        "10 - 15 years",
        "15 - 20 years",
        "above 20 years",
      ],
      cityDefaultCountry: null,

      personal: {
        user_id: null,
        phone: null,
        date_of_birth: null,
        city: "",
        gender: "male",
        experience: "",
        social_network: {
          twitter: "",
          linkedin: "",
        },
      },
      me: {},
    };
  },

  methods: {
    dialogUp(element) {
      this.$data["dialog" + element] = true;
    },
    validatePhone(params) {
      this.phoneValid = params.valid;
      this.personal.phone = params.number;
    },

    dialogDown(element) {
      this.$data["dialog" + element] = false;
    },

    uploadAvatar(chosenImage) {
      this.photoBlock = false;

      this.imagePreviewUrl = URL.createObjectURL(chosenImage);
      this.formData.append("avatar", chosenImage);
      Profile.saveAvatar(this.formData).then().catch();
      this.dialogDown("Photo");
    },

    retrieveAvatar() {
      Profile.getAvatar()
        .then((res) => {
          this.imagePreviewUrl = res.data;
          this.photoBlock = false;
        })
        .catch((error) => {
          this.photoBlock = true;
        });
    },

    savePersonal() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }

      Profile.saveDetails(this.personal);
      this.currentBlock = false;
      this.$emit("block2");
    },

    getCountryCode(country) {
        Var.getCountryCode(country).then((res) =>{
            console.log(res)
            this.cityDefaultCountry = res.data.country_code
        })
    },
  },

  created() {
    Promise.all([
      Profile.get().then((res) => {
        this.me = res.data;
      }),
      Profile.myDetails().then((res) => {
        if (res.data) {
          this.personal = res.data;
          this.phone = this.personal.phone;
          this.personal.social_network = JSON.parse(
            this.personal.social_network
          );
          return;
        }
      }),

      Var.getAllCountry().then((res) => {
        this.country = res.data;
      }),
    ]);
  },

  mounted() {
    this.retrieveAvatar();
  },
};
</script>
<style>
.introInput {
  width: 100%;
  height: 35px;
  border: 1px solid grey;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
}
</style>
