<template>
  <v-row align="center" justify="center" class="step-1">
    <v-col cols="10">
      
      <v-responsive :aspect-ratio="16 / 9">
        <v-card-text>
          <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec">
            <v-row justify="center" class="mx-10">
              <v-col cols="2">
                <v-img
                  style="border-radius: 20%"
                  min-height="95%"
                  :src="logoPreviewUrl"
                />
              </v-col>
              <v-col cols="10">
                <p class="text-sm-left" style="font-weight: normal">
                  <span v-if="me.investment_type == 'firm'"
                    >{{ me.firm_name }}<br /><br
                  /></span>
                  <span style="font-weight: bold">Main Contact<br /></span>
                  {{ me.first_name }}<br />
                  {{ me.last_name }}<br />
                  {{ me.email }}
                </p>
              </v-col>
            </v-row>
          </v-sheet>
          <v-col />
          <p
            class="cyan--text text--darken-1 font-weight-bold text-sm-left"
            id="title"
          >
            What is your investment strategy ?
          </p>
          <p class="text-sm-left thin">
            Tell us more about the type of your business you're investing in.<br />
            Select one or several values for each of the following criteria.
          </p>

          <StrategyBlock @block2="productBlock = true" />

          <ProductBlock @block3="stageBlock = true" v-if="productBlock" />

          <StageBlock @block4="nextBlock = true" v-if="stageBlock" />

          <NextBlock @agree="$emit('done')" v-if="nextBlock" />
        </v-card-text>
      </v-responsive>
    </v-col>
  </v-row>
</template>

<script>
import StageBlock from "@/components/Steps/Investor/Step3/Blocks/StageBlock";
import ProductBlock from "@/components/Steps/Investor/Step3/Blocks/ProductBlock";
import StrategyBlock from "@/components/Steps/Investor/Step3/Blocks/StrategyBlock";
import NextBlock from "@/components/Steps/Investor/Step3/Blocks/NextBlock";
import Profile from "@/store/services/profile-service";
import AccreditationService from "@/store/services/investor/accreditation.service.js";

export default {
  components: {
    StageBlock,
    ProductBlock,
    StrategyBlock,
    NextBlock,
  },

  props: {
    me: Object,
  },

  data() {
    return {
      productBlock: false,
      strategyBlock: false,
      stageBlock: false,
      nextBlock: false,
      logoPreviewUrl: null,
    };
  },

  methods: {
    retrieveLogo() {
      if (this.me.investment_type == "individual") {
        Profile.getAvatar()
          .then((res) => {
            this.logoPreviewUrl = res.data;
          })
          .catch((error) => {
            //console.log(error);
          });
      }
      if (this.me.investment_type == "firm") {
        AccreditationService.getLogo()
          .then((res) => {
            this.logoPreviewUrl = res.data;
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    },
  },
  mounted() {
    this.retrieveLogo();
  },
};
</script>
