<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-10">
      <v-row no-gutters>
        <v-col cols="1" />
        <v-col cols="10" class="mt-10">
          <p class="text-sm-left">What is the legal name of your company ?</p>
        </v-col>
        <v-row>
          <v-col cols="1" />
          <v-col cols="10" md="10" sm="10">
            <FormInlineMessage
              v-if="$v.describe.company_name.$error"
              class="errmsg"
            >
              Write your company's name
            </FormInlineMessage>
            <v-text-field
              v-model="describe.company_name"
              outlined
              dense
              placeholder=""
            />
          </v-col>
        </v-row>
      </v-row>

      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-col cols="1" />
        <v-col cols="11" class="mt-10">
          <p class="text-sm-left">What is the legal representative name ?</p>
        </v-col>
        <v-col cols="12" />
        <v-col md="2">
          <v-autocomplete
            v-model="describe.gender"
            :items="allGenre"
            outlined
            dense
          />
        </v-col>
        <v-col sm="1" />
        <v-col sm="3">
          <FormInlineMessage
            v-if="$v.describe.first_name.$error"
            class="errmsg"
          >
            Write representative's first name
          </FormInlineMessage>
          <v-text-field
            v-model="describe.first_name"
            outlined
            dense
            placeholder="first name"
          />
        </v-col>
        <v-col sm="1" />
        <v-col sm="3">
          <FormInlineMessage v-if="$v.describe.last_name.$error" class="errmsg">
            Write representative's last name
          </FormInlineMessage>
          <v-text-field
            v-model="describe.last_name"
            outlined
            dense
            placeholder="last name"
          />
        </v-col>
      </v-row>

      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-col cols="12" md="3">
          <v-col sm="10">
            <p class="text-sm-left bold">Where are you based ?</p>
          </v-col>
        </v-col>
        <v-col cols="10" md="6">
          <FormInlineMessage v-if="$v.describe.country.$error" class="errmsg">
            Indicate where you are based
          </FormInlineMessage>
          <v-autocomplete
            v-model="describe.country"
            :items="country"
            outlined
            dense
          />
        </v-col>
        <v-col cols="1" />
      </v-row>

      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-col cols="12" md="10">
          <v-col sm="10">
            <p class="text-sm-left bold">What is your HQ Address ?</p>
          </v-col>
        </v-col>

        <v-col cols="12" />

        <v-row>
          <v-col cols="2" />
          <v-col sm="2">
            <p class="text-sm-left bold">Street</p>
          </v-col>
          <v-col sm="7">
            <FormInlineMessage v-if="$v.describe.street.$error" class="errmsg">
              Indicate where is your HQ
            </FormInlineMessage>
            <v-text-field
              v-model="describe.street"
              outlined
              dense
              placeholder=""
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-col cols="12" />

        <v-row>
          <v-col cols="2" />
          <v-col sm="2">
            <p class="text-sm-left bold">ZIP code</p>
          </v-col>
          <v-col sm="2">
            <FormInlineMessage v-if="$v.describe.zip.$error" class="errmsg">
              Indicate where is your HQ
            </FormInlineMessage>
            <v-text-field v-model="describe.zip" outlined dense placeholder="">
            </v-text-field>
          </v-col>

          <v-col cols="1" />

          <v-col sm="2">
            <p class="text-sm-left bold">City*</p>
          </v-col>
          <v-col cols="2">
            <FormInlineMessage v-if="$v.describe.city.$error" class="errmsg">
              Indicate where your HQ is
            </FormInlineMessage>
            <v-text-field v-model="describe.city" outlined dense placeholder="">
            </v-text-field>
          </v-col>
        </v-row>

        <v-col cols="12" />

        <v-row>
          <v-col cols="2" />
          <v-col sm="2">
            <p class="text-sm-left bold">Phone</p>
          </v-col>
          <v-col sm="7">
            <FormInlineMessage v-if="$v.phoneValid.$error" class="errmsg">
              Indicate a valid phone number
            </FormInlineMessage>
            <vue-tel-input
              v-model="phone"
              defaultCountry="country"
              ref="phoneComplete"
              @validate="validatePhone"
              class="mb-5"
              @blur="$v.phoneValid.$touch()"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />
      </v-row>

      <v-row align="center" no-gutters class="pt-8">
        <v-col cols="1" />
        <v-col sm="3">
          <v-col sm="10">
            <p class="text-sm-left bold">Date of incorporation</p>
          </v-col>
        </v-col>
        <v-col sm="auto">
          <FormInlineMessage
            v-if="$v.describe.date_incorporation.$error"
            class="errmsg"
          >
            Indicate the date of incorporation
          </FormInlineMessage>
          <v-menu
            ref="dob"
            :close-on-content-click="false"
            :return-value.sync="describe.date_incorporation"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                height="40"
                outlined
                v-model="describe.date_incorporation"
                placeholder="Date of incorporation"
                readonly
                v-bind="attrs"
                v-on="on"
                @blur="$v.describe.date_incorporation.$touch()"
              ></v-text-field>
            </template>
            <v-date-picker v-model="describe.date_incorporation" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.dob.isActive = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dob.save(describe.date_incorporation)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col></v-col>
        <v-col cols="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveDescribe"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import CompanyService from "@/store/services/company/company.service";
import Var from "@/store/services/var";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  minLength,
  maxLength,
  numeric,
  minValue,
  email,
  sameAs,
  url,
} from "vuelidate/lib/validators";

export default {
  components: {
    FormInlineMessage,
  },

  props: {
    company_id: Number,
  },

  data() {
    return {
      currentBlock: true,
      allGenre: ["Mr.", "Ms.", "Mrs."],
      country: [],
      phoneValid: false,
      phone: null,
      describe: {
        company_name: "",
        gender: "Mr.",
        first_name: "",
        last_name: "",
        country: "",
        street: "",
        zip: "",
        city: "",
        phone: null,
        date_incorporation: "",
      },
    };
  },

  validations: {
    phoneValid: {
      sameAs: sameAs(() => true),
    },
    describe: {
      required,
      company_name: { required },
      first_name: { required },
      last_name: { required },
      country: { required },
      street: { required },
      zip: { required },
      city: { required },
      date_incorporation: { required },
    },
  },

  methods: {
    validatePhone(params) {
      this.phoneValid = params.valid;
      this.describe.phone = params.number;
    },

    saveDescribe() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }

      CompanyService.saveDescribe(this.describe)
        .then((res) => {
          this.$emit("passCID", res.data, this.describe.company_name);
          this.currentBlock = false;
          this.$emit("block2");
        })
        .catch((e) => {});
    },

    getVariables() {
      Var.getAllCountry().then((res) => {
        this.country = res.data;
      });
    },
  },

  created() {
    this.getVariables();
    CompanyService.getDescribe().then((res) => {
      if (res.data) {
        this.describe.company_name = res.data.company_name;
        this.describe.gender = res.data.gender;
        this.describe.first_name = res.data.first_name;
        this.describe.last_name = res.data.last_name;
        this.describe.country = res.data.country;
        this.describe.street = res.data.street;
        this.describe.zip = res.data.zip;
        this.describe.city = res.data.city;
        this.describe.date_incorporation = res.data.date_incorporation;
        this.describe.phone = res.data.phone;
        this.phone = this.describe.phone;
      }
    });
  },
};
</script>
