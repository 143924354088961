<template>
  <div>
    <HeaderSponsor class="d-none d-md-flex" />
    <HeaderSponsorPortable class="d-flex d-md-none" />
    <div class="container mybackground">
      <div class="contact-main-wrap">
        <div class="contact-area">
          <div class="contact-title">
            <h3>Get in touch</h3>
            <p>We’d love to hear from you.If you need to get in touch with us please fill the contact form below</p>
          </div>

          <v-layout
          align-center
          justify-center
          class="mt-5"
          style="margin-bottom: 2em"
        >
            <v-card class="elevation-3 pa-3" height="100%" width="100%">
              <v-card-text id="card-text">
                <v-form class="my-5">
                  <FormInlineMessage v-if="$v.contact.name.$error">
                    Please provide a valid name
                  </FormInlineMessage>
                  <v-text-field
                    v-model="contact.name"
                    outlined
                    placeholder="Your Name"
                    dense
                    @blur="$v.contact.name.$touch()"
                  />

                  <FormInlineMessage v-if="$v.contact.email.$error">
                    Please provide a valid email address
                  </FormInlineMessage>
                  <v-text-field
                    v-model="contact.email"
                    outlined
                    placeholder="example@mail.com"
                    dense
                    @blur="$v.contact.email.$touch()"
                  />

                  <FormInlineMessage v-if="$v.phoneValid.$error">
                    Please provide a valid phone number
                  </FormInlineMessage>
                  <vue-tel-input
                    v-model="contact.phone"
                    ref="phoneComplete"
                    @validate="validatePhone"
                    class="mb-5"
                    @blur="$v.phoneValid.$touch()"
                  />

                  <FormInlineMessage v-if="$v.contact.subject.$error">
                    Please provide a valid subject
                  </FormInlineMessage>
                  <v-text-field
                    v-model="contact.subject"
                    outlined
                    placeholder="Your subject here"
                    dense
                    @blur="$v.contact.subject.$touch()"
                  />

                  <FormInlineMessage v-if="$v.contact.message.$error">
                    Please provide a valid message
                  </FormInlineMessage>
                  <v-textarea
                    v-model="contact.message"
                    outlined
                    placeholder="Your message here"
                    rows="2"
                    dense
                    @blur="$v.contact.message.$touch()"
                  />
                  <FormInlineMessage
                    v-if="this.submit_success"
                    class="mygreentext biggerinlinetext"
                  >
                    Contact form received
                  </FormInlineMessage>
                  <FormInlineMessage
                    v-if="this.submit_failure"
                    class="biggerinlinetext"
                  >
                    Something went wrong, try again soon.
                  </FormInlineMessage>
                </v-form>
                  <div class="action-btn d-flex justify-content-between align-items-center">
                    <div class="recaptcha">
                      <FormInlineMessage v-if="this.captcha_negative_msg">
                        Recaptcha is neccessary
                      </FormInlineMessage>
                        <vue-recaptcha
                        sitekey="6Ldv_m8cAAAAAH3w1Hd1vxuxoNiPYMCBjES8rf35"
                        @verify="verifyCaptcha"
                      ></vue-recaptcha>
                    </div>
                    <v-col cols="0" md="4"></v-col>
                    <div class="send-message">
                      <v-btn
                        sm="2"
                        class="white--text contact-send"
                        style="font-size: 1em"
                        color="#514d7a"
                        height="60"
                        min-width="70"
                        rounded
                        block
                        :disabled="false"
                        @click.prevent.stop="submitContactForm"
                      >
                        <span v-if="loading">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </span>
                        <span v-else>SEND MESSAGE</span>
                      </v-btn>
                    </div>
                  </div>
              </v-card-text>
            </v-card>
        </v-layout>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/FooterCms.vue";
import HeaderSponsor from "@/components/HeaderSponsor.vue";
import HeaderSponsorPortable from "@/components/HeaderSponsorPortable.vue";
import VueRecaptcha from "vue-recaptcha";
import FormService from "@/store/services/contact.service.js";
import {
  required,
  minLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import FormInlineMessage from "@/components/FormInlineMessage";

export default {
  components: {
    FormInlineMessage,
    Footer,
    HeaderSponsor,
    HeaderSponsorPortable,
    VueRecaptcha,
  },
  data() {
    return {
      submit_success: false,
      submit_failure: false,
      captcha_negative_msg: false,
      captcha: false,
      phoneValid: false,
      loading: false,
      contact: {
        name: null,
        email: null,
        phone: null,
        subject: null,
        message: null,
      },
    };
  },

  validations: {
    phoneValid: { sameAs: sameAs(() => true) },
    contact: {
      required,
      name: { required, minLength: minLength(2) },
      email: { required, email },
      subject: { required, minLength: minLength(2) },
      message: { required, minLength: minLength(2) },
    },
  },

  methods: {
    verifyCaptcha(result) {
      this.captcha = result;
      if (this.captcha) {
        this.captcha_negative_msg = false;
      }
    },
    validatePhone(params) {
      this.phoneValid = params.valid;
      this.contact.phone = params.number;
    },
    submitContactForm() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$error) {
        this.loading = false;
        return false;
      }
      if (this.captcha) {
        //call to backend
        FormService.submitContactForm(this.contact)
          .then((res) => {
            // this.submit_success = true;
            this.loading = false;
            this.$toast.open({ 
              message: "Your message has been sent to our team",
              type: 'success',
              position: 'top-right'
            });
            this.$router.push('/');
            //clear the form models below, null better than ""
            this.contact.name = null;
            this.contact.phone = null;
            this.contact.email = null;
            this.contact.subject = null;
            this.contact.message = null;
            this.$v.$reset(); //reset errors
          })
          .catch((e) => {
            this.submit_failure = true;
            this.loading = false;
          });
      } else {
        this.captcha_negative_msg = true;
        this.loading = false;
      }
    },
  },
  mounted() {},
};
</script>
<style scoped lang="css">
  .mybackground {
    max-width: 1170px;
  }
  .mygreentext {
    color: green !important;
  }
  .biggerinlinetext {
    font-size: 1.5em !important;
  }

  .contact-main-wrap {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding: 10% 0;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .contact-main-wrap:after {
    width: 100%;
    height: 100%;
    background: #47c2d4;
    position: absolute;
    content: "";
    top: 0;
    opacity: 0.6;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .contact-area {
    width: 70%;
    margin-bottom: 20px;
  }

  .contact-title {
    background: #ffffff96;
    padding: 7px 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  .contact-title h3 {
    color: #00aac3;
    margin-bottom: 10px;
  }

  .contact-send {
    border: none;
    display: inline-block;
    padding: 20px 50px;
    border-radius: 32px;
    font-weight: 700;
    background: #000 !important;
    color: #fff;
    transition: 0.3s;
  }

  .contact-send:hover {
    background: #fff !important;
    color: #000 !important;
    transition: 0.3s;
  }

  @media (max-width: 959px) { 
    .action-btn {
      display: flex;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and (min-width: 1071px) and (max-width: 1082px) {
    .action-btn  {
      width: 98%;
    }

  }

  @media only screen and (min-width: 1042px) and (max-width: 1070px) {
    .action-btn  {
      width: 90%;
    }
  }

  @media only screen and (min-width: 985px) and (max-width: 1041px) {
    .action-btn  {
      width: 80%;
    }
  }

  @media only screen and (min-width: 960px) and (max-width: 984px) {
    .action-btn  {
      width: 70%;
    }
  }

  @media only screen and (min-width: 50px) and (max-width: 460px) {
  .contact-area {
      width: 95%;
      margin-bottom: 20px;
    }
  }
</style>
