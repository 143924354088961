<template>
    <div class="mar-top-100">
        <v-col/>
        <v-row align="center" justify="center">
            <v-col cols="0" sm="1"/>
            <v-col cols="11" sm="11">
                <p class="cyan--text text--darken-1 text-sm-left" id="title" v-if="investment_type == 'firm'">Investor Accreditation of the Firm {{ describeFirmName }}</p>
                <p class="cyan--text text--darken-1 text-sm-left" id="title" v-else>Your Investor Accreditation as an individual</p>
                <v-row>
                    <v-col col="10" sm="8">
                        <span class="float-right" style="color: #ccc;">Required fields <span style="color:#000;">*</span> to save your data</span>
                    </v-col>
                </v-row>
                <v-row class="mb-n10">
                    <v-col cols="12">
                        <p class="text-sm-left" v-if="investment_type == 'firm'">Type of investor your firm is*</p>
                        <p class="text-sm-left" v-else>Type of investor you are*</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="8" class="mt-n8">
                        <FormInlineMessage v-if="$v.type.investor.$error">
                            Select your sector
                        </FormInlineMessage>
                        <v-combobox
                            v-model="type.investor"
                            :items="combo_items"
                            label="Select all types that apply"
                            multiple
                            deletable-chips
                            small-chips
                            outlined
                            dense
                            @blur="$v.type.investor.$touch()"
                        ></v-combobox>
                    </v-col>
                </v-row>

                <v-row> 
                    <v-col cols="12">
                        <p class="text-sm-left" v-if="investment_type == 'firm'">How is your firm/fund accredited*</p>
                        <p class="text-sm-left" v-else>How are you accredited*</p>
                    </v-col>

                    <v-col class="mt-n15">
                        <v-row no-gutters>
                            <v-col md="2" class="mt-6 mb-n5" v-if="$v.model.$error">
                                <FormInlineMessage
                                class="errmsg"
                                >
                                Select an option below
                                </FormInlineMessage>
                            </v-col>
                        </v-row>
                        <v-row no-gutters v-if="investment_type == 'individual'" class="accredit_first_option">
                            <v-col cols="11" sm="8" lg="8" class="ml-6 mb-7">
                                <v-checkbox
                                    v-model="model.$200K"
                                    label="I have had +$200k in income (or +$300k jointly with my
                                    spouse) for the past 2 years and expect the same this year"
                                    color="#11aec9"
                                    class="newbold"
                                    @change="model_none_above = false"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row no-gutters v-if="investment_type == 'firm'" class="accredit_model">
                            <v-col cols="11" class="ml-6 mb-3">
                                <v-checkbox
                                    v-model="model.$5_25M"
                                    label="My firm/fund has $5-25M in assets"
                                    color="#11aec9"
                                    class="newbold"
                                    @change="model_none_above = false"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row no-gutters v-if="investment_type == 'individual'" class="accredit_model">
                            <v-col cols="11" class="ml-6 mb-5">
                                <v-checkbox
                                    v-model="model.$1_2_1M"
                                    label="I have $1-2.1M in net assets, exluding my primary residence"
                                    color="#11aec9"
                                    class="newbold"
                                    @change="model_none_above = false"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row no-gutters v-if="investment_type == 'firm'" class="accredit_model">
                            <v-col cols="11" class="ml-6 mb-3">
                                <v-checkbox
                                    v-model="model.$25M"
                                    label="My firm/fund has over $25M in assets"
                                    color="#11aec9"
                                    class="newbold"
                                    @change="model_none_above = false"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row no-gutters v-if="investment_type == 'individual'" class="accredit_model">
                            <v-col cols="11" class="ml-6 mb-5">
                                <v-checkbox
                                    v-model="model.$2_1_5M"
                                    label="I have $2.1-5M in net assets, exluding my primary residence"
                                    color="#11aec9"
                                    class="newbold"
                                    @change="model_none_above = false"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row no-gutters v-if="investment_type == 'firm'" class="accredit_model">
                            <v-col cols="11" class="ml-6 mb-2">
                                <v-checkbox
                                    v-model="model.individually_accredited"
                                    label="All owners of my firm/fund are individually accredited"
                                    color="#11aec9"
                                    class="newbold"
                                    @change="model_none_above = false"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row no-gutters class="accredit_model" v-if="investment_type == 'individual'">
                            <v-col cols="11" class="ml-6 mb-2">
                                <v-checkbox
                                    v-model="model.$5M"
                                    label="I have over $5M in net assets, exluding my primary residence"
                                    color="#11aec9"
                                    class="newbold"
                                    @change="model_none_above = false"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row no-gutters class="accredit_model all_owners" v-if="investment_type == 'firm'">
                            <v-col cols="11" class="ml-6 mb-2">
                                <v-checkbox
                                    v-model="model.individually_qualified"
                                    label="All owners of my firm/fund are individually qualified purchasers"
                                    color="#11aec9"
                                    class="newbold"
                                    @change="model_none_above = false"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row no-gutters v-if="investment_type == 'firm'" class="none_model">
                            <v-col cols="11" class="ml-6">
                                <v-checkbox
                                    v-model="model_none_above"
                                    label="None of above"
                                    color="#11aec9"
                                    class="newbold"
                                    @change="disCheckOtherOptions($event)"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row class="mb-n10 mt-10">
                    <v-col cols="12" class="numberInvestment">
                        <p class="text-sm-left" v-if="investment_type == 'firm'">Number of investments you have made in your current fund*</p>
                        <p class="text-sm-left" v-else>Number of investments you have made*</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="8" class="mt-n8">
                        <FormInlineMessage
                            v-if="$v.type_investments_made.$error"
                            class="errmsg"
                        >
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_type_investments_made_length_error.error">
                            {{ is_type_investments_made_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                            placeholder="99"
                            outlined
                            dense
                            v-model="type_investments_made"
                            @input="type_investments_made = formatToString(type_investments_made), $v.type_investments_made.$touch()"
                            @keypress="isNumber($event)"
                        />
                    </v-col>
                </v-row>

                <v-row class="mb-n10">
                    <v-col cols="12">
                        <p class="text-sm-left">Number of deals you look at in a year*</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="8" class="mt-n8">
                        <FormInlineMessage
                            v-if="$v.type_deals_looked.$error"
                            class="errmsg"
                        >
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_type_deals_looked_length_error.error">
                            {{ is_type_deals_looked_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                            placeholder="240"
                            outlined
                            dense
                            v-model="type_deals_looked"
                            @input="type_deals_looked = formatToString(type_deals_looked), $v.type_deals_looked.$touch()"
                            @keypress="isNumber($event)"
                        />
                    </v-col>
                </v-row>

                <v-row class="mb-n10">
                    <v-col cols="12">
                        <p class="text-sm-left">Number of deals you do in a year*</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="8" class="mt-n8">
                        <FormInlineMessage
                            v-if="$v.type_deals_made.$error"
                            class="errmsg"
                        >
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_type_deals_made_length_error.error">
                            {{ is_type_deals_made_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                            placeholder="12"
                            outlined
                            dense
                            v-model="type_deals_made"
                            @input="type_deals_made = formatToString(type_deals_made), $v.type_deals_made.$touch()"
                            @keypress="isNumber($event)"
                        />
                    </v-col>
                </v-row>

                <v-row align="center" justify="center" class="pt-2 btn-save">
                    <v-col cols="0" xs="5" sm="6" md="2" lg="4"/>
                    <v-col cols="12" xs="1" sm="1" md="1" lg="1">
                        <v-btn
                        id="btn-photo"
                        class="white--text mb-3"
                        style="font-weight: bold; font-size: 14px"
                        color="#11aec9"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.stop.prevent="saveType()">
                            save
                        </v-btn>
                    </v-col>
                </v-row>


                <v-row class="mb-n12">
                    <v-col cols="12">
                        <p class="text-sm-left pt-2">
                            Your yearly investing budget for Seed and Series A+ startups in USD *
                            <span class="font-weight-regular"><br />For example, enter '400000' for $ 400,000</span
                        >
                        </p>
                    </v-col>
                    <v-col cols="12" sm="8" class="mt-n8">
                        <FormInlineMessage
                            v-if="$v.investment_yearly_seed_budget.$error"
                            class="errmsg"
                        >
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_investment_yearly_seed_budget_length_error.error">
                            {{ is_investment_yearly_seed_budget_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                            placeholder="4000000"
                            outlined
                            dense
                            v-model="investment_yearly_seed_budget" 
                            @input="investment_yearly_seed_budget  = formatToString(investment_yearly_seed_budget), $v.investment_yearly_seed_budget.$touch()"
                            @keypress="isNumber($event)"
                        />
                    </v-col>
                </v-row>

                <v-row class="mb-n12">
                    <v-col cols="12">
                        <p class="text-sm-left pt-2">
                            Your min check size for Startups in USD*
                            <span class="font-weight-regular"><br />For example, enter “150000” for $ 150,000</span
                            >
                        </p>
                    </v-col>
                    <v-col cols="12" sm="8" class="mt-n8">
                        <FormInlineMessage
                            v-if="$v.investment_min_check_size.$error"
                            class="errmsg"
                        >
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_investment_min_check_size_length_error.error">
                            {{ is_investment_min_check_size_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                            placeholder="100000"
                            outlined
                            dense
                            v-model="investment_min_check_size"
                            @input="investment_min_check_size  = formatToString(investment_min_check_size), $v.investment_min_check_size.$touch()"
                            @keypress="isNumber($event)"
                        />
                    </v-col>
                </v-row>

                <v-row class="mb-n11">
                    <v-col cols="12">
                        <p class="text-sm-left pt-2">
                            Your max check size for Startups in USD*
                            <span class="font-weight-regular"><br />For example, enter “1000000” for $ 1,000,000</span
                        >
                        </p>
                    </v-col>
                    <v-col cols="12" sm="8" class="mt-n8">
                        <FormInlineMessage
                            v-if="$v.investment_max_check_size.$error"
                            class="errmsg"
                        >
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_investment_max_check_size_length_error.error">
                            {{ is_investment_max_check_size_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                            placeholder="800000"
                            outlined
                            dense
                            v-model="investment_max_check_size"
                            @input="investment_max_check_size = formatToString(investment_max_check_size), $v.investment_max_check_size.$touch()"
                            @keypress="isNumber($event)"
                        />
                    </v-col>
                </v-row>

                <v-row class="mt-4"> 
                    <v-col cols="12">
                        <p class="text-sm-left">You have invested previously*</p>
                    </v-col>
                    <v-row no-gutters>
                        <v-col>
                            <FormInlineMessage
                                v-if="$v.model.$error"
                                class="errmsg"
                            >
                                Select an option below
                            </FormInlineMessage>
                        </v-col>
                    </v-row>

                    <v-col class="mt-n15">
                        <v-row no-gutters class="invested_previously">
                            <v-col cols="11" class="ml-6">
                                <v-checkbox
                                    @change="changeCheckboxValue('directly')"
                                    v-model="previous_ways.directly"
                                    color="#11aec9"
                                    label="Directly into a SME or a startup"
                                    class="newbold"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row no-gutters class="invested_previously">
                            <v-col cols="11" class="ml-6">
                                <v-checkbox
                                    @change="changeCheckboxValue('LP')"
                                    v-model="previous_ways.lp"
                                    color="#11aec9"
                                    label="As an LP in a VC fund"
                                    class="newbold"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                         <v-row no-gutters class="invested_previously">
                            <v-col cols="11" class="ml-6">
                                <v-checkbox
                                    @change="changeCheckboxValue('public')"
                                    v-model="previous_ways.public"
                                    color="#11aec9"
                                    label="In the public markets"
                                    class="newbold"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                         <v-row no-gutters>
                            <v-col cols="11" class="ml-6">
                                <v-checkbox
                                    @change="changeCheckboxValue('single_deal')"
                                    v-model="previous_ways.spv"
                                    color="#11aec9"
                                    label="Into a single-deal SPV"
                                    class="newbold"
                                ></v-checkbox>
                            </v-col>
                        </v-row> 
                    </v-col>
                </v-row>



                <v-col cols="12" sm="8" class="mt-6">
                    <v-row no-gutters>
                        <v-col cols="6">
                        <p class="text-sm-left bold">
                            Remarkable Past investments made
                        </p>
                        </v-col>
                    </v-row>
                    <v-textarea
                        v-model="type.remarkable_investments"
                        placeholder="List some successful startups you have invested in ..."
                        outlined
                        rows="3"
                        dense
                    />
                </v-col>

                <v-row align="center" justify="center" class="pt-2 btn-save">
                    <v-col cols="0" xs="5" sm="6" md="2" lg="4"/>
                    <v-col cols="12" xs="1" sm="1" md="1" lg="1">
                        <v-btn
                        id="btn-photo"
                        class="white--text mb-3"
                        style="font-weight: bold; font-size: 14px"
                        color="#11aec9"
                        min-width="20"
                        rounded
                        block
                        :disabled="false"
                        @click.stop.prevent="saveCategory()">
                            save
                        </v-btn>
                    </v-col>
                </v-row>
                <br><br>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import AccreditationService from "@/store/services/investor/accreditation.service";
import { myMixins } from "@/mixins/mixins.js";
import {  required } from "vuelidate/lib/validators";

export default {
    mixins: [myMixins],
    components: {
        FormInlineMessage,
    },

    props: {
        company_id: Number,
    },

    data() {
        return {
            describeFirmName: "",
            type: {
                investor: [],
                accreditation: "",
                investments_made: null, //int
                deals_looked: null, //int
                deals_made: null, //int  
                remarkable_investments: null,
            },
            type_investments_made: null, //int
            type_deals_looked: null, //int
            type_deals_made: null, //int

            investment_yearly_seed_budget: null, //int
            investment_min_check_size: null, //int
            investment_max_check_size: null, //int

            investment: {
                yearly_seed_budget: null, //int
                min_check_size: null, //int
                max_check_size: null, //int
                previous_investing: [],
            },
            combo_items: [
                "Angel",
                "VC Fund",
                "Accelerator",
                "Incubator",
                "Syndicate Lead",
                "Corporate VC",
                "Other",
            ],
            model: {
                $200K: false,
                $5_25M: false,
                $1_2_1M: false,
                $25M: false,
                $2_1_5M: false,
                individually_accredited: false,
                $5M: false,
                individually_qualified:false,
                // none_above: false,
            },
            model_none_above: false,
            previous_ways: {
                directly: false,
                lp: false,
                public: false,
                spv: false,
            },

            describe: {
                firm_name: "",
                gender: "Mr.",
                first_name: "",
                last_name: "",
                country: "",
                street: "",
                zip: "",
                city: "",
                phone: null,
                website: "",
            },
            investment_type: '',
        }
    },

    validations: {
        model: {required},
        type_investments_made: { required },
        type_deals_looked: { required }, 
        type_deals_made: { required },
        type: {
            investor: { required },
        },

        investment_yearly_seed_budget: { required },
        investment_min_check_size: { required },
        investment_max_check_size: { required },
    },

    methods: {
        disCheckOtherOptions() {
            this.model = {
                $200K: false,
                $5_25M: false,
                $1_2_1M: false,
                $25M: false,
                $2_1_5M: false,
                individually_accredited: false,
                $5M: false,
                individually_qualified:false
            };
        },
        changeCheckboxValue(value) {
            if (this.investment.previous_investing.includes(value)) {
                this.investment.previous_investing.splice(this.investment.previous_investing.indexOf(value), 1);
            } else {
                this.investment.previous_investing.push(value);
            }
        },

        saveType() {
            this.$v.type_investments_made.$touch();
            this.$v.type_deals_looked.$touch();
            this.$v.type_deals_made.$touch();
            if (
                this.$v.type_investments_made.$error ||
                this.$v.type_deals_looked.$error ||
                this.$v.type_deals_made.$error ||
                this.is_type_investments_made_length_error ||
                this.is_type_deals_looked_length_error ||
                this.is_type_deals_made_length_error
            ) {
                return this.$toast.open({
                    message: "<b>Accreditation</b>: Please fill all required fields or correct numbers or amounts having more than 15 digits before saving.",
                    type: "error",
                    position: "top-right",
                });
            }
            this.type_investments_made = this.formatToInt(this.type_investments_made);
            this.type_deals_looked     = this.formatToInt(this.type_deals_looked);
            this.type_deals_made       = this.formatToInt(this.type_deals_made);

            this.type.investments_made = this.type_investments_made;
            this.type.deals_looked     = this.type_deals_looked;
            this.type.deals_made       = this.type_deals_made;

            let model = [];
            if (!this.model_none_above) {
                for (const item in this.model) {
                    if (this.model[item] !== false) {
                        if (item == '$200K') model.push('+$200K')
                        if (item == '$5_25M') model.push('$5-25M')
                        if (item == '$1_2_1M') model.push('$1-2.1M')
                        if (item == '$25M') model.push('$25M')
                        if (item == '$2_1_5M') model.push('$2.1-5M')
                        if (item == 'individually_accredited') model.push('individually_accredited')
                        if (item == '$5M') model.push('+$5M')
                        if (item == 'individually_qualified') model.push('individually_qualified')
                    }
                }
            } else {
                model.push("none_above");
            }
            model = model.join();
            this.type.accreditation = model;

            AccreditationService.saveCategoryDashboard(this.type, 'type').then((res) => {
                if (res.status === 200) {
                    if (res.status == 200) {
                        this.$toast.open({
                            message: "Accreditation information saved successfully !",
                            type: 'success',
                            position: 'top-right'
                        });
                    } else {
                        this.$toast.open({
                            message: "Something went wrong !",
                            type: 'error',
                            position: 'top-right'
                        });
                    }
                }
            });

            this.type_investments_made = this.type_investments_made.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_deals_looked     = this.type_deals_looked.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_deals_made       = this.type_deals_made.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        saveCategory() {
            this.$v.investment_yearly_seed_budget.$touch();
            this.$v.investment_min_check_size.$touch();
            this.$v.investment_max_check_size.$touch();
            if (
                this.$v.investment_yearly_seed_budget.$error ||
                this.$v.investment_min_check_size.$error ||
                this.$v.investment_max_check_size.$error ||
                this.is_investment_yearly_seed_budget_length_error ||
                this.is_investment_min_check_size_length_error ||
                this.is_investment_max_check_size_length_error
            ) {
                return this.$toast.open({
                    message: "<b>Accreditation</b>: Please fill all required fields or correct numbers or amounts having more than 15 digits before saving.",
                    type: "error",
                    position: "top-right",
                });
            }
            this.investment_yearly_seed_budget = this.formatToInt(this.investment_yearly_seed_budget);
            this.investment_min_check_size     = this.formatToInt(this.investment_min_check_size);
            this.investment_max_check_size     = this.formatToInt(this.investment_max_check_size);

            this.type_investments_made = this.formatToInt(this.type_investments_made);
            this.type_deals_looked     = this.formatToInt(this.type_deals_looked);
            this.type_deals_made       = this.formatToInt(this.type_deals_made);

            // First Part
            this.type.investments_made = this.type_investments_made;
            this.type.deals_looked     = this.type_deals_looked;
            this.type.deals_made       = this.type_deals_made;

            let model = [];
            for(const item in this.model) {
                if(this.model[item]!==false){
                    if(item == '$200K') model.push('+$200K')
                    if(item == '$5_25M') model.push('$5-25M')
                    if(item == '$1_2_1M') model.push('$1-2.1M')
                    if(item == '$25M') model.push('$25M')
                    if(item == '$2_1_5M') model.push('$2.1-5M')
                    if(item == 'individually_accredited') model.push('individually_accredited')
                    if(item == '$5M') model.push('+$5M')
                    if(item == 'individually_qualified') model.push('individually_qualified')
                }
            }
            model = model.join();
            this.type.accreditation = model;

            AccreditationService.saveCategoryDashboard(this.type, 'type');

            this.type_investments_made = this.type_investments_made.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_deals_looked     = this.type_deals_looked.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.type_deals_made       = this.type_deals_made.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            // Second Part

            this.investment.yearly_seed_budget = this.investment_yearly_seed_budget;
            this.investment.min_check_size     = this.investment_min_check_size;
            this.investment.max_check_size     = this.investment_max_check_size;

            AccreditationService.saveCategoryDashboard(this.investment, 'investment').then((res) => {
                if(res.status == 200) {
                    this.saveType();
                } else {
                    this.$toast.open({
                        message: "Something went wrong !",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            })

            this.investment_yearly_seed_budget = this.investment_yearly_seed_budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_min_check_size     = this.investment_min_check_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_max_check_size     = this.investment_max_check_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        getType() {
            AccreditationService.getCategory()
                .then((res) => {
                if (res.data) {
                    this.type.investor = res.data.investor_type;
                    this.type.remarkable_investments = res.data.remarkable_investments;

                    this.type_investments_made = res.data.previous_investments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.type_deals_looked     = res.data.yearly_deals_looked.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.type_deals_made       = res.data.yearly_deals_made.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                    this.investment_yearly_seed_budget = res.data.yearly_budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.investment_min_check_size     = res.data.min_check_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.investment_max_check_size     = res.data.max_check_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                    let accreditation = res.data.accreditation.split(',');
                    if(accreditation.includes("+$200K") ) {
                        this.model.$200K = true;
                    } 
                    if(accreditation.includes("$5-25M") ) {
                        this.model.$5_25M = true;
                    } 
                    if(accreditation.includes("$1-2.1M") ) {
                        this.model.$1_2_1M = true;
                    } 
                    if(accreditation.includes("$25M") ) {
                        this.model.$25M = true;
                    } 
                    if(accreditation.includes("$2.1-5M") ) {
                        this.model.$2_1_5M = true;
                    } 
                    if(accreditation.includes("individually_accredited") ) {
                        this.model.individually_accredited = true;
                    } 
                    if(accreditation.includes("+$5M") ) {
                        this.model.$5M = true;
                    } 
                    if(accreditation.includes("individually_qualified") ) {
                        this.model.individually_qualified = true;
                    }
                    if(accreditation.includes("none_above") ) {
                        this.model_none_above = true;
                    }

                    if(res.data.investment_methods)
                    {
                        this.investment.previous_investing = res.data.investment_methods;
                    }
                    
                    res.data.investment_methods.forEach((entry) => {
                        if (entry == "directly") {
                            this.previous_ways.directly = true;
                        } else if (entry == "LP") {
                            this.previous_ways.lp = true;
                        } else if (entry == "public") {
                            this.previous_ways.public = true;
                        } else if (entry == "single_deal") {
                            this.previous_ways.spv = true;
                        }
                    });
                }
            })
            .catch((e) => {
                //console.error(e);
            });
        },

        getTypeOfAccreditation() {
            AccreditationService.getType().then((res) => {
                if (res.data) {
                    if (res.data.firm_or_individual) {
                        this.investment_type = res.data.firm_or_individual;
                    }
                    return;
                }
                this.investment_type = "firm";
            });
        }, 
        getDescribe() {
            AccreditationService.getProfile().then((res) => {
                if (res.data) {
                    this.describeFirmName = res.data.firm_name;
                }
            });
        },
    },

    mounted() {
        this.getDescribe();
        this.getType();
        this.getTypeOfAccreditation();
    },
}
</script>
<style scoped>
@media (min-width: 1264px) and (max-width: 2890px) { 
    .btn-save {
        margin-right: 6% !important;
    }
}
@media (min-width: 295px) and (max-width: 381px) { 
    .accredit_first_option {
        margin-bottom: 30px !important;
    }
    .accredit_model {
        margin-bottom: 20px !important;
    }
    .none_model {
        margin-top: 20px !important;
    }
}
@media (min-width: 200px) and (max-width: 294px) { 
    .accredit_first_option {
        margin-bottom: 50px !important;
    }
    .accredit_model {
        margin-bottom: 20px !important;
    }
    .none_model {
        margin-top: 35px !important;
    }
    .invested_previously {
        margin-top: 15px !important;
    }
}
@media (min-width: 200px) and (max-width: 414px) { 
    .none_model {
        margin-top: 30px !important;
    }
    .all_owners {
        margin-top: 14px !important;
    }
}
@media (min-width: 415px) and (max-width: 540px) { 
    .none_model {
        margin-top: 16px !important;
    }
}
@media (min-width: 200px) and (max-width: 334px) { 
    .numberInvestment {
        margin-top: 15px !important;
    }
}
.newbold label {
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
  margin-top: 9px;
}
</style>
