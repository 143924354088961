<template>
  <v-row align="center" justify="center" class="step-1">
    <v-col cols="10">
      <v-responsive :aspect-ratio="16 / 9">
        <v-card-text>
          <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec">
            <v-row justify="center" class="mx-10">
              <v-col cols="2">
                <v-img
                  style="border-radius: 20%"
                  min-height="95%"
                  :src="avatarPreviewUrl"
                />
              </v-col>
              <v-col cols="10">
                <p class="text-sm-left bold">Main Contact</p>
                <p class="text-sm-left thin">{{ this.me.first_name }}</p>
                <p class="text-sm-left thin">{{ this.me.last_name }}</p>
                <p class="text-sm-left thin">{{ this.me.email }}</p>
              </v-col>
            </v-row>
          </v-sheet>

          <v-col />

          <v-col>
            <p
              class="cyan--text text--darken-1 font-weight-bold text-sm-center"
              id="title"
            >
              Describe your company
            </p>
          </v-col>

          <DescribeBlock @passCID="emitToApplication" @block2="toLinkBlock()" />

          <LinkBlock
            @block3="resumeBlock = true"
            v-if="linkBlock"
            v-bind:company_id="this.company_id"
          />

          <ResumeBlock
            @block4="nextBlock = true"
            v-if="resumeBlock"
            v-bind:company_id="this.company_id"
          />

          <NextBlock @agreed="$emit('done')" v-if="nextBlock" />
        </v-card-text>
      </v-responsive>
    </v-col>
  </v-row>
</template>

<script>
import DescribeBlock from "@/components/Steps/Step2/Blocks/DescribeBlock";
import LinkBlock from "@/components/Steps/Step2/Blocks/LinkBlock";
import ResumeBlock from "@/components/Steps/Step2/Blocks/ResumeBlock";
import NextBlock from "@/components/Steps/Step2/Blocks/NextBlock";
import Profile from "@/store/services/profile-service";

export default {
  components: {
    DescribeBlock,
    LinkBlock,
    ResumeBlock,
    NextBlock,
  },

  props: {
    me: Object,
  },

  data() {
    return {
      avatarPreviewUrl: null,
      linkBlock: false,
      nextBlock: false,
      resumeBlock: false,
      company_id: null,
    };
  },

  methods: {
    emitToApplication(company_id, company_name) {
      this.company_id = company_id;
      this.$emit("passCID", company_id, company_name);
    },
    toLinkBlock() {
      this.linkBlock = true;
    },

    retrieveTopAvatar() {
      Profile.getAvatar()
        .then((res) => {
          this.avatarPreviewUrl = res.data;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
  },

  mounted() {
    this.retrieveTopAvatar();
  },
};
</script>
