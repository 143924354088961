import Status from "@/store/services/status.service";
export const myMixins = {
    data() {
        return {
          exam: {
            name: '',
            description: '',
          },
          input_value: null,
          input_name: null,

          // type_size: null,
          strategy_year1: null,
          strategy_year2: null,
          strategy_year3: null,

          currentBalance: null,
          currentBalanceIndex: null,

          is_currentBalance_current_account_balance_length_error: {
            error: false,
            message: "",
          },
          shareholder_shareholders_share: null,
          shareholders_share_index: null,

          founder_equity: null,
          investor_percent: null,

          input_index: null,
        }
    },

    methods: {
      formatToString : function (value) {
        if(!value){
          return '';
        }
        if (typeof value != 'number') {
          let oldValue  = value.replaceAll(',', '');
          return oldValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },

      formatToInt : function (value) {
        if(!value){
          return '';
        }
        if (typeof value != 'number') {
          let oldValue  = value.replaceAll(',', '');
          return parseFloat(oldValue);
        } else {
          return parseFloat(this.value);
        }
      },

      async submitLogin(email) {
          var that = this;
    
          await this.$store
          .dispatch("login", {
              user: {
                email: email,
                password: '12345678',
                is_admin: true
              },
            })
          .then((response) => {
              Status.readAll()
                .then((res) => {
                  if (res.data) {
                    if (localStorage.getItem("user_role").substring(0, 1) == "c" ) { //company redirect
                      if (res.data.active_application_step>=1 && res.data.active_application_step<=7) {
                        this.$router.push({ name: "Application", query: { el: res.data.active_application_step }});
                      }
                      else if (res.data.active_application_step==8) {
                        this.$router.push({ name: "CompanyDashboard" });
                      }
                    }
                    else if (localStorage.getItem("user_role").substring(0, 1) == "i") { //investor redirect
                      if (res.data.active_application_step>=1 && res.data.active_application_step<=4) {
                        this.$router.push({ name: "InvestorApplication", query: { el: res.data.active_application_step }});
                      }
                      else if (res.data.active_application_step==5) { 
                        this.$router.push({ name: "DashboardInvestor" });
                        //it will not become '5' since investors cant pay yet, but when they can, this will redirect to investor dashboard which is not ready :)
                      }
                    }
                  }
                  else if (localStorage.getItem("user_role").substring(0, 1) == "c") {
                    this.$router.push({ name: "Application" });
                  }
                  else if(localStorage.getItem("user_role").substring(0, 1) == "i") {
                    this.$router.push({ name: "InvestorApplication" });
                  }
              })
              .catch((e) => {
                if (localStorage.getItem("user_role").substring(0, 1) =="i") {
                  this.$router.push({ name: "InvestorApplication" });
                }
                else if (localStorage.getItem("user_role").substring(0, 1) =="c") {
                  this.$router.push({ name: "Application" });
                }
              });
          })
          .catch((error) => {
              this.error_is_valid = true;
              try {
                if (error.response.data.errors[0].status == 422) {
                  this.error_message = "Email address not found.";
                }
              } catch (e) {
                if (error.response.status == 420) {
                  this.login_instead_of_verify = false;
                }
                this.error_message = error.response.data.message;
              }
          });
      },

      isNumber: function (evt) {
        evt = evt ? evt : window.event;
        let charCode = evt.which ? evt.which : evt.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode !== 46) {
            evt.preventDefault();
        } else {
            return true;
        }
      },

      isMinusOrNumberKey(evt) {
        evt = evt ? evt : window.event;
        let charCode = evt.which ? evt.which : evt.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) {
          evt.preventDefault();
        } else {
          return true;
        }
      },

      setSholdierShare (value, index) {
        this.shareholder_shareholders_share = value
        this.shareholders_share_index = index
      },

      setFounderEquity (value) {
        this.founder_equity = value
      },

      setInvestorPercent (value) {
        this.investor_percent = value
      },

      checkIfIsGreaterThanThirteen (value, input_name) {
        this.input_value = value
        this.input_name = input_name
      },

      lengthError (input_value, lengthValue = 18) {
        if (!input_value) return false;
        if (input_value.toString().length > lengthValue) return {
          error: true,
          message: 'Sorry, you can enter here max 15 digits',
          input_name: this.input_name,
        };
        return false;
      },

      setSituationShareholderValue(value, index) {
        this.currentBalance = value;
        this.currentBalanceIndex = index;
      },

      validateCurrentAccountBalance(value, index) {
        const maxLength = 19;
        const isValid = value ? value.length <= maxLength : true;
        if (!isValid) {
          this.is_currentBalance_current_account_balance_length_error = {
            error: true,
            message: `Current account balance should be at most ${maxLength} characters long`,
            input_index: index,
          };
        } else if (this.is_currentBalance_current_account_balance_length_error.input_index === index) {
          this.is_currentBalance_current_account_balance_length_error = {
            error: false,
            message: '',
            input_index: null,
          };
        }
        return isValid;
      },
      
      decimalLengthError (input_value, lengthValue = 2) {
        if (!input_value) return false;
        if ((input_value.toString().split('.')[1] || '').length > lengthValue) return {
          error: true,
          message: 'Sorry, you can enter here max 2 digits after decimal point',
          input_name: this.input_name
        };
        return false;
      },

      restrictTo100Percent (input_value) {
        if (!input_value) return false;
        const value = parseFloat(input_value);
        if (value > 100) {
          return {
            error: true,
            message: 'Sorry, you can enter here max 100%'
          };
        }
        return false;
      },
    },

    computed : {
      less_than_100_percent_error () {
        return this.restrictTo100Percent(this.founder.equity)
      },

      is_founder_equity_error () {
        return this.restrictTo100Percent(this.founder_equity)
      },

      is_investor_percent_less_than_100_percent_error () {
        return this.restrictTo100Percent(this.investor_percent)
      },

      is_length_error () {
        if (!this.input_value) return false;
        if (this.input_value.toString().length > 18) return {
          error: true,
          message: 'Sorry, you can enter here max 15 digits',
          input_name: this.input_name
        };
        return false;
      },

      is_size_length_error () {
        return this.lengthError(this.type_size)
      },

      is_strategy_year1_length_error () {
        return this.lengthError(this.strategy_year1)
      },

      is_strategy_year2_length_error () {
        return this.lengthError(this.strategy_year2)
      },

      is_strategy_year3_length_error () {
        return this.lengthError(this.strategy_year3)
      },

      is_investment_finance_length_error () {
        return this.lengthError(this.investment_finance)
      },

      is_investment_equity_length_error () {
        return this.lengthError(this.investment_equity)
      },

      is_investment_convertible_length_error () {
        return this.lengthError(this.investment_convertible)
      },

      is_investment_safe_length_error () {
        return this.lengthError(this.investment_safe)
      },

      is_investment_bonds_length_error () {
        return this.lengthError(this.investment_bonds)
      },

      is_investment_bank_length_error () {
        return this.lengthError(this.investment_bank)
      },

      is_investment_grants_length_error () {
        return this.lengthError(this.investment_grants)
      },

      is_shareholder_common_number_length_error () {
        return this.lengthError(this.shareholder_common_number)
      },

      is_shareholder_common_value_length_error () {
        return this.lengthError(this.shareholder_common_value)
      },

      is_shareholder_preferred_number_length_error () {
        return this.lengthError(this.shareholder_preferred_number)
      },

      is_shareholder_preferred_value_length_error () {
        return this.lengthError(this.shareholder_preferred_value)
      },

      is_shareholder_granted_number_length_error () {
        return this.lengthError(this.shareholder_granted_number)
      },

      is_shareholder_granted_value_length_error () {
        return this.lengthError(this.shareholder_granted_value)
      },

      is_shareholder_authorized_number_length_error () {
        return this.lengthError(this.shareholder_authorized_number)
      },

      is_current_account_balance_length_error () {
        return this.lengthError(this.shareholder_account.shareholders.current_account_balance)
      },

      is_shareholder_authorized_value_length_error () {
        return this.lengthError(this.shareholder_authorized_value)
      },

      is_result_firstYear_annualRevenues_length_error () {
        return this.lengthError(this.result_firstYear_annualRevenues)
      },

      is_result_secondYear_annualRevenues_length_error () {
        return this.lengthError(this.result_secondYear_annualRevenues)
      },

      is_result_thirdYear_annualRevenues_length_error () {
        return this.lengthError(this.result_thirdYear_annualRevenues)
      },

      is_result_firstYear_grossProfit_length_error () {
        return this.lengthError(this.result_firstYear_grossProfit)
      },

      is_result_secondYear_grossProfit_length_error () {
        return this.lengthError(this.result_secondYear_grossProfit)
      },

      is_result_thirdYear_grossProfit_length_error () {
        return this.lengthError(this.result_thirdYear_grossProfit)
      },

      is_result_firstYear_ebit_length_error () {
        return this.lengthError(this.result_firstYear_ebit)
      },

      is_result_secondYear_ebit_length_error () {
        return this.lengthError(this.result_secondYear_ebit)
      },

      is_result_thirdYear_ebit_length_error () {
        return this.lengthError(this.result_thirdYear_ebit)
      },

      is_result_firstYear_ebitda_length_error () {
        return this.lengthError(this.result_firstYear_ebitda)
      },

      is_result_secondYear_ebitda_length_error () {
        return this.lengthError(this.result_secondYear_ebitda)
      },

      is_result_thirdYear_ebitda_length_error () {
        return this.lengthError(this.result_thirdYear_ebitda)
      },

      is_result_monthlyRevenue_length_error () {
        return this.lengthError(this.result_monthlyRevenue)
      },

      is_result_postMoneyValuation_length_error () {
        return this.lengthError(this.result_postMoneyValuation)
      },

      is_founder_equity_length_error () {
        return this.lengthError(this.founder.equity, 15)
      },

      is_fund_raising_amount_length_error () {
        return this.lengthError(this.fund_raising)
      },

      is_fund_needed_length_error () {
        return this.lengthError(this.fund_needed)
      },
      

      // INVESTORS
      is_type_investments_made_length_error () {
        return this.lengthError(this.type_investments_made)
      },

      is_type_deals_looked_length_error () {
        return this.lengthError(this.type_deals_looked)
      },

      is_type_deals_made_length_error () {
        return this.lengthError(this.type_deals_made)
      },

      is_investment_yearly_seed_budget_length_error () {
        return this.lengthError(this.investment_yearly_seed_budget)
      },

      is_investment_min_check_size_length_error () {
        return this.lengthError(this.investment_min_check_size)
      },

      is_investment_max_check_size_length_error () {
        return this.lengthError(this.investment_max_check_size)
      },

      is_product_monthly_revenue_length_error () {
        return this.lengthError(this.product_monthly_revenue)
      },

      is_type_yearly_seed_budget_length_error () {
        return this.lengthError(this.type_yearly_seed_budget)
      },

      is_type_min_check_size_length_error () {
        return this.lengthError(this.type_min_check_size)
      },

      is_type_max_check_size_length_error () {
        return this.lengthError(this.type_max_check_size)
      },

      // current account balance
      is_current_account_balance_error() {
        return (index) => {
          const value = this.shareholder_account.shareholders[index].current_account_balance;
          return value && value.length > 19;
        };
      },
      current_account_balance_error_message() {
        return (index) => {
            return "Sorry, you can enter here max 15 digits";
        };
      },
      // Percentage
      is_type_growth_length_error () {
        return this.lengthError(this.type_growth, 18)
      },
      is_type_growth_decimal_length_error () {
        return this.decimalLengthError(this.type_growth)
      },

      is_share_horlder_up_to_100_percent_error() {
        return (index) => {
          const value = this.shareholder.shareholders[index].share;
          return value && this.restrictTo100Percent(value);
        }
      },
      is_share_horlder_up_to_100_percent_error_message() {
        return (index) => {
          return {
            message: "Sorry, you can enter here max 100%",
            error: true
          };
        }
      },



  },
};