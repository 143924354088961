<template>
  <div>
    <v-toolbar height="78">
      <!--  style="background: #f6f6f6" -->
      <img
        class="ml-4 mr-n10"
        alt="IdeasFundX Logo"
        src="../assets/logo.png"
        width="200"
        height="30"
        v-bind:onclick="this.CMSlink"
      />
      <v-spacer></v-spacer>
      <div class="mainmenu">
        <ul>
          <li><a v-bind:href="CMSabout" target="_blank">About</a></li>
          <li><a v-bind:href="CMScontact" target="_blank">Contact Us</a></li>
          <li><a v-bind:href="CMSinvestor" target="_blank">Investor</a></li>
          <li><a v-bind:href="CMShome" target="_blank">Company</a></li>
        </ul>
      </div>
      <v-spacer></v-spacer>
      <div class="header-right">
        <a href="/login">Login</a>
        <!-- v-bind:href="CMSlogin" -->
      </div>

      <!-- <v-spacer /> -->

      <!-- <v-divider vertical style="border-color: #0db0c4" /> -->

      <!-- <v-img
        class="mx-2"
        contain
        maxWidth="70px"
        maxHeight="70px"
        style="border-radius: 50%; border: 1px solid"
        :src="avatarPreviewUrl"
      />

      <v-icon @click.prevent.stop="RightSidebarPanel()">
        mdi-cog-outline
      </v-icon> -->
    </v-toolbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      CMSlink: `window.location=\'${process.env.VUE_APP_CMS_URL}\';`,
      CMShome: `${process.env.VUE_APP_CMS_URL}`,
      CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
      CMSinvestor: `${process.env.VUE_APP_CMS_URL}investor`,
      CMSabout: `${process.env.VUE_APP_CMS_URL}about`,
      CMSlogin: `${process.env.VUE_APP_CMS_URL}login`,
    };
  },

  methods: {},
};
</script>

<style>
.link:hover {
  cursor: pointer;
}

.mainmenu {
  text-align: center;
}
.mainmenu ul {
  margin: auto;
  padding: 0;
  list-style: none;
}
.mainmenu ul li {
  display: inline;
  margin: 0 25px;
}
.mainmenu li a {
  display: inline-block;
  color: #505A63 !important;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.4px;
  transition: 0.3s;
  text-decoration: none;
}
.mainmenu li a:hover {
  color: #269abc !important;
}

.right-side {
  position: fixed;
  float: right;
}

.header-right {
  text-align: right;
}
.header-right a {
  color: #fff !important;
  display: inline-block;
  background: #00aac2;
  padding: 8px 35px;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 40px;
  font-weight: 700;
  letter-spacing: 0.4px;
  border: 2px solid #00aac2;
  transition: 0.3s;
  text-decoration: none;
}
.header-right a:hover {
  background: transparent;
  color: #00aac2 !important;
}
</style>
