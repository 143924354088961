<template>
  <v-dialog v-model="active_dialog" max-width="550px">
    <v-card style="overflow-x: hidden; overflow-y: hidden">
      <div class="">
        <div>
          <img class="mt-5" src="../assets/logo.png" height="15%" width="15%">
          <div class="justify-center mb-4">
            <span v-if="this.user_status == 'c-1'" id="title" class="mb-n3">Company Application</span>
            <span v-if="this.user_status == 'i-1'" id="title" class="mb-n3">Investor Application</span>
            <span v-if="this.user_status == 'c-2'" id="title" class="mb-n3">Company Dashboard</span>
            <span v-if="this.user_status == 'i-2'" id="title" class="mb-n3">Investor Dashboard</span>
          </div>
        </div>
        <img src="../assets/img/icons8-close-24.png" alt="close" class="close-icon" @click="redictionHome()">
      </div>
      <v-sheet height="100%" class="mx-4 my-0" rounded>
        <div class="main-title">
          <p v-if="this.user_status == 'c-1' || this.user_status == 'i-1'"><span>REVIEW</span><br> <span>IN PROGRESS</span></p>
          <p v-if="this.user_status == 'c-2' || this.user_status == 'i-2'">COMING SOON</p>
        </div>
        <br>
        <v-card-text>
          <v-container style="width: 350px;">
            <v-row id="sub-text" class="text-left mt-n5">
              <p v-if="this.user_status == 'c-1'">
                Our team is now looking at the information you have provided .
              </p>
              <p v-if="this.user_status == 'c-2'">
                Because we want the best for you, we are working
                hard to build the new company dashboard where
                you will be able to update your data and
                administrate your billing information.
              </p>

              <p v-if="this.user_status == 'i-1'">
                Once your application will be confirmed,  
                you will receive our newsletter with investment 
                opportunities.
              </p>
              <p v-if="this.user_status == 'i-2'">
                Your application has been validated. You are now among our list of investors. 
              </p>

              <p v-if="this.user_status == 'c-1'">
                Our expert team is now reviewing your application. 
                We will notify you if we need more information. 
                The process of validation will take 15 days from 
                the date of the payment.
              </p>
              <p v-if="this.user_status == 'c-2'">
                The new release of the company dashboard 
                will be online very soon.
              </p>

              <p v-if="this.user_status == 'i-1'">
                For each investment opportunity you 
                want to learn more about, just click on the link and
                we will send you a NDA before sharing with you all the
                information about the deal.
              </p>
              <p v-if="this.user_status == 'i-2'">
                Because we want the best for you, we are working
                hard to build the first release of the investor dashboard 
                where you will be able to update your data and
                administrative information.
              </p>

              <p v-if="this.user_status == 'c-1'">
                When your company is in the IdeasFundX database, 
                your deal will be ready to be submitted to investors 
                who are working with us.
              </p>
              <p v-if="this.user_status == 'c-2'">
                Meanwhile, you will receive notifications from us
                when your application will be confirmed and 
                when investors express interests in your company.
              </p>

              <p v-if="this.user_status == 'i-1'">
                Because we want the best for you, we are working
                hard to build the new investor dashboard where
                you will be able to update your data and
                administrative information. Later, it will be also the place
                to check and follow-up the dealflow recommandations.
              </p>
              <p v-if="this.user_status == 'i-2'">
                The new release of the investor dashboard will be online very soon.
              </p>

              <p v-if="this.user_status == 'c-1'">
                Because we want the best for you, we are working
                hard to build the new company dashboard where
                you will be able to update your data and
                administrative information. Later, it will be also the place
                to check and follow-up the dealflow recommandations
              </p>

              <p v-if="this.user_status == 'i-1'">
                The new release of the investor dashboard will be online very soon.
              </p>
              <p v-if="this.user_status == 'i-2'">
                You will receive our newsletter with investment 
                opportunities. For each investment opportunity you 
                want to learn more about, just click on the link and
                we will send you a NDA before sharing with you all the
                information about the deal.
              </p>
            </v-row>
            <v-row id="sub-text" class="text-left mt-n2 mb-n9">
              <p>
                <br />
                <a href="/contact">Contact Us</a> for any further information or questions.
              </p>
            </v-row>
            <br>
          </v-container>
        </v-card-text>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: { user_status: String },

  data() {
    return {
      //CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
      active_dialog: true,
    };
  },

  methods: {
    redictionHome () {
      this.$router.push({ name: "Home" });
    },
    closeDialog() {
      window.open('/home', "_top");
      //this.active_dialog=false;
    },
  },
};
</script>

<style scoped lang="css">
.main-title {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  background: #00abc5; 
  color: white; 
  height: 200px;
  text-align: center;
}
.main-title p {
  align-items: center !important;
  justify-content: center !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 53px;
  width: 400px;
  border-bottom: 14px solid #fff;
}
#title {
  font-weight: bolder;
  font-size: 18px;
}
#sub-text p{
  font-weight: normal;
  font-size: 14px;
}
.close-icon {
  position: absolute; 
  top: 4%; 
  right: 30%;
  cursor: pointer;
}
</style>
