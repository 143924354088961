<template>
  <v-row align="center" justify="center" class="step-1">
    <v-col cols="10">
      <v-responsive :aspect-ratio="16 / 9">
        <v-card-text>
          <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec">
            <v-row justify="center" class="mx-10">
              <v-col cols="2">
                <v-img
                  style="border-radius: 20%"
                  min-height="95%"
                  :src="logoPreviewUrl"
                />
              </v-col>
              <v-col cols="10">
                <p class="text-sm-left thin">{{this.me.company_name}}</p>
                <p class="text-sm-left bold">Main Contact</p>
                <p class="text-sm-left thin">{{ this.me.first_name }}</p>
                <p class="text-sm-left thin">{{ this.me.last_name }}</p>
                <p class="text-sm-left thin">{{ this.me.email }}</p>
              </v-col>
            </v-row>
          </v-sheet>
          <v-col />

          <Fund
            @block2="investorBlock = true"
            v-bind:company_id="company_id"
          />

          <Investor
            @block3="nextBlock = true"
            v-if="investorBlock"
            v-bind:company_id="company_id"
          />

          <Next @agreed="$emit('done')" v-if="nextBlock" />
        </v-card-text>
      </v-responsive>
    </v-col>
  </v-row>
</template>

<script>
import CompanyService from "@/store/services/company/company.service";
import Fund from "@/components/Steps/Step6/Blocks/FundBlock";
import Investor from "@/components/Steps/Step6/Blocks/InvestorBlock";
import Next from "@/components/Steps/Step6/Blocks/NextBlock";
import Status from "@/store/services/status.service";

export default {
  components: {
    Fund,
    Investor,
    Next,
  },
  
  props: {
    me: Object,
    company_id: Number,
  },

  data() {
    return {
      // me: {},
      investorBlock: false,
      nextBlock: false,
      logoPreviewUrl: null,
    };
  },

  methods: {
    retrieveLogo(company_id) {
      CompanyService.getLogo(company_id)
        .then((res) => {
          this.logoPreviewUrl = res.data;
        })
        .catch((error) => {});
    },
  },

  created() {
  },
  mounted() {
    Status.readAll()
      .then((res) => {
        if (res.data) {
          this.company_id = res.data.active_company_id;
          this.retrieveLogo(res.data.active_company_id);
        }
      })
  },
};
</script>

<style>
.step-1 {
  font-family: "Arial", sans-serif;
}

p.smallest {
  font-size: 12px;
}

p.thin {
  font-weight: normal;
}

#btn-photo {
  font-weight: bolder;
  font-size: 14px;
  border-radius: 70px;
}

p {
  font-weight: bold;
  font-size: 14px;
}

#title {
  letter-spacing: -1px;
}

.mdi-twitter,
.mdi-linkedin {
  color: #00aac3 !important;
}

#btn-add {
  text-transform: none;
  font-weight: bold;
}

#btn-save {
  font-weight: bolder;
  font-style: italic;
}

.step-1 .v-text-field .v-input__control .v-input__slot {
  min-height: 30px !important;
  display: flex !important;
  align-items: center !important;
}

.v-input--selection-controls {
  height: 20px;
}

.radio-label label {
  margin-left: 25px;
}

#btn-verify {
  font-weight: bolder;
  font-size: 18px;
  border-radius: 70px;
  margin-bottom: 50px;
}
</style>
