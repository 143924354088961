<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            What are your past result in the last 3 years ?
          </p>
          <p class="text-sm-left font-weight-thin">
            Please tell us about your Key financials of your company in the last
            3 years. In the first line, replace .... by the years.
          </p>
          <v-row>
            <v-col cols="3" align="center" justify="center">
              <p class="text-sm-left blue-grey--text text--lighten-2 font-weight-thin mt-2">
                Unit : USD K
              </p>
              <p class="text-sm-left my-9">Annual revenues</p>
              <p class="text-sm-left my-9">Gross Profit</p>
              <p class="text-sm-left my-9">EBITDA</p>
              <p class="text-sm-left my-9">EBIT</p>
            </v-col>

            <!-- First year -->

            <v-col cols="2">
              <v-text-field
                v-model="result.firstYear.year"
                background-color="grey"
                class="years"
                placeholder="...."
                outlined
                dense
                @keypress="isNumber($event)"
              />
              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_firstYear_annualRevenues.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_result_firstYear_annualRevenues_length_error.error" class="errmsg">
                  {{ is_result_firstYear_annualRevenues_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_firstYear_annualRevenues"
                  outlined
                  dense
                  @input="result_firstYear_annualRevenues  = formatToString(result_firstYear_annualRevenues), $v.result_firstYear_annualRevenues.$touch()"
                  @keypress="isNumber($event)"
                />
              </div>

              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_firstYear_grossProfit.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_result_firstYear_grossProfit_length_error.error" class="errmsg">
                  {{ is_result_firstYear_grossProfit_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_firstYear_grossProfit"
                  outlined
                  dense
                  @keypress="isMinusOrNumberKey($event)"
                  @input="result_firstYear_grossProfit  = formatToString(result_firstYear_grossProfit), $v.result_firstYear_grossProfit.$touch()"
                />
              </div>

              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_firstYear_ebitda.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_result_firstYear_ebitda_length_error.error" class="errmsg">
                  {{ is_result_firstYear_ebitda_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_firstYear_ebitda"
                  outlined
                  dense
                  @keypress="isMinusOrNumberKey($event)"
                  @input="result_firstYear_ebitda  = formatToString(result_firstYear_ebitda), $v.result_firstYear_ebitda.$touch()"
                />
              </div>

              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_firstYear_ebit.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_result_firstYear_ebit_length_error.error" class="errmsg">
                  {{ is_result_firstYear_ebit_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_firstYear_ebit"
                  outlined
                  dense
                  @keypress="isMinusOrNumberKey($event)"
                  @input="result_firstYear_ebit  = formatToString(result_firstYear_ebit), $v.result_firstYear_ebit.$touch()"
                />
              </div>
            </v-col>

            <!-- Second year -->

            <v-col cols="2">
              <v-text-field
                v-model="result.secondYear.year"
                background-color="grey"
                class="years"
                placeholder="...."
                outlined
                dense
                @keypress="isNumber($event)"
              />

              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_secondYear_annualRevenues.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage> 
                <FormInlineMessage v-if="is_result_secondYear_annualRevenues_length_error.error" class="errmsg">
                  {{ is_result_secondYear_annualRevenues_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_secondYear_annualRevenues"
                  outlined
                  dense
                  @input="result_secondYear_annualRevenues = formatToString(result_secondYear_annualRevenues), $v.result_secondYear_annualRevenues.$touch()"
                  @keypress="isNumber($event)"
                />
              </div>

              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_secondYear_grossProfit.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_result_secondYear_grossProfit_length_error.error" class="errmsg">
                  {{ is_result_secondYear_grossProfit_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_secondYear_grossProfit"
                  outlined
                  dense
                  @keypress="isMinusOrNumberKey($event)"
                  @input="result_secondYear_grossProfit = formatToString(result_secondYear_grossProfit), $v.result_secondYear_grossProfit.$touch()"
                />
              </div>

              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_secondYear_ebitda.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_result_secondYear_ebitda_length_error.error" class="errmsg">
                  {{ is_result_secondYear_ebitda_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_secondYear_ebitda"
                  outlined
                  dense
                  @keypress="isMinusOrNumberKey($event)"
                  @input="result_secondYear_ebitda = formatToString(result_secondYear_ebitda), $v.result_secondYear_ebitda.$touch()"
                />
              </div>

              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_secondYear_ebit.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_result_secondYear_ebit_length_error.error" class="errmsg">
                  {{ is_result_secondYear_ebit_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_secondYear_ebit"
                  outlined
                  dense
                  @keypress="isMinusOrNumberKey($event)"
                  @input="result_secondYear_ebit = formatToString(result_secondYear_ebit), $v.result_secondYear_ebit.$touch()"
                />
              </div>
            </v-col>

            <!-- Third year -->

            <v-col cols="2">
              <v-text-field
                v-model="result.thirdYear.year"
                background-color="grey"
                class="years"
                placeholder="...."
                outlined
                dense
                @keypress="isNumber($event)"
              />

              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_thirdYear_annualRevenues.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_result_thirdYear_annualRevenues_length_error.error" class="errmsg">
                  {{ is_result_thirdYear_annualRevenues_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_thirdYear_annualRevenues"
                  outlined
                  dense
                  @input="result_thirdYear_annualRevenues  = formatToString(result_thirdYear_annualRevenues), $v.result_thirdYear_annualRevenues.$touch()"
                  @keypress="isNumber($event)"
                />
              </div>

              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_thirdYear_grossProfit.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_result_thirdYear_grossProfit_length_error.error" class="errmsg">
                  {{ is_result_thirdYear_grossProfit_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_thirdYear_grossProfit"
                  outlined
                  dense
                  @keypress="isMinusOrNumberKey($event)"
                  @input="result_thirdYear_grossProfit  = formatToString(result_thirdYear_grossProfit), $v.result_thirdYear_grossProfit.$touch()"
                />
              </div>

              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_thirdYear_ebitda.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_result_thirdYear_ebitda_length_error.error" class="errmsg">
                  {{ is_result_thirdYear_ebitda_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_thirdYear_ebitda"
                  outlined
                  dense
                  @keypress="isMinusOrNumberKey($event)"
                  @input="result_thirdYear_ebitda  = formatToString(result_thirdYear_ebitda), $v.result_thirdYear_ebitda.$touch()"
                />
              </div>

              <div style="position: relative;">
                <FormInlineMessage v-if="$v.result_thirdYear_ebit.$error" class="errmsg position-absolute-error">
                  Fill in the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_result_thirdYear_ebit_length_error.error" class="errmsg">
                  {{ is_result_thirdYear_ebit_length_error.message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="result_thirdYear_ebit"
                  outlined
                  dense
                  @keypress="isMinusOrNumberKey($event)"
                  @input="result_thirdYear_ebit  = formatToString(result_thirdYear_ebit), $v.result_thirdYear_ebit.$touch()"
                />
              </div>
            </v-col>
            
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="mb-5">
        <v-col cols="11">
          <v-divider />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <p class="text-sm-left">
            Monthly Recurring Revenue in USD
            <span class="font-weight-thin"
              >in the last semester (write 0 if none) ?</span
            >
          </p>
          <v-row justify="start">
            <v-col cols="2">
              <FormInlineMessage v-if="$v.result_monthlyRevenue.$error" class="errmsg text-left">
                Fill in the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_result_monthlyRevenue_length_error.error" class="errmsg">
                {{ is_result_monthlyRevenue_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                v-model="result_monthlyRevenue"
                placeholder="......"
                outlined
                dense
                @input="result_monthlyRevenue  = formatToString(result_monthlyRevenue), $v.result_monthlyRevenue.$touch()"
                @keypress="isNumber($event)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            What is the valuation of your company ?
          </p>
          <p class="text-sm-left font-weight-thin">
            Share post-money valuation in USD.<br />
            If this will be an equity round. If this round will be a SAFE or
            Convertible Note. Share valuation cap.<br />
            <span class="blue-grey--text text--lighten-2"
              >Enter 10000000 for US $10,000,000</span
            >
          </p>
          <v-row justify="start">
            <v-col cols="6">
              <FormInlineMessage v-if="$v.result_postMoneyValuation.$error" class="errmsg">
                Fill in the field
              </FormInlineMessage>
              <FormInlineMessage v-if="is_result_postMoneyValuation_length_error.error" class="errmsg">
                {{ is_result_postMoneyValuation_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                v-model="result_postMoneyValuation"
                outlined
                dense
                @input="result_postMoneyValuation = formatToString(result_postMoneyValuation), $v.result_postMoneyValuation.$touch()"
                @keypress="isNumber($event)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveResult"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import FinanceService from "@/store/services/finance.service";
import { required } from "vuelidate/lib/validators";
import { myMixins } from "@/mixins/mixins.js";

export default {
  mixins: [myMixins],
  components: {
    FormInlineMessage,
  },

  props: {
    company_id: Number,
  },

  data() {
    return {
      currentBlock: true,
      result: {
        firstYear: {
          year: "",
        },
        secondYear: {
          year: "",
        },
        thirdYear: {
          year: "",
        },
      },
      result_firstYear_annualRevenues: null,
      result_secondYear_annualRevenues: null,
      result_thirdYear_annualRevenues: null,
      result_firstYear_grossProfit: null,
      result_secondYear_grossProfit: null,
      result_thirdYear_grossProfit: null,
      result_firstYear_ebit: null,
      result_secondYear_ebit: null,
      result_thirdYear_ebit: null,
      result_firstYear_ebitda: null,
      result_secondYear_ebitda: null,
      result_thirdYear_ebitda: null,

      result_monthlyRevenue: null,
      result_postMoneyValuation: null,
    };
  },

  validations: {
    result_firstYear_annualRevenues: { required },
    result_secondYear_annualRevenues: { required },
    result_thirdYear_annualRevenues: { required },
    result_firstYear_grossProfit: { required },
    result_secondYear_grossProfit: { required },
    result_thirdYear_grossProfit: { required },
    result_firstYear_ebit: { required },
    result_secondYear_ebit: { required },
    result_thirdYear_ebit: { required },
    result_firstYear_ebitda: { required },
    result_secondYear_ebitda: { required },
    result_thirdYear_ebitda: { required },

    result_monthlyRevenue: { required },
    result_postMoneyValuation: { required },


  },

  methods: {
    resultFormated() {
      this.result_firstYear_annualRevenues  = this.result_firstYear_annualRevenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.result_secondYear_annualRevenues = this.result_secondYear_annualRevenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.result_thirdYear_annualRevenues  = this.result_thirdYear_annualRevenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.result_firstYear_grossProfit  = this.result_firstYear_grossProfit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.result_secondYear_grossProfit = this.result_secondYear_grossProfit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.result_thirdYear_grossProfit  = this.result_thirdYear_grossProfit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.result_firstYear_ebit  = this.result_firstYear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.result_secondYear_ebit = this.result_secondYear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.result_thirdYear_ebit  = this.result_thirdYear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.result_firstYear_ebitda  = this.result_firstYear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.result_secondYear_ebitda = this.result_secondYear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.result_thirdYear_ebitda  = this.result_thirdYear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.result_monthlyRevenue     = this.result_monthlyRevenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.result_postMoneyValuation = this.result_postMoneyValuation.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    saveResult() {
      this.$v.$touch();
      if (
        this.$v.$error ||
        this.is_result_firstYear_annualRevenues_length_error ||
        this.is_result_firstYear_grossProfit_length_error ||
        this.is_result_firstYear_ebit_length_error ||
        this.is_result_firstYear_ebitda_length_error ||
        this.is_result_secondYear_annualRevenues_length_error ||
        this.is_result_secondYear_grossProfit_length_error ||
        this.is_result_secondYear_ebit_length_error ||
        this.is_result_secondYear_ebitda_length_error ||
        this.is_result_thirdYear_annualRevenues_length_error ||
        this.is_result_thirdYear_grossProfit_length_error ||
        this.is_result_thirdYear_ebit_length_error ||
        this.is_result_thirdYear_ebitda_length_error ||
        this.is_result_monthlyRevenue_length_error ||
        this.is_result_postMoneyValuation_length_error 
      ) {
        return;
      }
      this.result_firstYear_annualRevenues = this.formatToInt(this.result_firstYear_annualRevenues);
      this.result_secondYear_annualRevenues = this.formatToInt(this.result_secondYear_annualRevenues);
      this.result_thirdYear_annualRevenues = this.formatToInt(this.result_thirdYear_annualRevenues);
      
      this.result_firstYear_grossProfit = this.formatToInt(this.result_firstYear_grossProfit);
      this.result_secondYear_grossProfit = this.formatToInt(this.result_secondYear_grossProfit);
      this.result_thirdYear_grossProfit = this.formatToInt(this.result_thirdYear_grossProfit);

      this.result_firstYear_ebit = this.formatToInt(this.result_firstYear_ebit);
      this.result_secondYear_ebit = this.formatToInt(this.result_secondYear_ebit);
      this.result_thirdYear_ebit = this.formatToInt(this.result_thirdYear_ebit);

      this.result_firstYear_ebitda = this.formatToInt(this.result_firstYear_ebitda);
      this.result_secondYear_ebitda = this.formatToInt(this.result_secondYear_ebitda);
      this.result_thirdYear_ebitda = this.formatToInt(this.result_thirdYear_ebitda);

      this.result_monthlyRevenue = this.formatToInt(this.result_monthlyRevenue);
      this.result_postMoneyValuation = this.formatToInt(this.result_postMoneyValuation);

      this.result.firstYear.annualRevenues  = this.result_firstYear_annualRevenues;
      this.result.secondYear.annualRevenues = this.result_secondYear_annualRevenues;
      this.result.thirdYear.annualRevenues  = this.result_thirdYear_annualRevenues;

      this.result.firstYear.grossProfit  = this.result_firstYear_grossProfit;
      this.result.secondYear.grossProfit = this.result_secondYear_grossProfit;
      this.result.thirdYear.grossProfit  = this.result_thirdYear_grossProfit;

      this.result.firstYear.ebit  = this.result_firstYear_ebit;
      this.result.secondYear.ebit = this.result_secondYear_ebit;
      this.result.thirdYear.ebit  = this.result_thirdYear_ebit;

      this.result.firstYear.ebitda  = this.result_firstYear_ebitda;
      this.result.secondYear.ebitda = this.result_secondYear_ebitda;
      this.result.thirdYear.ebitda  = this.result_thirdYear_ebitda;

      this.result.monthlyRevenue      = this.result_monthlyRevenue;
      this.result.postMoneyValuation  = this.result_postMoneyValuation;

      FinanceService.saveResult({
        company_id: this.company_id,
        result: this.result,
      });

      this.resultFormated();
      this.currentBlock = false;
      this.$emit("block5");
    },

    getResult() {
      FinanceService.getResult(this.company_id)
        .then((res) => {
          if (res.data) {
            this.result.firstYear.year  = res.data.firstyear_year;
            this.result.secondYear.year = res.data.secondyear_year;
            this.result.thirdYear.year  = res.data.thirdyear_year;

            this.result_firstYear_annualRevenues  = res.data.firstyear_annual_revenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_secondYear_annualRevenues = res.data.secondyear_annual_revenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_thirdYear_annualRevenues  = res.data.thirdyear_annual_revenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.result_firstYear_grossProfit  = res.data.firstyear_gross_profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_secondYear_grossProfit = res.data.secondyear_gross_profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_thirdYear_grossProfit  = res.data.thirdyear_gross_profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.result_firstYear_ebit  = res.data.firstyear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_secondYear_ebit = res.data.secondyear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_thirdYear_ebit  = res.data.thirdyear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.result_firstYear_ebitda  = res.data.firstyear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_secondYear_ebitda = res.data.secondyear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_thirdYear_ebitda  = res.data.thirdyear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.result_monthlyRevenue     = res.data.monthly_revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_postMoneyValuation = res.data.post_money_valuation.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.result.monthlyRevenue = res.data.monthly_revenue;
            this.result.postMoneyValuation = res.data.post_money_valuation;
          }
        })
        .catch((e) => {});
    },
  },
  mounted() {
    this.getResult();
  },
};
</script>

<style>
.years input,
.years input::placeholder {
  color: white !important;
  text-align: center;
  font-weight: bold;
}
.position-absolute-error {
  position: absolute;
  top: -20px;
}
</style>
