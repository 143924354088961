<template>
  <v-row align="center" justify="center" class="step-1">
    <v-col cols="10">
      <v-responsive :aspect-ratio="16 / 9">
        <v-card-text>
          <v-sheet height="100%" class="mx-auto mt-0" color="#c5e7ec">
            <v-row justify="center" class="mx-10">
              <v-col cols="2">
                <v-img
                  style="border-radius: 20%"
                  min-height="95%"
                  :src="logoPreviewUrl"
                />
              </v-col>
              <v-col cols="9">
                <p class="text-sm-left" style="font-weight: normal">
                  <span v-if="me.investment_type == 'firm'"
                    >{{ me.firm_name }}<br /><br
                  /></span>
                  <span style="font-weight: bold">Main Contact<br /></span>
                  {{ me.first_name }}<br />
                  {{ me.last_name }}<br />
                  {{ me.email }}
                </p>
              </v-col>
            </v-row>
          </v-sheet>
          <v-col />

          <Term />
        </v-card-text>
      </v-responsive>
    </v-col>
  </v-row>
</template>

<script>
import Term from "@/components/Steps/Investor/Step4/Blocks/TermBlock";
import Profile from "@/store/services/profile-service";
import AccreditationService from "@/store/services/investor/accreditation.service.js";

export default {
  components: {
    Term
  },

  props: {
    me: Object,
  },

  data() {
    return {
      logoPreviewUrl: null,
    };
  },

  methods: {
    retrieveLogo() {
      if (this.me.investment_type == "individual") {
        Profile.getAvatar()
          .then((res) => {
            this.logoPreviewUrl = res.data;
          })
          .catch((error) => {
          });
      }
      if (this.me.investment_type == "firm") {
        AccreditationService.getLogo()
          .then((res) => {
            this.logoPreviewUrl = res.data;
          })
          .catch((error) => {
          });
      }
    },
  },
  mounted() {
    this.retrieveLogo();
  },
};
</script>

<style scoped>
p {
  font-weight: normal !important;
}

.bold {
  font-weight: bold;
  font-size: 14px;
}
</style>
