<template>
    <div>
        <div
            :is="component" @forceUpdate="getProfile()"
        />
    </div>
</template>

<script>
    import CompanyStatusC1 from '@/components/Dashboard/CompanyStatusC1';
    import CompanyStatusC2 from '@/components/Dashboard/CompanyStatusC2';
    import CompanyStatusC3 from '@/components/Dashboard/CompanyStatusC3';
    import Profile from "@/store/services/profile-service";
    export default { 
        components : {
            CompanyStatusC1,
            CompanyStatusC2,
            CompanyStatusC3
        },
        data() {
            return {
                user: null,
                component: null
            }
        },
        methods: {
            getProfile() {
                Profile.get().then((res) => { 
                    if (res.status == 200) {
                        if (res.data.role_id == 'c-1') {
                            this.component = 'CompanyStatusC1';
                        }else if(res.data.role_id == 'c-2') {
                            this.component = 'CompanyStatusC2';
                        }else if (res.data.role_id == 'c-3') {
                            this.component = 'CompanyStatusC3';
                        }
                    }
                });
            },
        },
        mounted() {
            this.getProfile();
        }
    }
</script>

<style scoped>
    @import '../../assets/styles/accountStatus.css';
</style>