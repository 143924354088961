<template>
  <div>
    <v-toolbar style="background: #f6f6f6" height="78">
      <img
        class="ml-4"
        alt="IdeasFundX Logo"
        src="../../assets/logo.png"
        width="200"
        height="30"
      />

      <v-spacer />

      <div class="d-flex mt-4">
        <p class="mr-4 router-link" @click="redirectTo('/admin/investor')" 
          :class="activePage == 'DashboardAdminInvestor' ? 'active' : ''"
        >INVESTORS</p>
        <p class="mr-4 router-link" @click="redirectTo('/admin/company')"
          :class="activePage == 'DashboardAdminCompany' ? 'active' : ''"
        >COMPANIES</p>
      </div>

      <v-divider vertical style="border-color: #0db0c4" />

      <div @click.prevent.stop="RightSidebarPanel()">
        <!-- <v-icon @click.prevent.stop="RightSidebarPanel()">
          mdi-cog-outline
        </v-icon> -->
        <img
          class="ml-4 settigns"
          alt="Settigns"
          src="../../assets/img/Settings.png"
        />
      </div>
    </v-toolbar>

    <div class="sidebar">
      <div
        class="sidebar-backdrop"
        @click="RightSidebarPanel()"
        v-if="isPanelRightOpen"
      />

      <transition name="slideRight">
        <div
          v-if="isPanelRightOpen"
          class="sidebar-panel-right"
          style="width: 15vw !important; left: 85vw !important"
        >
          <!-- <p class="setting" @click.prevent.stop="changeComponent()">
            Password
          </p> -->
          <p class="setting link" style="text-align: left !important" @click.prevent.stop="submitLogout()">Logout</p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: { avatarPreviewUrl: String },

  data() {
    return {
      CMSlink:`window.location=\'${process.env.VUE_APP_CMS_URL}\';`,
      isPanelRightOpen: false,
      activePage: '',
      profile: localStorage.profile,
    };
  },

  methods: {
    RightSidebarPanel() {
      this.isPanelRightOpen = !this.isPanelRightOpen;
    },

    changeComponent() {
      this.isPanelRightOpen = false;
      this.$emit("changeComponent", null, "changepwd");
    },

    submitLogout() {
      localStorage.profile = '';
      this.$router.push('/admin');  
    },
    redirectTo(route) {
      this.$router.push(route);
    },
  },

  created() {
    this.activePage = this.$route.name;
    if (!this.profile) {
      this.$router.push('/admin');
    }
  }
};
</script>

<style>
.link:hover {
    cursor: pointer;
}
.settigns{
  cursor: pointer;
  transition: ease all 1s;
}
.settigns:hover{
  transform: scale(1.1, 1.1);
}
.router-link {
  cursor: pointer;
  font-weight: 800;
  font-size: 15px;
  transition: ease all 1s;
}
.router-link:hover {
  color: #00a9c5;
}
.active {
  color: #00a9c5 !important;
}
</style>
