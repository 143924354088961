<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="11">
          <p class="cyan--text text--darken-1 text-sm-left" id="title">
            How equity is currently distributed among shareholders ?
          </p>
        </v-col>
        <v-col cols="1" />
        <v-col cols="11">
          <p class="text-sm-left">Shareholders</p>
          <v-row>
            <v-col cols="4" />
            <v-col cols="3">
              <FormInlineMessage v-if="!this.shareholder.shareholders" class="errmsg">
                Add a shareholder
              </FormInlineMessage>
            </v-col>
          </v-row>
        </v-col>

        <v-col>
          <div v-for="(shareholder, index) in this.shareholder.shareholders" :key="index">
            <v-row>
              <v-col cols="1" />
              <v-col cols="6">
                <p class="font-weight-thin text-sm-left" v-if="index == 0">
                  Name
                </p>
                <FormInlineMessage
                  v-if="$v.shareholder.shareholders.$each[index].name.$error"
                  class="errmsg"
                >
                  Fill the field
                </FormInlineMessage>
                <v-text-field
                  v-model="shareholder.name"
                  outlined
                  dense
                  placeholder="Name"
                />
              </v-col>
              <v-col cols="2">
                <p class="font-weight-thin text-sm-left" v-if="index == 0">
                  % Share
                </p>
                <FormInlineMessage
                  v-if="$v.shareholder.shareholders.$each[index].share.$error"
                  class="errmsg"
                >
                  Fill the field
                </FormInlineMessage>
                <FormInlineMessage v-if="is_share_horlder_up_to_100_percent_error(index)">
                  {{ is_share_horlder_up_to_100_percent_error_message(index).message }}
                </FormInlineMessage>
                <v-text-field
                  v-model="shareholder.share"
                  outlined
                  dense
                  placeholder="% Share"
                  @input="setSholdierShare(shareholder.share, index), restrictTo100Percent(shareholder.share)"
                  @keypress="isNumber($event)"
                  />
              </v-col>
              <v-col cols="2">
                <p class="font-weight-thin text-sm-left" v-if="index == 0">
                  Type of shareholder
                </p>
                <FormInlineMessage
                  v-if="$v.shareholder.shareholders.$each[index].type.$error"
                  class="errmsg"
                >
                  Fill the field
                </FormInlineMessage>
                <v-select
                  height="32"
                  v-model="shareholder.type"
                  :items="typeShareholder"
                  placeholder="Corporate"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="4" />
            <v-btn
              id="btn-add"
              text
              color="#00aac3"
              @click.prevent.stop="addShareholder()"
            >
              + Add Shareholder
            </v-btn>
            <v-col cols="2" />
            <v-btn
              v-if="shareholder.shareholders.length > 1"
              id="btn-add"
              text
              color="#00aac3"
              @click.prevent.stop="removeShareholder()"
            >
              - Remove Shareholder
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-col />
    </v-sheet>

    <!-- Capitalization table -->

    <!-- share -->

    <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
      <v-col />
      <v-row>
        <v-col cols="1" />
        <v-col cols="11">
          <p class="text-sm-left">Capitalization table</p>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="1" />
            <v-col cols="4">
              <p class="cyan--text text--darken-1 text-sm-left">Shares</p>
              <p class="boxed text-sm-left mb-7">Common Shares</p>
              <p class="boxed text-sm-left">Preferred Shares</p>
              <v-col cols="12" />
              <p class="font-weight-thin text-sm-left">
                Total Common and Preferred Shares
              </p>
            </v-col>
            <v-col cols="3">
              <p class="font-weight-thin text-sm-left">Number of shares</p>
              <FormInlineMessage v-if="is_shareholder_common_number_length_error.error">
                {{ is_shareholder_common_number_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                v-model="shareholder_common_number"
                outlined
                dense
                placeholder="..."
                @input="shareholder_common_number  = formatToString(shareholder_common_number)"
                @keypress="isNumber($event)"
              />

              <FormInlineMessage v-if="is_shareholder_preferred_number_length_error.error">
                {{ is_shareholder_preferred_number_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                v-model="shareholder_preferred_number"
                outlined
                dense
                placeholder="..."
                @input="shareholder_preferred_number  = formatToString(shareholder_preferred_number)"
                @keypress="isNumber($event)"
              />
              <p class="font-weight-thin boxed text-sm-center total">
                {{ formatToString((shareholder_common_number ? formatToInt(shareholder_common_number) : 0) +
                    (shareholder_preferred_number ? formatToInt(shareholder_preferred_number) : 0)) 
                }}
              </p>
            </v-col>
            <v-col cols="3">
              <p class="font-weight-thin text-sm-left">
                Value of shares in USD
              </p>
              <FormInlineMessage v-if="is_shareholder_common_value_length_error.error">
                {{ is_shareholder_common_value_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                v-model="shareholder_common_value"
                outlined
                dense
                placeholder="..."
                @input="shareholder_common_value  = formatToString(shareholder_common_value)"
                @keypress="isNumber($event)"
              />

              <FormInlineMessage v-if="is_shareholder_preferred_value_length_error.error">
                {{ is_shareholder_preferred_value_length_error.message }}
              </FormInlineMessage>
              <v-text-field
                v-model="shareholder_preferred_value"
                outlined
                dense
                placeholder="..."
                @input="shareholder_preferred_value  = formatToString(shareholder_preferred_value)"
                @keypress="isNumber($event)"
              />
              <p class="font-weight-thin boxed text-sm-center total">
                {{ formatToString((shareholder_common_value ? formatToInt(shareholder_common_value) : 0) +
                  (shareholder_preferred_value ? formatToInt(shareholder_preferred_value) : 0)) 
                }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="mb-5">
        <v-col cols="11">
          <v-divider />
        </v-col>
      </v-row>

      <!-- options -->

      <v-col>
        <v-row>
          <v-col cols="1" />
          <v-col cols="4">
            <p class="cyan--text text--darken-1 text-sm-left">Options</p>
            <p class="boxed text-sm-left mb-7">Options - Granted</p>
            <p class="boxed text-sm-left">Options - Authorized, Not granted</p>
            <v-col cols="12" />
            <p class="font-weight-thin text-sm-left">Total Option</p>
          </v-col>
          <v-col cols="3">
            <p class="font-weight-thin text-sm-left">Number of options</p>
            <FormInlineMessage v-if="is_shareholder_granted_number_length_error.error">
              {{ is_shareholder_granted_number_length_error.message }}
            </FormInlineMessage>
            <v-text-field
              v-model="shareholder_granted_number"
              outlined
              dense
              placeholder="..."
              @input="shareholder_granted_number  = formatToString(shareholder_granted_number)"
              @keypress="isNumber($event)"
            />

            <FormInlineMessage v-if="is_shareholder_authorized_number_length_error.error">
              {{ is_shareholder_authorized_number_length_error.message }}
            </FormInlineMessage>
            <v-text-field
              v-model="shareholder_authorized_number"
              outlined
              dense
              placeholder="..."
              @input="shareholder_authorized_number  = formatToString(shareholder_authorized_number)"
              @keypress="isNumber($event)"
            />
            <p class="font-weight-thin boxed text-sm-center total">
              {{ formatToString((shareholder_granted_number ? formatToInt(shareholder_granted_number) : 0) +
                (shareholder_authorized_number ? formatToInt(shareholder_authorized_number) : 0)) 
              }}
            </p>
          </v-col>
          <v-col cols="3">
            <p class="font-weight-thin text-sm-left">Value of options in USD</p>

            <FormInlineMessage v-if="is_shareholder_granted_value_length_error.error">
              {{ is_shareholder_granted_value_length_error.message }}
            </FormInlineMessage>
            <v-text-field
              v-model="shareholder_granted_value"
              outlined
              dense
              placeholder="..."
              @input="shareholder_granted_value  = formatToString(shareholder_granted_value)"
              @keypress="isNumber($event)"
            />

            <FormInlineMessage v-if="is_shareholder_authorized_value_length_error.error">
              {{ is_shareholder_authorized_value_length_error.message }}
            </FormInlineMessage>
            <v-text-field
              v-model="shareholder_authorized_value"
              outlined
              dense
              placeholder="..."
              @input="shareholder_authorized_value  = formatToString(shareholder_authorized_value)"
              @keypress="isNumber($event)"
            />
            <p class="font-weight-thin boxed text-sm-center total">
              {{ formatToString((shareholder_granted_value ? formatToInt(shareholder_granted_value) : 0) +
                (shareholder_authorized_value ? formatToInt(shareholder_authorized_value) : 0)) 
              }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col cols="10" />
        <v-col cols="12" md="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveShareholder"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import FinanceService from "@/store/services/finance.service";
import Var from "@/store/services/var";
import { required } from "vuelidate/lib/validators";
import { myMixins } from "@/mixins/mixins.js";

export default {
  mixins: [myMixins],
  components: {
    FormInlineMessage,
  },

  props: {
    company_id: Number,
  },

  data() {
    return {
      is_error: false,
      currentBlock: true,
      typeShareholder: [],
      shareholder: {
        common: {
          number: "",
          value: "",
        },
        preferred: {
          number: "",
          value: "",
        },
        granted: {
          number: "",
          value: "",
        },
        authorized: {
          number: "",
          value: "",
        },
        shareholders: [{}],
      },
      shareholder_common_number: null,
      shareholder_common_value: null,
      shareholder_preferred_number: null,
      shareholder_preferred_value: null,
      shareholder_granted_number: null,
      shareholder_granted_value: null,
      shareholder_authorized_number: null,
      shareholder_authorized_value: null,

      shareholder_share_has_error: false,
    };
  },

  validations: {
    shareholder: {
      required,
      shareholders: {
        required,
        $each: {
          name: { required },
          share: { required },
          type: { required },
        },
      },
    },
    shareholder_common_number: { required },
    shareholder_common_value: { required },
    shareholder_preferred_number: { required },
    shareholder_preferred_value: { required },
    shareholder_granted_number: { required },
    shareholder_granted_value: { required },
    shareholder_authorized_number: { required },
    shareholder_authorized_value: { required },
  },

  methods: {
    shareholderFormated() {
      this.shareholder_common_number = this.shareholder_common_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.shareholder_common_value  = this.shareholder_common_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.shareholder_preferred_number = this.shareholder_preferred_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.shareholder_preferred_value  = this.shareholder_preferred_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.shareholder_granted_number   = this.shareholder_granted_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.shareholder_granted_value    = this.shareholder_granted_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.shareholder_authorized_number = this.shareholder_authorized_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.shareholder_authorized_value  = this.shareholder_authorized_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    saveShareholder() {
      this.$v.$touch();
      if (
        this.$v.$error ||
        this.is_shareholder_common_number_length_error ||
        this.is_shareholder_preferred_number_length_error ||
        this.is_shareholder_common_value_length_error ||
        this.is_shareholder_preferred_value_length_error ||
        this.is_shareholder_granted_number_length_error ||
        this.is_shareholder_authorized_number_length_error ||
        this.is_shareholder_granted_value_length_error ||
        this.is_shareholder_authorized_value_length_error
      ) {
        return;
      }
      this.shareholder_common_number    = this.formatToInt(this.shareholder_common_number);
      this.shareholder_common_value     = this.formatToInt(this.shareholder_common_value);
      this.shareholder_preferred_number = this.formatToInt(this.shareholder_preferred_number);
      this.shareholder_preferred_value  = this.formatToInt(this.shareholder_preferred_value);
      this.shareholder_granted_number   = this.formatToInt(this.shareholder_granted_number);
      this.shareholder_granted_value    = this.formatToInt(this.shareholder_granted_value);
      this.shareholder_authorized_number = this.formatToInt(this.shareholder_authorized_number);
      this.shareholder_authorized_value  = this.formatToInt(this.shareholder_authorized_value);

      this.shareholder.common.number    = this.shareholder_common_number;
      this.shareholder.common.value     = this.shareholder_common_value;
      this.shareholder.preferred.number = this.shareholder_preferred_number;
      this.shareholder.preferred.value  = this.shareholder_preferred_value;
      this.shareholder.granted.number   = this.shareholder_granted_number;
      this.shareholder.granted.value    = this.shareholder_granted_value;
      this.shareholder.authorized.number = this.shareholder_authorized_number;
      this.shareholder.authorized.value  = this.shareholder_authorized_value;

      if (this.shareholder.shareholders.some((shareholder) => {
          if (!shareholder.share) return false;
          if (shareholder.share > 100) {
              this.shareholder_share_has_error = true;
              this.shareholderFormated();
              return true;
          } 
          this.shareholder_share_has_error = false;
      }))

      this.shareholderFormated();
      if (this.shareholder_share_has_error) return;

      FinanceService.saveShareholder({
        company_id: this.company_id,
        shareholder: this.shareholder,
      });

      this.shareholderFormated();
      this.currentBlock = false;
      this.$emit("block3");
    },

    getShareholder() {
      FinanceService.getShareholder(this.company_id)
        .then((res) => {
          if (res.data) {
            if (res.data.capitalization) {
              let data = res.data.capitalization;

              this.shareholder_common_number = data.common_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              this.shareholder_common_value  = data.common_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              this.shareholder_preferred_number  = data.preferred_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              this.shareholder_preferred_value   = data.preferred_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              this.shareholder_granted_number    = data.granted_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              this.shareholder_granted_value     = data.granted_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              this.shareholder_authorized_number = data.authorized_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              this.shareholder_authorized_value  = data.authorized_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (res.data.shareholders) {
              this.shareholder.shareholders.pop();
              res.data.shareholders.forEach((element) => {
                this.shareholder.shareholders.push({
                  name: element.name,
                  share: element.share,
                  type: element.type,
                });
              });
            }
          }
        })
        .catch((e) => {});
    },
    addShareholder() {
      this.shareholder.shareholders.push({});
    },

    removeShareholder() {
      this.shareholder.shareholders.pop();
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    getVar() {
      Var.getAllShareholders().then((res) => {
        this.typeShareholder = res.data;
      });
    },
  },

  created() {
    this.getVar();
  },
  mounted() {
    this.getShareholder();
  },
};
</script>

<style>
.boxed {
  border: 1px solid;
  height: 15%;
  padding: 5px;
}

.total {
  background-color: dimgray;
  color: white;
}
</style>
