<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-8">
      <v-row align="center">
        <v-col cols="12" />
        <v-col cols="5" />
        <v-col cols="2">
          <v-card-actions class="justify-center">
            <v-hover v-slot:default="{ hover }">
              <v-img
                v-if="!photoBlock"
                max-height="300"
                max-width="300"
                @click.stop="dialogUp('Photo')"
                :src="imagePreviewUrl"
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    style="background-color: rgb(81, 77, 122); height: 100%"
                    class="d-flex transition-fast-in-fast-out v-img--reveal display-1 white--text align-center justify-center"
                  >
                    CHANGE YOUR LOGO
                  </div>
                </v-expand-transition>
              </v-img>
            </v-hover>
            <v-btn
              v-if="photoBlock"
              sm="2"
              id="btn-photo"
              class="white--text"
              color="#514d7a"
              height="90"
              min-width="20"
              rounded
              block
              :disabled="false"
              @click.stop="dialogUp('Photo')"
            >
              ADD LOGO*
            </v-btn>
          </v-card-actions>
          <p class="blue-grey--text font-weight-thin" v-if="photoBlock">
            Add your company logo<br />Min 300x300 & Max 3 Mb
          </p>
        </v-col>
      </v-row>
    </v-sheet>

    <!-- dialog for Photo upload-->
    <v-dialog v-model="dialogPhoto" max-width="400">
      <PhotoForm
        @uploadPhoto="uploadLogo"
        @closePhoto="dialogDown('Photo')"
      ></PhotoForm>
    </v-dialog>

    <v-sheet height="100%" class="mx-auto mt-8">
      <v-row align="center" justify="center" no-gutters class="pt-8">
        <v-row>
          <v-col cols="1" />
          <v-col cols="2">
            <img
              src="../../../../assets/img/Icon-Website.png"
              width="30"
              height="30"
            />
          </v-col>
          <v-col cols="8">
            <FormInlineMessage v-if="$v.link.website.$error">
              please write a valid url
            </FormInlineMessage>
            <v-text-field
              v-model="link.website"
              outlined
              dense
              placeholder="Enter url of your company website"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />

        <v-row>
          <v-col cols="1" />
          <v-col cols="2">
            <img
              src="../../../../assets/img/Icon-Blog4.png"
              width="30"
              height="30"
            />
          </v-col>
          <v-col cols="8">
            <FormInlineMessage v-if="$v.link.blog.$error">
              please write a valid url
            </FormInlineMessage>
            <v-text-field
              v-model="link.blog"
              outlined
              dense
              placeholder="Enter url of your company blog"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />

        <v-row>
          <v-col cols="1" />
          <v-col cols="2">
            <img
              src="../../../../assets/img/Icon-FB1.png"
              width="30"
              height="30"
            />
          </v-col>
          <v-col cols="8">
            <FormInlineMessage v-if="$v.link.facebook.$error">
              please write a valid url
            </FormInlineMessage>
            <v-text-field
              v-model="link.facebook"
              outlined
              dense
              placeholder="Enter url of your company facebook page"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />

        <v-row>
          <v-col cols="1" />
          <v-col cols="2">
            <img
              src="../../../../assets/img/Icon-In.png"
              width="30"
              height="30"
            />
          </v-col>
          <v-col cols="8">
            <FormInlineMessage v-if="$v.link.linkedin.$error">
              please write a valid url
            </FormInlineMessage>
            <v-text-field
              v-model="link.linkedin"
              outlined
              dense
              placeholder="Enter url of your company linkedin"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />

        <v-row>
          <v-col cols="1" />
          <v-col cols="2">
            <img
              src="../../../../assets/img/Icon-Twitter.png"
              width="30"
              height="30"
            />
          </v-col>
          <v-col cols="8">
            <FormInlineMessage v-if="$v.link.twitter.$error">
              please write a valid url
            </FormInlineMessage>
            <v-text-field
              v-model="link.twitter"
              outlined
              dense
              placeholder="Enter url of your company twitter account"
            />
          </v-col>
        </v-row>

        <v-col cols="12" />
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
      <v-row align="center" justify="center">
        <v-col></v-col>
        <v-col cols="12">
          <v-icon
            large
            color="#00aac3"
            right
            center
            @click.prevent.stop="saveLink"
          >
            mdi-arrow-down-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  minLength,
  maxLength,
  numeric,
  minValue,
  email,
  sameAs,
  url,
} from "vuelidate/lib/validators";

import FormInlineMessage from "@/components/FormInlineMessage";
import PhotoForm from "@/forms/PhotoForm";
import CompanyService from "@/store/services/company/company.service";

export default {
  components: {
    FormInlineMessage,
    PhotoForm,
    //CompanyService,
  },

  props: {
    company_id: Number,
  },
  data() {
    return {
      imagePrevieUrl: null,
      currentBlock: true,
      dialogPhoto: false,
      photoBlock: true,
      formData: new FormData(),
      link: {
        website: "",
        blog: "",
        facebook: "",
        linkedin: "",
        twitter: "",
      },
    };
  },

  validations: {
    link: {
      website: { url },
      blog: { url },
      facebook: { url },
      linkedin: { url },
      twitter: { url },
    },
  },

  methods: {
    dialogUp(element) {
      //console.log('dialogUp :'+element);
      this.$data["dialog" + element] = true;
    },

    dialogDown(element) {
      //console.log("dialogDown :" + element);
      this.$data["dialog" + element] = false;
    },

    saveLink() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      CompanyService.saveLink(
        JSON.stringify({ company_id: this.company_id, link: this.link })
      );

      this.currentBlock = false;
      this.$emit("block3");
    },

    uploadLogo(chosenImage) {
      this.imagePreviewUrl = URL.createObjectURL(chosenImage);
      this.formData.append("logo", chosenImage);
      this.formData.append("company_id", this.company_id);

      CompanyService.saveLogo(this.formData).then().catch();
      this.photoBlock = false;
      this.dialogDown("Photo");
      //this.photoBlock = false;
    },

    retrieveLogo() {
      if (this.company_id != null) {
        //this.formData.append("company_id", this.$global_company_id);

        CompanyService.getLogo(this.company_id)
          .then((res) => {
            this.imagePreviewUrl = res.data;
            this.photoBlock = false;
          })
          .catch((error) => {
            this.photoBlock = true;
          });
      }
    },
  },

  created() {},

  mounted() {
    this.retrieveLogo();

    CompanyService.getLink(this.company_id).then((res) => {
      if (res.data) {
        this.link = res.data;
      }
    });
  },
};
</script>
