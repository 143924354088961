<template>
    <div class="body" justify="center">
      <Header class="d-none d-md-flex"/>
      <HeaderPortable class="d-flex d-md-none"/>
      <v-row>
        <v-col cols="0" sm="0" md="0" lg="2" class="d-none d-md-flex">
          <Menu />
        </v-col>
        
        <v-col cols="10" sm="12" md="12" lg="10" justify="center" class="text-center container">
          <div class="row justify-content-center my-5">
            <div class="row justify-content-center align-items-center mt-5">
              <p class="mr-2 mt-n3 col-12 col-md-4">FIND INVESTORS FOR COMPANY</p>
              <v-combobox
                v-model="selectedCompany"
                :items="companies"
                outlined
                dense
                @change="getCompanyById()"
                deletable-chips
                placeholder="Select a company"
                class="col-10 col-md-8"
              />
            </div>
          </div>

          <div class="d-flex justify-content-around align-items-center pt-4" style="background-color: #cbecf2" v-if="company">
            <p class="text-normal mr-10"><strong>Date of Application</strong> <span>{{ formattedDate(company.payment.created_at) }}</span></p>
            <p>
              <a v-if="company.company_application.last_application_pdf" outlined :href="pdf_path + company.company_application.last_application_pdf" target="_blank" download class="link">Download Updated Application</a>
              <a v-else class="disabled">Download Updated Application</a>
              <img src="../../../assets/img/pdf.png" alt="pdf" class="ml-2 img-fluid">
            </p>
            <p class="link" @click="submitLogin(company.user.email)">LOGIN TO COMPANY DASHBOARD</p>
          </div>

          <div class="container mt-5">
            <div class="row" v-if="company">
              <div class="col-12 col-md-6 pb-7" style="background-color: #ddd; height: auto;">
                <strong class="mt-5">{{ company.company_name }}</strong>
                <div style="background-color: #fff" class="mt-8">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-10 col-md-3">
                      <img v-if="company.logo" :src="company.logo" alt="avatar" class="img-fluid round-circle" />
                      <img v-else src="../../../assets/img/no-image.png" alt="avatar" class="img-fluid round-circle" />
                    </div>
                    <div class="col-10 col-md-8 text-start">
                      <div class="d-flex justify-content-between">
                        <div>
                          <strong>HQ Address:</strong>
                        </div>
                        <div class="text-end">
                          <span>{{ company.street }}</span><br>
                          <span>{{ company.city }}</span><br>
                          <span>{{ company.country }}</span>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between mt-2">
                        <div>
                          <strong>Phone:</strong> <br>
                          <strong>Creation:</strong>
                        </div>
                        <div class="text-end">
                          <span>{{ company.phone }}</span><br>
                          <span>{{ formattedDate(company.created_at) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 pb-5" style="background-color: #ddd">
                <span>Main contact</span>
                <div style="background-color: #fff" class="mt-5 p-2">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-5">
                      <img v-if="avatar" :src="avatar" alt="avatar" class="img-fluid round-circle">
                      <img v-else src="../../../assets/img/no-image.png" alt="avatar" class="img-fluid round-circle" />
                    </div>
                    <div class="col-7 text-start">
                      <span>{{ company ? company.user.first_name : '' }}</span><br>
                      <span>{{ company ? company.user.last_name : '' }}</span><br>
                      <span>{{ company ? company.user.email : '' }}</span><br>
                      <span>Skills: <strong>{{ skills.join(', ') }}</strong> </span><br>
                      <span>School(s) <strong>{{ educations.join(', ') }}</strong> </span><br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="background-color: #ddd; font-weight: 700" class="container mt-4 mb-4">
            Company Profile
          </div>

          <div class="container">
            <v-row class="mb-n8">
              <v-col cols="12" sm="3">
                <p class="text-sm-left">Industry sector</p>
              </v-col>
              <v-col cols="12" sm="9">
                <v-autocomplete
                  v-model="industry"
                  :items="allIndustries"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  disabled
                  dense/>
              </v-col>
            </v-row>
            <v-row class="mb-n8">
              <v-col cols="12" sm="3">
                <p class="text-sm-left">Stage of the company</p>
              </v-col>
              <v-col cols="12" sm="9">
                <v-autocomplete
                  v-model="stage"
                  :items="allStage"
                  small-chips
                  deletable-chips
                  outlined
                  disabled
                  dense/>
              </v-col>
            </v-row>

            <p class="text-left site-text mt-5">About the market</p>
            <v-row class="mb-n8">
              <v-col cols="12" sm="3">
                <p class="text-sm-left">Market Type</p>
              </v-col>
              <v-col cols="12" sm="9">
                <v-autocomplete
                  v-model="marketModel"
                  :items="allModel"
                  outlined
                  disabled
                  dense/>
              </v-col>
            </v-row>
            <v-row class="mb-n8">
              <v-col cols="12" sm="3">
                <p class="text-sm-left">Market Location</p>
              </v-col>
              <v-col cols="12" sm="9">
                <v-autocomplete
                  v-model="location"
                  :items="allLocation"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  disabled
                  dense/>
              </v-col>
            </v-row>
            <v-row class="mb-n8">
              <v-col cols="12" sm="3">
                <p class="text-sm-left">Stage of Market Maturity</p>
              </v-col>
              <v-col cols="12" sm="9">
                <v-autocomplete
                v-model="maturity"
                :items="allMaturity"
                small-chips
                deletable-chips
                outlined
                disabled
                dense/>
              </v-col>
            </v-row>

            <p class="text-left site-text mt-5">About the product</p>
            <v-row class="mb-n8">
              <v-col cols="12" sm="3">
                <p class="text-sm-left">Type of offer</p>
              </v-col>
              <v-col cols="12" sm="9">
                <v-autocomplete
                  v-model="offer"
                  :items="allPrimaryOffer"
                  outlined
                  disabled
                  dense/>
              </v-col>
            </v-row>
            <v-row class="mb-1">
              <v-col>
                <v-checkbox
                  v-model="technologyFocus"
                  label="Technology focus"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="withRB"
                  label="With R&D"
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>

          <div style="background-color: #ddd; font-weight: 700;" class="container mt-4 mb-4">
            Fundraising campaign
          </div>
          <div class="container">
            <v-row class="mb-n8">
              <v-col cols="12" sm="3" class="mt-2">
                <p class="text-sm-left">Initial Funding Needs</p>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field
                v-model="initialFunding"
                outlined
                disabled
                dense/>
              </v-col>
            </v-row>
            <v-row class="mb-n8">
              <v-col cols="12" sm="3" class="mt-2">
                <p class="text-sm-left">Updated Funding Needs</p>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field
                v-model="updatedFunding"
                outlined
                disabled
                dense/>
              </v-col>
            </v-row>
          </div>
          
          <div class="d-flex justify-content-around align-items-center pt-4 mt-10" style="background-color: #cbecf2">
            <p>List of investors matching with the company profile</p>
          </div>
          <div class="container mt-5">
            <v-row class="mb-n8">
              <v-col cols="12" sm="2">
                <p class="text-sm-left mt-2">Quick criteria selection</p>
              </v-col>
              <v-col cols="12" sm="2" class="mt-n5">
                <v-checkbox
                  v-model="selectAll"
                  @change="selectAndUnselectAll()"
                  label="All"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" class="mt-n5">
                <v-checkbox
                  v-model="selectBusiness"
                  @change="selectAndUnselectBusiness()"
                  label="Business"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" class="mt-n5">
                <v-checkbox
                  v-model="selectRecurring"
                  @change="selectAndUnselectRecurring()"
                  label="Recurring Revenue"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" class="mt-n5">
                <v-checkbox
                  v-model="selectLegal"
                  @change="selectAndUnselectLegal()"
                  label="Legal"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" class="mt-n5">
                <v-checkbox
                  v-model="selectHuman"
                  @change="selectAndUnselectHuman()"
                  label="Human chemistry"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <p class="site-text mt-col-6 mt-4 ml-4">Investor strategy matching the business of the company</p>
              <v-col cols="12" sm="12" class="mt-n5">
                <v-checkbox
                  v-model="business.industry"
                  label="A1. Investing in the industry the company is"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="business.model"
                  label="A2. Investing in the business model type of the company"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="business.location"
                  label="A3. Investing if market location matches investor preference"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="business.market_size"
                  label="A4. Investing if the market size of the product is at least the minimum size required by investor"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="business.market_growth"
                  label="A5. Investing if the expected market growth is at least the minimum growth required by the investor"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="business.product"
                  label="A6. Investing in the primary product/service type company offers"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="business.technology_focus"
                  label="A7. Investors with matching tech focus for company profile"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="business.technology"
                  label="A8. Investing in companies developing technologies investors are looking for"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="business.rd"
                  label="A9. Investors with aligned R&D focus for company profile"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="business.patent"
                  label="A10. Investors with matching exclusive rights/patents for company profile"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <p class="site-text mt-4 ml-4">Minimum recurring revenue requirement</p>
              <v-col cols="12" sm="12" class="mt-n5">
                <v-checkbox
                  v-model="recurring.revenue"
                  label="B1. Recurring revenue required by investor matching the company profile"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <p class="site-text mt-10 ml-4">Legal requirements</p>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="legal.company_HQ"
                  label="C1. Investing in the location the company HQ is"
                  class="mt-n2"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="legal.fund_need"
                  label="C2. Investing in the range of the funding need"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="legal.investment_stage"
                  label="C3. Investing in the investment stage the company is"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="legal.vehicle"
                  label="C4. Investing in the vehicle(s) offered by the company"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="legal.founder_profile_team"
                  label="C5. Investing in specific profile founders the company have"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="legal.investor_type"
                  label="C6. Investing only if the company is incorporate in US"
                  class="mt-n1"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="">
              <p class="site-text mt-4 ml-4">Human chemistry</p>
              <v-col cols="12" sm="12" class="mt-n5">
                <v-checkbox
                  v-model="human.graduate_school"
                  label="D1. Graduate from the same School"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="human.location_country"
                  label="D2. Both Located in the same country"
                  class="mt-n2"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="human.skills"
                  label="D3. Expected skill(s) from investor"
                  class="mt-n2"
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
          <br> <br>
          <div>
            <v-row class="d-flex justify-content-center mt-n4 mb-2">
              <button class="btn btn-primary mr-2" @click="getInvestors()">
                <v-icon aria-hidden="false" color="#fff" size="15">
                  mdi-filter
                </v-icon>
                Filter
              </button>
              <export-excel
                  class   = "btn btn-primary"
                  :data   = "investors"
                  worksheet = "investors"
                  :fields = "investor_fields"
                  title ="LIST OF INVESTORS"
                  name    = "list_investors.xls">
                Export
              </export-excel>
            </v-row>
          </div>
          <br>
          <LoaderPop v-if="loaderAlert" type="update" />
          <v-app id="inspire">
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="investors"
              :single-select="singleSelect"
              item-key="id"
              show-select
              id="user"
            >

              <template v-slot:item.actions="{ item }">
                <span class="list" @mouseover="showActionsList(item)">>>></span>
                <div class="actionsList" :class="actionsList == item.id ? 'active' : ''">
                  <div class="actions" @mouseleave="hideActionsList()">
                    <h5 class="actionsTitle">Action</h5>
                    <div class="download_link">
                      <a v-if="item.initial_application_pdf != null" outlined :href="pdf_path + item.initial_application_pdf" target="_blank" download>Download Initial Application</a>
                      <a v-else class="disabled">Download Initial Application</a>
                      <a v-if="item.last_application_pdf != null && item.last_application_pdf" outlined :href="pdf_path + item.last_application_pdf" target="_blank" download>Download Updated Application</a>
                      <a v-else class="disabled">Download Updated Application</a>
                      <a @click="submitLogin(item.email)">Login to User Dashboard</a>
                      <a href="/status" @click.prevent.stop="confirmApplication(item.id)">Confirm Application</a>
                    </div>
                  </div>
                </div>
              </template>
            </v-data-table>
          </v-app>
        </v-col>
      </v-row>

      <br> <br> <br> <br>
    </div>
  </template>
  
<script>
  import Header from '@/components/admin/AdminHeader.vue';
  import HeaderPortable from '@/components/admin/AdminHeadePortable.vue';
  import Menu from '@/components/admin/AdminLeftMenu.vue';
  import company from '@/store/services/company/company.service.js'
  import Admin from "@/store/services/admin/company";
  import Var from "@/store/services/var";
  import { myMixins } from "@/mixins/mixins.js";
  
  export default {
    mixins: [myMixins],
    components: {
      Header,
      HeaderPortable,
      Menu,
    },
    data() {
      return {
        pdf_path: process.env.VUE_APP_API_BASE_URL_PDF,
        companies: [],
        company: null,
        skills: [],
        educations: [],
        allIndustries: [],
        allMaturity: [],
        allStage: [],
        allLocation: [],
        allPrimaryOffer: [],
        allModel: [],
        stage: null,
        location: [],
        industry: [],
        maturity: null,
        offer: null,
        avatar: null,
        marketModel: null,
        selectedCompany: null,
        technologyFocus: false,
        withRB: false,
        initialFunding: '----',
        updatedFunding: '----',

        selectAll: false,
        selectBusiness: false,
        selectRecurring: false,
        selectLegal: false,
        selectHuman: false,

        business: {
          industry: false,
          model: false,
          location: false,
          market_size: false,
          market_growth: false,
          product: false,
          technology_focus: false,
          technology: false,
          rd: false,
          patent: false,
        },
        recurring: {
          revenue: false,
        },
        legal: {
          company_HQ: false,
          fund_need: false,
          investment_stage: false,
          vehicle: false,
          founder_profile_team: false,
          investor_type: false
        },
        human: {
          graduate_school: false,
          location_country: false,
          skills: false,
        },

        actionsList: false,
        investors: [],
        loaderAlert: false,
        singleSelect: false,
        selected: [],
        headers: [
          { text: 'ID', value: 'id' },
          { text: 'User-Name', value: 'user_name', sortable: false },
          { text: 'VC Firm Name', value: 'firm_name', sortable: false },
          { text: 'City', value: 'city', sortable: false },
          { text: 'Country', value: 'country' },
          { text: 'Application Date', value: 'terms_accepted_at' },
          { text: 'Status', value: 'role_id' },
          { text: 'Yearly Budget', value: 'yearly_budget', align: 'right' },
          { text: 'Investment Range', value: 'check_size', align: 'right' }, 
          { text: 'Sent', value: 'sent', align: 'center', sortable: false },
          { text: 'Review', value: 'review', align: 'center', sortable: false },
          { text: 'Menu', value: 'actions', sortable: false },
        ],

        investor_fields: {
          'ID': 'id',
          'User-Name': 'user_name',
          'VC Firm Name': 'firm_name',
          'City': 'city',
          'Country' : 'country',
          'Application Date' : 'terms_accepted_at',
          'Status' : 'role_id',
          'Yearly Budget' : 'yearly_budget',
          'Investment Range' : 'check_size',
          'Sent' : 'sent',
          'Review' : 'review',
        },
        json_meta: [
          [
            {
              'key': 'charset',
              'value': 'utf-8'
            }
          ]
        ],
      }
    },
  
    mounted() {
      Var.getAllIndustries().then((res) => { this.allIndustries = res.data; })
      Var.getAllBusinessModel().then((res) => { this.allModel = res.data; })
      Var.getAllStageOfMarketMaturity().then((res) => { this.allMaturity = res.data; })
      Var.getAllStageOfYourCompanies().then((res) => { this.allStage = res.data; })
      Var.getAllLocations().then((res) => { this.allLocation = res.data; })
      Var.getAllTypeOfOffers().then((res) => { this.allPrimaryOffer = res.data; })
      this.getAllCompanies()
    },
    methods: {
      selectAndUnselectAll () {
        this.business = {
          industry: this.selectAll ? true : false,
          model: this.selectAll ? true : false,
          location: this.selectAll ? true : false,
          market_size: this.selectAll ? true : false,
          market_growth: this.selectAll ? true : false,
          product: this.selectAll ? true : false,
          technology_focus: this.selectAll ? true : false,
          technology: this.selectAll ? true : false,
          rd: this.selectAll ? true : false,
          patent: this.selectAll ? true : false,
        }
        this.recurring = {
          revenue: this.selectAll ? true : false,
        }
        this.legal = {
          company_HQ: this.selectAll ? true : false,
          fund_need: this.selectAll ? true : false,
          investment_stage: this.selectAll ? true : false,
          vehicle: this.selectAll ? true : false,
          founder_profile_team: this.selectAll ? true : false,
          investor_type: this.selectAll ? true : false,
        }
        this.human = {
          graduate_school: this.selectAll ? true : false,
          location_country: this.selectAll ? true : false,
          skills: this.selectAll ? true : false,
        }
      },

      selectAndUnselectBusiness () {
        this.business = {
          industry: this.selectBusiness ? true : false,
          model: this.selectBusiness ? true : false,
          location: this.selectBusiness ? true : false,
          market_size: this.selectBusiness ? true : false,
          market_growth: this.selectBusiness ? true : false,
          product: this.selectBusiness ? true : false,
          technology_focus: this.selectBusiness ? true : false,
          technology: this.selectBusiness ? true : false,
          rd: this.selectBusiness ? true : false,
          patent: this.selectBusiness ? true : false,
        }
      },

      selectAndUnselectRecurring () {
        this.recurring = {
          revenue: this.selectRecurring ? true : false,
        }
      },

      selectAndUnselectLegal () {
        this.legal = {
          company_HQ: this.selectLegal ? true : false,
          fund_need: this.selectLegal ? true : false,
          investment_stage: this.selectLegal ? true : false,
          vehicle: this.selectLegal ? true : false,
          founder_profile_team: this.selectLegal ? true : false,
          investor_type: this.selectLegal ? true : false,
        }
      },

      selectAndUnselectHuman () {
        this.human = {
          graduate_school: this.selectHuman ? true : false,
          location_country: this.selectHuman ? true : false,
          skills: this.selectHuman ? true : false,
        }
      },
      getCity(address) {
        let city = JSON.parse(address);
        return city.city;
      },
      formatDate(date) {
        let dat = new Date(date);
        let year = dat.getFullYear();
        let month = dat.getMonth() + 1;
        let day = dat.getDate();
        if (day < 10 && month < 10) {
          return `0${day}/0${month}/${year}`;
        }
        else if (month < 10) {
          return `${day}/0${month}/${year}`;
        }
        else {
          return `${day}/${month}/${year}`;
        }
      },
      hideActionsList() {
        this.actionsList = false
      },
      showActionsList(item) {
        this.actionsList == item.id ? (this.actionsList = null) : (this.actionsList = item.id);
      },
      formatInvestorArray (arr) {
        this.investors = [];
        for (const element of arr) {
          this.investors.push(
            {
              id: element.id,
              'user_name': `${element.first_name} ${element.last_name}`,
              'firm_name': element.investor_accreditation && element.investor_accreditation.firm_name ? element.investor_accreditation.firm_name : '----',
              'city': element.investor_accreditation ? this.getCity(element.investor_accreditation.address) : '',
              'country': element.investor_accreditation ? element.investor_accreditation.country : '',
              'terms_accepted_at': element.investor_status ? this.formatDate(element.investor_status.terms_accepted_at) : '',
              'role_id': element.role_id,
              'yearly_budget': element.investor_accreditation ? this.formatToString(element.investor_accreditation.yearly_budget) : '',
              'check_size': element.investor_accreditation ? `${this.formatToString(element.investor_accreditation.min_check_size)} - ${this.formatToString(element.investor_accreditation.max_check_size)}` : '',
              'sent': '0',
              'review': '0',
              'initial_application_pdf': element.investor_status ? element.investor_status.initial_application_pdf : null,
              'last_application_pdf': element.investor_status ? element.investor_status.last_application_pdf : null,
              'email': element.email
            }
          )
        }
      },

      getInvestors() {
        if (this.selectedCompany) {
          let data = {
            company_id: this.selectedCompany.value,
            user_id: this.company.user_id,
            business: this.business,
            recurring: this.recurring,
            legal: this.legal,
            human: this.human,
          }

          Admin.filterInvestor(data).then(res => {
            if(res.status == 200) {
              this.formatInvestorArray(res.data);
            }
          })
        } else {
          this.$toast.open({
            message: "Please select a company first !",
            type: 'error',
            position: 'top-right'
          });
        }
      },

      getCompanyById() {
        Admin.getAllInfoCompanyById(this.selectedCompany.value).then(res => {
          if(res.status == 200) {
            this.company = res.data;
            this.industry = res.data.business.industry;
            this.stage = res.data.business.stage;
            this.location = res.data.business.location;
            this.maturity = res.data.business.maturity;
            this.marketModel = res.data.business.model;
            this.offer = res.data.business_products.service_offer;
            this.technologyFocus = res.data.business_products.technology_focus;
            this.withRB = res.data.business_products.rd;
            this.initialFunding = res.data.needs.raising.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.updatedFunding = res.data.needs.needed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            Admin.getSkillsById(res.data.user_id).then(res => {
              this.skills = res.data;
            });

            Admin.getEducationsById(res.data.user_id).then(res => {
              this.educations = [];
              for (let education of res.data) {
                this.educations.push(
                  education.school
                )
              }
            });

            Admin.admingetAvatarCompany(res.data.user_id).then(res => {
              if (res.status == 200) {
                this.avatar = res.data;
              } else {
                this.avatar = null;
              }
            })
          }
        })

        Admin.admingetLogoCompany(this.selectedCompany.value).then(res => {
          this.company.logo = res.data;
        });
      },
      getAllCompanies() {
        company.getCompanyWithRolesC2OrC3().then(res => {
          for (let campany of res.data) {
            this.companies.push(
              { value: campany.id, text: campany.company_name }
            )
          }
        })
      },
      formattedDate(date) {
        let dat = new Date(date)
        return dat.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      }
    },
    
  }
</script>
<style src="../../../assets/styles/admin/style.css"> </style>
<style>
  .site-text {
    color: #00aac2;
  }
  .text-normal {
    font-weight: 500;
    font-size: 15px;
  }
  .link {
    color: #000 !important;
    text-decoration: none;
  }
  .link:hover {
    color: #00aac2 !important;
  }
  .round-circle {
    border-radius: 50px !important;
    object-fit: cover;
    width: 150px !important;
    height: 150px !important;
  }

  *:disabled {
    cursor: not-allowed;
    opacity: 0.7 !important;
    color: #000000 !important;
  }

  .v-chip--select {
    cursor: not-allowed;
    opacity: 0.9 !important;
    color: #000000;
  }

  .v-label {
    margin-top: 6.5px;
  }

  @media screen and (max-width: 768px) {
    .mt-col-6 {
      margin-top: 30px !important;
    }
  }
</style>
  