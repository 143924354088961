<template>
    <div class="mar-top-100">
        <v-row>
            <v-col cols="1" md="1"/>
            <v-col cols="10" md="10">
                <p class="cyan--text text--darken-1 text-sm-left my-5" id="title">Finance History</p>
                <p class="cyan--text text--darken-1 text-sm-left" id="title">The investment of the company </p>
                
                <v-radio-group height="100%" style="display: inline;" v-model="investment.stage">
                    <v-row v-for="(stage, index) in stages" :key="index">
                        <FormInlineMessage v-if="!oneSelect()">
                            Fill the field
                        </FormInlineMessage>
                        
                        <v-col cols="12">
                            <v-radio :label="stage.label" color="primary" :value="stage.name" class="mt-n3"></v-radio>
                        </v-col>

                        <v-col cols="1"/>

                        <v-col cols="10">
                            <p class="text-sm-left font-weight-thin">
                                {{ stage.description }}
                            </p>
                        </v-col>
                    </v-row>
                </v-radio-group>
                
                <p class="cyan--text text--darken-1 text-sm-left" id="title">How much external financing did you previously get ?</p>
                <v-row>
                    <v-col cols="12" md="3">
                        <FormInlineMessage v-if="$v.investment_finance.$error" class="errmsg">
                            <v-col cols="0" md="12"/>
                        </FormInlineMessage>
                        <p>Total amount of previous external financing in USD (U.S. dollars)</p>
                    </v-col>
                    <v-col cols="12" md="8">
                        <FormInlineMessage v-if="$v.investment_finance.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_investment_finance_length_error.error">
                            {{ is_investment_finance_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="investment_finance"
                        outlined
                        dense
                        @input="investment_finance = formatToString(investment_finance)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <p>
                    Repartition of your previous external financing in USD<br>
                    <span class="blue-grey--text text--lighten-3">Enter 0 if the amount of financing type is nil</span>
                </p>

                <v-row>
                    <v-col cols="12" md="3">
                        <FormInlineMessage v-if="$v.investment_equity.$error" class="errmsg">
                            <v-col cols="0" md="12"/>
                        </FormInlineMessage>
                        <p class="thin">Equity</p>
                    </v-col>
                    <v-col cols="12" md="8">
                        <FormInlineMessage v-if="$v.investment_equity.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_investment_equity_length_error.error">
                            {{ is_investment_equity_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="investment_equity"
                        outlined
                        dense
                        @input="investment_equity = formatToString(investment_equity)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="3">
                        <FormInlineMessage v-if="$v.investment_convertible.$error" class="errmsg">
                            <v-col cols="0" md="12"/>
                        </FormInlineMessage>
                        <p class="thin">Convertible note</p>
                    </v-col>
                    <v-col cols="12" md="8">
                        <FormInlineMessage v-if="$v.investment_convertible.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_investment_convertible_length_error.error">
                            {{ is_investment_convertible_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="investment_convertible"
                        outlined
                        dense
                        @input="investment_convertible = formatToString(investment_convertible)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="3">
                        <FormInlineMessage v-if="$v.investment_safe.$error" class="errmsg">
                            <v-col cols="0" md="12"/>
                        </FormInlineMessage>
                        <p class="thin">SAFE</p>
                    </v-col>
                    <v-col cols="12" md="8">
                        <FormInlineMessage v-if="$v.investment_safe.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_investment_safe_length_error.error">
                            {{ is_investment_safe_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="investment_safe"
                        outlined
                        dense
                        @input="investment_safe = formatToString(investment_safe)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="3">
                        <FormInlineMessage v-if="$v.investment_bonds.$error" class="errmsg">
                            <v-col cols="0" md="12"/>
                        </FormInlineMessage>
                        <p class="thin">Bonds</p>
                    </v-col>
                    <v-col cols="12" md="8">
                        <FormInlineMessage v-if="$v.investment_bonds.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_investment_bonds_length_error.error">
                            {{ is_investment_bonds_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="investment_bonds"
                        outlined
                        dense
                        @input="investment_bonds  = formatToString(investment_bonds)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="3">
                        <FormInlineMessage v-if="$v.investment_bank.$error" class="errmsg">
                            <v-col cols="0" md="12"/>
                        </FormInlineMessage>
                        <p class="thin">Bank Loans</p>
                    </v-col>
                    <v-col cols="12" md="8">
                        <FormInlineMessage v-if="$v.investment_bank.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_investment_bank_length_error.error">
                            {{ is_investment_bank_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="investment_bank"
                        outlined
                        dense
                        @input="investment_bank = formatToString(investment_bank)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="3">
                        <FormInlineMessage v-if="$v.investment_grants.$error" class="errmsg">
                            <v-col cols="0" md="12"/>
                        </FormInlineMessage>
                        <p class="thin">Grants</p>
                    </v-col>
                    <v-col cols="12" md="8">
                        <FormInlineMessage v-if="$v.investment_grants.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_investment_grants_length_error.error">
                            {{ is_investment_grants_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="investment_grants"
                        outlined
                        dense
                        @input="investment_grants = formatToString(investment_grants)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <p class="cyan--text text--darken-1 text-sm-left" id="title">How equity is distributed among shareholders currently</p>
                <p class="text-sm-left">Shareholders</p>
                <v-row class="mb-n10">
                    <v-col cols="4" md="6">
                        <p class="text-sm-left thin">Name</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin">% Shares</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin">Type of shareholder</p>
                    </v-col>
                </v-row>

                <div v-for="(shareholder, index) in shareholder.shareholders" :key="index">
                    <v-row>
                        <v-col cols="12" md="6">
                            <FormInlineMessage v-if="$v.shareholder.shareholders.$each[index].name.$error" class="errmsg">
                                Fill the field
                            </FormInlineMessage>
                            <v-text-field
                            v-model="shareholder.name"
                            outlined
                            dense
                            :placeholder="getname(index + 1)"/>
                        </v-col>
                        <v-col cols="6" md="3">
                            <FormInlineMessage v-if="$v.shareholder.shareholders.$each[index].share.$error" class="errmsg">
                                Fill the field
                            </FormInlineMessage>
                            <FormInlineMessage v-if="is_share_horlder_up_to_100_percent_error(index)">
                                {{ is_share_horlder_up_to_100_percent_error_message(index).message }}
                            </FormInlineMessage>
                            <v-text-field
                            v-model="shareholder.share"
                            outlined
                            dense
                            placeholder="% Shares"
                            @keypress="isNumber($event)"/>
                        </v-col>
                        <v-col cols="6" md="3">
                            <FormInlineMessage v-if="$v.shareholder.shareholders.$each[index].type.$error" class="errmsg">
                                Fill the field
                            </FormInlineMessage>
                            <v-select
                            v-model="shareholder.type"
                            :items="typeShareholder"
                            placeholder="Corporate"
                            outlined
                            dense/>
                        </v-col>
                    </v-row>
                </div>

                <v-row>
                    <v-col cols="0" md="10" v-if="shareholder.shareholders.length == 1"/>
                    <v-col cols="0" md="7" v-else/>
                    <v-row class="mb-10 mt-n28">
                    <v-btn
                    id="btn-add"
                    text
                    color="#00aac3"
                    @click.prevent.stop="addShareholder()">
                        + Add Shareholder
                    </v-btn>

                    <v-btn
                    v-if="shareholder.shareholders.length > 1"
                    id="btn-add"
                    text
                    color="#00aac3"
                    @click.prevent.stop="removeShareholder()">
                        - Remove Shareholder
                    </v-btn>
                    </v-row>
                </v-row>

                <p class="text-sm-left mt-5">Capitalization table</p>
                <v-row class="mb-n10">
                    <v-col cols="4" md="6">
                        <p class="text-sm-left">Shares</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin">Number of shares</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin">Value of shares</p>
                    </v-col> 
                </v-row>

                <v-row class="mb-n10">
                    <v-col cols="4" md="6">
                        <p class="text-sm-left thin py-2 pl-1" style="border: 1px solid black;">Common Shares</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <FormInlineMessage v-if="$v.shareholder_common_number.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_shareholder_common_number_length_error.error">
                            {{ is_shareholder_common_number_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="shareholder_common_number"
                        outlined
                        dense
                        placeholder="......"
                        @input="shareholder_common_number  = formatToString(shareholder_common_number)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                    <v-col cols="4" md="3">
                        <FormInlineMessage v-if="$v.shareholder_common_value.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_shareholder_common_value_length_error.error">
                            {{ is_shareholder_common_value_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="shareholder_common_value"
                        outlined
                        dense
                        placeholder="USD ......"
                        @input="shareholder_common_value  = formatToString(shareholder_common_value)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row class="mb-n10">
                    <v-col cols="4" md="6">
                        <p class="text-sm-left thin py-2 pl-1" style="border: 1px solid black;">Preferred Shares</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <FormInlineMessage v-if="$v.shareholder_preferred_number.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_shareholder_preferred_number_length_error.error">
                            {{ is_shareholder_preferred_number_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="shareholder_preferred_number"
                        outlined
                        dense
                        placeholder="......"
                        @input="shareholder_preferred_number  = formatToString(shareholder_preferred_number)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                    <v-col cols="4" md="3">
                        <FormInlineMessage v-if="$v.shareholder_preferred_value.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_shareholder_preferred_value_length_error.error">
                            {{ is_shareholder_preferred_value_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="shareholder_preferred_value"
                        outlined
                        dense
                        placeholder="USD ......"
                        @input="shareholder_preferred_value  = formatToString(shareholder_preferred_value)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" md="6">
                        <p class="text-sm-left thin py-2 pl-1">Total Common and Preffered Shares</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin py-2 text-sm-center grey-back" style="border: 1px solid black; background-color: dimgray;">
                            {{ formatToString((shareholder_common_number ? formatToInt(shareholder_common_number) : 0) +
                            (shareholder_preferred_number ? formatToInt(shareholder_preferred_number) : 0)) }}
                        </p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin py-2 text-sm-center grey-back" style="border: 1px solid black; background-color: dimgray;">
                            {{ formatToString((shareholder_common_value ? formatToInt(shareholder_common_value) : 0) +
                            (shareholder_preferred_value ? formatToInt(shareholder_preferred_value) : 0)) }}
                        </p>
                    </v-col>
                </v-row>

                 <v-row class="mb-n10">
                    <v-col cols="4" md="6">
                        <p class="text-sm-left">Options</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin">Number of options</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin">Value of options</p>
                    </v-col>
                </v-row>

                <v-row class="mb-n10">
                    <v-col cols="4" md="6">
                        <p class="text-sm-left thin py-2 pl-1" style="border: 1px solid black;">Options - Granted</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <FormInlineMessage v-if="$v.shareholder_granted_number.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_shareholder_granted_number_length_error.error">
                            {{ is_shareholder_granted_number_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="shareholder_granted_number"
                        outlined
                        dense
                        placeholder="......"
                        @input="shareholder_granted_number  = formatToString(shareholder_granted_number)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                    <v-col cols="4" md="3">
                        <FormInlineMessage v-if="$v.shareholder_granted_value.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_shareholder_granted_value_length_error.error">
                            {{ is_shareholder_granted_value_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="shareholder_granted_value"
                        outlined
                        dense
                        placeholder="USD ......"
                        @input="shareholder_granted_value  = formatToString(shareholder_granted_value)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row class="mb-n10">
                    <v-col cols="4" md="6">
                        <p class="text-sm-left thin py-2 pl-1" style="border: 1px solid black;">Options - Authorized, Not granted</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <FormInlineMessage v-if="$v.shareholder_authorized_number.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_shareholder_authorized_number_length_error.error">
                            {{ is_shareholder_authorized_number_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="shareholder_authorized_number"
                        outlined
                        dense
                        placeholder="......"
                        @input="shareholder_authorized_number  = formatToString(shareholder_authorized_number)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                    <v-col cols="4" md="3">
                        <FormInlineMessage v-if="$v.shareholder_authorized_value.$error" class="errmsg">
                            Fill the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_shareholder_authorized_value_length_error.error">
                            {{ is_shareholder_authorized_value_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="shareholder_authorized_value"
                        outlined
                        dense
                        placeholder="USD ......"
                        @input="shareholder_authorized_value  = formatToString(shareholder_authorized_value)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" md="6">
                        <p class="text-sm-left thin py-2 pl-1">Total Options</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin py-2 text-sm-center grey-back" style="border: 1px solid black; background-color: dimgray;">
                            {{ formatToString((shareholder_granted_number ? formatToInt(shareholder_granted_number) : 0) +
                            (shareholder_authorized_number ? formatToInt(shareholder_authorized_number) : 0)) }}
                        </p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin py-2 text-sm-center grey-back" style="border: 1px solid black; background-color: dimgray;">
                            {{ formatToString((shareholder_granted_value ? formatToInt(shareholder_granted_value) : 0) +
                            (shareholder_authorized_value ? formatToInt(shareholder_authorized_value) : 0)) }}
                        </p>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" md="6">
                        <p class="text-sm-left thin py-2 pl-1" style="border: 1px solid black;">Total Fully Dilluted Shares</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin py-2 text-sm-center" style="border: 1px solid black;">
                            {{ formatToString((shareholder_granted_number ? formatToInt(shareholder_granted_number) : 0) +
                            (shareholder_authorized_number ? formatToInt(shareholder_authorized_number) : 0) +
                            (shareholder_common_number ? formatToInt(shareholder_common_number) : 0) +
                            (shareholder_preferred_number ? formatToInt(shareholder_preferred_number) : 0)) }}
                        </p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin py-2 text-sm-center" style="border: 1px solid black;">
                            {{ formatToString((shareholder_granted_value ? formatToInt(shareholder_granted_value) : 0) +
                            (shareholder_authorized_value ? formatToInt(shareholder_authorized_value) : 0) +
                            (shareholder_common_value ? formatToInt(shareholder_common_value) : 0) +
                            (shareholder_preferred_value ? formatToInt(shareholder_preferred_value) : 0)) }}
                        </p>
                    </v-col>
                </v-row>

                <p class="cyan--text text--darken-1 text-sm-left mt-5" id="title">Situation of shareholder and cofounder' current accounts</p>
                <p class="text-sm-left">Shareholders</p>
                <v-row class="mb-n10">
                    <v-col cols="4" md="6">
                        <p class="text-sm-left thin">Name</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin">Current account balance amount in USD</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <p class="text-sm-left thin">Type of shareholder</p>
                    </v-col>
                </v-row>

                <div v-for="(shareholder_account, index) in shareholder_account.shareholders" :key="index + 1">
                    <v-row>
                        <v-col cols="12" md="6">
                            <FormInlineMessage v-if="$v.shareholder_account.shareholders.$each[index].name.$error" class="errmsg">
                                Fill the field
                            </FormInlineMessage>
                            <v-text-field
                            v-model="shareholder_account.name"
                            outlined
                            dense
                            :placeholder="getname(index + 1)"/>
                        </v-col>
                        <v-col cols="6" md="3">
                            <FormInlineMessage v-if="is_current_account_balance_error(index)">
                                {{ current_account_balance_error_message(index) }}
                            </FormInlineMessage>
                            <v-text-field
                            v-model="shareholder_account.current_account_balance"
                            outlined
                            dense
                            placeholder="---"
                            @keypress="isMinusOrNumberKey($event)"
                            @input="shareholder_account.current_account_balance  = formatToString(shareholder_account.current_account_balance)"/>
                        </v-col>
                        <v-col cols="6" md="3">
                            <FormInlineMessage v-if="$v.shareholder_account.shareholders.$each[index].type.$error" class="errmsg">
                                Fill the field
                            </FormInlineMessage>
                            <v-select
                            v-model="shareholder_account.type"
                            :items="typeShareholder"
                            placeholder="Corporate"
                            outlined
                            dense/>
                        </v-col>
                    </v-row>
                </div>
                <v-row>
                    <v-col cols="0" md="10" v-if="shareholder_account.shareholders.length == 1"/>
                    <v-col cols="0" md="7" v-else/>
                    <v-row class="mb-10 mt-n28">
                        <v-btn
                        id="btn-add"
                        text
                        color="#00aac3"
                        @click.prevent.stop="addShareholderAccounts()">
                            + Add Shareholder
                        </v-btn>

                        <v-btn
                        v-if="shareholder_account.shareholders.length > 1"
                        id="btn-add"
                        text
                        color="#00aac3"
                        @click.prevent.stop="removeShareholderAccounts()">
                            - Remove Shareholder
                        </v-btn>
                    </v-row>
                </v-row>

                <p class="cyan--text text--darken-1 text-sm-left mt-5" id="title">Past result in the last 3 years ?</p>
                <p class="text-sm-left">Please tell us about your Key financials of your company in the last 3 years. In the first line, replace .... by the years.</p>

                <v-row>
                    <v-col cols="12" md="3">
                        <p class="blue-grey--text mb-n8 text--lighten-3 py-5 text-sm-left">Unit: USD K</p>
                    </v-col>
                    <v-col cols="4" md="3">
                        <v-text-field
                        v-model="result.firstYear.year"
                        background-color="grey"
                        class="years"
                        placeholder="...."
                        outlined
                        dense
                        @keypress="isNumber($event)"/>
                    </v-col>
                    <v-col cols="4" md="3">
                        <v-text-field
                        v-model="result.secondYear.year"
                        background-color="grey"
                        class="years"
                        placeholder="...."
                        outlined
                        dense
                        @keypress="isNumber($event)"/>
                    </v-col>
                    <v-col cols="4" md="3">
                        <v-text-field
                        v-model="result.thirdYear.year"
                        background-color="grey"
                        class="years"
                        placeholder="...."
                        outlined
                        dense
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row class="mt-n6">
                    <v-col cols="12" md="3">
                        <p class="py-5 text-sm-left mt-n3">Annual revenues</p>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_firstYear_annualRevenues.$error" class="errmsg position-absolute-error">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_firstYear_annualRevenues_length_error.error">
                            {{ is_result_firstYear_annualRevenues_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_firstYear_annualRevenues"
                        outlined
                        dense
                        @input="result_firstYear_annualRevenues  = formatToString(result_firstYear_annualRevenues)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_secondYear_annualRevenues.$error" class="errmsg position-absolute-error">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_secondYear_annualRevenues_length_error.error">
                            {{ is_result_secondYear_annualRevenues_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_secondYear_annualRevenues"
                        outlined
                        dense
                        @input="result_secondYear_annualRevenues  = formatToString(result_secondYear_annualRevenues)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_thirdYear_annualRevenues.$error" class="errmsg position-absolute-error">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_thirdYear_annualRevenues_length_error.error">
                            {{ is_result_thirdYear_annualRevenues_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_thirdYear_annualRevenues"
                        outlined
                        dense
                        @input="result_thirdYear_annualRevenues  = formatToString(result_thirdYear_annualRevenues)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <v-row class="mt-n6">
                    <v-col cols="12" md="3">
                        <p class="py-5 text-sm-left mt-n3">Gross Profit</p>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_firstYear_grossProfit.$error" class="errmsg position-absolute-error">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_firstYear_grossProfit_length_error.error">
                            {{ is_result_firstYear_grossProfit_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_firstYear_grossProfit"
                        outlined
                        dense
                        @input="result_firstYear_grossProfit  = formatToString(result_firstYear_grossProfit)"/>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_secondYear_grossProfit.$error" class="errmsg position-absolute-error">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_secondYear_grossProfit_length_error.error">
                            {{ is_result_secondYear_grossProfit_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_secondYear_grossProfit"
                        outlined
                        dense
                        @input="result_secondYear_grossProfit  = formatToString(result_secondYear_grossProfit)"/>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_thirdYear_grossProfit.$error" class="errmsg position-absolute-error">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_thirdYear_grossProfit_length_error.error">
                            {{ is_result_thirdYear_grossProfit_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_thirdYear_grossProfit"
                        outlined
                        dense
                        @input="result_thirdYear_grossProfit  = formatToString(result_thirdYear_grossProfit)"/>
                    </v-col>
                </v-row>

                <v-row class="mt-n6">
                    <v-col cols="12" md="3">
                        <p class="py-5 text-sm-left mt-n3">EBITDA</p>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_firstYear_ebitda.$error" class="errmsg position-absolute-error">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_firstYear_ebitda_length_error.error">
                            {{ is_result_firstYear_ebitda_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_firstYear_ebitda"
                        outlined
                        dense
                        @input="result_firstYear_ebitda  = formatToString(result_firstYear_ebitda)"/>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_secondYear_ebitda.$error" class="errmsg position-absolute-error">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_secondYear_ebitda_length_error.error">
                            {{ is_result_secondYear_ebitda_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_secondYear_ebitda"
                        outlined
                        dense
                        @input="result_secondYear_ebitda  = formatToString(result_secondYear_ebitda)"/>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_thirdYear_ebitda.$error" class="errmsg position-absolute-error">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_thirdYear_ebitda_length_error.error">
                            {{ is_result_thirdYear_ebitda_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_thirdYear_ebitda"
                        outlined
                        dense
                        @input="result_thirdYear_ebitda  = formatToString(result_thirdYear_ebitda)"/>
                    </v-col>
                </v-row>

                <v-row class="mt-n6">
                    <v-col cols="12" md="3">
                        <p class="py-5 text-sm-left mt-n3">EBIT</p>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_firstYear_ebit.$error" class="errmsg position-absolute-error">
                           Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_firstYear_ebit_length_error.error">
                            {{ is_result_firstYear_ebit_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_firstYear_ebit"
                        outlined
                        dense
                        @input="result_firstYear_ebit = formatToString(result_firstYear_ebit)"/>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_secondYear_ebit.$error" class="errmsg position-absolute-error">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_secondYear_ebit_length_error.error">
                            {{ is_result_secondYear_ebit_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_secondYear_ebit"
                        outlined
                        dense
                        @input="result_secondYear_ebit = formatToString(result_secondYear_ebit)"/>
                    </v-col>
                    <v-col cols="4" md="3" style="position: relative;">
                        <FormInlineMessage v-if="$v.result_thirdYear_ebit.$error" class="errmsg position-absolute-error">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_thirdYear_ebit_length_error.error">
                            {{ is_result_thirdYear_ebit_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_thirdYear_ebit"
                        outlined
                        dense
                        @input="result_thirdYear_ebit  = formatToString(result_thirdYear_ebit)"/>
                    </v-col>
                </v-row>

                <v-row class="mt-10">
                    <v-col cols="12" md="4">
                        <p class="text-sm-left">Monthly Recurring Revenues in USD <span style="font-weight: normal !important;">in the last semester (write 0 if none)?</span></p>
                    </v-col>
                    <v-col cols="12" md="8">
                        <FormInlineMessage v-if="$v.result_monthlyRevenue.$error" class="errmsg">
                            Fill in the field
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_monthlyRevenue_length_error.error">
                            {{ is_result_monthlyRevenue_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_monthlyRevenue"
                        outlined
                        dense
                        @input="result_monthlyRevenue  = formatToString(result_monthlyRevenue)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>

                <p class="cyan--text text--darken-1 text-sm-left mt-10" id="title">What is the valuation of your company ?</p>

                <v-row>
                    <v-col cols="12" md="4">
                        <p class="text-sm-left thin">
                            Share post-money valuation in USD.<br>
                            If this will be an equity round. If this will be on a SAFE or Convertible Note. Share valuation cap.<br>
                            <span class="blue-grey--text text--lighten-3">Enter 10000000 for US $10.000.000</span>
                        </p>
                    </v-col>
                    <v-col cols="12" md="8">
                        <FormInlineMessage v-if="$v.result_monthlyRevenue.$error" class="errmsg">
                            Sorry, Post-money valuation is required and you can enter here max 15 digits
                        </FormInlineMessage>
                        <FormInlineMessage v-if="is_result_postMoneyValuation_length_error.error">
                            {{ is_result_postMoneyValuation_length_error.message }}
                        </FormInlineMessage>
                        <v-text-field
                        v-model="result_postMoneyValuation"
                        outlined
                        dense
                        @input="result_postMoneyValuation  = formatToString(result_postMoneyValuation)"
                        @keypress="isNumber($event)"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row align="center" justify="center" class="pt-2">
            <v-col cols="12" sm="1">
                <v-btn
                id="btn-photo"
                class="white--text mb-3"
                style="font-weight: bold; font-size: 14px"
                color="#11aec9"
                min-width="20"
                rounded
                block
                :disabled="false"
                @click.stop.prevent="save()">
                    save
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import FinanceService from "@/store/services/finance.service";
import Var from "@/store/services/var";
import { required } from "vuelidate/lib/validators";
import { myMixins } from "@/mixins/mixins";

export default {
    mixins: [myMixins],
    components: {
        FormInlineMessage,
    },

    props: {
        company_id: Number,
    },

    data () {
        return {
            typeShareholder: [],
            stages: [],
            investment: {},
            shareholder: { 
                shareholders: [{}],
                common: {},
                preferred: {},
                granted: {},
                authorized: {},
            },
            shareholder_account: {
                shareholders: [{}],
            },
            result: {
                firstYear: {},
                secondYear: {},
                thirdYear: {},
            },
            seed_stage: false,
            series_a: false,
            series_b: false,
            later_stage: false,
            investment_finance: null,
            investment_equity: null,
            investment_convertible: null,
            investment_safe: null,
            investment_bonds: null,
            investment_bank: null,
            investment_grants: null,
            shareholder_common_number: null,
            shareholder_common_value: null,
            shareholder_preferred_number: null,
            shareholder_preferred_value: null,
            shareholder_granted_number: null,
            shareholder_granted_value: null,
            shareholder_authorized_number: null,
            shareholder_authorized_value: null,

            result_monthlyRevenue: null,
            result_postMoneyValuation: null,

            result_firstYear_annualRevenues: null,
            result_secondYear_annualRevenues: null,
            result_thirdYear_annualRevenues: null,
            result_firstYear_grossProfit: null,
            result_secondYear_grossProfit: null,
            result_thirdYear_grossProfit: null,
            result_firstYear_ebit: null,
            result_secondYear_ebit: null,
            result_thirdYear_ebit: null,
            result_firstYear_ebitda: null,
            result_secondYear_ebitda: null,
            result_thirdYear_ebitda: null,

            shareholder_share_has_error: false,
            account_current_account_balance_has_error: false,
        }
    },

    validations: {
        investment_finance: { required,  },
        investment_equity: { required, },
        investment_convertible: { required, },
        investment_safe: { required, },
        investment_bonds: { required, },
        investment_bank: { required, },
        investment_grants: { required, },

        shareholder_common_number: { required, },
        shareholder_common_value: { required, },
        shareholder_preferred_number: { required, },
        shareholder_preferred_value: { required, },
        shareholder_granted_number: { required, },
        shareholder_granted_value: { required, },
        shareholder_authorized_number: { required, },
        shareholder_authorized_value: { required, },

        result_firstYear_annualRevenues: { required, },
        result_secondYear_annualRevenues: { required, },
        result_thirdYear_annualRevenues: { required, },

        result_firstYear_grossProfit: { required, },
        result_secondYear_grossProfit: { required, },
        result_thirdYear_grossProfit: { required, },

        result_firstYear_ebit: { required, },
        result_secondYear_ebit: { required, },
        result_thirdYear_ebit: { required, },

        result_firstYear_ebitda: { required, },
        result_secondYear_ebitda: { required, },
        result_thirdYear_ebitda: { required, },

        result_monthlyRevenue: { required, },

        investment: {
            stage: { required },
            finance: { required, },
            equity: { required },
            convertible: { required },
            // safe: { required },
            bonds: { required },
            bank: { required },
            grants: { required },
        },

        shareholder: {
            shareholders: {
                $each: {
                    name: { required, },
                    share: { required, },
                    type: { required, },
                },
            },
        },

        shareholder_account: {
            shareholders: {
                $each: {
                    name: {  },
                    current_account_balance: {  },
                    type: {  },
                },
            },
        },
    },

    methods: {
        oneSelect() {
            if (this.investment.stage) { return true; }
            return false;
        },
        getname(index) {
            return ("Name " + index); 
        },

        addShareholder() {
            this.shareholder.shareholders.push({});
        },

        removeShareholder() {
            this.shareholder.shareholders.pop();
        },

        addShareholderAccounts() {
            this.shareholder_account.shareholders.push({});
        },

        removeShareholderAccounts() {
            this.shareholder_account.shareholders.pop();
        },

        shareholderValidation(value) {

        },

        shareholderAccountValidation() {
            if (this.shareholder_account.shareholders.some((account) => {
                if (!account.current_account_balance) return false;
                let value = this.formatToInt(account.current_account_balance);
                if (value.toString().length > 15) {
                    this.account_current_account_balance_has_error = true;
                    this.resetForm();
                    return true;
                }
                this.account_current_account_balance_has_error = false;
            }));
        },

        resetForm () {
            this.investment_finance = this.investment_finance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_equity  = this.investment_equity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_convertible = this.investment_convertible.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_safe  = this.investment_safe.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_bonds = this.investment_bonds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_bank  = this.investment_bank.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.investment_grants = this.investment_grants.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.shareholder_common_number = this.shareholder_common_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.shareholder_common_value  = this.shareholder_common_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.shareholder_preferred_number = this.shareholder_preferred_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.shareholder_preferred_value  = this.shareholder_preferred_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.shareholder_granted_number   = this.shareholder_granted_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.shareholder_granted_value    = this.shareholder_granted_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.shareholder_authorized_number = this.shareholder_authorized_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.shareholder_authorized_value  = this.shareholder_authorized_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.result_monthlyRevenue     = this.result_monthlyRevenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_postMoneyValuation = this.result_postMoneyValuation.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.result_firstYear_annualRevenues  = this.result_firstYear_annualRevenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_secondYear_annualRevenues = this.result_secondYear_annualRevenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_thirdYear_annualRevenues  = this.result_thirdYear_annualRevenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.result_firstYear_grossProfit  = this.result_firstYear_grossProfit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_secondYear_grossProfit = this.result_secondYear_grossProfit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_thirdYear_grossProfit  = this.result_thirdYear_grossProfit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.result_firstYear_ebit  = this.result_firstYear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_secondYear_ebit = this.result_secondYear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_thirdYear_ebit  = this.result_thirdYear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.result_firstYear_ebitda  = this.result_firstYear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_secondYear_ebitda = this.result_secondYear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.result_thirdYear_ebitda  = this.result_thirdYear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        save() {
            this.$v.$touch();
            if (
                this.$v.$error ||
                this.is_investment_finance_length_error ||
                this.is_investment_equity_length_error ||
                this.is_investment_convertible_length_error ||
                this.is_investment_safe_length_error ||
                this.is_investment_bonds_length_error ||
                this.is_investment_bank_length_error ||
                this.is_investment_grants_length_error ||
                this.is_shareholder_common_number_length_error ||
                this.is_shareholder_common_value_length_error ||
                this.is_shareholder_preferred_number_length_error ||
                this.is_shareholder_preferred_value_length_error ||
                this.is_shareholder_granted_number_length_error ||
                this.is_shareholder_granted_value_length_error ||
                this.is_shareholder_authorized_number_length_error ||
                this.is_shareholder_authorized_value_length_error ||
                this.is_result_firstYear_annualRevenues_length_error ||
                this.is_result_secondYear_annualRevenues_length_error ||
                this.is_result_thirdYear_annualRevenues_length_error ||
                this.is_result_firstYear_grossProfit_length_error ||
                this.is_result_secondYear_grossProfit_length_error ||
                this.is_result_thirdYear_grossProfit_length_error ||
                this.is_result_firstYear_ebit_length_error ||
                this.is_result_secondYear_ebit_length_error ||
                this.is_result_thirdYear_ebit_length_error ||
                this.is_result_firstYear_ebitda_length_error ||
                this.is_result_secondYear_ebitda_length_error ||
                this.is_result_thirdYear_ebitda_length_error ||
                this.is_result_monthlyRevenue_length_error ||
                this.is_result_postMoneyValuation_length_error
            ) {
                return this.$toast.open({
                    message: "Please fill all required fields or correct numbers or amounts having more than 15 digits before saving.",
                    type: "error",
                    position: "top-right",
                }); 
            };

            this.investment_finance = this.formatToInt(this.investment_finance);
            this.investment_equity = this.formatToInt(this.investment_equity);
            this.investment_convertible = this.formatToInt(this.investment_convertible);
            this.investment_safe = this.formatToInt(this.investment_safe);
            this.investment_bonds = this.formatToInt(this.investment_bonds);
            this.investment_bank = this.formatToInt(this.investment_bank);
            this.investment_grants = this.formatToInt(this.investment_grants);

            this.shareholder_common_number = this.formatToInt(this.shareholder_common_number);
            this.shareholder_common_value = this.formatToInt(this.shareholder_common_value);
            this.shareholder_preferred_number = this.formatToInt(this.shareholder_preferred_number);
            this.shareholder_preferred_value = this.formatToInt(this.shareholder_preferred_value);
            this.shareholder_granted_number = this.formatToInt(this.shareholder_granted_number);
            this.shareholder_granted_value = this.formatToInt(this.shareholder_granted_value);
            this.shareholder_authorized_number = this.formatToInt(this.shareholder_authorized_number);
            this.shareholder_authorized_value = this.formatToInt(this.shareholder_authorized_value);

            this.result_monthlyRevenue = this.formatToInt(this.result_monthlyRevenue);
            this.result_postMoneyValuation = this.formatToInt(this.result_postMoneyValuation);

            this.result_firstYear_annualRevenues = this.formatToInt(this.result_firstYear_annualRevenues);
            this.result_secondYear_annualRevenues = this.formatToInt(this.result_secondYear_annualRevenues);
            this.result_thirdYear_annualRevenues = this.formatToInt(this.result_thirdYear_annualRevenues);
            
            this.result_firstYear_grossProfit = this.formatToInt(this.result_firstYear_grossProfit);
            this.result_secondYear_grossProfit = this.formatToInt(this.result_secondYear_grossProfit);
            this.result_thirdYear_grossProfit = this.formatToInt(this.result_thirdYear_grossProfit);

            this.result_firstYear_ebit = this.formatToInt(this.result_firstYear_ebit);
            this.result_secondYear_ebit = this.formatToInt(this.result_secondYear_ebit);
            this.result_thirdYear_ebit = this.formatToInt(this.result_thirdYear_ebit);

            this.result_firstYear_ebitda = this.formatToInt(this.result_firstYear_ebitda);
            this.result_secondYear_ebitda = this.formatToInt(this.result_secondYear_ebitda);
            this.result_thirdYear_ebitda = this.formatToInt(this.result_thirdYear_ebitda);

            this.investment.finance = this.investment_finance;
            this.investment.equity = this.investment_equity; 
            this.investment.convertible = this.investment_convertible; 
            this.investment.safe = this.investment_safe; 
            this.investment.bonds = this.investment_bonds; 
            this.investment.bank = this.investment_bank; 
            this.investment.grants = this.investment_grants; 

            this.shareholder.common.number = this.shareholder_common_number;
            this.shareholder.common.value  = this.shareholder_common_value;
            this.shareholder.preferred.number = this.shareholder_preferred_number;
            this.shareholder.preferred.value  = this.shareholder_preferred_value;
            this.shareholder.granted.number = this.shareholder_granted_number;
            this.shareholder.granted.value  = this.shareholder_granted_value;
            this.shareholder.authorized.number = this.shareholder_authorized_number;
            this.shareholder.authorized.value  = this.shareholder_authorized_value;

            this.result.monthlyRevenue      = this.result_monthlyRevenue;
            this.result.postMoneyValuation  = this.result_postMoneyValuation;

            this.result.firstYear.annualRevenues  = this.result_firstYear_annualRevenues;
            this.result.secondYear.annualRevenues = this.result_secondYear_annualRevenues;
            this.result.thirdYear.annualRevenues  = this.result_thirdYear_annualRevenues;

            this.result.firstYear.grossProfit  = this.result_firstYear_grossProfit;
            this.result.secondYear.grossProfit = this.result_secondYear_grossProfit;
            this.result.thirdYear.grossProfit  = this.result_thirdYear_grossProfit;

            this.result.firstYear.ebit  = this.result_firstYear_ebit;
            this.result.secondYear.ebit = this.result_secondYear_ebit;
            this.result.thirdYear.ebit  = this.result_thirdYear_ebit;

            this.result.firstYear.ebitda  = this.result_firstYear_ebitda;
            this.result.secondYear.ebitda = this.result_secondYear_ebitda;
            this.result.thirdYear.ebitda  = this.result_thirdYear_ebitda;

            if (this.shareholder.shareholders.some((shareholder) => {
                if (!shareholder.share) return false;
                if (shareholder.share > 100) {
                    this.shareholder_share_has_error = true;
                    this.resetForm();
                    return this.$toast.open({
                        message: "Please correct numbers or amounts having more than 15 digits before saving.",
                        type: "error",
                        position: "top-right",
                    });
                }
                this.shareholder_share_has_error = false;
                if (this.shareholder_account.shareholders.some((account) => {
                    if (!account.current_account_balance) return false;
                    let value = this.formatToInt(account.current_account_balance);
                    if (value.toString().length > 15) {
                        this.account_current_account_balance_has_error = true;
                        this.resetForm();
                        return this.$toast.open({
                            message: "Please correct numbers or amounts having more than 15 digits before saving.",
                            type: "error",
                            position: "top-right",
                        });
                    }
                    this.account_current_account_balance_has_error = false;
                }));
            }))

            this.resetForm();
            if (this.shareholder_share_has_error || this.account_current_account_balance_has_error) return;

            FinanceService.saveInvestment({ investment: this.investment, company_id: this.company_id, });
            FinanceService.saveShareholder({ company_id: this.company_id, shareholder: this.shareholder, });

            // format the current account balance to int
            for(let elm of this.shareholder_account.shareholders) {
                elm.current_account_balance = this.formatToInt(elm.current_account_balance);
            }
            FinanceService.saveCurrentAccountsBalanceShoulder({
                company_id: this.company_id,
                currentBalance: this.shareholder_account,
            }).then((res) => {
                for(let elm of this.shareholder_account.shareholders) {
                    elm.current_account_balance = elm.current_account_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }).catch((err) => {
                for(let elm of this.shareholder_account.shareholders) {
                    elm.current_account_balance = elm.current_account_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            });
            FinanceService.saveResult({ company_id: this.company_id, result: this.result, }).then((response) => {
                if(response.status == 200) {
                    this.$toast.open({
                        message: "Finance information saved successfully !",
                        type: 'success',
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({
                        message: "Something went wrong",
                        type: 'error',
                        position: 'top-right'
                    });
                }
            });

            this.resetForm();
        },
    },

    created() {
        Promise.all([
            Var.getAllShareholders().then((res) => { this.typeShareholder = res.data; }),
            Var.getStages().then((res) => { this.stages = Object.values(res.data); }),
            FinanceService.getResult(this.company_id).then((res) => {
                this.result.firstYear.year = res.data.firstyear_year;
                this.result.firstYear.annualRevenues = res.data.firstyear_annual_revenues;
                this.result.firstYear.grossProfit = res.data.firstyear_gross_profit;
                this.result.firstYear.ebit = res.data.firstyear_ebit;
                this.result.firstYear.ebitda = res.data.firstyear_ebitda;

                this.result.secondYear.year = res.data.secondyear_year;
                this.result.secondYear.annualRevenues = res.data.secondyear_annual_revenues;
                this.result.secondYear.grossProfit = res.data.secondyear_gross_profit;
                this.result.secondYear.ebit = res.data.secondyear_ebit;
                this.result.secondYear.ebitda = res.data.secondyear_ebitda;

                this.result.thirdYear.year = res.data.thirdyear_year;
                this.result.thirdYear.annualRevenues = res.data.thirdyear_annual_revenues;
                this.result.thirdYear.grossProfit = res.data.thirdyear_gross_profit;
                this.result.thirdYear.ebit   = res.data.thirdyear_ebit;
                this.result.thirdYear.ebitda = res.data.thirdyear_ebitda;

                this.result.monthlyRevenue     = res.data.monthly_revenue;
                this.result.postMoneyValuation = res.data.post_money_valuation;


                this.result_monthlyRevenue     = res.data.monthly_revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                this.result_postMoneyValuation = res.data.post_money_valuation.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                this.result_firstYear_annualRevenues  = res.data.firstyear_annual_revenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.result_secondYear_annualRevenues = res.data.secondyear_annual_revenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.result_thirdYear_annualRevenues  = res.data.thirdyear_annual_revenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                this.result_firstYear_grossProfit  = res.data.firstyear_gross_profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.result_secondYear_grossProfit = res.data.secondyear_gross_profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.result_thirdYear_grossProfit  = res.data.thirdyear_gross_profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                this.result_firstYear_ebit  = res.data.firstyear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.result_secondYear_ebit = res.data.secondyear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.result_thirdYear_ebit  = res.data.thirdyear_ebit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                this.result_firstYear_ebitda  = res.data.firstyear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.result_secondYear_ebitda = res.data.secondyear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.result_thirdYear_ebitda  = res.data.thirdyear_ebitda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }),
            FinanceService.getInvestment(this.company_id).then((res) => {
                this.investment = res.data;
                this.investment_finance = this.investment.finance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.investment_equity  = this.investment.equity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.investment_convertible = this.investment.convertible.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.investment_safe   = this.investment.safe.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.investment_bonds  = this.investment.bonds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.investment_bank   = this.investment.bank.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.investment_grants = this.investment.grants.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                if (this.investment.stage == 'seed_stage') { this.seed_stage = true; }
                else if (this.investment.stage == 'series_a') { this.series_a = true; }
                else if (this.investment.stage == 'series_b') { this.series_b = true; }
                else if (this.investment.stage == 'later_stage') { this.later_stage = true; }
            }),
            FinanceService.getShareholder(this.company_id).then((res) => {
                let data = res.data.capitalization;
                this.shareholder_common_number  = data.common_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.shareholder_common_value   = data.common_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.shareholder_preferred_number  = data.preferred_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.shareholder_preferred_value   = data.preferred_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.shareholder_granted_number    = data.granted_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.shareholder_granted_value     = data.granted_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.shareholder_authorized_number = data.authorized_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.shareholder_authorized_value  = data.authorized_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                
                this.shareholder.common.number = data.common_number;
                this.shareholder.common.value  = data.common_value;
                this.shareholder.preferred.number = data.preferred_number;
                this.shareholder.preferred.value  = data.preferred_value;
                this.shareholder.granted.number   = data.granted_number;
                this.shareholder.granted.value    = data.granted_value;
                this.shareholder.authorized.number = data.authorized_number;
                this.shareholder.authorized.value  = data.authorized_value;

                this.shareholder.shareholders.pop();
                res.data.shareholders.forEach((element) => {
                    this.shareholder.shareholders.push({
                        name: element.name,
                        share: element.share,
                        type: element.type,
                    });
                });

                if (res.data.dilluted) {
                    this.shareholder_account.shareholders.pop();
                    res.data.dilluted.forEach((element) => {
                        this.shareholder_account.shareholders.push({
                            name: element.name,
                            current_account_balance: element.current_account_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                            type: element.type,
                        });
                    });
                }
            }),
        ])
    }
}
</script>

<style scoped>
.grey-back,
.years input,
.years input::placeholder {
    color: white !important;
    text-align: center;
    font-weight: bold;
}
.position-absolute-error {
  position: absolute;
  top: -5px;
}
</style>
