<template>
  <div>
    <v-sheet height="100%" class="mx-auto mt-10">
      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="1" />
        <v-col cols="10">
          <p class="pt-2 text-sm-left cyan--text text--darken-1" id="title">
            Your Billing and Payment Currency
          </p>
          <v-row>
            <v-col cols="auto">
              <p class="text-sm-left thin pt-2">
                Confirm the currency of the payment
              </p>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                v-model="currency"
                :items="currencies"
                background-color="white"
                @change="getNeedService(change)"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="1" />
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-10">
      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="1" />
        <v-col cols="10">
          <p class="pt-2 text-sm-left cyan--text text--darken-1" id="title">
            Redeem your coupon
          </p>
          <v-row>
            <v-col cols="auto">
              <p class="text-sm-left thin pt-2">
                Promo code
              </p>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field
                  v-model="promo_code"
                  background-color="white"
                  :error="is_not_correct"
                  :success="is_correct"
                  outlined
                  dense
                  placeholder="Promo code"
                />
            </v-col>
          </v-row>
            <p class="pt-2 text-sm-left blue-grey--text text--lighten-2 font-weight-thin" v-if="discount">SAVE: {{ discount.coupon_type == 'Percent' ? `${discount.percent_discount } %`: `USD ${discount.amount_discount}` }}</p>
          <v-col cols="12" v-if="message" class="text-left mt-n5 ml-n3">
            <div>{{ message }} Please <a :href="CMScontact">contact us</a> for more information</div>
          </v-col>
        </v-col>
        <v-col cols="1" />
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto mt-10">
      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="1" />
        <v-col cols="10">
          <p class="pt-2 text-sm-left cyan--text text--darken-1" id="title">
            Your Order
          </p>
          <p class="text-sm-left thin pt-2">
            According to the amount of investment your company wants to raise,
            ie {{ currency }}
            {{
              currency == "USD" ? fundRaisingAmountUSD : fundRaisingAmountEUR
            }}, the fixed fee to apply to IdeasFundX is
          </p>

          <v-row class="mb-2">
            <v-col cols="2">
              <p class="text-sm-left">Application for IdeasFundX</p>
            </v-col>
            <v-col cols="auto">
              <p style="border: 1px solid" class="px-4" v-if="vatBlock">
                {{ currency }} {{ currency == "USD" ? TotalpriceUSD : TotalpriceEUR }}
              </p>
              <p style="border: 1px solid" class="px-4" v-else>
                {{ currency }} {{ currency == "USD" ? priceUSD : priceEUR }}
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-n10">
            <v-col cols="2">
              <p class="text-sm-left thin">Discount</p>
            </v-col>
            <v-col cols="auto">
              <p class="thin px-4">
                {{ currency }} {{ currency == "USD" ? discountValueUSD : discountValueEUR }}
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-n10">
            <v-col cols="2">
              <p class="text-sm-left thin">
                {{ inEEC(country) ? "VAT Base" : "Sub-Total" }}
              </p>
            </v-col>
            <v-col cols="auto">
              <p class="thin px-4" v-if="vatBlock">
                {{ currency }} {{ currency == "USD" ? vatBaseUSD : vatBaseEUR }}
              </p>
              <p class="thin px-4" v-else>
                {{ currency }} {{ currency == "USD" ? priceUSD : priceEUR }}
              </p>
            </v-col>
          </v-row>

          <v-row class="mt-n10">
            <v-col cols="2">
              <p class="text-sm-left thin">{{ inEEC(country) ? "VAT 20%" : "Tax" }}</p>
            </v-col>
            <v-col cols="auto">
              <p class="thin px-4">
                {{ currency }} {{ currency == "USD" ? percentUSD : percentEUR }}
              </p>
            </v-col>
          </v-row>

          <v-row class="mt-n8">
            <v-col cols="2">
              <p class="text-sm-left">Total purchase</p>
            </v-col>
            <v-col cols="auto">
              <p style="border: 1px solid" class="px-4">
                {{ currency }} {{ currency == "USD" ? totalUSD : totalEUR }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="1" />
      </v-row>
    </v-sheet>

    <v-sheet height="100%" class="mx-auto" color="#f6f6f6">
      <v-row align="center" justify="center" class="pt-2">
        <v-col cols="12" md="10" v-if="loader">
          <p class="cyan--text text--darken-1 font-weight-bold" id="title">
            CONFIRMATION IN PROCESS...
          </p>
        </v-col>
        <v-col cols="7" md="2" v-else>
          <v-card-actions class="justify-center">
            <v-btn
              sm="2"
              id="btn-photo"
              class="white--text"
              style="font-weight: bold; font-size: 24px"
              color="#514d7a"
              height="90"
              min-width="20"
              rounded
              block
              :disabled="false"
              @click.prevent.stop="payWithPaypal()"
            >
              {{ confirmBtnText }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
      <p class="blue-grey--text text--lighten-2 font-weight-thin" v-if="totalUSD != 0 || totalEUR != 0">
        Note that you can pay directly with your credit card<br />
        or with Paypal.
      </p>
    </v-sheet>
  </div>
</template>

<script>
import Var from '@/store/services/var.js'
import NeedService from "@/store/services/need.service";
import Paypal from "@/store/services/paypal.service";
import Status from "@/store/services/status.service";

export default {
  props: {
    company_id: Number,
    address: Object,
  },

  data() {
    return {
      CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
      country: "France",
      fundRaisingAmountUSD: 0,
      fundRaisingAmountEUR: 0,
      currencies: ["EUR", "USD"],
      change: null,
      currency: "USD",
      vat: "",
      vatEnabled: 0,
      EEC: [
        "Austria",
        "Belgium",
        "Bulgaria",
        "Croatia",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Estonia",
        "Finland",
        "France",
        "France, Metropolitan",
        "Germany",
        "Greece",
        "Hungary",
        "Ireland",
        "Italy",
        "Latvia",
        "Lithuania",
        "Luxembourg",
        "Malta",
        "Netherlands",
        "Poland",
        "Portugal",
        "Romania",
        "Slovakia",
        "Slovenia",
        "Spain",
        "Sweden",
      ],
      priceUSD: 0,
      priceEUR: 0,
      percentUSD: 0,
      percentEUR: 0,
      totalUSD: 0,
      totalEUR: 0,
      promo_code: '',
      message: '',
      is_correct: false,
      is_not_correct: false,
      discount: null,
      discountValueUSD: 0,
      discountValueEUR: 0,
      confirmBtnText: 'PAY NOW',
      vatBlock: false,
      vatBaseUSD: 0,
      vatBaseEUR: 0,
      TotalpriceUSD: 0,
      TotalpriceEUR: 0,
      loader: false
    };
  },

  watch: {
    
    promo_code() {
      if (this.promo_code === '') {
        this.message = '';
      } else if (this.promo_code !== '' && this.promo_code.trim().length >= 8) {
        this.checkIfExist();
      } else {
        this.message = 'The code you have entered does not exist.';
        this.is_not_correct = true
        this.is_correct = false;
        
        if (this.promo_code.trim().length == 7) {
          this.getNeedService(this.change);
        }
      }
    }
  },

  methods: {
    inEEC (country) {
      return this.EEC.includes(country);
    },

    checkIfExist() {
      this.message = 'Code you have entered does not exist';
      Paypal.checkCode({
        code: this.promo_code,
        role: 'Company'
      }).then((res)=>{
        if(res.status == 200) {
          this.is_not_correct = false
          this.is_correct = true;
          this.discount = res.data;
          this.getNeedService(this.change);
          this.message = ""
          this.$toast.open({
            message: "Code is correct!",
            type: 'success',
            position: 'top-right'
          });
        } else if (res.status == 202) {
          this.message = res.data;
          this.is_not_correct = true
          this.is_correct = false;
          this.discount = null;
          this.getNeedService(this.change);
        }  
        else {
          this.is_not_correct = true
          this.is_correct = false;
          this.message = 'The code you have entered does not exist.';
          this.discount = null;
          this.getNeedService(this.change);
        }
      })
    },

    getDiscount(price, discountPercent) {
      return (price * discountPercent) / 100;
    },

    payWithPaypal() {
      let payItems = {
        currency: this.currency,
        total: this.currency == "USD" ? this.totalUSD : this.totalEUR,
        v: this.vatEnabled,
        company_id: this.company_id,
        role: "company",
        tpay: "application",
        fee: 0
      }; 

      if (payItems.currency && payItems.total != 0 && payItems.company_id) {
        Paypal.charge(payItems).then((res) => {
          if (res.status == 202) {
            if (this.promo_code) {
              localStorage.promo_code = this.promo_code;
              localStorage.coupon_type = this.discount.coupon_type;
              localStorage.coupon_discount = this.discount.coupon_type == "Percent" ? this.discount.percent_discount : this.discount.amount_discount;
              localStorage.coupon_code = this.discount.coupon_code;
            }
            if (this.vatBlock) {
              localStorage.initial_amount  = this.currency == 'USD' ? this.TotalpriceUSD : this.TotalpriceEUR;
              localStorage.vatBase         = this.currency == 'USD' ? this.vatBaseUSD : this.vatBaseEUR;
            } else {
              localStorage.initial_amount  = this.currency == 'USD' ? this.priceUSD : this.priceEUR;
              localStorage.vatBase         = this.currency == 'USD' ? this.priceUSD : this.priceEUR;
            }
            window.open(res.data, "_top");
          } else {
            this.$toast.open({
              message: "Something went wrong, please try late!",
              type: 'error',
              position: 'top-right'
            });
          }
        });
      } else {
        this.loader = true;
        this.paymentSuccess()
      }
    },

    validPayment: function (data, actions) {
      return actions.payment.create({
        transactions: [
          {
            amount: {
              currency: this.currency,
              total: this.currency == "USD" ? this.totalUSD : this.totalEUR,
            },
          },
        ],
      });
    },

    getNeedService(changeRate) {
      this.TotalpriceUSD = 0;
      this.discountValueUSD = 0;
      this.vatBaseUSD = 0;
      this.percentUSD = 0;
      this.percentEUR = 0;

      NeedService.getFund(this.company_id).then((res) => {
        this.fundRaisingAmountUSD = res.data.raising;
        this.fundRaisingAmountEUR = this.fundRaisingAmountUSD * changeRate;
        if ((this.inEEC(this.country) && !this.vat) || this.country == "France" || this.country == "France, Metropolitan" || (this.address.company_name && this.inEEC(this.country) && !this.vat)) {
          this.priceUSD =
            Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100; //* 1.05
          this.priceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
          this.percentUSD = Math.floor(this.priceUSD * 0.2 * 100) / 100;
          this.percentEUR = Math.floor(this.priceEUR * 0.2 * 100) / 100;
          this.vatEnabled = 1;

          this.totalUSD = this.priceUSD + this.percentUSD;
          this.totalEUR = this.priceEUR + this.percentEUR;

          if (this.is_correct) {
            this.vatBlock = true;
            if(this.discount && this.discount.coupon_type == 'Percent') {
              if(this.discount.percent_discount == 100) {
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100;
                  this.discountValueUSD = this.priceUSD;
                  this.vatBaseUSD = 0;
                  this.percentUSD = 0;
                } else {
                  this.discountValueEUR = this.priceEUR;
                  this.TotalpriceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
                  this.vatBaseEUR = 0;
                  this.percentEUR = 0;
                } 
              } else {
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100;
                  this.discountValueUSD = this.getDiscount(this.priceUSD, this.discount.percent_discount);
                  this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
                  this.percentUSD = Math.floor(this.vatBaseUSD * 0.2 * 100) / 100;
                } else {
                  this.TotalpriceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
                  this.discountValueEUR = this.getDiscount(this.priceEUR, this.discount.percent_discount);
                  this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
                  this.percentEUR = Math.floor(this.vatBaseEUR * 0.2 * 100) / 100;
                }
              } 
            } else if (this.discount && this.discount.coupon_type == 'Value') {
              this.discountValueUSD = this.discount.amount_discount;
              this.discountValueEUR = this.priceEUR = Math.floor(this.discount.amount_discount * changeRate * 100) / 100;
              this.TotalpriceUSD = Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100;
              this.TotalpriceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
              this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
              this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
              this.percentUSD = Math.floor(this.vatBaseUSD * 0.2 * 100) / 100;
              this.percentEUR = Math.floor(this.vatBaseEUR * 0.2 * 100) / 100;
            }
            this.totalUSD = this.vatBaseUSD + this.percentUSD;
            this.totalEUR = this.vatBaseEUR + this.percentEUR;
          }

          if (this.totalUSD == 0 || this.totalEUR == 0) {
            this.confirmBtnText = "CONFIRM"
          } else {
            this.confirmBtnText = "PAY NOW"
          }

        } else if (this.inEEC(this.country) && this.vat) {
          this.priceUSD =
            Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100; //* 1.05
          this.priceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
          this.percentUSD = 0;
          this.percentEUR = 0;

          this.totalUSD = this.priceUSD + this.percentUSD;
          this.totalEUR = this.priceEUR + this.percentEUR;

          if (this.is_correct) {
            this.vatBlock = true;
            if(this.discount && this.discount.coupon_type == 'Percent') {
              if(this.discount.percent_discount == 100) {
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100;
                  this.discountValueUSD = this.priceUSD;
                  this.vatBaseUSD = 0;
                  this.percentUSD = 0;
                } else {
                  this.discountValueEUR = this.priceEUR;
                  this.TotalpriceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
                  this.vatBaseEUR = 0;
                  this.percentEUR = 0;
                } 
              } else {
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100;
                  this.discountValueUSD = this.getDiscount(this.priceUSD, this.discount.percent_discount);
                  this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
                  this.percentUSD = 0;
                } else {
                  this.TotalpriceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
                  this.discountValueEUR = this.getDiscount(this.priceEUR, this.discount.percent_discount);
                  this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
                  this.percentEUR = 0;
                }
              }
            } else if (this.discount && this.discount.coupon_type == 'Value') {
              this.discountValueUSD = this.discount.amount_discount;
              this.discountValueEUR = this.priceEUR = Math.floor(this.discount.amount_discount * changeRate * 100) / 100;
              this.TotalpriceUSD = Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100;
              this.TotalpriceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
              this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
              this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
              this.percentUSD = Math.floor(this.vatBaseUSD * 0.2 * 100) / 100;
              this.percentEUR = Math.floor(this.vatBaseEUR * 0.2 * 100) / 100;
            }
            this.totalUSD = this.vatBaseUSD + this.percentUSD;
            this.totalEUR = this.vatBaseEUR + this.percentEUR;
          }
          if (this.totalUSD == 0 || this.totalEUR == 0) {
            this.confirmBtnText = "CONFIRM"
          } else {
            this.confirmBtnText = "PAY NOW"
          }
          
        } else {
          this.priceUSD =
            Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100;
          this.priceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
          this.percentUSD = 0;
          this.percentEUR = 0;

          this.totalUSD = this.priceUSD + this.percentUSD;
          this.totalEUR = this.priceEUR + this.percentEUR;

          if (this.is_correct) {
            this.vatBlock = true;
            if(this.discount && this.discount.coupon_type == 'Percent') {
              if(this.discount.percent_discount == 100) {
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100;
                  this.discountValueUSD = this.priceUSD;
                  this.vatBaseUSD = 0;
                  this.percentUSD = 0;
                } else {
                  this.discountValueEUR = this.priceEUR;
                  this.TotalpriceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
                  this.vatBaseEUR = 0;
                  this.percentEUR = 0;
                } 
              } else {
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100;
                  this.discountValueUSD = this.getDiscount(this.priceUSD, this.discount.percent_discount);
                  this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
                  this.percentUSD = 0;
                } else {
                  this.TotalpriceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
                  this.discountValueEUR = this.getDiscount(this.priceEUR, this.discount.percent_discount);
                  this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
                  this.percentEUR = 0;
                }
              }
            } else if (this.discount && this.discount.coupon_type == 'Value') {
              this.discountValueUSD = this.discount.amount_discount;
              this.discountValueEUR = this.priceEUR = Math.floor(this.discount.amount_discount * changeRate * 100) / 100;
              this.TotalpriceUSD = Math.floor(this.fundRaisingAmountUSD * 0.001 * 100) / 100;
              this.TotalpriceEUR = Math.floor(this.priceUSD * changeRate * 100) / 100;
              this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
              this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
              this.percentUSD = 0;
              this.percentEUR = 0;
            }
            this.totalUSD = this.vatBaseUSD + this.percentUSD;
            this.totalEUR = this.vatBaseEUR + this.percentEUR;
          } else {
            this.vatBlock = false;
          }
          if (this.totalUSD == 0 || this.totalEUR == 0) {
            this.confirmBtnText = "CONFIRM"
          } else {
            this.confirmBtnText = "PAY NOW"
          }
        }
      });
    },

    paymentSuccess() {
      Paypal.promoCodeSuccess(
        JSON.stringify({
          company_id: this.company_id, //this.company_id,
          paymentId: 'NULL',
          token: 'NULL',
          PayerID: 'NULL',
          v: 'NULL',
          role: 'company',
          tpay: 'NULL',
          fee: 0,
          currency: this.currency,
          discount_amount: this.currency == 'USD' ? this.discountValueUSD : this.discountValueEUR,
          promo_code: this.promo_code,
          coupon_type : this.discount.coupon_type,
          coupon_discount : this.discount.coupon_type == "Percent" ? this.discount.percent_discount : this.discount.amount_discount,
          description:
            "Application to submit your company profile to investors",
        })
      ).then((res) => {
        if (res.status == 200) {
          Status.store(
            JSON.stringify({
              company_id: this.company_id,
              application_step: 8,
            })
          ).then(() => {
            this.$router.push({ name: "CompanyDashboard" });
            this.loader = false;
          });
          
        }else {
          this.loader = false;
        }
      })
    },
  },

  mounted() {
    Var.getRate().then((res) => {
      this.change = res.data.final_rate;

      this.vat = this.address.vat;
      if (this.address.country) {
        if (this.inEEC(this.address.country)) {
          this.currency = 'EUR';
        } else {
          this.currency = 'USD';
        } 
      } else {
        this.currency = 'EUR';
      }
      this.country = this.address.country; 
      this.getNeedService(res.data.final_rate);
    })
  },
};
</script>
