<template>
  <div>
    <v-toolbar style="background: #f6f6f6; overflow: hidden; position: fixed; top: 0; width: 100%; z-index:1000;" height="78">
      <img
        class="ml-n4 link"
        alt="Vue logo"
        src="../../../assets/img/Left_menu.png"
        width="78"
        height="78"
        @click.prevent.stop="LeftSidebarPanel()"
      />

      <v-spacer></v-spacer>
      <img
        alt="Vue logo"
        src="../../../assets/img/Favicon.png"
        width="78"
        height="78"
        v-bind:onclick="this.CMSlink"
      />

      <v-spacer></v-spacer>

      <img
        class="mr-n4 link"
        alt="Vue logo"
        src="../../../assets/img/Right_menu.png"
        width="78"
        height="78"
        @click.prevent.stop="RightSidebarPanel()"
      />
    </v-toolbar>

    <div class="sidebar">
      <div
        class="sidebar-backdrop"
        @click="LeftSidebarPanel"
        v-if="isPanelLeftOpen"
      />

      <transition name="slideLeft">
        <div v-if="isPanelLeftOpen" class="sidebar-panel-left">
          <Menu
            v-bind:array="this.companies"
            v-bind:comp_id="this.id"
            v-bind:current="this.current"
            @changeComponent="changeComponent"
            @closeLeftMenuMobile="closeLeftMenuMobile()"
            @openAlertPopupDownload="openAlertPopupDownload"
          />
        </div>
      </transition>
    </div>

    <div class="sidebar">
      <div
        class="sidebar-backdrop"
        @click="RightSidebarPanel"
        v-if="isPanelRightOpen"
      />

      <transition name="slideRight">
        <div v-if="isPanelRightOpen" class="sidebar-panel-right">
          <div
            class="setting mb-5"
            @click.prevent.stop="changeComponent(null, 'changepwd')"
          >
            Password
          </div>
          <div class="setting" @click.prevent.stop="submitLogout()">Logout</div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Dashboard/Investor/DashboardMenu.vue";

export default {
  components: {
    Menu,
  },

  props: {
    companies: Array,
    id: Number,
  },

  data() {
    return {
      CMSlink:`window.location=\'${process.env.VUE_APP_CMS_URL}\';`,
      isPanelLeftOpen: false,
      isPanelRightOpen: false,
      current: null,
      enablePopup: false,
      type: null,
    };
  },

  methods: {
    LeftSidebarPanel() {
      this.isPanelRightOpen = false;
      this.isPanelLeftOpen = !this.isPanelLeftOpen;
    },

    RightSidebarPanel() {
      this.isPanelLeftOpen = false;
      this.isPanelRightOpen = !this.isPanelRightOpen;
    },

    changeComponent(id, comp) {
      this.current = comp;
      this.isPanelLeftOpen = false;
      this.isPanelRightOpen = false;
      this.$emit("changeComponent", id, comp);
    },

    openAlertPopupDownload(val) {
      this.isPanelLeftOpen = false;
      this.$emit("openAlertPopupDownload", val);
    },

    closeLeftMenuMobile(){
      this.isPanelRightOpen = false;
      // this.isPanelLeftOpen = !this.isPanelLeftOpen;
    },

    async submitLogout() {
      await this.$store
        .dispatch("logout", {})
        .then((response) => {
          //console.log("logout success");
        })
        .catch((error) => {});
    },
  },
};
</script>

<style>
.slideLeft-enter-active,
.slideLeft-leave-active,
.slideRight-enter-active,
.slideRight-leave-active {
  transition: transform 0.2s ease;
}

.slideLeft-enter,
.slideLeft-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.slideRight-enter,
.slideRight-leave-to {
  transform: translateX(+100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel-left {
  overflow-y: auto;
  background-color: #f6f6f6;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding: 3rem 20px 2rem 20px;
  width: 300;
}

.sidebar-panel-right {
  overflow-y: auto;
  background-color: #f6f6f6;
  position: fixed;
  left: 50vw;
  top: 78px;
  height: 15vh;
  z-index: 999;
  padding: 2rem 20px 2rem 20px;
  width: 50vw;
}
.link:hover {
    cursor: pointer;
}
.setting{
  transition: ease all 0.5s;
}
.setting:hover {
  color: #000;
  font-weight: 700 !important;
  cursor: pointer;
}

</style>
