<template>
  <div>
    <div v-if="!loading">
      <v-dialog v-model="dialogDeleteFounder" max-width="400px">
        <v-card
          min-height="140px"
          style="overflow-x: hidden; overflow-y: hidden"
        >
          <div class="text-center p-4">
            <span id="title" class="text-center">Delete Founder</span>
            <div>Are you sure you want to delete the Founder <strong v-if="currentFounder">{{ currentFounder.name }}</strong>?</div>

            <v-card-actions class="d-flex justify-content-between">
              <button class="btn btn-secondary" @click="dialogDeleteFounder = false">Cancel</button>
              <button class="btn btn-danger" @click="deleteSavedFounder(currentFounder.founder_id)">Yes</button>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

      <v-sheet height="100%" class="mx-auto mt-8" align="center" justify="center">
        <v-col />
        <v-row>
          <v-col cols="1" />
          <v-col cols="4">
            <p class="text-sm-left">The founding team</p>
          </v-col>
        </v-row>

        <v-row align="center" justify="center" class="mb-5">
          <v-col cols="10">
            <v-divider />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1" />
          <v-col sm="10">
            <div v-for="index in nb" :key="index + 1">
              <div class="d-flex justify-content-between">
                <p class="text-sm-left cyan--text text--darken-1">
                  Founder {{ index }}
                </p>
                <span class="d-flex justify-content-center align-items-center gap-4 delete-bnt" @click="openDeleteDialogue(list_cofounder[index - 1], index)">
                  <div class="mt-1 text">Delete</div> 
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 32 33" fill="none">
                      <path d="M4 8.88889H6.66667H28" stroke="#FF6B4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.6666 8.88889V6.22222C10.6666 5.51497 10.9476 4.8367 11.4477 4.3366C11.9478 3.8365 12.626 3.55555 13.3333 3.55555H18.6666C19.3739 3.55555 20.0521 3.8365 20.5522 4.3366C21.0523 4.8367 21.3333 5.51497 21.3333 6.22222V8.88889M25.3333 8.88889V27.5556C25.3333 28.2628 25.0523 28.9411 24.5522 29.4412C24.0521 29.9413 23.3739 30.2222 22.6666 30.2222H9.33329C8.62605 30.2222 7.94777 29.9413 7.44767 29.4412C6.94758 28.9411 6.66663 28.2628 6.66663 27.5556V8.88889H25.3333Z" stroke="#FF6B4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.3334 15.5556V23.5556" stroke="#FF6B4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M18.6666 15.5556V23.5556" stroke="#FF6B4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </span>
              </div>
              <Founder
                ref="founder"
                v-bind:founderProp="list_cofounder[index - 1]"
                :founderIndex ="index"
                :degrees="degrees"
                :country="country"
                :years="years"
                :entries="entries"
                :imagePreviewUrl="imagePreviewUrl"
                :photoBlock="photoBlock"
                v-if="index - 1 < list_cofounder.length"
              />
              <Founder
                ref="founder"
                :founderIndex ="index"
                v-bind:founderProp="{ schools: [{}], formers: [{}] }"
                :degrees="degrees"
                :country="country"
                :years="years"
                :entries="entries"
                :imagePreviewUrl="imagePreviewUrl"
                :photoBlock="photoBlock"
                v-else
              />
            </div>
          </v-col>

          <v-col cols="3" />
          
          <v-btn
            id="btn-add"
            text
            color="#00aac3"
            class="mb-4"
            @click.prevent.stop="addFounder(); currentBlock = true"
          >
            + Add Founder
          </v-btn>

          <v-col cols="3" />

          <div class="d-none" v-if="this.nb > 1">
            <v-btn
              id="btn-add"
              text
              color="#00aac3"
              @click.prevent.stop="deleteFounder"
            >
              - Remove Founder
            </v-btn>
          </div>

          <v-col cols="12" />
        </v-row>
      </v-sheet>

      <v-sheet height="100%" class="mx-auto" color="#f6f6f6" v-if="currentBlock">
        <v-row align="center" justify="center">
          <v-col cols="10" />
          <v-col cols="12" md="12">
            <v-icon
              large
              color="#00aac3"
              right
              center
              @click.prevent.stop="saveFounders"
            >
              mdi-arrow-down-circle
            </v-icon>
          </v-col>
        </v-row>
      </v-sheet>
    </div>
    <div v-else>
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

</template>

<script>
import Founder from "@/components/Steps/Step3/Blocks/Founders";
import TeamsService from "@/store/services/teams.service";
import Status from "@/store/services/status.service";
import Var from "@/store/services/var";
import Profile from "@/store/services/profile-service";

export default {
  components: {
    Founder,
  },

  // props: {
  //   company_id: Number,
  // },

  data() {
    return {
      nb: 0,
      currentBlock: true,
      list_cofounder: [],
      founderIndex: 0,
      dialogDeleteFounder: false,
      company_id: localStorage.getItem('company_id'),
      currentFounder: null,
      loading: false,

      degrees: [],
      country: [],
      years: [],
      entries: [],
      imagePreviewUrl: null,
      photoBlock: true,
    };
  },

  methods: {
    addFounder() {
      this.nb += 1;
    },

    deleteFounder() {
      this.nb -= 1;
    },
    
    openDeleteDialogue(founder) {
      if (!founder || (typeof founder !== 'object') || Object.keys(founder || {}).length === 0) {
        this.deleteFounder()
      } else {
        this.currentFounder = founder;
        this.dialogDeleteFounder = true;
      }
    },

    deleteSavedFounder (id) {
      TeamsService.destroy(id)
        .then(() => {
          this.$toast.open({ 
            message: "Founder deleted successfully !",
            type: 'success',
            position: 'top-right'
          });
          this.nb = 0;
          this.retrieveFounders(this.company_id)
          this.saveFounders()
          this.dialogDeleteFounder = false;
        })
        .catch(() => {
          this.$toast.open({
            message: "Something went wrong, please try again !",
            type: 'error',
            position: 'top-right'
          });
        });
    },

    saveFounders() {
      let ret = false;

      for (let x = 0; x < this.nb; x++) {
        ret += this.$refs.founder[x].saveFounder(this.company_id);
      }

      if (ret) {
        this.currentBlock = false;
        this.$emit("block2");
        this.nb = 0
        this.retrieveFounders(this.company_id);
      }
    },

    retrieveFounders(company_id) {
      this.loading = true;
      TeamsService.getFounders(company_id)

      .then((res) => {
        if (res.data.length) {
          res.data.forEach((founder) => {
            this.list_cofounder.push({});
            this.list_cofounder[this.nb].name = founder[0];
            this.list_cofounder[this.nb].country = founder[1];
            this.list_cofounder[this.nb].birth = founder[2];
            this.list_cofounder[this.nb].equity = founder[3];
            this.list_cofounder[this.nb].expertise = founder[4];
            this.list_cofounder[this.nb].years = founder[5];
            this.list_cofounder[this.nb].founder_id = founder[11];

            this.list_cofounder[this.nb].formers = [];
            this.list_cofounder[this.nb].schools = [];
            founder[6].forEach((element) => {
              this.list_cofounder[this.nb].formers.push({
                company: element.company,
                position: element.position,
              });
            });
            
            founder[7].forEach((element) => {
              this.list_cofounder[this.nb].schools.push({
                school: element.school,
                degree: element.degree,
              });
            });
            
            this.list_cofounder[this.nb].linkedin = founder[8];
            this.list_cofounder[this.nb].describe = founder[9];
            this.list_cofounder[this.nb].photoLink = founder[10];
            this.nb += 1;
          });
          } else {
            TeamsService.isFounder(company_id)
            .then((res) => {
              let founder = res.data;
              this.list_cofounder.push({});
              this.list_cofounder[this.nb].name = founder.name;
              this.list_cofounder[this.nb].country = founder.country;
              this.list_cofounder[this.nb].birth = founder.birth;
              this.list_cofounder[this.nb].expertise = founder.expertise;
              this.list_cofounder[this.nb].years = founder.years;
              this.list_cofounder[this.nb].formers = [];
              this.list_cofounder[this.nb].schools = [];

              founder.formers.forEach((element) => {
                this.list_cofounder[this.nb].formers.push({
                  company: element[1],
                  position: element[0],
                });
              });
              
              founder.schools.forEach((element) => {
                this.list_cofounder[this.nb].schools.push({
                  school: element[0],
                  degree: element[1],
                });
              });
              
              this.list_cofounder[this.nb].linkedin = founder.linkedin ? founder.linkedin : "";
              this.list_cofounder[this.nb].photoLink = founder.photo;
              this.nb += 1;
            })
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.nb = 1;
        });
    },

    getStatus() {
      Status.readAll()
        .then((res) => {
          if (res.data) {
            this.company_id = res.data.active_company_id;
            this.retrieveFounders(res.data.active_company_id);
          }
        })
    },

    getVar() {
      Var.getAllVariables().then((res) => {
        this.degrees = res.data.degrees;
        this.country = res.data.countries;
        this.years = res.data.years_of_experience;
        this.entries = res.data.skills;
      })
    },

    retrieveAvatar() {
      Profile.getAvatar()
        .then((res) => {
          this.imagePreviewUrl = res.data;
          this.photoBlock = false;
        })
        .catch((error) => {
          this.photoBlock = true;
        });
    },
  },

  mounted() {
    this.getStatus();
    this.getVar();
    this.retrieveAvatar();
  },
};
</script>

<style>
  .delete-bnt {
    display: flex;
    justify-content: center;
    align-items: center;
    color: brown;
    cursor: pointer;
    padding: 0px 15px;
    transition: ease all .5s;
    border: solid rgb(255, 0, 0) 1px;
    font-size: 16px !important;
  }
  .delete-bnt:hover {
    color: rgb(255, 87, 87) !important;
    border: solid rgb(255, 36, 36) 1px !important;
  }
</style>
