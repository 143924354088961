<template>
  <div>
    <v-toolbar style="background: #f6f6f6; overflow: hidden; position: fixed; top: 0; width: 100%; z-index:1000;" height="78">
      <img
      class="ml-4"
        alt="IdeasFundX Logo"
        src="../../../assets/logo.png"
        width="200"
        height="30"
        v-bind:onclick="this.CMSlink"
      />

      <v-spacer />

      <img src="../../../assets/img/Bell.png" alt="Bell" height="30px" class="mr-10">

      <v-divider vertical style="border-color: #0db0c4" />

      <v-img
        class="mx-2"
        contain
        maxWidth="70px"
        maxHeight="70px"
        style="border-radius: 50%; border: 1px solid"
        :src="avatarPreviewUrl"
      />

      <v-icon @click.prevent.stop="RightSidebarPanel()">
        mdi-cog-outline
      </v-icon>
    </v-toolbar>

    <div class="sidebar">
      <div
        class="sidebar-backdrop"
        @click="RightSidebarPanel()"
        v-if="isPanelRightOpen"
      />

      <transition name="slideRight">
        <div
          v-if="isPanelRightOpen"
          class="sidebar-panel-right"
          style="width: 20vw !important; left: 80vw !important;"
        >
          <div class="setting mb-5" @click.prevent.stop="changeComponent()">
            Password
          </div>
          <div class="setting" @click.prevent.stop="submitLogout()">Logout</div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: { avatarPreviewUrl: String },

  data() {
    return {
      CMSlink:`window.location=\'${process.env.VUE_APP_CMS_URL}\';`,
      isPanelRightOpen: false,
    };
  },

  methods: {
    RightSidebarPanel() {
      this.isPanelRightOpen = !this.isPanelRightOpen;
    },

    changeComponent() {
      this.isPanelRightOpen = false;
      this.$emit("changeComponent", null, "changepwd");
    },

    async submitLogout() {
      await this.$store
        .dispatch("logout", {})
        .then((response) => {
          //console.log("logout success");
        })
        .catch((error) => {});
    },
  },
};
</script>

<style>
.link:hover {
  cursor: pointer;
}
.setting{
  transition: ease all 0.5s;
}
.setting:hover {
  color: #000;
  font-weight: 700 !important;
  cursor: pointer;
}
</style>
