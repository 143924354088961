<template>
  <div class="mb-16">
    <v-toolbar style="background: #f6f6f6; position: fixed; top: 0; width: 100%; z-index: 10;" height="78">
      <img
        class="logo-site"
        alt="IdeasFundX Logo"
        src="../assets/logo.png"
        v-bind:onclick="this.CMSlink"
      />

      <v-spacer></v-spacer>
      <div class="header-right mr-4">
        <a href="/login">Login</a>
      </div>
      <img
        class="mr-1 link"
        alt="Vue logo"
        src="../assets/img/menu.jpg"
        width="51px"
        height="36px"
        @click.prevent.stop="RightSidebarPanel()"
      />
    </v-toolbar>

    <div class="sidebar">
      <div
        class="sidebar-backdrop"
        @click="RightSidebarPanel"
        v-if="isPanelRightOpen"
      />

      <transition name="slideRight">
        <div v-if="isPanelRightOpen" class="sidebar-panel-right">
          <ul class="menu-slide">
            <ul>
              <li>
                <a href="/about" target="_blank">About</a>
              </li>
              <li>
                <a href="/contact" target="_blank">Contact Us</a></li>
              <li>
                <a href="/investor">Investor</a>
              </li>
              <li>
                <a href="/home">Company</a>
              </li>
            </ul>
          </ul>

          <div class="donate-btn">
            <a href="/login" target="_blank">Login</a>
          </div>
          <ul class="slide-social">
            <li>
              <a href="http://twitter.com/ideasvoice" target="_blank"><v-icon large color="white darken-2" class="social-icon">mdi-twitter</v-icon></a>
            </li>
            <li>
              <a href="https://www.facebook.com/IdeasvoiceNews/" target="_blank"><v-icon large color="white darken-2" class="social-icon"> mdi-facebook</v-icon></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/ideasvoice" target="_blank"><v-icon large color="white darken-2" class="social-icon"> mdi-linkedin</v-icon></a>
            </li>
            <li>
              <a href="https://www.youtube.com/ideasvoice" target="_blank"><v-icon large color="white darken-2" class="social-icon">mdi-youtube</v-icon></a></li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Dashboard/DashboardMenu.vue";

export default {
  components: {
    Menu,
  },

  props: {
    companies: Array,
    id: Number,
  },

  data() {
    return {
      CMSlink: `window.location=\'${process.env.VUE_APP_CMS_URL}\';`,
      CMShome: `${process.env.VUE_APP_CMS_URL}`,
      CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
      CMSinvestor: `${process.env.VUE_APP_CMS_URL}investor`,
      CMSabout: `${process.env.VUE_APP_CMS_URL}about`,
      isPanelRightOpen: false,
      current: null,
    };
  },

  methods: {
    RightSidebarPanel() {
      this.isPanelRightOpen = !this.isPanelRightOpen;
    },
    redirectTo(route) {
      window.open(route); //, "_top"
    },
  },
};
</script>

<style>
.logo-site {
  height: 27% !important;
  margin-left: 3px;
}

.slideLeft-enter-active,
.slideLeft-leave-active,
.slideRight-enter-active,
.slideRight-leave-active {
  transition: transform 0.2s ease;
}

.slideLeft-enter,
.slideLeft-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.slideRight-enter,
.slideRight-leave-to {
  transform: translateX(+100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel-left {
  overflow-y: auto;
  background-color: #f6f6f6;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding: 3rem 20px 2rem 20px;
  width: 300;
}

.link:hover {
  cursor: pointer;
}

.header-right {
  text-align: right;
}
.header-right a {
  color: #fff !important;
  display: inline-block;
  background: #00aac2;
  padding: 6px 20px;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 40px;
  font-weight: 600;
  letter-spacing: 0.4px;
  border: 2px solid #00aac2;
  transition: 0.3s;
  text-decoration: none;
}
.header-right a:hover {
  background: transparent;
  color: #00aac2 !important;
}

/* ====== SLIDE MENU ====== */

.sidebar-panel-right {
  overflow-y: auto;
  background-color: #00aac3 !important;
  position: fixed;
  left: 40vw;
  top: 78px;
  height: 100vh !important;
  z-index: 999;
  /* padding: 2rem 20px 2rem 20px; */
  width: 50vw;
}

.menu-slide {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
  margin-top: 50px !important;
}
.menu-slide li {
  display: block !important;
  text-align: left !important;
}
.menu-slide li a {
  display: block !important;
  padding: 15px 0 !important;
  border-bottom: 1px solid #fff !important;
  color: #fff !important;
  font-size: 18px !important;
  text-decoration: none !important;
}

.donate-btn a {
  color: #00aac3;
  display: block;
  background: rgb(255, 249, 249);
  padding: 15px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
}
.donate-btn {
  margin-top: 50px;
}

 /* .slide-social {
  margin: 0 !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 20px 25px;
} */

.slide-social {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 15px 25px;
  margin-top: 20px;
  position: absolute;
  /* bottom: 0; */
  width: 100%;
  left: 0;
}
.slide-social li {
  display: inline-block;
  color: #fff;
  font-size: 76px;
}

.slide-social li a .social-icon {
  font-size: 20px !important;
  color: #fff;
  display: inline-block;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}

@media only screen and (min-width: 20px) and (max-width: 330px) {
  .logo-site {
    width: 40%;
    height: 25% !important;
    margin-left: -5px !important;
  }
  .header-right {
    text-align: right;
  }
  .header-right a {
    padding: 4px 18px;
    font-size: 14px;
  }
  .link {
    width: 46px !important;
  }
  .menu-slide ul {
    padding-left: 0px !important;
  }
}
</style>
