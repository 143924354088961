<template>
  <div class="mt-8">
  </div>
</template>

<script>
export default {
  //props: { is_company: Boolean },

  data() {
    return {
      CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
      //
    };
  },

  methods: {
    redirectToCMS() {
      window.open(process.env.VUE_APP_CMS_URL, "_top");
    },
  },
};
</script>

<style scoped lang="css">
.imgcenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.mycenter {
  justify-content: center;
  align-items: center;
}
#title {
  /* font-weight: bolder; */
  font-size: 2em;
}
#sub-text p {
  font-weight: normal;
  font-size: 1em;
}
</style>
