import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      // Authorization: "Bearer " + localStorage.getItem("vue-authenticate.vueauth_access_token")
    },
};

function storeLogs(payload) {
    return axios.post(`${url}/admin/company/logs`, payload)
}

export default {  
    storeLogs,
};
