<template>
  <div class="body">
    <Header class="d-none d-md-flex"/>
    <HeaderPortable class="d-flex d-md-none"/>
    <v-row>
      <v-col cols="0" md="2" class="d-none d-md-flex">
        <Menu />
      </v-col>

      <v-col class="maskContainer">
        <div class="d-flex justify-content-center align-items-start">
            <p class="font-weight-bolder mr-4 font-size-20">COUPONS LIST</p>
            <router-link to="/admin/create-coupon">
                <v-icon dense class="admin-icon edit">
                mdi-plus
            </v-icon>
            </router-link>
        </div>
        <div>
            <table id="user" v-if="coupons.length != 0">
                <tr>
                    <th class="pt-flex">
                        <label class="inputCheckBox">
                            <input
                                type="checkbox"
                                @click="is_checked = !is_checked"
                                name="consent" id="consent"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </th>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Product</th>
                    <th>Code</th>
                    <th>Total</th>
                    <th>Remaining</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                <tr v-for="(coupon, index) in coupons" :key="index">
                    <td class="pt-flex">
                        <label class="inputCheckBox">
                            <input
                                type="checkbox"
                                name="consent" id="consent" :checked="is_checked"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </td>
                    <td>{{ coupon.id }}</td>
                    <td>{{ coupon.title }}</td>
                    <td>{{ coupon.product }}</td>
                    <td>{{ coupon.code }}</td>
                    <td>{{ coupon.available_stock }}</td>
                    <td>{{ coupon.remaining_stock }}</td>
                    <td>
                        <div class="switchTable">
                            <span :class="coupon.coupon_status == 'Active' ? 'active': ''">
                                Active
                            </span>
                            <span :class="coupon.coupon_status != 'Active' ? 'active': ''">
                                No Active
                            </span>
                        </div>
                    </td>
                    <td>
                        <router-link :to="`/admin/update-coupon/${coupon.id}`">
                            <v-icon dense class="admin-icon edit" style="margin-right: 5px;">
                                mdi-pencil
                            </v-icon>
                        </router-link>
                        <v-icon dense class="admin-icon delete" @click="onDelete(coupon.id)">
                            mdi-delete
                        </v-icon>
                    </td>
                </tr>
            </table>
            <div v-else class="mt-10">
                <img src="../../assets/img/EmptyCoupons.png" alt="Empty coupons" height="100">
                <p>List of Coupons is empty</p>
            </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Header from '@/components/admin/AdminHeader.vue';
import HeaderPortable from '@/components/admin/AdminHeadePortable.vue';
import Menu from '@/components/admin/AdminLeftMenu.vue';
import Admin from "@/store/services/admin/company";


export default {
    components: {
        Header,
        HeaderPortable,
        Menu,
    },
    data() {
        return {
            is_checked: false,
            coupons: [],
        }
    },
    validations: { },
    methods: { 
        getCoupons() {
            Admin.getCoupons().then((res) => {
                if(res.status == 200) {
                    this.coupons = res.data;
                }
            })
        },
        onDelete(coupon_id) {
            this.$confirm("Are you sure you want to delete?").then(() => {
                Admin.deleteCoupon(coupon_id).then((res) =>{
                    if (res.status == 200) {
                        this.$toast.open({
                            message: res.data,
                            type: 'success',
                            position: 'top-right'
                        });
                        this.getCoupons();
                    } else if (res.status == 202) {
                        this.$toast.open({
                            message: res.data,
                            type: 'info',
                            position: 'top-right'
                        });
                    }  else {
                        this.$toast.open({
                            message: 'You can\'t delete',
                            type: 'error',
                            position: 'top-right'
                        });
                    }
                }) 
            })
        }
    },

  mounted() { 
    this.getCoupons();
  },
}
</script>
<style src="../../assets/styles/admin/style.css"> </style>
<style scoped src="../../assets/styles/checkBoxInput.css"></style>
