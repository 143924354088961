<template>
  <div class="body">
    <Header class="d-none d-md-flex"/>
    <HeaderPortable class="d-flex d-md-none"/>
    <v-row>
      <v-col cols="0" md="2" class="d-none d-md-flex">
        <Menu />
      </v-col>

      <v-col class="maskContainer">
        <div>
            <p class="text-align-center title">EXCHANGE RATE</p>
            <div class="text-left" v-if="changeRate">
                <p class="normal-p">Initial Currency: <b>{{ changeRate.initial_currency	}}</b></p>
                <p class="normal-p">Initial Rate: <b>{{ changeRate.initial_rate }}</b></p>
                <p class="normal-p">Final Currency: <b>{{ changeRate.final_currency }}</b></p>
                <p class="normal-p">Final Rate: </p>
            </div>
            <div class="d-flex mt-n3 form-btn">
                <div>
                    <div class="mr-5">
                        <v-text-field
                        v-model="final_rate"
                        outlined
                        placeholder="Final Rate"
                        dense
                        width="30"
                    />
                    </div>
                    <FormInlineMessage v-if="$v.final_rate.$error">
                        Final rate cannot be empty, please!
                    </FormInlineMessage>
                </div>
                <div>
                    <v-btn
                        id="btn-photo"
                        class="white--text"
                        style="font-weight: bold; font-size: 14px"
                        color="#11aec9"
                        height="42"
                        min-width="100px"
                        rounded
                        :disabled="false"
                        @click.stop.prevent="updateRate()">
                        UPDATE
                    </v-btn>
                </div>
            </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormInlineMessage from "@/components/FormInlineMessage";
import { required } from "vuelidate/lib/validators";
import Header from '@/components/admin/AdminHeader.vue';
import HeaderPortable from '@/components/admin/AdminHeadePortable.vue';
import Menu from '@/components/admin/AdminLeftMenu.vue';
import Var from '@/store/services/var.js'


export default {
    components: {
        Header,
        HeaderPortable,
        Menu,
        FormInlineMessage
    },
    data() {
        return {
            final_rate: null,
            changeRate: null
        }
    },
    validations: {
        final_rate: { required },
    },
    methods: {
        getRate() {
            Var.getRate().then((res) => {
                this.changeRate = res.data;
                this.final_rate = this.changeRate.final_rate;
            });
        },
        updateRate() {
            this.$v.final_rate.$touch();
            if (this.$v.final_rate.$error) { return; }
            let data = {
                id: this.changeRate.id_rate,
                rate: this.final_rate	 
            }
            Var.updateRate(data).then((res)=>{
                if (res.status == 200) {
                    this.$fire({
                        title: "Update Exchange Rate",
                        text: res.data,
                        type: "success",
                        timer: 3000
                    }).then(r => {
                        // console.log(r.value);
                    });
                }
            });
        }
    },

  mounted() {
    this.getRate();
  },
}
</script>
<style src="../../assets/styles/admin/style.css"> </style>
<style>
    .maskContainer {
        margin-left: 40px;
    }
    .normal-p {
        font-weight: 500 !important;
    }
    @media (min-width: 1px) and (max-width: 415px) {
        .form-btn {
            display: flex;
            flex-direction: column;
        }
    }
</style>
