import service from "@/store/services/profile-service";

const state = {
  me: null,
};

const mutations = {
  SET_RESOURCE: (state, me) => {
    state.me = me;
  },
};

const actions = {
  me({ commit, dispatch }, params) {
    return service.get(params).then((profile) => {
      //console.log(profile);
      commit("SET_RESOURCE", profile.list);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((profile) => {
      commit("SET_RESOURCE", profile);
    });
  },

  generateCode({ commit, dispatch }, params) {
    return service.generateCode("generate");
  },

  sendCode(context, payload) {
    return service.send({ path: "code", payload: payload });

    // return axios.post(`${baseUrl}/code`, payload, requestOptions)
    //   .then(response => {
    //     vueAuth.setToken(response,vueAuth.options.tokenPath);
    //     context.commit("isAuthenticated",{
    //       isAuthenticated: vueAuth.isAuthenticated()
    //     });
    //     router.push({name: "Home"});
    // });
  },

  requestEmail(context, payload) {
    return service.callEmail(payload.path);

    // return axios.post(`${baseUrl}/code`, payload, requestOptions)
    //   .then(response => {
    //     vueAuth.setToken(response,vueAuth.options.tokenPath);
    //     context.commit("isAuthenticated",{
    //       isAuthenticated: vueAuth.isAuthenticated()
    //     });
    //     router.push({name: "Home"});
    // });
  },

  requestDetails(context, payload) {
    return service.myDetails();
  },
};

const getters = {
  me: (state) => state.me,
};

const profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default profile;
